import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const FacetBooleanFilterFragmentDoc = gql`
    fragment facetBooleanFilter on FacetBooleanFilter {
  key
  facetType
}
    `;
export const FacetComplexFilterFragmentDoc = gql`
    fragment facetComplexFilter on FacetComplexFilter {
  key
  facetType
  children {
    ... on FacetBooleanFilter {
      key
    }
    ... on FacetListFilter {
      __typename
      key
      facetType
      valuesPagination(
        page: $page
        perPage: $perPage
        query: $filterSearch
        includeUnusedLabels: false
      ) {
        pageInfo {
          page
          perPage
          hasNextPage
        }
        items {
          record {
            ... on StringWrapper {
              value
            }
          }
          count
        }
      }
    }
    ... on FacetRangeFilter {
      key
      facetType
      minValue
      maxValue
    }
  }
}
    `;
export const FacetDateRangeFilterFragmentDoc = gql`
    fragment facetDateRangeFilter on FacetDateRangeFilter {
  key
  facetType
}
    `;
export const FacetFreeListFilterFragmentDoc = gql`
    fragment facetFreeListFilter on FacetFreeListFilter {
  key
  facetType
  values {
    items {
      count
      value
    }
  }
}
    `;
export const FacetListFilterFragmentDoc = gql`
    fragment facetListFilter on FacetListFilter {
  key
  facetType
  valuesPagination(
    page: $page
    perPage: $perPage
    query: $filterSearch
    includeUnusedLabels: $includeUnusedLabels
  ) {
    items {
      count
      record {
        ... on StringWrapper {
          value
        }
      }
    }
    pageInfo {
      page
      perPage
      hasNextPage
    }
  }
  count
}
    `;
export const FacetRangeFilterFragmentDoc = gql`
    fragment facetRangeFilter on FacetRangeFilter {
  key
  facetType
  minValue
  maxValue
}
    `;
export const FacetFilterFragmentDoc = gql`
    fragment facetFilter on FacetFilter {
  ...facetBooleanFilter
  ...facetComplexFilter
  ...facetDateRangeFilter
  ...facetFreeListFilter
  ...facetListFilter
  ...facetRangeFilter
}
    ${FacetBooleanFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetDateRangeFilterFragmentDoc}
${FacetFreeListFilterFragmentDoc}
${FacetListFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}`;
export const AdminGetMerchantsListItemFragmentDoc = gql`
    fragment adminGetMerchantsListItem on Merchant {
  id
  status
  name
  deliveryTime {
    id
    localizedName(localeId: $localeId)
  }
  deliveryTerms
  country {
    id
    localizedName(localeId: $localeId)
    codeISO
  }
  website
  createdAt
  updatedAt
  updateUser {
    id
    username
  }
}
    `;
export const ImageThumbnail234FragmentDoc = gql`
    fragment imageThumbnail234 on Image {
  thumbnailUrl: imageUrl(transformations: {width: 234, height: 234, resize: fit})
  thumbnailRetinaUrl: imageUrl(
    transformations: {width: 468, height: 468, resize: fit}
  )
}
    `;
export const ImageThumbnail48FragmentDoc = gql`
    fragment imageThumbnail48 on Image {
  imageUrl
  thumbnailUrl: imageUrl(transformations: {width: 48, height: 48, resize: fit})
  thumbnailRetinaUrl: imageUrl(
    transformations: {width: 96, height: 96, resize: fit}
  )
}
    `;
export const AdminProductELabelFragmentDoc = gql`
    fragment adminProductELabel on ProductELabel {
  id
  isPublished
  shortUrl
  updatedAt
  createdAt
  updateUser {
    id
    username
  }
  bottleImage {
    ...imageThumbnail234
  }
  bottleImageThumb: bottleImage {
    ...imageThumbnail48
  }
  bottleSize {
    id
    volumeML
  }
  packageType {
    id
    isDefault
    shortName
    localizedName(localeId: $localeId)
  }
  originProduct {
    slug
    wine {
      tradeName {
        id
      }
    }
  }
  companyPublications {
    company {
      id
      representativeUser {
        id
        username
      }
      crmOrganization {
        id
        link
      }
    }
    isPublished
  }
  wine {
    brand {
      brand
    }
    country {
      codeISO
      localizedName(localeId: $localeId)
    }
    tradeNameBrand
    nationalGIType {
      localizedName(localeId: $localeId)
    }
    geoIndications {
      localizedName(localeId: $localeId)
    }
    keyWords
    specialClassifications {
      name
    }
    sweetnessLevel {
      isDefault
      localizedName(localeId: $localeId)
    }
    wineType {
      isDefault
      localizedName(localeId: $localeId)
    }
    wineColor {
      localizedName(localeId: $localeId)
    }
    country {
      codeISO
      localizedName(localeId: $localeId)
    }
    vintage
    tradeName {
      tradeName
    }
    chemicalAnalysis {
      alcoholVolume
    }
    grapeVarieties {
      grapeVarietySynonym {
        id
        name
      }
      value
    }
  }
}
    ${ImageThumbnail234FragmentDoc}
${ImageThumbnail48FragmentDoc}`;
export const ProductInfoFragmentDoc = gql`
    fragment ProductInfo on Product {
  id
  slug
  bottleSize {
    id
    volumeML
  }
  packageType {
    id
    isDefault
    shortName
    localizedName(localeId: $localeId)
  }
  wine {
    id
    tradeNameBrand
    keyWords
    specialClassifications {
      id
      name
    }
    vintage
    wineType {
      id
      isDefault
      localizedName(localeId: $localeId)
    }
    wineColor {
      id
      localizedName(localeId: $localeId)
    }
    sweetnessLevel {
      id
      isDefault
      localizedName(localeId: $localeId)
    }
    country {
      id
      codeISO
      localizedName(localeId: $localeId)
    }
    geoIndicationText
    geoIndications {
      id
      localizedName(localeId: $localeId)
    }
    nationalGITypeText
    nationalGIType {
      id
      localizedShortName(localeId: $localeId)
    }
    grapeVarieties {
      value
      grapeVarietySynonym {
        id
        name
      }
    }
  }
}
    `;
export const WineOfferRequestCompanyMatchFragmentDoc = gql`
    fragment wineOfferRequestCompanyMatch on WineOfferRequestCompanyMatch {
  companyStatus
  company {
    tradeName
    email
    phone
    country {
      localizedName(localeId: $localeId)
    }
    representativeUser {
      username
      email
    }
    producerTradeName {
      tradeName
      email
      phone
      country {
        localizedName(localeId: $localeId)
      }
    }
  }
  productCount
  offerCount
}
    `;
export const PotentialProducersItemFragmentDoc = gql`
    fragment potentialProducersItem on WineOfferRequestTradeNameMatch {
  emailStatus
  tradeName {
    id
    tradeName
    email
    phone
    website
    postAddress
    postCode
    country {
      id
      localizedName(localeId: $localeId)
      identityNumberName
      branchNumberName
      vatName
      legalEntityDiscriminator
    }
    legalEntity {
      id
      legalName
      identityNumber
      branchNumber
      vat
      legalAddress
      activityCode
      isActive
      closingDate
      legalEntityForm {
        id
        shortName
        originName
      }
    }
    authorUser {
      username
    }
    source {
      id
      description
      name
      sourceType {
        code
      }
    }
    sourceAuthorUser {
      username
    }
    updatedAt
    lastNewsletter {
      senderUser {
        id
        username
      }
      wineOfferRequest {
        number
      }
      createdAt
    }
  }
  productCount
}
    `;
export const AdminPriceSourcesItemFragmentDoc = gql`
    fragment adminPriceSourcesItem on PriceSource {
  id
  type
  status
  refreshRate {
    id
    localizedName(localeId: $localeId)
  }
  sourceURL
  merchant {
    id
    name
    website
  }
  createdAt
  updatedAt
  updateUser {
    id
    username
  }
  lastUpload {
    invalidRowCount
    validRowCount
  }
}
    `;
export const AdminWineRequestsItemFragmentDoc = gql`
    fragment adminWineRequestsItem on WineOfferRequest {
  id
  number
  periodOfValidity
  createdAt
  comment
  authorUser {
    username
    country {
      id
      codeISO
      localizedName(localeId: $localeId)
    }
  }
  note
  stage
  status
  buyerCompany: buyer {
    ... on Company {
      id
      country {
        id
        codeISO
        localizedName(localeId: $localeId)
      }
      companyCategory {
        id
        localizedName(localeId: $localeId)
      }
      tradeName
      representativeUser {
        id
        username
      }
      postCountry {
        id
        localizedName(localeId: $localeId)
      }
    }
    ... on HiddenCompany {
      id
      country {
        id
        codeISO
        localizedName(localeId: $localeId)
      }
      companyCategory {
        id
        localizedName(localeId: $localeId)
      }
      representativeUser {
        id
        username
      }
    }
  }
  filters(onlyVisible: false) {
    ... on FacetListFilterSelection {
      key
      byName(localeId: $localeId)
    }
    ... on FacetRangeFilterSelection {
      key
      minValue
      maxValue
    }
    ... on FacetBooleanFilterSelection {
      key
      value
    }
    ... on FacetComplexFilterSelection {
      key
      childrenInputs {
        ... on FacetBooleanFilterSelection {
          key
          value
        }
        ... on FacetListFilterSelection {
          key
          byName(localeId: $localeId)
        }
        ... on FacetRangeFilterSelection {
          key
          minValue
          maxValue
        }
      }
    }
  }
  matches {
    count
    companyCount
    tradeNamePotentialCount
    tradeNamePotentialNewsletterSendedCount
    companyHasOfferCount
    companyRegisteredCount
  }
}
    `;
export const ImageSearchResultProductInfoFragmentDoc = gql`
    fragment imageSearchResultProductInfo on Product {
  id
  acceptability
  wine {
    country {
      id
      codeISO
      localizedName(localeId: $localeId)
      codeISO
    }
    tradeNameBrand
    brand {
      id
      name
      localizedName(localeId: $localeId)
    }
    keyWords
    tradeName {
      tradeName
      id
    }
    wineType {
      localizedName(localeId: $localeId)
      id
    }
    wineColor {
      localizedName(localeId: $localeId)
      id
    }
    sweetnessLevel {
      localizedName(localeId: $localeId)
      id
    }
    specialClassifications {
      id
      name
    }
    vintage
    vintageList {
      id
      vintage
      acceptability
    }
    nationalGIType {
      id
      localizedName(localeId: $localeId)
      localizedShortName(localeId: $localeId)
    }
    nationalGITypeText
    geoIndicationText
    geoIndications {
      id
      localizedName(localeId: $localeId)
      wineRegionsEU {
        localizedName(localeId: $localeId)
      }
    }
  }
  bottleSize {
    id
    volumeML
  }
  packageType {
    id
    isDefault
    shortName
    localizedName(localeId: $localeId)
  }
  bottleImage {
    imageUrl
  }
  backLabelImage {
    imageUrl
  }
  frontLabelImage {
    imageUrl
  }
}
    `;
export const ImageSearchResultFragmentDoc = gql`
    fragment imageSearchResult on ProductImageSearch {
  googleResponse {
    product {
      ...imageSearchResultProductInfo
    }
    score
  }
  tineyeResponse {
    product {
      ...imageSearchResultProductInfo
    }
    score
  }
  productImage {
    product {
      ...imageSearchResultProductInfo
    }
  }
}
    ${ImageSearchResultProductInfoFragmentDoc}`;
export const WineReviewsAdministrationItemFragmentDoc = gql`
    fragment wineReviewsAdministrationItem on WineReview {
  id
  isBottleDefected
  drinkingWindowFrom
  drinkingWindowTo
  review
  date
  score
  recognitionResult {
    ... on ProductRecognitionError {
      reason
    }
    ... on ProductRecognitionNeedMoreInfo {
      unknownAttributes
    }
    ... on ProductRecognised {
      incorrectRecognition {
        comment
        product {
          id
        }
      }
    }
  }
  backLabelImage {
    imageUrl
    thumbnailUrl: imageUrl(transformations: {width: 170, height: 170, resize: fit})
    thumbnailRetinaUrl: imageUrl(
      transformations: {width: 340, height: 340, resize: fit}
    )
  }
  frontLabelImage {
    imageUrl
    thumbnailUrl: imageUrl(transformations: {width: 170, height: 170, resize: fit})
    thumbnailRetinaUrl: imageUrl(
      transformations: {width: 340, height: 340, resize: fit}
    )
  }
  bottleImage {
    originUrl
    imageUrl
    thumbnailUrl: imageUrl(transformations: {width: 170, height: 170, resize: fit})
    thumbnailRetinaUrl: imageUrl(
      transformations: {width: 340, height: 340, resize: fit}
    )
  }
  scoreSystem {
    localizedName(localeId: $localeId)
    minScore
    maxScore
  }
  authorUser {
    id
    username
    name
    lastName
  }
  owner {
    ... on User {
      id
      username
      role {
        id
        code
      }
    }
    ... on Company {
      id
      tradeName
      representativeUser {
        id
        username
      }
    }
  }
  isDraft
  status
  createdAt
  type
  blockedAt
  product {
    id
    slug
    bottleSize {
      id
      volumeML
    }
    packageType {
      id
      isDefault
      shortName
      localizedName(localeId: $localeId)
    }
    wine {
      id
      rating
      tradeNameBrand
      chemicalAnalysis {
        alcoholVolume
      }
      grapeVarieties {
        grapeVarietySynonym {
          id
          name
        }
        value
      }
      nationalGIType {
        id
        localizedName(localeId: $localeId)
        euGIType {
          id
          masterName
        }
      }
      nationalGITypeText
      geoIndications {
        id
        localizedName(localeId: $localeId)
        wineRegionsEU {
          id
          localizedName(localeId: $localeId)
        }
      }
      geoIndicationText
      vintage
      country {
        id
        localizedName(localeId: $localeId)
        codeISO
      }
      tradeName {
        acceptability
        tradeName
        country {
          id
          localizedName(localeId: $localeId)
          codeISO
        }
      }
      wineType {
        id
        isDefault
        localizedName(localeId: $localeId)
      }
      wineColor {
        id
        localizedName(localeId: $localeId)
      }
      brand {
        id
        name
      }
      sweetnessLevel {
        id
        isDefault
        localizedName(localeId: $localeId)
      }
      keyWords
      specialClassifications {
        id
        name
      }
    }
  }
}
    `;
export const AdminUsersListItemFragmentDoc = gql`
    fragment adminUsersListItem on User {
  id
  email
  name
  lastName
  username
  expertQualification {
    id
    localizedName(localeId: $localeId)
  }
  expertVerification {
    comment
    status {
      id
      code
      localizedName(localeId: $localeId)
    }
  }
  permissions {
    reviewsDisplayed
    reviewsConsidered
  }
  expertQualificationDocs
  status {
    id
    code
    localizedName(localeId: $localeId)
  }
  role {
    id
    code
    localizedName(localeId: $localeId)
  }
  blockedReason
  createdAt
  status {
    id
    code
  }
  statusUpdateUser {
    id
    username
  }
  statusComment
}
    `;
export const AdminCompaniesListItemFragmentDoc = gql`
    fragment adminCompaniesListItem on Company {
  id
  website
  verificationStatus {
    id
    code
    masterName
  }
  companyCategory {
    id
  }
  tradeName
  representativeUser {
    id
    email
    username
    name
    lastName
  }
  verificationStatusUpdatedAt
  verificationComment
  verificationUpdateUser {
    id
    username
  }
  productELabelSubscription {
    status
    quota
    usedQuota
    periodStart
    periodEnd
    interval
    currency {
      id
      code
    }
    price
  }
  productELabelCustomer {
    id
  }
  createdAt
}
    `;
export const MetadataFragmentDoc = gql`
    fragment Metadata on EntityDiffMetadata {
  timestamp
  priorityType {
    localizedName(localeId: $localeId)
    order
  }
  authorUser {
    username
    avatar {
      imageUrl
      thumbnailUrl: imageUrl(transformations: {width: 24, height: 24, resize: fill})
      thumbnailRetinaUrl: imageUrl(
        transformations: {width: 48, height: 48, resize: fill}
      )
    }
  }
  batchLoad
}
    `;
export const FullAcceptabilityDiffFragmentDoc = gql`
    fragment FullAcceptabilityDiff on AcceptabilityDiff {
  id
  canDelete
  lhs
  rhs
  current
  metadata {
    ...Metadata
  }
}
    ${MetadataFragmentDoc}`;
export const FullProductAcceptabilityDiffFragmentDoc = gql`
    fragment FullProductAcceptabilityDiff on ProductAcceptabilityDiff {
  product {
    id
    bottleSize {
      id
      volumeML
    }
    packageType {
      id
      isDefault
      shortName
      localizedName(localeId: $localeId)
    }
  }
  lhs
  rhs
  current
  metadata {
    ...Metadata
  }
}
    ${MetadataFragmentDoc}`;
export const FullProductDiffFragmentDoc = gql`
    fragment FullProductDiff on ProductDiff {
  id
  canDelete
  canMove
  product {
    bottleSize {
      id
      volumeML
    }
    packageType {
      id
      isDefault
      shortName
      localizedName(localeId: $localeId)
    }
  }
  bottleImage {
    lhs {
      imageUrl
      thumbnailUrl: imageUrl(transformations: {width: 48, height: 48, resize: fit})
      thumbnailRetinaUrl: imageUrl(
        transformations: {width: 96, height: 96, resize: fit}
      )
    }
    rhs {
      imageUrl
      thumbnailUrl: imageUrl(transformations: {width: 48, height: 48, resize: fit})
      thumbnailRetinaUrl: imageUrl(
        transformations: {width: 96, height: 96, resize: fit}
      )
    }
    current
  }
  frontLabelImage {
    lhs {
      imageUrl
      thumbnailUrl: imageUrl(transformations: {width: 48, height: 48, resize: fit})
      thumbnailRetinaUrl: imageUrl(
        transformations: {width: 96, height: 96, resize: fit}
      )
    }
    rhs {
      imageUrl
      thumbnailUrl: imageUrl(transformations: {width: 48, height: 48, resize: fit})
      thumbnailRetinaUrl: imageUrl(
        transformations: {width: 96, height: 96, resize: fit}
      )
    }
    current
  }
  backLabelImage {
    lhs {
      imageUrl
      thumbnailUrl: imageUrl(transformations: {width: 48, height: 48, resize: fit})
      thumbnailRetinaUrl: imageUrl(
        transformations: {width: 96, height: 96, resize: fit}
      )
    }
    rhs {
      imageUrl
      thumbnailUrl: imageUrl(transformations: {width: 48, height: 48, resize: fit})
      thumbnailRetinaUrl: imageUrl(
        transformations: {width: 96, height: 96, resize: fit}
      )
    }
    current
  }
  annualProduction {
    lhs {
      value
      unit {
        localizedName(localeId: $localeId)
      }
    }
    rhs {
      value
      unit {
        localizedName(localeId: $localeId)
      }
    }
    current
  }
  cellarDoorPriceLink {
    lhs
    rhs
    current
  }
  barcode {
    lhs
    rhs
    current
  }
  gtin {
    lhs
    rhs
    current
  }
  priceAtWinery {
    lhs {
      currency {
        code
      }
      price
    }
    rhs {
      currency {
        code
      }
      price
    }
    current
  }
  alcoholOnLabel {
    lhs
    rhs
    current
  }
  bottlingDate {
    lhs
    rhs
    current
  }
  closureType {
    lhs {
      localizedName(localeId: $localeId)
    }
    rhs {
      localizedName(localeId: $localeId)
    }
    current
  }
  metadata {
    ...Metadata
  }
}
    ${MetadataFragmentDoc}`;
export const FullTechSheetAcceptabilityDiffFragmentDoc = gql`
    fragment FullTechSheetAcceptabilityDiff on TechSheetAcceptabilityDiff {
  techSheet {
    locale {
      locale
      name
    }
  }
  lhs
  rhs
  current
  metadata {
    ...Metadata
  }
}
    ${MetadataFragmentDoc}`;
export const FullTechSheetDiffFragmentDoc = gql`
    fragment FullTechSheetDiff on TechSheetDiff {
  id
  canDelete
  canMove
  techSheet {
    locale {
      locale
      name
    }
  }
  viticulture {
    lhs
    rhs
    current
  }
  vinification {
    lhs
    rhs
    current
  }
  wineAging {
    lhs
    rhs
    current
  }
  vintageDetails {
    lhs
    rhs
    current
  }
  marketingNote {
    lhs
    rhs
    current
  }
  contactProducerTradeName {
    lhs
    rhs
    current
  }
  contactWebsite {
    lhs
    rhs
    current
  }
  contactPhone {
    lhs
    rhs
    current
  }
  contactEmail {
    lhs
    rhs
    current
  }
  tastingNote {
    lhs
    rhs
    current
  }
  metadata {
    ...Metadata
  }
}
    ${MetadataFragmentDoc}`;
export const FullWineAwardAcceptabilityDiffFragmentDoc = gql`
    fragment FullWineAwardAcceptabilityDiff on WineAwardAcceptabilityDiff {
  wineAward {
    award {
      masterName
    }
    minScore
    maxScore
    year
  }
  lhs
  rhs
  current
  metadata {
    ...Metadata
  }
}
    ${MetadataFragmentDoc}`;
export const AwardRewardFragmentDoc = gql`
    fragment awardReward on AwardReward {
  rewardType {
    localizedName(localeId: $localeId)
  }
  name
  minYear
  maxYear
  minScore
  maxScore
}
    `;
export const FullWineAwardDiffFragmentDoc = gql`
    fragment FullWineAwardDiff on WineAwardDiff {
  id
  canDelete
  canMove
  wineAward {
    id
    year
    award {
      masterName
    }
  }
  score {
    lhs {
      min
      max
    }
    rhs {
      min
      max
    }
    current
  }
  rewards {
    lhs {
      ...awardReward
    }
    rhs {
      ...awardReward
    }
    current
  }
  metadata {
    ...Metadata
  }
}
    ${AwardRewardFragmentDoc}
${MetadataFragmentDoc}`;
export const WineCertificationTypeLabelFragmentDoc = gql`
    fragment WineCertificationTypeLabel on WineCertification {
  isUsed
  certificationType {
    localizedName(localeId: $localeId)
  }
}
    `;
export const WineAllergenLabelFragmentDoc = gql`
    fragment WineAllergenLabel on WineAllergen {
  isUsed
  allergen {
    localizedName(localeId: $localeId)
  }
}
    `;
export const FullWineDiffFragmentDoc = gql`
    fragment FullWineDiff on WineDiff {
  id
  canDelete
  canMove
  certifications {
    lhs {
      ...WineCertificationTypeLabel
    }
    rhs {
      ...WineCertificationTypeLabel
    }
    current
  }
  allergens {
    lhs {
      ...WineAllergenLabel
    }
    rhs {
      ...WineAllergenLabel
    }
    current
  }
  foodPairs {
    lhs {
      masterName
    }
    rhs {
      masterName
    }
    current
  }
  pH {
    lhs
    rhs
    current
  }
  acidityTotal {
    lhs {
      value
    }
    rhs {
      value
    }
    current
  }
  residualSugar {
    lhs
    rhs
    current
  }
  grapeVarieties {
    lhs {
      value
      grapeVarietySynonym {
        name
      }
    }
    rhs {
      value
      grapeVarietySynonym {
        name
      }
    }
    current
  }
  servingTemperatureMin {
    lhs
    rhs
    current
  }
  servingTemperatureMax {
    lhs
    rhs
    current
  }
  metadata {
    ...Metadata
  }
}
    ${WineCertificationTypeLabelFragmentDoc}
${WineAllergenLabelFragmentDoc}
${MetadataFragmentDoc}`;
export const FullBrandDiffFragmentDoc = gql`
    fragment FullBrandDiff on BrandDiff {
  id
  canDelete
  canMove
  translations {
    lhs {
      name
      locale {
        locale
      }
    }
    rhs {
      name
      locale {
        locale
      }
    }
    current
  }
  metadata {
    ...Metadata
  }
}
    ${MetadataFragmentDoc}`;
export const FullTradeNameAcceptabilityDiffFragmentDoc = gql`
    fragment FullTradeNameAcceptabilityDiff on AcceptabilityDiff {
  id
  canDelete
  lhs
  rhs
  current
  metadata {
    ...Metadata
  }
}
    ${MetadataFragmentDoc}`;
export const FullTradeNameDiffFragmentDoc = gql`
    fragment FullTradeNameDiff on TradeNameDiff {
  id
  canDelete
  canMove
  logo {
    lhs {
      imageUrl
    }
    rhs {
      imageUrl
    }
    current
  }
  website {
    lhs
    rhs
    current
  }
  postAddress {
    lhs
    rhs
    current
  }
  postCode {
    lhs
    rhs
    current
  }
  email {
    lhs
    rhs
    current
  }
  phone {
    lhs
    rhs
    current
  }
  legalEntity {
    identityNumber {
      lhs
      rhs
      current
    }
    branchNumber {
      lhs
      rhs
      current
    }
    legalEntityForm {
      lhs {
        originName
      }
      rhs {
        originName
      }
      current
    }
    legalAddress {
      lhs
      rhs
      current
    }
    activityCode {
      lhs
      rhs
      current
    }
    legalName {
      lhs
      rhs
      current
    }
  }
  translations {
    lhs {
      locale {
        locale
      }
      tradeName
    }
    rhs {
      locale {
        locale
      }
      tradeName
    }
    current
  }
  metadata {
    ...Metadata
  }
}
    ${MetadataFragmentDoc}`;
export const WineDatabaseItemFragmentFragmentDoc = gql`
    fragment WineDatabaseItemFragment on Wine {
  id
  acceptability
  verified
  shouldOverrideAcceptability
  canOverrideAcceptability
  keyWords
  tradeName {
    id
    tradeName
    acceptability
  }
  tradeNameBrand
  brand {
    id
    name
    acceptability
  }
  specialClassifications {
    name
  }
  wineType {
    isDefault
    localizedName(localeId: $localeId)
  }
  wineColor {
    localizedName(localeId: $localeId)
  }
  sweetnessLevel {
    localizedName(localeId: $localeId)
    isDefault
  }
  vintage
  country {
    id
    localizedName(localeId: $localeId)
    codeISO
  }
  geoIndicationText
  geoIndications {
    localizedName(localeId: $localeId)
  }
  nationalGITypeText
  nationalGIType {
    id
    localizedShortName(localeId: $localeId)
  }
  grapeVarieties {
    value
    grapeVarietySynonym {
      name
    }
  }
  products {
    id
    acceptability
    verified
    slug
    bottleSize {
      id
      volumeML
    }
    packageType {
      id
      isDefault
      shortName
      localizedName(localeId: $localeId)
    }
  }
  authorUser {
    username
  }
  sourceAuthorUser {
    username
  }
  updatedAt
  source {
    id
    description
    name
    sourceType {
      code
    }
  }
}
    `;
export const SellWineFullWineOfferDistFragmentDoc = gql`
    fragment SellWineFullWineOfferDist on WineOfferDistLimit {
  id
  country {
    id
    codeISO
    localizedName(localeId: $localeId)
  }
  isIncluded
  address
  territory {
    latitude
    longitude
    radius
    radiusUnit {
      masterName
      id
    }
  }
}
    `;
export const MyWinesImageWithThumbnail48FragmentDoc = gql`
    fragment myWinesImageWithThumbnail48 on Image {
  imageUrl
  thumbnail48Url: imageUrl(transformations: {width: 48, height: 48, resize: fit})
  thumbnailUrl: imageUrl(transformations: {width: 232, height: 232, resize: fit})
  thumbnailRetinaUrl: imageUrl(
    transformations: {width: 464, height: 464, resize: fit}
  )
}
    `;
export const MyWinesWineDataFragmentDoc = gql`
    fragment MyWinesWineData on Wine {
  id
  tradeNameBrand
  keyWords
  rating
  brand {
    id
    name
    acceptability
  }
  specialClassifications {
    id
    name
  }
  products {
    id
    acceptability
    shouldOverrideAcceptability
    canOverrideAcceptability
    verified
    bottleSize {
      id
      volumeML
    }
    packageType {
      id
      isDefault
      shortName
      localizedName(localeId: $localeId)
    }
  }
  wineType {
    id
    isDefault
    localizedName(localeId: $localeId)
  }
  wineColor {
    id
    localizedName(localeId: $localeId)
  }
  sweetnessLevel {
    id
    localizedName(localeId: $localeId)
    isDefault
  }
  vintage
  country {
    id
    localizedName(localeId: $localeId)
    codeISO
  }
  geoIndicationText
  geoIndications {
    id
    localizedName(localeId: $localeId)
  }
  nationalGITypeText
  nationalGIType {
    id
    localizedShortName(localeId: $localeId)
  }
  techSheets {
    id
    locale {
      id
    }
  }
  authorUser {
    id
    username
  }
  updatedAt
  sources {
    id
  }
  sourceAuthorUser {
    id
    username
  }
  canOverrideFacts
  verified
  canOverrideAcceptability
  acceptability
}
    `;
export const OfferCountPerCategoryFragmentDoc = gql`
    fragment OfferCountPerCategory on WineOfferCountPerCategory {
  wholesaler
  restaurant
  shop
}
    `;
export const WineOffersFragmentDoc = gql`
    fragment WineOffers on Product {
  publicCount: wineOffers(
    isBuyer: false
    companyId: $companyId
    filter: {wineOfferTypeIds: [1], wineOfferStatusIds: $activeOfferStatusIds}
  ) {
    count
  }
  forCompanyCount: wineOffers(
    isBuyer: false
    companyId: $companyId
    filter: {wineOfferTypeIds: [3], wineOfferStatusIds: $activeOfferStatusIds}
  ) {
    count
  }
  offerMarkets: wineOffers(
    isBuyer: false
    companyId: $companyId
    filter: {wineOfferStatusIds: $activeOfferStatusIds, wineOfferTypeIds: [1, 3]}
  ) {
    markets {
      country {
        id
        localizedName(localeId: $localeId)
        codeISO
      }
      publicOffersCount {
        ...OfferCountPerCategory
      }
      companyOffersCount {
        ...OfferCountPerCategory
      }
    }
  }
}
    ${OfferCountPerCategoryFragmentDoc}`;
export const MyWinesProductFragmentDoc = gql`
    fragment MyWinesProduct on Product {
  __typename
  id
  acceptability
  verified
  shouldOverrideAcceptability
  canOverrideAcceptability
  canOverrideFacts
  shortlisted(companyId: $companyId)
  fillPercent
  slug
  bottleSize {
    id
    volumeML
  }
  packageType {
    id
    isDefault
    shortName
    localizedName(localeId: $localeId)
  }
  bottleImage {
    ...myWinesImageWithThumbnail48
    src: imageUrl(transformations: {width: 48, height: 48, resize: fit})
    retinaSrc: imageUrl(transformations: {width: 96, height: 96, resize: fit})
  }
  frontLabelImage {
    ...myWinesImageWithThumbnail48
    src: imageUrl(transformations: {width: 48, height: 48, resize: fit})
    retinaSrc: imageUrl(transformations: {width: 96, height: 96, resize: fit})
  }
  backLabelImage {
    ...myWinesImageWithThumbnail48
    src: imageUrl(transformations: {width: 48, height: 48, resize: fit})
    retinaSrc: imageUrl(transformations: {width: 96, height: 96, resize: fit})
  }
  recommendedRetailPrice
  currency {
    id
    code
  }
  appellationRatings {
    rank
    count
    geoIndication {
      id
      localizedName(localeId: $localeId)
    }
  }
  wine {
    ...MyWinesWineData
  }
  ...WineOffers
}
    ${MyWinesImageWithThumbnail48FragmentDoc}
${MyWinesWineDataFragmentDoc}
${WineOffersFragmentDoc}`;
export const MyWinesWineImageForUploadDialogFragmentDoc = gql`
    fragment MyWinesWineImageForUploadDialog on Image {
  thumbnailUrl: imageUrl(transformations: {width: 125, height: 125, resize: fit})
  thumbnailRetinaUrl: imageUrl(
    transformations: {width: 250, height: 250, resize: fit}
  )
}
    `;
export const MyWinesWineImageForPreviewFragmentDoc = gql`
    fragment MyWinesWineImageForPreview on Image {
  thumbnailUrl: imageUrl(transformations: {width: 232, height: 232, resize: fit})
  thumbnailRetinaUrl: imageUrl(
    transformations: {width: 464, height: 464, resize: fit}
  )
}
    `;
export const MyWinesWinesProductFragmentDoc = gql`
    fragment MyWinesWinesProduct on Product {
  __typename
  id
  acceptability
  verified
  shouldOverrideAcceptability
  canOverrideAcceptability
  canOverrideFacts
  shortlisted(companyId: $companyId)
  fillPercent
  slug
  bottleSize {
    id
    volumeML
  }
  writePolicy {
    bottleImage
    frontLabelImage
    backLabelImage
  }
  packageType {
    id
    isDefault
    shortName
    localizedName(localeId: $localeId)
  }
  bottleImage {
    ...imageThumbnail48
  }
  frontLabelImage {
    ...imageThumbnail48
  }
  backLabelImage {
    ...imageThumbnail48
  }
  bottleImageForUploadDialog: bottleImage {
    ...MyWinesWineImageForUploadDialog
  }
  frontLabelImageForUploadDialog: frontLabelImage {
    ...MyWinesWineImageForUploadDialog
  }
  backLabelImageForUploadDialog: backLabelImage {
    ...MyWinesWineImageForUploadDialog
  }
  bottleImageForPreview: bottleImage {
    ...MyWinesWineImageForPreview
  }
  frontLabelImageForPreview: frontLabelImage {
    ...MyWinesWineImageForPreview
  }
  backLabelImageForPreview: backLabelImage {
    ...MyWinesWineImageForPreview
  }
  recommendedRetailPrice
  currency {
    id
    code
  }
  appellationRatings {
    rank
    count
    geoIndication {
      id
      localizedName(localeId: $localeId)
    }
  }
  wine {
    ...MyWinesWineData
  }
  ...WineOffers
}
    ${ImageThumbnail48FragmentDoc}
${MyWinesWineImageForUploadDialogFragmentDoc}
${MyWinesWineImageForPreviewFragmentDoc}
${MyWinesWineDataFragmentDoc}
${WineOffersFragmentDoc}`;
export const MyWinesWineFragmentDoc = gql`
    fragment MyWinesWine on Wine {
  ...MyWinesWineData
  acceptability
  verified
  shouldOverrideAcceptability
  canOverrideAcceptability
  fillPercent
}
    ${MyWinesWineDataFragmentDoc}`;
export const RequestFiltersFragmentDoc = gql`
    fragment requestFilters on WineOfferRequest {
  filters {
    ... on FacetListFilterSelection {
      key
      byName(localeId: $localeId)
    }
    ... on FacetRangeFilterSelection {
      key
      minValue
      maxValue
    }
    ... on FacetBooleanFilterSelection {
      key
      value
    }
    ... on FacetComplexFilterSelection {
      key
      childrenInputs {
        ... on FacetBooleanFilterSelection {
          key
          value
        }
        ... on FacetListFilterSelection {
          key
          byName(localeId: $localeId)
        }
        ... on FacetRangeFilterSelection {
          key
          minValue
          maxValue
        }
      }
    }
  }
}
    `;
export const WineOfferRequestProductListImageForPreviewFragmentDoc = gql`
    fragment wineOfferRequestProductListImageForPreview on Image {
  thumbnailUrl: imageUrl(transformations: {width: 232, height: 232, resize: fit})
  thumbnailRetinaUrl: imageUrl(
    transformations: {width: 464, height: 464, resize: fit}
  )
}
    `;
export const WineOfferRequestProductListImageForUploadDialogFragmentDoc = gql`
    fragment wineOfferRequestProductListImageForUploadDialog on Image {
  thumbnailUrl: imageUrl(transformations: {width: 125, height: 125, resize: fit})
  thumbnailRetinaUrl: imageUrl(
    transformations: {width: 250, height: 250, resize: fit}
  )
}
    `;
export const SellWineRequestFullWineOfferDistFragmentDoc = gql`
    fragment SellWineRequestFullWineOfferDist on WineOfferDistLimit {
  id
  country {
    id
    codeISO
    localizedName(localeId: $localeId)
  }
  isIncluded
  address
  territory {
    latitude
    longitude
    radius
    radiusUnit {
      id
      masterName
    }
  }
}
    `;
export const TechSheetFieldsFragmentDoc = gql`
    fragment techSheetFields on TechSheet {
  id
  locale {
    id
    locale
  }
  contactProducerTradeName
  contactWebsite
  contactPhone
  contactEmail
  viticulture
  vinification
  wineAging
  vintageDetails
  tastingNote
  marketingNote
  isWwlScoreUsed
  awards {
    isUsed
    wineAward {
      id
      award {
        id
        masterName
        masterNameShort
      }
      year
      maxScore
      minScore
      rewards {
        id
        name
        shortName
        rewardType {
          id
          masterName
        }
      }
    }
  }
}
    `;
export const TechSheetProductFieldsFragmentDoc = gql`
    fragment techSheetProductFields on Product {
  id
  shortUrl
  slug
  bottleImage {
    imageUrl
    thumbnailUrl: imageUrl(transformations: {width: 512, height: 512, resize: fit})
    thumbnailRetinaUrl: imageUrl(
      transformations: {width: 1024, height: 1024, resize: fit}
    )
  }
  bottleImagePreview: bottleImage {
    thumbnailUrl: imageUrl(transformations: {width: 108, height: 302, resize: fit})
    thumbnailRetinaUrl: imageUrl(
      transformations: {width: 215, height: 603, resize: fit}
    )
  }
  closureType {
    id
    localizedName(localeId: $localeId)
  }
  bottlingDate
  annualProduction {
    value
    unit {
      id
      masterName
      localizedName(localeId: $localeId)
    }
  }
  bottleSize {
    id
    volumeML
  }
  packageType {
    id
    isDefault
    shortName
    localizedName(localeId: $localeId)
  }
}
    `;
export const TechSheetWineFieldsFragmentDoc = gql`
    fragment techSheetWineFields on Wine {
  country {
    id
    localizedName(localeId: $localeId)
    codeISO
  }
  tradeName {
    id
    tradeName
    website
    phone
    email
    logo {
      imageUrl
      thumbnailUrl: imageUrl(transformations: {width: 65, height: 65, resize: fit})
      thumbnailRetinaUrl: imageUrl(
        transformations: {width: 130, height: 130, resize: fit}
      )
    }
    logoPreview: logo {
      thumbnailUrl: imageUrl(transformations: {width: 104, height: 104, resize: fit})
      thumbnailRetinaUrl: imageUrl(
        transformations: {width: 208, height: 208, resize: fit}
      )
    }
  }
  tradeNameBrand
  brand {
    id
    localizedName(localeId: $localeId)
    name
  }
  keyWords
  specialClassifications {
    id
    name
  }
  vintage
  wineColor {
    id
    localizedName(localeId: $localeId)
  }
  wineType {
    id
    isDefault
    localizedName(localeId: $localeId)
  }
  sweetnessLevel {
    id
    isDefault
    localizedName(localeId: $localeId)
  }
  rating
  nationalGITypeText
  nationalGIType {
    id
    localizedShortName(localeId: $localeId)
  }
  geoIndicationText
  geoIndications {
    id
    localizedName(localeId: $localeId)
    wineRegionEU {
      id
      localizedName(localeId: $localeId)
    }
    wineRegionsEU {
      id
      localizedName(localeId: $localeId)
    }
  }
  grapeVarieties {
    grapeVarietySynonym {
      id
      name
    }
    value
  }
  servingTemperatureMax
  servingTemperatureMin
  foodPairs {
    id
    localizedName(localeId: $localeId)
  }
  chemicalAnalysis {
    id
    acidityTotal {
      value
      unit {
        id
        masterName
      }
    }
    pH
    residualSugar
    alcoholVolume
  }
  ingredients {
    id
    localizedName(localeId: $localeId)
    code
    isAllergen
    code
    category {
      id
      localizedName(localeId: $localeId)
      isPrintable
    }
  }
  certs {
    id
    localizedName(localeId: $localeId)
  }
}
    `;
export const ImageThumbnail64FragmentDoc = gql`
    fragment imageThumbnail64 on Image {
  thumbnailUrl: imageUrl(transformations: {width: 64, height: 64, resize: fit})
  thumbnailRetinaUrl: imageUrl(
    transformations: {width: 128, height: 128, resize: fit}
  )
}
    `;
export const CompanyContactsDataFragmentDoc = gql`
    fragment companyContactsData on Company {
  id
  companyCategory {
    id
    localizedName(localeId: $localeId)
  }
  country {
    id
    localizedName(localeId: $localeId)
    codeISO
    identityNumberName
    branchNumberName
    vatName
    legalEntityDiscriminator
  }
  avatar {
    ...imageThumbnail64
  }
  tradeName
  legalEntity {
    id
    identityNumber
    branchNumber
    vat
    isActive
    closingDate
    legalEntityForm {
      id
      originName
      shortName
    }
    activityCode
    legalName
    legalAddress
  }
  website
  postCode
  postAddress
  email
  phone
  representativeUser {
    id
    name
    lastName
  }
}
    ${ImageThumbnail64FragmentDoc}`;
export const WineOfferContactsFragmentDoc = gql`
    fragment wineOfferContacts on WineOffer {
  ... on WineOfferPersonal {
    contactEmail
    contactPhone
    sellerCompany {
      id
    }
  }
  ... on WineOfferCompany {
    contactEmail
    contactPhone
    sellerCompany {
      id
    }
  }
  ... on WineOfferPublic {
    contactEmail
    contactPhone
    sellerCompany {
      id
    }
  }
}
    `;
export const AddELabelProductFragmentDoc = gql`
    fragment AddELabelProduct on Product {
  __typename
  id
  gtin
  shortUrl
  bottleSize {
    id
    volumeML
  }
  packageType {
    id
    isDefault
    shortName
    localizedName(localeId: $localeId)
  }
  bottleImage {
    thumbnailUrl: imageUrl(transformations: {width: 288, height: 288, resize: fit})
    thumbnailRetinaUrl: imageUrl(
      transformations: {width: 564, height: 564, resize: fit}
    )
  }
  bottleImageInList: bottleImage {
    ...imageThumbnail48
  }
  bottleImagePreview: bottleImage {
    thumbnailUrl: imageUrl(transformations: {width: 295, height: 295, resize: fit})
    thumbnailRetinaUrl: imageUrl(
      transformations: {width: 590, height: 590, resize: fit}
    )
  }
  wine {
    id
    wineType {
      id
      isDefault
      localizedName(localeId: $localeId)
    }
    wineTypeEU {
      id
      isDefault
      localizedName(localeId: $localeId)
    }
    wineColor {
      id
      localizedName(localeId: $localeId)
    }
    sweetnessLevel {
      id
      isDefault
      localizedName(localeId: $localeId)
    }
    chemicalAnalysis {
      id
      alcoholVolume
    }
    country {
      id
      localizedName(localeId: $localeId)
      codeISO
    }
    tradeName {
      id
      tradeName
      logo {
        imageUrl
        thumbnailUrl: imageUrl(transformations: {width: 128, height: 128, resize: fit})
        thumbnailRetinaUrl: imageUrl(
          transformations: {width: 256, height: 256, resize: fit}
        )
      }
      country {
        id
        codeISO
        localizedName(localeId: $localeId)
      }
    }
    nationalGIType {
      id
      localizedName(localeId: $localeId)
      localizedShortName(localeId: $localeId)
    }
    nationalGITypeText
    geoIndications {
      id
      localizedName(localeId: $localeId)
    }
    geoIndicationText
    vintage
    keyWords
    brand {
      id
      name
      localizedName(localeId: $localeId)
    }
    tradeNameBrand
    specialClassifications {
      id
      name
    }
    ingredients {
      id
      localizedName(localeId: $localeId)
      isAllergen
      code
      category {
        id
        localizedName(localeId: $localeId)
        isPrintable
      }
    }
    nutritionDeclaration {
      carbohydrate
      carbohydrateApproximate
      energyKCal
      energyKCalApproximate
      energyKJ
      fat
      fatApproximate
      protein
      proteinApproximate
      salt
      saltApproximate
      saturates
      saturatesApproximate
      sugars
      sugarsApproximate
    }
    certs {
      id
      localizedName(localeId: $localeId)
      logo
    }
    responsibleConsumptions {
      id
      localizedName(localeId: $localeId)
      logo
    }
    grapeVarieties {
      value
      grapeVarietySynonym {
        id
        name
      }
    }
  }
  slug
}
    ${ImageThumbnail48FragmentDoc}`;
export const WineSpecsFragmentDoc = gql`
    fragment wineSpecs on Wine {
  id
  wineType {
    id
    isDefault
    localizedName(localeId: $localeId)
  }
  wineColor {
    id
    localizedName(localeId: $localeId)
  }
  sweetnessLevel {
    id
    isDefault
    localizedName(localeId: $localeId)
  }
  vintage
  country {
    id
    codeISO
    localizedName(localeId: $localeId)
  }
}
    `;
export const ProductSpecsFragmentDoc = gql`
    fragment productSpecs on Product {
  id
  bottleSize {
    id
    volumeML
  }
  packageType {
    id
    isDefault
    shortName
    localizedName(localeId: $localeId)
  }
  wine {
    ...wineSpecs
  }
}
    ${WineSpecsFragmentDoc}`;
export const WineTitleTradeNameBrandFragmentDoc = gql`
    fragment wineTitleTradeNameBrand on Wine {
  tradeNameBrand
  tradeName {
    id
    tradeName
  }
  keyWords
  specialClassifications {
    id
    name
  }
  vintage
}
    `;
export const SearchModalWineFragmentDoc = gql`
    fragment searchModalWine on Wine {
  id
  ...wineSpecs
  ...wineTitleTradeNameBrand
  tradeName {
    country {
      id
      localizedName(localeId: $localeId)
      codeISO
    }
  }
  country {
    id
    localizedName(localeId: $localeId)
    codeISO
  }
  nationalGITypeText
  nationalGIType {
    id
    localizedShortName(localeId: $localeId)
    localizedName(localeId: $localeId)
  }
  geoIndicationText
  geoIndications {
    id
    localizedName(localeId: $localeId)
  }
  brand {
    id
    name
    localizedName(localeId: $localeId)
  }
}
    ${WineSpecsFragmentDoc}
${WineTitleTradeNameBrandFragmentDoc}`;
export const SearchModalProductFragmentDoc = gql`
    fragment searchModalProduct on Product {
  id
  ...productSpecs
  bottleImage {
    ...imageThumbnail48
  }
  frontLabelImage {
    ...imageThumbnail48
  }
  backLabelImage {
    ...imageThumbnail48
  }
  wine {
    ...searchModalWine
  }
}
    ${ProductSpecsFragmentDoc}
${ImageThumbnail48FragmentDoc}
${SearchModalWineFragmentDoc}`;
export const AdminProductImagesProductInfoFragmentDoc = gql`
    fragment adminProductImagesProductInfo on Product {
  id
  slug
  bottleSize {
    id
    volumeML
  }
  packageType {
    id
    isDefault
    shortName
    localizedName(localeId: $localeId)
  }
  bottleImage {
    ...imageThumbnail48
  }
  frontLabelImage {
    ...imageThumbnail48
  }
  backLabelImage {
    ...imageThumbnail48
  }
  wine {
    id
    tradeNameBrand
    keyWords
    specialClassifications {
      id
      name
    }
    vintage
    wineType {
      id
      isDefault
      localizedName(localeId: $localeId)
    }
    wineColor {
      id
      localizedName(localeId: $localeId)
    }
    sweetnessLevel {
      id
      isDefault
      localizedName(localeId: $localeId)
    }
    country {
      id
      codeISO
      localizedName(localeId: $localeId)
    }
    geoIndicationText
    geoIndications {
      id
      localizedName(localeId: $localeId)
    }
    nationalGITypeText
    nationalGIType {
      id
      localizedShortName(localeId: $localeId)
    }
    grapeVarieties {
      value
      grapeVarietySynonym {
        id
        name
      }
    }
  }
}
    ${ImageThumbnail48FragmentDoc}`;
export const AdminTradeOfferLotForPricesTableFragmentDoc = gql`
    fragment adminTradeOfferLotForPricesTable on TradeOfferLot {
  id
  price
  currency {
    id
    code
  }
  lot {
    id
    localizedName(localeId: $localeId)
  }
  deliveryTime {
    id
    localizedName(localeId: $localeId)
  }
  createdAt
  tradeOffer {
    id
    status
  }
  authorUser {
    username
  }
}
    `;
export const AdminTradeOfferListItemFragmentDoc = gql`
    fragment adminTradeOfferListItem on TradeOffer {
  type: __typename
  id
  status
  product {
    ...productSpecs
    id
    slug
    currency {
      code
      id
    }
    wine {
      id
      geoIndications {
        id
        localizedName(localeId: $localeId)
      }
      geoIndicationText
      nationalGIType {
        id
        localizedShortName(localeId: $localeId)
      }
      nationalGITypeText
      specialClassifications {
        id
        name
      }
      keyWords
      country {
        id
        localizedName(localeId: $localeId)
        codeISO
      }
      tradeNameBrand
      id
      brand {
        name
        acceptability
        popularity
      }
      tradeName {
        id
        tradeName
        country {
          id
          localizedName(localeId: $localeId)
          codeISO
        }
      }
    }
  }
  merchant {
    id
    name
    website
    country {
      id
      localizedName(localeId: $localeId)
    }
  }
  priceSource {
    id
    type
  }
  additionalId
  productURL
  isProductURLUnique
  updatedAt
  updateUser {
    id
    username
  }
}
    ${ProductSpecsFragmentDoc}`;
export const AdminTradeOfferLotListItemFragmentDoc = gql`
    fragment adminTradeOfferLotListItem on TradeOfferLot {
  type: __typename
  id
  price
  price
  convertedPrice: price(
    currencyCode: $currencyCodeToConvert
    wineLotId: $wineLotIdToConvert
  ) @include(if: $convertPrice)
  currency {
    id
    code
  }
  lot {
    id
    bottleQuantity
    localizedName(localeId: $localeId)
  }
  deliveryTime {
    id
    localizedName(localeId: $localeId)
  }
  createdAt
  tradeOffer {
    ...adminTradeOfferListItem
  }
}
    ${AdminTradeOfferListItemFragmentDoc}`;
export const ViewBrandTranslationItemFragmentDoc = gql`
    fragment viewBrandTranslationItem on BrandTranslation {
  locale {
    locale
    name
  }
  tradeMarks {
    id
    name
    country {
      localizedName(localeId: $localeId)
      codeISO
      id
    }
    holderName
    TIN
    status {
      id
      localizedName(localeId: $localeId)
    }
  }
  name
}
    `;
export const BuyWinePageOfProductAppellationRatingFragmentDoc = gql`
    fragment BuyWinePageOfProductAppellationRating on ProductsPagination {
  pageInfo {
    totalItems
    totalPages
    page
    perPage
    hasPreviousPage
    hasNextPage
  }
  items {
    id
    slug
    bottleSize {
      id
      volumeML
    }
    packageType {
      id
      isDefault
      shortName
      localizedName(localeId: $localeId)
    }
    recommendedRetailPrice
    currency {
      id
      code
      rate
    }
    appellationRating(geoIndicationId: $geoIndicationId) {
      rank
    }
    mainSynonym {
      id
    }
    bottleImage {
      imageUrl
      thumbnailUrl: imageUrl(transformations: {width: 48, height: 96, resize: fit})
      thumbnailRetinaUrl: imageUrl(
        transformations: {width: 96, height: 192, resize: fit}
      )
    }
    wine {
      id
      chemicalAnalysis {
        alcoholVolume
      }
      tradeNameBrand
      brand {
        id
        localizedName(localeId: $localeId)
        name
      }
      geoIndicationText
      tradeName {
        id
        tradeName
      }
      keyWords
      specialClassifications {
        id
        name
      }
      vintage
      grapeVarieties {
        value
        grapeVarietySynonym {
          id
          name
        }
      }
      wineType {
        id
        isDefault
        localizedName(localeId: $localeId)
      }
      wineColor {
        id
        localizedName(localeId: $localeId)
      }
      sweetnessLevel {
        id
        isDefault
        localizedName(localeId: $localeId)
      }
      country {
        id
        localizedName(localeId: $localeId)
      }
      nationalGIType {
        id
        localizedShortName(localeId: $localeId)
        localizedName(localeId: $localeId)
        euGIType {
          masterName
        }
      }
      geoIndications {
        id
        localizedName(localeId: $localeId)
        wineRegionsEU {
          localizedName(localeId: $localeId)
        }
      }
      nationalGITypeText
      rating
      certs {
        id
        localizedName(localeId: $localeId)
      }
    }
    wineOffers(
      isBuyer: true
      companyId: $companyId
      filter: {facetsInputs: $facetsInputs, wineOfferStatusIds: $wineOfferStatusIds, wineOfferTypeIds: $wineOfferTypeIds}
    ) {
      count
      priceCoefficientRange {
        min
        max
      }
      pricePerBottleRange {
        min {
          currency {
            code
          }
          price(currencyCode: $offerCurrencyCode)
        }
        max {
          currency {
            code
          }
          price(currencyCode: $offerCurrencyCode)
        }
      }
    }
  }
}
    `;
export const BuyWineFullWineOfferDistFragmentDoc = gql`
    fragment BuyWineFullWineOfferDist on WineOfferDistLimit {
  id
  country {
    id
    codeISO
    localizedName(localeId: $localeId)
  }
  isIncluded
  address
  territory {
    latitude
    longitude
    radius
    radiusUnit {
      id
      masterName
    }
  }
}
    `;
export const ImageThumbnail40FragmentDoc = gql`
    fragment imageThumbnail40 on Image {
  thumbnailUrl: imageUrl(transformations: {width: 40, height: 40, resize: fit})
  thumbnailRetinaUrl: imageUrl(
    transformations: {width: 80, height: 80, resize: fit}
  )
}
    `;
export const LastPersonalOfferFragmentDoc = gql`
    fragment lastPersonalOffer on WineOfferPersonal {
  id
  product {
    id
    slug
    mainSynonym {
      ... on Product {
        id
      }
    }
    bottleImage {
      ...imageThumbnail40
    }
    ...productSpecs
    cellarDoorPrice
    currency {
      id
      code
    }
    wine {
      id
      ...wineTitleTradeNameBrand
      rating
    }
  }
  price
  currency {
    id
    code
  }
  priceCoefficient
  pricePerBottle
  sellerCompany {
    id
    tradeName
    companyCategory {
      id
    }
  }
  buyerCompany: buyer(companyId: $companyId) {
    ... on Company {
      id
      tradeName
      companyCategory {
        id
      }
    }
    ... on HiddenCompany {
      id
      companyCategory {
        id
      }
    }
  }
  periodOfValidity
  price
  lot {
    id
    localizedName(localeId: $localeId)
    bottleQuantity
  }
  minimumDeliverySize
  incoterms {
    id
    code
  }
  warehouse {
    id
    country {
      id
      codeISO
    }
    address
  }
  baseWineOffer {
    ... on WineOfferBase {
      wineOfferDistLimits {
        id
        isIncluded
        address
      }
      pricePerBottle
      price
    }
  }
  buyerDeliverySize
  buyerIncoterms {
    id
    code
  }
  buyerPeriodOfValidity
  buyerPrice
  wineOfferStatus {
    id
    code
  }
}
    ${ImageThumbnail40FragmentDoc}
${ProductSpecsFragmentDoc}
${WineTitleTradeNameBrandFragmentDoc}`;
export const WineOfferCompanyPreviewFragmentDoc = gql`
    fragment wineOfferCompanyPreview on Company {
  id
  tradeName
  email
  phone
  companyCategory {
    id
    localizedName(localeId: $localeId)
  }
  avatar {
    ...imageThumbnail40
  }
}
    ${ImageThumbnail40FragmentDoc}`;
export const ImageThumbnail128FragmentDoc = gql`
    fragment imageThumbnail128 on Image {
  thumbnailUrl: imageUrl(transformations: {width: 128, height: 128, resize: fit})
  thumbnailRetinaUrl: imageUrl(
    transformations: {width: 256, height: 256, resize: fit}
  )
}
    `;
export const ExhibitionInfoFragmentDoc = gql`
    fragment exhibitionInfo on Exhibition {
  id
  name
  place
  country {
    id
    codeISO
  }
  dateStart
  dateEnd
}
    `;
export const ExhibitionsDisplayInfoFragmentDoc = gql`
    fragment exhibitionsDisplayInfo on WineOfferExhibition {
  exhibition {
    ...exhibitionInfo
  }
  exhibitionStand
}
    ${ExhibitionInfoFragmentDoc}`;
export const WineOfferBaseFragmentDoc = gql`
    fragment wineOfferBase on WineOfferBase {
  __typename
  id
  wineOfferType {
    id
    code
    localizedName(localeId: $localeId)
  }
  wineOfferStatus {
    id
    code
    localizedName(localeId: $localeId)
  }
  seller: sellerCompany {
    ...wineOfferCompanyPreview
  }
  note
  product {
    id
    slug
    wine {
      id
      vintage
      nationalGITypeText
      keyWords
      geoIndicationText
      rating
      acceptability
      country {
        localizedName(localeId: $localeId)
        codeISO
        id
      }
      wineType {
        localizedName(localeId: $localeId)
        isDefault
      }
      wineColor {
        localizedName(localeId: $localeId)
      }
      sweetnessLevel {
        localizedName(localeId: $localeId)
        isDefault
      }
      specialClassifications {
        name
      }
      tradeNameBrand
      nationalGIType {
        localizedShortName(localeId: $localeId)
      }
      geoIndications {
        localizedName(localeId: $localeId)
      }
    }
    bottleSize {
      volumeML
    }
    packageType {
      localizedName(localeId: $localeId)
      shortName
    }
    bottleImage {
      ...imageThumbnail128
    }
    cellarDoorPrice
    recommendedRetailPrice
    currency {
      code
    }
    shortUrl
  }
  price
  pricePerBottle
  currency {
    id
    code
  }
  priceCoefficient
  lot {
    id
    localizedName(localeId: $localeId)
    bottleQuantity
  }
  incoterms {
    id
    code
    localizedShortDescription(localeId: $localeId)
  }
  warehouse {
    id
    name
    country {
      id
      codeISO
      localizedName(localeId: $localeId)
      __typename
    }
    address
    territory {
      masterName
      __typename
    }
  }
  minimumDeliverySize
  periodOfValidity
  isExclusiveRight
  updatedAt
  createdAt
  shortlistedCount
  exhibitions {
    ...exhibitionsDisplayInfo
  }
  wineOfferDistLimits {
    id
    isIncluded
    address
    country {
      id
      localizedName(localeId: $localeId)
      codeISO
    }
    territory {
      latitude
      longitude
      radius
      radiusUnit {
        localizedName(localeId: $localeId)
      }
    }
  }
}
    ${WineOfferCompanyPreviewFragmentDoc}
${ImageThumbnail128FragmentDoc}
${ExhibitionsDisplayInfoFragmentDoc}`;
export const WineOfferBuyerInfoFragmentDoc = gql`
    fragment wineOfferBuyerInfo on CompanyInfo {
  ...wineOfferCompanyPreview
  ... on HiddenCompany {
    id
    companyCategory {
      localizedName(localeId: $localeId)
      id
    }
  }
}
    ${WineOfferCompanyPreviewFragmentDoc}`;
export const WineOfferCompanyFragmentDoc = gql`
    fragment wineOfferCompany on WineOfferCompany {
  CompanyBuyer: buyer(companyId: $companyId) {
    ...wineOfferBuyerInfo
  }
  buyerContactsRequested
  canBuyerGrantCompanyInfo
  canBuyerRevokeCompanyInfo
  contactPhone
  contactEmail
}
    ${WineOfferBuyerInfoFragmentDoc}`;
export const WineOfferHistoryFragmentDoc = gql`
    fragment wineOfferHistory on WineOfferPersonal {
  id
  baseWineOffer {
    ... on WineOfferCompany {
      id
      incoterms {
        code
      }
      pricePerBottle
      periodOfValidity
      price
      minimumDeliverySize
      warehouse {
        id
        country {
          codeISO
        }
        address
      }
      lot {
        bottleQuantity
      }
      currency {
        code
      }
    }
    ... on WineOfferPublic {
      id
      incoterms {
        code
      }
      pricePerBottle
      periodOfValidity
      price
      minimumDeliverySize
      warehouse {
        id
        country {
          codeISO
        }
        address
      }
      lot {
        bottleQuantity
      }
      currency {
        code
      }
    }
    ... on WineOfferPersonal {
      id
      incoterms {
        code
      }
      pricePerBottle
      periodOfValidity
      price
      minimumDeliverySize
      warehouse {
        id
        country {
          codeISO
        }
        address
      }
      lot {
        bottleQuantity
      }
      currency {
        code
      }
    }
  }
  wineOfferStatus {
    code
  }
  wineOfferType {
    code
  }
  createdAt
  updatedAt
  sellerCompany {
    id
    companyCategory {
      id
    }
    tradeName
    avatar {
      ...imageThumbnail40
    }
    representativeUser {
      name
      lastName
      username
    }
  }
  buyerCompany: buyer(companyId: $companyId) {
    ... on Company {
      id
      companyCategory {
        id
      }
      tradeName
      avatar {
        ...imageThumbnail40
      }
      representativeUser {
        name
        lastName
        username
      }
    }
    ... on HiddenCompany {
      id
      companyCategory {
        id
      }
      representativeUser {
        name
        lastName
        username
      }
    }
  }
  buyerPrice
  buyerPricePerBottle
  pricePerBottle
  price
  incoterms {
    code
  }
  currency {
    code
  }
  lot {
    localizedName(localeId: $localeId)
    bottleQuantity
  }
  buyerIncoterms {
    code
  }
  warehouse {
    id
    country {
      codeISO
    }
    address
  }
  minimumDeliverySize
  buyerDeliverySize
  periodOfValidity
  buyerPeriodOfValidity
  comment
}
    ${ImageThumbnail40FragmentDoc}`;
export const WineOfferPersonalFragmentFragmentDoc = gql`
    fragment WineOfferPersonalFragment on WineOfferPersonal {
  PersonalBuyer: buyer(companyId: $companyId) {
    ...wineOfferBuyerInfo
    ... on Company {
      id
      tradeName
      email
      phone
      companyCategory {
        id
        localizedName(localeId: $localeId)
      }
      avatar {
        ...imageThumbnail40
      }
    }
  }
  baseWineOffer {
    ... on WineOfferPersonal {
      id
      price
      periodOfValidity
      isExclusiveRight
      updatedAt
      createdAt
      priceCoefficient
      pricePerBottle
      minimumDeliverySize
      incoterms {
        id
        code
      }
      warehouse {
        id
        name
        country {
          id
          codeISO
        }
      }
      currency {
        id
        code
      }
    }
  }
  comment
  isSellerFault
  buyerPrice
  buyerPricePerBottle
  buyerCurrency {
    id
    code
  }
  buyerIncoterms {
    id
    localizedShortDescription(localeId: $localeId)
    code
  }
  buyerDeliverySize
  buyerPeriodOfValidity
  negotiationId
  buyerContactsRequested
  canBuyerGrantCompanyInfo
  canBuyerRevokeCompanyInfo
  contactPhone
  contactEmail
}
    ${WineOfferBuyerInfoFragmentDoc}
${ImageThumbnail40FragmentDoc}`;
export const WineOfferPublicFragmentDoc = gql`
    fragment wineOfferPublic on WineOfferPublic {
  contactPhone
  contactEmail
  PublicBuyer: buyerCompanyCategory {
    id
    localizedName(localeId: $localeId)
  }
}
    `;
export const ReviewSearchWineFragmentDoc = gql`
    fragment reviewSearchWine on Wine {
  __typename
  id
  country {
    id
    localizedName(localeId: $localeId)
    codeISO
  }
  grapeVarieties {
    grapeVarietySynonym {
      id
      name
    }
    value
  }
  products {
    id
    acceptability
    shouldOverrideAcceptability
    canOverrideAcceptability
    bottleSize {
      id
      volumeML
    }
    packageType {
      id
      isDefault
      shortName
      localizedName(localeId: $localeId)
    }
    bottleImage {
      ...imageThumbnail48
    }
  }
  rating
  nationalGIType {
    id
    localizedName(localeId: $localeId)
    localizedShortName(localeId: $localeId)
    euGIType {
      id
      masterName
    }
  }
  nationalGITypeText
  geoIndications {
    id
    localizedName(localeId: $localeId)
    wineRegionsEU {
      id
      localizedName(localeId: $localeId)
    }
  }
  geoIndicationText
  vintageList {
    id
    vintage
    acceptability
  }
  vintage
  country {
    id
  }
  tradeName {
    id
    tradeName
    country {
      id
      codeISO
      localizedName(localeId: $localeId)
    }
  }
  wineType {
    id
    isDefault
    localizedName(localeId: $localeId)
  }
  wineColor {
    id
    localizedName(localeId: $localeId)
  }
  brand {
    id
    name
  }
  tradeNameBrand
  sweetnessLevel {
    id
    isDefault
    localizedName(localeId: $localeId)
  }
  keyWords
  specialClassifications {
    id
    name
  }
  chemicalAnalysis {
    alcoholVolume
  }
}
    ${ImageThumbnail48FragmentDoc}`;
export const ImageThumbnail160FragmentDoc = gql`
    fragment imageThumbnail160 on Image {
  thumbnailUrl: imageUrl(transformations: {width: 160, height: 160, resize: fit})
  thumbnailRetinaUrl: imageUrl(
    transformations: {width: 320, height: 320, resize: fit}
  )
}
    `;
export const ReviewSearchProductFragmentDoc = gql`
    fragment reviewSearchProduct on Product {
  __typename
  id
  bottleSize {
    id
    volumeML
  }
  packageType {
    id
    isDefault
    shortName
    localizedName(localeId: $localeId)
  }
  slug
  wine {
    ...reviewSearchWine
  }
  bottleImage {
    ...imageThumbnail160
  }
  canOverrideFacts
  frontLabelImage {
    ...imageThumbnail160
  }
  backLabelImage {
    ...imageThumbnail160
  }
  alcoholOnLabel
}
    ${ReviewSearchWineFragmentDoc}
${ImageThumbnail160FragmentDoc}`;
export const AverageScoreByVintageItemFragmentDoc = gql`
    fragment averageScoreByVintageItem on WineReviewsAggregateByVintageScoreItem {
  vintage
  count
  avgScore
  minScore
  maxScore
}
    `;
export const GeoIndicationsByCountriesItemFragmentDoc = gql`
    fragment geoIndicationsByCountriesItem on WineReviewsAggregateByCountryGeoIndicationItem {
  count
  geoIndicationCount
  country {
    id
    codeISO
    localizedName(localeId: $localeId)
  }
}
    `;
export const GrapesCoverageItemFragmentDoc = gql`
    fragment grapesCoverageItem on WineReviewsAggregateGrapeVarietyCoverage {
  covered
  total
}
    `;
export const MostReviewedGrapesItemFragmentDoc = gql`
    fragment mostReviewedGrapesItem on WineReviewsAggregateByGrapeVarietyItem {
  count
  grapeVariety {
    id
    masterName
  }
}
    `;
export const ProducersCoverageByGeoIndicationItemFragmentDoc = gql`
    fragment producersCoverageByGeoIndicationItem on WineReviewsAggregateByGeoIndicationTradeNameItem {
  count
  tradeNameCount
  geoIndication {
    id
    localizedName(localeId: $localeId)
    wineTerritoryType {
      id
      country {
        id
        codeISO
      }
    }
  }
}
    `;
export const ReviewsByColorItemFragmentDoc = gql`
    fragment reviewsByColorItem on WineReviewsAggregateByWineColorItem {
  count
  coverage
  wineColor {
    id
    masterName
    localizedName(localeId: $localeId)
  }
}
    `;
export const ReviewsByCountryItemFragmentDoc = gql`
    fragment reviewsByCountryItem on WineReviewsAggregateByCountryItem {
  count
  coverage
  country {
    id
    codeISO
    localizedName(localeId: $localeId)
  }
}
    `;
export const ReviewsByPriceItemFragmentDoc = gql`
    fragment reviewsByPriceItem on WineReviewsAggregateByPriceRangeItem {
  count
  name
  lower
  upper
}
    `;
export const ReviewsBySweetnessItemFragmentDoc = gql`
    fragment reviewsBySweetnessItem on WineReviewsAggregateBySweetnessLevelItem {
  count
  coverage
  sweetnessLevel {
    id
    localizedName(localeId: $localeId)
    wineType {
      id
      masterName
      localizedName(localeId: $localeId)
    }
  }
}
    `;
export const TopRatedGeoIndicationsItemFragmentDoc = gql`
    fragment topRatedGeoIndicationsItem on WineReviewsAggregateByGeoIndicationItem {
  avgScore
  geoIndication {
    id
    localizedName(localeId: $localeId)
    wineTerritoryType {
      id
      localizedName(localeId: $localeId)
      country {
        id
        codeISO
      }
    }
  }
}
    `;
export const MostScoredReviewFragmentDoc = gql`
    fragment MostScoredReview on WineReview {
  id
  score
  scoreSystem {
    id
    maxScore
  }
  product {
    id
    slug
    bottleImage {
      ...imageThumbnail48
    }
    bottleSize {
      id
      volumeML
    }
    packageType {
      id
      isDefault
      shortName
      localizedName(localeId: $localeId)
    }
    wine {
      id
      tradeNameBrand
      keyWords
      specialClassifications {
        id
        name
      }
      vintage
      country {
        id
        codeISO
        localizedName(localeId: $localeId)
      }
      geoIndicationText
      geoIndications {
        id
        localizedName(localeId: $localeId)
        wineRegionsEU {
          id
          localizedName(localeId: $localeId)
        }
      }
      nationalGITypeText
      nationalGIType {
        id
        localizedShortName(localeId: $localeId)
      }
      wineType {
        id
        isDefault
        localizedName(localeId: $localeId)
      }
      wineColor {
        id
        localizedName(localeId: $localeId)
      }
      sweetnessLevel {
        id
        isDefault
        localizedName(localeId: $localeId)
      }
    }
  }
}
    ${ImageThumbnail48FragmentDoc}`;
export const TopReviewedGeoIndicationsItemFragmentDoc = gql`
    fragment topReviewedGeoIndicationsItem on WineReviewsAggregateByGeoIndicationItem {
  count
  geoIndication {
    id
    localizedName(localeId: $localeId)
    wineTerritoryType {
      id
      localizedName(localeId: $localeId)
      country {
        id
        codeISO
      }
    }
  }
}
    `;
export const MyReviewsImagesFragmentDoc = gql`
    fragment myReviewsImages on WineReview {
  frontLabelImage {
    ...imageThumbnail48
  }
  bottleImage {
    ...imageThumbnail48
  }
  backLabelImage {
    ...imageThumbnail48
  }
  frontLabelImageBig: frontLabelImage {
    ...imageThumbnail234
  }
  bottleImageBig: bottleImage {
    ...imageThumbnail234
  }
  backLabelImageBig: backLabelImage {
    ...imageThumbnail234
  }
  product {
    frontLabelImage {
      ...imageThumbnail48
    }
    bottleImage {
      ...imageThumbnail48
    }
    backLabelImage {
      ...imageThumbnail48
    }
    frontLabelImageBig: frontLabelImage {
      ...imageThumbnail234
    }
    bottleImageBig: bottleImage {
      ...imageThumbnail234
    }
    backLabelImageBig: backLabelImage {
      ...imageThumbnail234
    }
  }
}
    ${ImageThumbnail48FragmentDoc}
${ImageThumbnail234FragmentDoc}`;
export const OfferWarehousesPaginationFragmentFragmentDoc = gql`
    fragment OfferWarehousesPaginationFragment on WarehousesPagination {
  pageInfo {
    totalItems
    totalPages
    page
    perPage
  }
  items {
    id
    name
    address
    longitude
    latitude
    country {
      id
      codeISO
    }
  }
}
    `;
export const OfferForCopySellerFragmentDoc = gql`
    fragment offerForCopySeller on Company {
  id
  tradeName
  companyCategory {
    id
    masterName
    localizedName(localeId: $localeId)
  }
  email
  phone
}
    `;
export const OfferForCopyWineOfferPersonalFragmentDoc = gql`
    fragment offerForCopyWineOfferPersonal on WineOfferPersonal {
  PersonalBuyer: buyer(companyId: $companyId) {
    ... on Company {
      id
      tradeName
      email
      phone
      companyCategory {
        id
        localizedName(localeId: $localeId)
      }
      avatar {
        imageUrl(transformations: {width: 80, height: 80, resize: fit})
      }
    }
  }
  baseWineOffer {
    ... on WineOfferPersonal {
      id
      price
      periodOfValidity
      isExclusiveRight
      updatedAt
      createdAt
      priceCoefficient
      pricePerBottle
      minimumDeliverySize
      incoterms {
        id
        code
      }
      warehouse {
        id
        name
        country {
          id
          codeISO
        }
      }
      currency {
        id
        code
      }
    }
  }
  comment
  isSellerFault
  buyerPrice
  buyerPricePerBottle
  buyerCurrency {
    id
    code
  }
  buyerIncoterms {
    id
    localizedShortDescription(localeId: $localeId)
    code
  }
  buyerDeliverySize
  buyerPeriodOfValidity
  negotiationId
  buyerContactsRequested
  canBuyerGrantCompanyInfo
  canBuyerRevokeCompanyInfo
  contactPhone
  contactEmail
}
    `;
export const ProductForNewOfferFragmentDoc = gql`
    fragment productForNewOffer on Product {
  __typename
  id
  bottleSize {
    id
    volumeML
  }
  packageType {
    id
    isDefault
    shortName
    localizedName(localeId: $localeId)
  }
  wine {
    id
    chemicalAnalysis {
      alcoholVolume
    }
    grapeVarieties {
      grapeVarietySynonym {
        id
        name
      }
      value
    }
    nationalGIType {
      id
      localizedName(localeId: $localeId)
      localizedShortName(localeId: $localeId)
      euGIType {
        id
        masterName
      }
    }
    nationalGITypeText
    geoIndications {
      id
      localizedName(localeId: $localeId)
      wineRegionsEU {
        id
        localizedName(localeId: $localeId)
      }
    }
    geoIndicationText
    vintage
    country {
      id
      localizedName(localeId: $localeId)
      codeISO
    }
    tradeName {
      id
      tradeName
      country {
        id
        codeISO
        localizedName(localeId: $localeId)
      }
    }
    wineType {
      id
      isDefault
      localizedName(localeId: $localeId)
    }
    wineColor {
      id
      localizedName(localeId: $localeId)
    }
    brand {
      id
      name
    }
    tradeNameBrand
    sweetnessLevel {
      id
      isDefault
      localizedName(localeId: $localeId)
    }
    keyWords
    specialClassifications {
      id
      name
    }
    products {
      id
      acceptability
      shouldOverrideAcceptability
      canOverrideAcceptability
      verified
      bottleSize {
        id
        volumeML
      }
      packageType {
        id
        isDefault
        shortName
        localizedName(localeId: $localeId)
      }
    }
  }
  bottleImage {
    originUrl
    imageUrl
  }
}
    `;
export const AddNewOfferFullWineOfferDistFragmentDoc = gql`
    fragment AddNewOfferFullWineOfferDist on WineOfferDistLimit {
  id
  country {
    id
    codeISO
    localizedName(localeId: $localeId)
  }
  isIncluded
  address
  territory {
    latitude
    longitude
    radius
    radiusUnit {
      id
    }
  }
}
    `;
export const SellerFragmentFragmentDoc = gql`
    fragment SellerFragment on Company {
  id
  tradeName
  companyCategory {
    id
    masterName
    localizedName(localeId: $localeId)
  }
  email
  phone
}
    `;
export const UserNotificationsFragmentDoc = gql`
    fragment userNotifications on UserNotificationSettings {
  news
}
    `;
export const CompanyNotificationsFragmentDoc = gql`
    fragment companyNotifications on Company {
  notificationSettings {
    offerConditionChanged
    offerAccepted
    offerDeclined
    wineOfferRequestCreated
    marketing
    wineReviewPublished
  }
}
    `;
export const WinePageOfProductAppellationRatingFragmentDoc = gql`
    fragment winePageOfProductAppellationRating on ProductsPagination {
  pageInfo {
    totalItems
    totalPages
    page
    perPage
    hasPreviousPage
    hasNextPage
  }
  items {
    id
    slug
    bottleSize {
      id
      volumeML
    }
    packageType {
      id
      isDefault
      shortName
      localizedName(localeId: $localeId)
    }
    recommendedRetailPrice
    currency {
      id
      code
    }
    appellationRating(geoIndicationId: $geoIndicationId) {
      rank
    }
    wine {
      id
      tradeNameBrand
      tradeName {
        id
        tradeName
      }
      keyWords
      specialClassifications {
        id
        name
      }
      vintage
      wineType {
        id
        isDefault
        localizedName(localeId: $localeId)
      }
      wineColor {
        id
        localizedName(localeId: $localeId)
      }
      sweetnessLevel {
        id
        isDefault
        localizedName(localeId: $localeId)
      }
      country {
        id
        localizedName(localeId: $localeId)
      }
      nationalGIType {
        id
        localizedShortName(localeId: $localeId)
      }
      geoIndications {
        id
        localizedName(localeId: $localeId)
      }
      rating
    }
  }
}
    `;
export const ProductPageReviewFragmentDoc = gql`
    fragment productPageReview on WineReview {
  id
  images {
    altText
    thumbnailUrl: imageUrl(transformations: {width: 77, height: 77, resize: fit})
    thumbnailRetinaUrl: imageUrl(
      transformations: {width: 154, height: 154, resize: fit}
    )
  }
  authorUser {
    id
    avatar {
      imageUrl
    }
    username
    name
    lastName
    permissions {
      reviewsConsidered
    }
    expertQualifications {
      isVerified
      verification {
        status {
          id
          code
          localizedName(localeId: $localeId)
        }
      }
      qualification {
        id
        localizedName(localeId: $localeId)
      }
    }
  }
  owner {
    ... on User {
      role {
        id
        code
      }
    }
  }
  isBottleDefected
  createdAt
  updatedAt
  blockedAt
  score
  scoreSystem {
    id
    maxScore
  }
  review
  drinkingWindowFrom
  drinkingWindowTo
  foodPairs {
    id
    localizedName(localeId: $localeId)
  }
  place
  address
  price
  currency {
    id
    code
  }
  date
}
    `;
export const ProductViewImageFragmentDoc = gql`
    fragment productViewImage on Image {
  thumbnailUrl: imageUrl(transformations: {width: 512, height: 512, resize: fit})
  thumbnailRetinaUrl: imageUrl(
    transformations: {width: 1024, height: 1024, resize: fit}
  )
}
    `;
export const WineViewFieldsFragmentDoc = gql`
    fragment wineViewFields on Wine {
  id
  country {
    id
    localizedName(localeId: $localeId)
    codeISO
    isEUMember
  }
  rating
  verified
  tradeName {
    id
    tradeName
    acceptability
    canOverrideAcceptability
    website
    email
    phone
    logo {
      imageUrl
    }
    country {
      id
      localizedName(localeId: $localeId)
      codeISO
      isEUMember
    }
  }
  contactEmail
  contactPhone
  contactProducerTradeName
  contactWebsite
  acceptability
  canOverrideAcceptability
  canOverrideFacts
  brand {
    id
    name
    localizedName(localeId: $localeId)
    acceptability
    canOverrideAcceptability
  }
  keyWords
  specialClassifications {
    id
    name
  }
  wineType {
    id
    isDefault
    localizedName(localeId: $localeId)
  }
  wineColor {
    id
    localizedName(localeId: $localeId)
  }
  sweetnessLevel {
    id
    isDefault
    localizedName(localeId: $localeId)
  }
  vintage
  awards {
    id
    minScore
    maxScore
    year
    award {
      id
      masterName
      masterNameShort
      roundScore
      stepScore
    }
    rewards {
      id
      name
      shortName
      minYear
      maxYear
      minScore
      maxScore
      rewardType {
        id
        masterName
      }
    }
  }
  tastingNote
  foodPairs {
    id
    localizedName(localeId: $localeId)
  }
  servingTemperatureMin
  servingTemperatureMax
  chemicalAnalysis {
    id
    residualSugar
    alcoholVolume
    acidityTotal {
      value
      unit {
        masterName
      }
    }
    pH
  }
  geoIndicationText
  nationalGITypeText
  geoIndications {
    id
    localizedName(localeId: $localeId)
    wineRegionEU {
      id
      localizedName(localeId: $localeId)
    }
    wineRegionsEU {
      id
      localizedName(localeId: $localeId)
      country {
        id
        codeISO
        localizedName(localeId: $localeId)
      }
    }
  }
  nationalGIType {
    id
    localizedShortName(localeId: $localeId)
  }
  grapeVarieties {
    value
    grapeVarietySynonym {
      id
      name
    }
  }
  products {
    id
    slug
    bottleSize {
      id
      volumeML
    }
    packageType {
      id
      isDefault
      shortName
      localizedName(localeId: $localeId)
    }
    priceAtWinery
    currency {
      id
      code
    }
  }
  vintageList {
    id
    vintage
    products {
      id
      slug
    }
  }
  tradeNameBrand
  mainSynonym {
    id
  }
  wineTypeEU {
    id
    localizedName(localeId: $localeId)
  }
  ingredients {
    id
    localizedName(localeId: $localeId)
    isAllergen
    code
    category {
      id
      localizedName(localeId: $localeId)
      isPrintable
    }
  }
  nutritionDeclaration {
    carbohydrate
    carbohydrateApproximate
    energyKCal
    energyKCalApproximate
    energyKJ
    fat
    fatApproximate
    protein
    proteinApproximate
    salt
    saltApproximate
    saturates
    saturatesApproximate
    sugars
    sugarsApproximate
  }
  certs {
    id
    logo
    localizedName(localeId: $localeId)
  }
  responsibleConsumptions {
    id
    logo
    localizedName(localeId: $localeId)
  }
}
    `;
export const AdminGetMerchantsListDocument = gql`
    query adminGetMerchantsList($localeId: Int!, $sort: MerchantSort! = CREATED_AT_DESC, $filter: AdminMerchantFilter, $query: String, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20) {
  admin {
    merchants {
      merchantsPagination(
        localeId: $localeId
        sort: $sort
        filter: $filter
        query: $query
        page: $page
        perPage: $perPage
      ) {
        items {
          ...adminGetMerchantsListItem
        }
        pageInfo {
          totalItems
          totalPages
          page
          perPage
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
}
    ${AdminGetMerchantsListItemFragmentDoc}`;

/**
 * __useAdminGetMerchantsListQuery__
 *
 * To run a query within a React component, call `useAdminGetMerchantsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetMerchantsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetMerchantsListQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useAdminGetMerchantsListQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminGetMerchantsListQuery, Types.AdminGetMerchantsListQueryVariables> & ({ variables: Types.AdminGetMerchantsListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminGetMerchantsListQuery, Types.AdminGetMerchantsListQueryVariables>(AdminGetMerchantsListDocument, options);
      }
export function useAdminGetMerchantsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminGetMerchantsListQuery, Types.AdminGetMerchantsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminGetMerchantsListQuery, Types.AdminGetMerchantsListQueryVariables>(AdminGetMerchantsListDocument, options);
        }
export function useAdminGetMerchantsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminGetMerchantsListQuery, Types.AdminGetMerchantsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminGetMerchantsListQuery, Types.AdminGetMerchantsListQueryVariables>(AdminGetMerchantsListDocument, options);
        }
export type AdminGetMerchantsListQueryHookResult = ReturnType<typeof useAdminGetMerchantsListQuery>;
export type AdminGetMerchantsListLazyQueryHookResult = ReturnType<typeof useAdminGetMerchantsListLazyQuery>;
export type AdminGetMerchantsListSuspenseQueryHookResult = ReturnType<typeof useAdminGetMerchantsListSuspenseQuery>;
export type AdminGetMerchantsListQueryResult = Apollo.QueryResult<Types.AdminGetMerchantsListQuery, Types.AdminGetMerchantsListQueryVariables>;
export const AdminMerchantsFiltersDocument = gql`
    query adminMerchantsFilters($localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 30, $filterSearch: String, $filter: AdminMerchantFilter, $includeUnusedLabels: Boolean!, $query: String) {
  admin {
    merchants {
      merchantsFilters(localeId: $localeId, filter: $filter, query: $query) {
        count
        pricePerBottleFilter: filterByKey(key: "pricePerBottle") {
          ...facetComplexFilter
        }
        pricePerLotFilter: filterByKey(key: "pricePerLot") {
          ...facetComplexFilter
        }
        deliveryTimeFilter: filterByKey(key: "deliveryTime") {
          ...facetListFilter
        }
        merchantCountryFilter: filterByKey(key: "country") {
          ...facetListFilter
        }
        quantityOfItemFilter: filterByKey(key: "activeTradeOfferCount") {
          ...facetRangeFilter
        }
        wineLotFilter: filterByKey(key: "wineLot") {
          ...facetListFilter
        }
        cellarDoorPriceFilter: filterByKey(key: "cellarDoorPrice") {
          ...facetComplexFilter
        }
        priceCoefFilter: filterByKey(key: "priceCoefficient") {
          ...facetRangeFilter
        }
        countryFilter: filterByKey(key: "wineCountry") {
          ...facetListFilter
        }
        producerFilter: filterByKey(key: "tradeName") {
          ...facetListFilter
        }
        brandFilter: filterByKey(key: "brand") {
          ...facetListFilter
        }
        wineRegionEUFilter: filterByKey(key: "wineRegionEU") {
          ...facetListFilter
        }
        nationalGITypeFilter: filterByKey(key: "nationalGIType") {
          ...facetListFilter
        }
        geoIndicationFilter: filterByKey(key: "geoIndication") {
          ...facetListFilter
        }
        specialClassificationFilter: filterByKey(key: "specialClassification") {
          ...facetListFilter
        }
        wineTypeFilter: filterByKey(key: "wineType") {
          ...facetListFilter
        }
        wineColourFilter: filterByKey(key: "wineColor") {
          ...facetListFilter
        }
        sweetnessTypeFilter: filterByKey(key: "sweetnessLevel") {
          ...facetListFilter
        }
        vintageFilter: filterByKey(key: "vintage") {
          ...facetListFilter
        }
        bottleSizeFilter: filterByKey(key: "bottleSize") {
          ...facetListFilter
        }
        grapesFilter: filterByKey(key: "grapeAndMonocepage") {
          ...facetComplexFilter
        }
        packageTypeFilter: filterByKey(key: "packageType") {
          ...facetListFilter
        }
        certificatesFilter: filterByKey(key: "certification") {
          ...facetListFilter
        }
        alcoholLevelFilter: filterByKey(key: "chemicalAnalysisAlcohol") {
          ...facetRangeFilter
        }
        wwlRatingFilter: filterByKey(key: "wines.rating") {
          ...facetListFilter
        }
        createdAtFilter: filterByKey(key: "createdAt") {
          ...facetDateRangeFilter
        }
        lastUpdateFilter: filterByKey(key: "updatedAt") {
          ...facetDateRangeFilter
        }
      }
    }
  }
}
    ${FacetComplexFilterFragmentDoc}
${FacetListFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}
${FacetDateRangeFilterFragmentDoc}`;

/**
 * __useAdminMerchantsFiltersQuery__
 *
 * To run a query within a React component, call `useAdminMerchantsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMerchantsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMerchantsFiltersQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      filter: // value for 'filter'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAdminMerchantsFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminMerchantsFiltersQuery, Types.AdminMerchantsFiltersQueryVariables> & ({ variables: Types.AdminMerchantsFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminMerchantsFiltersQuery, Types.AdminMerchantsFiltersQueryVariables>(AdminMerchantsFiltersDocument, options);
      }
export function useAdminMerchantsFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminMerchantsFiltersQuery, Types.AdminMerchantsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminMerchantsFiltersQuery, Types.AdminMerchantsFiltersQueryVariables>(AdminMerchantsFiltersDocument, options);
        }
export function useAdminMerchantsFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminMerchantsFiltersQuery, Types.AdminMerchantsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminMerchantsFiltersQuery, Types.AdminMerchantsFiltersQueryVariables>(AdminMerchantsFiltersDocument, options);
        }
export type AdminMerchantsFiltersQueryHookResult = ReturnType<typeof useAdminMerchantsFiltersQuery>;
export type AdminMerchantsFiltersLazyQueryHookResult = ReturnType<typeof useAdminMerchantsFiltersLazyQuery>;
export type AdminMerchantsFiltersSuspenseQueryHookResult = ReturnType<typeof useAdminMerchantsFiltersSuspenseQuery>;
export type AdminMerchantsFiltersQueryResult = Apollo.QueryResult<Types.AdminMerchantsFiltersQuery, Types.AdminMerchantsFiltersQueryVariables>;
export const AdminMerchantsSpecificFilterDocument = gql`
    query adminMerchantsSpecificFilter($filterKey: String!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filterSearch: String, $filter: AdminMerchantFilter, $includeUnusedLabels: Boolean! = true) {
  admin {
    merchants {
      merchantsFilters(localeId: $localeId, filter: $filter) {
        filterByKey(key: $filterKey) {
          ...facetFreeListFilter
          ...facetComplexFilter
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetFreeListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetListFilterFragmentDoc}`;

/**
 * __useAdminMerchantsSpecificFilterQuery__
 *
 * To run a query within a React component, call `useAdminMerchantsSpecificFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMerchantsSpecificFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMerchantsSpecificFilterQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      filter: // value for 'filter'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useAdminMerchantsSpecificFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminMerchantsSpecificFilterQuery, Types.AdminMerchantsSpecificFilterQueryVariables> & ({ variables: Types.AdminMerchantsSpecificFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminMerchantsSpecificFilterQuery, Types.AdminMerchantsSpecificFilterQueryVariables>(AdminMerchantsSpecificFilterDocument, options);
      }
export function useAdminMerchantsSpecificFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminMerchantsSpecificFilterQuery, Types.AdminMerchantsSpecificFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminMerchantsSpecificFilterQuery, Types.AdminMerchantsSpecificFilterQueryVariables>(AdminMerchantsSpecificFilterDocument, options);
        }
export function useAdminMerchantsSpecificFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminMerchantsSpecificFilterQuery, Types.AdminMerchantsSpecificFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminMerchantsSpecificFilterQuery, Types.AdminMerchantsSpecificFilterQueryVariables>(AdminMerchantsSpecificFilterDocument, options);
        }
export type AdminMerchantsSpecificFilterQueryHookResult = ReturnType<typeof useAdminMerchantsSpecificFilterQuery>;
export type AdminMerchantsSpecificFilterLazyQueryHookResult = ReturnType<typeof useAdminMerchantsSpecificFilterLazyQuery>;
export type AdminMerchantsSpecificFilterSuspenseQueryHookResult = ReturnType<typeof useAdminMerchantsSpecificFilterSuspenseQuery>;
export type AdminMerchantsSpecificFilterQueryResult = Apollo.QueryResult<Types.AdminMerchantsSpecificFilterQuery, Types.AdminMerchantsSpecificFilterQueryVariables>;
export const AdminProductELabelsDocument = gql`
    query adminProductELabels($localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: ProductELabelSort! = UPDATED_AT_DESC, $query: String, $filter: AdminProductELabelsFilter) {
  admin {
    productELabels {
      productELabelsPagination(
        localeId: $localeId
        page: $page
        perPage: $perPage
        sort: $sort
        query: $query
        filter: $filter
      ) {
        pageInfo {
          totalPages
          totalItems
          page
          perPage
          hasNextPage
          hasPreviousPage
        }
        items {
          ...adminProductELabel
        }
      }
    }
  }
}
    ${AdminProductELabelFragmentDoc}`;

/**
 * __useAdminProductELabelsQuery__
 *
 * To run a query within a React component, call `useAdminProductELabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProductELabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProductELabelsQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminProductELabelsQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminProductELabelsQuery, Types.AdminProductELabelsQueryVariables> & ({ variables: Types.AdminProductELabelsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminProductELabelsQuery, Types.AdminProductELabelsQueryVariables>(AdminProductELabelsDocument, options);
      }
export function useAdminProductELabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminProductELabelsQuery, Types.AdminProductELabelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminProductELabelsQuery, Types.AdminProductELabelsQueryVariables>(AdminProductELabelsDocument, options);
        }
export function useAdminProductELabelsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminProductELabelsQuery, Types.AdminProductELabelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminProductELabelsQuery, Types.AdminProductELabelsQueryVariables>(AdminProductELabelsDocument, options);
        }
export type AdminProductELabelsQueryHookResult = ReturnType<typeof useAdminProductELabelsQuery>;
export type AdminProductELabelsLazyQueryHookResult = ReturnType<typeof useAdminProductELabelsLazyQuery>;
export type AdminProductELabelsSuspenseQueryHookResult = ReturnType<typeof useAdminProductELabelsSuspenseQuery>;
export type AdminProductELabelsQueryResult = Apollo.QueryResult<Types.AdminProductELabelsQuery, Types.AdminProductELabelsQueryVariables>;
export const AdminProductELabelsFilterDocument = gql`
    query adminProductELabelsFilter($filterKey: String!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filter: AdminProductELabelsFilter, $query: String, $filterSearch: String, $includeUnusedLabels: Boolean! = true) {
  admin {
    productELabels {
      productELabelsFilters(localeId: $localeId, filter: $filter, query: $query) {
        filterByKey(key: $filterKey) {
          ...facetFreeListFilter
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetFreeListFilterFragmentDoc}
${FacetListFilterFragmentDoc}`;

/**
 * __useAdminProductELabelsFilterQuery__
 *
 * To run a query within a React component, call `useAdminProductELabelsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProductELabelsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProductELabelsFilterQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      query: // value for 'query'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useAdminProductELabelsFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminProductELabelsFilterQuery, Types.AdminProductELabelsFilterQueryVariables> & ({ variables: Types.AdminProductELabelsFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminProductELabelsFilterQuery, Types.AdminProductELabelsFilterQueryVariables>(AdminProductELabelsFilterDocument, options);
      }
export function useAdminProductELabelsFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminProductELabelsFilterQuery, Types.AdminProductELabelsFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminProductELabelsFilterQuery, Types.AdminProductELabelsFilterQueryVariables>(AdminProductELabelsFilterDocument, options);
        }
export function useAdminProductELabelsFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminProductELabelsFilterQuery, Types.AdminProductELabelsFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminProductELabelsFilterQuery, Types.AdminProductELabelsFilterQueryVariables>(AdminProductELabelsFilterDocument, options);
        }
export type AdminProductELabelsFilterQueryHookResult = ReturnType<typeof useAdminProductELabelsFilterQuery>;
export type AdminProductELabelsFilterLazyQueryHookResult = ReturnType<typeof useAdminProductELabelsFilterLazyQuery>;
export type AdminProductELabelsFilterSuspenseQueryHookResult = ReturnType<typeof useAdminProductELabelsFilterSuspenseQuery>;
export type AdminProductELabelsFilterQueryResult = Apollo.QueryResult<Types.AdminProductELabelsFilterQuery, Types.AdminProductELabelsFilterQueryVariables>;
export const AdminProductELabelsFiltersDocument = gql`
    query adminProductELabelsFilters($localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt!, $filter: AdminProductELabelsFilter, $query: String, $includeUnusedLabels: Boolean!, $filterSearch: String) {
  admin {
    productELabels {
      productELabelsFilters(localeId: $localeId, filter: $filter, query: $query) {
        count
        producerFilter: filterByKey(key: "wine.tradeName") {
          ...facetListFilter
        }
        updateUserUsernameFilter: filterByKey(key: "updateUserUsername") {
          ...facetFreeListFilter
        }
        wineCountryFilter: filterByKey(key: "wine.country") {
          ...facetListFilter
        }
        statusFilter: filterByKey(key: "status") {
          ...facetListFilter
        }
        createdAtFilter: filterByKey(key: "createdAt") {
          ...facetDateRangeFilter
        }
        updatedAtFilter: filterByKey(key: "updatedAt") {
          ...facetDateRangeFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetFreeListFilterFragmentDoc}
${FacetDateRangeFilterFragmentDoc}`;

/**
 * __useAdminProductELabelsFiltersQuery__
 *
 * To run a query within a React component, call `useAdminProductELabelsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProductELabelsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProductELabelsFiltersQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      query: // value for 'query'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *      filterSearch: // value for 'filterSearch'
 *   },
 * });
 */
export function useAdminProductELabelsFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminProductELabelsFiltersQuery, Types.AdminProductELabelsFiltersQueryVariables> & ({ variables: Types.AdminProductELabelsFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminProductELabelsFiltersQuery, Types.AdminProductELabelsFiltersQueryVariables>(AdminProductELabelsFiltersDocument, options);
      }
export function useAdminProductELabelsFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminProductELabelsFiltersQuery, Types.AdminProductELabelsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminProductELabelsFiltersQuery, Types.AdminProductELabelsFiltersQueryVariables>(AdminProductELabelsFiltersDocument, options);
        }
export function useAdminProductELabelsFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminProductELabelsFiltersQuery, Types.AdminProductELabelsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminProductELabelsFiltersQuery, Types.AdminProductELabelsFiltersQueryVariables>(AdminProductELabelsFiltersDocument, options);
        }
export type AdminProductELabelsFiltersQueryHookResult = ReturnType<typeof useAdminProductELabelsFiltersQuery>;
export type AdminProductELabelsFiltersLazyQueryHookResult = ReturnType<typeof useAdminProductELabelsFiltersLazyQuery>;
export type AdminProductELabelsFiltersSuspenseQueryHookResult = ReturnType<typeof useAdminProductELabelsFiltersSuspenseQuery>;
export type AdminProductELabelsFiltersQueryResult = Apollo.QueryResult<Types.AdminProductELabelsFiltersQuery, Types.AdminProductELabelsFiltersQueryVariables>;
export const AdminWineImagesDocument = gql`
    query adminWineImages($page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filter: AdminProductImageFilter, $localeId: Int!) {
  admin {
    productImages {
      productImagesPagination(page: $page, perPage: $perPage, filter: $filter) {
        pageInfo {
          page
          perPage
          totalItems
          totalPages
          hasPreviousPage
          hasNextPage
        }
        items {
          id
          url
          type
          isReference
          linkStatus
          isVintageUnknown
          createdAt
          authorUser {
            id
            username
          }
          product {
            ...ProductInfo
          }
          image {
            thumbnailUrl: imageUrl(transformations: {width: 48, height: 96, resize: fit})
            thumbnailRetinaUrl: imageUrl(
              transformations: {width: 96, height: 192, resize: fit}
            )
            thumbnail256Url: imageUrl(transformations: {width: 400, height: 256})
            zoomedUrl: imageUrl(transformations: {width: 1000, height: 1000, enlarge: 1})
          }
          sourceType
          search {
            ... on ProductImageSearch {
              id
              googleResponse {
                product {
                  ...ProductInfo
                }
                referenceImage
              }
              tineyeResponse {
                product {
                  ...ProductInfo
                }
                response
                referenceImage
              }
            }
          }
        }
      }
    }
  }
}
    ${ProductInfoFragmentDoc}`;

/**
 * __useAdminWineImagesQuery__
 *
 * To run a query within a React component, call `useAdminWineImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminWineImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminWineImagesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useAdminWineImagesQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminWineImagesQuery, Types.AdminWineImagesQueryVariables> & ({ variables: Types.AdminWineImagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminWineImagesQuery, Types.AdminWineImagesQueryVariables>(AdminWineImagesDocument, options);
      }
export function useAdminWineImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminWineImagesQuery, Types.AdminWineImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminWineImagesQuery, Types.AdminWineImagesQueryVariables>(AdminWineImagesDocument, options);
        }
export function useAdminWineImagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminWineImagesQuery, Types.AdminWineImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminWineImagesQuery, Types.AdminWineImagesQueryVariables>(AdminWineImagesDocument, options);
        }
export type AdminWineImagesQueryHookResult = ReturnType<typeof useAdminWineImagesQuery>;
export type AdminWineImagesLazyQueryHookResult = ReturnType<typeof useAdminWineImagesLazyQuery>;
export type AdminWineImagesSuspenseQueryHookResult = ReturnType<typeof useAdminWineImagesSuspenseQuery>;
export type AdminWineImagesQueryResult = Apollo.QueryResult<Types.AdminWineImagesQuery, Types.AdminWineImagesQueryVariables>;
export const WineForImagesDocument = gql`
    query wineForImages($localeId: Int!, $id: ID!) {
  wine(id: $id) {
    id
    tradeNameBrand
    tradeName {
      id
      tradeName
    }
    keyWords
    specialClassifications {
      id
      name
    }
    vintage
    wineType {
      id
      isDefault
      localizedName(localeId: $localeId)
    }
    wineColor {
      id
      localizedName(localeId: $localeId)
    }
    sweetnessLevel {
      id
      isDefault
      localizedName(localeId: $localeId)
    }
    country {
      id
      codeISO
      localizedName(localeId: $localeId)
    }
    geoIndicationText
    geoIndications {
      id
      localizedName(localeId: $localeId)
    }
    nationalGITypeText
    nationalGIType {
      id
      localizedShortName(localeId: $localeId)
    }
    grapeVarieties {
      value
      grapeVarietySynonym {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useWineForImagesQuery__
 *
 * To run a query within a React component, call `useWineForImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineForImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineForImagesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWineForImagesQuery(baseOptions: Apollo.QueryHookOptions<Types.WineForImagesQuery, Types.WineForImagesQueryVariables> & ({ variables: Types.WineForImagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineForImagesQuery, Types.WineForImagesQueryVariables>(WineForImagesDocument, options);
      }
export function useWineForImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineForImagesQuery, Types.WineForImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineForImagesQuery, Types.WineForImagesQueryVariables>(WineForImagesDocument, options);
        }
export function useWineForImagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineForImagesQuery, Types.WineForImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineForImagesQuery, Types.WineForImagesQueryVariables>(WineForImagesDocument, options);
        }
export type WineForImagesQueryHookResult = ReturnType<typeof useWineForImagesQuery>;
export type WineForImagesLazyQueryHookResult = ReturnType<typeof useWineForImagesLazyQuery>;
export type WineForImagesSuspenseQueryHookResult = ReturnType<typeof useWineForImagesSuspenseQuery>;
export type WineForImagesQueryResult = Apollo.QueryResult<Types.WineForImagesQuery, Types.WineForImagesQueryVariables>;
export const WineOfferRequestQueryDocument = gql`
    query wineOfferRequestQuery($id: ID!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filter: WineOfferRequestCompaniesMatchFilter, $localeId: Int!) {
  wineOfferRequest(id: $id) {
    id
    number
    createdAt
    periodOfValidity
    buyerCompany: buyer {
      ... on Company {
        id
        country {
          localizedName(localeId: $localeId)
          codeISO
        }
        companyCategory {
          id
          localizedName(localeId: $localeId)
        }
        tradeName
        representativeUser {
          username
        }
      }
      ... on HiddenCompany {
        id
        country {
          localizedName(localeId: $localeId)
          codeISO
        }
        companyCategory {
          id
          localizedName(localeId: $localeId)
        }
        representativeUser {
          username
        }
      }
    }
    filters {
      ... on FacetListFilterSelection {
        key
        byName(localeId: $localeId)
      }
      ... on FacetRangeFilterSelection {
        key
        minValue
        maxValue
      }
      ... on FacetBooleanFilterSelection {
        key
        value
      }
      ... on FacetComplexFilterSelection {
        key
        childrenInputs {
          ... on FacetBooleanFilterSelection {
            key
            value
          }
          ... on FacetListFilterSelection {
            key
            byName(localeId: $localeId)
          }
          ... on FacetRangeFilterSelection {
            key
            minValue
            maxValue
          }
        }
      }
    }
    matches {
      companyRegisteredCount
      companyHasOfferCount
      companiesPagination(page: $page, perPage: $perPage, filter: $filter) {
        pageInfo {
          totalItems
          totalPages
          page
          perPage
          hasPreviousPage
          hasNextPage
        }
        items {
          ...wineOfferRequestCompanyMatch
        }
      }
    }
  }
}
    ${WineOfferRequestCompanyMatchFragmentDoc}`;

/**
 * __useWineOfferRequestQueryQuery__
 *
 * To run a query within a React component, call `useWineOfferRequestQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineOfferRequestQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useWineOfferRequestQueryQuery(baseOptions: Apollo.QueryHookOptions<Types.WineOfferRequestQueryQuery, Types.WineOfferRequestQueryQueryVariables> & ({ variables: Types.WineOfferRequestQueryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineOfferRequestQueryQuery, Types.WineOfferRequestQueryQueryVariables>(WineOfferRequestQueryDocument, options);
      }
export function useWineOfferRequestQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineOfferRequestQueryQuery, Types.WineOfferRequestQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineOfferRequestQueryQuery, Types.WineOfferRequestQueryQueryVariables>(WineOfferRequestQueryDocument, options);
        }
export function useWineOfferRequestQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineOfferRequestQueryQuery, Types.WineOfferRequestQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineOfferRequestQueryQuery, Types.WineOfferRequestQueryQueryVariables>(WineOfferRequestQueryDocument, options);
        }
export type WineOfferRequestQueryQueryHookResult = ReturnType<typeof useWineOfferRequestQueryQuery>;
export type WineOfferRequestQueryLazyQueryHookResult = ReturnType<typeof useWineOfferRequestQueryLazyQuery>;
export type WineOfferRequestQuerySuspenseQueryHookResult = ReturnType<typeof useWineOfferRequestQuerySuspenseQuery>;
export type WineOfferRequestQueryQueryResult = Apollo.QueryResult<Types.WineOfferRequestQueryQuery, Types.WineOfferRequestQueryQueryVariables>;
export const AdminWineOfferFiltersDocument = gql`
    query adminWineOfferFilters($localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt!, $filterSearch: String, $filter: AdminWineOfferFilter, $query: String, $includeUnusedLabels: Boolean!) {
  admin {
    wineOffers {
      wineOffersFilters(localeId: $localeId, filter: $filter, query: $query) {
        count
        producerFilter: filterByKey(key: "product.wine.tradeName") {
          ...facetListFilter
        }
        countryFilter: filterByKey(key: "product.wine.country") {
          ...facetListFilter
        }
        brandFilter: filterByKey(key: "product.wine.brand") {
          ...facetListFilter
        }
        exhibitionFilter: filterByKey(key: "exhibition") {
          ...facetListFilter
        }
        nationalGITypeFilter: filterByKey(key: "product.wine.nationalGIType") {
          ...facetListFilter
        }
        wineRegionEUFilter: filterByKey(key: "product.wine.wineRegionEU") {
          ...facetListFilter
        }
        euGITypeFilter: filterByKey(key: "product.wine.nationalGIType.euGIType") {
          ...facetListFilter
        }
        geoIndicationFilter: filterByKey(key: "product.wine.geoIndication") {
          ...facetListFilter
        }
        specialClassificationFilter: filterByKey(
          key: "product.wine.specialClassification"
        ) {
          ...facetListFilter
        }
        wineTypeFilter: filterByKey(key: "product.wine.wineType") {
          ...facetListFilter
        }
        wineColourFilter: filterByKey(key: "product.wine.wineColor") {
          ...facetListFilter
        }
        sweetnessTypeFilter: filterByKey(key: "product.wine.sweetnessLevel") {
          ...facetListFilter
        }
        vintageFilter: filterByKey(key: "product.wine.vintage") {
          ...facetListFilter
        }
        bottleSizeFilter: filterByKey(key: "bottleSize") {
          ...facetListFilter
        }
        packageTypeFilter: filterByKey(key: "packageType") {
          ...facetListFilter
        }
        grapesFilter: filterByKey(key: "grapeAndMonocepage") {
          ...facetComplexFilter
        }
        certificatesFilter: filterByKey(key: "product.wine.certification") {
          ...facetListFilter
        }
        alcoholLevelFilter: filterByKey(key: "product.alcoholOnLabel") {
          ...facetRangeFilter
        }
        wwlRatingFilter: filterByKey(key: "product.wine.rating") {
          ...facetListFilter
        }
        distributionFilter: filterByKey(key: "countryOfDelivery") {
          ...facetListFilter
        }
        buyerCompanyCategoryFilter: filterByKey(key: "buyerCompanyCategory") {
          ...facetListFilter
        }
        sellerCompanyCategoryFilter: filterByKey(key: "sellerCompanyCategory") {
          ...facetListFilter
        }
        periodOfValidityFilter: filterByKey(key: "periodOfValidity") {
          ...facetDateRangeFilter
        }
        countryOfShipmentFilter: filterByKey(key: "countryOfShipment") {
          ...facetListFilter
        }
        deliveryTermsFilter: filterByKey(key: "incoterms") {
          ...facetListFilter
        }
        sellerCompanyUsernameFilter: filterByKey(key: "sellerCompanyUsername") {
          ...facetListFilter
        }
        priceCoefFilter: filterByKey(key: "priceCoefficient") {
          ...facetRangeFilter
        }
        buyerCompanyUsernameFilter: filterByKey(key: "buyerCompanyUsername") {
          ...facetListFilter
        }
        wineOfferTypeFilter: filterByKey(key: "wineOfferType") {
          ...facetListFilter
        }
        priceFilter: filterByKey(key: "pricePerBottle") {
          ...facetComplexFilter
        }
        buyerCompanyCountryFilter: filterByKey(key: "buyerCompanyCountry") {
          ...facetListFilter
        }
        rrpFilter: filterByKey(key: "product.priceAtWinery") {
          ...facetComplexFilter
        }
        retailPriceFilter: filterByKey(key: "tradeOfferLotPricePerBottle") {
          ...facetComplexFilter
        }
        frontLabelFilter: filterByKey(key: "hasFrontLabelImage") {
          ...facetListFilter
        }
        backLabelFilter: filterByKey(key: "hasBackLabelImage") {
          ...facetListFilter
        }
        packshotFilter: filterByKey(key: "hasBottleImage") {
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}
${FacetDateRangeFilterFragmentDoc}`;

/**
 * __useAdminWineOfferFiltersQuery__
 *
 * To run a query within a React component, call `useAdminWineOfferFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminWineOfferFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminWineOfferFiltersQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      filter: // value for 'filter'
 *      query: // value for 'query'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useAdminWineOfferFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminWineOfferFiltersQuery, Types.AdminWineOfferFiltersQueryVariables> & ({ variables: Types.AdminWineOfferFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminWineOfferFiltersQuery, Types.AdminWineOfferFiltersQueryVariables>(AdminWineOfferFiltersDocument, options);
      }
export function useAdminWineOfferFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminWineOfferFiltersQuery, Types.AdminWineOfferFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminWineOfferFiltersQuery, Types.AdminWineOfferFiltersQueryVariables>(AdminWineOfferFiltersDocument, options);
        }
export function useAdminWineOfferFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminWineOfferFiltersQuery, Types.AdminWineOfferFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminWineOfferFiltersQuery, Types.AdminWineOfferFiltersQueryVariables>(AdminWineOfferFiltersDocument, options);
        }
export type AdminWineOfferFiltersQueryHookResult = ReturnType<typeof useAdminWineOfferFiltersQuery>;
export type AdminWineOfferFiltersLazyQueryHookResult = ReturnType<typeof useAdminWineOfferFiltersLazyQuery>;
export type AdminWineOfferFiltersSuspenseQueryHookResult = ReturnType<typeof useAdminWineOfferFiltersSuspenseQuery>;
export type AdminWineOfferFiltersQueryResult = Apollo.QueryResult<Types.AdminWineOfferFiltersQuery, Types.AdminWineOfferFiltersQueryVariables>;
export const AdminWineOfferSpecificFilterDocument = gql`
    query adminWineOfferSpecificFilter($filterKey: String!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filter: AdminWineOfferFilter, $query: String, $filterSearch: String, $includeUnusedLabels: Boolean! = true) {
  admin {
    wineOffers {
      wineOffersFilters(localeId: $localeId, filter: $filter, query: $query) {
        filterByKey(key: $filterKey) {
          ...facetFreeListFilter
          ...facetComplexFilter
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetFreeListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetListFilterFragmentDoc}`;

/**
 * __useAdminWineOfferSpecificFilterQuery__
 *
 * To run a query within a React component, call `useAdminWineOfferSpecificFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminWineOfferSpecificFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminWineOfferSpecificFilterQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      query: // value for 'query'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useAdminWineOfferSpecificFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminWineOfferSpecificFilterQuery, Types.AdminWineOfferSpecificFilterQueryVariables> & ({ variables: Types.AdminWineOfferSpecificFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminWineOfferSpecificFilterQuery, Types.AdminWineOfferSpecificFilterQueryVariables>(AdminWineOfferSpecificFilterDocument, options);
      }
export function useAdminWineOfferSpecificFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminWineOfferSpecificFilterQuery, Types.AdminWineOfferSpecificFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminWineOfferSpecificFilterQuery, Types.AdminWineOfferSpecificFilterQueryVariables>(AdminWineOfferSpecificFilterDocument, options);
        }
export function useAdminWineOfferSpecificFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminWineOfferSpecificFilterQuery, Types.AdminWineOfferSpecificFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminWineOfferSpecificFilterQuery, Types.AdminWineOfferSpecificFilterQueryVariables>(AdminWineOfferSpecificFilterDocument, options);
        }
export type AdminWineOfferSpecificFilterQueryHookResult = ReturnType<typeof useAdminWineOfferSpecificFilterQuery>;
export type AdminWineOfferSpecificFilterLazyQueryHookResult = ReturnType<typeof useAdminWineOfferSpecificFilterLazyQuery>;
export type AdminWineOfferSpecificFilterSuspenseQueryHookResult = ReturnType<typeof useAdminWineOfferSpecificFilterSuspenseQuery>;
export type AdminWineOfferSpecificFilterQueryResult = Apollo.QueryResult<Types.AdminWineOfferSpecificFilterQuery, Types.AdminWineOfferSpecificFilterQueryVariables>;
export const AdminWineOffersDocument = gql`
    query adminWineOffers($localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filter: AdminWineOfferFilter, $sort: WineOfferSort! = UPDATED_AT_DESC, $query: String) {
  admin {
    wineOffers {
      wineOffersPagination(
        localeId: $localeId
        page: $page
        perPage: $perPage
        filter: $filter
        sort: $sort
        query: $query
      ) {
        pageInfo {
          totalItems
          totalPages
          page
          perPage
          hasNextPage
          hasPreviousPage
        }
        items {
          ... on WineOfferPublic {
            firstPublishedAt
            wineOfferDistLimits {
              country {
                id
                localizedName(localeId: $localeId)
                codeISO
              }
              address
              isIncluded
              territory {
                radius
                radiusUnit {
                  id
                  masterName
                }
              }
            }
            buyer: buyerCompanyCategory {
              id
              localizedName(localeId: $localeId)
            }
            seller: sellerCompany {
              tradeName
              companyCategory {
                id
                localizedName(localeId: $localeId)
              }
              representativeUser {
                username
              }
            }
          }
          ... on WineOfferCompany {
            firstPublishedAt
            wineOfferDistLimits {
              country {
                id
                localizedName(localeId: $localeId)
                codeISO
              }
              address
              isIncluded
              territory {
                radius
                radiusUnit {
                  id
                  masterName
                }
              }
            }
            buyer {
              ... on Company {
                companyCategory {
                  id
                  localizedName(localeId: $localeId)
                }
                country {
                  id
                  localizedName(localeId: $localeId)
                  codeISO
                }
                tradeName
                representativeUser {
                  username
                }
              }
            }
            seller: sellerCompany {
              tradeName
              companyCategory {
                id
                localizedName(localeId: $localeId)
              }
              representativeUser {
                username
              }
            }
          }
          ... on WineOfferBase {
            updatedAt
            minimumDeliverySize
            wineOfferType {
              code
              localizedName(localeId: $localeId)
            }
            wineOfferStatus {
              code
            }
            priceCoefficient
            price
            pricePerBottle
            currency {
              code
              id
            }
            lot {
              id
              localizedName(localeId: $localeId)
              bottleQuantity
            }
            wineOfferType {
              code
            }
            periodOfValidity
            sellerCompany {
              id
              tradeName
              companyCategory {
                id
                masterName
                localizedName(localeId: $localeId)
              }
            }
            isExclusiveRight
            incoterms {
              id
              code
              localizedShortDescription(localeId: $localeId)
            }
            warehouse {
              id
              country {
                id
                codeISO
                localizedName(localeId: $localeId)
              }
              address
              territory {
                masterName
              }
            }
            product {
              id
              slug
              acceptability
              priceAtWinery
              currency {
                code
                id
              }
              wine {
                chemicalAnalysis {
                  alcoholVolume
                }
                country {
                  id
                  localizedName(localeId: $localeId)
                  codeISO
                }
                tradeNameBrand
                id
                brand {
                  name
                  acceptability
                  popularity
                }
                acceptability
                keyWords
                tradeName {
                  acceptability
                  tradeName
                  country {
                    id
                    localizedName(localeId: $localeId)
                    codeISO
                  }
                }
                wineType {
                  isDefault
                  masterName
                  localizedName(localeId: $localeId)
                }
                wineColor {
                  localizedName(localeId: $localeId)
                }
                sweetnessLevel {
                  masterName
                  isDefault
                  localizedName(localeId: $localeId)
                }
                specialClassifications {
                  name
                }
                vintage
                grapeVarieties {
                  grapeVarietySynonym {
                    name
                  }
                  value
                }
                nationalGIType {
                  shortName
                  localizedName(localeId: $localeId)
                }
                geoIndications {
                  localizedName(localeId: $localeId)
                  wineRegionsEU {
                    localizedName(localeId: $localeId)
                  }
                }
                rating
              }
              bottleSize {
                id
                volumeML
              }
              packageType {
                id
                isDefault
                shortName
                localizedName(localeId: $localeId)
              }
              bottleImage {
                imageUrl
                thumbnailUrl: imageUrl(transformations: {width: 48, height: 96, resize: fit})
                thumbnailRetinaUrl: imageUrl(
                  transformations: {width: 96, height: 192, resize: fit}
                )
              }
            }
            id
            exhibitions {
              ...exhibitionsDisplayInfo
            }
          }
          ... on WineOfferDeal {
            sellerCompanyCategory {
              id
              localizedName(localeId: $localeId)
            }
            buyer: buyerCompany {
              companyCategory {
                id
              }
              tradeName
              representativeUser {
                username
              }
            }
            sellerTradeName
            createdAt
          }
        }
      }
    }
  }
}
    ${ExhibitionsDisplayInfoFragmentDoc}`;

/**
 * __useAdminWineOffersQuery__
 *
 * To run a query within a React component, call `useAdminWineOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminWineOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminWineOffersQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAdminWineOffersQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminWineOffersQuery, Types.AdminWineOffersQueryVariables> & ({ variables: Types.AdminWineOffersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminWineOffersQuery, Types.AdminWineOffersQueryVariables>(AdminWineOffersDocument, options);
      }
export function useAdminWineOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminWineOffersQuery, Types.AdminWineOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminWineOffersQuery, Types.AdminWineOffersQueryVariables>(AdminWineOffersDocument, options);
        }
export function useAdminWineOffersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminWineOffersQuery, Types.AdminWineOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminWineOffersQuery, Types.AdminWineOffersQueryVariables>(AdminWineOffersDocument, options);
        }
export type AdminWineOffersQueryHookResult = ReturnType<typeof useAdminWineOffersQuery>;
export type AdminWineOffersLazyQueryHookResult = ReturnType<typeof useAdminWineOffersLazyQuery>;
export type AdminWineOffersSuspenseQueryHookResult = ReturnType<typeof useAdminWineOffersSuspenseQuery>;
export type AdminWineOffersQueryResult = Apollo.QueryResult<Types.AdminWineOffersQuery, Types.AdminWineOffersQueryVariables>;
export const PotentialProducersCountDocument = gql`
    query potentialProducersCount($id: ID!, $filter: WineOfferRequestTradeNamesMatchFilter) {
  wineOfferRequest(id: $id) {
    matches {
      tradeNamesPagination(filter: $filter) {
        pageInfo {
          totalItems
        }
      }
    }
  }
}
    `;

/**
 * __usePotentialProducersCountQuery__
 *
 * To run a query within a React component, call `usePotentialProducersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePotentialProducersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePotentialProducersCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePotentialProducersCountQuery(baseOptions: Apollo.QueryHookOptions<Types.PotentialProducersCountQuery, Types.PotentialProducersCountQueryVariables> & ({ variables: Types.PotentialProducersCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PotentialProducersCountQuery, Types.PotentialProducersCountQueryVariables>(PotentialProducersCountDocument, options);
      }
export function usePotentialProducersCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PotentialProducersCountQuery, Types.PotentialProducersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PotentialProducersCountQuery, Types.PotentialProducersCountQueryVariables>(PotentialProducersCountDocument, options);
        }
export function usePotentialProducersCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PotentialProducersCountQuery, Types.PotentialProducersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PotentialProducersCountQuery, Types.PotentialProducersCountQueryVariables>(PotentialProducersCountDocument, options);
        }
export type PotentialProducersCountQueryHookResult = ReturnType<typeof usePotentialProducersCountQuery>;
export type PotentialProducersCountLazyQueryHookResult = ReturnType<typeof usePotentialProducersCountLazyQuery>;
export type PotentialProducersCountSuspenseQueryHookResult = ReturnType<typeof usePotentialProducersCountSuspenseQuery>;
export type PotentialProducersCountQueryResult = Apollo.QueryResult<Types.PotentialProducersCountQuery, Types.PotentialProducersCountQueryVariables>;
export const PotentialProducersFilterDocument = gql`
    query potentialProducersFilter($id: ID!, $filter: WineOfferRequestTradeNamesMatchFilter) {
  wineOfferRequest(id: $id) {
    matches {
      tradeNamesPagination(filter: $filter) {
        pageInfo {
          totalItems
        }
      }
    }
  }
}
    `;

/**
 * __usePotentialProducersFilterQuery__
 *
 * To run a query within a React component, call `usePotentialProducersFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `usePotentialProducersFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePotentialProducersFilterQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePotentialProducersFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.PotentialProducersFilterQuery, Types.PotentialProducersFilterQueryVariables> & ({ variables: Types.PotentialProducersFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PotentialProducersFilterQuery, Types.PotentialProducersFilterQueryVariables>(PotentialProducersFilterDocument, options);
      }
export function usePotentialProducersFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PotentialProducersFilterQuery, Types.PotentialProducersFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PotentialProducersFilterQuery, Types.PotentialProducersFilterQueryVariables>(PotentialProducersFilterDocument, options);
        }
export function usePotentialProducersFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PotentialProducersFilterQuery, Types.PotentialProducersFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PotentialProducersFilterQuery, Types.PotentialProducersFilterQueryVariables>(PotentialProducersFilterDocument, options);
        }
export type PotentialProducersFilterQueryHookResult = ReturnType<typeof usePotentialProducersFilterQuery>;
export type PotentialProducersFilterLazyQueryHookResult = ReturnType<typeof usePotentialProducersFilterLazyQuery>;
export type PotentialProducersFilterSuspenseQueryHookResult = ReturnType<typeof usePotentialProducersFilterSuspenseQuery>;
export type PotentialProducersFilterQueryResult = Apollo.QueryResult<Types.PotentialProducersFilterQuery, Types.PotentialProducersFilterQueryVariables>;
export const PotentialProducersQueryDocument = gql`
    query potentialProducersQuery($id: ID!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $localeId: Int!, $sort: TradeNameRequestMatchSort!, $filter: WineOfferRequestTradeNamesMatchFilter) {
  wineOfferRequest(id: $id) {
    id
    number
    createdAt
    periodOfValidity
    buyerCompany: buyer {
      ... on Company {
        id
        country {
          localizedName(localeId: $localeId)
          codeISO
        }
        companyCategory {
          id
          localizedName(localeId: $localeId)
        }
        tradeName
        representativeUser {
          username
        }
      }
      ... on HiddenCompany {
        id
        country {
          localizedName(localeId: $localeId)
          codeISO
        }
        companyCategory {
          id
          localizedName(localeId: $localeId)
        }
        representativeUser {
          username
        }
      }
    }
    filters {
      ... on FacetListFilterSelection {
        key
        byName(localeId: $localeId)
      }
      ... on FacetRangeFilterSelection {
        key
        minValue
        maxValue
      }
      ... on FacetBooleanFilterSelection {
        key
        value
      }
      ... on FacetComplexFilterSelection {
        key
        childrenInputs {
          ... on FacetBooleanFilterSelection {
            key
            value
          }
          ... on FacetListFilterSelection {
            key
            byName(localeId: $localeId)
          }
          ... on FacetRangeFilterSelection {
            key
            minValue
            maxValue
          }
        }
      }
    }
    matches {
      companyRegisteredCount
      companyHasOfferCount
      tradeNamesPagination(
        page: $page
        perPage: $perPage
        sort: $sort
        filter: $filter
      ) {
        pageInfo {
          totalItems
          totalPages
          page
          perPage
          hasPreviousPage
          hasNextPage
        }
        items {
          ...potentialProducersItem
        }
      }
    }
  }
}
    ${PotentialProducersItemFragmentDoc}`;

/**
 * __usePotentialProducersQueryQuery__
 *
 * To run a query within a React component, call `usePotentialProducersQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePotentialProducersQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePotentialProducersQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      localeId: // value for 'localeId'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePotentialProducersQueryQuery(baseOptions: Apollo.QueryHookOptions<Types.PotentialProducersQueryQuery, Types.PotentialProducersQueryQueryVariables> & ({ variables: Types.PotentialProducersQueryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PotentialProducersQueryQuery, Types.PotentialProducersQueryQueryVariables>(PotentialProducersQueryDocument, options);
      }
export function usePotentialProducersQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PotentialProducersQueryQuery, Types.PotentialProducersQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PotentialProducersQueryQuery, Types.PotentialProducersQueryQueryVariables>(PotentialProducersQueryDocument, options);
        }
export function usePotentialProducersQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PotentialProducersQueryQuery, Types.PotentialProducersQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PotentialProducersQueryQuery, Types.PotentialProducersQueryQueryVariables>(PotentialProducersQueryDocument, options);
        }
export type PotentialProducersQueryQueryHookResult = ReturnType<typeof usePotentialProducersQueryQuery>;
export type PotentialProducersQueryLazyQueryHookResult = ReturnType<typeof usePotentialProducersQueryLazyQuery>;
export type PotentialProducersQuerySuspenseQueryHookResult = ReturnType<typeof usePotentialProducersQuerySuspenseQuery>;
export type PotentialProducersQueryQueryResult = Apollo.QueryResult<Types.PotentialProducersQueryQuery, Types.PotentialProducersQueryQueryVariables>;
export const WineOfferRequestNewsletterPotentialProducersCountDocument = gql`
    query wineOfferRequestNewsletterPotentialProducersCount($id: ID!, $filter: WineOfferRequestTradeNamesMatchFilter) {
  admin {
    wineOfferRequests {
      wineOfferRequestNewsletterPotentialProducersCount(id: $id, filter: $filter) {
        count
      }
    }
  }
}
    `;

/**
 * __useWineOfferRequestNewsletterPotentialProducersCountQuery__
 *
 * To run a query within a React component, call `useWineOfferRequestNewsletterPotentialProducersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestNewsletterPotentialProducersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineOfferRequestNewsletterPotentialProducersCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useWineOfferRequestNewsletterPotentialProducersCountQuery(baseOptions: Apollo.QueryHookOptions<Types.WineOfferRequestNewsletterPotentialProducersCountQuery, Types.WineOfferRequestNewsletterPotentialProducersCountQueryVariables> & ({ variables: Types.WineOfferRequestNewsletterPotentialProducersCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineOfferRequestNewsletterPotentialProducersCountQuery, Types.WineOfferRequestNewsletterPotentialProducersCountQueryVariables>(WineOfferRequestNewsletterPotentialProducersCountDocument, options);
      }
export function useWineOfferRequestNewsletterPotentialProducersCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineOfferRequestNewsletterPotentialProducersCountQuery, Types.WineOfferRequestNewsletterPotentialProducersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineOfferRequestNewsletterPotentialProducersCountQuery, Types.WineOfferRequestNewsletterPotentialProducersCountQueryVariables>(WineOfferRequestNewsletterPotentialProducersCountDocument, options);
        }
export function useWineOfferRequestNewsletterPotentialProducersCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineOfferRequestNewsletterPotentialProducersCountQuery, Types.WineOfferRequestNewsletterPotentialProducersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineOfferRequestNewsletterPotentialProducersCountQuery, Types.WineOfferRequestNewsletterPotentialProducersCountQueryVariables>(WineOfferRequestNewsletterPotentialProducersCountDocument, options);
        }
export type WineOfferRequestNewsletterPotentialProducersCountQueryHookResult = ReturnType<typeof useWineOfferRequestNewsletterPotentialProducersCountQuery>;
export type WineOfferRequestNewsletterPotentialProducersCountLazyQueryHookResult = ReturnType<typeof useWineOfferRequestNewsletterPotentialProducersCountLazyQuery>;
export type WineOfferRequestNewsletterPotentialProducersCountSuspenseQueryHookResult = ReturnType<typeof useWineOfferRequestNewsletterPotentialProducersCountSuspenseQuery>;
export type WineOfferRequestNewsletterPotentialProducersCountQueryResult = Apollo.QueryResult<Types.WineOfferRequestNewsletterPotentialProducersCountQuery, Types.WineOfferRequestNewsletterPotentialProducersCountQueryVariables>;
export const WineOfferRequestNewsletterSendToPotentialProducersDocument = gql`
    mutation wineOfferRequestNewsletterSendToPotentialProducers($input: AdminWineOfferRequestNewsletterSendToPotentialProducersInput!) {
  admin {
    wineOfferRequests {
      wineOfferRequestNewsletterSendToPotentialProducers(input: $input) {
        __typename
      }
    }
  }
}
    `;
export type WineOfferRequestNewsletterSendToPotentialProducersMutationFn = Apollo.MutationFunction<Types.WineOfferRequestNewsletterSendToPotentialProducersMutation, Types.WineOfferRequestNewsletterSendToPotentialProducersMutationVariables>;

/**
 * __useWineOfferRequestNewsletterSendToPotentialProducersMutation__
 *
 * To run a mutation, you first call `useWineOfferRequestNewsletterSendToPotentialProducersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestNewsletterSendToPotentialProducersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOfferRequestNewsletterSendToPotentialProducersMutation, { data, loading, error }] = useWineOfferRequestNewsletterSendToPotentialProducersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOfferRequestNewsletterSendToPotentialProducersMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOfferRequestNewsletterSendToPotentialProducersMutation, Types.WineOfferRequestNewsletterSendToPotentialProducersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOfferRequestNewsletterSendToPotentialProducersMutation, Types.WineOfferRequestNewsletterSendToPotentialProducersMutationVariables>(WineOfferRequestNewsletterSendToPotentialProducersDocument, options);
      }
export type WineOfferRequestNewsletterSendToPotentialProducersMutationHookResult = ReturnType<typeof useWineOfferRequestNewsletterSendToPotentialProducersMutation>;
export type WineOfferRequestNewsletterSendToPotentialProducersMutationResult = Apollo.MutationResult<Types.WineOfferRequestNewsletterSendToPotentialProducersMutation>;
export type WineOfferRequestNewsletterSendToPotentialProducersMutationOptions = Apollo.BaseMutationOptions<Types.WineOfferRequestNewsletterSendToPotentialProducersMutation, Types.WineOfferRequestNewsletterSendToPotentialProducersMutationVariables>;
export const AdminPriceSourcesListDocument = gql`
    query adminPriceSourcesList($localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: PriceSourceSort! = UPDATED_AT_DESC, $query: String, $filter: AdminPriceSourceFilter) {
  admin {
    priceSources {
      priceSourcesPagination(
        localeId: $localeId
        page: $page
        perPage: $perPage
        sort: $sort
        query: $query
        filter: $filter
      ) {
        pageInfo {
          totalItems
          totalPages
          page
          perPage
          hasNextPage
          hasPreviousPage
        }
        items {
          ...adminPriceSourcesItem
        }
      }
    }
  }
}
    ${AdminPriceSourcesItemFragmentDoc}`;

/**
 * __useAdminPriceSourcesListQuery__
 *
 * To run a query within a React component, call `useAdminPriceSourcesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPriceSourcesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPriceSourcesListQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminPriceSourcesListQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminPriceSourcesListQuery, Types.AdminPriceSourcesListQueryVariables> & ({ variables: Types.AdminPriceSourcesListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminPriceSourcesListQuery, Types.AdminPriceSourcesListQueryVariables>(AdminPriceSourcesListDocument, options);
      }
export function useAdminPriceSourcesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminPriceSourcesListQuery, Types.AdminPriceSourcesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminPriceSourcesListQuery, Types.AdminPriceSourcesListQueryVariables>(AdminPriceSourcesListDocument, options);
        }
export function useAdminPriceSourcesListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminPriceSourcesListQuery, Types.AdminPriceSourcesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminPriceSourcesListQuery, Types.AdminPriceSourcesListQueryVariables>(AdminPriceSourcesListDocument, options);
        }
export type AdminPriceSourcesListQueryHookResult = ReturnType<typeof useAdminPriceSourcesListQuery>;
export type AdminPriceSourcesListLazyQueryHookResult = ReturnType<typeof useAdminPriceSourcesListLazyQuery>;
export type AdminPriceSourcesListSuspenseQueryHookResult = ReturnType<typeof useAdminPriceSourcesListSuspenseQuery>;
export type AdminPriceSourcesListQueryResult = Apollo.QueryResult<Types.AdminPriceSourcesListQuery, Types.AdminPriceSourcesListQueryVariables>;
export const PriceSourcesFilterDocument = gql`
    query priceSourcesFilter($filterKey: String!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $includeUnusedLabels: Boolean! = true) {
  admin {
    priceSources {
      priceSourcesFilters(
        localeId: $localeId
        filter: {facetsInputs: $facetsInputs}
        query: $query
      ) {
        filterByKey(key: $filterKey) {
          ...facetFreeListFilter
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetFreeListFilterFragmentDoc}
${FacetListFilterFragmentDoc}`;

/**
 * __usePriceSourcesFilterQuery__
 *
 * To run a query within a React component, call `usePriceSourcesFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `usePriceSourcesFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceSourcesFilterQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function usePriceSourcesFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.PriceSourcesFilterQuery, Types.PriceSourcesFilterQueryVariables> & ({ variables: Types.PriceSourcesFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PriceSourcesFilterQuery, Types.PriceSourcesFilterQueryVariables>(PriceSourcesFilterDocument, options);
      }
export function usePriceSourcesFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PriceSourcesFilterQuery, Types.PriceSourcesFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PriceSourcesFilterQuery, Types.PriceSourcesFilterQueryVariables>(PriceSourcesFilterDocument, options);
        }
export function usePriceSourcesFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PriceSourcesFilterQuery, Types.PriceSourcesFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PriceSourcesFilterQuery, Types.PriceSourcesFilterQueryVariables>(PriceSourcesFilterDocument, options);
        }
export type PriceSourcesFilterQueryHookResult = ReturnType<typeof usePriceSourcesFilterQuery>;
export type PriceSourcesFilterLazyQueryHookResult = ReturnType<typeof usePriceSourcesFilterLazyQuery>;
export type PriceSourcesFilterSuspenseQueryHookResult = ReturnType<typeof usePriceSourcesFilterSuspenseQuery>;
export type PriceSourcesFilterQueryResult = Apollo.QueryResult<Types.PriceSourcesFilterQuery, Types.PriceSourcesFilterQueryVariables>;
export const PriceSourcesFiltersDocument = gql`
    query priceSourcesFilters($localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt!, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $includeUnusedLabels: Boolean! = true) {
  admin {
    priceSources {
      priceSourcesFilters(
        localeId: $localeId
        filter: {facetsInputs: $facetsInputs}
        query: $query
      ) {
        count
        updateUserUsernameFilter: filterByKey(key: "updateUserUsername") {
          ...facetFreeListFilter
        }
        merchantFilter: filterByKey(key: "merchant.name") {
          ...facetListFilter
        }
        creationDateFilter: filterByKey(key: "createdAt") {
          ...facetDateRangeFilter
        }
        updatedAtFilter: filterByKey(key: "updatedAt") {
          ...facetDateRangeFilter
        }
      }
    }
  }
}
    ${FacetFreeListFilterFragmentDoc}
${FacetListFilterFragmentDoc}
${FacetDateRangeFilterFragmentDoc}`;

/**
 * __usePriceSourcesFiltersQuery__
 *
 * To run a query within a React component, call `usePriceSourcesFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePriceSourcesFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceSourcesFiltersQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function usePriceSourcesFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.PriceSourcesFiltersQuery, Types.PriceSourcesFiltersQueryVariables> & ({ variables: Types.PriceSourcesFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PriceSourcesFiltersQuery, Types.PriceSourcesFiltersQueryVariables>(PriceSourcesFiltersDocument, options);
      }
export function usePriceSourcesFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PriceSourcesFiltersQuery, Types.PriceSourcesFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PriceSourcesFiltersQuery, Types.PriceSourcesFiltersQueryVariables>(PriceSourcesFiltersDocument, options);
        }
export function usePriceSourcesFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PriceSourcesFiltersQuery, Types.PriceSourcesFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PriceSourcesFiltersQuery, Types.PriceSourcesFiltersQueryVariables>(PriceSourcesFiltersDocument, options);
        }
export type PriceSourcesFiltersQueryHookResult = ReturnType<typeof usePriceSourcesFiltersQuery>;
export type PriceSourcesFiltersLazyQueryHookResult = ReturnType<typeof usePriceSourcesFiltersLazyQuery>;
export type PriceSourcesFiltersSuspenseQueryHookResult = ReturnType<typeof usePriceSourcesFiltersSuspenseQuery>;
export type PriceSourcesFiltersQueryResult = Apollo.QueryResult<Types.PriceSourcesFiltersQuery, Types.PriceSourcesFiltersQueryVariables>;
export const AdminWineRequestsDocument = gql`
    query adminWineRequests($page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $localeId: Int!, $filter: AdminWineOfferRequestFilter, $query: String!, $sort: WineOfferRequestSort! = CREATED_AT_DESC) {
  admin {
    wineOfferRequests {
      wineOfferRequestsPagination(
        page: $page
        perPage: $perPage
        filter: $filter
        query: $query
        sort: $sort
      ) {
        pageInfo {
          totalItems
          totalPages
          page
          perPage
          hasNextPage
          hasPreviousPage
        }
        items {
          ...adminWineRequestsItem
        }
      }
    }
  }
}
    ${AdminWineRequestsItemFragmentDoc}`;

/**
 * __useAdminWineRequestsQuery__
 *
 * To run a query within a React component, call `useAdminWineRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminWineRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminWineRequestsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      localeId: // value for 'localeId'
 *      filter: // value for 'filter'
 *      query: // value for 'query'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAdminWineRequestsQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminWineRequestsQuery, Types.AdminWineRequestsQueryVariables> & ({ variables: Types.AdminWineRequestsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminWineRequestsQuery, Types.AdminWineRequestsQueryVariables>(AdminWineRequestsDocument, options);
      }
export function useAdminWineRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminWineRequestsQuery, Types.AdminWineRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminWineRequestsQuery, Types.AdminWineRequestsQueryVariables>(AdminWineRequestsDocument, options);
        }
export function useAdminWineRequestsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminWineRequestsQuery, Types.AdminWineRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminWineRequestsQuery, Types.AdminWineRequestsQueryVariables>(AdminWineRequestsDocument, options);
        }
export type AdminWineRequestsQueryHookResult = ReturnType<typeof useAdminWineRequestsQuery>;
export type AdminWineRequestsLazyQueryHookResult = ReturnType<typeof useAdminWineRequestsLazyQuery>;
export type AdminWineRequestsSuspenseQueryHookResult = ReturnType<typeof useAdminWineRequestsSuspenseQuery>;
export type AdminWineRequestsQueryResult = Apollo.QueryResult<Types.AdminWineRequestsQuery, Types.AdminWineRequestsQueryVariables>;
export const WineOfferRequestsFiltersDocument = gql`
    query wineOfferRequestsFilters($localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt!, $filter: AdminWineOfferRequestFilter, $query: String, $filterSearch: String, $includeUnusedLabels: Boolean!) {
  admin {
    wineOfferRequests {
      wineOfferRequestsFilters(localeId: $localeId, filter: $filter, query: $query) {
        count
        producerCountryFilter: filterByKey(key: "product.wine.tradeName.country") {
          ...facetListFilter
        }
        producerFilter: filterByKey(key: "product.wine.tradeName") {
          ...facetListFilter
        }
        countryFilter: filterByKey(key: "product.wine.country") {
          ...facetListFilter
        }
        brandFilter: filterByKey(key: "product.wine.brand") {
          ...facetListFilter
        }
        euGITypeFilter: filterByKey(key: "product.wine.nationalGIType.euGIType") {
          ...facetListFilter
        }
        nationalGITypeFilter: filterByKey(key: "product.wine.nationalGIType") {
          ...facetListFilter
        }
        wineRegionEUFilter: filterByKey(key: "product.wine.wineRegionEU") {
          ...facetListFilter
        }
        geoIndicationFilter: filterByKey(key: "product.wine.geoIndication") {
          ...facetListFilter
        }
        specialClassificationFilter: filterByKey(
          key: "product.wine.specialClassification"
        ) {
          ...facetListFilter
        }
        wineTypeFilter: filterByKey(key: "product.wine.wineType") {
          ...facetListFilter
        }
        wineColourFilter: filterByKey(key: "product.wine.wineColor") {
          ...facetListFilter
        }
        sweetnessTypeFilter: filterByKey(key: "product.wine.sweetnessLevel") {
          ...facetListFilter
        }
        vintageFilter: filterByKey(key: "product.wine.vintage") {
          ...facetListFilter
        }
        bottleSizeFilter: filterByKey(key: "bottleSize") {
          ...facetListFilter
        }
        packageTypeFilter: filterByKey(key: "packageType") {
          ...facetListFilter
        }
        grapesFilter: filterByKey(key: "grapeAndMonocepage") {
          ...facetComplexFilter
        }
        certificatesFilter: filterByKey(key: "product.wine.certification") {
          ...facetListFilter
        }
        alcoholLevelFilter: filterByKey(key: "product.alcoholOnLabel") {
          ...facetRangeFilter
        }
        wwlRatingFilter: filterByKey(key: "product.wine.rating") {
          ...facetListFilter
        }
        priceFilter: filterByKey(key: "pricePerBottle") {
          ...facetComplexFilter
        }
        deliveryTermsFilter: filterByKey(key: "incoterms") {
          ...facetListFilter
        }
        distributionFilter: filterByKey(key: "countryOfDelivery") {
          ...facetListFilter
        }
        buyerCompanyUsernameFilter: filterByKey(key: "buyerCompanyUsername") {
          ...facetListFilter
        }
        buyerCompanyCategoryFilter: filterByKey(key: "buyerCompanyCategory") {
          ...facetListFilter
        }
        buyerCompanyCountryFilter: filterByKey(key: "buyerCompanyCountry") {
          ...facetListFilter
        }
        stageFilter: filterByKey(key: "stage") {
          ...facetListFilter
          __typename
        }
        hasOffersFilter: filterByKey(key: "hasOffers") {
          ...facetListFilter
        }
        countryOfShipmentFilter: filterByKey(key: "countryOfShipment") {
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}`;

/**
 * __useWineOfferRequestsFiltersQuery__
 *
 * To run a query within a React component, call `useWineOfferRequestsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineOfferRequestsFiltersQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      query: // value for 'query'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useWineOfferRequestsFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.WineOfferRequestsFiltersQuery, Types.WineOfferRequestsFiltersQueryVariables> & ({ variables: Types.WineOfferRequestsFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineOfferRequestsFiltersQuery, Types.WineOfferRequestsFiltersQueryVariables>(WineOfferRequestsFiltersDocument, options);
      }
export function useWineOfferRequestsFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineOfferRequestsFiltersQuery, Types.WineOfferRequestsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineOfferRequestsFiltersQuery, Types.WineOfferRequestsFiltersQueryVariables>(WineOfferRequestsFiltersDocument, options);
        }
export function useWineOfferRequestsFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineOfferRequestsFiltersQuery, Types.WineOfferRequestsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineOfferRequestsFiltersQuery, Types.WineOfferRequestsFiltersQueryVariables>(WineOfferRequestsFiltersDocument, options);
        }
export type WineOfferRequestsFiltersQueryHookResult = ReturnType<typeof useWineOfferRequestsFiltersQuery>;
export type WineOfferRequestsFiltersLazyQueryHookResult = ReturnType<typeof useWineOfferRequestsFiltersLazyQuery>;
export type WineOfferRequestsFiltersSuspenseQueryHookResult = ReturnType<typeof useWineOfferRequestsFiltersSuspenseQuery>;
export type WineOfferRequestsFiltersQueryResult = Apollo.QueryResult<Types.WineOfferRequestsFiltersQuery, Types.WineOfferRequestsFiltersQueryVariables>;
export const WineOfferRequestsSpecificFilterDocument = gql`
    query wineOfferRequestsSpecificFilter($filterKey: String!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filter: AdminWineOfferRequestFilter, $query: String, $filterSearch: String, $includeUnusedLabels: Boolean! = true) {
  admin {
    wineOfferRequests {
      wineOfferRequestsFilters(localeId: $localeId, filter: $filter, query: $query) {
        filterByKey(key: $filterKey) {
          ...facetFreeListFilter
          ...facetComplexFilter
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetFreeListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetListFilterFragmentDoc}`;

/**
 * __useWineOfferRequestsSpecificFilterQuery__
 *
 * To run a query within a React component, call `useWineOfferRequestsSpecificFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestsSpecificFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineOfferRequestsSpecificFilterQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      query: // value for 'query'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useWineOfferRequestsSpecificFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.WineOfferRequestsSpecificFilterQuery, Types.WineOfferRequestsSpecificFilterQueryVariables> & ({ variables: Types.WineOfferRequestsSpecificFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineOfferRequestsSpecificFilterQuery, Types.WineOfferRequestsSpecificFilterQueryVariables>(WineOfferRequestsSpecificFilterDocument, options);
      }
export function useWineOfferRequestsSpecificFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineOfferRequestsSpecificFilterQuery, Types.WineOfferRequestsSpecificFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineOfferRequestsSpecificFilterQuery, Types.WineOfferRequestsSpecificFilterQueryVariables>(WineOfferRequestsSpecificFilterDocument, options);
        }
export function useWineOfferRequestsSpecificFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineOfferRequestsSpecificFilterQuery, Types.WineOfferRequestsSpecificFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineOfferRequestsSpecificFilterQuery, Types.WineOfferRequestsSpecificFilterQueryVariables>(WineOfferRequestsSpecificFilterDocument, options);
        }
export type WineOfferRequestsSpecificFilterQueryHookResult = ReturnType<typeof useWineOfferRequestsSpecificFilterQuery>;
export type WineOfferRequestsSpecificFilterLazyQueryHookResult = ReturnType<typeof useWineOfferRequestsSpecificFilterLazyQuery>;
export type WineOfferRequestsSpecificFilterSuspenseQueryHookResult = ReturnType<typeof useWineOfferRequestsSpecificFilterSuspenseQuery>;
export type WineOfferRequestsSpecificFilterQueryResult = Apollo.QueryResult<Types.WineOfferRequestsSpecificFilterQuery, Types.WineOfferRequestsSpecificFilterQueryVariables>;
export const BlockWineReviewsDocument = gql`
    mutation blockWineReviews($id: [ID!]!) {
  admin {
    wineReviews {
      block(wineReviewId: $id) {
        records {
          id
          blockedAt
        }
      }
    }
  }
}
    `;
export type BlockWineReviewsMutationFn = Apollo.MutationFunction<Types.BlockWineReviewsMutation, Types.BlockWineReviewsMutationVariables>;

/**
 * __useBlockWineReviewsMutation__
 *
 * To run a mutation, you first call `useBlockWineReviewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockWineReviewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockWineReviewsMutation, { data, loading, error }] = useBlockWineReviewsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBlockWineReviewsMutation(baseOptions?: Apollo.MutationHookOptions<Types.BlockWineReviewsMutation, Types.BlockWineReviewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BlockWineReviewsMutation, Types.BlockWineReviewsMutationVariables>(BlockWineReviewsDocument, options);
      }
export type BlockWineReviewsMutationHookResult = ReturnType<typeof useBlockWineReviewsMutation>;
export type BlockWineReviewsMutationResult = Apollo.MutationResult<Types.BlockWineReviewsMutation>;
export type BlockWineReviewsMutationOptions = Apollo.BaseMutationOptions<Types.BlockWineReviewsMutation, Types.BlockWineReviewsMutationVariables>;
export const ProductRecognitionResultSaveDocument = gql`
    mutation productRecognitionResultSave($input: ProductRecognitionResultSaveInput!) {
  productRecognitionResultSave(input: $input) {
    query {
      _
    }
  }
}
    `;
export type ProductRecognitionResultSaveMutationFn = Apollo.MutationFunction<Types.ProductRecognitionResultSaveMutation, Types.ProductRecognitionResultSaveMutationVariables>;

/**
 * __useProductRecognitionResultSaveMutation__
 *
 * To run a mutation, you first call `useProductRecognitionResultSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductRecognitionResultSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productRecognitionResultSaveMutation, { data, loading, error }] = useProductRecognitionResultSaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductRecognitionResultSaveMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProductRecognitionResultSaveMutation, Types.ProductRecognitionResultSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductRecognitionResultSaveMutation, Types.ProductRecognitionResultSaveMutationVariables>(ProductRecognitionResultSaveDocument, options);
      }
export type ProductRecognitionResultSaveMutationHookResult = ReturnType<typeof useProductRecognitionResultSaveMutation>;
export type ProductRecognitionResultSaveMutationResult = Apollo.MutationResult<Types.ProductRecognitionResultSaveMutation>;
export type ProductRecognitionResultSaveMutationOptions = Apollo.BaseMutationOptions<Types.ProductRecognitionResultSaveMutation, Types.ProductRecognitionResultSaveMutationVariables>;
export const RecognitionResultDocument = gql`
    query recognitionResult($id: ID!, $localeId: Int!) {
  wineReview(id: $id) {
    id
    recognitionResult {
      ... on ProductRecognised {
        bottleImage {
          ...imageSearchResult
        }
        backLabelImage {
          ...imageSearchResult
        }
        frontLabelImage {
          ...imageSearchResult
        }
      }
    }
  }
}
    ${ImageSearchResultFragmentDoc}`;

/**
 * __useRecognitionResultQuery__
 *
 * To run a query within a React component, call `useRecognitionResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecognitionResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecognitionResultQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useRecognitionResultQuery(baseOptions: Apollo.QueryHookOptions<Types.RecognitionResultQuery, Types.RecognitionResultQueryVariables> & ({ variables: Types.RecognitionResultQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RecognitionResultQuery, Types.RecognitionResultQueryVariables>(RecognitionResultDocument, options);
      }
export function useRecognitionResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RecognitionResultQuery, Types.RecognitionResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RecognitionResultQuery, Types.RecognitionResultQueryVariables>(RecognitionResultDocument, options);
        }
export function useRecognitionResultSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RecognitionResultQuery, Types.RecognitionResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RecognitionResultQuery, Types.RecognitionResultQueryVariables>(RecognitionResultDocument, options);
        }
export type RecognitionResultQueryHookResult = ReturnType<typeof useRecognitionResultQuery>;
export type RecognitionResultLazyQueryHookResult = ReturnType<typeof useRecognitionResultLazyQuery>;
export type RecognitionResultSuspenseQueryHookResult = ReturnType<typeof useRecognitionResultSuspenseQuery>;
export type RecognitionResultQueryResult = Apollo.QueryResult<Types.RecognitionResultQuery, Types.RecognitionResultQueryVariables>;
export const UnblockWineReviewsDocument = gql`
    mutation unblockWineReviews($id: [ID!]!) {
  admin {
    wineReviews {
      unblock(wineReviewId: $id) {
        records {
          id
          blockedAt
        }
      }
    }
  }
}
    `;
export type UnblockWineReviewsMutationFn = Apollo.MutationFunction<Types.UnblockWineReviewsMutation, Types.UnblockWineReviewsMutationVariables>;

/**
 * __useUnblockWineReviewsMutation__
 *
 * To run a mutation, you first call `useUnblockWineReviewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockWineReviewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockWineReviewsMutation, { data, loading, error }] = useUnblockWineReviewsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnblockWineReviewsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnblockWineReviewsMutation, Types.UnblockWineReviewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnblockWineReviewsMutation, Types.UnblockWineReviewsMutationVariables>(UnblockWineReviewsDocument, options);
      }
export type UnblockWineReviewsMutationHookResult = ReturnType<typeof useUnblockWineReviewsMutation>;
export type UnblockWineReviewsMutationResult = Apollo.MutationResult<Types.UnblockWineReviewsMutation>;
export type UnblockWineReviewsMutationOptions = Apollo.BaseMutationOptions<Types.UnblockWineReviewsMutation, Types.UnblockWineReviewsMutationVariables>;
export const AdminWineReviewSpecificFilterDocument = gql`
    query adminWineReviewSpecificFilter($filterKey: String!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filter: AdminWineReviewFilter, $query: String, $filterSearch: String, $includeUnusedLabels: Boolean! = true) {
  admin {
    wineReviews {
      reviewsFilters(localeId: $localeId, filter: $filter, query: $query) {
        filterByKey(key: $filterKey) {
          ...facetFreeListFilter
          ...facetListFilter
          ...facetDateRangeFilter
          ...facetRangeFilter
          ...facetComplexFilter
        }
      }
    }
  }
}
    ${FacetFreeListFilterFragmentDoc}
${FacetListFilterFragmentDoc}
${FacetDateRangeFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}`;

/**
 * __useAdminWineReviewSpecificFilterQuery__
 *
 * To run a query within a React component, call `useAdminWineReviewSpecificFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminWineReviewSpecificFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminWineReviewSpecificFilterQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      query: // value for 'query'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useAdminWineReviewSpecificFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminWineReviewSpecificFilterQuery, Types.AdminWineReviewSpecificFilterQueryVariables> & ({ variables: Types.AdminWineReviewSpecificFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminWineReviewSpecificFilterQuery, Types.AdminWineReviewSpecificFilterQueryVariables>(AdminWineReviewSpecificFilterDocument, options);
      }
export function useAdminWineReviewSpecificFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminWineReviewSpecificFilterQuery, Types.AdminWineReviewSpecificFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminWineReviewSpecificFilterQuery, Types.AdminWineReviewSpecificFilterQueryVariables>(AdminWineReviewSpecificFilterDocument, options);
        }
export function useAdminWineReviewSpecificFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminWineReviewSpecificFilterQuery, Types.AdminWineReviewSpecificFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminWineReviewSpecificFilterQuery, Types.AdminWineReviewSpecificFilterQueryVariables>(AdminWineReviewSpecificFilterDocument, options);
        }
export type AdminWineReviewSpecificFilterQueryHookResult = ReturnType<typeof useAdminWineReviewSpecificFilterQuery>;
export type AdminWineReviewSpecificFilterLazyQueryHookResult = ReturnType<typeof useAdminWineReviewSpecificFilterLazyQuery>;
export type AdminWineReviewSpecificFilterSuspenseQueryHookResult = ReturnType<typeof useAdminWineReviewSpecificFilterSuspenseQuery>;
export type AdminWineReviewSpecificFilterQueryResult = Apollo.QueryResult<Types.AdminWineReviewSpecificFilterQuery, Types.AdminWineReviewSpecificFilterQueryVariables>;
export const WineReviewsAdministrationDocument = gql`
    query wineReviewsAdministration($localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: WineReviewSort! = CREATED_AT_DESC, $query: String, $filter: AdminWineReviewFilter) {
  admin {
    wineReviews {
      reviewsPagination(
        localeId: $localeId
        filter: $filter
        page: $page
        perPage: $perPage
        sort: $sort
        query: $query
      ) {
        pageInfo {
          totalItems
          totalPages
          page
          perPage
          hasPreviousPage
          hasNextPage
        }
        items {
          ...wineReviewsAdministrationItem
        }
      }
    }
  }
}
    ${WineReviewsAdministrationItemFragmentDoc}`;

/**
 * __useWineReviewsAdministrationQuery__
 *
 * To run a query within a React component, call `useWineReviewsAdministrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineReviewsAdministrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineReviewsAdministrationQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useWineReviewsAdministrationQuery(baseOptions: Apollo.QueryHookOptions<Types.WineReviewsAdministrationQuery, Types.WineReviewsAdministrationQueryVariables> & ({ variables: Types.WineReviewsAdministrationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineReviewsAdministrationQuery, Types.WineReviewsAdministrationQueryVariables>(WineReviewsAdministrationDocument, options);
      }
export function useWineReviewsAdministrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineReviewsAdministrationQuery, Types.WineReviewsAdministrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineReviewsAdministrationQuery, Types.WineReviewsAdministrationQueryVariables>(WineReviewsAdministrationDocument, options);
        }
export function useWineReviewsAdministrationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineReviewsAdministrationQuery, Types.WineReviewsAdministrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineReviewsAdministrationQuery, Types.WineReviewsAdministrationQueryVariables>(WineReviewsAdministrationDocument, options);
        }
export type WineReviewsAdministrationQueryHookResult = ReturnType<typeof useWineReviewsAdministrationQuery>;
export type WineReviewsAdministrationLazyQueryHookResult = ReturnType<typeof useWineReviewsAdministrationLazyQuery>;
export type WineReviewsAdministrationSuspenseQueryHookResult = ReturnType<typeof useWineReviewsAdministrationSuspenseQuery>;
export type WineReviewsAdministrationQueryResult = Apollo.QueryResult<Types.WineReviewsAdministrationQuery, Types.WineReviewsAdministrationQueryVariables>;
export const AdminWineReviewsFiltersDocument = gql`
    query adminWineReviewsFilters($localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt!, $filterSearch: String, $filter: AdminWineReviewFilter, $query: String, $includeUnusedLabels: Boolean! = true) {
  admin {
    wineReviews {
      reviewsFilters(localeId: $localeId, filter: $filter, query: $query) {
        count
        usersFilter: filterByKey(key: "authorUsername") {
          ...facetFreeListFilter
        }
        bottleSizeFilter: filterByKey(key: "product.bottleSize") {
          ...facetListFilter
        }
        packageTypeFilter: filterByKey(key: "packageType") {
          ...facetListFilter
        }
        createdAtFilter: filterByKey(key: "createdAt") {
          ...facetDateRangeFilter
        }
        drinkingWindowFilter: filterByKey(key: "drinkingWindow") {
          ...facetRangeFilter
        }
        producerFilter: filterByKey(key: "product.wine.tradeName") {
          ...facetListFilter
        }
        countryFilter: filterByKey(key: "product.wine.country") {
          ...facetListFilter
        }
        brandFilter: filterByKey(key: "product.wine.brand") {
          ...facetListFilter
        }
        nationalGITypeFilter: filterByKey(key: "product.wine.nationalGIType") {
          ...facetListFilter
        }
        euGITypeFilter: filterByKey(key: "product.wine.nationalGIType.euGIType") {
          ...facetListFilter
        }
        wineRegionEUFilter: filterByKey(key: "product.wine.wineRegionEU") {
          ...facetListFilter
        }
        geoIndicationFilter: filterByKey(key: "product.wine.geoIndication") {
          ...facetListFilter
        }
        specialClassificationFilter: filterByKey(
          key: "product.wine.specialClassification"
        ) {
          ...facetListFilter
        }
        wineTypeFilter: filterByKey(key: "product.wine.wineType") {
          ...facetListFilter
        }
        wineColourFilter: filterByKey(key: "product.wine.wineColor") {
          ...facetListFilter
        }
        sweetnessTypeFilter: filterByKey(key: "product.wine.sweetnessLevel") {
          ...facetListFilter
        }
        vintageFilter: filterByKey(key: "product.wine.vintage") {
          ...facetListFilter
        }
        grapesFilter: filterByKey(key: "grapeAndMonocepage") {
          ...facetComplexFilter
        }
        additionalInfoFilter: filterByKey(key: "product.wine.certification") {
          ...facetListFilter
        }
        alcoholLevelFilter: filterByKey(key: "product.alcoholOnLabel") {
          ...facetRangeFilter
        }
        wwlRatingFilter: filterByKey(key: "product.wine.rating") {
          ...facetListFilter
        }
        recognitionStatusFilter: filterByKey(key: "recognitionStatus") {
          ...facetListFilter
        }
        statusFilter: filterByKey(key: "status") {
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetFreeListFilterFragmentDoc}
${FacetListFilterFragmentDoc}
${FacetDateRangeFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}`;

/**
 * __useAdminWineReviewsFiltersQuery__
 *
 * To run a query within a React component, call `useAdminWineReviewsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminWineReviewsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminWineReviewsFiltersQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      filter: // value for 'filter'
 *      query: // value for 'query'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useAdminWineReviewsFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminWineReviewsFiltersQuery, Types.AdminWineReviewsFiltersQueryVariables> & ({ variables: Types.AdminWineReviewsFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminWineReviewsFiltersQuery, Types.AdminWineReviewsFiltersQueryVariables>(AdminWineReviewsFiltersDocument, options);
      }
export function useAdminWineReviewsFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminWineReviewsFiltersQuery, Types.AdminWineReviewsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminWineReviewsFiltersQuery, Types.AdminWineReviewsFiltersQueryVariables>(AdminWineReviewsFiltersDocument, options);
        }
export function useAdminWineReviewsFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminWineReviewsFiltersQuery, Types.AdminWineReviewsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminWineReviewsFiltersQuery, Types.AdminWineReviewsFiltersQueryVariables>(AdminWineReviewsFiltersDocument, options);
        }
export type AdminWineReviewsFiltersQueryHookResult = ReturnType<typeof useAdminWineReviewsFiltersQuery>;
export type AdminWineReviewsFiltersLazyQueryHookResult = ReturnType<typeof useAdminWineReviewsFiltersLazyQuery>;
export type AdminWineReviewsFiltersSuspenseQueryHookResult = ReturnType<typeof useAdminWineReviewsFiltersSuspenseQuery>;
export type AdminWineReviewsFiltersQueryResult = Apollo.QueryResult<Types.AdminWineReviewsFiltersQuery, Types.AdminWineReviewsFiltersQueryVariables>;
export const ActivateUserDocument = gql`
    mutation activateUser($userId: [ID!]!, $localeId: Int!) {
  admin {
    users {
      activate(userId: $userId) {
        records {
          id
          status {
            id
            localizedName(localeId: $localeId)
            code
          }
        }
      }
    }
  }
}
    `;
export type ActivateUserMutationFn = Apollo.MutationFunction<Types.ActivateUserMutation, Types.ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.ActivateUserMutation, Types.ActivateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ActivateUserMutation, Types.ActivateUserMutationVariables>(ActivateUserDocument, options);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = Apollo.MutationResult<Types.ActivateUserMutation>;
export type ActivateUserMutationOptions = Apollo.BaseMutationOptions<Types.ActivateUserMutation, Types.ActivateUserMutationVariables>;
export const AdminArchiveUserDocument = gql`
    mutation adminArchiveUser($userId: [ID!]!, $reason: String) {
  admin {
    users {
      archive(userId: $userId, reason: $reason) {
        records {
          id
          status {
            id
            code
          }
          statusUpdateUser {
            id
            username
          }
          statusComment
        }
      }
    }
  }
}
    `;
export type AdminArchiveUserMutationFn = Apollo.MutationFunction<Types.AdminArchiveUserMutation, Types.AdminArchiveUserMutationVariables>;

/**
 * __useAdminArchiveUserMutation__
 *
 * To run a mutation, you first call `useAdminArchiveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminArchiveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminArchiveUserMutation, { data, loading, error }] = useAdminArchiveUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useAdminArchiveUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminArchiveUserMutation, Types.AdminArchiveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminArchiveUserMutation, Types.AdminArchiveUserMutationVariables>(AdminArchiveUserDocument, options);
      }
export type AdminArchiveUserMutationHookResult = ReturnType<typeof useAdminArchiveUserMutation>;
export type AdminArchiveUserMutationResult = Apollo.MutationResult<Types.AdminArchiveUserMutation>;
export type AdminArchiveUserMutationOptions = Apollo.BaseMutationOptions<Types.AdminArchiveUserMutation, Types.AdminArchiveUserMutationVariables>;
export const AdminBanUserDocument = gql`
    mutation adminBanUser($userId: [ID!]!, $reason: String, $localeId: Int!) {
  admin {
    users {
      ban(userId: $userId, reason: $reason) {
        records {
          id
          blockedReason
          status {
            id
            localizedName(localeId: $localeId)
            code
          }
          statusUpdateUser {
            id
            username
          }
        }
      }
    }
  }
}
    `;
export type AdminBanUserMutationFn = Apollo.MutationFunction<Types.AdminBanUserMutation, Types.AdminBanUserMutationVariables>;

/**
 * __useAdminBanUserMutation__
 *
 * To run a mutation, you first call `useAdminBanUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminBanUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminBanUserMutation, { data, loading, error }] = useAdminBanUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      reason: // value for 'reason'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useAdminBanUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminBanUserMutation, Types.AdminBanUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminBanUserMutation, Types.AdminBanUserMutationVariables>(AdminBanUserDocument, options);
      }
export type AdminBanUserMutationHookResult = ReturnType<typeof useAdminBanUserMutation>;
export type AdminBanUserMutationResult = Apollo.MutationResult<Types.AdminBanUserMutation>;
export type AdminBanUserMutationOptions = Apollo.BaseMutationOptions<Types.AdminBanUserMutation, Types.AdminBanUserMutationVariables>;
export const AdminChangePermissionsDocument = gql`
    mutation adminChangePermissions($input: AdminUserChangePermissionsInput!) {
  admin {
    users {
      changePermissions(input: $input) {
        record {
          id
          permissions {
            reviewsDisplayed
            reviewsConsidered
          }
        }
      }
    }
  }
}
    `;
export type AdminChangePermissionsMutationFn = Apollo.MutationFunction<Types.AdminChangePermissionsMutation, Types.AdminChangePermissionsMutationVariables>;

/**
 * __useAdminChangePermissionsMutation__
 *
 * To run a mutation, you first call `useAdminChangePermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminChangePermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminChangePermissionsMutation, { data, loading, error }] = useAdminChangePermissionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminChangePermissionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminChangePermissionsMutation, Types.AdminChangePermissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminChangePermissionsMutation, Types.AdminChangePermissionsMutationVariables>(AdminChangePermissionsDocument, options);
      }
export type AdminChangePermissionsMutationHookResult = ReturnType<typeof useAdminChangePermissionsMutation>;
export type AdminChangePermissionsMutationResult = Apollo.MutationResult<Types.AdminChangePermissionsMutation>;
export type AdminChangePermissionsMutationOptions = Apollo.BaseMutationOptions<Types.AdminChangePermissionsMutation, Types.AdminChangePermissionsMutationVariables>;
export const AdminChangeUserRoleDocument = gql`
    mutation adminChangeUserRole($userId: ID!, $roleId: Int!, $localeId: Int!) {
  admin {
    users {
      changeRole(userId: $userId, userRoleId: $roleId) {
        user {
          id
          role {
            id
            code
            localizedName(localeId: $localeId)
          }
        }
      }
    }
  }
}
    `;
export type AdminChangeUserRoleMutationFn = Apollo.MutationFunction<Types.AdminChangeUserRoleMutation, Types.AdminChangeUserRoleMutationVariables>;

/**
 * __useAdminChangeUserRoleMutation__
 *
 * To run a mutation, you first call `useAdminChangeUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminChangeUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminChangeUserRoleMutation, { data, loading, error }] = useAdminChangeUserRoleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      roleId: // value for 'roleId'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useAdminChangeUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminChangeUserRoleMutation, Types.AdminChangeUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminChangeUserRoleMutation, Types.AdminChangeUserRoleMutationVariables>(AdminChangeUserRoleDocument, options);
      }
export type AdminChangeUserRoleMutationHookResult = ReturnType<typeof useAdminChangeUserRoleMutation>;
export type AdminChangeUserRoleMutationResult = Apollo.MutationResult<Types.AdminChangeUserRoleMutation>;
export type AdminChangeUserRoleMutationOptions = Apollo.BaseMutationOptions<Types.AdminChangeUserRoleMutation, Types.AdminChangeUserRoleMutationVariables>;
export const AdminRemoveUserBanDocument = gql`
    mutation adminRemoveUserBan($userId: [ID!]!, $localeId: Int!) {
  admin {
    users {
      removeBan(userId: $userId) {
        records {
          id
          status {
            id
            localizedName(localeId: $localeId)
            code
          }
        }
      }
    }
  }
}
    `;
export type AdminRemoveUserBanMutationFn = Apollo.MutationFunction<Types.AdminRemoveUserBanMutation, Types.AdminRemoveUserBanMutationVariables>;

/**
 * __useAdminRemoveUserBanMutation__
 *
 * To run a mutation, you first call `useAdminRemoveUserBanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRemoveUserBanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRemoveUserBanMutation, { data, loading, error }] = useAdminRemoveUserBanMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useAdminRemoveUserBanMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminRemoveUserBanMutation, Types.AdminRemoveUserBanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminRemoveUserBanMutation, Types.AdminRemoveUserBanMutationVariables>(AdminRemoveUserBanDocument, options);
      }
export type AdminRemoveUserBanMutationHookResult = ReturnType<typeof useAdminRemoveUserBanMutation>;
export type AdminRemoveUserBanMutationResult = Apollo.MutationResult<Types.AdminRemoveUserBanMutation>;
export type AdminRemoveUserBanMutationOptions = Apollo.BaseMutationOptions<Types.AdminRemoveUserBanMutation, Types.AdminRemoveUserBanMutationVariables>;
export const AdminUsersFiltersDocument = gql`
    query adminUsersFilters($localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 30, $filterSearch: String, $includeUnusedLabels: Boolean!, $query: String, $filter: UserFilter) {
  admin {
    users {
      usersFilters(localeId: $localeId, filter: $filter, query: $query) {
        count
        createdAtFilter: filterByKey(key: "createdAt") {
          ...facetDateRangeFilter
        }
        permissionFilter: filterByKey(key: "permission") {
          ...facetListFilter
        }
        expertQualificationFilter: filterByKey(key: "expertQualification") {
          ...facetListFilter
        }
        expertVerificationStatusFilter: filterByKey(key: "expertVerificationStatus") {
          ...facetListFilter
        }
        userStatusFilter: filterByKey(key: "status") {
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetDateRangeFilterFragmentDoc}
${FacetListFilterFragmentDoc}`;

/**
 * __useAdminUsersFiltersQuery__
 *
 * To run a query within a React component, call `useAdminUsersFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUsersFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUsersFiltersQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminUsersFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminUsersFiltersQuery, Types.AdminUsersFiltersQueryVariables> & ({ variables: Types.AdminUsersFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminUsersFiltersQuery, Types.AdminUsersFiltersQueryVariables>(AdminUsersFiltersDocument, options);
      }
export function useAdminUsersFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminUsersFiltersQuery, Types.AdminUsersFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminUsersFiltersQuery, Types.AdminUsersFiltersQueryVariables>(AdminUsersFiltersDocument, options);
        }
export function useAdminUsersFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminUsersFiltersQuery, Types.AdminUsersFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminUsersFiltersQuery, Types.AdminUsersFiltersQueryVariables>(AdminUsersFiltersDocument, options);
        }
export type AdminUsersFiltersQueryHookResult = ReturnType<typeof useAdminUsersFiltersQuery>;
export type AdminUsersFiltersLazyQueryHookResult = ReturnType<typeof useAdminUsersFiltersLazyQuery>;
export type AdminUsersFiltersSuspenseQueryHookResult = ReturnType<typeof useAdminUsersFiltersSuspenseQuery>;
export type AdminUsersFiltersQueryResult = Apollo.QueryResult<Types.AdminUsersFiltersQuery, Types.AdminUsersFiltersQueryVariables>;
export const AdminUsersListDocument = gql`
    query adminUsersList($localeId: Int!, $query: String, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: UserSort!, $filter: UserFilter) {
  admin {
    users {
      usersPagination(
        localeId: $localeId
        query: $query
        page: $page
        perPage: $perPage
        sort: $sort
        filter: $filter
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          totalPages
          totalItems
          page
          perPage
        }
        items {
          ...adminUsersListItem
        }
      }
    }
  }
}
    ${AdminUsersListItemFragmentDoc}`;

/**
 * __useAdminUsersListQuery__
 *
 * To run a query within a React component, call `useAdminUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUsersListQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminUsersListQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminUsersListQuery, Types.AdminUsersListQueryVariables> & ({ variables: Types.AdminUsersListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminUsersListQuery, Types.AdminUsersListQueryVariables>(AdminUsersListDocument, options);
      }
export function useAdminUsersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminUsersListQuery, Types.AdminUsersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminUsersListQuery, Types.AdminUsersListQueryVariables>(AdminUsersListDocument, options);
        }
export function useAdminUsersListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminUsersListQuery, Types.AdminUsersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminUsersListQuery, Types.AdminUsersListQueryVariables>(AdminUsersListDocument, options);
        }
export type AdminUsersListQueryHookResult = ReturnType<typeof useAdminUsersListQuery>;
export type AdminUsersListLazyQueryHookResult = ReturnType<typeof useAdminUsersListLazyQuery>;
export type AdminUsersListSuspenseQueryHookResult = ReturnType<typeof useAdminUsersListSuspenseQuery>;
export type AdminUsersListQueryResult = Apollo.QueryResult<Types.AdminUsersListQuery, Types.AdminUsersListQueryVariables>;
export const AdminVerifyExpertDocument = gql`
    mutation adminVerifyExpert($userId: ID!, $comment: String, $expertVerificationStatusId: Int!, $localeId: Int!) {
  admin {
    users {
      verifyExpert(
        userId: $userId
        comment: $comment
        expertVerificationStatusId: $expertVerificationStatusId
      ) {
        record {
          id
          isVerifiedExpert
          expertVerification {
            status {
              id
              localizedName(localeId: $localeId)
              code
            }
            comment
          }
        }
      }
    }
  }
}
    `;
export type AdminVerifyExpertMutationFn = Apollo.MutationFunction<Types.AdminVerifyExpertMutation, Types.AdminVerifyExpertMutationVariables>;

/**
 * __useAdminVerifyExpertMutation__
 *
 * To run a mutation, you first call `useAdminVerifyExpertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminVerifyExpertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminVerifyExpertMutation, { data, loading, error }] = useAdminVerifyExpertMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      comment: // value for 'comment'
 *      expertVerificationStatusId: // value for 'expertVerificationStatusId'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useAdminVerifyExpertMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminVerifyExpertMutation, Types.AdminVerifyExpertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminVerifyExpertMutation, Types.AdminVerifyExpertMutationVariables>(AdminVerifyExpertDocument, options);
      }
export type AdminVerifyExpertMutationHookResult = ReturnType<typeof useAdminVerifyExpertMutation>;
export type AdminVerifyExpertMutationResult = Apollo.MutationResult<Types.AdminVerifyExpertMutation>;
export type AdminVerifyExpertMutationOptions = Apollo.BaseMutationOptions<Types.AdminVerifyExpertMutation, Types.AdminVerifyExpertMutationVariables>;
export const RecoverUserDocument = gql`
    mutation recoverUser($userId: [ID!]!) {
  admin {
    users {
      recover(userId: $userId) {
        records {
          id
        }
      }
    }
  }
}
    `;
export type RecoverUserMutationFn = Apollo.MutationFunction<Types.RecoverUserMutation, Types.RecoverUserMutationVariables>;

/**
 * __useRecoverUserMutation__
 *
 * To run a mutation, you first call `useRecoverUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverUserMutation, { data, loading, error }] = useRecoverUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRecoverUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.RecoverUserMutation, Types.RecoverUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RecoverUserMutation, Types.RecoverUserMutationVariables>(RecoverUserDocument, options);
      }
export type RecoverUserMutationHookResult = ReturnType<typeof useRecoverUserMutation>;
export type RecoverUserMutationResult = Apollo.MutationResult<Types.RecoverUserMutation>;
export type RecoverUserMutationOptions = Apollo.BaseMutationOptions<Types.RecoverUserMutation, Types.RecoverUserMutationVariables>;
export const SpecificFilterUsersFiltersDocument = gql`
    query specificFilterUsersFilters($filterKey: String!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filterSearch: String, $filter: UserFilter, $includeUnusedLabels: Boolean! = true, $query: String) {
  admin {
    users {
      usersFilters(localeId: $localeId, filter: $filter, query: $query) {
        filterByKey(key: $filterKey) {
          ...facetFreeListFilter
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetFreeListFilterFragmentDoc}
${FacetListFilterFragmentDoc}`;

/**
 * __useSpecificFilterUsersFiltersQuery__
 *
 * To run a query within a React component, call `useSpecificFilterUsersFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecificFilterUsersFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecificFilterUsersFiltersQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      filter: // value for 'filter'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSpecificFilterUsersFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.SpecificFilterUsersFiltersQuery, Types.SpecificFilterUsersFiltersQueryVariables> & ({ variables: Types.SpecificFilterUsersFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SpecificFilterUsersFiltersQuery, Types.SpecificFilterUsersFiltersQueryVariables>(SpecificFilterUsersFiltersDocument, options);
      }
export function useSpecificFilterUsersFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SpecificFilterUsersFiltersQuery, Types.SpecificFilterUsersFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SpecificFilterUsersFiltersQuery, Types.SpecificFilterUsersFiltersQueryVariables>(SpecificFilterUsersFiltersDocument, options);
        }
export function useSpecificFilterUsersFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SpecificFilterUsersFiltersQuery, Types.SpecificFilterUsersFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SpecificFilterUsersFiltersQuery, Types.SpecificFilterUsersFiltersQueryVariables>(SpecificFilterUsersFiltersDocument, options);
        }
export type SpecificFilterUsersFiltersQueryHookResult = ReturnType<typeof useSpecificFilterUsersFiltersQuery>;
export type SpecificFilterUsersFiltersLazyQueryHookResult = ReturnType<typeof useSpecificFilterUsersFiltersLazyQuery>;
export type SpecificFilterUsersFiltersSuspenseQueryHookResult = ReturnType<typeof useSpecificFilterUsersFiltersSuspenseQuery>;
export type SpecificFilterUsersFiltersQueryResult = Apollo.QueryResult<Types.SpecificFilterUsersFiltersQuery, Types.SpecificFilterUsersFiltersQueryVariables>;
export const AdminCompaniesListDocument = gql`
    query adminCompaniesList($localeId: Int!, $query: String, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: CompanySort!, $filter: AdminCompanyFilter) {
  admin {
    companies {
      companiesPagination(
        query: $query
        page: $page
        perPage: $perPage
        sort: $sort
        filter: $filter
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          totalPages
          totalItems
          page
          perPage
        }
        items {
          ...adminCompaniesListItem
          companyCategory {
            id
            localizedName(localeId: $localeId)
          }
        }
      }
    }
  }
}
    ${AdminCompaniesListItemFragmentDoc}`;

/**
 * __useAdminCompaniesListQuery__
 *
 * To run a query within a React component, call `useAdminCompaniesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminCompaniesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminCompaniesListQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminCompaniesListQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminCompaniesListQuery, Types.AdminCompaniesListQueryVariables> & ({ variables: Types.AdminCompaniesListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminCompaniesListQuery, Types.AdminCompaniesListQueryVariables>(AdminCompaniesListDocument, options);
      }
export function useAdminCompaniesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminCompaniesListQuery, Types.AdminCompaniesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminCompaniesListQuery, Types.AdminCompaniesListQueryVariables>(AdminCompaniesListDocument, options);
        }
export function useAdminCompaniesListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminCompaniesListQuery, Types.AdminCompaniesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminCompaniesListQuery, Types.AdminCompaniesListQueryVariables>(AdminCompaniesListDocument, options);
        }
export type AdminCompaniesListQueryHookResult = ReturnType<typeof useAdminCompaniesListQuery>;
export type AdminCompaniesListLazyQueryHookResult = ReturnType<typeof useAdminCompaniesListLazyQuery>;
export type AdminCompaniesListSuspenseQueryHookResult = ReturnType<typeof useAdminCompaniesListSuspenseQuery>;
export type AdminCompaniesListQueryResult = Apollo.QueryResult<Types.AdminCompaniesListQuery, Types.AdminCompaniesListQueryVariables>;
export const CompanyVerificationAllowDocument = gql`
    mutation companyVerificationAllow($input: CompanyVerificationAllowInput!) {
  companyVerificationAllow(input: $input) {
    records {
      ...adminCompaniesListItem
    }
  }
}
    ${AdminCompaniesListItemFragmentDoc}`;
export type CompanyVerificationAllowMutationFn = Apollo.MutationFunction<Types.CompanyVerificationAllowMutation, Types.CompanyVerificationAllowMutationVariables>;

/**
 * __useCompanyVerificationAllowMutation__
 *
 * To run a mutation, you first call `useCompanyVerificationAllowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyVerificationAllowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyVerificationAllowMutation, { data, loading, error }] = useCompanyVerificationAllowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyVerificationAllowMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompanyVerificationAllowMutation, Types.CompanyVerificationAllowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompanyVerificationAllowMutation, Types.CompanyVerificationAllowMutationVariables>(CompanyVerificationAllowDocument, options);
      }
export type CompanyVerificationAllowMutationHookResult = ReturnType<typeof useCompanyVerificationAllowMutation>;
export type CompanyVerificationAllowMutationResult = Apollo.MutationResult<Types.CompanyVerificationAllowMutation>;
export type CompanyVerificationAllowMutationOptions = Apollo.BaseMutationOptions<Types.CompanyVerificationAllowMutation, Types.CompanyVerificationAllowMutationVariables>;
export const ProhibitCompanyDocument = gql`
    mutation prohibitCompany($input: CompanyVerificationProhibitInput!) {
  companyVerificationProhibit(input: $input) {
    records {
      ...adminCompaniesListItem
    }
  }
}
    ${AdminCompaniesListItemFragmentDoc}`;
export type ProhibitCompanyMutationFn = Apollo.MutationFunction<Types.ProhibitCompanyMutation, Types.ProhibitCompanyMutationVariables>;

/**
 * __useProhibitCompanyMutation__
 *
 * To run a mutation, you first call `useProhibitCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProhibitCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prohibitCompanyMutation, { data, loading, error }] = useProhibitCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProhibitCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProhibitCompanyMutation, Types.ProhibitCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProhibitCompanyMutation, Types.ProhibitCompanyMutationVariables>(ProhibitCompanyDocument, options);
      }
export type ProhibitCompanyMutationHookResult = ReturnType<typeof useProhibitCompanyMutation>;
export type ProhibitCompanyMutationResult = Apollo.MutationResult<Types.ProhibitCompanyMutation>;
export type ProhibitCompanyMutationOptions = Apollo.BaseMutationOptions<Types.ProhibitCompanyMutation, Types.ProhibitCompanyMutationVariables>;
export const RejectCompanyDocument = gql`
    mutation rejectCompany($input: CompanyVerificationRejectInput!) {
  companyVerificationReject(input: $input) {
    records {
      ...adminCompaniesListItem
    }
  }
}
    ${AdminCompaniesListItemFragmentDoc}`;
export type RejectCompanyMutationFn = Apollo.MutationFunction<Types.RejectCompanyMutation, Types.RejectCompanyMutationVariables>;

/**
 * __useRejectCompanyMutation__
 *
 * To run a mutation, you first call `useRejectCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectCompanyMutation, { data, loading, error }] = useRejectCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.RejectCompanyMutation, Types.RejectCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RejectCompanyMutation, Types.RejectCompanyMutationVariables>(RejectCompanyDocument, options);
      }
export type RejectCompanyMutationHookResult = ReturnType<typeof useRejectCompanyMutation>;
export type RejectCompanyMutationResult = Apollo.MutationResult<Types.RejectCompanyMutation>;
export type RejectCompanyMutationOptions = Apollo.BaseMutationOptions<Types.RejectCompanyMutation, Types.RejectCompanyMutationVariables>;
export const VerificationFilterDataDocument = gql`
    query verificationFilterData($filterKey: String!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filterSearch: String, $facetsInputs: [FacetInput!], $includeUnusedLabels: Boolean! = true) {
  admin {
    companies {
      companiesFilters(localeId: $localeId, filter: {facetsInputs: $facetsInputs}) {
        filterByKey(key: $filterKey) {
          ...facetFreeListFilter
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetFreeListFilterFragmentDoc}
${FacetListFilterFragmentDoc}`;

/**
 * __useVerificationFilterDataQuery__
 *
 * To run a query within a React component, call `useVerificationFilterDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerificationFilterDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerificationFilterDataQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useVerificationFilterDataQuery(baseOptions: Apollo.QueryHookOptions<Types.VerificationFilterDataQuery, Types.VerificationFilterDataQueryVariables> & ({ variables: Types.VerificationFilterDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.VerificationFilterDataQuery, Types.VerificationFilterDataQueryVariables>(VerificationFilterDataDocument, options);
      }
export function useVerificationFilterDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.VerificationFilterDataQuery, Types.VerificationFilterDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.VerificationFilterDataQuery, Types.VerificationFilterDataQueryVariables>(VerificationFilterDataDocument, options);
        }
export function useVerificationFilterDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.VerificationFilterDataQuery, Types.VerificationFilterDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.VerificationFilterDataQuery, Types.VerificationFilterDataQueryVariables>(VerificationFilterDataDocument, options);
        }
export type VerificationFilterDataQueryHookResult = ReturnType<typeof useVerificationFilterDataQuery>;
export type VerificationFilterDataLazyQueryHookResult = ReturnType<typeof useVerificationFilterDataLazyQuery>;
export type VerificationFilterDataSuspenseQueryHookResult = ReturnType<typeof useVerificationFilterDataSuspenseQuery>;
export type VerificationFilterDataQueryResult = Apollo.QueryResult<Types.VerificationFilterDataQuery, Types.VerificationFilterDataQueryVariables>;
export const VerificationFiltersDataDocument = gql`
    query verificationFiltersData($localeId: Int!, $facetsInputs: [FacetInput!], $page: PositiveInt! = 1, $perPage: PositiveInt! = 30, $filterSearch: String, $includeUnusedLabels: Boolean!, $query: String) {
  admin {
    companies {
      companiesFilters(
        localeId: $localeId
        filter: {facetsInputs: $facetsInputs}
        query: $query
      ) {
        count
        usersFilter: filterByKey(key: "user.username") {
          ...facetFreeListFilter
        }
        verificationStatusFilter: filterByKey(key: "companyVerification") {
          ...facetListFilter
        }
        companyCategoryFilter: filterByKey(key: "companyCategory") {
          ...facetListFilter
        }
        stripeIdStatusFilter: filterByKey(key: "eLabelSubscription.status") {
          ...facetListFilter
        }
        haveELabelStripeCustomerIdFilter: filterByKey(key: "haveELabelStripeCustomerId") {
          ...facetListFilter
        }
        createdAtFilter: filterByKey(key: "createdAt") {
          ...facetDateRangeFilter
        }
      }
    }
  }
}
    ${FacetFreeListFilterFragmentDoc}
${FacetListFilterFragmentDoc}
${FacetDateRangeFilterFragmentDoc}`;

/**
 * __useVerificationFiltersDataQuery__
 *
 * To run a query within a React component, call `useVerificationFiltersDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerificationFiltersDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerificationFiltersDataQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      facetsInputs: // value for 'facetsInputs'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useVerificationFiltersDataQuery(baseOptions: Apollo.QueryHookOptions<Types.VerificationFiltersDataQuery, Types.VerificationFiltersDataQueryVariables> & ({ variables: Types.VerificationFiltersDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.VerificationFiltersDataQuery, Types.VerificationFiltersDataQueryVariables>(VerificationFiltersDataDocument, options);
      }
export function useVerificationFiltersDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.VerificationFiltersDataQuery, Types.VerificationFiltersDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.VerificationFiltersDataQuery, Types.VerificationFiltersDataQueryVariables>(VerificationFiltersDataDocument, options);
        }
export function useVerificationFiltersDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.VerificationFiltersDataQuery, Types.VerificationFiltersDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.VerificationFiltersDataQuery, Types.VerificationFiltersDataQueryVariables>(VerificationFiltersDataDocument, options);
        }
export type VerificationFiltersDataQueryHookResult = ReturnType<typeof useVerificationFiltersDataQuery>;
export type VerificationFiltersDataLazyQueryHookResult = ReturnType<typeof useVerificationFiltersDataLazyQuery>;
export type VerificationFiltersDataSuspenseQueryHookResult = ReturnType<typeof useVerificationFiltersDataSuspenseQuery>;
export type VerificationFiltersDataQueryResult = Apollo.QueryResult<Types.VerificationFiltersDataQuery, Types.VerificationFiltersDataQueryVariables>;
export const VerifyCompanyDocument = gql`
    mutation verifyCompany($input: CompanyVerificationAcceptInput!) {
  companyVerificationAccept(input: $input) {
    records {
      ...adminCompaniesListItem
    }
  }
}
    ${AdminCompaniesListItemFragmentDoc}`;
export type VerifyCompanyMutationFn = Apollo.MutationFunction<Types.VerifyCompanyMutation, Types.VerifyCompanyMutationVariables>;

/**
 * __useVerifyCompanyMutation__
 *
 * To run a mutation, you first call `useVerifyCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyCompanyMutation, { data, loading, error }] = useVerifyCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.VerifyCompanyMutation, Types.VerifyCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.VerifyCompanyMutation, Types.VerifyCompanyMutationVariables>(VerifyCompanyDocument, options);
      }
export type VerifyCompanyMutationHookResult = ReturnType<typeof useVerifyCompanyMutation>;
export type VerifyCompanyMutationResult = Apollo.MutationResult<Types.VerifyCompanyMutation>;
export type VerifyCompanyMutationOptions = Apollo.BaseMutationOptions<Types.VerifyCompanyMutation, Types.VerifyCompanyMutationVariables>;
export const BrandAcceptDocument = gql`
    mutation brandAccept($input: BrandAcceptInput!) {
  brandAccept(input: $input) {
    recordId
    record {
      id
      acceptability
      verified
      canOverrideAcceptability
      shouldOverrideAcceptability
      updatedAt
    }
  }
}
    `;
export type BrandAcceptMutationFn = Apollo.MutationFunction<Types.BrandAcceptMutation, Types.BrandAcceptMutationVariables>;

/**
 * __useBrandAcceptMutation__
 *
 * To run a mutation, you first call `useBrandAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrandAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brandAcceptMutation, { data, loading, error }] = useBrandAcceptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBrandAcceptMutation(baseOptions?: Apollo.MutationHookOptions<Types.BrandAcceptMutation, Types.BrandAcceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BrandAcceptMutation, Types.BrandAcceptMutationVariables>(BrandAcceptDocument, options);
      }
export type BrandAcceptMutationHookResult = ReturnType<typeof useBrandAcceptMutation>;
export type BrandAcceptMutationResult = Apollo.MutationResult<Types.BrandAcceptMutation>;
export type BrandAcceptMutationOptions = Apollo.BaseMutationOptions<Types.BrandAcceptMutation, Types.BrandAcceptMutationVariables>;
export const BrandCreateDocument = gql`
    mutation brandCreate($input: BrandCreateInput!, $localeId: Int!) {
  brandCreate(input: $input) {
    record {
      id
      name
      localizedName(localeId: $localeId)
    }
  }
}
    `;
export type BrandCreateMutationFn = Apollo.MutationFunction<Types.BrandCreateMutation, Types.BrandCreateMutationVariables>;

/**
 * __useBrandCreateMutation__
 *
 * To run a mutation, you first call `useBrandCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrandCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brandCreateMutation, { data, loading, error }] = useBrandCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useBrandCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.BrandCreateMutation, Types.BrandCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BrandCreateMutation, Types.BrandCreateMutationVariables>(BrandCreateDocument, options);
      }
export type BrandCreateMutationHookResult = ReturnType<typeof useBrandCreateMutation>;
export type BrandCreateMutationResult = Apollo.MutationResult<Types.BrandCreateMutation>;
export type BrandCreateMutationOptions = Apollo.BaseMutationOptions<Types.BrandCreateMutation, Types.BrandCreateMutationVariables>;
export const BrandRefuseDocument = gql`
    mutation brandRefuse($input: BrandRefuseInput!) {
  brandRefuse(input: $input) {
    record {
      id
      acceptability
      canOverrideAcceptability
      shouldOverrideAcceptability
      updatedAt
    }
  }
}
    `;
export type BrandRefuseMutationFn = Apollo.MutationFunction<Types.BrandRefuseMutation, Types.BrandRefuseMutationVariables>;

/**
 * __useBrandRefuseMutation__
 *
 * To run a mutation, you first call `useBrandRefuseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrandRefuseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brandRefuseMutation, { data, loading, error }] = useBrandRefuseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBrandRefuseMutation(baseOptions?: Apollo.MutationHookOptions<Types.BrandRefuseMutation, Types.BrandRefuseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BrandRefuseMutation, Types.BrandRefuseMutationVariables>(BrandRefuseDocument, options);
      }
export type BrandRefuseMutationHookResult = ReturnType<typeof useBrandRefuseMutation>;
export type BrandRefuseMutationResult = Apollo.MutationResult<Types.BrandRefuseMutation>;
export type BrandRefuseMutationOptions = Apollo.BaseMutationOptions<Types.BrandRefuseMutation, Types.BrandRefuseMutationVariables>;
export const ExpertQualificationsDocument = gql`
    query expertQualifications($localeId: Int!) {
  expertQualifications {
    id
    localizedName(localeId: $localeId)
  }
}
    `;

/**
 * __useExpertQualificationsQuery__
 *
 * To run a query within a React component, call `useExpertQualificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpertQualificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertQualificationsQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useExpertQualificationsQuery(baseOptions: Apollo.QueryHookOptions<Types.ExpertQualificationsQuery, Types.ExpertQualificationsQueryVariables> & ({ variables: Types.ExpertQualificationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExpertQualificationsQuery, Types.ExpertQualificationsQueryVariables>(ExpertQualificationsDocument, options);
      }
export function useExpertQualificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExpertQualificationsQuery, Types.ExpertQualificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExpertQualificationsQuery, Types.ExpertQualificationsQueryVariables>(ExpertQualificationsDocument, options);
        }
export function useExpertQualificationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ExpertQualificationsQuery, Types.ExpertQualificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ExpertQualificationsQuery, Types.ExpertQualificationsQueryVariables>(ExpertQualificationsDocument, options);
        }
export type ExpertQualificationsQueryHookResult = ReturnType<typeof useExpertQualificationsQuery>;
export type ExpertQualificationsLazyQueryHookResult = ReturnType<typeof useExpertQualificationsLazyQuery>;
export type ExpertQualificationsSuspenseQueryHookResult = ReturnType<typeof useExpertQualificationsSuspenseQuery>;
export type ExpertQualificationsQueryResult = Apollo.QueryResult<Types.ExpertQualificationsQuery, Types.ExpertQualificationsQueryVariables>;
export const AppellationRatingFilterDocument = gql`
    query appellationRatingFilter($filterKey: String!, $localeId: Int!, $slug: String!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $geoIndicationId: Int!, $companyId: ID!, $facetsInputs: [FacetInput!], $filterSearch: String, $includeUnusedLabels: Boolean, $wineOfferStatusIds: [Int!], $wineOfferTypeIds: [Int!]) {
  productBySlug(slug: $slug) {
    id
    appellationRating(geoIndicationId: $geoIndicationId) {
      filters(
        localeId: $localeId
        filter: {facetsInputs: $facetsInputs, wineOfferStatusIds: $wineOfferStatusIds, wineOfferTypeIds: $wineOfferTypeIds}
        companyId: $companyId
      ) {
        filterByKey(key: $filterKey) {
          ...facetFreeListFilter
          ...facetListFilter
          ...facetComplexFilter
        }
      }
    }
  }
}
    ${FacetFreeListFilterFragmentDoc}
${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}`;

/**
 * __useAppellationRatingFilterQuery__
 *
 * To run a query within a React component, call `useAppellationRatingFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppellationRatingFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppellationRatingFilterQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      slug: // value for 'slug'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      geoIndicationId: // value for 'geoIndicationId'
 *      companyId: // value for 'companyId'
 *      facetsInputs: // value for 'facetsInputs'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *      wineOfferStatusIds: // value for 'wineOfferStatusIds'
 *      wineOfferTypeIds: // value for 'wineOfferTypeIds'
 *   },
 * });
 */
export function useAppellationRatingFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.AppellationRatingFilterQuery, Types.AppellationRatingFilterQueryVariables> & ({ variables: Types.AppellationRatingFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AppellationRatingFilterQuery, Types.AppellationRatingFilterQueryVariables>(AppellationRatingFilterDocument, options);
      }
export function useAppellationRatingFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AppellationRatingFilterQuery, Types.AppellationRatingFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AppellationRatingFilterQuery, Types.AppellationRatingFilterQueryVariables>(AppellationRatingFilterDocument, options);
        }
export function useAppellationRatingFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AppellationRatingFilterQuery, Types.AppellationRatingFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AppellationRatingFilterQuery, Types.AppellationRatingFilterQueryVariables>(AppellationRatingFilterDocument, options);
        }
export type AppellationRatingFilterQueryHookResult = ReturnType<typeof useAppellationRatingFilterQuery>;
export type AppellationRatingFilterLazyQueryHookResult = ReturnType<typeof useAppellationRatingFilterLazyQuery>;
export type AppellationRatingFilterSuspenseQueryHookResult = ReturnType<typeof useAppellationRatingFilterSuspenseQuery>;
export type AppellationRatingFilterQueryResult = Apollo.QueryResult<Types.AppellationRatingFilterQuery, Types.AppellationRatingFilterQueryVariables>;
export const AppellationRatingFiltersDocument = gql`
    query appellationRatingFilters($slug: String!, $page: PositiveInt!, $perPage: PositiveInt!, $geoIndicationId: Int!, $localeId: Int!, $companyId: ID!, $facetsInputs: [FacetInput!], $filterSearch: String, $includeUnusedLabels: Boolean, $query: String, $wineOfferStatusIds: [Int!], $wineOfferTypeIds: [Int!]) {
  productBySlug(slug: $slug) {
    id
    appellationRating(geoIndicationId: $geoIndicationId) {
      filters(
        localeId: $localeId
        filter: {facetsInputs: $facetsInputs, wineOfferStatusIds: $wineOfferStatusIds, wineOfferTypeIds: $wineOfferTypeIds}
        companyId: $companyId
        query: $query
      ) {
        count
        producerFilter: filterByKey(key: "wine.tradeName") {
          ...facetListFilter
        }
        brandFilter: filterByKey(key: "wine.brand") {
          ...facetListFilter
        }
        countryFilter: filterByKey(key: "wine.country") {
          ...facetListFilter
        }
        specialClassificationFilter: filterByKey(key: "wine.specialClassification") {
          ...facetListFilter
        }
        vintageFilter: filterByKey(key: "wine.vintage") {
          ...facetListFilter
        }
        grapesFilter: filterByKey(key: "grapeAndMonocepage") {
          ...facetComplexFilter
        }
        certificatesFilter: filterByKey(key: "wine.certification") {
          ...facetListFilter
        }
        alcoholLevelFilter: filterByKey(key: "alcoholOnLabel") {
          ...facetRangeFilter
        }
        wwlRatingFilter: filterByKey(key: "wine.rating") {
          ...facetListFilter
        }
        rrpFilter: filterByKey(key: "priceAtWinery") {
          ...facetComplexFilter
        }
        rankFilter: filterByKey(key: "appellationRating.rank") {
          ...facetRangeFilter
        }
        priceFilter: filterByKey(key: "wineOffers.pricePerBottle") {
          ...facetComplexFilter
        }
        deliveryTermsFilter: filterByKey(key: "wineOffers.incoterms") {
          ...facetListFilter
        }
        countryOfShipmentFilter: filterByKey(key: "wineOffers.countryOfShipment") {
          ...facetListFilter
        }
        priceCoefFilter: filterByKey(key: "wineOffers.priceCoefficient") {
          ...facetRangeFilter
        }
        exhibitionFilter: filterByKey(key: "wineOffers.exhibition") {
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}`;

/**
 * __useAppellationRatingFiltersQuery__
 *
 * To run a query within a React component, call `useAppellationRatingFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppellationRatingFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppellationRatingFiltersQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      geoIndicationId: // value for 'geoIndicationId'
 *      localeId: // value for 'localeId'
 *      companyId: // value for 'companyId'
 *      facetsInputs: // value for 'facetsInputs'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *      query: // value for 'query'
 *      wineOfferStatusIds: // value for 'wineOfferStatusIds'
 *      wineOfferTypeIds: // value for 'wineOfferTypeIds'
 *   },
 * });
 */
export function useAppellationRatingFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.AppellationRatingFiltersQuery, Types.AppellationRatingFiltersQueryVariables> & ({ variables: Types.AppellationRatingFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AppellationRatingFiltersQuery, Types.AppellationRatingFiltersQueryVariables>(AppellationRatingFiltersDocument, options);
      }
export function useAppellationRatingFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AppellationRatingFiltersQuery, Types.AppellationRatingFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AppellationRatingFiltersQuery, Types.AppellationRatingFiltersQueryVariables>(AppellationRatingFiltersDocument, options);
        }
export function useAppellationRatingFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AppellationRatingFiltersQuery, Types.AppellationRatingFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AppellationRatingFiltersQuery, Types.AppellationRatingFiltersQueryVariables>(AppellationRatingFiltersDocument, options);
        }
export type AppellationRatingFiltersQueryHookResult = ReturnType<typeof useAppellationRatingFiltersQuery>;
export type AppellationRatingFiltersLazyQueryHookResult = ReturnType<typeof useAppellationRatingFiltersLazyQuery>;
export type AppellationRatingFiltersSuspenseQueryHookResult = ReturnType<typeof useAppellationRatingFiltersSuspenseQuery>;
export type AppellationRatingFiltersQueryResult = Apollo.QueryResult<Types.AppellationRatingFiltersQuery, Types.AppellationRatingFiltersQueryVariables>;
export const BuyWineAnalaysisFiltersDocument = gql`
    query buyWineAnalaysisFilters($companyId: ID!, $localeId: Int!, $page: PositiveInt, $perPage: PositiveInt! = 50, $query: String, $isBuyer: Boolean!, $includeUnusedLabels: Boolean!, $filter: WineOfferFiltersFilter, $filterSearch: String) {
  me {
    company(id: $companyId) {
      wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: $filter
        query: $query
      ) {
        count
        producerFilter: filterByKey(key: "product.wine.tradeName") {
          ...facetListFilter
        }
        brandFilter: filterByKey(key: "product.wine.brand") {
          ...facetListFilter
        }
        countryFilter: filterByKey(key: "product.wine.country") {
          ...facetListFilter
        }
        distributionFilter: filterByKey(key: "countryOfDelivery") {
          ...facetListFilter
        }
        euGITypeFilter: filterByKey(key: "product.wine.nationalGIType.euGIType") {
          ...facetListFilter
        }
        nationalGITypeFilter: filterByKey(key: "product.wine.nationalGIType") {
          ...facetListFilter
        }
        wineRegionEUFilter: filterByKey(key: "product.wine.wineRegionEU") {
          ...facetListFilter
        }
        geoIndicationFilter: filterByKey(key: "product.wine.geoIndication") {
          ...facetListFilter
        }
        wineTypeFilter: filterByKey(key: "product.wine.wineType") {
          ...facetListFilter
        }
        wineColourFilter: filterByKey(key: "product.wine.wineColor") {
          ...facetListFilter
        }
        vintageFilter: filterByKey(key: "product.wine.vintage") {
          ...facetListFilter
        }
        sweetnessTypeFilter: filterByKey(key: "product.wine.sweetnessLevel") {
          ...facetListFilter
        }
        certificatesFilter: filterByKey(key: "product.wine.certification") {
          ...facetListFilter
        }
        bottleSizeFilter: filterByKey(key: "bottleSize") {
          ...facetListFilter
        }
        packageTypeFilter: filterByKey(key: "packageType") {
          ...facetListFilter
        }
        grapesFilter: filterByKey(key: "grapeAndMonocepage") {
          ...facetComplexFilter
        }
        rrpFilter: filterByKey(key: "product.priceAtWinery") {
          ...facetComplexFilter
        }
        alcoholLevelFilter: filterByKey(key: "product.alcoholOnLabel") {
          ...facetRangeFilter
        }
        priceCoefFilter: filterByKey(key: "priceCoefficient") {
          ...facetRangeFilter
        }
        rankFilter: filterByKey(key: "product.appellationRating.rank") {
          ...facetRangeFilter
        }
        wwlRatingFilter: filterByKey(key: "product.wine.rating") {
          ...facetListFilter
        }
        deliveryTermsFilter: filterByKey(key: "incoterms") {
          ...facetListFilter
        }
        priceFilter: filterByKey(key: "price") {
          ...facetComplexFilter
        }
        countryOfShipmentFilter: filterByKey(key: "countryOfShipment") {
          ...facetListFilter
        }
        exhibitionFilter: filterByKey(key: "exhibition") {
          ...facetListFilter
        }
        wineOfferTypeDealFilter: filterByKey(key: "wineOfferTypeDeal") {
          ...facetListFilter
        }
        specialClassificationFilter: filterByKey(
          key: "product.wine.specialClassification"
        ) {
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}`;

/**
 * __useBuyWineAnalaysisFiltersQuery__
 *
 * To run a query within a React component, call `useBuyWineAnalaysisFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyWineAnalaysisFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyWineAnalaysisFiltersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      isBuyer: // value for 'isBuyer'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *      filter: // value for 'filter'
 *      filterSearch: // value for 'filterSearch'
 *   },
 * });
 */
export function useBuyWineAnalaysisFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.BuyWineAnalaysisFiltersQuery, Types.BuyWineAnalaysisFiltersQueryVariables> & ({ variables: Types.BuyWineAnalaysisFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyWineAnalaysisFiltersQuery, Types.BuyWineAnalaysisFiltersQueryVariables>(BuyWineAnalaysisFiltersDocument, options);
      }
export function useBuyWineAnalaysisFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyWineAnalaysisFiltersQuery, Types.BuyWineAnalaysisFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyWineAnalaysisFiltersQuery, Types.BuyWineAnalaysisFiltersQueryVariables>(BuyWineAnalaysisFiltersDocument, options);
        }
export function useBuyWineAnalaysisFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyWineAnalaysisFiltersQuery, Types.BuyWineAnalaysisFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyWineAnalaysisFiltersQuery, Types.BuyWineAnalaysisFiltersQueryVariables>(BuyWineAnalaysisFiltersDocument, options);
        }
export type BuyWineAnalaysisFiltersQueryHookResult = ReturnType<typeof useBuyWineAnalaysisFiltersQuery>;
export type BuyWineAnalaysisFiltersLazyQueryHookResult = ReturnType<typeof useBuyWineAnalaysisFiltersLazyQuery>;
export type BuyWineAnalaysisFiltersSuspenseQueryHookResult = ReturnType<typeof useBuyWineAnalaysisFiltersSuspenseQuery>;
export type BuyWineAnalaysisFiltersQueryResult = Apollo.QueryResult<Types.BuyWineAnalaysisFiltersQuery, Types.BuyWineAnalaysisFiltersQueryVariables>;
export const BuyerWineOfferRequestsDocument = gql`
    query buyerWineOfferRequests($localeId: Int!, $companyId: ID!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: WineOfferRequestSort!) {
  me {
    company(id: $companyId) {
      wineOfferRequestsPagination(
        page: $page
        perPage: $perPage
        isBuyer: true
        sort: $sort
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          totalPages
          totalItems
          page
          perPage
        }
        items {
          id
          periodOfValidity
          createdAt
          query
          number
          comment
          matches {
            count
          }
          status
          filters(onlyVisible: false) {
            ... on FacetListFilterSelection {
              key
              byName(localeId: $localeId)
            }
            ... on FacetRangeFilterSelection {
              key
              minValue
              maxValue
            }
            ... on FacetBooleanFilterSelection {
              key
              value
            }
            ... on FacetComplexFilterSelection {
              key
              childrenInputs {
                ... on FacetBooleanFilterSelection {
                  key
                  value
                }
                ... on FacetListFilterSelection {
                  key
                  byName(localeId: $localeId)
                }
                ... on FacetRangeFilterSelection {
                  key
                  minValue
                  maxValue
                }
              }
            }
          }
          product {
            id
            bottleSize {
              id
              volumeML
            }
            packageType {
              id
              isDefault
              shortName
              localizedName(localeId: $localeId)
            }
            wine {
              id
              chemicalAnalysis {
                alcoholVolume
              }
              grapeVarieties {
                grapeVarietySynonym {
                  id
                  name
                }
                value
              }
              nationalGIType {
                id
                localizedName(localeId: $localeId)
                euGIType {
                  id
                  masterName
                }
              }
              nationalGITypeText
              geoIndications {
                id
                localizedName(localeId: $localeId)
                wineRegionsEU {
                  id
                  localizedName(localeId: $localeId)
                }
              }
              geoIndicationText
              vintage
              country {
                id
              }
              tradeName {
                id
                tradeName
                country {
                  id
                  localizedName(localeId: $localeId)
                  codeISO
                }
              }
              wineType {
                id
                localizedName(localeId: $localeId)
              }
              wineColor {
                id
                localizedName(localeId: $localeId)
              }
              brand {
                id
                name
              }
              sweetnessLevel {
                id
                localizedName(localeId: $localeId)
              }
              keyWords
              specialClassifications {
                id
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBuyerWineOfferRequestsQuery__
 *
 * To run a query within a React component, call `useBuyerWineOfferRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyerWineOfferRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyerWineOfferRequestsQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      companyId: // value for 'companyId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useBuyerWineOfferRequestsQuery(baseOptions: Apollo.QueryHookOptions<Types.BuyerWineOfferRequestsQuery, Types.BuyerWineOfferRequestsQueryVariables> & ({ variables: Types.BuyerWineOfferRequestsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyerWineOfferRequestsQuery, Types.BuyerWineOfferRequestsQueryVariables>(BuyerWineOfferRequestsDocument, options);
      }
export function useBuyerWineOfferRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyerWineOfferRequestsQuery, Types.BuyerWineOfferRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyerWineOfferRequestsQuery, Types.BuyerWineOfferRequestsQueryVariables>(BuyerWineOfferRequestsDocument, options);
        }
export function useBuyerWineOfferRequestsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyerWineOfferRequestsQuery, Types.BuyerWineOfferRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyerWineOfferRequestsQuery, Types.BuyerWineOfferRequestsQueryVariables>(BuyerWineOfferRequestsDocument, options);
        }
export type BuyerWineOfferRequestsQueryHookResult = ReturnType<typeof useBuyerWineOfferRequestsQuery>;
export type BuyerWineOfferRequestsLazyQueryHookResult = ReturnType<typeof useBuyerWineOfferRequestsLazyQuery>;
export type BuyerWineOfferRequestsSuspenseQueryHookResult = ReturnType<typeof useBuyerWineOfferRequestsSuspenseQuery>;
export type BuyerWineOfferRequestsQueryResult = Apollo.QueryResult<Types.BuyerWineOfferRequestsQuery, Types.BuyerWineOfferRequestsQueryVariables>;
export const DeleteWineOfferRequestDocument = gql`
    mutation deleteWineOfferRequest($input: WineOfferRequestDeleteInput!) {
  wineOfferRequestDelete(input: $input) {
    query {
      _
    }
  }
}
    `;
export type DeleteWineOfferRequestMutationFn = Apollo.MutationFunction<Types.DeleteWineOfferRequestMutation, Types.DeleteWineOfferRequestMutationVariables>;

/**
 * __useDeleteWineOfferRequestMutation__
 *
 * To run a mutation, you first call `useDeleteWineOfferRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWineOfferRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWineOfferRequestMutation, { data, loading, error }] = useDeleteWineOfferRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWineOfferRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteWineOfferRequestMutation, Types.DeleteWineOfferRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteWineOfferRequestMutation, Types.DeleteWineOfferRequestMutationVariables>(DeleteWineOfferRequestDocument, options);
      }
export type DeleteWineOfferRequestMutationHookResult = ReturnType<typeof useDeleteWineOfferRequestMutation>;
export type DeleteWineOfferRequestMutationResult = Apollo.MutationResult<Types.DeleteWineOfferRequestMutation>;
export type DeleteWineOfferRequestMutationOptions = Apollo.BaseMutationOptions<Types.DeleteWineOfferRequestMutation, Types.DeleteWineOfferRequestMutationVariables>;
export const WineOfferRequestByIdDocument = gql`
    query wineOfferRequestById($id: ID!, $localeId: Int!, $companyId: ID) {
  wineOfferRequest(id: $id) {
    id
    periodOfValidity
    createdAt
    comment
    query
    buyer(companyId: $companyId) {
      ... on Company {
        id
        companyCategory {
          localizedName(localeId: $localeId)
        }
        tradeName
        postAddress
      }
    }
    number
    status
    matches {
      count
    }
    filters(onlyVisible: false) {
      ... on FacetListFilterSelection {
        key
        byName(localeId: $localeId)
      }
      ... on FacetRangeFilterSelection {
        key
        minValue
        maxValue
      }
      ... on FacetBooleanFilterSelection {
        key
        value
      }
      ... on FacetComplexFilterSelection {
        key
        childrenInputs {
          ... on FacetBooleanFilterSelection {
            key
            value
          }
          ... on FacetListFilterSelection {
            key
            byName(localeId: $localeId)
          }
          ... on FacetRangeFilterSelection {
            key
            minValue
            maxValue
          }
        }
      }
    }
    product {
      id
      bottleSize {
        id
        volumeML
      }
      packageType {
        id
        isDefault
        shortName
        localizedName(localeId: $localeId)
      }
      wine {
        id
        chemicalAnalysis {
          alcoholVolume
        }
        grapeVarieties {
          grapeVarietySynonym {
            id
            name
          }
          value
        }
        nationalGIType {
          id
          localizedName(localeId: $localeId)
          euGIType {
            id
            masterName
          }
        }
        nationalGITypeText
        geoIndications {
          id
          localizedName(localeId: $localeId)
          wineRegionsEU {
            id
            localizedName(localeId: $localeId)
          }
        }
        geoIndicationText
        vintage
        country {
          id
        }
        tradeName {
          id
          tradeName
          country {
            id
            localizedName(localeId: $localeId)
            codeISO
          }
        }
        wineType {
          id
          localizedName(localeId: $localeId)
        }
        wineColor {
          id
          localizedName(localeId: $localeId)
        }
        brand {
          id
          name
        }
        sweetnessLevel {
          id
          localizedName(localeId: $localeId)
        }
        keyWords
        specialClassifications {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useWineOfferRequestByIdQuery__
 *
 * To run a query within a React component, call `useWineOfferRequestByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineOfferRequestByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useWineOfferRequestByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.WineOfferRequestByIdQuery, Types.WineOfferRequestByIdQueryVariables> & ({ variables: Types.WineOfferRequestByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineOfferRequestByIdQuery, Types.WineOfferRequestByIdQueryVariables>(WineOfferRequestByIdDocument, options);
      }
export function useWineOfferRequestByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineOfferRequestByIdQuery, Types.WineOfferRequestByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineOfferRequestByIdQuery, Types.WineOfferRequestByIdQueryVariables>(WineOfferRequestByIdDocument, options);
        }
export function useWineOfferRequestByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineOfferRequestByIdQuery, Types.WineOfferRequestByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineOfferRequestByIdQuery, Types.WineOfferRequestByIdQueryVariables>(WineOfferRequestByIdDocument, options);
        }
export type WineOfferRequestByIdQueryHookResult = ReturnType<typeof useWineOfferRequestByIdQuery>;
export type WineOfferRequestByIdLazyQueryHookResult = ReturnType<typeof useWineOfferRequestByIdLazyQuery>;
export type WineOfferRequestByIdSuspenseQueryHookResult = ReturnType<typeof useWineOfferRequestByIdSuspenseQuery>;
export type WineOfferRequestByIdQueryResult = Apollo.QueryResult<Types.WineOfferRequestByIdQuery, Types.WineOfferRequestByIdQueryVariables>;
export const WineOfferRequestUpdatePeriodOfValidityDocument = gql`
    mutation wineOfferRequestUpdatePeriodOfValidity($input: WineOfferRequestUpdatePeriodOfValidityInput!) {
  wineOfferRequestUpdatePeriodOfValidity(input: $input) {
    records {
      id
      periodOfValidity
    }
  }
}
    `;
export type WineOfferRequestUpdatePeriodOfValidityMutationFn = Apollo.MutationFunction<Types.WineOfferRequestUpdatePeriodOfValidityMutation, Types.WineOfferRequestUpdatePeriodOfValidityMutationVariables>;

/**
 * __useWineOfferRequestUpdatePeriodOfValidityMutation__
 *
 * To run a mutation, you first call `useWineOfferRequestUpdatePeriodOfValidityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestUpdatePeriodOfValidityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOfferRequestUpdatePeriodOfValidityMutation, { data, loading, error }] = useWineOfferRequestUpdatePeriodOfValidityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOfferRequestUpdatePeriodOfValidityMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOfferRequestUpdatePeriodOfValidityMutation, Types.WineOfferRequestUpdatePeriodOfValidityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOfferRequestUpdatePeriodOfValidityMutation, Types.WineOfferRequestUpdatePeriodOfValidityMutationVariables>(WineOfferRequestUpdatePeriodOfValidityDocument, options);
      }
export type WineOfferRequestUpdatePeriodOfValidityMutationHookResult = ReturnType<typeof useWineOfferRequestUpdatePeriodOfValidityMutation>;
export type WineOfferRequestUpdatePeriodOfValidityMutationResult = Apollo.MutationResult<Types.WineOfferRequestUpdatePeriodOfValidityMutation>;
export type WineOfferRequestUpdatePeriodOfValidityMutationOptions = Apollo.BaseMutationOptions<Types.WineOfferRequestUpdatePeriodOfValidityMutation, Types.WineOfferRequestUpdatePeriodOfValidityMutationVariables>;
export const AnalogCountsDocument = gql`
    query analogCounts($companyId: ID!, $localeId: Int!, $isBuyer: Boolean!, $wineOfferStatusIds: [Int!], $input1: [FacetInput!], $input2: [FacetInput!], $input3: [FacetInput!], $input4: [FacetInput!], $input5: [FacetInput!], $input6: [FacetInput!], $input7: [FacetInput!], $input8: [FacetInput!], $input9: [FacetInput!], $input10: [FacetInput!], $input11: [FacetInput!], $input12: [FacetInput!], $input13: [FacetInput!], $input14: [FacetInput!], $input15: [FacetInput!], $input16: [FacetInput!], $input17: [FacetInput!], $input18: [FacetInput!], $input19: [FacetInput!], $input20: [FacetInput!]) {
  me {
    company(id: $companyId) {
      analog1: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input1}
      ) {
        count
      }
      analog2: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input2}
      ) {
        count
      }
      analog3: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input3}
      ) {
        count
      }
      analog4: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input4}
      ) {
        count
      }
      analog5: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input5}
      ) {
        count
      }
      analog6: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input6}
      ) {
        count
      }
      analog7: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input7}
      ) {
        count
      }
      analog8: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input8}
      ) {
        count
      }
      analog9: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input9}
      ) {
        count
      }
      analog10: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input10}
      ) {
        count
      }
      analog11: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input11}
      ) {
        count
      }
      analog12: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input12}
      ) {
        count
      }
      analog13: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input13}
      ) {
        count
      }
      analog14: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input14}
      ) {
        count
      }
      analog15: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input15}
      ) {
        count
      }
      analog16: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input16}
      ) {
        count
      }
      analog17: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input17}
      ) {
        count
      }
      analog18: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input18}
      ) {
        count
      }
      analog19: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input19}
      ) {
        count
      }
      analog20: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input20}
      ) {
        count
      }
      __typename
    }
    __typename
  }
}
    `;

/**
 * __useAnalogCountsQuery__
 *
 * To run a query within a React component, call `useAnalogCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalogCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalogCountsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      isBuyer: // value for 'isBuyer'
 *      wineOfferStatusIds: // value for 'wineOfferStatusIds'
 *      input1: // value for 'input1'
 *      input2: // value for 'input2'
 *      input3: // value for 'input3'
 *      input4: // value for 'input4'
 *      input5: // value for 'input5'
 *      input6: // value for 'input6'
 *      input7: // value for 'input7'
 *      input8: // value for 'input8'
 *      input9: // value for 'input9'
 *      input10: // value for 'input10'
 *      input11: // value for 'input11'
 *      input12: // value for 'input12'
 *      input13: // value for 'input13'
 *      input14: // value for 'input14'
 *      input15: // value for 'input15'
 *      input16: // value for 'input16'
 *      input17: // value for 'input17'
 *      input18: // value for 'input18'
 *      input19: // value for 'input19'
 *      input20: // value for 'input20'
 *   },
 * });
 */
export function useAnalogCountsQuery(baseOptions: Apollo.QueryHookOptions<Types.AnalogCountsQuery, Types.AnalogCountsQueryVariables> & ({ variables: Types.AnalogCountsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AnalogCountsQuery, Types.AnalogCountsQueryVariables>(AnalogCountsDocument, options);
      }
export function useAnalogCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AnalogCountsQuery, Types.AnalogCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AnalogCountsQuery, Types.AnalogCountsQueryVariables>(AnalogCountsDocument, options);
        }
export function useAnalogCountsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AnalogCountsQuery, Types.AnalogCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AnalogCountsQuery, Types.AnalogCountsQueryVariables>(AnalogCountsDocument, options);
        }
export type AnalogCountsQueryHookResult = ReturnType<typeof useAnalogCountsQuery>;
export type AnalogCountsLazyQueryHookResult = ReturnType<typeof useAnalogCountsLazyQuery>;
export type AnalogCountsSuspenseQueryHookResult = ReturnType<typeof useAnalogCountsSuspenseQuery>;
export type AnalogCountsQueryResult = Apollo.QueryResult<Types.AnalogCountsQuery, Types.AnalogCountsQueryVariables>;
export const BuyWineOffersAllFilterByKeyDocument = gql`
    query buyWineOffersAllFilterByKey($companyId: ID!, $localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt! = 50, $query: String, $key: String!, $isBuyer: Boolean!, $filter: WineOfferFiltersFilter, $filterSearch: String, $includeUnusedLabels: Boolean! = true) {
  me {
    company(id: $companyId) {
      wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: $filter
        query: $query
      ) {
        filterByKey(key: $key) {
          ...facetComplexFilter
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetComplexFilterFragmentDoc}
${FacetListFilterFragmentDoc}`;

/**
 * __useBuyWineOffersAllFilterByKeyQuery__
 *
 * To run a query within a React component, call `useBuyWineOffersAllFilterByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyWineOffersAllFilterByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyWineOffersAllFilterByKeyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      key: // value for 'key'
 *      isBuyer: // value for 'isBuyer'
 *      filter: // value for 'filter'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useBuyWineOffersAllFilterByKeyQuery(baseOptions: Apollo.QueryHookOptions<Types.BuyWineOffersAllFilterByKeyQuery, Types.BuyWineOffersAllFilterByKeyQueryVariables> & ({ variables: Types.BuyWineOffersAllFilterByKeyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyWineOffersAllFilterByKeyQuery, Types.BuyWineOffersAllFilterByKeyQueryVariables>(BuyWineOffersAllFilterByKeyDocument, options);
      }
export function useBuyWineOffersAllFilterByKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyWineOffersAllFilterByKeyQuery, Types.BuyWineOffersAllFilterByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyWineOffersAllFilterByKeyQuery, Types.BuyWineOffersAllFilterByKeyQueryVariables>(BuyWineOffersAllFilterByKeyDocument, options);
        }
export function useBuyWineOffersAllFilterByKeySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyWineOffersAllFilterByKeyQuery, Types.BuyWineOffersAllFilterByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyWineOffersAllFilterByKeyQuery, Types.BuyWineOffersAllFilterByKeyQueryVariables>(BuyWineOffersAllFilterByKeyDocument, options);
        }
export type BuyWineOffersAllFilterByKeyQueryHookResult = ReturnType<typeof useBuyWineOffersAllFilterByKeyQuery>;
export type BuyWineOffersAllFilterByKeyLazyQueryHookResult = ReturnType<typeof useBuyWineOffersAllFilterByKeyLazyQuery>;
export type BuyWineOffersAllFilterByKeySuspenseQueryHookResult = ReturnType<typeof useBuyWineOffersAllFilterByKeySuspenseQuery>;
export type BuyWineOffersAllFilterByKeyQueryResult = Apollo.QueryResult<Types.BuyWineOffersAllFilterByKeyQuery, Types.BuyWineOffersAllFilterByKeyQueryVariables>;
export const BuyWineOffersAllFiltersDocument = gql`
    query buyWineOffersAllFilters($companyId: ID!, $localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt! = 50, $query: String, $isBuyer: Boolean!, $filter: WineOfferFiltersFilter, $filterSearch: String, $includeUnusedLabels: Boolean! = true) {
  me {
    company(id: $companyId) {
      wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: $filter
        query: $query
      ) {
        count
        countryFilter: filterByKey(key: "product.wine.country") {
          ...facetListFilter
        }
        nationalGITypeFilter: filterByKey(key: "product.wine.nationalGIType") {
          ...facetListFilter
        }
        geoIndicationFilter: filterByKey(key: "product.wine.geoIndication") {
          ...facetListFilter
        }
        wineTypeFilter: filterByKey(key: "product.wine.wineType") {
          ...facetListFilter
        }
        wineColourFilter: filterByKey(key: "product.wine.wineColor") {
          ...facetListFilter
        }
        vintageFilter: filterByKey(key: "product.wine.vintage") {
          ...facetListFilter
        }
        sweetnessTypeFilter: filterByKey(key: "product.wine.sweetnessLevel") {
          ...facetListFilter
        }
        certificatesFilter: filterByKey(key: "product.wine.certification") {
          ...facetListFilter
        }
        bottleSizeFilter: filterByKey(key: "bottleSize") {
          ...facetListFilter
        }
        packageTypeFilter: filterByKey(key: "packageType") {
          ...facetListFilter
        }
        grapesFilter: filterByKey(key: "grapeAndMonocepage") {
          ...facetComplexFilter
        }
        statusFilter: filterByKey(key: "wineOfferStatus") {
          ...facetListFilter
        }
        wwlRatingFilter: filterByKey(key: "product.wine.rating") {
          ...facetListFilter
        }
        deliveryTermsFilter: filterByKey(key: "incoterms") {
          ...facetListFilter
        }
        alcoholLevelFilter: filterByKey(key: "product.alcoholOnLabel") {
          ...facetRangeFilter
        }
        priceFilter: filterByKey(key: "pricePerBottle") {
          ...facetComplexFilter
        }
        countryOfShipmentFilter: filterByKey(key: "countryOfShipment") {
          ...facetListFilter
        }
        exhibitionFilter: filterByKey(key: "exhibition") {
          ...facetListFilter
        }
        producerFilter: filterByKey(key: "product.wine.tradeName") {
          ...facetListFilter
        }
        brandFilter: filterByKey(key: "product.wine.brand") {
          ...facetListFilter
        }
        specialClassificationFilter: filterByKey(
          key: "product.wine.specialClassification"
        ) {
          ...facetListFilter
        }
        statusFilter: filterByKey(key: "wineOfferStatus") {
          ...facetListFilter
        }
        rrpFilter: filterByKey(key: "product.priceAtWinery") {
          ...facetComplexFilter
        }
        retailPriceFilter: filterByKey(key: "tradeOfferLotPricePerBottle") {
          ...facetComplexFilter
        }
        priceCoefFilter: filterByKey(key: "priceCoefficient") {
          ...facetRangeFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}`;

/**
 * __useBuyWineOffersAllFiltersQuery__
 *
 * To run a query within a React component, call `useBuyWineOffersAllFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyWineOffersAllFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyWineOffersAllFiltersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      isBuyer: // value for 'isBuyer'
 *      filter: // value for 'filter'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useBuyWineOffersAllFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.BuyWineOffersAllFiltersQuery, Types.BuyWineOffersAllFiltersQueryVariables> & ({ variables: Types.BuyWineOffersAllFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyWineOffersAllFiltersQuery, Types.BuyWineOffersAllFiltersQueryVariables>(BuyWineOffersAllFiltersDocument, options);
      }
export function useBuyWineOffersAllFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyWineOffersAllFiltersQuery, Types.BuyWineOffersAllFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyWineOffersAllFiltersQuery, Types.BuyWineOffersAllFiltersQueryVariables>(BuyWineOffersAllFiltersDocument, options);
        }
export function useBuyWineOffersAllFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyWineOffersAllFiltersQuery, Types.BuyWineOffersAllFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyWineOffersAllFiltersQuery, Types.BuyWineOffersAllFiltersQueryVariables>(BuyWineOffersAllFiltersDocument, options);
        }
export type BuyWineOffersAllFiltersQueryHookResult = ReturnType<typeof useBuyWineOffersAllFiltersQuery>;
export type BuyWineOffersAllFiltersLazyQueryHookResult = ReturnType<typeof useBuyWineOffersAllFiltersLazyQuery>;
export type BuyWineOffersAllFiltersSuspenseQueryHookResult = ReturnType<typeof useBuyWineOffersAllFiltersSuspenseQuery>;
export type BuyWineOffersAllFiltersQueryResult = Apollo.QueryResult<Types.BuyWineOffersAllFiltersQuery, Types.BuyWineOffersAllFiltersQueryVariables>;
export const BuyWineOffersArchivedFilterByKeyDocument = gql`
    query buyWineOffersArchivedFilterByKey($companyId: ID!, $localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt! = 50, $query: String, $key: String!, $isBuyer: Boolean!, $filter: WineOfferFiltersFilter, $filterSearch: String, $includeUnusedLabels: Boolean! = true) {
  me {
    company(id: $companyId) {
      wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: $filter
        query: $query
      ) {
        filterByKey(key: $key) {
          ...facetComplexFilter
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetComplexFilterFragmentDoc}
${FacetListFilterFragmentDoc}`;

/**
 * __useBuyWineOffersArchivedFilterByKeyQuery__
 *
 * To run a query within a React component, call `useBuyWineOffersArchivedFilterByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyWineOffersArchivedFilterByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyWineOffersArchivedFilterByKeyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      key: // value for 'key'
 *      isBuyer: // value for 'isBuyer'
 *      filter: // value for 'filter'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useBuyWineOffersArchivedFilterByKeyQuery(baseOptions: Apollo.QueryHookOptions<Types.BuyWineOffersArchivedFilterByKeyQuery, Types.BuyWineOffersArchivedFilterByKeyQueryVariables> & ({ variables: Types.BuyWineOffersArchivedFilterByKeyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyWineOffersArchivedFilterByKeyQuery, Types.BuyWineOffersArchivedFilterByKeyQueryVariables>(BuyWineOffersArchivedFilterByKeyDocument, options);
      }
export function useBuyWineOffersArchivedFilterByKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyWineOffersArchivedFilterByKeyQuery, Types.BuyWineOffersArchivedFilterByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyWineOffersArchivedFilterByKeyQuery, Types.BuyWineOffersArchivedFilterByKeyQueryVariables>(BuyWineOffersArchivedFilterByKeyDocument, options);
        }
export function useBuyWineOffersArchivedFilterByKeySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyWineOffersArchivedFilterByKeyQuery, Types.BuyWineOffersArchivedFilterByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyWineOffersArchivedFilterByKeyQuery, Types.BuyWineOffersArchivedFilterByKeyQueryVariables>(BuyWineOffersArchivedFilterByKeyDocument, options);
        }
export type BuyWineOffersArchivedFilterByKeyQueryHookResult = ReturnType<typeof useBuyWineOffersArchivedFilterByKeyQuery>;
export type BuyWineOffersArchivedFilterByKeyLazyQueryHookResult = ReturnType<typeof useBuyWineOffersArchivedFilterByKeyLazyQuery>;
export type BuyWineOffersArchivedFilterByKeySuspenseQueryHookResult = ReturnType<typeof useBuyWineOffersArchivedFilterByKeySuspenseQuery>;
export type BuyWineOffersArchivedFilterByKeyQueryResult = Apollo.QueryResult<Types.BuyWineOffersArchivedFilterByKeyQuery, Types.BuyWineOffersArchivedFilterByKeyQueryVariables>;
export const BuyWineOffersArchivedFiltersDocument = gql`
    query buyWineOffersArchivedFilters($companyId: ID!, $localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt! = 50, $query: String, $isBuyer: Boolean!, $filter: WineOfferFiltersFilter, $filterSearch: String, $includeUnusedLabels: Boolean! = true) {
  me {
    company(id: $companyId) {
      wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: $filter
        query: $query
      ) {
        count
        countryFilter: filterByKey(key: "product.wine.country") {
          ...facetListFilter
        }
        nationalGITypeFilter: filterByKey(key: "product.wine.nationalGIType") {
          ...facetListFilter
        }
        wineRegionEUFilter: filterByKey(key: "product.wine.wineRegionEU") {
          ...facetListFilter
        }
        geoIndicationFilter: filterByKey(key: "product.wine.geoIndication") {
          ...facetListFilter
        }
        wineTypeFilter: filterByKey(key: "product.wine.wineType") {
          ...facetListFilter
        }
        wineColourFilter: filterByKey(key: "product.wine.wineColor") {
          ...facetListFilter
        }
        vintageFilter: filterByKey(key: "product.wine.vintage") {
          ...facetListFilter
        }
        sweetnessTypeFilter: filterByKey(key: "product.wine.sweetnessLevel") {
          ...facetListFilter
        }
        certificatesFilter: filterByKey(key: "product.wine.certification") {
          ...facetListFilter
        }
        bottleSizeFilter: filterByKey(key: "bottleSize") {
          ...facetListFilter
        }
        packageTypeFilter: filterByKey(key: "packageType") {
          ...facetListFilter
        }
        grapesFilter: filterByKey(key: "grapeAndMonocepage") {
          ...facetComplexFilter
        }
        statusFilter: filterByKey(key: "wineOfferStatus") {
          ...facetListFilter
        }
        wwlRatingFilter: filterByKey(key: "product.wine.rating") {
          ...facetListFilter
        }
        deliveryTermsFilter: filterByKey(key: "incoterms") {
          ...facetListFilter
        }
        alcoholLevelFilter: filterByKey(key: "product.alcoholOnLabel") {
          ...facetRangeFilter
        }
        priceFilter: filterByKey(key: "pricePerBottle") {
          ...facetComplexFilter
        }
        countryOfShipmentFilter: filterByKey(key: "countryOfShipment") {
          ...facetListFilter
        }
        exhibitionFilter: filterByKey(key: "exhibition") {
          ...facetListFilter
        }
        producerFilter: filterByKey(key: "product.wine.tradeName") {
          ...facetListFilter
        }
        brandFilter: filterByKey(key: "product.wine.brand") {
          ...facetListFilter
        }
        specialClassificationFilter: filterByKey(
          key: "product.wine.specialClassification"
        ) {
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}`;

/**
 * __useBuyWineOffersArchivedFiltersQuery__
 *
 * To run a query within a React component, call `useBuyWineOffersArchivedFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyWineOffersArchivedFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyWineOffersArchivedFiltersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      isBuyer: // value for 'isBuyer'
 *      filter: // value for 'filter'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useBuyWineOffersArchivedFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.BuyWineOffersArchivedFiltersQuery, Types.BuyWineOffersArchivedFiltersQueryVariables> & ({ variables: Types.BuyWineOffersArchivedFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyWineOffersArchivedFiltersQuery, Types.BuyWineOffersArchivedFiltersQueryVariables>(BuyWineOffersArchivedFiltersDocument, options);
      }
export function useBuyWineOffersArchivedFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyWineOffersArchivedFiltersQuery, Types.BuyWineOffersArchivedFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyWineOffersArchivedFiltersQuery, Types.BuyWineOffersArchivedFiltersQueryVariables>(BuyWineOffersArchivedFiltersDocument, options);
        }
export function useBuyWineOffersArchivedFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyWineOffersArchivedFiltersQuery, Types.BuyWineOffersArchivedFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyWineOffersArchivedFiltersQuery, Types.BuyWineOffersArchivedFiltersQueryVariables>(BuyWineOffersArchivedFiltersDocument, options);
        }
export type BuyWineOffersArchivedFiltersQueryHookResult = ReturnType<typeof useBuyWineOffersArchivedFiltersQuery>;
export type BuyWineOffersArchivedFiltersLazyQueryHookResult = ReturnType<typeof useBuyWineOffersArchivedFiltersLazyQuery>;
export type BuyWineOffersArchivedFiltersSuspenseQueryHookResult = ReturnType<typeof useBuyWineOffersArchivedFiltersSuspenseQuery>;
export type BuyWineOffersArchivedFiltersQueryResult = Apollo.QueryResult<Types.BuyWineOffersArchivedFiltersQuery, Types.BuyWineOffersArchivedFiltersQueryVariables>;
export const BuyWineOffersDealsAnalogCountsDocument = gql`
    query buyWineOffersDealsAnalogCounts($companyId: ID!, $localeId: Int!, $isBuyer: Boolean!, $wineOfferStatusIds: [Int!], $input1: [FacetInput!], $input2: [FacetInput!], $input3: [FacetInput!], $input4: [FacetInput!], $input5: [FacetInput!], $input6: [FacetInput!], $input7: [FacetInput!], $input8: [FacetInput!], $input9: [FacetInput!], $input10: [FacetInput!], $input11: [FacetInput!], $input12: [FacetInput!], $input13: [FacetInput!], $input14: [FacetInput!], $input15: [FacetInput!], $input16: [FacetInput!], $input17: [FacetInput!], $input18: [FacetInput!], $input19: [FacetInput!], $input20: [FacetInput!]) {
  me {
    company(id: $companyId) {
      analog1: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input1}
      ) {
        count
      }
      analog2: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input2}
      ) {
        count
      }
      analog3: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input3}
      ) {
        count
      }
      analog4: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input4}
      ) {
        count
      }
      analog5: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input5}
      ) {
        count
      }
      analog6: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input6}
      ) {
        count
      }
      analog7: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input7}
      ) {
        count
      }
      analog8: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input8}
      ) {
        count
      }
      analog9: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input9}
      ) {
        count
      }
      analog10: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input10}
      ) {
        count
      }
      analog11: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input11}
      ) {
        count
      }
      analog12: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input12}
      ) {
        count
      }
      analog13: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input13}
      ) {
        count
      }
      analog14: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input14}
      ) {
        count
      }
      analog15: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input15}
      ) {
        count
      }
      analog16: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input16}
      ) {
        count
      }
      analog17: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input17}
      ) {
        count
      }
      analog18: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input18}
      ) {
        count
      }
      analog19: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input19}
      ) {
        count
      }
      analog20: wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: {wineOfferStatusIds: $wineOfferStatusIds, facetsInputs: $input20}
      ) {
        count
      }
      __typename
    }
    __typename
  }
}
    `;

/**
 * __useBuyWineOffersDealsAnalogCountsQuery__
 *
 * To run a query within a React component, call `useBuyWineOffersDealsAnalogCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyWineOffersDealsAnalogCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyWineOffersDealsAnalogCountsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      isBuyer: // value for 'isBuyer'
 *      wineOfferStatusIds: // value for 'wineOfferStatusIds'
 *      input1: // value for 'input1'
 *      input2: // value for 'input2'
 *      input3: // value for 'input3'
 *      input4: // value for 'input4'
 *      input5: // value for 'input5'
 *      input6: // value for 'input6'
 *      input7: // value for 'input7'
 *      input8: // value for 'input8'
 *      input9: // value for 'input9'
 *      input10: // value for 'input10'
 *      input11: // value for 'input11'
 *      input12: // value for 'input12'
 *      input13: // value for 'input13'
 *      input14: // value for 'input14'
 *      input15: // value for 'input15'
 *      input16: // value for 'input16'
 *      input17: // value for 'input17'
 *      input18: // value for 'input18'
 *      input19: // value for 'input19'
 *      input20: // value for 'input20'
 *   },
 * });
 */
export function useBuyWineOffersDealsAnalogCountsQuery(baseOptions: Apollo.QueryHookOptions<Types.BuyWineOffersDealsAnalogCountsQuery, Types.BuyWineOffersDealsAnalogCountsQueryVariables> & ({ variables: Types.BuyWineOffersDealsAnalogCountsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyWineOffersDealsAnalogCountsQuery, Types.BuyWineOffersDealsAnalogCountsQueryVariables>(BuyWineOffersDealsAnalogCountsDocument, options);
      }
export function useBuyWineOffersDealsAnalogCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyWineOffersDealsAnalogCountsQuery, Types.BuyWineOffersDealsAnalogCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyWineOffersDealsAnalogCountsQuery, Types.BuyWineOffersDealsAnalogCountsQueryVariables>(BuyWineOffersDealsAnalogCountsDocument, options);
        }
export function useBuyWineOffersDealsAnalogCountsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyWineOffersDealsAnalogCountsQuery, Types.BuyWineOffersDealsAnalogCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyWineOffersDealsAnalogCountsQuery, Types.BuyWineOffersDealsAnalogCountsQueryVariables>(BuyWineOffersDealsAnalogCountsDocument, options);
        }
export type BuyWineOffersDealsAnalogCountsQueryHookResult = ReturnType<typeof useBuyWineOffersDealsAnalogCountsQuery>;
export type BuyWineOffersDealsAnalogCountsLazyQueryHookResult = ReturnType<typeof useBuyWineOffersDealsAnalogCountsLazyQuery>;
export type BuyWineOffersDealsAnalogCountsSuspenseQueryHookResult = ReturnType<typeof useBuyWineOffersDealsAnalogCountsSuspenseQuery>;
export type BuyWineOffersDealsAnalogCountsQueryResult = Apollo.QueryResult<Types.BuyWineOffersDealsAnalogCountsQuery, Types.BuyWineOffersDealsAnalogCountsQueryVariables>;
export const BuyWineOffersDealsFilterByKeyDocument = gql`
    query buyWineOffersDealsFilterByKey($companyId: ID!, $localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt! = 50, $query: String, $key: String!, $isBuyer: Boolean!, $filter: WineOfferFiltersFilter, $filterSearch: String, $includeUnusedLabels: Boolean! = true) {
  me {
    company(id: $companyId) {
      wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: $filter
        query: $query
      ) {
        filterByKey(key: $key) {
          ...facetComplexFilter
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetComplexFilterFragmentDoc}
${FacetListFilterFragmentDoc}`;

/**
 * __useBuyWineOffersDealsFilterByKeyQuery__
 *
 * To run a query within a React component, call `useBuyWineOffersDealsFilterByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyWineOffersDealsFilterByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyWineOffersDealsFilterByKeyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      key: // value for 'key'
 *      isBuyer: // value for 'isBuyer'
 *      filter: // value for 'filter'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useBuyWineOffersDealsFilterByKeyQuery(baseOptions: Apollo.QueryHookOptions<Types.BuyWineOffersDealsFilterByKeyQuery, Types.BuyWineOffersDealsFilterByKeyQueryVariables> & ({ variables: Types.BuyWineOffersDealsFilterByKeyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyWineOffersDealsFilterByKeyQuery, Types.BuyWineOffersDealsFilterByKeyQueryVariables>(BuyWineOffersDealsFilterByKeyDocument, options);
      }
export function useBuyWineOffersDealsFilterByKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyWineOffersDealsFilterByKeyQuery, Types.BuyWineOffersDealsFilterByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyWineOffersDealsFilterByKeyQuery, Types.BuyWineOffersDealsFilterByKeyQueryVariables>(BuyWineOffersDealsFilterByKeyDocument, options);
        }
export function useBuyWineOffersDealsFilterByKeySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyWineOffersDealsFilterByKeyQuery, Types.BuyWineOffersDealsFilterByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyWineOffersDealsFilterByKeyQuery, Types.BuyWineOffersDealsFilterByKeyQueryVariables>(BuyWineOffersDealsFilterByKeyDocument, options);
        }
export type BuyWineOffersDealsFilterByKeyQueryHookResult = ReturnType<typeof useBuyWineOffersDealsFilterByKeyQuery>;
export type BuyWineOffersDealsFilterByKeyLazyQueryHookResult = ReturnType<typeof useBuyWineOffersDealsFilterByKeyLazyQuery>;
export type BuyWineOffersDealsFilterByKeySuspenseQueryHookResult = ReturnType<typeof useBuyWineOffersDealsFilterByKeySuspenseQuery>;
export type BuyWineOffersDealsFilterByKeyQueryResult = Apollo.QueryResult<Types.BuyWineOffersDealsFilterByKeyQuery, Types.BuyWineOffersDealsFilterByKeyQueryVariables>;
export const BuyWineOffersDealsFiltersDocument = gql`
    query buyWineOffersDealsFilters($companyId: ID!, $localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt! = 50, $query: String, $isBuyer: Boolean!, $filter: WineOfferFiltersFilter, $filterSearch: String, $includeUnusedLabels: Boolean! = true) {
  me {
    company(id: $companyId) {
      wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: $filter
        query: $query
      ) {
        count
        countryFilter: filterByKey(key: "product.wine.country") {
          ...facetListFilter
        }
        nationalGITypeFilter: filterByKey(key: "product.wine.nationalGIType") {
          ...facetListFilter
        }
        wineRegionEUFilter: filterByKey(key: "product.wine.wineRegionEU") {
          ...facetListFilter
        }
        geoIndicationFilter: filterByKey(key: "product.wine.geoIndication") {
          ...facetListFilter
        }
        wineTypeFilter: filterByKey(key: "product.wine.wineType") {
          ...facetListFilter
        }
        wineColourFilter: filterByKey(key: "product.wine.wineColor") {
          ...facetListFilter
        }
        vintageFilter: filterByKey(key: "product.wine.vintage") {
          ...facetListFilter
        }
        sweetnessTypeFilter: filterByKey(key: "product.wine.sweetnessLevel") {
          ...facetListFilter
        }
        certificatesFilter: filterByKey(key: "product.wine.certification") {
          ...facetListFilter
        }
        bottleSizeFilter: filterByKey(key: "bottleSize") {
          ...facetListFilter
        }
        packageTypeFilter: filterByKey(key: "packageType") {
          ...facetListFilter
        }
        grapesFilter: filterByKey(key: "grapeAndMonocepage") {
          ...facetComplexFilter
        }
        statusFilter: filterByKey(key: "wineOfferStatus") {
          ...facetListFilter
        }
        wwlRatingFilter: filterByKey(key: "product.wine.rating") {
          ...facetListFilter
        }
        deliveryTermsFilter: filterByKey(key: "incoterms") {
          ...facetListFilter
        }
        alcoholLevelFilter: filterByKey(key: "product.alcoholOnLabel") {
          ...facetRangeFilter
        }
        priceFilter: filterByKey(key: "pricePerBottle") {
          ...facetComplexFilter
        }
        countryOfShipmentFilter: filterByKey(key: "countryOfShipment") {
          ...facetListFilter
        }
        exhibitionFilter: filterByKey(key: "exhibition") {
          ...facetListFilter
        }
        producerFilter: filterByKey(key: "product.wine.tradeName") {
          ...facetListFilter
        }
        brandFilter: filterByKey(key: "product.wine.brand") {
          ...facetListFilter
        }
        specialClassificationFilter: filterByKey(
          key: "product.wine.specialClassification"
        ) {
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}`;

/**
 * __useBuyWineOffersDealsFiltersQuery__
 *
 * To run a query within a React component, call `useBuyWineOffersDealsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyWineOffersDealsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyWineOffersDealsFiltersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      isBuyer: // value for 'isBuyer'
 *      filter: // value for 'filter'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useBuyWineOffersDealsFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.BuyWineOffersDealsFiltersQuery, Types.BuyWineOffersDealsFiltersQueryVariables> & ({ variables: Types.BuyWineOffersDealsFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyWineOffersDealsFiltersQuery, Types.BuyWineOffersDealsFiltersQueryVariables>(BuyWineOffersDealsFiltersDocument, options);
      }
export function useBuyWineOffersDealsFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyWineOffersDealsFiltersQuery, Types.BuyWineOffersDealsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyWineOffersDealsFiltersQuery, Types.BuyWineOffersDealsFiltersQueryVariables>(BuyWineOffersDealsFiltersDocument, options);
        }
export function useBuyWineOffersDealsFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyWineOffersDealsFiltersQuery, Types.BuyWineOffersDealsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyWineOffersDealsFiltersQuery, Types.BuyWineOffersDealsFiltersQueryVariables>(BuyWineOffersDealsFiltersDocument, options);
        }
export type BuyWineOffersDealsFiltersQueryHookResult = ReturnType<typeof useBuyWineOffersDealsFiltersQuery>;
export type BuyWineOffersDealsFiltersLazyQueryHookResult = ReturnType<typeof useBuyWineOffersDealsFiltersLazyQuery>;
export type BuyWineOffersDealsFiltersSuspenseQueryHookResult = ReturnType<typeof useBuyWineOffersDealsFiltersSuspenseQuery>;
export type BuyWineOffersDealsFiltersQueryResult = Apollo.QueryResult<Types.BuyWineOffersDealsFiltersQuery, Types.BuyWineOffersDealsFiltersQueryVariables>;
export const BuyWineOffersNegotiationsFilterByKeyDocument = gql`
    query buyWineOffersNegotiationsFilterByKey($companyId: ID!, $localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt! = 50, $query: String, $key: String!, $isBuyer: Boolean!, $filter: WineOfferFiltersFilter, $filterSearch: String, $includeUnusedLabels: Boolean! = true) {
  me {
    company(id: $companyId) {
      wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: $filter
        query: $query
      ) {
        filterByKey(key: $key) {
          ...facetComplexFilter
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetComplexFilterFragmentDoc}
${FacetListFilterFragmentDoc}`;

/**
 * __useBuyWineOffersNegotiationsFilterByKeyQuery__
 *
 * To run a query within a React component, call `useBuyWineOffersNegotiationsFilterByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyWineOffersNegotiationsFilterByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyWineOffersNegotiationsFilterByKeyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      key: // value for 'key'
 *      isBuyer: // value for 'isBuyer'
 *      filter: // value for 'filter'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useBuyWineOffersNegotiationsFilterByKeyQuery(baseOptions: Apollo.QueryHookOptions<Types.BuyWineOffersNegotiationsFilterByKeyQuery, Types.BuyWineOffersNegotiationsFilterByKeyQueryVariables> & ({ variables: Types.BuyWineOffersNegotiationsFilterByKeyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyWineOffersNegotiationsFilterByKeyQuery, Types.BuyWineOffersNegotiationsFilterByKeyQueryVariables>(BuyWineOffersNegotiationsFilterByKeyDocument, options);
      }
export function useBuyWineOffersNegotiationsFilterByKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyWineOffersNegotiationsFilterByKeyQuery, Types.BuyWineOffersNegotiationsFilterByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyWineOffersNegotiationsFilterByKeyQuery, Types.BuyWineOffersNegotiationsFilterByKeyQueryVariables>(BuyWineOffersNegotiationsFilterByKeyDocument, options);
        }
export function useBuyWineOffersNegotiationsFilterByKeySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyWineOffersNegotiationsFilterByKeyQuery, Types.BuyWineOffersNegotiationsFilterByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyWineOffersNegotiationsFilterByKeyQuery, Types.BuyWineOffersNegotiationsFilterByKeyQueryVariables>(BuyWineOffersNegotiationsFilterByKeyDocument, options);
        }
export type BuyWineOffersNegotiationsFilterByKeyQueryHookResult = ReturnType<typeof useBuyWineOffersNegotiationsFilterByKeyQuery>;
export type BuyWineOffersNegotiationsFilterByKeyLazyQueryHookResult = ReturnType<typeof useBuyWineOffersNegotiationsFilterByKeyLazyQuery>;
export type BuyWineOffersNegotiationsFilterByKeySuspenseQueryHookResult = ReturnType<typeof useBuyWineOffersNegotiationsFilterByKeySuspenseQuery>;
export type BuyWineOffersNegotiationsFilterByKeyQueryResult = Apollo.QueryResult<Types.BuyWineOffersNegotiationsFilterByKeyQuery, Types.BuyWineOffersNegotiationsFilterByKeyQueryVariables>;
export const BuyWineOffersNegotiationsFiltersDocument = gql`
    query buyWineOffersNegotiationsFilters($companyId: ID!, $localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt! = 50, $query: String, $isBuyer: Boolean!, $filter: WineOfferFiltersFilter, $filterSearch: String, $includeUnusedLabels: Boolean! = true) {
  me {
    company(id: $companyId) {
      wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: $filter
        query: $query
      ) {
        count
        countryFilter: filterByKey(key: "product.wine.country") {
          ...facetListFilter
        }
        nationalGITypeFilter: filterByKey(key: "product.wine.nationalGIType") {
          ...facetListFilter
        }
        wineRegionEUFilter: filterByKey(key: "product.wine.wineRegionEU") {
          ...facetListFilter
        }
        geoIndicationFilter: filterByKey(key: "product.wine.geoIndication") {
          ...facetListFilter
        }
        wineTypeFilter: filterByKey(key: "product.wine.wineType") {
          ...facetListFilter
        }
        wineColourFilter: filterByKey(key: "product.wine.wineColor") {
          ...facetListFilter
        }
        vintageFilter: filterByKey(key: "product.wine.vintage") {
          ...facetListFilter
        }
        sweetnessTypeFilter: filterByKey(key: "product.wine.sweetnessLevel") {
          ...facetListFilter
        }
        certificatesFilter: filterByKey(key: "product.wine.certification") {
          ...facetListFilter
        }
        bottleSizeFilter: filterByKey(key: "bottleSize") {
          ...facetListFilter
        }
        packageTypeFilter: filterByKey(key: "packageType") {
          ...facetListFilter
        }
        grapesFilter: filterByKey(key: "grapeAndMonocepage") {
          ...facetComplexFilter
        }
        statusFilter: filterByKey(key: "wineOfferStatus") {
          ...facetListFilter
        }
        wwlRatingFilter: filterByKey(key: "product.wine.rating") {
          ...facetListFilter
        }
        deliveryTermsFilter: filterByKey(key: "incoterms") {
          ...facetListFilter
        }
        alcoholLevelFilter: filterByKey(key: "product.alcoholOnLabel") {
          ...facetRangeFilter
        }
        priceFilter: filterByKey(key: "pricePerBottle") {
          ...facetComplexFilter
        }
        countryOfShipmentFilter: filterByKey(key: "countryOfShipment") {
          ...facetListFilter
        }
        exhibitionFilter: filterByKey(key: "exhibition") {
          ...facetListFilter
        }
        producerFilter: filterByKey(key: "product.wine.tradeName") {
          ...facetListFilter
        }
        brandFilter: filterByKey(key: "product.wine.brand") {
          ...facetListFilter
        }
        specialClassificationFilter: filterByKey(
          key: "product.wine.specialClassification"
        ) {
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}`;

/**
 * __useBuyWineOffersNegotiationsFiltersQuery__
 *
 * To run a query within a React component, call `useBuyWineOffersNegotiationsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyWineOffersNegotiationsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyWineOffersNegotiationsFiltersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      isBuyer: // value for 'isBuyer'
 *      filter: // value for 'filter'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useBuyWineOffersNegotiationsFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.BuyWineOffersNegotiationsFiltersQuery, Types.BuyWineOffersNegotiationsFiltersQueryVariables> & ({ variables: Types.BuyWineOffersNegotiationsFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyWineOffersNegotiationsFiltersQuery, Types.BuyWineOffersNegotiationsFiltersQueryVariables>(BuyWineOffersNegotiationsFiltersDocument, options);
      }
export function useBuyWineOffersNegotiationsFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyWineOffersNegotiationsFiltersQuery, Types.BuyWineOffersNegotiationsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyWineOffersNegotiationsFiltersQuery, Types.BuyWineOffersNegotiationsFiltersQueryVariables>(BuyWineOffersNegotiationsFiltersDocument, options);
        }
export function useBuyWineOffersNegotiationsFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyWineOffersNegotiationsFiltersQuery, Types.BuyWineOffersNegotiationsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyWineOffersNegotiationsFiltersQuery, Types.BuyWineOffersNegotiationsFiltersQueryVariables>(BuyWineOffersNegotiationsFiltersDocument, options);
        }
export type BuyWineOffersNegotiationsFiltersQueryHookResult = ReturnType<typeof useBuyWineOffersNegotiationsFiltersQuery>;
export type BuyWineOffersNegotiationsFiltersLazyQueryHookResult = ReturnType<typeof useBuyWineOffersNegotiationsFiltersLazyQuery>;
export type BuyWineOffersNegotiationsFiltersSuspenseQueryHookResult = ReturnType<typeof useBuyWineOffersNegotiationsFiltersSuspenseQuery>;
export type BuyWineOffersNegotiationsFiltersQueryResult = Apollo.QueryResult<Types.BuyWineOffersNegotiationsFiltersQuery, Types.BuyWineOffersNegotiationsFiltersQueryVariables>;
export const BuyWineOffersShortlistFilterByKeyDocument = gql`
    query buyWineOffersShortlistFilterByKey($companyId: ID!, $localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt! = 50, $query: String, $key: String!, $isBuyer: Boolean!, $filter: WineOfferFiltersFilter, $filterSearch: String, $includeUnusedLabels: Boolean! = true) {
  me {
    company(id: $companyId) {
      wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: $filter
        query: $query
      ) {
        filterByKey(key: $key) {
          ...facetComplexFilter
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetComplexFilterFragmentDoc}
${FacetListFilterFragmentDoc}`;

/**
 * __useBuyWineOffersShortlistFilterByKeyQuery__
 *
 * To run a query within a React component, call `useBuyWineOffersShortlistFilterByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyWineOffersShortlistFilterByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyWineOffersShortlistFilterByKeyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      key: // value for 'key'
 *      isBuyer: // value for 'isBuyer'
 *      filter: // value for 'filter'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useBuyWineOffersShortlistFilterByKeyQuery(baseOptions: Apollo.QueryHookOptions<Types.BuyWineOffersShortlistFilterByKeyQuery, Types.BuyWineOffersShortlistFilterByKeyQueryVariables> & ({ variables: Types.BuyWineOffersShortlistFilterByKeyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyWineOffersShortlistFilterByKeyQuery, Types.BuyWineOffersShortlistFilterByKeyQueryVariables>(BuyWineOffersShortlistFilterByKeyDocument, options);
      }
export function useBuyWineOffersShortlistFilterByKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyWineOffersShortlistFilterByKeyQuery, Types.BuyWineOffersShortlistFilterByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyWineOffersShortlistFilterByKeyQuery, Types.BuyWineOffersShortlistFilterByKeyQueryVariables>(BuyWineOffersShortlistFilterByKeyDocument, options);
        }
export function useBuyWineOffersShortlistFilterByKeySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyWineOffersShortlistFilterByKeyQuery, Types.BuyWineOffersShortlistFilterByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyWineOffersShortlistFilterByKeyQuery, Types.BuyWineOffersShortlistFilterByKeyQueryVariables>(BuyWineOffersShortlistFilterByKeyDocument, options);
        }
export type BuyWineOffersShortlistFilterByKeyQueryHookResult = ReturnType<typeof useBuyWineOffersShortlistFilterByKeyQuery>;
export type BuyWineOffersShortlistFilterByKeyLazyQueryHookResult = ReturnType<typeof useBuyWineOffersShortlistFilterByKeyLazyQuery>;
export type BuyWineOffersShortlistFilterByKeySuspenseQueryHookResult = ReturnType<typeof useBuyWineOffersShortlistFilterByKeySuspenseQuery>;
export type BuyWineOffersShortlistFilterByKeyQueryResult = Apollo.QueryResult<Types.BuyWineOffersShortlistFilterByKeyQuery, Types.BuyWineOffersShortlistFilterByKeyQueryVariables>;
export const BuyWineOffersShortlistFiltersDocument = gql`
    query buyWineOffersShortlistFilters($companyId: ID!, $localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt! = 50, $query: String, $isBuyer: Boolean!, $filter: WineOfferFiltersFilter, $filterSearch: String, $includeUnusedLabels: Boolean! = true) {
  me {
    company(id: $companyId) {
      wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: $filter
        query: $query
      ) {
        count
        countryFilter: filterByKey(key: "product.wine.country") {
          ...facetListFilter
        }
        nationalGITypeFilter: filterByKey(key: "product.wine.nationalGIType") {
          ...facetListFilter
        }
        wineRegionEUFilter: filterByKey(key: "product.wine.wineRegionEU") {
          ...facetListFilter
        }
        geoIndicationFilter: filterByKey(key: "product.wine.geoIndication") {
          ...facetListFilter
        }
        wineTypeFilter: filterByKey(key: "product.wine.wineType") {
          ...facetListFilter
        }
        wineColourFilter: filterByKey(key: "product.wine.wineColor") {
          ...facetListFilter
        }
        vintageFilter: filterByKey(key: "product.wine.vintage") {
          ...facetListFilter
        }
        sweetnessTypeFilter: filterByKey(key: "product.wine.sweetnessLevel") {
          ...facetListFilter
        }
        certificatesFilter: filterByKey(key: "product.wine.certification") {
          ...facetListFilter
        }
        bottleSizeFilter: filterByKey(key: "bottleSize") {
          ...facetListFilter
        }
        packageTypeFilter: filterByKey(key: "packageType") {
          ...facetListFilter
        }
        grapesFilter: filterByKey(key: "grapeAndMonocepage") {
          ...facetComplexFilter
        }
        statusFilter: filterByKey(key: "wineOfferStatus") {
          ...facetListFilter
        }
        wwlRatingFilter: filterByKey(key: "product.wine.rating") {
          ...facetListFilter
        }
        deliveryTermsFilter: filterByKey(key: "incoterms") {
          ...facetListFilter
        }
        alcoholLevelFilter: filterByKey(key: "product.alcoholOnLabel") {
          ...facetRangeFilter
        }
        priceFilter: filterByKey(key: "pricePerBottle") {
          ...facetComplexFilter
        }
        countryOfShipmentFilter: filterByKey(key: "countryOfShipment") {
          ...facetListFilter
        }
        exhibitionFilter: filterByKey(key: "exhibition") {
          ...facetListFilter
        }
        producerFilter: filterByKey(key: "product.wine.tradeName") {
          ...facetListFilter
        }
        brandFilter: filterByKey(key: "product.wine.brand") {
          ...facetListFilter
        }
        specialClassificationFilter: filterByKey(
          key: "product.wine.specialClassification"
        ) {
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}`;

/**
 * __useBuyWineOffersShortlistFiltersQuery__
 *
 * To run a query within a React component, call `useBuyWineOffersShortlistFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyWineOffersShortlistFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyWineOffersShortlistFiltersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      isBuyer: // value for 'isBuyer'
 *      filter: // value for 'filter'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useBuyWineOffersShortlistFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.BuyWineOffersShortlistFiltersQuery, Types.BuyWineOffersShortlistFiltersQueryVariables> & ({ variables: Types.BuyWineOffersShortlistFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyWineOffersShortlistFiltersQuery, Types.BuyWineOffersShortlistFiltersQueryVariables>(BuyWineOffersShortlistFiltersDocument, options);
      }
export function useBuyWineOffersShortlistFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyWineOffersShortlistFiltersQuery, Types.BuyWineOffersShortlistFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyWineOffersShortlistFiltersQuery, Types.BuyWineOffersShortlistFiltersQueryVariables>(BuyWineOffersShortlistFiltersDocument, options);
        }
export function useBuyWineOffersShortlistFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyWineOffersShortlistFiltersQuery, Types.BuyWineOffersShortlistFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyWineOffersShortlistFiltersQuery, Types.BuyWineOffersShortlistFiltersQueryVariables>(BuyWineOffersShortlistFiltersDocument, options);
        }
export type BuyWineOffersShortlistFiltersQueryHookResult = ReturnType<typeof useBuyWineOffersShortlistFiltersQuery>;
export type BuyWineOffersShortlistFiltersLazyQueryHookResult = ReturnType<typeof useBuyWineOffersShortlistFiltersLazyQuery>;
export type BuyWineOffersShortlistFiltersSuspenseQueryHookResult = ReturnType<typeof useBuyWineOffersShortlistFiltersSuspenseQuery>;
export type BuyWineOffersShortlistFiltersQueryResult = Apollo.QueryResult<Types.BuyWineOffersShortlistFiltersQuery, Types.BuyWineOffersShortlistFiltersQueryVariables>;
export const BuyProductsFiltersDocument = gql`
    query buyProductsFilters($localeId: Int!, $query: String, $companyId: ID!, $page: PositiveInt!, $perPage: PositiveInt! = 50, $filter: BuyProductsPaginationFilter, $filterSearch: String, $includeUnusedLabels: Boolean! = true) {
  me {
    company(id: $companyId) {
      buyProductsFilters(localeId: $localeId, filter: $filter, query: $query) {
        count
        countryFilter: filterByKey(key: "wine.country") {
          ...facetListFilter
        }
        producerFilter: filterByKey(key: "wine.tradeName") {
          ...facetListFilter
        }
        brandFilter: filterByKey(key: "wine.brand") {
          ...facetListFilter
        }
        nationalGITypeFilter: filterByKey(key: "wine.nationalGIType") {
          ...facetListFilter
        }
        geoIndicationFilter: filterByKey(key: "wine.geoIndication") {
          ...facetListFilter
        }
        specialClassificationFilter: filterByKey(key: "wine.specialClassification") {
          ...facetListFilter
        }
        wineTypeFilter: filterByKey(key: "wine.wineType") {
          ...facetListFilter
        }
        wineColourFilter: filterByKey(key: "wine.wineColor") {
          ...facetListFilter
        }
        sweetnessTypeFilter: filterByKey(key: "wine.sweetnessLevel") {
          ...facetListFilter
        }
        vintageFilter: filterByKey(key: "wine.vintage") {
          ...facetListFilter
        }
        marginFilter: filterByKey(key: "margin") {
          ...facetRangeFilter
        }
        bestOfferFilter: filterByKey(key: "bestOfferPricePerBottle") {
          ...facetComplexFilter
        }
        coefFilter: filterByKey(key: "coef") {
          ...facetRangeFilter
        }
        minRetailPriceFilter: filterByKey(key: "minRetailPrice") {
          ...facetComplexFilter
        }
        rrpFilter: filterByKey(key: "recommendedRetailPrice") {
          ...facetComplexFilter
        }
        grapesFilter: filterByKey(key: "grapeAndMonocepage") {
          ...facetComplexFilter
        }
        alcoholLevelFilter: filterByKey(key: "alcoholOnLabel") {
          ...facetRangeFilter
        }
        certificatesFilter: filterByKey(key: "wine.certification") {
          ...facetListFilter
        }
        bottleSizeFilter: filterByKey(key: "bottleSize") {
          ...facetListFilter
        }
        packageTypeFilter: filterByKey(key: "packageType") {
          ...facetListFilter
        }
        hasBottleImageFilter: filterByKey(key: "hasBottleImage") {
          ...facetListFilter
        }
        hasFrontLabelImageFilter: filterByKey(key: "hasFrontLabelImage") {
          ...facetListFilter
        }
        hasBackLabelImageFilter: filterByKey(key: "hasBackLabelImage") {
          ...facetListFilter
        }
        hasVerifiedRepresentativeFilter: filterByKey(key: "hasVerifiedRepresentative") {
          ...facetListFilter
        }
        wwlRatingFilter: filterByKey(key: "wine.rating") {
          ...facetListFilter
        }
        rankFilter: filterByKey(key: "appellationRating.rank") {
          ...facetRangeFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}`;

/**
 * __useBuyProductsFiltersQuery__
 *
 * To run a query within a React component, call `useBuyProductsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyProductsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyProductsFiltersQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      query: // value for 'query'
 *      companyId: // value for 'companyId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useBuyProductsFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.BuyProductsFiltersQuery, Types.BuyProductsFiltersQueryVariables> & ({ variables: Types.BuyProductsFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyProductsFiltersQuery, Types.BuyProductsFiltersQueryVariables>(BuyProductsFiltersDocument, options);
      }
export function useBuyProductsFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyProductsFiltersQuery, Types.BuyProductsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyProductsFiltersQuery, Types.BuyProductsFiltersQueryVariables>(BuyProductsFiltersDocument, options);
        }
export function useBuyProductsFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyProductsFiltersQuery, Types.BuyProductsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyProductsFiltersQuery, Types.BuyProductsFiltersQueryVariables>(BuyProductsFiltersDocument, options);
        }
export type BuyProductsFiltersQueryHookResult = ReturnType<typeof useBuyProductsFiltersQuery>;
export type BuyProductsFiltersLazyQueryHookResult = ReturnType<typeof useBuyProductsFiltersLazyQuery>;
export type BuyProductsFiltersSuspenseQueryHookResult = ReturnType<typeof useBuyProductsFiltersSuspenseQuery>;
export type BuyProductsFiltersQueryResult = Apollo.QueryResult<Types.BuyProductsFiltersQuery, Types.BuyProductsFiltersQueryVariables>;
export const BuyProductsSpecificFilterDocument = gql`
    query buyProductsSpecificFilter($filterKey: String!, $localeId: Int!, $companyId: ID!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filter: BuyProductsPaginationFilter, $query: String, $filterSearch: String, $includeUnusedLabels: Boolean! = true) {
  me {
    company(id: $companyId) {
      buyProductsFilters(localeId: $localeId, filter: $filter, query: $query) {
        filterByKey(key: $filterKey) {
          ...facetFreeListFilter
          ...facetListFilter
          ...facetDateRangeFilter
          ...facetRangeFilter
          ...facetComplexFilter
        }
      }
    }
  }
}
    ${FacetFreeListFilterFragmentDoc}
${FacetListFilterFragmentDoc}
${FacetDateRangeFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}`;

/**
 * __useBuyProductsSpecificFilterQuery__
 *
 * To run a query within a React component, call `useBuyProductsSpecificFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyProductsSpecificFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyProductsSpecificFilterQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      companyId: // value for 'companyId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      query: // value for 'query'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useBuyProductsSpecificFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.BuyProductsSpecificFilterQuery, Types.BuyProductsSpecificFilterQueryVariables> & ({ variables: Types.BuyProductsSpecificFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyProductsSpecificFilterQuery, Types.BuyProductsSpecificFilterQueryVariables>(BuyProductsSpecificFilterDocument, options);
      }
export function useBuyProductsSpecificFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyProductsSpecificFilterQuery, Types.BuyProductsSpecificFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyProductsSpecificFilterQuery, Types.BuyProductsSpecificFilterQueryVariables>(BuyProductsSpecificFilterDocument, options);
        }
export function useBuyProductsSpecificFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyProductsSpecificFilterQuery, Types.BuyProductsSpecificFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyProductsSpecificFilterQuery, Types.BuyProductsSpecificFilterQueryVariables>(BuyProductsSpecificFilterDocument, options);
        }
export type BuyProductsSpecificFilterQueryHookResult = ReturnType<typeof useBuyProductsSpecificFilterQuery>;
export type BuyProductsSpecificFilterLazyQueryHookResult = ReturnType<typeof useBuyProductsSpecificFilterLazyQuery>;
export type BuyProductsSpecificFilterSuspenseQueryHookResult = ReturnType<typeof useBuyProductsSpecificFilterSuspenseQuery>;
export type BuyProductsSpecificFilterQueryResult = Apollo.QueryResult<Types.BuyProductsSpecificFilterQuery, Types.BuyProductsSpecificFilterQueryVariables>;
export const CompanyBuyProductsPaginationDocument = gql`
    query companyBuyProductsPagination($localeId: Int!, $companyId: ID!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $query: String, $filter: BuyProductsPaginationFilter, $sort: ProductSort!) {
  me {
    company(id: $companyId) {
      buyProductsPagination(
        localeId: $localeId
        page: $page
        perPage: $perPage
        query: $query
        filter: $filter
        sort: $sort
      ) {
        items {
          id
          slug
          cellarDoorPrice
          currency {
            code
          }
          appellationRatings {
            rank
            count
            geoIndication {
              id
              localizedName(localeId: $localeId)
            }
          }
          wine {
            id
            rating
            tradeNameBrand
            chemicalAnalysis {
              alcoholVolume
            }
            country {
              id
              localizedName(localeId: $localeId)
              codeISO
            }
            grapeVarieties {
              grapeVarietySynonym {
                id
                name
              }
              value
            }
            nationalGIType {
              id
              localizedName(localeId: $localeId)
            }
            nationalGITypeText
            geoIndications {
              id
              localizedName(localeId: $localeId)
              wineRegionsEU {
                id
                localizedName(localeId: $localeId)
              }
            }
            certs {
              id
              localizedName(localeId: $localeId)
            }
            geoIndicationText
            vintage
            country {
              id
              localizedName(localeId: $localeId)
              codeISO
            }
            tradeName {
              id
              acceptability
              tradeName
              country {
                id
                localizedName(localeId: $localeId)
                codeISO
              }
            }
            wineType {
              id
              isDefault
              localizedName(localeId: $localeId)
            }
            wineColor {
              id
              localizedName(localeId: $localeId)
            }
            brand {
              id
              name
            }
            sweetnessLevel {
              id
              isDefault
              localizedName(localeId: $localeId)
            }
            keyWords
            specialClassifications {
              id
              name
            }
          }
          margin(companyId: $companyId) {
            value
            isForecast
          }
          coef(companyId: $companyId) {
            value
            isForecast
          }
          bestOfferPrice(companyId: $companyId) {
            price
            currency {
              code
            }
            isForecast
          }
          bottleImage {
            ...imageThumbnail48
            zoomedUrl: imageUrl(transformations: {width: 1000, height: 1000, enlarge: 1})
          }
          frontLabelImage {
            ...imageThumbnail48
            zoomedUrl: imageUrl(transformations: {width: 1000, height: 1000, enlarge: 1})
          }
          backLabelImage {
            ...imageThumbnail48
            zoomedUrl: imageUrl(transformations: {width: 1000, height: 1000, enlarge: 1})
          }
          minimalRecommendedRetailPrice(companyId: $companyId) {
            isForecast
            currency {
              code
            }
            price
          }
          bottleSize {
            id
            volumeML
          }
          packageType {
            id
            isDefault
            shortName
            localizedName(localeId: $localeId)
          }
          recommendedRetailPrice
          minimalRetailPrice(companyId: $companyId) {
            isForecast
            currency {
              code
            }
            price
          }
        }
        tradeNameCount
        pageInfo {
          totalItems
          totalPages
          page
          perPage
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
}
    ${ImageThumbnail48FragmentDoc}`;

/**
 * __useCompanyBuyProductsPaginationQuery__
 *
 * To run a query within a React component, call `useCompanyBuyProductsPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyBuyProductsPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyBuyProductsPaginationQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      companyId: // value for 'companyId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCompanyBuyProductsPaginationQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyBuyProductsPaginationQuery, Types.CompanyBuyProductsPaginationQueryVariables> & ({ variables: Types.CompanyBuyProductsPaginationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyBuyProductsPaginationQuery, Types.CompanyBuyProductsPaginationQueryVariables>(CompanyBuyProductsPaginationDocument, options);
      }
export function useCompanyBuyProductsPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyBuyProductsPaginationQuery, Types.CompanyBuyProductsPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyBuyProductsPaginationQuery, Types.CompanyBuyProductsPaginationQueryVariables>(CompanyBuyProductsPaginationDocument, options);
        }
export function useCompanyBuyProductsPaginationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CompanyBuyProductsPaginationQuery, Types.CompanyBuyProductsPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompanyBuyProductsPaginationQuery, Types.CompanyBuyProductsPaginationQueryVariables>(CompanyBuyProductsPaginationDocument, options);
        }
export type CompanyBuyProductsPaginationQueryHookResult = ReturnType<typeof useCompanyBuyProductsPaginationQuery>;
export type CompanyBuyProductsPaginationLazyQueryHookResult = ReturnType<typeof useCompanyBuyProductsPaginationLazyQuery>;
export type CompanyBuyProductsPaginationSuspenseQueryHookResult = ReturnType<typeof useCompanyBuyProductsPaginationSuspenseQuery>;
export type CompanyBuyProductsPaginationQueryResult = Apollo.QueryResult<Types.CompanyBuyProductsPaginationQuery, Types.CompanyBuyProductsPaginationQueryVariables>;
export const AwardRewardScoreLevelsDocument = gql`
    query awardRewardScoreLevels {
  awardRewardScoreLevels {
    id
    name
  }
}
    `;

/**
 * __useAwardRewardScoreLevelsQuery__
 *
 * To run a query within a React component, call `useAwardRewardScoreLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAwardRewardScoreLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAwardRewardScoreLevelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAwardRewardScoreLevelsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AwardRewardScoreLevelsQuery, Types.AwardRewardScoreLevelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AwardRewardScoreLevelsQuery, Types.AwardRewardScoreLevelsQueryVariables>(AwardRewardScoreLevelsDocument, options);
      }
export function useAwardRewardScoreLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AwardRewardScoreLevelsQuery, Types.AwardRewardScoreLevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AwardRewardScoreLevelsQuery, Types.AwardRewardScoreLevelsQueryVariables>(AwardRewardScoreLevelsDocument, options);
        }
export function useAwardRewardScoreLevelsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AwardRewardScoreLevelsQuery, Types.AwardRewardScoreLevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AwardRewardScoreLevelsQuery, Types.AwardRewardScoreLevelsQueryVariables>(AwardRewardScoreLevelsDocument, options);
        }
export type AwardRewardScoreLevelsQueryHookResult = ReturnType<typeof useAwardRewardScoreLevelsQuery>;
export type AwardRewardScoreLevelsLazyQueryHookResult = ReturnType<typeof useAwardRewardScoreLevelsLazyQuery>;
export type AwardRewardScoreLevelsSuspenseQueryHookResult = ReturnType<typeof useAwardRewardScoreLevelsSuspenseQuery>;
export type AwardRewardScoreLevelsQueryResult = Apollo.QueryResult<Types.AwardRewardScoreLevelsQuery, Types.AwardRewardScoreLevelsQueryVariables>;
export const AwardRewardTypesDocument = gql`
    query awardRewardTypes {
  awardRewardTypes {
    id
    masterName
  }
}
    `;

/**
 * __useAwardRewardTypesQuery__
 *
 * To run a query within a React component, call `useAwardRewardTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAwardRewardTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAwardRewardTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAwardRewardTypesQuery(baseOptions?: Apollo.QueryHookOptions<Types.AwardRewardTypesQuery, Types.AwardRewardTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AwardRewardTypesQuery, Types.AwardRewardTypesQueryVariables>(AwardRewardTypesDocument, options);
      }
export function useAwardRewardTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AwardRewardTypesQuery, Types.AwardRewardTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AwardRewardTypesQuery, Types.AwardRewardTypesQueryVariables>(AwardRewardTypesDocument, options);
        }
export function useAwardRewardTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AwardRewardTypesQuery, Types.AwardRewardTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AwardRewardTypesQuery, Types.AwardRewardTypesQueryVariables>(AwardRewardTypesDocument, options);
        }
export type AwardRewardTypesQueryHookResult = ReturnType<typeof useAwardRewardTypesQuery>;
export type AwardRewardTypesLazyQueryHookResult = ReturnType<typeof useAwardRewardTypesLazyQuery>;
export type AwardRewardTypesSuspenseQueryHookResult = ReturnType<typeof useAwardRewardTypesSuspenseQuery>;
export type AwardRewardTypesQueryResult = Apollo.QueryResult<Types.AwardRewardTypesQuery, Types.AwardRewardTypesQueryVariables>;
export const AwardsDocument = gql`
    query awards {
  awards {
    id
    masterName
    minScore
    maxScore
    roundScore
    stepScore
  }
}
    `;

/**
 * __useAwardsQuery__
 *
 * To run a query within a React component, call `useAwardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAwardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAwardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAwardsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AwardsQuery, Types.AwardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AwardsQuery, Types.AwardsQueryVariables>(AwardsDocument, options);
      }
export function useAwardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AwardsQuery, Types.AwardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AwardsQuery, Types.AwardsQueryVariables>(AwardsDocument, options);
        }
export function useAwardsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AwardsQuery, Types.AwardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AwardsQuery, Types.AwardsQueryVariables>(AwardsDocument, options);
        }
export type AwardsQueryHookResult = ReturnType<typeof useAwardsQuery>;
export type AwardsLazyQueryHookResult = ReturnType<typeof useAwardsLazyQuery>;
export type AwardsSuspenseQueryHookResult = ReturnType<typeof useAwardsSuspenseQuery>;
export type AwardsQueryResult = Apollo.QueryResult<Types.AwardsQuery, Types.AwardsQueryVariables>;
export const BottleSizesDocument = gql`
    query bottleSizes {
  bottleSizes {
    id
    volumeML
  }
}
    `;

/**
 * __useBottleSizesQuery__
 *
 * To run a query within a React component, call `useBottleSizesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBottleSizesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBottleSizesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBottleSizesQuery(baseOptions?: Apollo.QueryHookOptions<Types.BottleSizesQuery, Types.BottleSizesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BottleSizesQuery, Types.BottleSizesQueryVariables>(BottleSizesDocument, options);
      }
export function useBottleSizesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BottleSizesQuery, Types.BottleSizesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BottleSizesQuery, Types.BottleSizesQueryVariables>(BottleSizesDocument, options);
        }
export function useBottleSizesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BottleSizesQuery, Types.BottleSizesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BottleSizesQuery, Types.BottleSizesQueryVariables>(BottleSizesDocument, options);
        }
export type BottleSizesQueryHookResult = ReturnType<typeof useBottleSizesQuery>;
export type BottleSizesLazyQueryHookResult = ReturnType<typeof useBottleSizesLazyQuery>;
export type BottleSizesSuspenseQueryHookResult = ReturnType<typeof useBottleSizesSuspenseQuery>;
export type BottleSizesQueryResult = Apollo.QueryResult<Types.BottleSizesQuery, Types.BottleSizesQueryVariables>;
export const CertificationsDocument = gql`
    query certifications($localeId: Int!) {
  certifications {
    id
    logo
    masterName
    localizedName(localeId: $localeId)
  }
}
    `;

/**
 * __useCertificationsQuery__
 *
 * To run a query within a React component, call `useCertificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificationsQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useCertificationsQuery(baseOptions: Apollo.QueryHookOptions<Types.CertificationsQuery, Types.CertificationsQueryVariables> & ({ variables: Types.CertificationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CertificationsQuery, Types.CertificationsQueryVariables>(CertificationsDocument, options);
      }
export function useCertificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CertificationsQuery, Types.CertificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CertificationsQuery, Types.CertificationsQueryVariables>(CertificationsDocument, options);
        }
export function useCertificationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CertificationsQuery, Types.CertificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CertificationsQuery, Types.CertificationsQueryVariables>(CertificationsDocument, options);
        }
export type CertificationsQueryHookResult = ReturnType<typeof useCertificationsQuery>;
export type CertificationsLazyQueryHookResult = ReturnType<typeof useCertificationsLazyQuery>;
export type CertificationsSuspenseQueryHookResult = ReturnType<typeof useCertificationsSuspenseQuery>;
export type CertificationsQueryResult = Apollo.QueryResult<Types.CertificationsQuery, Types.CertificationsQueryVariables>;
export const ClosureTypesDocument = gql`
    query closureTypes($localeId: Int!) {
  closureTypes {
    id
    localizedName(localeId: $localeId)
  }
}
    `;

/**
 * __useClosureTypesQuery__
 *
 * To run a query within a React component, call `useClosureTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClosureTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClosureTypesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useClosureTypesQuery(baseOptions: Apollo.QueryHookOptions<Types.ClosureTypesQuery, Types.ClosureTypesQueryVariables> & ({ variables: Types.ClosureTypesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ClosureTypesQuery, Types.ClosureTypesQueryVariables>(ClosureTypesDocument, options);
      }
export function useClosureTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ClosureTypesQuery, Types.ClosureTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ClosureTypesQuery, Types.ClosureTypesQueryVariables>(ClosureTypesDocument, options);
        }
export function useClosureTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ClosureTypesQuery, Types.ClosureTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ClosureTypesQuery, Types.ClosureTypesQueryVariables>(ClosureTypesDocument, options);
        }
export type ClosureTypesQueryHookResult = ReturnType<typeof useClosureTypesQuery>;
export type ClosureTypesLazyQueryHookResult = ReturnType<typeof useClosureTypesLazyQuery>;
export type ClosureTypesSuspenseQueryHookResult = ReturnType<typeof useClosureTypesSuspenseQuery>;
export type ClosureTypesQueryResult = Apollo.QueryResult<Types.ClosureTypesQuery, Types.ClosureTypesQueryVariables>;
export const CompanyCategoriesDocument = gql`
    query companyCategories($localeId: Int!) {
  companyCategories {
    id
    masterName
    localizedName(localeId: $localeId)
  }
}
    `;

/**
 * __useCompanyCategoriesQuery__
 *
 * To run a query within a React component, call `useCompanyCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyCategoriesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useCompanyCategoriesQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyCategoriesQuery, Types.CompanyCategoriesQueryVariables> & ({ variables: Types.CompanyCategoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyCategoriesQuery, Types.CompanyCategoriesQueryVariables>(CompanyCategoriesDocument, options);
      }
export function useCompanyCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyCategoriesQuery, Types.CompanyCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyCategoriesQuery, Types.CompanyCategoriesQueryVariables>(CompanyCategoriesDocument, options);
        }
export function useCompanyCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CompanyCategoriesQuery, Types.CompanyCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompanyCategoriesQuery, Types.CompanyCategoriesQueryVariables>(CompanyCategoriesDocument, options);
        }
export type CompanyCategoriesQueryHookResult = ReturnType<typeof useCompanyCategoriesQuery>;
export type CompanyCategoriesLazyQueryHookResult = ReturnType<typeof useCompanyCategoriesLazyQuery>;
export type CompanyCategoriesSuspenseQueryHookResult = ReturnType<typeof useCompanyCategoriesSuspenseQuery>;
export type CompanyCategoriesQueryResult = Apollo.QueryResult<Types.CompanyCategoriesQuery, Types.CompanyCategoriesQueryVariables>;
export const CountriesListDocument = gql`
    query countriesList($localeId: Int!) {
  countries {
    id
    localizedName(localeId: $localeId)
    codeISO
  }
}
    `;

/**
 * __useCountriesListQuery__
 *
 * To run a query within a React component, call `useCountriesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesListQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useCountriesListQuery(baseOptions: Apollo.QueryHookOptions<Types.CountriesListQuery, Types.CountriesListQueryVariables> & ({ variables: Types.CountriesListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountriesListQuery, Types.CountriesListQueryVariables>(CountriesListDocument, options);
      }
export function useCountriesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountriesListQuery, Types.CountriesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountriesListQuery, Types.CountriesListQueryVariables>(CountriesListDocument, options);
        }
export function useCountriesListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CountriesListQuery, Types.CountriesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CountriesListQuery, Types.CountriesListQueryVariables>(CountriesListDocument, options);
        }
export type CountriesListQueryHookResult = ReturnType<typeof useCountriesListQuery>;
export type CountriesListLazyQueryHookResult = ReturnType<typeof useCountriesListLazyQuery>;
export type CountriesListSuspenseQueryHookResult = ReturnType<typeof useCountriesListSuspenseQuery>;
export type CountriesListQueryResult = Apollo.QueryResult<Types.CountriesListQuery, Types.CountriesListQueryVariables>;
export const CurrenciesDocument = gql`
    query currencies {
  currencies {
    id
    code
  }
}
    `;

/**
 * __useCurrenciesQuery__
 *
 * To run a query within a React component, call `useCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<Types.CurrenciesQuery, Types.CurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CurrenciesQuery, Types.CurrenciesQueryVariables>(CurrenciesDocument, options);
      }
export function useCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CurrenciesQuery, Types.CurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CurrenciesQuery, Types.CurrenciesQueryVariables>(CurrenciesDocument, options);
        }
export function useCurrenciesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CurrenciesQuery, Types.CurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CurrenciesQuery, Types.CurrenciesQueryVariables>(CurrenciesDocument, options);
        }
export type CurrenciesQueryHookResult = ReturnType<typeof useCurrenciesQuery>;
export type CurrenciesLazyQueryHookResult = ReturnType<typeof useCurrenciesLazyQuery>;
export type CurrenciesSuspenseQueryHookResult = ReturnType<typeof useCurrenciesSuspenseQuery>;
export type CurrenciesQueryResult = Apollo.QueryResult<Types.CurrenciesQuery, Types.CurrenciesQueryVariables>;
export const DeliveryTimesDocument = gql`
    query deliveryTimes($localeId: Int!) {
  deliveryTimes {
    id
    localizedName(localeId: $localeId)
  }
}
    `;

/**
 * __useDeliveryTimesQuery__
 *
 * To run a query within a React component, call `useDeliveryTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryTimesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useDeliveryTimesQuery(baseOptions: Apollo.QueryHookOptions<Types.DeliveryTimesQuery, Types.DeliveryTimesQueryVariables> & ({ variables: Types.DeliveryTimesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DeliveryTimesQuery, Types.DeliveryTimesQueryVariables>(DeliveryTimesDocument, options);
      }
export function useDeliveryTimesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DeliveryTimesQuery, Types.DeliveryTimesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DeliveryTimesQuery, Types.DeliveryTimesQueryVariables>(DeliveryTimesDocument, options);
        }
export function useDeliveryTimesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.DeliveryTimesQuery, Types.DeliveryTimesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.DeliveryTimesQuery, Types.DeliveryTimesQueryVariables>(DeliveryTimesDocument, options);
        }
export type DeliveryTimesQueryHookResult = ReturnType<typeof useDeliveryTimesQuery>;
export type DeliveryTimesLazyQueryHookResult = ReturnType<typeof useDeliveryTimesLazyQuery>;
export type DeliveryTimesSuspenseQueryHookResult = ReturnType<typeof useDeliveryTimesSuspenseQuery>;
export type DeliveryTimesQueryResult = Apollo.QueryResult<Types.DeliveryTimesQuery, Types.DeliveryTimesQueryVariables>;
export const EuPackageMaterialsDocument = gql`
    query euPackageMaterials($localeId: Int!) {
  euPackageMaterials {
    id
    logo
    code
    localizedName(localeId: $localeId)
    localizedDisposal(localeId: $localeId)
  }
}
    `;

/**
 * __useEuPackageMaterialsQuery__
 *
 * To run a query within a React component, call `useEuPackageMaterialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEuPackageMaterialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEuPackageMaterialsQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useEuPackageMaterialsQuery(baseOptions: Apollo.QueryHookOptions<Types.EuPackageMaterialsQuery, Types.EuPackageMaterialsQueryVariables> & ({ variables: Types.EuPackageMaterialsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EuPackageMaterialsQuery, Types.EuPackageMaterialsQueryVariables>(EuPackageMaterialsDocument, options);
      }
export function useEuPackageMaterialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EuPackageMaterialsQuery, Types.EuPackageMaterialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EuPackageMaterialsQuery, Types.EuPackageMaterialsQueryVariables>(EuPackageMaterialsDocument, options);
        }
export function useEuPackageMaterialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EuPackageMaterialsQuery, Types.EuPackageMaterialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EuPackageMaterialsQuery, Types.EuPackageMaterialsQueryVariables>(EuPackageMaterialsDocument, options);
        }
export type EuPackageMaterialsQueryHookResult = ReturnType<typeof useEuPackageMaterialsQuery>;
export type EuPackageMaterialsLazyQueryHookResult = ReturnType<typeof useEuPackageMaterialsLazyQuery>;
export type EuPackageMaterialsSuspenseQueryHookResult = ReturnType<typeof useEuPackageMaterialsSuspenseQuery>;
export type EuPackageMaterialsQueryResult = Apollo.QueryResult<Types.EuPackageMaterialsQuery, Types.EuPackageMaterialsQueryVariables>;
export const ExhibitionTypesDocument = gql`
    query exhibitionTypes($localeId: Int!) {
  exhibitionTypes {
    id
    localizedName(localeId: $localeId)
  }
}
    `;

/**
 * __useExhibitionTypesQuery__
 *
 * To run a query within a React component, call `useExhibitionTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExhibitionTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExhibitionTypesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useExhibitionTypesQuery(baseOptions: Apollo.QueryHookOptions<Types.ExhibitionTypesQuery, Types.ExhibitionTypesQueryVariables> & ({ variables: Types.ExhibitionTypesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExhibitionTypesQuery, Types.ExhibitionTypesQueryVariables>(ExhibitionTypesDocument, options);
      }
export function useExhibitionTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExhibitionTypesQuery, Types.ExhibitionTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExhibitionTypesQuery, Types.ExhibitionTypesQueryVariables>(ExhibitionTypesDocument, options);
        }
export function useExhibitionTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ExhibitionTypesQuery, Types.ExhibitionTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ExhibitionTypesQuery, Types.ExhibitionTypesQueryVariables>(ExhibitionTypesDocument, options);
        }
export type ExhibitionTypesQueryHookResult = ReturnType<typeof useExhibitionTypesQuery>;
export type ExhibitionTypesLazyQueryHookResult = ReturnType<typeof useExhibitionTypesLazyQuery>;
export type ExhibitionTypesSuspenseQueryHookResult = ReturnType<typeof useExhibitionTypesSuspenseQuery>;
export type ExhibitionTypesQueryResult = Apollo.QueryResult<Types.ExhibitionTypesQuery, Types.ExhibitionTypesQueryVariables>;
export const ExhibitionsDocument = gql`
    query exhibitions {
  exhibitions {
    id
    name
  }
}
    `;

/**
 * __useExhibitionsQuery__
 *
 * To run a query within a React component, call `useExhibitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExhibitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExhibitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExhibitionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ExhibitionsQuery, Types.ExhibitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExhibitionsQuery, Types.ExhibitionsQueryVariables>(ExhibitionsDocument, options);
      }
export function useExhibitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExhibitionsQuery, Types.ExhibitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExhibitionsQuery, Types.ExhibitionsQueryVariables>(ExhibitionsDocument, options);
        }
export function useExhibitionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ExhibitionsQuery, Types.ExhibitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ExhibitionsQuery, Types.ExhibitionsQueryVariables>(ExhibitionsDocument, options);
        }
export type ExhibitionsQueryHookResult = ReturnType<typeof useExhibitionsQuery>;
export type ExhibitionsLazyQueryHookResult = ReturnType<typeof useExhibitionsLazyQuery>;
export type ExhibitionsSuspenseQueryHookResult = ReturnType<typeof useExhibitionsSuspenseQuery>;
export type ExhibitionsQueryResult = Apollo.QueryResult<Types.ExhibitionsQuery, Types.ExhibitionsQueryVariables>;
export const FoodPairsDocument = gql`
    query foodPairs($localeId: Int!) {
  foodPairs {
    id
    localizedName(localeId: $localeId)
  }
}
    `;

/**
 * __useFoodPairsQuery__
 *
 * To run a query within a React component, call `useFoodPairsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodPairsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodPairsQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useFoodPairsQuery(baseOptions: Apollo.QueryHookOptions<Types.FoodPairsQuery, Types.FoodPairsQueryVariables> & ({ variables: Types.FoodPairsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FoodPairsQuery, Types.FoodPairsQueryVariables>(FoodPairsDocument, options);
      }
export function useFoodPairsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FoodPairsQuery, Types.FoodPairsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FoodPairsQuery, Types.FoodPairsQueryVariables>(FoodPairsDocument, options);
        }
export function useFoodPairsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.FoodPairsQuery, Types.FoodPairsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.FoodPairsQuery, Types.FoodPairsQueryVariables>(FoodPairsDocument, options);
        }
export type FoodPairsQueryHookResult = ReturnType<typeof useFoodPairsQuery>;
export type FoodPairsLazyQueryHookResult = ReturnType<typeof useFoodPairsLazyQuery>;
export type FoodPairsSuspenseQueryHookResult = ReturnType<typeof useFoodPairsSuspenseQuery>;
export type FoodPairsQueryResult = Apollo.QueryResult<Types.FoodPairsQuery, Types.FoodPairsQueryVariables>;
export const GrapeVarietySynonymsDocument = gql`
    query grapeVarietySynonyms($countryId: Int, $query: String) {
  grapeVarietySynonyms(countryId: $countryId, query: $query) {
    id
    name
  }
}
    `;

/**
 * __useGrapeVarietySynonymsQuery__
 *
 * To run a query within a React component, call `useGrapeVarietySynonymsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrapeVarietySynonymsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrapeVarietySynonymsQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGrapeVarietySynonymsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GrapeVarietySynonymsQuery, Types.GrapeVarietySynonymsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GrapeVarietySynonymsQuery, Types.GrapeVarietySynonymsQueryVariables>(GrapeVarietySynonymsDocument, options);
      }
export function useGrapeVarietySynonymsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GrapeVarietySynonymsQuery, Types.GrapeVarietySynonymsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GrapeVarietySynonymsQuery, Types.GrapeVarietySynonymsQueryVariables>(GrapeVarietySynonymsDocument, options);
        }
export function useGrapeVarietySynonymsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GrapeVarietySynonymsQuery, Types.GrapeVarietySynonymsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GrapeVarietySynonymsQuery, Types.GrapeVarietySynonymsQueryVariables>(GrapeVarietySynonymsDocument, options);
        }
export type GrapeVarietySynonymsQueryHookResult = ReturnType<typeof useGrapeVarietySynonymsQuery>;
export type GrapeVarietySynonymsLazyQueryHookResult = ReturnType<typeof useGrapeVarietySynonymsLazyQuery>;
export type GrapeVarietySynonymsSuspenseQueryHookResult = ReturnType<typeof useGrapeVarietySynonymsSuspenseQuery>;
export type GrapeVarietySynonymsQueryResult = Apollo.QueryResult<Types.GrapeVarietySynonymsQuery, Types.GrapeVarietySynonymsQueryVariables>;
export const IngredientsDocument = gql`
    query ingredients($localeId: Int!) {
  ingredients {
    id
    localizedName(localeId: $localeId)
    isAllergen
    code
    category {
      id
      localizedName(localeId: $localeId)
      isPrintable
    }
  }
}
    `;

/**
 * __useIngredientsQuery__
 *
 * To run a query within a React component, call `useIngredientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIngredientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIngredientsQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useIngredientsQuery(baseOptions: Apollo.QueryHookOptions<Types.IngredientsQuery, Types.IngredientsQueryVariables> & ({ variables: Types.IngredientsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IngredientsQuery, Types.IngredientsQueryVariables>(IngredientsDocument, options);
      }
export function useIngredientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IngredientsQuery, Types.IngredientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IngredientsQuery, Types.IngredientsQueryVariables>(IngredientsDocument, options);
        }
export function useIngredientsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.IngredientsQuery, Types.IngredientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IngredientsQuery, Types.IngredientsQueryVariables>(IngredientsDocument, options);
        }
export type IngredientsQueryHookResult = ReturnType<typeof useIngredientsQuery>;
export type IngredientsLazyQueryHookResult = ReturnType<typeof useIngredientsLazyQuery>;
export type IngredientsSuspenseQueryHookResult = ReturnType<typeof useIngredientsSuspenseQuery>;
export type IngredientsQueryResult = Apollo.QueryResult<Types.IngredientsQuery, Types.IngredientsQueryVariables>;
export const LegalEntityFormsDocument = gql`
    query legalEntityForms($countryId: Int!) {
  legalEntityForms(countryId: $countryId) {
    id
    originName
    shortName
  }
}
    `;

/**
 * __useLegalEntityFormsQuery__
 *
 * To run a query within a React component, call `useLegalEntityFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegalEntityFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegalEntityFormsQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useLegalEntityFormsQuery(baseOptions: Apollo.QueryHookOptions<Types.LegalEntityFormsQuery, Types.LegalEntityFormsQueryVariables> & ({ variables: Types.LegalEntityFormsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LegalEntityFormsQuery, Types.LegalEntityFormsQueryVariables>(LegalEntityFormsDocument, options);
      }
export function useLegalEntityFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LegalEntityFormsQuery, Types.LegalEntityFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LegalEntityFormsQuery, Types.LegalEntityFormsQueryVariables>(LegalEntityFormsDocument, options);
        }
export function useLegalEntityFormsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LegalEntityFormsQuery, Types.LegalEntityFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LegalEntityFormsQuery, Types.LegalEntityFormsQueryVariables>(LegalEntityFormsDocument, options);
        }
export type LegalEntityFormsQueryHookResult = ReturnType<typeof useLegalEntityFormsQuery>;
export type LegalEntityFormsLazyQueryHookResult = ReturnType<typeof useLegalEntityFormsLazyQuery>;
export type LegalEntityFormsSuspenseQueryHookResult = ReturnType<typeof useLegalEntityFormsSuspenseQuery>;
export type LegalEntityFormsQueryResult = Apollo.QueryResult<Types.LegalEntityFormsQuery, Types.LegalEntityFormsQueryVariables>;
export const LocalesDocument = gql`
    query locales {
  locales {
    id
    locale
    name
  }
}
    `;

/**
 * __useLocalesQuery__
 *
 * To run a query within a React component, call `useLocalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocalesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocalesQuery(baseOptions?: Apollo.QueryHookOptions<Types.LocalesQuery, Types.LocalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LocalesQuery, Types.LocalesQueryVariables>(LocalesDocument, options);
      }
export function useLocalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LocalesQuery, Types.LocalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LocalesQuery, Types.LocalesQueryVariables>(LocalesDocument, options);
        }
export function useLocalesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LocalesQuery, Types.LocalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LocalesQuery, Types.LocalesQueryVariables>(LocalesDocument, options);
        }
export type LocalesQueryHookResult = ReturnType<typeof useLocalesQuery>;
export type LocalesLazyQueryHookResult = ReturnType<typeof useLocalesLazyQuery>;
export type LocalesSuspenseQueryHookResult = ReturnType<typeof useLocalesSuspenseQuery>;
export type LocalesQueryResult = Apollo.QueryResult<Types.LocalesQuery, Types.LocalesQueryVariables>;
export const LocalizedLocalesDocument = gql`
    query localizedLocales($localeId: Int!) {
  locales {
    id
    name
    locale
    localizedName(localeId: $localeId)
  }
}
    `;

/**
 * __useLocalizedLocalesQuery__
 *
 * To run a query within a React component, call `useLocalizedLocalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocalizedLocalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocalizedLocalesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useLocalizedLocalesQuery(baseOptions: Apollo.QueryHookOptions<Types.LocalizedLocalesQuery, Types.LocalizedLocalesQueryVariables> & ({ variables: Types.LocalizedLocalesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LocalizedLocalesQuery, Types.LocalizedLocalesQueryVariables>(LocalizedLocalesDocument, options);
      }
export function useLocalizedLocalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LocalizedLocalesQuery, Types.LocalizedLocalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LocalizedLocalesQuery, Types.LocalizedLocalesQueryVariables>(LocalizedLocalesDocument, options);
        }
export function useLocalizedLocalesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LocalizedLocalesQuery, Types.LocalizedLocalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LocalizedLocalesQuery, Types.LocalizedLocalesQueryVariables>(LocalizedLocalesDocument, options);
        }
export type LocalizedLocalesQueryHookResult = ReturnType<typeof useLocalizedLocalesQuery>;
export type LocalizedLocalesLazyQueryHookResult = ReturnType<typeof useLocalizedLocalesLazyQuery>;
export type LocalizedLocalesSuspenseQueryHookResult = ReturnType<typeof useLocalizedLocalesSuspenseQuery>;
export type LocalizedLocalesQueryResult = Apollo.QueryResult<Types.LocalizedLocalesQuery, Types.LocalizedLocalesQueryVariables>;
export const ScoreSystemsDocument = gql`
    query scoreSystems($localeId: Int!) {
  scoreSystems {
    id
    minScore
    maxScore
    localizedName(localeId: $localeId)
  }
}
    `;

/**
 * __useScoreSystemsQuery__
 *
 * To run a query within a React component, call `useScoreSystemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScoreSystemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScoreSystemsQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useScoreSystemsQuery(baseOptions: Apollo.QueryHookOptions<Types.ScoreSystemsQuery, Types.ScoreSystemsQueryVariables> & ({ variables: Types.ScoreSystemsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ScoreSystemsQuery, Types.ScoreSystemsQueryVariables>(ScoreSystemsDocument, options);
      }
export function useScoreSystemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ScoreSystemsQuery, Types.ScoreSystemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ScoreSystemsQuery, Types.ScoreSystemsQueryVariables>(ScoreSystemsDocument, options);
        }
export function useScoreSystemsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ScoreSystemsQuery, Types.ScoreSystemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ScoreSystemsQuery, Types.ScoreSystemsQueryVariables>(ScoreSystemsDocument, options);
        }
export type ScoreSystemsQueryHookResult = ReturnType<typeof useScoreSystemsQuery>;
export type ScoreSystemsLazyQueryHookResult = ReturnType<typeof useScoreSystemsLazyQuery>;
export type ScoreSystemsSuspenseQueryHookResult = ReturnType<typeof useScoreSystemsSuspenseQuery>;
export type ScoreSystemsQueryResult = Apollo.QueryResult<Types.ScoreSystemsQuery, Types.ScoreSystemsQueryVariables>;
export const SweetnessLevelsForCountryDocument = gql`
    query sweetnessLevelsForCountry($localeId: Int!, $countryId: Int!, $wineTypeId: Int!) {
  country(id: $countryId) {
    id
    sweetnessLevels(wineTypeId: $wineTypeId) {
      id
      isDefault
      localizedName(localeId: $localeId)
    }
  }
}
    `;

/**
 * __useSweetnessLevelsForCountryQuery__
 *
 * To run a query within a React component, call `useSweetnessLevelsForCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSweetnessLevelsForCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSweetnessLevelsForCountryQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      countryId: // value for 'countryId'
 *      wineTypeId: // value for 'wineTypeId'
 *   },
 * });
 */
export function useSweetnessLevelsForCountryQuery(baseOptions: Apollo.QueryHookOptions<Types.SweetnessLevelsForCountryQuery, Types.SweetnessLevelsForCountryQueryVariables> & ({ variables: Types.SweetnessLevelsForCountryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SweetnessLevelsForCountryQuery, Types.SweetnessLevelsForCountryQueryVariables>(SweetnessLevelsForCountryDocument, options);
      }
export function useSweetnessLevelsForCountryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SweetnessLevelsForCountryQuery, Types.SweetnessLevelsForCountryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SweetnessLevelsForCountryQuery, Types.SweetnessLevelsForCountryQueryVariables>(SweetnessLevelsForCountryDocument, options);
        }
export function useSweetnessLevelsForCountrySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SweetnessLevelsForCountryQuery, Types.SweetnessLevelsForCountryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SweetnessLevelsForCountryQuery, Types.SweetnessLevelsForCountryQueryVariables>(SweetnessLevelsForCountryDocument, options);
        }
export type SweetnessLevelsForCountryQueryHookResult = ReturnType<typeof useSweetnessLevelsForCountryQuery>;
export type SweetnessLevelsForCountryLazyQueryHookResult = ReturnType<typeof useSweetnessLevelsForCountryLazyQuery>;
export type SweetnessLevelsForCountrySuspenseQueryHookResult = ReturnType<typeof useSweetnessLevelsForCountrySuspenseQuery>;
export type SweetnessLevelsForCountryQueryResult = Apollo.QueryResult<Types.SweetnessLevelsForCountryQuery, Types.SweetnessLevelsForCountryQueryVariables>;
export const TradeNameBrandsDocument = gql`
    query tradeNameBrands($query: String, $tradeNameId: ID!, $localeId: Int!) {
  tradeName(id: $tradeNameId) {
    id
    brands(onlyAccepted: true, query: $query) {
      id
      name
      localizedName(localeId: $localeId)
    }
  }
}
    `;

/**
 * __useTradeNameBrandsQuery__
 *
 * To run a query within a React component, call `useTradeNameBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeNameBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeNameBrandsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      tradeNameId: // value for 'tradeNameId'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useTradeNameBrandsQuery(baseOptions: Apollo.QueryHookOptions<Types.TradeNameBrandsQuery, Types.TradeNameBrandsQueryVariables> & ({ variables: Types.TradeNameBrandsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradeNameBrandsQuery, Types.TradeNameBrandsQueryVariables>(TradeNameBrandsDocument, options);
      }
export function useTradeNameBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradeNameBrandsQuery, Types.TradeNameBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradeNameBrandsQuery, Types.TradeNameBrandsQueryVariables>(TradeNameBrandsDocument, options);
        }
export function useTradeNameBrandsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradeNameBrandsQuery, Types.TradeNameBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradeNameBrandsQuery, Types.TradeNameBrandsQueryVariables>(TradeNameBrandsDocument, options);
        }
export type TradeNameBrandsQueryHookResult = ReturnType<typeof useTradeNameBrandsQuery>;
export type TradeNameBrandsLazyQueryHookResult = ReturnType<typeof useTradeNameBrandsLazyQuery>;
export type TradeNameBrandsSuspenseQueryHookResult = ReturnType<typeof useTradeNameBrandsSuspenseQuery>;
export type TradeNameBrandsQueryResult = Apollo.QueryResult<Types.TradeNameBrandsQuery, Types.TradeNameBrandsQueryVariables>;
export const WineColorsDocument = gql`
    query wineColors($localeId: Int!) {
  wineColors {
    id
    localizedName(localeId: $localeId)
  }
}
    `;

/**
 * __useWineColorsQuery__
 *
 * To run a query within a React component, call `useWineColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineColorsQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useWineColorsQuery(baseOptions: Apollo.QueryHookOptions<Types.WineColorsQuery, Types.WineColorsQueryVariables> & ({ variables: Types.WineColorsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineColorsQuery, Types.WineColorsQueryVariables>(WineColorsDocument, options);
      }
export function useWineColorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineColorsQuery, Types.WineColorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineColorsQuery, Types.WineColorsQueryVariables>(WineColorsDocument, options);
        }
export function useWineColorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineColorsQuery, Types.WineColorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineColorsQuery, Types.WineColorsQueryVariables>(WineColorsDocument, options);
        }
export type WineColorsQueryHookResult = ReturnType<typeof useWineColorsQuery>;
export type WineColorsLazyQueryHookResult = ReturnType<typeof useWineColorsLazyQuery>;
export type WineColorsSuspenseQueryHookResult = ReturnType<typeof useWineColorsSuspenseQuery>;
export type WineColorsQueryResult = Apollo.QueryResult<Types.WineColorsQuery, Types.WineColorsQueryVariables>;
export const WineLotsDocument = gql`
    query wineLots($localeId: Int!) {
  wineLots {
    id
    localizedName(localeId: $localeId)
    bottleQuantity
    masterName
  }
}
    `;

/**
 * __useWineLotsQuery__
 *
 * To run a query within a React component, call `useWineLotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineLotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineLotsQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useWineLotsQuery(baseOptions: Apollo.QueryHookOptions<Types.WineLotsQuery, Types.WineLotsQueryVariables> & ({ variables: Types.WineLotsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineLotsQuery, Types.WineLotsQueryVariables>(WineLotsDocument, options);
      }
export function useWineLotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineLotsQuery, Types.WineLotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineLotsQuery, Types.WineLotsQueryVariables>(WineLotsDocument, options);
        }
export function useWineLotsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineLotsQuery, Types.WineLotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineLotsQuery, Types.WineLotsQueryVariables>(WineLotsDocument, options);
        }
export type WineLotsQueryHookResult = ReturnType<typeof useWineLotsQuery>;
export type WineLotsLazyQueryHookResult = ReturnType<typeof useWineLotsLazyQuery>;
export type WineLotsSuspenseQueryHookResult = ReturnType<typeof useWineLotsSuspenseQuery>;
export type WineLotsQueryResult = Apollo.QueryResult<Types.WineLotsQuery, Types.WineLotsQueryVariables>;
export const WinePackageElementsDocument = gql`
    query winePackageElements($localeId: Int!) {
  winePackageElements {
    id
    localizedName(localeId: $localeId)
  }
}
    `;

/**
 * __useWinePackageElementsQuery__
 *
 * To run a query within a React component, call `useWinePackageElementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinePackageElementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinePackageElementsQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useWinePackageElementsQuery(baseOptions: Apollo.QueryHookOptions<Types.WinePackageElementsQuery, Types.WinePackageElementsQueryVariables> & ({ variables: Types.WinePackageElementsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WinePackageElementsQuery, Types.WinePackageElementsQueryVariables>(WinePackageElementsDocument, options);
      }
export function useWinePackageElementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WinePackageElementsQuery, Types.WinePackageElementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WinePackageElementsQuery, Types.WinePackageElementsQueryVariables>(WinePackageElementsDocument, options);
        }
export function useWinePackageElementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WinePackageElementsQuery, Types.WinePackageElementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WinePackageElementsQuery, Types.WinePackageElementsQueryVariables>(WinePackageElementsDocument, options);
        }
export type WinePackageElementsQueryHookResult = ReturnType<typeof useWinePackageElementsQuery>;
export type WinePackageElementsLazyQueryHookResult = ReturnType<typeof useWinePackageElementsLazyQuery>;
export type WinePackageElementsSuspenseQueryHookResult = ReturnType<typeof useWinePackageElementsSuspenseQuery>;
export type WinePackageElementsQueryResult = Apollo.QueryResult<Types.WinePackageElementsQuery, Types.WinePackageElementsQueryVariables>;
export const WineRegionsEuDocument = gql`
    query wineRegionsEU($localeId: Int!) {
  wineRegionsEU {
    id
    localizedName(localeId: $localeId)
  }
}
    `;

/**
 * __useWineRegionsEuQuery__
 *
 * To run a query within a React component, call `useWineRegionsEuQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineRegionsEuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineRegionsEuQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useWineRegionsEuQuery(baseOptions: Apollo.QueryHookOptions<Types.WineRegionsEuQuery, Types.WineRegionsEuQueryVariables> & ({ variables: Types.WineRegionsEuQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineRegionsEuQuery, Types.WineRegionsEuQueryVariables>(WineRegionsEuDocument, options);
      }
export function useWineRegionsEuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineRegionsEuQuery, Types.WineRegionsEuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineRegionsEuQuery, Types.WineRegionsEuQueryVariables>(WineRegionsEuDocument, options);
        }
export function useWineRegionsEuSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineRegionsEuQuery, Types.WineRegionsEuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineRegionsEuQuery, Types.WineRegionsEuQueryVariables>(WineRegionsEuDocument, options);
        }
export type WineRegionsEuQueryHookResult = ReturnType<typeof useWineRegionsEuQuery>;
export type WineRegionsEuLazyQueryHookResult = ReturnType<typeof useWineRegionsEuLazyQuery>;
export type WineRegionsEuSuspenseQueryHookResult = ReturnType<typeof useWineRegionsEuSuspenseQuery>;
export type WineRegionsEuQueryResult = Apollo.QueryResult<Types.WineRegionsEuQuery, Types.WineRegionsEuQueryVariables>;
export const WineTypesDocument = gql`
    query wineTypes($localeId: Int!) {
  wineTypes {
    id
    isDefault
    localizedName(localeId: $localeId)
  }
}
    `;

/**
 * __useWineTypesQuery__
 *
 * To run a query within a React component, call `useWineTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineTypesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useWineTypesQuery(baseOptions: Apollo.QueryHookOptions<Types.WineTypesQuery, Types.WineTypesQueryVariables> & ({ variables: Types.WineTypesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineTypesQuery, Types.WineTypesQueryVariables>(WineTypesDocument, options);
      }
export function useWineTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineTypesQuery, Types.WineTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineTypesQuery, Types.WineTypesQueryVariables>(WineTypesDocument, options);
        }
export function useWineTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineTypesQuery, Types.WineTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineTypesQuery, Types.WineTypesQueryVariables>(WineTypesDocument, options);
        }
export type WineTypesQueryHookResult = ReturnType<typeof useWineTypesQuery>;
export type WineTypesLazyQueryHookResult = ReturnType<typeof useWineTypesLazyQuery>;
export type WineTypesSuspenseQueryHookResult = ReturnType<typeof useWineTypesSuspenseQuery>;
export type WineTypesQueryResult = Apollo.QueryResult<Types.WineTypesQuery, Types.WineTypesQueryVariables>;
export const WineTypesEuDocument = gql`
    query wineTypesEU($localeId: Int!) {
  wineTypesEU {
    id
    localizedName(localeId: $localeId)
  }
}
    `;

/**
 * __useWineTypesEuQuery__
 *
 * To run a query within a React component, call `useWineTypesEuQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineTypesEuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineTypesEuQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useWineTypesEuQuery(baseOptions: Apollo.QueryHookOptions<Types.WineTypesEuQuery, Types.WineTypesEuQueryVariables> & ({ variables: Types.WineTypesEuQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineTypesEuQuery, Types.WineTypesEuQueryVariables>(WineTypesEuDocument, options);
      }
export function useWineTypesEuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineTypesEuQuery, Types.WineTypesEuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineTypesEuQuery, Types.WineTypesEuQueryVariables>(WineTypesEuDocument, options);
        }
export function useWineTypesEuSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineTypesEuQuery, Types.WineTypesEuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineTypesEuQuery, Types.WineTypesEuQueryVariables>(WineTypesEuDocument, options);
        }
export type WineTypesEuQueryHookResult = ReturnType<typeof useWineTypesEuQuery>;
export type WineTypesEuLazyQueryHookResult = ReturnType<typeof useWineTypesEuLazyQuery>;
export type WineTypesEuSuspenseQueryHookResult = ReturnType<typeof useWineTypesEuSuspenseQuery>;
export type WineTypesEuQueryResult = Apollo.QueryResult<Types.WineTypesEuQuery, Types.WineTypesEuQueryVariables>;
export const TradeNameSearchForCompanyCreateDocument = gql`
    query tradeNameSearchForCompanyCreate($query: String, $localeId: Int!, $filter: TradeNameFilter) {
  tradeNameInstantSearch(query: $query, filter: $filter, localeId: $localeId) {
    id
    tradeName
    country {
      id
      codeISO
      localizedName(localeId: $localeId)
    }
    email
    phone
    website
    postCountry {
      id
    }
    postAddress
    postCode
    latitude
    longitude
    logo {
      imageUrl
      thumbnailUrl: imageUrl(transformations: {width: 80, height: 80, resize: fit})
      thumbnailRetinaUrl: imageUrl(
        transformations: {width: 160, height: 160, resize: fit}
      )
    }
    writePolicy {
      postCountry
      postCode
      postAddress
      latitude
      longitude
      phone
      website
      email
      logo
    }
  }
}
    `;

/**
 * __useTradeNameSearchForCompanyCreateQuery__
 *
 * To run a query within a React component, call `useTradeNameSearchForCompanyCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeNameSearchForCompanyCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeNameSearchForCompanyCreateQuery({
 *   variables: {
 *      query: // value for 'query'
 *      localeId: // value for 'localeId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTradeNameSearchForCompanyCreateQuery(baseOptions: Apollo.QueryHookOptions<Types.TradeNameSearchForCompanyCreateQuery, Types.TradeNameSearchForCompanyCreateQueryVariables> & ({ variables: Types.TradeNameSearchForCompanyCreateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradeNameSearchForCompanyCreateQuery, Types.TradeNameSearchForCompanyCreateQueryVariables>(TradeNameSearchForCompanyCreateDocument, options);
      }
export function useTradeNameSearchForCompanyCreateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradeNameSearchForCompanyCreateQuery, Types.TradeNameSearchForCompanyCreateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradeNameSearchForCompanyCreateQuery, Types.TradeNameSearchForCompanyCreateQueryVariables>(TradeNameSearchForCompanyCreateDocument, options);
        }
export function useTradeNameSearchForCompanyCreateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradeNameSearchForCompanyCreateQuery, Types.TradeNameSearchForCompanyCreateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradeNameSearchForCompanyCreateQuery, Types.TradeNameSearchForCompanyCreateQueryVariables>(TradeNameSearchForCompanyCreateDocument, options);
        }
export type TradeNameSearchForCompanyCreateQueryHookResult = ReturnType<typeof useTradeNameSearchForCompanyCreateQuery>;
export type TradeNameSearchForCompanyCreateLazyQueryHookResult = ReturnType<typeof useTradeNameSearchForCompanyCreateLazyQuery>;
export type TradeNameSearchForCompanyCreateSuspenseQueryHookResult = ReturnType<typeof useTradeNameSearchForCompanyCreateSuspenseQuery>;
export type TradeNameSearchForCompanyCreateQueryResult = Apollo.QueryResult<Types.TradeNameSearchForCompanyCreateQuery, Types.TradeNameSearchForCompanyCreateQueryVariables>;
export const CompanyForEditDocument = gql`
    query companyForEdit($id: ID!, $localeId: Int!) {
  company(id: $id) {
    id
    avatar {
      imageUrl
      thumbnailUrl: imageUrl(transformations: {width: 80, height: 80, resize: fit})
      thumbnailRetinaUrl: imageUrl(
        transformations: {width: 160, height: 160, resize: fit}
      )
    }
    tradeName
    producerTradeName {
      id
      writePolicy {
        logo
        postCountry
        postCode
        postAddress
        latitude
        longitude
        phone
        website
        email
        legalEntity
      }
    }
    companyCategory {
      id
      localizedName(localeId: $localeId)
      masterName
    }
    representativeUser {
      id
    }
    country {
      id
      masterName
      codeISO
      localizedName(localeId: $localeId)
      legalEntityDiscriminator
      vatName
      vatFormat
      vatMask
      localizedVATTooltip(localeId: $localeId)
      localizedVATFormatError(localeId: $localeId)
      identityNumberName
      identityNumberFormat
      tinMask
      localizedIdentityNumberTooltip(localeId: $localeId)
      localizedIdentityNumberFormatError(localeId: $localeId)
      localizedPostCodeTooltip(localeId: $localeId)
      localizedPostCodeFormatError(localeId: $localeId)
      postCodeName
      postCodeMask
      postCodeFormat
      branchNumberName
      branchNumberFormat
      localizedBranchNumberFormatError(localeId: $localeId)
      localizedBranchNumberTooltip(localeId: $localeId)
    }
    legalEntity {
      id
      legalEntityForm {
        id
        originName
        shortName
      }
      identityNumber
      branchNumber
      legalName
      isActive
      closingDate
      legalAddress
      activityCode
      vat
      canEdit
    }
    website
    postCode
    postAddress
    postCountry {
      id
      postCodeName
      postCodeMask
      postCodeFormat
    }
    email
    phone
    latitude
    longitude
  }
}
    `;

/**
 * __useCompanyForEditQuery__
 *
 * To run a query within a React component, call `useCompanyForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyForEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useCompanyForEditQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyForEditQuery, Types.CompanyForEditQueryVariables> & ({ variables: Types.CompanyForEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyForEditQuery, Types.CompanyForEditQueryVariables>(CompanyForEditDocument, options);
      }
export function useCompanyForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyForEditQuery, Types.CompanyForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyForEditQuery, Types.CompanyForEditQueryVariables>(CompanyForEditDocument, options);
        }
export function useCompanyForEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CompanyForEditQuery, Types.CompanyForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompanyForEditQuery, Types.CompanyForEditQueryVariables>(CompanyForEditDocument, options);
        }
export type CompanyForEditQueryHookResult = ReturnType<typeof useCompanyForEditQuery>;
export type CompanyForEditLazyQueryHookResult = ReturnType<typeof useCompanyForEditLazyQuery>;
export type CompanyForEditSuspenseQueryHookResult = ReturnType<typeof useCompanyForEditSuspenseQuery>;
export type CompanyForEditQueryResult = Apollo.QueryResult<Types.CompanyForEditQuery, Types.CompanyForEditQueryVariables>;
export const EditRepresentativeCompanyDocument = gql`
    mutation EditRepresentativeCompany($input: CompanyUpdateInput!) {
  companyUpdate(input: $input) {
    company {
      id
    }
  }
}
    `;
export type EditRepresentativeCompanyMutationFn = Apollo.MutationFunction<Types.EditRepresentativeCompanyMutation, Types.EditRepresentativeCompanyMutationVariables>;

/**
 * __useEditRepresentativeCompanyMutation__
 *
 * To run a mutation, you first call `useEditRepresentativeCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRepresentativeCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRepresentativeCompanyMutation, { data, loading, error }] = useEditRepresentativeCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRepresentativeCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditRepresentativeCompanyMutation, Types.EditRepresentativeCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditRepresentativeCompanyMutation, Types.EditRepresentativeCompanyMutationVariables>(EditRepresentativeCompanyDocument, options);
      }
export type EditRepresentativeCompanyMutationHookResult = ReturnType<typeof useEditRepresentativeCompanyMutation>;
export type EditRepresentativeCompanyMutationResult = Apollo.MutationResult<Types.EditRepresentativeCompanyMutation>;
export type EditRepresentativeCompanyMutationOptions = Apollo.BaseMutationOptions<Types.EditRepresentativeCompanyMutation, Types.EditRepresentativeCompanyMutationVariables>;
export const CompanyWineReviewsDocument = gql`
    query companyWineReviews($companyId: ID!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: WineReviewSort! = CREATED_AT_DESC) {
  me {
    company(id: $companyId) {
      wineReviewsPagination(
        localeId: $localeId
        page: $page
        perPage: $perPage
        sort: $sort
      ) {
        pageInfo {
          totalItems
        }
        items {
          id
          review
          createdAt
          date
          authorUser {
            name
            lastName
            username
            expertQualifications {
              qualification {
                localizedName(localeId: $localeId)
              }
              verification {
                status {
                  localizedName(localeId: $localeId)
                  code
                }
              }
            }
          }
          status
          score
          scoreSystem {
            localizedName(localeId: $localeId)
            minScore
            maxScore
          }
          product {
            id
            slug
            wine {
              tradeNameBrand
              id
              chemicalAnalysis {
                alcoholVolume
              }
              country {
                id
                localizedName(localeId: $localeId)
                codeISO
              }
              brand {
                name
                acceptability
                popularity
              }
              acceptability
              keyWords
              tradeName {
                acceptability
                tradeName
                country {
                  id
                  localizedName(localeId: $localeId)
                  codeISO
                }
              }
              wineType {
                isDefault
                masterName
                localizedName(localeId: $localeId)
              }
              wineColor {
                localizedName(localeId: $localeId)
              }
              sweetnessLevel {
                masterName
                isDefault
                localizedName(localeId: $localeId)
              }
              specialClassifications {
                name
              }
              vintage
              nationalGIType {
                shortName
                localizedName(localeId: $localeId)
              }
              geoIndications {
                localizedName(localeId: $localeId)
                wineRegionsEU {
                  localizedName(localeId: $localeId)
                }
              }
              rating
            }
            bottleSize {
              id
              volumeML
            }
            packageType {
              id
              isDefault
              shortName
              localizedName(localeId: $localeId)
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCompanyWineReviewsQuery__
 *
 * To run a query within a React component, call `useCompanyWineReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyWineReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyWineReviewsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCompanyWineReviewsQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyWineReviewsQuery, Types.CompanyWineReviewsQueryVariables> & ({ variables: Types.CompanyWineReviewsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyWineReviewsQuery, Types.CompanyWineReviewsQueryVariables>(CompanyWineReviewsDocument, options);
      }
export function useCompanyWineReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyWineReviewsQuery, Types.CompanyWineReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyWineReviewsQuery, Types.CompanyWineReviewsQueryVariables>(CompanyWineReviewsDocument, options);
        }
export function useCompanyWineReviewsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CompanyWineReviewsQuery, Types.CompanyWineReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompanyWineReviewsQuery, Types.CompanyWineReviewsQueryVariables>(CompanyWineReviewsDocument, options);
        }
export type CompanyWineReviewsQueryHookResult = ReturnType<typeof useCompanyWineReviewsQuery>;
export type CompanyWineReviewsLazyQueryHookResult = ReturnType<typeof useCompanyWineReviewsLazyQuery>;
export type CompanyWineReviewsSuspenseQueryHookResult = ReturnType<typeof useCompanyWineReviewsSuspenseQuery>;
export type CompanyWineReviewsQueryResult = Apollo.QueryResult<Types.CompanyWineReviewsQuery, Types.CompanyWineReviewsQueryVariables>;
export const WineReviewsSetPrivateDocument = gql`
    mutation wineReviewsSetPrivate($input: WineReviewsSetPrivateInput!) {
  wineReviewsSetPrivate(input: $input) {
    records {
      id
      isPrivate
    }
  }
}
    `;
export type WineReviewsSetPrivateMutationFn = Apollo.MutationFunction<Types.WineReviewsSetPrivateMutation, Types.WineReviewsSetPrivateMutationVariables>;

/**
 * __useWineReviewsSetPrivateMutation__
 *
 * To run a mutation, you first call `useWineReviewsSetPrivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineReviewsSetPrivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineReviewsSetPrivateMutation, { data, loading, error }] = useWineReviewsSetPrivateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineReviewsSetPrivateMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineReviewsSetPrivateMutation, Types.WineReviewsSetPrivateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineReviewsSetPrivateMutation, Types.WineReviewsSetPrivateMutationVariables>(WineReviewsSetPrivateDocument, options);
      }
export type WineReviewsSetPrivateMutationHookResult = ReturnType<typeof useWineReviewsSetPrivateMutation>;
export type WineReviewsSetPrivateMutationResult = Apollo.MutationResult<Types.WineReviewsSetPrivateMutation>;
export type WineReviewsSetPrivateMutationOptions = Apollo.BaseMutationOptions<Types.WineReviewsSetPrivateMutation, Types.WineReviewsSetPrivateMutationVariables>;
export const WineReviewsSetPublicDocument = gql`
    mutation wineReviewsSetPublic($input: WineReviewsSetPublicInput!) {
  wineReviewsSetPublic(input: $input) {
    records {
      id
      isPrivate
    }
  }
}
    `;
export type WineReviewsSetPublicMutationFn = Apollo.MutationFunction<Types.WineReviewsSetPublicMutation, Types.WineReviewsSetPublicMutationVariables>;

/**
 * __useWineReviewsSetPublicMutation__
 *
 * To run a mutation, you first call `useWineReviewsSetPublicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineReviewsSetPublicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineReviewsSetPublicMutation, { data, loading, error }] = useWineReviewsSetPublicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineReviewsSetPublicMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineReviewsSetPublicMutation, Types.WineReviewsSetPublicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineReviewsSetPublicMutation, Types.WineReviewsSetPublicMutationVariables>(WineReviewsSetPublicDocument, options);
      }
export type WineReviewsSetPublicMutationHookResult = ReturnType<typeof useWineReviewsSetPublicMutation>;
export type WineReviewsSetPublicMutationResult = Apollo.MutationResult<Types.WineReviewsSetPublicMutation>;
export type WineReviewsSetPublicMutationOptions = Apollo.BaseMutationOptions<Types.WineReviewsSetPublicMutation, Types.WineReviewsSetPublicMutationVariables>;
export const BrandChangelogDocument = gql`
    query brandChangelog($id: ID!, $localeId: Int!) {
  brand(id: $id) {
    changelog {
      ...FullAcceptabilityDiff
      ...FullBrandDiff
    }
  }
}
    ${FullAcceptabilityDiffFragmentDoc}
${FullBrandDiffFragmentDoc}`;

/**
 * __useBrandChangelogQuery__
 *
 * To run a query within a React component, call `useBrandChangelogQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandChangelogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandChangelogQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useBrandChangelogQuery(baseOptions: Apollo.QueryHookOptions<Types.BrandChangelogQuery, Types.BrandChangelogQueryVariables> & ({ variables: Types.BrandChangelogQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BrandChangelogQuery, Types.BrandChangelogQueryVariables>(BrandChangelogDocument, options);
      }
export function useBrandChangelogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BrandChangelogQuery, Types.BrandChangelogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BrandChangelogQuery, Types.BrandChangelogQueryVariables>(BrandChangelogDocument, options);
        }
export function useBrandChangelogSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BrandChangelogQuery, Types.BrandChangelogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BrandChangelogQuery, Types.BrandChangelogQueryVariables>(BrandChangelogDocument, options);
        }
export type BrandChangelogQueryHookResult = ReturnType<typeof useBrandChangelogQuery>;
export type BrandChangelogLazyQueryHookResult = ReturnType<typeof useBrandChangelogLazyQuery>;
export type BrandChangelogSuspenseQueryHookResult = ReturnType<typeof useBrandChangelogSuspenseQuery>;
export type BrandChangelogQueryResult = Apollo.QueryResult<Types.BrandChangelogQuery, Types.BrandChangelogQueryVariables>;
export const BrandFactDeleteDocument = gql`
    mutation brandFactDelete($input: BrandFactDeleteInput!) {
  brandFactDelete(input: $input) {
    query {
      _
    }
    record {
      id
      name
    }
  }
}
    `;
export type BrandFactDeleteMutationFn = Apollo.MutationFunction<Types.BrandFactDeleteMutation, Types.BrandFactDeleteMutationVariables>;

/**
 * __useBrandFactDeleteMutation__
 *
 * To run a mutation, you first call `useBrandFactDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrandFactDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brandFactDeleteMutation, { data, loading, error }] = useBrandFactDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBrandFactDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.BrandFactDeleteMutation, Types.BrandFactDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BrandFactDeleteMutation, Types.BrandFactDeleteMutationVariables>(BrandFactDeleteDocument, options);
      }
export type BrandFactDeleteMutationHookResult = ReturnType<typeof useBrandFactDeleteMutation>;
export type BrandFactDeleteMutationResult = Apollo.MutationResult<Types.BrandFactDeleteMutation>;
export type BrandFactDeleteMutationOptions = Apollo.BaseMutationOptions<Types.BrandFactDeleteMutation, Types.BrandFactDeleteMutationVariables>;
export const BrandsDatabasePaginationDocument = gql`
    query brandsDatabasePagination($localeId: Int!, $query: String, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: BrandSort!, $filter: BrandFilter) {
  brandsDatabasePagination(
    filter: $filter
    query: $query
    page: $page
    perPage: $perPage
    sort: $sort
  ) {
    pageInfo {
      totalItems
      totalPages
      page
      perPage
      hasNextPage
      hasPreviousPage
    }
    items {
      id
      acceptability
      shouldOverrideAcceptability
      canOverrideFacts
      verified
      translations {
        locale {
          id
          locale
          name
        }
        name
      }
      source {
        id
        description
        name
        sourceType {
          code
        }
      }
      canOverrideAcceptability
      authorUser {
        username
      }
      sourceAuthorUser {
        username
      }
      updatedAt
      name
      tradeName {
        id
        tradeName
        acceptability
        mainSynonym {
          id
          tradeName
        }
      }
      tradeMarks {
        id
        name
        holderName
        status {
          localizedName(localeId: $localeId)
        }
        country {
          id
          codeISO
          localizedName(localeId: $localeId)
        }
      }
    }
  }
}
    `;

/**
 * __useBrandsDatabasePaginationQuery__
 *
 * To run a query within a React component, call `useBrandsDatabasePaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsDatabasePaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsDatabasePaginationQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBrandsDatabasePaginationQuery(baseOptions: Apollo.QueryHookOptions<Types.BrandsDatabasePaginationQuery, Types.BrandsDatabasePaginationQueryVariables> & ({ variables: Types.BrandsDatabasePaginationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BrandsDatabasePaginationQuery, Types.BrandsDatabasePaginationQueryVariables>(BrandsDatabasePaginationDocument, options);
      }
export function useBrandsDatabasePaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BrandsDatabasePaginationQuery, Types.BrandsDatabasePaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BrandsDatabasePaginationQuery, Types.BrandsDatabasePaginationQueryVariables>(BrandsDatabasePaginationDocument, options);
        }
export function useBrandsDatabasePaginationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BrandsDatabasePaginationQuery, Types.BrandsDatabasePaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BrandsDatabasePaginationQuery, Types.BrandsDatabasePaginationQueryVariables>(BrandsDatabasePaginationDocument, options);
        }
export type BrandsDatabasePaginationQueryHookResult = ReturnType<typeof useBrandsDatabasePaginationQuery>;
export type BrandsDatabasePaginationLazyQueryHookResult = ReturnType<typeof useBrandsDatabasePaginationLazyQuery>;
export type BrandsDatabasePaginationSuspenseQueryHookResult = ReturnType<typeof useBrandsDatabasePaginationSuspenseQuery>;
export type BrandsDatabasePaginationQueryResult = Apollo.QueryResult<Types.BrandsDatabasePaginationQuery, Types.BrandsDatabasePaginationQueryVariables>;
export const BrandsFilterDocument = gql`
    query brandsFilter($filterKey: String!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $includeUnusedLabels: Boolean!) {
  brandsFilters(
    localeId: $localeId
    filter: {facetsInputs: $facetsInputs}
    query: $query
  ) {
    filterByKey(key: $filterKey) {
      ...facetFreeListFilter
      ...facetListFilter
    }
  }
}
    ${FacetFreeListFilterFragmentDoc}
${FacetListFilterFragmentDoc}`;

/**
 * __useBrandsFilterQuery__
 *
 * To run a query within a React component, call `useBrandsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsFilterQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useBrandsFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.BrandsFilterQuery, Types.BrandsFilterQueryVariables> & ({ variables: Types.BrandsFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BrandsFilterQuery, Types.BrandsFilterQueryVariables>(BrandsFilterDocument, options);
      }
export function useBrandsFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BrandsFilterQuery, Types.BrandsFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BrandsFilterQuery, Types.BrandsFilterQueryVariables>(BrandsFilterDocument, options);
        }
export function useBrandsFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BrandsFilterQuery, Types.BrandsFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BrandsFilterQuery, Types.BrandsFilterQueryVariables>(BrandsFilterDocument, options);
        }
export type BrandsFilterQueryHookResult = ReturnType<typeof useBrandsFilterQuery>;
export type BrandsFilterLazyQueryHookResult = ReturnType<typeof useBrandsFilterLazyQuery>;
export type BrandsFilterSuspenseQueryHookResult = ReturnType<typeof useBrandsFilterSuspenseQuery>;
export type BrandsFilterQueryResult = Apollo.QueryResult<Types.BrandsFilterQuery, Types.BrandsFilterQueryVariables>;
export const BrandsFiltersDocument = gql`
    query brandsFilters($localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt!, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $includeUnusedLabels: Boolean!) {
  brandsFilters(
    localeId: $localeId
    filter: {facetsInputs: $facetsInputs}
    query: $query
  ) {
    count
    countryFilter: filterByKey(key: "tradeNameCountry") {
      ...facetListFilter
    }
    usersFilter: filterByKey(key: "contributorUsername") {
      ...facetFreeListFilter
    }
    sourceFilter: filterByKey(key: "source") {
      ...facetListFilter
    }
    creationDateFilter: filterByKey(key: "createdAt") {
      ...facetDateRangeFilter
    }
    lastUpdateFilter: filterByKey(key: "updatedAt") {
      ...facetDateRangeFilter
    }
    checkedByFilter: filterByKey(key: "checkedBy") {
      ...facetListFilter
    }
    invalidRecordFilter: filterByKey(key: "invalidRecord") {
      ...facetListFilter
    }
    registrationFilter: filterByKey(key: "hasRegistration") {
      ...facetListFilter
    }
    producerFilter: filterByKey(key: "tradeName") {
      ...facetListFilter
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetFreeListFilterFragmentDoc}
${FacetDateRangeFilterFragmentDoc}`;

/**
 * __useBrandsFiltersQuery__
 *
 * To run a query within a React component, call `useBrandsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsFiltersQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useBrandsFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.BrandsFiltersQuery, Types.BrandsFiltersQueryVariables> & ({ variables: Types.BrandsFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BrandsFiltersQuery, Types.BrandsFiltersQueryVariables>(BrandsFiltersDocument, options);
      }
export function useBrandsFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BrandsFiltersQuery, Types.BrandsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BrandsFiltersQuery, Types.BrandsFiltersQueryVariables>(BrandsFiltersDocument, options);
        }
export function useBrandsFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BrandsFiltersQuery, Types.BrandsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BrandsFiltersQuery, Types.BrandsFiltersQueryVariables>(BrandsFiltersDocument, options);
        }
export type BrandsFiltersQueryHookResult = ReturnType<typeof useBrandsFiltersQuery>;
export type BrandsFiltersLazyQueryHookResult = ReturnType<typeof useBrandsFiltersLazyQuery>;
export type BrandsFiltersSuspenseQueryHookResult = ReturnType<typeof useBrandsFiltersSuspenseQuery>;
export type BrandsFiltersQueryResult = Apollo.QueryResult<Types.BrandsFiltersQuery, Types.BrandsFiltersQueryVariables>;
export const ExhibitionsFiltersDocument = gql`
    query exhibitionsFilters($localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt!, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $includeUnusedLabels: Boolean!) {
  exhibitionsFilters(
    localeId: $localeId
    filter: {facetsInputs: $facetsInputs}
    query: $query
  ) {
    count
    dateStartFilter: filterByKey(key: "dateStart") {
      ...facetDateRangeFilter
    }
    dateEndFilter: filterByKey(key: "dateEnd") {
      ...facetDateRangeFilter
    }
    countryFilter: filterByKey(key: "country") {
      ...facetListFilter
    }
    participantsFilter: filterByKey(key: "participants") {
      ...facetRangeFilter
    }
    exhibitionTypeFilter: filterByKey(key: "exhibitionType") {
      ...facetListFilter
    }
  }
}
    ${FacetDateRangeFilterFragmentDoc}
${FacetListFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}`;

/**
 * __useExhibitionsFiltersQuery__
 *
 * To run a query within a React component, call `useExhibitionsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useExhibitionsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExhibitionsFiltersQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useExhibitionsFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.ExhibitionsFiltersQuery, Types.ExhibitionsFiltersQueryVariables> & ({ variables: Types.ExhibitionsFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExhibitionsFiltersQuery, Types.ExhibitionsFiltersQueryVariables>(ExhibitionsFiltersDocument, options);
      }
export function useExhibitionsFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExhibitionsFiltersQuery, Types.ExhibitionsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExhibitionsFiltersQuery, Types.ExhibitionsFiltersQueryVariables>(ExhibitionsFiltersDocument, options);
        }
export function useExhibitionsFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ExhibitionsFiltersQuery, Types.ExhibitionsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ExhibitionsFiltersQuery, Types.ExhibitionsFiltersQueryVariables>(ExhibitionsFiltersDocument, options);
        }
export type ExhibitionsFiltersQueryHookResult = ReturnType<typeof useExhibitionsFiltersQuery>;
export type ExhibitionsFiltersLazyQueryHookResult = ReturnType<typeof useExhibitionsFiltersLazyQuery>;
export type ExhibitionsFiltersSuspenseQueryHookResult = ReturnType<typeof useExhibitionsFiltersSuspenseQuery>;
export type ExhibitionsFiltersQueryResult = Apollo.QueryResult<Types.ExhibitionsFiltersQuery, Types.ExhibitionsFiltersQueryVariables>;
export const ExhibitionsFiltersForKeyDocument = gql`
    query exhibitionsFiltersForKey($filterKey: String!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $includeUnusedLabels: Boolean!) {
  exhibitionsFilters(
    localeId: $localeId
    filter: {facetsInputs: $facetsInputs}
    query: $query
  ) {
    filterByKey(key: $filterKey) {
      ...facetFreeListFilter
      ...facetListFilter
    }
  }
}
    ${FacetFreeListFilterFragmentDoc}
${FacetListFilterFragmentDoc}`;

/**
 * __useExhibitionsFiltersForKeyQuery__
 *
 * To run a query within a React component, call `useExhibitionsFiltersForKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useExhibitionsFiltersForKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExhibitionsFiltersForKeyQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useExhibitionsFiltersForKeyQuery(baseOptions: Apollo.QueryHookOptions<Types.ExhibitionsFiltersForKeyQuery, Types.ExhibitionsFiltersForKeyQueryVariables> & ({ variables: Types.ExhibitionsFiltersForKeyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExhibitionsFiltersForKeyQuery, Types.ExhibitionsFiltersForKeyQueryVariables>(ExhibitionsFiltersForKeyDocument, options);
      }
export function useExhibitionsFiltersForKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExhibitionsFiltersForKeyQuery, Types.ExhibitionsFiltersForKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExhibitionsFiltersForKeyQuery, Types.ExhibitionsFiltersForKeyQueryVariables>(ExhibitionsFiltersForKeyDocument, options);
        }
export function useExhibitionsFiltersForKeySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ExhibitionsFiltersForKeyQuery, Types.ExhibitionsFiltersForKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ExhibitionsFiltersForKeyQuery, Types.ExhibitionsFiltersForKeyQueryVariables>(ExhibitionsFiltersForKeyDocument, options);
        }
export type ExhibitionsFiltersForKeyQueryHookResult = ReturnType<typeof useExhibitionsFiltersForKeyQuery>;
export type ExhibitionsFiltersForKeyLazyQueryHookResult = ReturnType<typeof useExhibitionsFiltersForKeyLazyQuery>;
export type ExhibitionsFiltersForKeySuspenseQueryHookResult = ReturnType<typeof useExhibitionsFiltersForKeySuspenseQuery>;
export type ExhibitionsFiltersForKeyQueryResult = Apollo.QueryResult<Types.ExhibitionsFiltersForKeyQuery, Types.ExhibitionsFiltersForKeyQueryVariables>;
export const GeoIndicationsDatabasePaginationDocument = gql`
    query geoIndicationsDatabasePagination($localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: GeoIndicationSort, $query: String, $filter: GeoIndicationFilter) {
  geoIndicationsDatabasePagination(
    filter: $filter
    localeId: $localeId
    page: $page
    perPage: $perPage
    sort: $sort
    query: $query
  ) {
    pageInfo {
      totalItems
      totalPages
      page
      perPage
      hasNextPage
      hasPreviousPage
    }
    items {
      id
      masterName
      isReferenced
      code
      localizedName(localeId: $localeId)
      translations {
        name
        locale {
          locale
          name
          id
          localizedName(localeId: $localeId)
        }
      }
      wineTerritoryType {
        masterName
        country {
          localizedName(localeId: $localeId)
        }
      }
      parent {
        id
        masterName
      }
      children {
        localizedName(localeId: $localeId)
      }
      nationalGITypes {
        shortName
        localizedShortName(localeId: $localeId)
        euGIType {
          localizedShortName(localeId: $localeId)
        }
      }
      wineRegionsEU {
        masterName
        localizedName(localeId: $localeId)
      }
    }
  }
}
    `;

/**
 * __useGeoIndicationsDatabasePaginationQuery__
 *
 * To run a query within a React component, call `useGeoIndicationsDatabasePaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeoIndicationsDatabasePaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeoIndicationsDatabasePaginationQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGeoIndicationsDatabasePaginationQuery(baseOptions: Apollo.QueryHookOptions<Types.GeoIndicationsDatabasePaginationQuery, Types.GeoIndicationsDatabasePaginationQueryVariables> & ({ variables: Types.GeoIndicationsDatabasePaginationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GeoIndicationsDatabasePaginationQuery, Types.GeoIndicationsDatabasePaginationQueryVariables>(GeoIndicationsDatabasePaginationDocument, options);
      }
export function useGeoIndicationsDatabasePaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GeoIndicationsDatabasePaginationQuery, Types.GeoIndicationsDatabasePaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GeoIndicationsDatabasePaginationQuery, Types.GeoIndicationsDatabasePaginationQueryVariables>(GeoIndicationsDatabasePaginationDocument, options);
        }
export function useGeoIndicationsDatabasePaginationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GeoIndicationsDatabasePaginationQuery, Types.GeoIndicationsDatabasePaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GeoIndicationsDatabasePaginationQuery, Types.GeoIndicationsDatabasePaginationQueryVariables>(GeoIndicationsDatabasePaginationDocument, options);
        }
export type GeoIndicationsDatabasePaginationQueryHookResult = ReturnType<typeof useGeoIndicationsDatabasePaginationQuery>;
export type GeoIndicationsDatabasePaginationLazyQueryHookResult = ReturnType<typeof useGeoIndicationsDatabasePaginationLazyQuery>;
export type GeoIndicationsDatabasePaginationSuspenseQueryHookResult = ReturnType<typeof useGeoIndicationsDatabasePaginationSuspenseQuery>;
export type GeoIndicationsDatabasePaginationQueryResult = Apollo.QueryResult<Types.GeoIndicationsDatabasePaginationQuery, Types.GeoIndicationsDatabasePaginationQueryVariables>;
export const GeoIndicationsDeleteDocument = gql`
    mutation geoIndicationsDelete($input: GeoIndicationsDeleteInput!) {
  geoIndicationsDelete(input: $input) {
    recordIds
  }
}
    `;
export type GeoIndicationsDeleteMutationFn = Apollo.MutationFunction<Types.GeoIndicationsDeleteMutation, Types.GeoIndicationsDeleteMutationVariables>;

/**
 * __useGeoIndicationsDeleteMutation__
 *
 * To run a mutation, you first call `useGeoIndicationsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeoIndicationsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [geoIndicationsDeleteMutation, { data, loading, error }] = useGeoIndicationsDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGeoIndicationsDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.GeoIndicationsDeleteMutation, Types.GeoIndicationsDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GeoIndicationsDeleteMutation, Types.GeoIndicationsDeleteMutationVariables>(GeoIndicationsDeleteDocument, options);
      }
export type GeoIndicationsDeleteMutationHookResult = ReturnType<typeof useGeoIndicationsDeleteMutation>;
export type GeoIndicationsDeleteMutationResult = Apollo.MutationResult<Types.GeoIndicationsDeleteMutation>;
export type GeoIndicationsDeleteMutationOptions = Apollo.BaseMutationOptions<Types.GeoIndicationsDeleteMutation, Types.GeoIndicationsDeleteMutationVariables>;
export const GeoIndicationsFilterDocument = gql`
    query geoIndicationsFilter($filterKey: String!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $includeUnusedLabels: Boolean!) {
  geoIndicationsFilters(
    localeId: $localeId
    filter: {facetsInputs: $facetsInputs}
    query: $query
  ) {
    filterByKey(key: $filterKey) {
      ...facetListFilter
    }
  }
}
    ${FacetListFilterFragmentDoc}`;

/**
 * __useGeoIndicationsFilterQuery__
 *
 * To run a query within a React component, call `useGeoIndicationsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeoIndicationsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeoIndicationsFilterQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useGeoIndicationsFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.GeoIndicationsFilterQuery, Types.GeoIndicationsFilterQueryVariables> & ({ variables: Types.GeoIndicationsFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GeoIndicationsFilterQuery, Types.GeoIndicationsFilterQueryVariables>(GeoIndicationsFilterDocument, options);
      }
export function useGeoIndicationsFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GeoIndicationsFilterQuery, Types.GeoIndicationsFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GeoIndicationsFilterQuery, Types.GeoIndicationsFilterQueryVariables>(GeoIndicationsFilterDocument, options);
        }
export function useGeoIndicationsFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GeoIndicationsFilterQuery, Types.GeoIndicationsFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GeoIndicationsFilterQuery, Types.GeoIndicationsFilterQueryVariables>(GeoIndicationsFilterDocument, options);
        }
export type GeoIndicationsFilterQueryHookResult = ReturnType<typeof useGeoIndicationsFilterQuery>;
export type GeoIndicationsFilterLazyQueryHookResult = ReturnType<typeof useGeoIndicationsFilterLazyQuery>;
export type GeoIndicationsFilterSuspenseQueryHookResult = ReturnType<typeof useGeoIndicationsFilterSuspenseQuery>;
export type GeoIndicationsFilterQueryResult = Apollo.QueryResult<Types.GeoIndicationsFilterQuery, Types.GeoIndicationsFilterQueryVariables>;
export const GeoIndicationsFiltersDocument = gql`
    query geoIndicationsFilters($page: PositiveInt!, $localeId: Int!, $facetsInputs: [FacetInput!], $query: String, $perPage: PositiveInt!, $filterSearch: String, $includeUnusedLabels: Boolean!) {
  geoIndicationsFilters(
    localeId: $localeId
    filter: {facetsInputs: $facetsInputs}
    query: $query
  ) {
    count
    countryFilter: filterByKey(key: "country") {
      ...facetListFilter
    }
    euGITypeFilter: filterByKey(key: "euGIType") {
      ...facetListFilter
    }
    nationalGITypeFilter: filterByKey(key: "nationalGIType") {
      ...facetListFilter
    }
    wineRegionEUFilter: filterByKey(key: "wineRegionEU") {
      ...facetListFilter
    }
    parentGeoIndicationFilter: filterByKey(key: "parent") {
      ...facetListFilter
    }
    wineTerritoryTypeFilter: filterByKey(key: "wineTerritoryType") {
      ...facetListFilter
    }
  }
}
    ${FacetListFilterFragmentDoc}`;

/**
 * __useGeoIndicationsFiltersQuery__
 *
 * To run a query within a React component, call `useGeoIndicationsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeoIndicationsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeoIndicationsFiltersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      localeId: // value for 'localeId'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useGeoIndicationsFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.GeoIndicationsFiltersQuery, Types.GeoIndicationsFiltersQueryVariables> & ({ variables: Types.GeoIndicationsFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GeoIndicationsFiltersQuery, Types.GeoIndicationsFiltersQueryVariables>(GeoIndicationsFiltersDocument, options);
      }
export function useGeoIndicationsFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GeoIndicationsFiltersQuery, Types.GeoIndicationsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GeoIndicationsFiltersQuery, Types.GeoIndicationsFiltersQueryVariables>(GeoIndicationsFiltersDocument, options);
        }
export function useGeoIndicationsFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GeoIndicationsFiltersQuery, Types.GeoIndicationsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GeoIndicationsFiltersQuery, Types.GeoIndicationsFiltersQueryVariables>(GeoIndicationsFiltersDocument, options);
        }
export type GeoIndicationsFiltersQueryHookResult = ReturnType<typeof useGeoIndicationsFiltersQuery>;
export type GeoIndicationsFiltersLazyQueryHookResult = ReturnType<typeof useGeoIndicationsFiltersLazyQuery>;
export type GeoIndicationsFiltersSuspenseQueryHookResult = ReturnType<typeof useGeoIndicationsFiltersSuspenseQuery>;
export type GeoIndicationsFiltersQueryResult = Apollo.QueryResult<Types.GeoIndicationsFiltersQuery, Types.GeoIndicationsFiltersQueryVariables>;
export const BrandWinesDocument = gql`
    query brandWines($id: ID!, $localeId: Int!, $includeSynonyms: Boolean!) {
  brand(id: $id) {
    id
    wines(withoutVintageDuplicates: true, includeSynonyms: $includeSynonyms) {
      id
      acceptability
      verified
      tradeName {
        tradeName
      }
      tradeNameBrand
      wineType {
        isDefault
        localizedName(localeId: $localeId)
      }
      wineColor {
        localizedName(localeId: $localeId)
      }
      sweetnessLevel {
        localizedName(localeId: $localeId)
        isDefault
      }
      specialClassifications {
        id
        name
      }
      keyWords
      nationalGIType {
        id
        localizedName(localeId: $localeId)
        localizedShortName(localeId: $localeId)
        euGIType {
          id
          masterName
        }
      }
      nationalGITypeText
      geoIndications {
        id
        localizedName(localeId: $localeId)
        wineRegionsEU {
          id
          localizedName(localeId: $localeId)
        }
      }
      geoIndicationText
    }
  }
}
    `;

/**
 * __useBrandWinesQuery__
 *
 * To run a query within a React component, call `useBrandWinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandWinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandWinesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *      includeSynonyms: // value for 'includeSynonyms'
 *   },
 * });
 */
export function useBrandWinesQuery(baseOptions: Apollo.QueryHookOptions<Types.BrandWinesQuery, Types.BrandWinesQueryVariables> & ({ variables: Types.BrandWinesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BrandWinesQuery, Types.BrandWinesQueryVariables>(BrandWinesDocument, options);
      }
export function useBrandWinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BrandWinesQuery, Types.BrandWinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BrandWinesQuery, Types.BrandWinesQueryVariables>(BrandWinesDocument, options);
        }
export function useBrandWinesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BrandWinesQuery, Types.BrandWinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BrandWinesQuery, Types.BrandWinesQueryVariables>(BrandWinesDocument, options);
        }
export type BrandWinesQueryHookResult = ReturnType<typeof useBrandWinesQuery>;
export type BrandWinesLazyQueryHookResult = ReturnType<typeof useBrandWinesLazyQuery>;
export type BrandWinesSuspenseQueryHookResult = ReturnType<typeof useBrandWinesSuspenseQuery>;
export type BrandWinesQueryResult = Apollo.QueryResult<Types.BrandWinesQuery, Types.BrandWinesQueryVariables>;
export const TradeNameAcceptDocument = gql`
    mutation tradeNameAccept($input: TradeNameAcceptInput!) {
  tradeNameAccept(input: $input) {
    record {
      id
      acceptability
      canOverrideAcceptability
      shouldOverrideAcceptability
      updatedAt
    }
  }
}
    `;
export type TradeNameAcceptMutationFn = Apollo.MutationFunction<Types.TradeNameAcceptMutation, Types.TradeNameAcceptMutationVariables>;

/**
 * __useTradeNameAcceptMutation__
 *
 * To run a mutation, you first call `useTradeNameAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTradeNameAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tradeNameAcceptMutation, { data, loading, error }] = useTradeNameAcceptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTradeNameAcceptMutation(baseOptions?: Apollo.MutationHookOptions<Types.TradeNameAcceptMutation, Types.TradeNameAcceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.TradeNameAcceptMutation, Types.TradeNameAcceptMutationVariables>(TradeNameAcceptDocument, options);
      }
export type TradeNameAcceptMutationHookResult = ReturnType<typeof useTradeNameAcceptMutation>;
export type TradeNameAcceptMutationResult = Apollo.MutationResult<Types.TradeNameAcceptMutation>;
export type TradeNameAcceptMutationOptions = Apollo.BaseMutationOptions<Types.TradeNameAcceptMutation, Types.TradeNameAcceptMutationVariables>;
export const TradeNameBrandsForProducersDocument = gql`
    query tradeNameBrandsForProducers($id: ID!, $includeSynonyms: Boolean!) {
  tradeName(id: $id) {
    brands(onlyAccepted: false, includeSynonyms: $includeSynonyms) {
      id
      name
      acceptability
      verified
      translations(sort: CREATED_AT_DESC) {
        name
        locale {
          locale
        }
      }
    }
  }
}
    `;

/**
 * __useTradeNameBrandsForProducersQuery__
 *
 * To run a query within a React component, call `useTradeNameBrandsForProducersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeNameBrandsForProducersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeNameBrandsForProducersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeSynonyms: // value for 'includeSynonyms'
 *   },
 * });
 */
export function useTradeNameBrandsForProducersQuery(baseOptions: Apollo.QueryHookOptions<Types.TradeNameBrandsForProducersQuery, Types.TradeNameBrandsForProducersQueryVariables> & ({ variables: Types.TradeNameBrandsForProducersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradeNameBrandsForProducersQuery, Types.TradeNameBrandsForProducersQueryVariables>(TradeNameBrandsForProducersDocument, options);
      }
export function useTradeNameBrandsForProducersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradeNameBrandsForProducersQuery, Types.TradeNameBrandsForProducersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradeNameBrandsForProducersQuery, Types.TradeNameBrandsForProducersQueryVariables>(TradeNameBrandsForProducersDocument, options);
        }
export function useTradeNameBrandsForProducersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradeNameBrandsForProducersQuery, Types.TradeNameBrandsForProducersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradeNameBrandsForProducersQuery, Types.TradeNameBrandsForProducersQueryVariables>(TradeNameBrandsForProducersDocument, options);
        }
export type TradeNameBrandsForProducersQueryHookResult = ReturnType<typeof useTradeNameBrandsForProducersQuery>;
export type TradeNameBrandsForProducersLazyQueryHookResult = ReturnType<typeof useTradeNameBrandsForProducersLazyQuery>;
export type TradeNameBrandsForProducersSuspenseQueryHookResult = ReturnType<typeof useTradeNameBrandsForProducersSuspenseQuery>;
export type TradeNameBrandsForProducersQueryResult = Apollo.QueryResult<Types.TradeNameBrandsForProducersQuery, Types.TradeNameBrandsForProducersQueryVariables>;
export const TradeNameChangelogDocument = gql`
    query tradeNameChangelog($id: ID!, $localeId: Int!) {
  tradeName(id: $id) {
    changelog {
      ...FullAcceptabilityDiff
      ...FullTradeNameDiff
    }
  }
}
    ${FullAcceptabilityDiffFragmentDoc}
${FullTradeNameDiffFragmentDoc}`;

/**
 * __useTradeNameChangelogQuery__
 *
 * To run a query within a React component, call `useTradeNameChangelogQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeNameChangelogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeNameChangelogQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useTradeNameChangelogQuery(baseOptions: Apollo.QueryHookOptions<Types.TradeNameChangelogQuery, Types.TradeNameChangelogQueryVariables> & ({ variables: Types.TradeNameChangelogQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradeNameChangelogQuery, Types.TradeNameChangelogQueryVariables>(TradeNameChangelogDocument, options);
      }
export function useTradeNameChangelogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradeNameChangelogQuery, Types.TradeNameChangelogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradeNameChangelogQuery, Types.TradeNameChangelogQueryVariables>(TradeNameChangelogDocument, options);
        }
export function useTradeNameChangelogSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradeNameChangelogQuery, Types.TradeNameChangelogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradeNameChangelogQuery, Types.TradeNameChangelogQueryVariables>(TradeNameChangelogDocument, options);
        }
export type TradeNameChangelogQueryHookResult = ReturnType<typeof useTradeNameChangelogQuery>;
export type TradeNameChangelogLazyQueryHookResult = ReturnType<typeof useTradeNameChangelogLazyQuery>;
export type TradeNameChangelogSuspenseQueryHookResult = ReturnType<typeof useTradeNameChangelogSuspenseQuery>;
export type TradeNameChangelogQueryResult = Apollo.QueryResult<Types.TradeNameChangelogQuery, Types.TradeNameChangelogQueryVariables>;
export const TradeNameFactDeleteDocument = gql`
    mutation tradeNameFactDelete($input: TradeNameFactDeleteInput!) {
  tradeNameFactDelete(input: $input) {
    query {
      _
    }
    record {
      id
      tradeName
    }
  }
}
    `;
export type TradeNameFactDeleteMutationFn = Apollo.MutationFunction<Types.TradeNameFactDeleteMutation, Types.TradeNameFactDeleteMutationVariables>;

/**
 * __useTradeNameFactDeleteMutation__
 *
 * To run a mutation, you first call `useTradeNameFactDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTradeNameFactDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tradeNameFactDeleteMutation, { data, loading, error }] = useTradeNameFactDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTradeNameFactDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.TradeNameFactDeleteMutation, Types.TradeNameFactDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.TradeNameFactDeleteMutation, Types.TradeNameFactDeleteMutationVariables>(TradeNameFactDeleteDocument, options);
      }
export type TradeNameFactDeleteMutationHookResult = ReturnType<typeof useTradeNameFactDeleteMutation>;
export type TradeNameFactDeleteMutationResult = Apollo.MutationResult<Types.TradeNameFactDeleteMutation>;
export type TradeNameFactDeleteMutationOptions = Apollo.BaseMutationOptions<Types.TradeNameFactDeleteMutation, Types.TradeNameFactDeleteMutationVariables>;
export const TradeNameFilterDocument = gql`
    query tradeNameFilter($filterKey: String!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $includeUnusedLabels: Boolean! = true) {
  tradeNamesFilters(
    localeId: $localeId
    filter: {facetsInputs: $facetsInputs, onlyAccepted: false}
    query: $query
  ) {
    filterByKey(key: $filterKey) {
      ...facetFreeListFilter
      ...facetComplexFilter
      ...facetListFilter
    }
  }
}
    ${FacetFreeListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetListFilterFragmentDoc}`;

/**
 * __useTradeNameFilterQuery__
 *
 * To run a query within a React component, call `useTradeNameFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeNameFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeNameFilterQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useTradeNameFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.TradeNameFilterQuery, Types.TradeNameFilterQueryVariables> & ({ variables: Types.TradeNameFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradeNameFilterQuery, Types.TradeNameFilterQueryVariables>(TradeNameFilterDocument, options);
      }
export function useTradeNameFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradeNameFilterQuery, Types.TradeNameFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradeNameFilterQuery, Types.TradeNameFilterQueryVariables>(TradeNameFilterDocument, options);
        }
export function useTradeNameFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradeNameFilterQuery, Types.TradeNameFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradeNameFilterQuery, Types.TradeNameFilterQueryVariables>(TradeNameFilterDocument, options);
        }
export type TradeNameFilterQueryHookResult = ReturnType<typeof useTradeNameFilterQuery>;
export type TradeNameFilterLazyQueryHookResult = ReturnType<typeof useTradeNameFilterLazyQuery>;
export type TradeNameFilterSuspenseQueryHookResult = ReturnType<typeof useTradeNameFilterSuspenseQuery>;
export type TradeNameFilterQueryResult = Apollo.QueryResult<Types.TradeNameFilterQuery, Types.TradeNameFilterQueryVariables>;
export const TradeNameFiltersDocument = gql`
    query tradeNameFilters($localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt!, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $includeUnusedLabels: Boolean! = true) {
  tradeNamesFilters(
    localeId: $localeId
    filter: {facetsInputs: $facetsInputs, onlyAccepted: false}
    query: $query
  ) {
    count
    countryFilter: filterByKey(key: "country") {
      ...facetListFilter
    }
    usersFilter: filterByKey(key: "contributorUsername") {
      ...facetFreeListFilter
    }
    sourceFilter: filterByKey(key: "source") {
      ...facetListFilter
    }
    creationDateFilter: filterByKey(key: "createdAt") {
      ...facetDateRangeFilter
    }
    lastUpdateFilter: filterByKey(key: "updatedAt") {
      ...facetDateRangeFilter
    }
    checkedByFilter: filterByKey(key: "checkedBy") {
      ...facetListFilter
    }
    invalidRecordFilter: filterByKey(key: "invalidRecord") {
      ...facetListFilter
    }
    legalEntityFilter: filterByKey(key: "hasLegalEntity") {
      ...facetListFilter
    }
    producerWWLFilter: filterByKey(key: "hasVerifiedRepresentative") {
      ...facetListFilter
    }
    euGITypeFilter: filterByKey(key: "euGIType") {
      ...facetListFilter
    }
    nationalGITypeFilter: filterByKey(key: "nationalGIType") {
      ...facetListFilter
    }
    wineRegionEUFilter: filterByKey(key: "wineRegionEU") {
      ...facetListFilter
    }
    geoIndicationFilter: filterByKey(key: "geoIndication") {
      ...facetListFilter
    }
    wineTypeFilter: filterByKey(key: "wineType") {
      ...facetListFilter
    }
    wineColourFilter: filterByKey(key: "wineColor") {
      ...facetListFilter
    }
    sweetnessTypeFilter: filterByKey(key: "sweetnessLevel") {
      ...facetListFilter
    }
    vintageFilter: filterByKey(key: "vintage") {
      ...facetListFilter
    }
    bottleSizeFilter: filterByKey(key: "bottleSize") {
      ...facetListFilter
    }
    grapesFilter: filterByKey(key: "grapeAndMonocepage") {
      ...facetComplexFilter
    }
    certificatesFilter: filterByKey(key: "certification") {
      ...facetListFilter
    }
    alcoholLevelFilter: filterByKey(key: "alcoholOnLabel") {
      ...facetRangeFilter
    }
    cellarDoorPriceFilter: filterByKey(key: "cellarDoorPrice") {
      ...facetComplexFilter
    }
    wwlRatingFilter: filterByKey(key: "wines.rating") {
      ...facetListFilter
    }
    retailPriceFilter: filterByKey(key: "tradeOfferLotPricePerBottle") {
      ...facetComplexFilter
    }
    hasVerifiedRepresentativeFilter: filterByKey(key: "hasVerifiedRepresentative") {
      ...facetListFilter
    }
    hasWebsiteFilter: filterByKey(key: "hasWebsite") {
      ...facetListFilter
    }
    hasEmailFilter: filterByKey(key: "hasEmail") {
      ...facetListFilter
    }
    hasPhoneFilter: filterByKey(key: "hasPhone") {
      ...facetListFilter
    }
    synonymsFilter: filterByKey(key: "includeSynonyms") {
      ...facetBooleanFilter
    }
    specialClassificationFilter: filterByKey(key: "wines.specialClassification") {
      ...facetListFilter
    }
    packageTypeFilter: filterByKey(key: "packageType") {
      ...facetListFilter
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetFreeListFilterFragmentDoc}
${FacetDateRangeFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}
${FacetBooleanFilterFragmentDoc}`;

/**
 * __useTradeNameFiltersQuery__
 *
 * To run a query within a React component, call `useTradeNameFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeNameFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeNameFiltersQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useTradeNameFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.TradeNameFiltersQuery, Types.TradeNameFiltersQueryVariables> & ({ variables: Types.TradeNameFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradeNameFiltersQuery, Types.TradeNameFiltersQueryVariables>(TradeNameFiltersDocument, options);
      }
export function useTradeNameFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradeNameFiltersQuery, Types.TradeNameFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradeNameFiltersQuery, Types.TradeNameFiltersQueryVariables>(TradeNameFiltersDocument, options);
        }
export function useTradeNameFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradeNameFiltersQuery, Types.TradeNameFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradeNameFiltersQuery, Types.TradeNameFiltersQueryVariables>(TradeNameFiltersDocument, options);
        }
export type TradeNameFiltersQueryHookResult = ReturnType<typeof useTradeNameFiltersQuery>;
export type TradeNameFiltersLazyQueryHookResult = ReturnType<typeof useTradeNameFiltersLazyQuery>;
export type TradeNameFiltersSuspenseQueryHookResult = ReturnType<typeof useTradeNameFiltersSuspenseQuery>;
export type TradeNameFiltersQueryResult = Apollo.QueryResult<Types.TradeNameFiltersQuery, Types.TradeNameFiltersQueryVariables>;
export const TradeNamePaginationDocument = gql`
    query tradeNamePagination($localeId: Int!, $query: String, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: TradeNameSort!, $filter: TradeNameFilter!) {
  tradeNameDatabasePagination(
    localeId: $localeId
    filter: $filter
    query: $query
    page: $page
    perPage: $perPage
    sort: $sort
  ) {
    pageInfo {
      totalItems
      totalPages
      page
      perPage
      hasNextPage
      hasPreviousPage
    }
    items {
      id
      acceptability
      shouldOverrideAcceptability
      canOverrideFacts
      verified
      logo {
        imageUrl
      }
      tradeName
      country {
        id
        localizedName(localeId: $localeId)
        identityNumberName
        branchNumberName
        vatName
        legalEntityDiscriminator
        codeISO
      }
      legalEntity {
        id
        legalName
        identityNumber
        branchNumber
        vat
        legalAddress
        activityCode
        isActive
        closingDate
        legalEntityForm {
          id
          shortName
          originName
        }
      }
      website
      phone
      email
      postAddress
      postCode
      translations(sort: CREATED_AT_DESC) {
        locale {
          id
          locale
          name
        }
        tradeName
      }
      authorUser {
        username
      }
      source {
        id
        description
        name
        sourceType {
          code
        }
      }
      updatedAt
      canOverrideAcceptability
      sourceAuthorUser {
        username
      }
      phone
      email
      mainSynonym {
        id
        tradeName
      }
    }
  }
}
    `;

/**
 * __useTradeNamePaginationQuery__
 *
 * To run a query within a React component, call `useTradeNamePaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeNamePaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeNamePaginationQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTradeNamePaginationQuery(baseOptions: Apollo.QueryHookOptions<Types.TradeNamePaginationQuery, Types.TradeNamePaginationQueryVariables> & ({ variables: Types.TradeNamePaginationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradeNamePaginationQuery, Types.TradeNamePaginationQueryVariables>(TradeNamePaginationDocument, options);
      }
export function useTradeNamePaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradeNamePaginationQuery, Types.TradeNamePaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradeNamePaginationQuery, Types.TradeNamePaginationQueryVariables>(TradeNamePaginationDocument, options);
        }
export function useTradeNamePaginationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradeNamePaginationQuery, Types.TradeNamePaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradeNamePaginationQuery, Types.TradeNamePaginationQueryVariables>(TradeNamePaginationDocument, options);
        }
export type TradeNamePaginationQueryHookResult = ReturnType<typeof useTradeNamePaginationQuery>;
export type TradeNamePaginationLazyQueryHookResult = ReturnType<typeof useTradeNamePaginationLazyQuery>;
export type TradeNamePaginationSuspenseQueryHookResult = ReturnType<typeof useTradeNamePaginationSuspenseQuery>;
export type TradeNamePaginationQueryResult = Apollo.QueryResult<Types.TradeNamePaginationQuery, Types.TradeNamePaginationQueryVariables>;
export const TradeNameRefuseDocument = gql`
    mutation tradeNameRefuse($input: TradeNameRefuseInput!) {
  tradeNameRefuse(input: $input) {
    record {
      id
      acceptability
      canOverrideAcceptability
      shouldOverrideAcceptability
      updatedAt
    }
  }
}
    `;
export type TradeNameRefuseMutationFn = Apollo.MutationFunction<Types.TradeNameRefuseMutation, Types.TradeNameRefuseMutationVariables>;

/**
 * __useTradeNameRefuseMutation__
 *
 * To run a mutation, you first call `useTradeNameRefuseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTradeNameRefuseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tradeNameRefuseMutation, { data, loading, error }] = useTradeNameRefuseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTradeNameRefuseMutation(baseOptions?: Apollo.MutationHookOptions<Types.TradeNameRefuseMutation, Types.TradeNameRefuseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.TradeNameRefuseMutation, Types.TradeNameRefuseMutationVariables>(TradeNameRefuseDocument, options);
      }
export type TradeNameRefuseMutationHookResult = ReturnType<typeof useTradeNameRefuseMutation>;
export type TradeNameRefuseMutationResult = Apollo.MutationResult<Types.TradeNameRefuseMutation>;
export type TradeNameRefuseMutationOptions = Apollo.BaseMutationOptions<Types.TradeNameRefuseMutation, Types.TradeNameRefuseMutationVariables>;
export const WineVintageDetailsDocument = gql`
    query wineVintageDetails($id: ID!, $localeId: Int!) {
  wine(id: $id) {
    awards {
      id
      award {
        masterNameShort
      }
      year
      rewards {
        shortName
        rewardType {
          masterName
        }
      }
    }
    techSheets {
      locale {
        id
        locale
      }
    }
    products {
      acceptability
      verified
      bottleSize {
        id
        volumeML
      }
      packageType {
        id
        isDefault
        shortName
        localizedName(localeId: $localeId)
      }
    }
  }
}
    `;

/**
 * __useWineVintageDetailsQuery__
 *
 * To run a query within a React component, call `useWineVintageDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineVintageDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineVintageDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useWineVintageDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.WineVintageDetailsQuery, Types.WineVintageDetailsQueryVariables> & ({ variables: Types.WineVintageDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineVintageDetailsQuery, Types.WineVintageDetailsQueryVariables>(WineVintageDetailsDocument, options);
      }
export function useWineVintageDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineVintageDetailsQuery, Types.WineVintageDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineVintageDetailsQuery, Types.WineVintageDetailsQueryVariables>(WineVintageDetailsDocument, options);
        }
export function useWineVintageDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineVintageDetailsQuery, Types.WineVintageDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineVintageDetailsQuery, Types.WineVintageDetailsQueryVariables>(WineVintageDetailsDocument, options);
        }
export type WineVintageDetailsQueryHookResult = ReturnType<typeof useWineVintageDetailsQuery>;
export type WineVintageDetailsLazyQueryHookResult = ReturnType<typeof useWineVintageDetailsLazyQuery>;
export type WineVintageDetailsSuspenseQueryHookResult = ReturnType<typeof useWineVintageDetailsSuspenseQuery>;
export type WineVintageDetailsQueryResult = Apollo.QueryResult<Types.WineVintageDetailsQuery, Types.WineVintageDetailsQueryVariables>;
export const WineVintagesDocument = gql`
    query wineVintages($id: ID!) {
  wine(id: $id) {
    vintageList {
      id
      vintage
    }
  }
}
    `;

/**
 * __useWineVintagesQuery__
 *
 * To run a query within a React component, call `useWineVintagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineVintagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineVintagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWineVintagesQuery(baseOptions: Apollo.QueryHookOptions<Types.WineVintagesQuery, Types.WineVintagesQueryVariables> & ({ variables: Types.WineVintagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineVintagesQuery, Types.WineVintagesQueryVariables>(WineVintagesDocument, options);
      }
export function useWineVintagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineVintagesQuery, Types.WineVintagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineVintagesQuery, Types.WineVintagesQueryVariables>(WineVintagesDocument, options);
        }
export function useWineVintagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineVintagesQuery, Types.WineVintagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineVintagesQuery, Types.WineVintagesQueryVariables>(WineVintagesDocument, options);
        }
export type WineVintagesQueryHookResult = ReturnType<typeof useWineVintagesQuery>;
export type WineVintagesLazyQueryHookResult = ReturnType<typeof useWineVintagesLazyQuery>;
export type WineVintagesSuspenseQueryHookResult = ReturnType<typeof useWineVintagesSuspenseQuery>;
export type WineVintagesQueryResult = Apollo.QueryResult<Types.WineVintagesQuery, Types.WineVintagesQueryVariables>;
export const ProducersDuplicatesDocument = gql`
    query producersDuplicates($localeId: Int!, $query: String, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: TradeNameSort!, $filter: TradeNameFilter!) {
  tradeNameDatabasePagination(
    localeId: $localeId
    filter: $filter
    query: $query
    page: $page
    perPage: $perPage
    sort: $sort
  ) {
    pageInfo {
      totalItems
      totalPages
      page
      perPage
      hasNextPage
      hasPreviousPage
    }
    items {
      id
      acceptability
      shouldOverrideAcceptability
      canOverrideFacts
      verified
      logo {
        imageUrl
      }
      tradeName
      duplicateGroup {
        count
      }
      country {
        id
        localizedName(localeId: $localeId)
        identityNumberName
        branchNumberName
        vatName
        legalEntityDiscriminator
      }
      legalEntity {
        id
        legalName
        identityNumber
        branchNumber
        vat
        activityCode
        isActive
        closingDate
        legalEntityForm {
          id
          shortName
          originName
        }
      }
      website
      phone
      email
      postAddress
      postCode
      authorUser {
        username
      }
      source {
        id
        description
        name
        sourceType {
          code
        }
      }
      updatedAt
      canOverrideAcceptability
      sourceAuthorUser {
        username
      }
      phone
      email
      mainSynonym {
        id
        tradeName
      }
    }
  }
}
    `;

/**
 * __useProducersDuplicatesQuery__
 *
 * To run a query within a React component, call `useProducersDuplicatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProducersDuplicatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProducersDuplicatesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProducersDuplicatesQuery(baseOptions: Apollo.QueryHookOptions<Types.ProducersDuplicatesQuery, Types.ProducersDuplicatesQueryVariables> & ({ variables: Types.ProducersDuplicatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProducersDuplicatesQuery, Types.ProducersDuplicatesQueryVariables>(ProducersDuplicatesDocument, options);
      }
export function useProducersDuplicatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProducersDuplicatesQuery, Types.ProducersDuplicatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProducersDuplicatesQuery, Types.ProducersDuplicatesQueryVariables>(ProducersDuplicatesDocument, options);
        }
export function useProducersDuplicatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProducersDuplicatesQuery, Types.ProducersDuplicatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProducersDuplicatesQuery, Types.ProducersDuplicatesQueryVariables>(ProducersDuplicatesDocument, options);
        }
export type ProducersDuplicatesQueryHookResult = ReturnType<typeof useProducersDuplicatesQuery>;
export type ProducersDuplicatesLazyQueryHookResult = ReturnType<typeof useProducersDuplicatesLazyQuery>;
export type ProducersDuplicatesSuspenseQueryHookResult = ReturnType<typeof useProducersDuplicatesSuspenseQuery>;
export type ProducersDuplicatesQueryResult = Apollo.QueryResult<Types.ProducersDuplicatesQuery, Types.ProducersDuplicatesQueryVariables>;
export const ProducersDuplicatesFilterDocument = gql`
    query producersDuplicatesFilter($filterKey: String!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $includeUnusedLabels: Boolean! = true) {
  tradeNamesFilters(
    localeId: $localeId
    filter: {facetsInputs: $facetsInputs, onlyAccepted: false, onlyWithDuplicates: true}
    query: $query
  ) {
    filterByKey(key: $filterKey) {
      ...facetFilter
    }
  }
}
    ${FacetFilterFragmentDoc}`;

/**
 * __useProducersDuplicatesFilterQuery__
 *
 * To run a query within a React component, call `useProducersDuplicatesFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useProducersDuplicatesFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProducersDuplicatesFilterQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useProducersDuplicatesFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.ProducersDuplicatesFilterQuery, Types.ProducersDuplicatesFilterQueryVariables> & ({ variables: Types.ProducersDuplicatesFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProducersDuplicatesFilterQuery, Types.ProducersDuplicatesFilterQueryVariables>(ProducersDuplicatesFilterDocument, options);
      }
export function useProducersDuplicatesFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProducersDuplicatesFilterQuery, Types.ProducersDuplicatesFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProducersDuplicatesFilterQuery, Types.ProducersDuplicatesFilterQueryVariables>(ProducersDuplicatesFilterDocument, options);
        }
export function useProducersDuplicatesFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProducersDuplicatesFilterQuery, Types.ProducersDuplicatesFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProducersDuplicatesFilterQuery, Types.ProducersDuplicatesFilterQueryVariables>(ProducersDuplicatesFilterDocument, options);
        }
export type ProducersDuplicatesFilterQueryHookResult = ReturnType<typeof useProducersDuplicatesFilterQuery>;
export type ProducersDuplicatesFilterLazyQueryHookResult = ReturnType<typeof useProducersDuplicatesFilterLazyQuery>;
export type ProducersDuplicatesFilterSuspenseQueryHookResult = ReturnType<typeof useProducersDuplicatesFilterSuspenseQuery>;
export type ProducersDuplicatesFilterQueryResult = Apollo.QueryResult<Types.ProducersDuplicatesFilterQuery, Types.ProducersDuplicatesFilterQueryVariables>;
export const ProducersDuplicatesFiltersDocument = gql`
    query producersDuplicatesFilters($localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt!, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $includeUnusedLabels: Boolean! = true) {
  tradeNamesFilters(
    localeId: $localeId
    filter: {facetsInputs: $facetsInputs, onlyAccepted: false, onlyWithDuplicates: true}
    query: $query
  ) {
    count
    countryFilter: filterByKey(key: "country") {
      ...facetListFilter
    }
    usersFilter: filterByKey(key: "contributorUsername") {
      ...facetFreeListFilter
    }
    sourceFilter: filterByKey(key: "source") {
      ...facetListFilter
    }
    creationDateFilter: filterByKey(key: "createdAt") {
      ...facetDateRangeFilter
    }
    checkedByFilter: filterByKey(key: "checkedBy") {
      ...facetListFilter
    }
    invalidRecordFilter: filterByKey(key: "invalidRecord") {
      ...facetListFilter
    }
    producerWWLFilter: filterByKey(key: "hasVerifiedRepresentative") {
      ...facetListFilter
    }
    euGITypeFilter: filterByKey(key: "euGIType") {
      ...facetListFilter
    }
    nationalGITypeFilter: filterByKey(key: "nationalGIType") {
      ...facetListFilter
    }
    wineRegionEUFilter: filterByKey(key: "wineRegionEU") {
      ...facetListFilter
    }
    geoIndicationFilter: filterByKey(key: "geoIndication") {
      ...facetListFilter
    }
    wineTypeFilter: filterByKey(key: "wineType") {
      ...facetListFilter
    }
    wineColourFilter: filterByKey(key: "wineColor") {
      ...facetListFilter
    }
    sweetnessTypeFilter: filterByKey(key: "sweetnessLevel") {
      ...facetListFilter
    }
    vintageFilter: filterByKey(key: "vintage") {
      ...facetListFilter
    }
    bottleSizeFilter: filterByKey(key: "bottleSize") {
      ...facetListFilter
    }
    grapesFilter: filterByKey(key: "grapeVarieties") {
      ...facetListFilter
    }
    certificatesFilter: filterByKey(key: "certification") {
      ...facetListFilter
    }
    alcoholLevelFilter: filterByKey(key: "alcoholOnLabel") {
      ...facetRangeFilter
    }
    cellarDoorPriceFilter: filterByKey(key: "cellarDoorPrice") {
      ...facetComplexFilter
    }
    wwlRatingFilter: filterByKey(key: "wines.rating") {
      ...facetListFilter
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetFreeListFilterFragmentDoc}
${FacetDateRangeFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}`;

/**
 * __useProducersDuplicatesFiltersQuery__
 *
 * To run a query within a React component, call `useProducersDuplicatesFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProducersDuplicatesFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProducersDuplicatesFiltersQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useProducersDuplicatesFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.ProducersDuplicatesFiltersQuery, Types.ProducersDuplicatesFiltersQueryVariables> & ({ variables: Types.ProducersDuplicatesFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProducersDuplicatesFiltersQuery, Types.ProducersDuplicatesFiltersQueryVariables>(ProducersDuplicatesFiltersDocument, options);
      }
export function useProducersDuplicatesFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProducersDuplicatesFiltersQuery, Types.ProducersDuplicatesFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProducersDuplicatesFiltersQuery, Types.ProducersDuplicatesFiltersQueryVariables>(ProducersDuplicatesFiltersDocument, options);
        }
export function useProducersDuplicatesFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProducersDuplicatesFiltersQuery, Types.ProducersDuplicatesFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProducersDuplicatesFiltersQuery, Types.ProducersDuplicatesFiltersQueryVariables>(ProducersDuplicatesFiltersDocument, options);
        }
export type ProducersDuplicatesFiltersQueryHookResult = ReturnType<typeof useProducersDuplicatesFiltersQuery>;
export type ProducersDuplicatesFiltersLazyQueryHookResult = ReturnType<typeof useProducersDuplicatesFiltersLazyQuery>;
export type ProducersDuplicatesFiltersSuspenseQueryHookResult = ReturnType<typeof useProducersDuplicatesFiltersSuspenseQuery>;
export type ProducersDuplicatesFiltersQueryResult = Apollo.QueryResult<Types.ProducersDuplicatesFiltersQuery, Types.ProducersDuplicatesFiltersQueryVariables>;
export const TradeNameDuplicatesDocument = gql`
    query tradeNameDuplicates($id: ID!, $page: PositiveInt!, $sort: TradeNameSort!, $perPage: PositiveInt!, $localeId: Int!) {
  tradeName(id: $id) {
    id
    tradeName
    duplicateGroup {
      pagination(page: $page, perPage: $perPage, sort: $sort) {
        pageInfo {
          totalPages
          totalItems
          page
          perPage
          hasNextPage
          hasPreviousPage
        }
        items {
          id
          acceptability
          shouldOverrideAcceptability
          canOverrideFacts
          verified
          logo {
            imageUrl
          }
          tradeName
          duplicateGroup {
            count
          }
          country {
            id
            localizedName(localeId: $localeId)
            identityNumberName
            branchNumberName
          }
          legalEntity {
            id
            legalName
            identityNumber
            branchNumber
            activityCode
            isActive
            closingDate
            legalEntityForm {
              id
              shortName
              originName
            }
          }
          website
          phone
          email
          postAddress
          postCode
          authorUser {
            username
          }
          source {
            id
            description
            name
            sourceType {
              code
            }
          }
          updatedAt
          canOverrideAcceptability
          sourceAuthorUser {
            username
          }
          phone
          email
        }
      }
    }
  }
}
    `;

/**
 * __useTradeNameDuplicatesQuery__
 *
 * To run a query within a React component, call `useTradeNameDuplicatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeNameDuplicatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeNameDuplicatesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *      perPage: // value for 'perPage'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useTradeNameDuplicatesQuery(baseOptions: Apollo.QueryHookOptions<Types.TradeNameDuplicatesQuery, Types.TradeNameDuplicatesQueryVariables> & ({ variables: Types.TradeNameDuplicatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradeNameDuplicatesQuery, Types.TradeNameDuplicatesQueryVariables>(TradeNameDuplicatesDocument, options);
      }
export function useTradeNameDuplicatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradeNameDuplicatesQuery, Types.TradeNameDuplicatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradeNameDuplicatesQuery, Types.TradeNameDuplicatesQueryVariables>(TradeNameDuplicatesDocument, options);
        }
export function useTradeNameDuplicatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradeNameDuplicatesQuery, Types.TradeNameDuplicatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradeNameDuplicatesQuery, Types.TradeNameDuplicatesQueryVariables>(TradeNameDuplicatesDocument, options);
        }
export type TradeNameDuplicatesQueryHookResult = ReturnType<typeof useTradeNameDuplicatesQuery>;
export type TradeNameDuplicatesLazyQueryHookResult = ReturnType<typeof useTradeNameDuplicatesLazyQuery>;
export type TradeNameDuplicatesSuspenseQueryHookResult = ReturnType<typeof useTradeNameDuplicatesSuspenseQuery>;
export type TradeNameDuplicatesQueryResult = Apollo.QueryResult<Types.TradeNameDuplicatesQuery, Types.TradeNameDuplicatesQueryVariables>;
export const ProductFactDeleteDocument = gql`
    mutation productFactDelete($input: ProductFactDeleteInput!) {
  productFactDelete(input: $input) {
    query {
      _
    }
    record {
      id
    }
  }
}
    `;
export type ProductFactDeleteMutationFn = Apollo.MutationFunction<Types.ProductFactDeleteMutation, Types.ProductFactDeleteMutationVariables>;

/**
 * __useProductFactDeleteMutation__
 *
 * To run a mutation, you first call `useProductFactDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductFactDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productFactDeleteMutation, { data, loading, error }] = useProductFactDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductFactDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProductFactDeleteMutation, Types.ProductFactDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductFactDeleteMutation, Types.ProductFactDeleteMutationVariables>(ProductFactDeleteDocument, options);
      }
export type ProductFactDeleteMutationHookResult = ReturnType<typeof useProductFactDeleteMutation>;
export type ProductFactDeleteMutationResult = Apollo.MutationResult<Types.ProductFactDeleteMutation>;
export type ProductFactDeleteMutationOptions = Apollo.BaseMutationOptions<Types.ProductFactDeleteMutation, Types.ProductFactDeleteMutationVariables>;
export const TechSheetFactDeleteDocument = gql`
    mutation techSheetFactDelete($input: TechSheetFactDeleteInput!) {
  techSheetFactDelete(input: $input) {
    query {
      _
    }
    record {
      id
    }
  }
}
    `;
export type TechSheetFactDeleteMutationFn = Apollo.MutationFunction<Types.TechSheetFactDeleteMutation, Types.TechSheetFactDeleteMutationVariables>;

/**
 * __useTechSheetFactDeleteMutation__
 *
 * To run a mutation, you first call `useTechSheetFactDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTechSheetFactDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [techSheetFactDeleteMutation, { data, loading, error }] = useTechSheetFactDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTechSheetFactDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.TechSheetFactDeleteMutation, Types.TechSheetFactDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.TechSheetFactDeleteMutation, Types.TechSheetFactDeleteMutationVariables>(TechSheetFactDeleteDocument, options);
      }
export type TechSheetFactDeleteMutationHookResult = ReturnType<typeof useTechSheetFactDeleteMutation>;
export type TechSheetFactDeleteMutationResult = Apollo.MutationResult<Types.TechSheetFactDeleteMutation>;
export type TechSheetFactDeleteMutationOptions = Apollo.BaseMutationOptions<Types.TechSheetFactDeleteMutation, Types.TechSheetFactDeleteMutationVariables>;
export const WineAwardFactDeleteDocument = gql`
    mutation wineAwardFactDelete($input: WineAwardFactDeleteInput!) {
  wineAwardFactDelete(input: $input) {
    query {
      _
    }
    record {
      id
    }
  }
}
    `;
export type WineAwardFactDeleteMutationFn = Apollo.MutationFunction<Types.WineAwardFactDeleteMutation, Types.WineAwardFactDeleteMutationVariables>;

/**
 * __useWineAwardFactDeleteMutation__
 *
 * To run a mutation, you first call `useWineAwardFactDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineAwardFactDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineAwardFactDeleteMutation, { data, loading, error }] = useWineAwardFactDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineAwardFactDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineAwardFactDeleteMutation, Types.WineAwardFactDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineAwardFactDeleteMutation, Types.WineAwardFactDeleteMutationVariables>(WineAwardFactDeleteDocument, options);
      }
export type WineAwardFactDeleteMutationHookResult = ReturnType<typeof useWineAwardFactDeleteMutation>;
export type WineAwardFactDeleteMutationResult = Apollo.MutationResult<Types.WineAwardFactDeleteMutation>;
export type WineAwardFactDeleteMutationOptions = Apollo.BaseMutationOptions<Types.WineAwardFactDeleteMutation, Types.WineAwardFactDeleteMutationVariables>;
export const WineChangelogDocument = gql`
    query wineChangelog($id: ID!, $localeId: Int!) {
  wine(id: $id) {
    changelog {
      __typename
      ...FullWineDiff
      ...FullAcceptabilityDiff
      ...FullWineAwardAcceptabilityDiff
      ...FullTechSheetAcceptabilityDiff
      ...FullProductAcceptabilityDiff
      ...FullProductDiff
      ...FullWineAwardDiff
      ...FullTechSheetDiff
    }
  }
}
    ${FullWineDiffFragmentDoc}
${FullAcceptabilityDiffFragmentDoc}
${FullWineAwardAcceptabilityDiffFragmentDoc}
${FullTechSheetAcceptabilityDiffFragmentDoc}
${FullProductAcceptabilityDiffFragmentDoc}
${FullProductDiffFragmentDoc}
${FullWineAwardDiffFragmentDoc}
${FullTechSheetDiffFragmentDoc}`;

/**
 * __useWineChangelogQuery__
 *
 * To run a query within a React component, call `useWineChangelogQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineChangelogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineChangelogQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useWineChangelogQuery(baseOptions: Apollo.QueryHookOptions<Types.WineChangelogQuery, Types.WineChangelogQueryVariables> & ({ variables: Types.WineChangelogQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineChangelogQuery, Types.WineChangelogQueryVariables>(WineChangelogDocument, options);
      }
export function useWineChangelogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineChangelogQuery, Types.WineChangelogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineChangelogQuery, Types.WineChangelogQueryVariables>(WineChangelogDocument, options);
        }
export function useWineChangelogSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineChangelogQuery, Types.WineChangelogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineChangelogQuery, Types.WineChangelogQueryVariables>(WineChangelogDocument, options);
        }
export type WineChangelogQueryHookResult = ReturnType<typeof useWineChangelogQuery>;
export type WineChangelogLazyQueryHookResult = ReturnType<typeof useWineChangelogLazyQuery>;
export type WineChangelogSuspenseQueryHookResult = ReturnType<typeof useWineChangelogSuspenseQuery>;
export type WineChangelogQueryResult = Apollo.QueryResult<Types.WineChangelogQuery, Types.WineChangelogQueryVariables>;
export const WineFactDeleteDocument = gql`
    mutation wineFactDelete($input: WineFactDeleteInput!) {
  wineFactDelete(input: $input) {
    query {
      _
    }
    record {
      id
    }
  }
}
    `;
export type WineFactDeleteMutationFn = Apollo.MutationFunction<Types.WineFactDeleteMutation, Types.WineFactDeleteMutationVariables>;

/**
 * __useWineFactDeleteMutation__
 *
 * To run a mutation, you first call `useWineFactDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineFactDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineFactDeleteMutation, { data, loading, error }] = useWineFactDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineFactDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineFactDeleteMutation, Types.WineFactDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineFactDeleteMutation, Types.WineFactDeleteMutationVariables>(WineFactDeleteDocument, options);
      }
export type WineFactDeleteMutationHookResult = ReturnType<typeof useWineFactDeleteMutation>;
export type WineFactDeleteMutationResult = Apollo.MutationResult<Types.WineFactDeleteMutation>;
export type WineFactDeleteMutationOptions = Apollo.BaseMutationOptions<Types.WineFactDeleteMutation, Types.WineFactDeleteMutationVariables>;
export const WinesDatabasePaginationDocument = gql`
    query winesDatabasePagination($query: String, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: WineSort!, $localeId: Int!, $filter: WineDatabaseFilter!) {
  winesDatabasePagination(
    localeId: $localeId
    filter: $filter
    query: $query
    page: $page
    perPage: $perPage
    sort: $sort
  ) {
    pageInfo {
      totalItems
      totalPages
      page
      perPage
      hasNextPage
      hasPreviousPage
    }
    items {
      ...WineDatabaseItemFragment
    }
  }
}
    ${WineDatabaseItemFragmentFragmentDoc}`;

/**
 * __useWinesDatabasePaginationQuery__
 *
 * To run a query within a React component, call `useWinesDatabasePaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinesDatabasePaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinesDatabasePaginationQuery({
 *   variables: {
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      localeId: // value for 'localeId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useWinesDatabasePaginationQuery(baseOptions: Apollo.QueryHookOptions<Types.WinesDatabasePaginationQuery, Types.WinesDatabasePaginationQueryVariables> & ({ variables: Types.WinesDatabasePaginationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WinesDatabasePaginationQuery, Types.WinesDatabasePaginationQueryVariables>(WinesDatabasePaginationDocument, options);
      }
export function useWinesDatabasePaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WinesDatabasePaginationQuery, Types.WinesDatabasePaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WinesDatabasePaginationQuery, Types.WinesDatabasePaginationQueryVariables>(WinesDatabasePaginationDocument, options);
        }
export function useWinesDatabasePaginationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WinesDatabasePaginationQuery, Types.WinesDatabasePaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WinesDatabasePaginationQuery, Types.WinesDatabasePaginationQueryVariables>(WinesDatabasePaginationDocument, options);
        }
export type WinesDatabasePaginationQueryHookResult = ReturnType<typeof useWinesDatabasePaginationQuery>;
export type WinesDatabasePaginationLazyQueryHookResult = ReturnType<typeof useWinesDatabasePaginationLazyQuery>;
export type WinesDatabasePaginationSuspenseQueryHookResult = ReturnType<typeof useWinesDatabasePaginationSuspenseQuery>;
export type WinesDatabasePaginationQueryResult = Apollo.QueryResult<Types.WinesDatabasePaginationQuery, Types.WinesDatabasePaginationQueryVariables>;
export const WinesFilterDocument = gql`
    query winesFilter($filterKey: String!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $includeUnusedLabels: Boolean! = true) {
  winesFilters(
    localeId: $localeId
    filter: {facetsInputs: $facetsInputs}
    query: $query
  ) {
    filterByKey(key: $filterKey) {
      ...facetFilter
    }
  }
}
    ${FacetFilterFragmentDoc}`;

/**
 * __useWinesFilterQuery__
 *
 * To run a query within a React component, call `useWinesFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinesFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinesFilterQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useWinesFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.WinesFilterQuery, Types.WinesFilterQueryVariables> & ({ variables: Types.WinesFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WinesFilterQuery, Types.WinesFilterQueryVariables>(WinesFilterDocument, options);
      }
export function useWinesFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WinesFilterQuery, Types.WinesFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WinesFilterQuery, Types.WinesFilterQueryVariables>(WinesFilterDocument, options);
        }
export function useWinesFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WinesFilterQuery, Types.WinesFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WinesFilterQuery, Types.WinesFilterQueryVariables>(WinesFilterDocument, options);
        }
export type WinesFilterQueryHookResult = ReturnType<typeof useWinesFilterQuery>;
export type WinesFilterLazyQueryHookResult = ReturnType<typeof useWinesFilterLazyQuery>;
export type WinesFilterSuspenseQueryHookResult = ReturnType<typeof useWinesFilterSuspenseQuery>;
export type WinesFilterQueryResult = Apollo.QueryResult<Types.WinesFilterQuery, Types.WinesFilterQueryVariables>;
export const WinesFiltersDocument = gql`
    query winesFilters($localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt!, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $includeUnusedLabels: Boolean! = true) {
  winesFilters(
    localeId: $localeId
    filter: {facetsInputs: $facetsInputs}
    query: $query
  ) {
    count
    countryFilter: filterByKey(key: "country") {
      ...facetListFilter
    }
    usersFilter: filterByKey(key: "contributorUsername") {
      ...facetFreeListFilter
    }
    sourceFilter: filterByKey(key: "source") {
      ...facetListFilter
    }
    creationDateFilter: filterByKey(key: "createdAt") {
      ...facetDateRangeFilter
    }
    lastUpdateFilter: filterByKey(key: "updatedAt") {
      ...facetDateRangeFilter
    }
    checkedByFilter: filterByKey(key: "checkedBy") {
      ...facetListFilter
    }
    invalidRecordFilter: filterByKey(key: "invalidRecord") {
      ...facetListFilter
    }
    producerFilter: filterByKey(key: "tradeName") {
      ...facetListFilter
    }
    brandFilter: filterByKey(key: "brand") {
      ...facetListFilter
    }
    nationalGITypeFilter: filterByKey(key: "nationalGIType") {
      ...facetListFilter
    }
    wineRegionEUFilter: filterByKey(key: "wineRegionEU") {
      ...facetListFilter
    }
    geoIndicationFilter: filterByKey(key: "geoIndication") {
      ...facetListFilter
    }
    wineTypeFilter: filterByKey(key: "wineType") {
      ...facetListFilter
    }
    wineColourFilter: filterByKey(key: "wineColor") {
      ...facetListFilter
    }
    sweetnessTypeFilter: filterByKey(key: "sweetnessLevel") {
      ...facetListFilter
    }
    vintageFilter: filterByKey(key: "vintage") {
      ...facetListFilter
    }
    bottleSizeFilter: filterByKey(key: "bottleSize") {
      ...facetListFilter
    }
    packageTypeFilter: filterByKey(key: "packageType") {
      ...facetListFilter
    }
    grapesFilter: filterByKey(key: "grapeAndMonocepage") {
      ...facetComplexFilter
    }
    certificatesFilter: filterByKey(key: "certification") {
      ...facetListFilter
    }
    wwlRatingFilter: filterByKey(key: "rating") {
      ...facetListFilter
    }
    specialClassificationFilter: filterByKey(key: "specialClassification") {
      ...facetListFilter
    }
    cellarDoorPriceFilter: filterByKey(key: "cellarDoorPrice") {
      ...facetComplexFilter
    }
    producerWWLFilter: filterByKey(key: "hasVerifiedRepresentative") {
      ...facetListFilter
    }
    hasBottleImageFilter: filterByKey(key: "hasBottleImage") {
      ...facetListFilter
    }
    hasFrontLabelImageFilter: filterByKey(key: "hasFrontLabelImage") {
      ...facetListFilter
    }
    hasBackLabelImageFilter: filterByKey(key: "hasBackLabelImage") {
      ...facetListFilter
    }
    alcoholLevelFilter: filterByKey(key: "alcoholOnLabel") {
      ...facetRangeFilter
    }
    retailPriceFilter: filterByKey(key: "tradeOfferLotPricePerBottle") {
      ...facetComplexFilter
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetFreeListFilterFragmentDoc}
${FacetDateRangeFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}`;

/**
 * __useWinesFiltersQuery__
 *
 * To run a query within a React component, call `useWinesFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinesFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinesFiltersQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useWinesFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.WinesFiltersQuery, Types.WinesFiltersQueryVariables> & ({ variables: Types.WinesFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WinesFiltersQuery, Types.WinesFiltersQueryVariables>(WinesFiltersDocument, options);
      }
export function useWinesFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WinesFiltersQuery, Types.WinesFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WinesFiltersQuery, Types.WinesFiltersQueryVariables>(WinesFiltersDocument, options);
        }
export function useWinesFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WinesFiltersQuery, Types.WinesFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WinesFiltersQuery, Types.WinesFiltersQueryVariables>(WinesFiltersDocument, options);
        }
export type WinesFiltersQueryHookResult = ReturnType<typeof useWinesFiltersQuery>;
export type WinesFiltersLazyQueryHookResult = ReturnType<typeof useWinesFiltersLazyQuery>;
export type WinesFiltersSuspenseQueryHookResult = ReturnType<typeof useWinesFiltersSuspenseQuery>;
export type WinesFiltersQueryResult = Apollo.QueryResult<Types.WinesFiltersQuery, Types.WinesFiltersQueryVariables>;
export const WinePackageTypesDocument = gql`
    query winePackageTypes($localeId: Int!) {
  winePackageTypes {
    id
    isDefault
    shortName
    localizedName(localeId: $localeId)
  }
}
    `;

/**
 * __useWinePackageTypesQuery__
 *
 * To run a query within a React component, call `useWinePackageTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinePackageTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinePackageTypesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useWinePackageTypesQuery(baseOptions: Apollo.QueryHookOptions<Types.WinePackageTypesQuery, Types.WinePackageTypesQueryVariables> & ({ variables: Types.WinePackageTypesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WinePackageTypesQuery, Types.WinePackageTypesQueryVariables>(WinePackageTypesDocument, options);
      }
export function useWinePackageTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WinePackageTypesQuery, Types.WinePackageTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WinePackageTypesQuery, Types.WinePackageTypesQueryVariables>(WinePackageTypesDocument, options);
        }
export function useWinePackageTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WinePackageTypesQuery, Types.WinePackageTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WinePackageTypesQuery, Types.WinePackageTypesQueryVariables>(WinePackageTypesDocument, options);
        }
export type WinePackageTypesQueryHookResult = ReturnType<typeof useWinePackageTypesQuery>;
export type WinePackageTypesLazyQueryHookResult = ReturnType<typeof useWinePackageTypesLazyQuery>;
export type WinePackageTypesSuspenseQueryHookResult = ReturnType<typeof useWinePackageTypesSuspenseQuery>;
export type WinePackageTypesQueryResult = Apollo.QueryResult<Types.WinePackageTypesQuery, Types.WinePackageTypesQueryVariables>;
export const CountriesForGeoIndicationDocument = gql`
    query countriesForGeoIndication($localeId: Int!) {
  countries {
    id
    localizedName(localeId: $localeId)
    codeISO
    masterName
    postCodeName
    isEUMember
    wineTerritoryTypes {
      id
      localizedName(localeId: $localeId)
      geoIndications {
        id
        localizedName(localeId: $localeId)
      }
      level
    }
    wineRegionsEU {
      id
      localizedName(localeId: $localeId)
    }
    nationalGITypes {
      id
      shortName
      localizedName(localeId: $localeId)
    }
  }
}
    `;

/**
 * __useCountriesForGeoIndicationQuery__
 *
 * To run a query within a React component, call `useCountriesForGeoIndicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesForGeoIndicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesForGeoIndicationQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useCountriesForGeoIndicationQuery(baseOptions: Apollo.QueryHookOptions<Types.CountriesForGeoIndicationQuery, Types.CountriesForGeoIndicationQueryVariables> & ({ variables: Types.CountriesForGeoIndicationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountriesForGeoIndicationQuery, Types.CountriesForGeoIndicationQueryVariables>(CountriesForGeoIndicationDocument, options);
      }
export function useCountriesForGeoIndicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountriesForGeoIndicationQuery, Types.CountriesForGeoIndicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountriesForGeoIndicationQuery, Types.CountriesForGeoIndicationQueryVariables>(CountriesForGeoIndicationDocument, options);
        }
export function useCountriesForGeoIndicationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CountriesForGeoIndicationQuery, Types.CountriesForGeoIndicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CountriesForGeoIndicationQuery, Types.CountriesForGeoIndicationQueryVariables>(CountriesForGeoIndicationDocument, options);
        }
export type CountriesForGeoIndicationQueryHookResult = ReturnType<typeof useCountriesForGeoIndicationQuery>;
export type CountriesForGeoIndicationLazyQueryHookResult = ReturnType<typeof useCountriesForGeoIndicationLazyQuery>;
export type CountriesForGeoIndicationSuspenseQueryHookResult = ReturnType<typeof useCountriesForGeoIndicationSuspenseQuery>;
export type CountriesForGeoIndicationQueryResult = Apollo.QueryResult<Types.CountriesForGeoIndicationQuery, Types.CountriesForGeoIndicationQueryVariables>;
export const GeoIndicationDocument = gql`
    query GeoIndication($id: Int!, $localeId: Int!) {
  geoIndication(id: $id) {
    id
    masterName
    code
    localizedName(localeId: $localeId)
    translations {
      name
      locale {
        locale
        name
        id
      }
    }
    wineTerritoryType {
      id
      localizedName(localeId: $localeId)
      masterName
      country {
        codeISO
        masterName
        id
        localizedName(localeId: $localeId)
      }
    }
    parent {
      id
      localizedName(localeId: $localeId)
    }
    nationalGITypes {
      id
      localizedName(localeId: $localeId)
      shortName
      localizedShortName(localeId: $localeId)
      euGIType {
        localizedShortName(localeId: $localeId)
      }
    }
    wineRegionsEU {
      id
      masterName
      localizedName(localeId: $localeId)
    }
  }
}
    `;

/**
 * __useGeoIndicationQuery__
 *
 * To run a query within a React component, call `useGeoIndicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeoIndicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeoIndicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useGeoIndicationQuery(baseOptions: Apollo.QueryHookOptions<Types.GeoIndicationQuery, Types.GeoIndicationQueryVariables> & ({ variables: Types.GeoIndicationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GeoIndicationQuery, Types.GeoIndicationQueryVariables>(GeoIndicationDocument, options);
      }
export function useGeoIndicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GeoIndicationQuery, Types.GeoIndicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GeoIndicationQuery, Types.GeoIndicationQueryVariables>(GeoIndicationDocument, options);
        }
export function useGeoIndicationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GeoIndicationQuery, Types.GeoIndicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GeoIndicationQuery, Types.GeoIndicationQueryVariables>(GeoIndicationDocument, options);
        }
export type GeoIndicationQueryHookResult = ReturnType<typeof useGeoIndicationQuery>;
export type GeoIndicationLazyQueryHookResult = ReturnType<typeof useGeoIndicationLazyQuery>;
export type GeoIndicationSuspenseQueryHookResult = ReturnType<typeof useGeoIndicationSuspenseQuery>;
export type GeoIndicationQueryResult = Apollo.QueryResult<Types.GeoIndicationQuery, Types.GeoIndicationQueryVariables>;
export const GeoIndicationCreateDocument = gql`
    mutation geoIndicationCreate($input: GeoIndicationCreateInput!) {
  geoIndicationCreate(input: $input) {
    recordId
    record {
      id
      masterName
      code
      wineTerritoryType {
        id
      }
      translations {
        name
      }
      wineRegionsEU {
        id
      }
      nationalGITypes {
        id
      }
      parent {
        id
      }
    }
  }
}
    `;
export type GeoIndicationCreateMutationFn = Apollo.MutationFunction<Types.GeoIndicationCreateMutation, Types.GeoIndicationCreateMutationVariables>;

/**
 * __useGeoIndicationCreateMutation__
 *
 * To run a mutation, you first call `useGeoIndicationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeoIndicationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [geoIndicationCreateMutation, { data, loading, error }] = useGeoIndicationCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGeoIndicationCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.GeoIndicationCreateMutation, Types.GeoIndicationCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GeoIndicationCreateMutation, Types.GeoIndicationCreateMutationVariables>(GeoIndicationCreateDocument, options);
      }
export type GeoIndicationCreateMutationHookResult = ReturnType<typeof useGeoIndicationCreateMutation>;
export type GeoIndicationCreateMutationResult = Apollo.MutationResult<Types.GeoIndicationCreateMutation>;
export type GeoIndicationCreateMutationOptions = Apollo.BaseMutationOptions<Types.GeoIndicationCreateMutation, Types.GeoIndicationCreateMutationVariables>;
export const GeoIndicationUpdateDocument = gql`
    mutation geoIndicationUpdate($input: GeoIndicationUpdateInput!, $localeId: Int!) {
  geoIndicationUpdate(input: $input) {
    recordId
    record {
      id
      masterName
      code
      wineTerritoryType {
        id
        localizedName(localeId: $localeId)
        geoIndications {
          id
          localizedName(localeId: $localeId)
        }
      }
      translations {
        name
      }
      wineRegionsEU {
        id
      }
      nationalGITypes {
        id
      }
      parent {
        id
      }
    }
  }
}
    `;
export type GeoIndicationUpdateMutationFn = Apollo.MutationFunction<Types.GeoIndicationUpdateMutation, Types.GeoIndicationUpdateMutationVariables>;

/**
 * __useGeoIndicationUpdateMutation__
 *
 * To run a mutation, you first call `useGeoIndicationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeoIndicationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [geoIndicationUpdateMutation, { data, loading, error }] = useGeoIndicationUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useGeoIndicationUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.GeoIndicationUpdateMutation, Types.GeoIndicationUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GeoIndicationUpdateMutation, Types.GeoIndicationUpdateMutationVariables>(GeoIndicationUpdateDocument, options);
      }
export type GeoIndicationUpdateMutationHookResult = ReturnType<typeof useGeoIndicationUpdateMutation>;
export type GeoIndicationUpdateMutationResult = Apollo.MutationResult<Types.GeoIndicationUpdateMutation>;
export type GeoIndicationUpdateMutationOptions = Apollo.BaseMutationOptions<Types.GeoIndicationUpdateMutation, Types.GeoIndicationUpdateMutationVariables>;
export const AdminCreateImageDocument = gql`
    mutation adminCreateImage($input: AdminProductImageCreateInput!) {
  admin {
    productImages {
      create(input: $input) {
        query {
          _
        }
        record {
          id
        }
      }
    }
  }
}
    `;
export type AdminCreateImageMutationFn = Apollo.MutationFunction<Types.AdminCreateImageMutation, Types.AdminCreateImageMutationVariables>;

/**
 * __useAdminCreateImageMutation__
 *
 * To run a mutation, you first call `useAdminCreateImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateImageMutation, { data, loading, error }] = useAdminCreateImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateImageMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminCreateImageMutation, Types.AdminCreateImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminCreateImageMutation, Types.AdminCreateImageMutationVariables>(AdminCreateImageDocument, options);
      }
export type AdminCreateImageMutationHookResult = ReturnType<typeof useAdminCreateImageMutation>;
export type AdminCreateImageMutationResult = Apollo.MutationResult<Types.AdminCreateImageMutation>;
export type AdminCreateImageMutationOptions = Apollo.BaseMutationOptions<Types.AdminCreateImageMutation, Types.AdminCreateImageMutationVariables>;
export const MerchantByWebsiteDocument = gql`
    query merchantByWebsite($website: URL!) {
  admin {
    merchants {
      merchantByWebsite(website: $website) {
        id
        name
        website
      }
    }
  }
}
    `;

/**
 * __useMerchantByWebsiteQuery__
 *
 * To run a query within a React component, call `useMerchantByWebsiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantByWebsiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantByWebsiteQuery({
 *   variables: {
 *      website: // value for 'website'
 *   },
 * });
 */
export function useMerchantByWebsiteQuery(baseOptions: Apollo.QueryHookOptions<Types.MerchantByWebsiteQuery, Types.MerchantByWebsiteQueryVariables> & ({ variables: Types.MerchantByWebsiteQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MerchantByWebsiteQuery, Types.MerchantByWebsiteQueryVariables>(MerchantByWebsiteDocument, options);
      }
export function useMerchantByWebsiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MerchantByWebsiteQuery, Types.MerchantByWebsiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MerchantByWebsiteQuery, Types.MerchantByWebsiteQueryVariables>(MerchantByWebsiteDocument, options);
        }
export function useMerchantByWebsiteSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MerchantByWebsiteQuery, Types.MerchantByWebsiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MerchantByWebsiteQuery, Types.MerchantByWebsiteQueryVariables>(MerchantByWebsiteDocument, options);
        }
export type MerchantByWebsiteQueryHookResult = ReturnType<typeof useMerchantByWebsiteQuery>;
export type MerchantByWebsiteLazyQueryHookResult = ReturnType<typeof useMerchantByWebsiteLazyQuery>;
export type MerchantByWebsiteSuspenseQueryHookResult = ReturnType<typeof useMerchantByWebsiteSuspenseQuery>;
export type MerchantByWebsiteQueryResult = Apollo.QueryResult<Types.MerchantByWebsiteQuery, Types.MerchantByWebsiteQueryVariables>;
export const WineReviewsFilterDocument = gql`
    query wineReviewsFilter($filterKey: String!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $status: WineReviewStatus, $includeUnusedLabels: Boolean! = true) {
  wineReviewsFilters(
    localeId: $localeId
    filter: {facetsInputs: $facetsInputs, status: $status}
    query: $query
  ) {
    filterByKey(key: $filterKey) {
      ...facetFilter
    }
  }
}
    ${FacetFilterFragmentDoc}`;

/**
 * __useWineReviewsFilterQuery__
 *
 * To run a query within a React component, call `useWineReviewsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineReviewsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineReviewsFilterQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      status: // value for 'status'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useWineReviewsFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.WineReviewsFilterQuery, Types.WineReviewsFilterQueryVariables> & ({ variables: Types.WineReviewsFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineReviewsFilterQuery, Types.WineReviewsFilterQueryVariables>(WineReviewsFilterDocument, options);
      }
export function useWineReviewsFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineReviewsFilterQuery, Types.WineReviewsFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineReviewsFilterQuery, Types.WineReviewsFilterQueryVariables>(WineReviewsFilterDocument, options);
        }
export function useWineReviewsFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineReviewsFilterQuery, Types.WineReviewsFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineReviewsFilterQuery, Types.WineReviewsFilterQueryVariables>(WineReviewsFilterDocument, options);
        }
export type WineReviewsFilterQueryHookResult = ReturnType<typeof useWineReviewsFilterQuery>;
export type WineReviewsFilterLazyQueryHookResult = ReturnType<typeof useWineReviewsFilterLazyQuery>;
export type WineReviewsFilterSuspenseQueryHookResult = ReturnType<typeof useWineReviewsFilterSuspenseQuery>;
export type WineReviewsFilterQueryResult = Apollo.QueryResult<Types.WineReviewsFilterQuery, Types.WineReviewsFilterQueryVariables>;
export const WineReviewsFiltersDocument = gql`
    query wineReviewsFilters($localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt!, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $status: WineReviewStatus, $includeUnusedLabels: Boolean! = true) {
  wineReviewsFilters(
    localeId: $localeId
    filter: {facetsInputs: $facetsInputs, status: $status}
    query: $query
  ) {
    count
    countryFilter: filterByKey(key: "product.wine.country") {
      ...facetListFilter
    }
    producerFilter: filterByKey(key: "product.wine.tradeName") {
      ...facetListFilter
    }
    brandFilter: filterByKey(key: "product.wine.brand") {
      ...facetListFilter
    }
    nationalGITypeFilter: filterByKey(key: "product.wine.nationalGIType") {
      ...facetListFilter
    }
    wineRegionEUFilter: filterByKey(key: "product.wine.wineRegionEU") {
      ...facetListFilter
    }
    geoIndicationFilter: filterByKey(key: "product.wine.geoIndication") {
      ...facetListFilter
    }
    euGITypeFilter: filterByKey(key: "product.wine.nationalGIType.euGIType") {
      ...facetListFilter
    }
    specialClassificationFilter: filterByKey(
      key: "product.wine.specialClassification"
    ) {
      ...facetListFilter
    }
    bottleSizeFilter: filterByKey(key: "product.bottleSize") {
      ...facetListFilter
    }
    packageTypeFilter: filterByKey(key: "packageType") {
      ...facetListFilter
    }
    wineTypeFilter: filterByKey(key: "product.wine.wineType") {
      ...facetListFilter
    }
    wineColourFilter: filterByKey(key: "product.wine.wineColor") {
      ...facetListFilter
    }
    sweetnessTypeFilter: filterByKey(key: "product.wine.sweetnessLevel") {
      ...facetListFilter
    }
    vintageFilter: filterByKey(key: "product.wine.vintage") {
      ...facetListFilter
    }
    grapesFilter: filterByKey(key: "grapeAndMonocepage") {
      ...facetComplexFilter
    }
    wwlRatingFilter: filterByKey(key: "product.wine.rating") {
      ...facetListFilter
    }
    venueTypeFilter: filterByKey(key: "placeType") {
      ...facetListFilter
    }
    additionalInfoFilter: filterByKey(key: "product.wine.certification") {
      ...facetListFilter
    }
    alcoholLevelFilter: filterByKey(key: "product.alcoholOnLabel") {
      ...facetRangeFilter
    }
    samplingDateFilter: filterByKey(key: "date") {
      ...facetDateRangeFilter
    }
    alcoholLevelFilter: filterByKey(key: "product.alcoholOnLabel") {
      ...facetRangeFilter
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}
${FacetDateRangeFilterFragmentDoc}`;

/**
 * __useWineReviewsFiltersQuery__
 *
 * To run a query within a React component, call `useWineReviewsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineReviewsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineReviewsFiltersQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      status: // value for 'status'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useWineReviewsFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.WineReviewsFiltersQuery, Types.WineReviewsFiltersQueryVariables> & ({ variables: Types.WineReviewsFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineReviewsFiltersQuery, Types.WineReviewsFiltersQueryVariables>(WineReviewsFiltersDocument, options);
      }
export function useWineReviewsFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineReviewsFiltersQuery, Types.WineReviewsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineReviewsFiltersQuery, Types.WineReviewsFiltersQueryVariables>(WineReviewsFiltersDocument, options);
        }
export function useWineReviewsFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineReviewsFiltersQuery, Types.WineReviewsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineReviewsFiltersQuery, Types.WineReviewsFiltersQueryVariables>(WineReviewsFiltersDocument, options);
        }
export type WineReviewsFiltersQueryHookResult = ReturnType<typeof useWineReviewsFiltersQuery>;
export type WineReviewsFiltersLazyQueryHookResult = ReturnType<typeof useWineReviewsFiltersLazyQuery>;
export type WineReviewsFiltersSuspenseQueryHookResult = ReturnType<typeof useWineReviewsFiltersSuspenseQuery>;
export type WineReviewsFiltersQueryResult = Apollo.QueryResult<Types.WineReviewsFiltersQuery, Types.WineReviewsFiltersQueryVariables>;
export const GetCurrentUserBrandsAndTradeMarksDocument = gql`
    query getCurrentUserBrandsAndTradeMarks($companyId: ID!, $localeId: Int!, $query: String) {
  me {
    company(id: $companyId) {
      producerTradeName {
        tradeName
        brands(query: $query) {
          id
          updatedAt
          sources {
            id
          }
          sourceAuthorUser {
            username
          }
          authorUser {
            username
          }
          name
          localizedName(localeId: $localeId)
          tradeName {
            tradeName
            country {
              localizedName(localeId: $localeId)
              codeISO
              id
            }
          }
          translations {
            locale {
              locale
            }
            name
          }
          acceptability
          shouldOverrideAcceptability
          verified
          canOverrideFacts
          canOverrideAcceptability
          tradeMarks {
            id
            name
            country {
              localizedName(localeId: $localeId)
              codeISO
              id
            }
            holderName
            TIN
            status {
              id
              localizedName(localeId: $localeId)
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCurrentUserBrandsAndTradeMarksQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserBrandsAndTradeMarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserBrandsAndTradeMarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserBrandsAndTradeMarksQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetCurrentUserBrandsAndTradeMarksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCurrentUserBrandsAndTradeMarksQuery, Types.GetCurrentUserBrandsAndTradeMarksQueryVariables> & ({ variables: Types.GetCurrentUserBrandsAndTradeMarksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCurrentUserBrandsAndTradeMarksQuery, Types.GetCurrentUserBrandsAndTradeMarksQueryVariables>(GetCurrentUserBrandsAndTradeMarksDocument, options);
      }
export function useGetCurrentUserBrandsAndTradeMarksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCurrentUserBrandsAndTradeMarksQuery, Types.GetCurrentUserBrandsAndTradeMarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCurrentUserBrandsAndTradeMarksQuery, Types.GetCurrentUserBrandsAndTradeMarksQueryVariables>(GetCurrentUserBrandsAndTradeMarksDocument, options);
        }
export function useGetCurrentUserBrandsAndTradeMarksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetCurrentUserBrandsAndTradeMarksQuery, Types.GetCurrentUserBrandsAndTradeMarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetCurrentUserBrandsAndTradeMarksQuery, Types.GetCurrentUserBrandsAndTradeMarksQueryVariables>(GetCurrentUserBrandsAndTradeMarksDocument, options);
        }
export type GetCurrentUserBrandsAndTradeMarksQueryHookResult = ReturnType<typeof useGetCurrentUserBrandsAndTradeMarksQuery>;
export type GetCurrentUserBrandsAndTradeMarksLazyQueryHookResult = ReturnType<typeof useGetCurrentUserBrandsAndTradeMarksLazyQuery>;
export type GetCurrentUserBrandsAndTradeMarksSuspenseQueryHookResult = ReturnType<typeof useGetCurrentUserBrandsAndTradeMarksSuspenseQuery>;
export type GetCurrentUserBrandsAndTradeMarksQueryResult = Apollo.QueryResult<Types.GetCurrentUserBrandsAndTradeMarksQuery, Types.GetCurrentUserBrandsAndTradeMarksQueryVariables>;
export const MyELabelsPaginationDocument = gql`
    query myELabelsPagination($localeId: Int!, $companyId: ID!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: ProductELabelSort! = UPDATED_AT_DESC, $query: String, $filter: ProductELabelsFilter) {
  me {
    company(id: $companyId) {
      id
      producerTradeName {
        id
        productELabelsPagination(
          localeId: $localeId
          page: $page
          perPage: $perPage
          sort: $sort
          query: $query
          filter: $filter
        ) {
          pageInfo {
            totalItems
            totalPages
            page
            perPage
            hasPreviousPage
            hasNextPage
          }
          items {
            id
            packageElements {
              element {
                id
                localizedName(localeId: $localeId)
              }
              euMaterials {
                id
                logo
                code
                localizedName(localeId: $localeId)
                localizedDisposal(localeId: $localeId)
              }
            }
            originProduct {
              slug
              alcoholOnLabel
              bottleImage {
                imageUrl
              }
              wine {
                wineType {
                  id
                  isDefault
                  localizedName(localeId: $localeId)
                }
                ingredients {
                  id
                  isAllergen
                  localizedName(localeId: $localeId)
                  code
                  category {
                    id
                    localizedName(localeId: $localeId)
                    isPrintable
                  }
                }
                certs {
                  localizedName(localeId: $localeId)
                  id
                }
                responsibleConsumptions {
                  id
                  localizedName(localeId: $localeId)
                }
                nutritionDeclaration {
                  energyKCal
                  energyKCalApproximate
                  energyKJ
                  fat
                  fatApproximate
                  saturates
                  saturatesApproximate
                  carbohydrate
                  carbohydrateApproximate
                  sugars
                  sugarsApproximate
                  protein
                  proteinApproximate
                  salt
                  saltApproximate
                }
                grapeVarieties {
                  value
                  grapeVarietySynonym {
                    id
                    name
                  }
                }
                chemicalAnalysis {
                  alcoholVolume
                }
              }
            }
            wine {
              keyWords
              tradeNameBrand
              tradeName {
                tradeName
              }
              brand {
                brand
              }
              specialClassifications {
                name
              }
              wineType {
                id
                isDefault
                localizedName(localeId: $localeId)
              }
              wineColor {
                localizedName(localeId: $localeId)
              }
              sweetnessLevel {
                localizedName(localeId: $localeId)
                isDefault
              }
              nationalGIType {
                shortName
                localizedName(localeId: $localeId)
              }
              vintage
              country {
                id
                localizedName(localeId: $localeId)
                codeISO
              }
              chemicalAnalysis {
                alcoholVolume
              }
              geoIndications {
                localizedName(localeId: $localeId)
              }
              nutritionDeclarationFilled
              nutritionDeclaration {
                energyKCal
                energyKCalApproximate
                energyKJ
                fat
                fatApproximate
                saturates
                saturatesApproximate
                carbohydrate
                carbohydrateApproximate
                sugars
                sugarsApproximate
                protein
                proteinApproximate
                salt
                saltApproximate
              }
              ingredientsFilled
              ingredients {
                id
                isAllergen
                localizedName(localeId: $localeId)
                code
                category {
                  id
                  localizedName(localeId: $localeId)
                  isPrintable
                }
              }
              certs {
                localizedName(localeId: $localeId)
                id
              }
              responsibleConsumptions {
                id
                localizedName(localeId: $localeId)
              }
              grapeVarieties {
                value
                grapeVarietySynonym {
                  id
                  name
                }
              }
              chemicalAnalysis {
                alcoholVolume
              }
            }
            bottleSize {
              id
              volumeML
            }
            packageType {
              id
              isDefault
              shortName
              localizedName(localeId: $localeId)
            }
            bottleImage {
              imageUrl
              thumbnailUrl: imageUrl(transformations: {width: 48, height: 48, resize: fit})
              thumbnailRetinaUrl: imageUrl(
                transformations: {width: 96, height: 96, resize: fit}
              )
            }
            updateUser {
              username
            }
            updatedAt
            shortUrl
            shortResourcePath
            slug
            isPublished
            companyPublications {
              company {
                id
                representativeUser {
                  id
                  username
                }
              }
              isPublished
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMyELabelsPaginationQuery__
 *
 * To run a query within a React component, call `useMyELabelsPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyELabelsPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyELabelsPaginationQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      companyId: // value for 'companyId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMyELabelsPaginationQuery(baseOptions: Apollo.QueryHookOptions<Types.MyELabelsPaginationQuery, Types.MyELabelsPaginationQueryVariables> & ({ variables: Types.MyELabelsPaginationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MyELabelsPaginationQuery, Types.MyELabelsPaginationQueryVariables>(MyELabelsPaginationDocument, options);
      }
export function useMyELabelsPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MyELabelsPaginationQuery, Types.MyELabelsPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MyELabelsPaginationQuery, Types.MyELabelsPaginationQueryVariables>(MyELabelsPaginationDocument, options);
        }
export function useMyELabelsPaginationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MyELabelsPaginationQuery, Types.MyELabelsPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MyELabelsPaginationQuery, Types.MyELabelsPaginationQueryVariables>(MyELabelsPaginationDocument, options);
        }
export type MyELabelsPaginationQueryHookResult = ReturnType<typeof useMyELabelsPaginationQuery>;
export type MyELabelsPaginationLazyQueryHookResult = ReturnType<typeof useMyELabelsPaginationLazyQuery>;
export type MyELabelsPaginationSuspenseQueryHookResult = ReturnType<typeof useMyELabelsPaginationSuspenseQuery>;
export type MyELabelsPaginationQueryResult = Apollo.QueryResult<Types.MyELabelsPaginationQuery, Types.MyELabelsPaginationQueryVariables>;
export const ProductELabelsDeleteDocument = gql`
    mutation productELabelsDelete($input: ProductELabelsDeleteInput!) {
  productELabelsDelete(input: $input) {
    recordIds
  }
}
    `;
export type ProductELabelsDeleteMutationFn = Apollo.MutationFunction<Types.ProductELabelsDeleteMutation, Types.ProductELabelsDeleteMutationVariables>;

/**
 * __useProductELabelsDeleteMutation__
 *
 * To run a mutation, you first call `useProductELabelsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductELabelsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productELabelsDeleteMutation, { data, loading, error }] = useProductELabelsDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductELabelsDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProductELabelsDeleteMutation, Types.ProductELabelsDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductELabelsDeleteMutation, Types.ProductELabelsDeleteMutationVariables>(ProductELabelsDeleteDocument, options);
      }
export type ProductELabelsDeleteMutationHookResult = ReturnType<typeof useProductELabelsDeleteMutation>;
export type ProductELabelsDeleteMutationResult = Apollo.MutationResult<Types.ProductELabelsDeleteMutation>;
export type ProductELabelsDeleteMutationOptions = Apollo.BaseMutationOptions<Types.ProductELabelsDeleteMutation, Types.ProductELabelsDeleteMutationVariables>;
export const ProductELabelsFilterDocument = gql`
    query productELabelsFilter($filterKey: String!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $companyId: ID!, $includeUnusedLabels: Boolean! = true) {
  me {
    company(id: $companyId) {
      producerTradeName {
        productELabelsFilters(
          localeId: $localeId
          filter: {facetsInputs: $facetsInputs}
          query: $query
        ) {
          filterByKey(key: $filterKey) {
            ...facetFilter
          }
        }
      }
    }
  }
}
    ${FacetFilterFragmentDoc}`;

/**
 * __useProductELabelsFilterQuery__
 *
 * To run a query within a React component, call `useProductELabelsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductELabelsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductELabelsFilterQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      companyId: // value for 'companyId'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useProductELabelsFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductELabelsFilterQuery, Types.ProductELabelsFilterQueryVariables> & ({ variables: Types.ProductELabelsFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductELabelsFilterQuery, Types.ProductELabelsFilterQueryVariables>(ProductELabelsFilterDocument, options);
      }
export function useProductELabelsFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductELabelsFilterQuery, Types.ProductELabelsFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductELabelsFilterQuery, Types.ProductELabelsFilterQueryVariables>(ProductELabelsFilterDocument, options);
        }
export function useProductELabelsFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductELabelsFilterQuery, Types.ProductELabelsFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductELabelsFilterQuery, Types.ProductELabelsFilterQueryVariables>(ProductELabelsFilterDocument, options);
        }
export type ProductELabelsFilterQueryHookResult = ReturnType<typeof useProductELabelsFilterQuery>;
export type ProductELabelsFilterLazyQueryHookResult = ReturnType<typeof useProductELabelsFilterLazyQuery>;
export type ProductELabelsFilterSuspenseQueryHookResult = ReturnType<typeof useProductELabelsFilterSuspenseQuery>;
export type ProductELabelsFilterQueryResult = Apollo.QueryResult<Types.ProductELabelsFilterQuery, Types.ProductELabelsFilterQueryVariables>;
export const ProductELabelsFiltersDocument = gql`
    query productELabelsFilters($localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt!, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $companyId: ID!, $includeUnusedLabels: Boolean! = true) {
  me {
    company(id: $companyId) {
      producerTradeName {
        productELabelsFilters(
          localeId: $localeId
          filter: {facetsInputs: $facetsInputs}
          query: $query
        ) {
          count
          countryFilter: filterByKey(key: "wine.country") {
            ...facetListFilter
          }
          creationDateFilter: filterByKey(key: "createdAt") {
            ...facetDateRangeFilter
          }
          lastUpdateFilter: filterByKey(key: "updatedAt") {
            ...facetDateRangeFilter
          }
          updateUserUsernameFilter: filterByKey(key: "updateUserUsername") {
            ...facetFreeListFilter
          }
          alcoholLevelFilter: filterByKey(key: "alcoholOnLabel") {
            ...facetRangeFilter
          }
          brandFilter: filterByKey(key: "wine.brand") {
            ...facetListFilter
          }
          ingredientsFilledFilter: filterByKey(key: "ingredientsFilled") {
            ...facetListFilter
          }
          nutritionDeclarationFilledFilter: filterByKey(key: "nutritionDeclarationFilled") {
            ...facetListFilter
          }
          nationalGITypeFilter: filterByKey(key: "wine.nationalGIType") {
            ...facetListFilter
          }
          specialClassificationFilter: filterByKey(key: "wine.specialClassification") {
            ...facetListFilter
          }
          geoIndicationFilter: filterByKey(key: "wine.geoIndication") {
            ...facetListFilter
          }
          wineTypeFilter: filterByKey(key: "wine.wineType") {
            ...facetListFilter
          }
          wineTypeEUFilter: filterByKey(key: "wine.wineTypeEU") {
            ...facetListFilter
          }
          wineColourFilter: filterByKey(key: "wine.wineColor") {
            ...facetListFilter
          }
          sweetnessTypeFilter: filterByKey(key: "wine.sweetnessLevel") {
            ...facetListFilter
          }
          vintageFilter: filterByKey(key: "wine.vintage") {
            ...facetListFilter
          }
          bottleSizeFilter: filterByKey(key: "bottleSize") {
            ...facetListFilter
          }
          packageTypeFilter: filterByKey(key: "packageType") {
            ...facetListFilter
          }
          additionalInfoFilter: filterByKey(key: "wine.certification") {
            ...facetListFilter
          }
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetDateRangeFilterFragmentDoc}
${FacetFreeListFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}`;

/**
 * __useProductELabelsFiltersQuery__
 *
 * To run a query within a React component, call `useProductELabelsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductELabelsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductELabelsFiltersQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      companyId: // value for 'companyId'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useProductELabelsFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductELabelsFiltersQuery, Types.ProductELabelsFiltersQueryVariables> & ({ variables: Types.ProductELabelsFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductELabelsFiltersQuery, Types.ProductELabelsFiltersQueryVariables>(ProductELabelsFiltersDocument, options);
      }
export function useProductELabelsFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductELabelsFiltersQuery, Types.ProductELabelsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductELabelsFiltersQuery, Types.ProductELabelsFiltersQueryVariables>(ProductELabelsFiltersDocument, options);
        }
export function useProductELabelsFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductELabelsFiltersQuery, Types.ProductELabelsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductELabelsFiltersQuery, Types.ProductELabelsFiltersQueryVariables>(ProductELabelsFiltersDocument, options);
        }
export type ProductELabelsFiltersQueryHookResult = ReturnType<typeof useProductELabelsFiltersQuery>;
export type ProductELabelsFiltersLazyQueryHookResult = ReturnType<typeof useProductELabelsFiltersLazyQuery>;
export type ProductELabelsFiltersSuspenseQueryHookResult = ReturnType<typeof useProductELabelsFiltersSuspenseQuery>;
export type ProductELabelsFiltersQueryResult = Apollo.QueryResult<Types.ProductELabelsFiltersQuery, Types.ProductELabelsFiltersQueryVariables>;
export const UpdateProductELabelDocument = gql`
    mutation updateProductELabel($input: ProductELabelUpdateInput!) {
  productELabelUpdate(input: $input) {
    recordId
    record {
      id
      wine {
        country {
          id
        }
        wineType {
          id
        }
        wineColor {
          id
        }
        brand {
          brand
        }
        keyWords
        tradeName {
          tradeName
        }
        tradeNameBrand
        sweetnessLevel {
          id
        }
        vintage
        ingredients {
          id
        }
        responsibleConsumptions {
          id
        }
        chemicalAnalysis {
          alcoholVolume
        }
        nutritionDeclaration {
          fat
          fatApproximate
          energyKCal
          energyKCalApproximate
          saturates
          saturatesApproximate
          carbohydrate
          carbohydrateApproximate
          sugars
          sugarsApproximate
          protein
          proteinApproximate
          salt
          saltApproximate
        }
        nationalGIType {
          id
        }
        geoIndications {
          id
        }
        specialClassifications {
          id
        }
        wineTypeEU {
          id
        }
        grapeVarieties {
          grapeVarietySynonym {
            id
          }
          value
        }
        certs {
          id
        }
      }
      bottleSize {
        id
        volumeML
      }
      packageType {
        id
        isDefault
        shortName
      }
      bottleImage {
        imageUrl
      }
      updateUser {
        username
      }
    }
  }
}
    `;
export type UpdateProductELabelMutationFn = Apollo.MutationFunction<Types.UpdateProductELabelMutation, Types.UpdateProductELabelMutationVariables>;

/**
 * __useUpdateProductELabelMutation__
 *
 * To run a mutation, you first call `useUpdateProductELabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductELabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductELabelMutation, { data, loading, error }] = useUpdateProductELabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductELabelMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateProductELabelMutation, Types.UpdateProductELabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateProductELabelMutation, Types.UpdateProductELabelMutationVariables>(UpdateProductELabelDocument, options);
      }
export type UpdateProductELabelMutationHookResult = ReturnType<typeof useUpdateProductELabelMutation>;
export type UpdateProductELabelMutationResult = Apollo.MutationResult<Types.UpdateProductELabelMutation>;
export type UpdateProductELabelMutationOptions = Apollo.BaseMutationOptions<Types.UpdateProductELabelMutation, Types.UpdateProductELabelMutationVariables>;
export const MyShortlistedWinesDocument = gql`
    query myShortlistedWines($localeId: Int!, $companyId: ID!, $page: PositiveInt!, $perPage: PositiveInt!, $query: String, $filter: ProductsShortlistFilter, $activeOfferStatusIds: [Int!] = [1], $sort: ProductSort! = UPDATED_AT_DESC) {
  me {
    id
    company(id: $companyId) {
      id
      productsShortlistPagination(
        localeId: $localeId
        page: $page
        perPage: $perPage
        query: $query
        filter: $filter
        sort: $sort
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          totalPages
          totalItems
          page
          perPage
        }
        items {
          ... on Product {
            ...MyWinesProduct
          }
        }
      }
    }
  }
}
    ${MyWinesProductFragmentDoc}`;

/**
 * __useMyShortlistedWinesQuery__
 *
 * To run a query within a React component, call `useMyShortlistedWinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyShortlistedWinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyShortlistedWinesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      companyId: // value for 'companyId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *      activeOfferStatusIds: // value for 'activeOfferStatusIds'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useMyShortlistedWinesQuery(baseOptions: Apollo.QueryHookOptions<Types.MyShortlistedWinesQuery, Types.MyShortlistedWinesQueryVariables> & ({ variables: Types.MyShortlistedWinesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MyShortlistedWinesQuery, Types.MyShortlistedWinesQueryVariables>(MyShortlistedWinesDocument, options);
      }
export function useMyShortlistedWinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MyShortlistedWinesQuery, Types.MyShortlistedWinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MyShortlistedWinesQuery, Types.MyShortlistedWinesQueryVariables>(MyShortlistedWinesDocument, options);
        }
export function useMyShortlistedWinesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MyShortlistedWinesQuery, Types.MyShortlistedWinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MyShortlistedWinesQuery, Types.MyShortlistedWinesQueryVariables>(MyShortlistedWinesDocument, options);
        }
export type MyShortlistedWinesQueryHookResult = ReturnType<typeof useMyShortlistedWinesQuery>;
export type MyShortlistedWinesLazyQueryHookResult = ReturnType<typeof useMyShortlistedWinesLazyQuery>;
export type MyShortlistedWinesSuspenseQueryHookResult = ReturnType<typeof useMyShortlistedWinesSuspenseQuery>;
export type MyShortlistedWinesQueryResult = Apollo.QueryResult<Types.MyShortlistedWinesQuery, Types.MyShortlistedWinesQueryVariables>;
export const MyWinesDocument = gql`
    query myWines($localeId: Int!, $companyId: ID!, $page: PositiveInt!, $perPage: PositiveInt!, $query: String, $filter: ProductsOrWinesFilter, $activeOfferStatusIds: [Int!] = [1]) {
  me {
    id
    company(id: $companyId) {
      id
      producerTradeName {
        id
        productsOrWinesPagination(
          localeId: $localeId
          page: $page
          perPage: $perPage
          query: $query
          filter: $filter
          companyId: $companyId
        ) {
          pageInfo {
            hasPreviousPage
            hasNextPage
            totalPages
            totalItems
            page
            perPage
          }
          items {
            ... on Wine {
              ...MyWinesWine
            }
            ... on Product {
              ...MyWinesWinesProduct
            }
          }
        }
      }
    }
  }
}
    ${MyWinesWineFragmentDoc}
${MyWinesWinesProductFragmentDoc}`;

/**
 * __useMyWinesQuery__
 *
 * To run a query within a React component, call `useMyWinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyWinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyWinesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      companyId: // value for 'companyId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *      activeOfferStatusIds: // value for 'activeOfferStatusIds'
 *   },
 * });
 */
export function useMyWinesQuery(baseOptions: Apollo.QueryHookOptions<Types.MyWinesQuery, Types.MyWinesQueryVariables> & ({ variables: Types.MyWinesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MyWinesQuery, Types.MyWinesQueryVariables>(MyWinesDocument, options);
      }
export function useMyWinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MyWinesQuery, Types.MyWinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MyWinesQuery, Types.MyWinesQueryVariables>(MyWinesDocument, options);
        }
export function useMyWinesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MyWinesQuery, Types.MyWinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MyWinesQuery, Types.MyWinesQueryVariables>(MyWinesDocument, options);
        }
export type MyWinesQueryHookResult = ReturnType<typeof useMyWinesQuery>;
export type MyWinesLazyQueryHookResult = ReturnType<typeof useMyWinesLazyQuery>;
export type MyWinesSuspenseQueryHookResult = ReturnType<typeof useMyWinesSuspenseQuery>;
export type MyWinesQueryResult = Apollo.QueryResult<Types.MyWinesQuery, Types.MyWinesQueryVariables>;
export const MyWinesProductRefuseDocument = gql`
    mutation myWinesProductRefuse($input: ProductRefuseInput!) {
  refuse: productRefuse(input: $input) {
    record: record {
      id
      acceptability
      canOverrideAcceptability
      canOverrideFacts
      shouldOverrideAcceptability
      verified
      fillPercent
      wine {
        id
        acceptability
      }
    }
  }
}
    `;
export type MyWinesProductRefuseMutationFn = Apollo.MutationFunction<Types.MyWinesProductRefuseMutation, Types.MyWinesProductRefuseMutationVariables>;

/**
 * __useMyWinesProductRefuseMutation__
 *
 * To run a mutation, you first call `useMyWinesProductRefuseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMyWinesProductRefuseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [myWinesProductRefuseMutation, { data, loading, error }] = useMyWinesProductRefuseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMyWinesProductRefuseMutation(baseOptions?: Apollo.MutationHookOptions<Types.MyWinesProductRefuseMutation, Types.MyWinesProductRefuseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MyWinesProductRefuseMutation, Types.MyWinesProductRefuseMutationVariables>(MyWinesProductRefuseDocument, options);
      }
export type MyWinesProductRefuseMutationHookResult = ReturnType<typeof useMyWinesProductRefuseMutation>;
export type MyWinesProductRefuseMutationResult = Apollo.MutationResult<Types.MyWinesProductRefuseMutation>;
export type MyWinesProductRefuseMutationOptions = Apollo.BaseMutationOptions<Types.MyWinesProductRefuseMutation, Types.MyWinesProductRefuseMutationVariables>;
export const MyWinesShortlistDocument = gql`
    query myWinesShortlist($localeId: Int!, $companyId: ID!, $page: PositiveInt!, $perPage: PositiveInt!, $query: String, $filter: ProductsShortlistFilter, $activeOfferStatusIds: [Int!] = [1], $sort: ProductSort! = UPDATED_AT_DESC) {
  me {
    id
    company(id: $companyId) {
      id
      productsShortlistPagination(
        localeId: $localeId
        page: $page
        perPage: $perPage
        query: $query
        filter: $filter
        sort: $sort
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          totalPages
          totalItems
          page
          perPage
        }
        items {
          ... on Product {
            ...MyWinesWinesProduct
          }
        }
      }
    }
  }
}
    ${MyWinesWinesProductFragmentDoc}`;

/**
 * __useMyWinesShortlistQuery__
 *
 * To run a query within a React component, call `useMyWinesShortlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyWinesShortlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyWinesShortlistQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      companyId: // value for 'companyId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *      activeOfferStatusIds: // value for 'activeOfferStatusIds'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useMyWinesShortlistQuery(baseOptions: Apollo.QueryHookOptions<Types.MyWinesShortlistQuery, Types.MyWinesShortlistQueryVariables> & ({ variables: Types.MyWinesShortlistQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MyWinesShortlistQuery, Types.MyWinesShortlistQueryVariables>(MyWinesShortlistDocument, options);
      }
export function useMyWinesShortlistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MyWinesShortlistQuery, Types.MyWinesShortlistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MyWinesShortlistQuery, Types.MyWinesShortlistQueryVariables>(MyWinesShortlistDocument, options);
        }
export function useMyWinesShortlistSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MyWinesShortlistQuery, Types.MyWinesShortlistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MyWinesShortlistQuery, Types.MyWinesShortlistQueryVariables>(MyWinesShortlistDocument, options);
        }
export type MyWinesShortlistQueryHookResult = ReturnType<typeof useMyWinesShortlistQuery>;
export type MyWinesShortlistLazyQueryHookResult = ReturnType<typeof useMyWinesShortlistLazyQuery>;
export type MyWinesShortlistSuspenseQueryHookResult = ReturnType<typeof useMyWinesShortlistSuspenseQuery>;
export type MyWinesShortlistQueryResult = Apollo.QueryResult<Types.MyWinesShortlistQuery, Types.MyWinesShortlistQueryVariables>;
export const MyWinesWineRefuseDocument = gql`
    mutation myWinesWineRefuse($input: WineRefuseInput!) {
  refuse: wineRefuse(input: $input) {
    record {
      id
      acceptability
      canOverrideAcceptability
      canOverrideFacts
      shouldOverrideAcceptability
      verified
      fillPercent
    }
  }
}
    `;
export type MyWinesWineRefuseMutationFn = Apollo.MutationFunction<Types.MyWinesWineRefuseMutation, Types.MyWinesWineRefuseMutationVariables>;

/**
 * __useMyWinesWineRefuseMutation__
 *
 * To run a mutation, you first call `useMyWinesWineRefuseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMyWinesWineRefuseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [myWinesWineRefuseMutation, { data, loading, error }] = useMyWinesWineRefuseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMyWinesWineRefuseMutation(baseOptions?: Apollo.MutationHookOptions<Types.MyWinesWineRefuseMutation, Types.MyWinesWineRefuseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MyWinesWineRefuseMutation, Types.MyWinesWineRefuseMutationVariables>(MyWinesWineRefuseDocument, options);
      }
export type MyWinesWineRefuseMutationHookResult = ReturnType<typeof useMyWinesWineRefuseMutation>;
export type MyWinesWineRefuseMutationResult = Apollo.MutationResult<Types.MyWinesWineRefuseMutation>;
export type MyWinesWineRefuseMutationOptions = Apollo.BaseMutationOptions<Types.MyWinesWineRefuseMutation, Types.MyWinesWineRefuseMutationVariables>;
export const ProductAcceptDocument = gql`
    mutation productAccept($input: ProductAcceptInput!) {
  accept: productAccept(input: $input) {
    record {
      id
      acceptability
      canOverrideAcceptability
      canOverrideFacts
      shouldOverrideAcceptability
      verified
    }
  }
}
    `;
export type ProductAcceptMutationFn = Apollo.MutationFunction<Types.ProductAcceptMutation, Types.ProductAcceptMutationVariables>;

/**
 * __useProductAcceptMutation__
 *
 * To run a mutation, you first call `useProductAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productAcceptMutation, { data, loading, error }] = useProductAcceptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductAcceptMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProductAcceptMutation, Types.ProductAcceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductAcceptMutation, Types.ProductAcceptMutationVariables>(ProductAcceptDocument, options);
      }
export type ProductAcceptMutationHookResult = ReturnType<typeof useProductAcceptMutation>;
export type ProductAcceptMutationResult = Apollo.MutationResult<Types.ProductAcceptMutation>;
export type ProductAcceptMutationOptions = Apollo.BaseMutationOptions<Types.ProductAcceptMutation, Types.ProductAcceptMutationVariables>;
export const ProductsOrWinesFilterDocument = gql`
    query productsOrWinesFilter($filterKey: String!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $companyId: ID!, $includeUnusedLabels: Boolean! = true, $onlyAccepted: Boolean! = false) {
  me {
    id
    company(id: $companyId) {
      id
      producerTradeName {
        id
        productsOrWinesFilters(
          localeId: $localeId
          filter: {facetsInputs: $facetsInputs, onlyAccepted: $onlyAccepted}
          query: $query
        ) {
          filterByKey(key: $filterKey) {
            ...facetFilter
          }
        }
      }
    }
  }
}
    ${FacetFilterFragmentDoc}`;

/**
 * __useProductsOrWinesFilterQuery__
 *
 * To run a query within a React component, call `useProductsOrWinesFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsOrWinesFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsOrWinesFilterQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      companyId: // value for 'companyId'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *      onlyAccepted: // value for 'onlyAccepted'
 *   },
 * });
 */
export function useProductsOrWinesFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductsOrWinesFilterQuery, Types.ProductsOrWinesFilterQueryVariables> & ({ variables: Types.ProductsOrWinesFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductsOrWinesFilterQuery, Types.ProductsOrWinesFilterQueryVariables>(ProductsOrWinesFilterDocument, options);
      }
export function useProductsOrWinesFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductsOrWinesFilterQuery, Types.ProductsOrWinesFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductsOrWinesFilterQuery, Types.ProductsOrWinesFilterQueryVariables>(ProductsOrWinesFilterDocument, options);
        }
export function useProductsOrWinesFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductsOrWinesFilterQuery, Types.ProductsOrWinesFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductsOrWinesFilterQuery, Types.ProductsOrWinesFilterQueryVariables>(ProductsOrWinesFilterDocument, options);
        }
export type ProductsOrWinesFilterQueryHookResult = ReturnType<typeof useProductsOrWinesFilterQuery>;
export type ProductsOrWinesFilterLazyQueryHookResult = ReturnType<typeof useProductsOrWinesFilterLazyQuery>;
export type ProductsOrWinesFilterSuspenseQueryHookResult = ReturnType<typeof useProductsOrWinesFilterSuspenseQuery>;
export type ProductsOrWinesFilterQueryResult = Apollo.QueryResult<Types.ProductsOrWinesFilterQuery, Types.ProductsOrWinesFilterQueryVariables>;
export const ProductsOrWinesFiltersDocument = gql`
    query productsOrWinesFilters($localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt!, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $companyId: ID!, $includeUnusedLabels: Boolean! = true, $onlyAccepted: Boolean! = false) {
  me {
    id
    company(id: $companyId) {
      id
      producerTradeName {
        id
        productsOrWinesFilters(
          localeId: $localeId
          filter: {facetsInputs: $facetsInputs, onlyAccepted: $onlyAccepted}
          query: $query
          companyId: $companyId
        ) {
          count
          showLatestVintagesFilter: filterByKey(key: "onlyLatestVintage") {
            ...facetBooleanFilter
          }
          offerTypeFilter: filterByKey(key: "sellerHasOffer") {
            ...facetListFilter
          }
          brandFilter: filterByKey(key: "wine.brand") {
            ...facetListFilter
          }
          nationalGITypeFilter: filterByKey(key: "wine.nationalGIType") {
            ...facetListFilter
          }
          geoIndicationFilter: filterByKey(key: "wine.geoIndication") {
            ...facetListFilter
          }
          specialClassificationFilter: filterByKey(key: "wine.specialClassification") {
            ...facetListFilter
          }
          wineTypeFilter: filterByKey(key: "wine.wineType") {
            ...facetListFilter
          }
          wineColourFilter: filterByKey(key: "wine.wineColor") {
            ...facetListFilter
          }
          sweetnessTypeFilter: filterByKey(key: "wine.sweetnessLevel") {
            ...facetListFilter
          }
          vintageFilter: filterByKey(key: "wine.vintage") {
            ...facetListFilter
          }
          bottleSizeFilter: filterByKey(key: "bottleSize") {
            ...facetListFilter
          }
          packageTypeFilter: filterByKey(key: "packageType") {
            ...facetListFilter
          }
          grapesFilter: filterByKey(key: "grapeAndMonocepage") {
            ...facetComplexFilter
          }
          monocepageFilter: filterByKey(key: "wine.monocepage") {
            ...facetListFilter
          }
          certificationFilter: filterByKey(key: "wine.certification") {
            ...facetListFilter
          }
          wwlRatingFilter: filterByKey(key: "wine.rating") {
            ...facetListFilter
          }
          reviewsFilter: filterByKey(key: "havePublicReviewsBool") {
            ...facetListFilter
          }
          invalidRecordFilter: filterByKey(key: "invalidRecord") {
            ...facetListFilter
          }
          alcoholLevelFilter: filterByKey(key: "alcoholOnLabel") {
            ...facetRangeFilter
          }
        }
      }
    }
  }
}
    ${FacetBooleanFilterFragmentDoc}
${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}`;

/**
 * __useProductsOrWinesFiltersQuery__
 *
 * To run a query within a React component, call `useProductsOrWinesFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsOrWinesFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsOrWinesFiltersQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      companyId: // value for 'companyId'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *      onlyAccepted: // value for 'onlyAccepted'
 *   },
 * });
 */
export function useProductsOrWinesFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductsOrWinesFiltersQuery, Types.ProductsOrWinesFiltersQueryVariables> & ({ variables: Types.ProductsOrWinesFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductsOrWinesFiltersQuery, Types.ProductsOrWinesFiltersQueryVariables>(ProductsOrWinesFiltersDocument, options);
      }
export function useProductsOrWinesFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductsOrWinesFiltersQuery, Types.ProductsOrWinesFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductsOrWinesFiltersQuery, Types.ProductsOrWinesFiltersQueryVariables>(ProductsOrWinesFiltersDocument, options);
        }
export function useProductsOrWinesFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductsOrWinesFiltersQuery, Types.ProductsOrWinesFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductsOrWinesFiltersQuery, Types.ProductsOrWinesFiltersQueryVariables>(ProductsOrWinesFiltersDocument, options);
        }
export type ProductsOrWinesFiltersQueryHookResult = ReturnType<typeof useProductsOrWinesFiltersQuery>;
export type ProductsOrWinesFiltersLazyQueryHookResult = ReturnType<typeof useProductsOrWinesFiltersLazyQuery>;
export type ProductsOrWinesFiltersSuspenseQueryHookResult = ReturnType<typeof useProductsOrWinesFiltersSuspenseQuery>;
export type ProductsOrWinesFiltersQueryResult = Apollo.QueryResult<Types.ProductsOrWinesFiltersQuery, Types.ProductsOrWinesFiltersQueryVariables>;
export const ProductsShortlistFilterDocument = gql`
    query productsShortlistFilter($filterKey: String!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $companyId: ID!, $includeUnusedLabels: Boolean! = true, $onlyAccepted: Boolean! = false) {
  me {
    id
    company(id: $companyId) {
      productsShortlistFilters(
        localeId: $localeId
        filter: {facetsInputs: $facetsInputs, onlyAccepted: $onlyAccepted}
        query: $query
      ) {
        filterByKey(key: $filterKey) {
          ...facetFilter
        }
      }
    }
  }
}
    ${FacetFilterFragmentDoc}`;

/**
 * __useProductsShortlistFilterQuery__
 *
 * To run a query within a React component, call `useProductsShortlistFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsShortlistFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsShortlistFilterQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      companyId: // value for 'companyId'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *      onlyAccepted: // value for 'onlyAccepted'
 *   },
 * });
 */
export function useProductsShortlistFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductsShortlistFilterQuery, Types.ProductsShortlistFilterQueryVariables> & ({ variables: Types.ProductsShortlistFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductsShortlistFilterQuery, Types.ProductsShortlistFilterQueryVariables>(ProductsShortlistFilterDocument, options);
      }
export function useProductsShortlistFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductsShortlistFilterQuery, Types.ProductsShortlistFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductsShortlistFilterQuery, Types.ProductsShortlistFilterQueryVariables>(ProductsShortlistFilterDocument, options);
        }
export function useProductsShortlistFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductsShortlistFilterQuery, Types.ProductsShortlistFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductsShortlistFilterQuery, Types.ProductsShortlistFilterQueryVariables>(ProductsShortlistFilterDocument, options);
        }
export type ProductsShortlistFilterQueryHookResult = ReturnType<typeof useProductsShortlistFilterQuery>;
export type ProductsShortlistFilterLazyQueryHookResult = ReturnType<typeof useProductsShortlistFilterLazyQuery>;
export type ProductsShortlistFilterSuspenseQueryHookResult = ReturnType<typeof useProductsShortlistFilterSuspenseQuery>;
export type ProductsShortlistFilterQueryResult = Apollo.QueryResult<Types.ProductsShortlistFilterQuery, Types.ProductsShortlistFilterQueryVariables>;
export const ShortlistFiltersDocument = gql`
    query shortlistFilters($localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt!, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $companyId: ID!, $includeUnusedLabels: Boolean! = true, $onlyAccepted: Boolean! = false) {
  me {
    id
    company(id: $companyId) {
      id
      productsShortlistFilters(
        localeId: $localeId
        filter: {facetsInputs: $facetsInputs, onlyAccepted: $onlyAccepted}
        query: $query
      ) {
        count
        offerTypeFilter: filterByKey(key: "sellerHasOffer") {
          ...facetListFilter
        }
        alcoholLevelFilter: filterByKey(key: "alcoholOnLabel") {
          ...facetRangeFilter
        }
        showLatestVintagesFilter: filterByKey(key: "onlyLatestVintage") {
          ...facetBooleanFilter
        }
        brandFilter: filterByKey(key: "wine.brand") {
          ...facetListFilter
        }
        nationalGITypeFilter: filterByKey(key: "wine.nationalGIType") {
          ...facetListFilter
        }
        geoIndicationFilter: filterByKey(key: "wine.geoIndication") {
          ...facetListFilter
        }
        specialClassificationFilter: filterByKey(key: "wine.specialClassification") {
          ...facetListFilter
        }
        wineTypeFilter: filterByKey(key: "wine.wineType") {
          ...facetListFilter
        }
        wineColourFilter: filterByKey(key: "wine.wineColor") {
          ...facetListFilter
        }
        sweetnessTypeFilter: filterByKey(key: "wine.sweetnessLevel") {
          ...facetListFilter
        }
        vintageFilter: filterByKey(key: "wine.vintage") {
          ...facetListFilter
        }
        bottleSizeFilter: filterByKey(key: "bottleSize") {
          ...facetListFilter
        }
        packageTypeFilter: filterByKey(key: "packageType") {
          ...facetListFilter
        }
        grapesFilter: filterByKey(key: "grapeAndMonocepage") {
          ...facetComplexFilter
        }
        monocepageFilter: filterByKey(key: "wine.monocepage") {
          ...facetListFilter
        }
        certificationFilter: filterByKey(key: "wine.certification") {
          ...facetListFilter
        }
        wwlRatingFilter: filterByKey(key: "wine.rating") {
          ...facetListFilter
        }
        reviewsFilter: filterByKey(key: "havePublicReviewsBool") {
          ...facetListFilter
        }
        invalidRecordFilter: filterByKey(key: "invalidRecord") {
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}
${FacetBooleanFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}`;

/**
 * __useShortlistFiltersQuery__
 *
 * To run a query within a React component, call `useShortlistFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useShortlistFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShortlistFiltersQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      companyId: // value for 'companyId'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *      onlyAccepted: // value for 'onlyAccepted'
 *   },
 * });
 */
export function useShortlistFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.ShortlistFiltersQuery, Types.ShortlistFiltersQueryVariables> & ({ variables: Types.ShortlistFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ShortlistFiltersQuery, Types.ShortlistFiltersQueryVariables>(ShortlistFiltersDocument, options);
      }
export function useShortlistFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ShortlistFiltersQuery, Types.ShortlistFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ShortlistFiltersQuery, Types.ShortlistFiltersQueryVariables>(ShortlistFiltersDocument, options);
        }
export function useShortlistFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ShortlistFiltersQuery, Types.ShortlistFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ShortlistFiltersQuery, Types.ShortlistFiltersQueryVariables>(ShortlistFiltersDocument, options);
        }
export type ShortlistFiltersQueryHookResult = ReturnType<typeof useShortlistFiltersQuery>;
export type ShortlistFiltersLazyQueryHookResult = ReturnType<typeof useShortlistFiltersLazyQuery>;
export type ShortlistFiltersSuspenseQueryHookResult = ReturnType<typeof useShortlistFiltersSuspenseQuery>;
export type ShortlistFiltersQueryResult = Apollo.QueryResult<Types.ShortlistFiltersQuery, Types.ShortlistFiltersQueryVariables>;
export const MyWinesWineAcceptDocument = gql`
    mutation myWinesWineAccept($input: WineAcceptInput!) {
  accept: wineAccept(input: $input) {
    record {
      id
      acceptability
      canOverrideAcceptability
      canOverrideFacts
      shouldOverrideAcceptability
      verified
    }
  }
}
    `;
export type MyWinesWineAcceptMutationFn = Apollo.MutationFunction<Types.MyWinesWineAcceptMutation, Types.MyWinesWineAcceptMutationVariables>;

/**
 * __useMyWinesWineAcceptMutation__
 *
 * To run a mutation, you first call `useMyWinesWineAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMyWinesWineAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [myWinesWineAcceptMutation, { data, loading, error }] = useMyWinesWineAcceptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMyWinesWineAcceptMutation(baseOptions?: Apollo.MutationHookOptions<Types.MyWinesWineAcceptMutation, Types.MyWinesWineAcceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MyWinesWineAcceptMutation, Types.MyWinesWineAcceptMutationVariables>(MyWinesWineAcceptDocument, options);
      }
export type MyWinesWineAcceptMutationHookResult = ReturnType<typeof useMyWinesWineAcceptMutation>;
export type MyWinesWineAcceptMutationResult = Apollo.MutationResult<Types.MyWinesWineAcceptMutation>;
export type MyWinesWineAcceptMutationOptions = Apollo.BaseMutationOptions<Types.MyWinesWineAcceptMutation, Types.MyWinesWineAcceptMutationVariables>;
export const WineOffersAcceptDocument = gql`
    mutation wineOffersAccept($companyId: ID!, $wineOfferIds: [Int!]!) {
  wineOffersAccept(input: {companyId: $companyId, wineOfferIds: $wineOfferIds}) {
    records {
      id
      hasRead(companyId: $companyId)
      wineOfferType {
        id
        code
      }
      wineOfferStatus {
        id
        code
      }
    }
  }
}
    `;
export type WineOffersAcceptMutationFn = Apollo.MutationFunction<Types.WineOffersAcceptMutation, Types.WineOffersAcceptMutationVariables>;

/**
 * __useWineOffersAcceptMutation__
 *
 * To run a mutation, you first call `useWineOffersAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOffersAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOffersAcceptMutation, { data, loading, error }] = useWineOffersAcceptMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      wineOfferIds: // value for 'wineOfferIds'
 *   },
 * });
 */
export function useWineOffersAcceptMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOffersAcceptMutation, Types.WineOffersAcceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOffersAcceptMutation, Types.WineOffersAcceptMutationVariables>(WineOffersAcceptDocument, options);
      }
export type WineOffersAcceptMutationHookResult = ReturnType<typeof useWineOffersAcceptMutation>;
export type WineOffersAcceptMutationResult = Apollo.MutationResult<Types.WineOffersAcceptMutation>;
export type WineOffersAcceptMutationOptions = Apollo.BaseMutationOptions<Types.WineOffersAcceptMutation, Types.WineOffersAcceptMutationVariables>;
export const ProductsOrWinesToOfferDocument = gql`
    query productsOrWinesToOffer($localeId: Int!, $companyId: ID!, $query: String!, $limit: Int! = 20) {
  me {
    id
    company(id: $companyId) {
      id
      productsOrWinesToOffer(localeId: $localeId, limit: $limit, query: $query) {
        items {
          ...searchModalProduct
          ...searchModalWine
        }
      }
    }
  }
}
    ${SearchModalProductFragmentDoc}
${SearchModalWineFragmentDoc}`;

/**
 * __useProductsOrWinesToOfferQuery__
 *
 * To run a query within a React component, call `useProductsOrWinesToOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsOrWinesToOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsOrWinesToOfferQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      companyId: // value for 'companyId'
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProductsOrWinesToOfferQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductsOrWinesToOfferQuery, Types.ProductsOrWinesToOfferQueryVariables> & ({ variables: Types.ProductsOrWinesToOfferQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductsOrWinesToOfferQuery, Types.ProductsOrWinesToOfferQueryVariables>(ProductsOrWinesToOfferDocument, options);
      }
export function useProductsOrWinesToOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductsOrWinesToOfferQuery, Types.ProductsOrWinesToOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductsOrWinesToOfferQuery, Types.ProductsOrWinesToOfferQueryVariables>(ProductsOrWinesToOfferDocument, options);
        }
export function useProductsOrWinesToOfferSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductsOrWinesToOfferQuery, Types.ProductsOrWinesToOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductsOrWinesToOfferQuery, Types.ProductsOrWinesToOfferQueryVariables>(ProductsOrWinesToOfferDocument, options);
        }
export type ProductsOrWinesToOfferQueryHookResult = ReturnType<typeof useProductsOrWinesToOfferQuery>;
export type ProductsOrWinesToOfferLazyQueryHookResult = ReturnType<typeof useProductsOrWinesToOfferLazyQuery>;
export type ProductsOrWinesToOfferSuspenseQueryHookResult = ReturnType<typeof useProductsOrWinesToOfferSuspenseQuery>;
export type ProductsOrWinesToOfferQueryResult = Apollo.QueryResult<Types.ProductsOrWinesToOfferQuery, Types.ProductsOrWinesToOfferQueryVariables>;
export const CheckVatForPaymentDocument = gql`
    query checkVatForPayment($value: String!) {
  legalEntityByVAT(vat: $value) {
    vat
  }
}
    `;

/**
 * __useCheckVatForPaymentQuery__
 *
 * To run a query within a React component, call `useCheckVatForPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckVatForPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckVatForPaymentQuery({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useCheckVatForPaymentQuery(baseOptions: Apollo.QueryHookOptions<Types.CheckVatForPaymentQuery, Types.CheckVatForPaymentQueryVariables> & ({ variables: Types.CheckVatForPaymentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckVatForPaymentQuery, Types.CheckVatForPaymentQueryVariables>(CheckVatForPaymentDocument, options);
      }
export function useCheckVatForPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckVatForPaymentQuery, Types.CheckVatForPaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckVatForPaymentQuery, Types.CheckVatForPaymentQueryVariables>(CheckVatForPaymentDocument, options);
        }
export function useCheckVatForPaymentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CheckVatForPaymentQuery, Types.CheckVatForPaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CheckVatForPaymentQuery, Types.CheckVatForPaymentQueryVariables>(CheckVatForPaymentDocument, options);
        }
export type CheckVatForPaymentQueryHookResult = ReturnType<typeof useCheckVatForPaymentQuery>;
export type CheckVatForPaymentLazyQueryHookResult = ReturnType<typeof useCheckVatForPaymentLazyQuery>;
export type CheckVatForPaymentSuspenseQueryHookResult = ReturnType<typeof useCheckVatForPaymentSuspenseQuery>;
export type CheckVatForPaymentQueryResult = Apollo.QueryResult<Types.CheckVatForPaymentQuery, Types.CheckVatForPaymentQueryVariables>;
export const CompanyInformationForStripeDocument = gql`
    query companyInformationForStripe($id: ID!) {
  company(id: $id) {
    id
    postCountry {
      id
      codeISO
    }
    postAddress
    postCode
    email
    tradeName
    legalEntity {
      id
      vat
      legalEntityForm {
        id
        shortName
      }
      country {
        id
        codeISO
      }
      legalName
      legalAddress
    }
  }
}
    `;

/**
 * __useCompanyInformationForStripeQuery__
 *
 * To run a query within a React component, call `useCompanyInformationForStripeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyInformationForStripeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyInformationForStripeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyInformationForStripeQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyInformationForStripeQuery, Types.CompanyInformationForStripeQueryVariables> & ({ variables: Types.CompanyInformationForStripeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyInformationForStripeQuery, Types.CompanyInformationForStripeQueryVariables>(CompanyInformationForStripeDocument, options);
      }
export function useCompanyInformationForStripeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyInformationForStripeQuery, Types.CompanyInformationForStripeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyInformationForStripeQuery, Types.CompanyInformationForStripeQueryVariables>(CompanyInformationForStripeDocument, options);
        }
export function useCompanyInformationForStripeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CompanyInformationForStripeQuery, Types.CompanyInformationForStripeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompanyInformationForStripeQuery, Types.CompanyInformationForStripeQueryVariables>(CompanyInformationForStripeDocument, options);
        }
export type CompanyInformationForStripeQueryHookResult = ReturnType<typeof useCompanyInformationForStripeQuery>;
export type CompanyInformationForStripeLazyQueryHookResult = ReturnType<typeof useCompanyInformationForStripeLazyQuery>;
export type CompanyInformationForStripeSuspenseQueryHookResult = ReturnType<typeof useCompanyInformationForStripeSuspenseQuery>;
export type CompanyInformationForStripeQueryResult = Apollo.QueryResult<Types.CompanyInformationForStripeQuery, Types.CompanyInformationForStripeQueryVariables>;
export const ProductELabelPricesDocument = gql`
    query productELabelPrices {
  productELabelPrices {
    id
    quantity
    interval
    trial
    currency {
      id
      code
      symbol
    }
    price
  }
}
    `;

/**
 * __useProductELabelPricesQuery__
 *
 * To run a query within a React component, call `useProductELabelPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductELabelPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductELabelPricesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductELabelPricesQuery(baseOptions?: Apollo.QueryHookOptions<Types.ProductELabelPricesQuery, Types.ProductELabelPricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductELabelPricesQuery, Types.ProductELabelPricesQueryVariables>(ProductELabelPricesDocument, options);
      }
export function useProductELabelPricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductELabelPricesQuery, Types.ProductELabelPricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductELabelPricesQuery, Types.ProductELabelPricesQueryVariables>(ProductELabelPricesDocument, options);
        }
export function useProductELabelPricesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductELabelPricesQuery, Types.ProductELabelPricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductELabelPricesQuery, Types.ProductELabelPricesQueryVariables>(ProductELabelPricesDocument, options);
        }
export type ProductELabelPricesQueryHookResult = ReturnType<typeof useProductELabelPricesQuery>;
export type ProductELabelPricesLazyQueryHookResult = ReturnType<typeof useProductELabelPricesLazyQuery>;
export type ProductELabelPricesSuspenseQueryHookResult = ReturnType<typeof useProductELabelPricesSuspenseQuery>;
export type ProductELabelPricesQueryResult = Apollo.QueryResult<Types.ProductELabelPricesQuery, Types.ProductELabelPricesQueryVariables>;
export const ProductELabelSubscriptionActivateDocument = gql`
    mutation productELabelSubscriptionActivate($input: ProductElabelSubscriptionActivateInput!) {
  productELabelSubscriptionActivate(input: $input) {
    clientSecret
    trialing
    intentType
  }
}
    `;
export type ProductELabelSubscriptionActivateMutationFn = Apollo.MutationFunction<Types.ProductELabelSubscriptionActivateMutation, Types.ProductELabelSubscriptionActivateMutationVariables>;

/**
 * __useProductELabelSubscriptionActivateMutation__
 *
 * To run a mutation, you first call `useProductELabelSubscriptionActivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductELabelSubscriptionActivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productELabelSubscriptionActivateMutation, { data, loading, error }] = useProductELabelSubscriptionActivateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductELabelSubscriptionActivateMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProductELabelSubscriptionActivateMutation, Types.ProductELabelSubscriptionActivateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductELabelSubscriptionActivateMutation, Types.ProductELabelSubscriptionActivateMutationVariables>(ProductELabelSubscriptionActivateDocument, options);
      }
export type ProductELabelSubscriptionActivateMutationHookResult = ReturnType<typeof useProductELabelSubscriptionActivateMutation>;
export type ProductELabelSubscriptionActivateMutationResult = Apollo.MutationResult<Types.ProductELabelSubscriptionActivateMutation>;
export type ProductELabelSubscriptionActivateMutationOptions = Apollo.BaseMutationOptions<Types.ProductELabelSubscriptionActivateMutation, Types.ProductELabelSubscriptionActivateMutationVariables>;
export const ProductELabelSubscriptionPortalCreateDocument = gql`
    mutation productELabelSubscriptionPortalCreate($companyId: ID!, $returnUrl: URL!) {
  productELabelSubscriptionPortalCreate(
    input: {companyId: $companyId, returnUrl: $returnUrl}
  )
}
    `;
export type ProductELabelSubscriptionPortalCreateMutationFn = Apollo.MutationFunction<Types.ProductELabelSubscriptionPortalCreateMutation, Types.ProductELabelSubscriptionPortalCreateMutationVariables>;

/**
 * __useProductELabelSubscriptionPortalCreateMutation__
 *
 * To run a mutation, you first call `useProductELabelSubscriptionPortalCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductELabelSubscriptionPortalCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productELabelSubscriptionPortalCreateMutation, { data, loading, error }] = useProductELabelSubscriptionPortalCreateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      returnUrl: // value for 'returnUrl'
 *   },
 * });
 */
export function useProductELabelSubscriptionPortalCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProductELabelSubscriptionPortalCreateMutation, Types.ProductELabelSubscriptionPortalCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductELabelSubscriptionPortalCreateMutation, Types.ProductELabelSubscriptionPortalCreateMutationVariables>(ProductELabelSubscriptionPortalCreateDocument, options);
      }
export type ProductELabelSubscriptionPortalCreateMutationHookResult = ReturnType<typeof useProductELabelSubscriptionPortalCreateMutation>;
export type ProductELabelSubscriptionPortalCreateMutationResult = Apollo.MutationResult<Types.ProductELabelSubscriptionPortalCreateMutation>;
export type ProductELabelSubscriptionPortalCreateMutationOptions = Apollo.BaseMutationOptions<Types.ProductELabelSubscriptionPortalCreateMutation, Types.ProductELabelSubscriptionPortalCreateMutationVariables>;
export const SubscriptionInfoDocument = gql`
    query subscriptionInfo($id: ID!) {
  me {
    id
    company(id: $id) {
      id
      productELabelSubscription {
        quota
        usedQuota
        currency {
          id
          symbol
        }
        interval
        periodStart
        periodEnd
        price
        status
      }
    }
  }
}
    `;

/**
 * __useSubscriptionInfoQuery__
 *
 * To run a query within a React component, call `useSubscriptionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscriptionInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.SubscriptionInfoQuery, Types.SubscriptionInfoQueryVariables> & ({ variables: Types.SubscriptionInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SubscriptionInfoQuery, Types.SubscriptionInfoQueryVariables>(SubscriptionInfoDocument, options);
      }
export function useSubscriptionInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SubscriptionInfoQuery, Types.SubscriptionInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SubscriptionInfoQuery, Types.SubscriptionInfoQueryVariables>(SubscriptionInfoDocument, options);
        }
export function useSubscriptionInfoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SubscriptionInfoQuery, Types.SubscriptionInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SubscriptionInfoQuery, Types.SubscriptionInfoQueryVariables>(SubscriptionInfoDocument, options);
        }
export type SubscriptionInfoQueryHookResult = ReturnType<typeof useSubscriptionInfoQuery>;
export type SubscriptionInfoLazyQueryHookResult = ReturnType<typeof useSubscriptionInfoLazyQuery>;
export type SubscriptionInfoSuspenseQueryHookResult = ReturnType<typeof useSubscriptionInfoSuspenseQuery>;
export type SubscriptionInfoQueryResult = Apollo.QueryResult<Types.SubscriptionInfoQuery, Types.SubscriptionInfoQueryVariables>;
export const FilterPresetDeleteDocument = gql`
    mutation filterPresetDelete($id: ID!) {
  filterPresetDelete(id: $id) {
    recordId
  }
}
    `;
export type FilterPresetDeleteMutationFn = Apollo.MutationFunction<Types.FilterPresetDeleteMutation, Types.FilterPresetDeleteMutationVariables>;

/**
 * __useFilterPresetDeleteMutation__
 *
 * To run a mutation, you first call `useFilterPresetDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFilterPresetDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [filterPresetDeleteMutation, { data, loading, error }] = useFilterPresetDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFilterPresetDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.FilterPresetDeleteMutation, Types.FilterPresetDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.FilterPresetDeleteMutation, Types.FilterPresetDeleteMutationVariables>(FilterPresetDeleteDocument, options);
      }
export type FilterPresetDeleteMutationHookResult = ReturnType<typeof useFilterPresetDeleteMutation>;
export type FilterPresetDeleteMutationResult = Apollo.MutationResult<Types.FilterPresetDeleteMutation>;
export type FilterPresetDeleteMutationOptions = Apollo.BaseMutationOptions<Types.FilterPresetDeleteMutation, Types.FilterPresetDeleteMutationVariables>;
export const FilterPresetFavoriteDocument = gql`
    mutation filterPresetFavorite($id: ID!) {
  filterPresetFavorite(id: $id) {
    recordId
    record {
      id
      isFavorite
    }
  }
}
    `;
export type FilterPresetFavoriteMutationFn = Apollo.MutationFunction<Types.FilterPresetFavoriteMutation, Types.FilterPresetFavoriteMutationVariables>;

/**
 * __useFilterPresetFavoriteMutation__
 *
 * To run a mutation, you first call `useFilterPresetFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFilterPresetFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [filterPresetFavoriteMutation, { data, loading, error }] = useFilterPresetFavoriteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFilterPresetFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<Types.FilterPresetFavoriteMutation, Types.FilterPresetFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.FilterPresetFavoriteMutation, Types.FilterPresetFavoriteMutationVariables>(FilterPresetFavoriteDocument, options);
      }
export type FilterPresetFavoriteMutationHookResult = ReturnType<typeof useFilterPresetFavoriteMutation>;
export type FilterPresetFavoriteMutationResult = Apollo.MutationResult<Types.FilterPresetFavoriteMutation>;
export type FilterPresetFavoriteMutationOptions = Apollo.BaseMutationOptions<Types.FilterPresetFavoriteMutation, Types.FilterPresetFavoriteMutationVariables>;
export const FilterPresetSaveDocument = gql`
    mutation filterPresetSave($input: FilterPresetSaveInput!, $localeId: Int!) {
  filterPresetSave(input: $input) {
    query {
      _
    }
    recordId
    record {
      id
      name
      entity: page
      isFavorite
      filters {
        ... on FacetListFilterSelection {
          key
          byName(localeId: $localeId)
        }
        ... on FacetFreeListFilterSelection {
          key
          byName
        }
        ... on FacetRangeFilterSelection {
          key
          minValue
          maxValue
        }
        ... on FacetBooleanFilterSelection {
          key
          value
        }
        ... on FacetDateRangeFilterSelection {
          key
          fromDate
          toDate
        }
        ... on FacetComplexFilterSelection {
          key
          childrenInputs {
            ... on FacetBooleanFilterSelection {
              key
              value
            }
            ... on FacetListFilterSelection {
              key
              byName(localeId: $localeId)
            }
            ... on FacetRangeFilterSelection {
              key
              minValue
              maxValue
            }
          }
        }
      }
    }
  }
}
    `;
export type FilterPresetSaveMutationFn = Apollo.MutationFunction<Types.FilterPresetSaveMutation, Types.FilterPresetSaveMutationVariables>;

/**
 * __useFilterPresetSaveMutation__
 *
 * To run a mutation, you first call `useFilterPresetSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFilterPresetSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [filterPresetSaveMutation, { data, loading, error }] = useFilterPresetSaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useFilterPresetSaveMutation(baseOptions?: Apollo.MutationHookOptions<Types.FilterPresetSaveMutation, Types.FilterPresetSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.FilterPresetSaveMutation, Types.FilterPresetSaveMutationVariables>(FilterPresetSaveDocument, options);
      }
export type FilterPresetSaveMutationHookResult = ReturnType<typeof useFilterPresetSaveMutation>;
export type FilterPresetSaveMutationResult = Apollo.MutationResult<Types.FilterPresetSaveMutation>;
export type FilterPresetSaveMutationOptions = Apollo.BaseMutationOptions<Types.FilterPresetSaveMutation, Types.FilterPresetSaveMutationVariables>;
export const GetMyPagePresetsDocument = gql`
    query getMyPagePresets($page: String, $localeId: Int!) {
  me {
    id
    filterPresets(page: $page) {
      id
      name
      entity
      isFavorite
      filters {
        ... on FacetListFilterSelection {
          key
          byName(localeId: $localeId)
        }
        ... on FacetFreeListFilterSelection {
          key
          byName
        }
        ... on FacetRangeFilterSelection {
          key
          minValue
          maxValue
        }
        ... on FacetBooleanFilterSelection {
          key
          value
        }
        ... on FacetDateRangeFilterSelection {
          key
          fromDate
          toDate
        }
        ... on FacetComplexFilterSelection {
          key
          childrenInputs {
            ... on FacetBooleanFilterSelection {
              key
              value
            }
            ... on FacetListFilterSelection {
              key
              byName(localeId: $localeId)
            }
            ... on FacetRangeFilterSelection {
              key
              minValue
              maxValue
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetMyPagePresetsQuery__
 *
 * To run a query within a React component, call `useGetMyPagePresetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPagePresetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPagePresetsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useGetMyPagePresetsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMyPagePresetsQuery, Types.GetMyPagePresetsQueryVariables> & ({ variables: Types.GetMyPagePresetsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMyPagePresetsQuery, Types.GetMyPagePresetsQueryVariables>(GetMyPagePresetsDocument, options);
      }
export function useGetMyPagePresetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMyPagePresetsQuery, Types.GetMyPagePresetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMyPagePresetsQuery, Types.GetMyPagePresetsQueryVariables>(GetMyPagePresetsDocument, options);
        }
export function useGetMyPagePresetsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetMyPagePresetsQuery, Types.GetMyPagePresetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetMyPagePresetsQuery, Types.GetMyPagePresetsQueryVariables>(GetMyPagePresetsDocument, options);
        }
export type GetMyPagePresetsQueryHookResult = ReturnType<typeof useGetMyPagePresetsQuery>;
export type GetMyPagePresetsLazyQueryHookResult = ReturnType<typeof useGetMyPagePresetsLazyQuery>;
export type GetMyPagePresetsSuspenseQueryHookResult = ReturnType<typeof useGetMyPagePresetsSuspenseQuery>;
export type GetMyPagePresetsQueryResult = Apollo.QueryResult<Types.GetMyPagePresetsQuery, Types.GetMyPagePresetsQueryVariables>;
export const CreateTradeNameDocument = gql`
    mutation createTradeName($companyId: ID, $compositeId: TradeNameCompositeIDInput!, $localeId: Int!) {
  tradeNameCreate(input: {companyId: $companyId, compositeId: $compositeId}) {
    record {
      id
      tradeName
      acceptability
      canOverrideFacts
      canOverrideAcceptability
      country {
        id
        codeISO
        localizedName(localeId: $localeId)
        isEUMember
      }
    }
  }
}
    `;
export type CreateTradeNameMutationFn = Apollo.MutationFunction<Types.CreateTradeNameMutation, Types.CreateTradeNameMutationVariables>;

/**
 * __useCreateTradeNameMutation__
 *
 * To run a mutation, you first call `useCreateTradeNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTradeNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTradeNameMutation, { data, loading, error }] = useCreateTradeNameMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      compositeId: // value for 'compositeId'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useCreateTradeNameMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateTradeNameMutation, Types.CreateTradeNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateTradeNameMutation, Types.CreateTradeNameMutationVariables>(CreateTradeNameDocument, options);
      }
export type CreateTradeNameMutationHookResult = ReturnType<typeof useCreateTradeNameMutation>;
export type CreateTradeNameMutationResult = Apollo.MutationResult<Types.CreateTradeNameMutation>;
export type CreateTradeNameMutationOptions = Apollo.BaseMutationOptions<Types.CreateTradeNameMutation, Types.CreateTradeNameMutationVariables>;
export const RestoreTradeNameDocument = gql`
    mutation restoreTradeName($tradeNameId: ID!, $companyId: ID) {
  tradeNameAccept(input: {companyId: $companyId, tradeNameId: $tradeNameId}) {
    record {
      id
      acceptability
      canOverrideFacts
      canOverrideAcceptability
    }
  }
}
    `;
export type RestoreTradeNameMutationFn = Apollo.MutationFunction<Types.RestoreTradeNameMutation, Types.RestoreTradeNameMutationVariables>;

/**
 * __useRestoreTradeNameMutation__
 *
 * To run a mutation, you first call `useRestoreTradeNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreTradeNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreTradeNameMutation, { data, loading, error }] = useRestoreTradeNameMutation({
 *   variables: {
 *      tradeNameId: // value for 'tradeNameId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useRestoreTradeNameMutation(baseOptions?: Apollo.MutationHookOptions<Types.RestoreTradeNameMutation, Types.RestoreTradeNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RestoreTradeNameMutation, Types.RestoreTradeNameMutationVariables>(RestoreTradeNameDocument, options);
      }
export type RestoreTradeNameMutationHookResult = ReturnType<typeof useRestoreTradeNameMutation>;
export type RestoreTradeNameMutationResult = Apollo.MutationResult<Types.RestoreTradeNameMutation>;
export type RestoreTradeNameMutationOptions = Apollo.BaseMutationOptions<Types.RestoreTradeNameMutation, Types.RestoreTradeNameMutationVariables>;
export const TradeNameByCompositeIdDocument = gql`
    query tradeNameByCompositeId($compositeId: TradeNameCompositeIDInput!) {
  tradeNameByCompositeId(compositeId: $compositeId) {
    id
    acceptability
    canOverrideAcceptability
  }
}
    `;

/**
 * __useTradeNameByCompositeIdQuery__
 *
 * To run a query within a React component, call `useTradeNameByCompositeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeNameByCompositeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeNameByCompositeIdQuery({
 *   variables: {
 *      compositeId: // value for 'compositeId'
 *   },
 * });
 */
export function useTradeNameByCompositeIdQuery(baseOptions: Apollo.QueryHookOptions<Types.TradeNameByCompositeIdQuery, Types.TradeNameByCompositeIdQueryVariables> & ({ variables: Types.TradeNameByCompositeIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradeNameByCompositeIdQuery, Types.TradeNameByCompositeIdQueryVariables>(TradeNameByCompositeIdDocument, options);
      }
export function useTradeNameByCompositeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradeNameByCompositeIdQuery, Types.TradeNameByCompositeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradeNameByCompositeIdQuery, Types.TradeNameByCompositeIdQueryVariables>(TradeNameByCompositeIdDocument, options);
        }
export function useTradeNameByCompositeIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradeNameByCompositeIdQuery, Types.TradeNameByCompositeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradeNameByCompositeIdQuery, Types.TradeNameByCompositeIdQueryVariables>(TradeNameByCompositeIdDocument, options);
        }
export type TradeNameByCompositeIdQueryHookResult = ReturnType<typeof useTradeNameByCompositeIdQuery>;
export type TradeNameByCompositeIdLazyQueryHookResult = ReturnType<typeof useTradeNameByCompositeIdLazyQuery>;
export type TradeNameByCompositeIdSuspenseQueryHookResult = ReturnType<typeof useTradeNameByCompositeIdSuspenseQuery>;
export type TradeNameByCompositeIdQueryResult = Apollo.QueryResult<Types.TradeNameByCompositeIdQuery, Types.TradeNameByCompositeIdQueryVariables>;
export const TradeNameSearchForCreateProducerDocument = gql`
    query tradeNameSearchForCreateProducer($query: String, $localeId: Int!, $filter: TradeNameFilter) {
  tradeNameInstantSearch(query: $query, filter: $filter, localeId: $localeId) {
    id
    tradeName
    country {
      id
      localizedName(localeId: $localeId)
      isEUMember
    }
    logo {
      imageUrl
    }
  }
}
    `;

/**
 * __useTradeNameSearchForCreateProducerQuery__
 *
 * To run a query within a React component, call `useTradeNameSearchForCreateProducerQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeNameSearchForCreateProducerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeNameSearchForCreateProducerQuery({
 *   variables: {
 *      query: // value for 'query'
 *      localeId: // value for 'localeId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTradeNameSearchForCreateProducerQuery(baseOptions: Apollo.QueryHookOptions<Types.TradeNameSearchForCreateProducerQuery, Types.TradeNameSearchForCreateProducerQueryVariables> & ({ variables: Types.TradeNameSearchForCreateProducerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradeNameSearchForCreateProducerQuery, Types.TradeNameSearchForCreateProducerQueryVariables>(TradeNameSearchForCreateProducerDocument, options);
      }
export function useTradeNameSearchForCreateProducerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradeNameSearchForCreateProducerQuery, Types.TradeNameSearchForCreateProducerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradeNameSearchForCreateProducerQuery, Types.TradeNameSearchForCreateProducerQueryVariables>(TradeNameSearchForCreateProducerDocument, options);
        }
export function useTradeNameSearchForCreateProducerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradeNameSearchForCreateProducerQuery, Types.TradeNameSearchForCreateProducerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradeNameSearchForCreateProducerQuery, Types.TradeNameSearchForCreateProducerQueryVariables>(TradeNameSearchForCreateProducerDocument, options);
        }
export type TradeNameSearchForCreateProducerQueryHookResult = ReturnType<typeof useTradeNameSearchForCreateProducerQuery>;
export type TradeNameSearchForCreateProducerLazyQueryHookResult = ReturnType<typeof useTradeNameSearchForCreateProducerLazyQuery>;
export type TradeNameSearchForCreateProducerSuspenseQueryHookResult = ReturnType<typeof useTradeNameSearchForCreateProducerSuspenseQuery>;
export type TradeNameSearchForCreateProducerQueryResult = Apollo.QueryResult<Types.TradeNameSearchForCreateProducerQuery, Types.TradeNameSearchForCreateProducerQueryVariables>;
export const TradeNameForEditDocument = gql`
    query tradeNameForEdit($id: ID!, $localeId: Int!) {
  tradeName(id: $id) {
    id
    country {
      id
      localizedName(localeId: $localeId)
      codeISO
      legalEntityDiscriminator
      vatName
      vatFormat
      vatMask
      localizedVATTooltip(localeId: $localeId)
      localizedVATFormatError(localeId: $localeId)
      localizedIdentityNumberTooltip(localeId: $localeId)
      localizedIdentityNumberFormatError(localeId: $localeId)
      identityNumberName
      identityNumberFormat
      postCodeMask
      postCodeFormat
      postCodeName
      branchNumberName
      branchNumberFormat
      localizedBranchNumberFormatError(localeId: $localeId)
      localizedBranchNumberTooltip(localeId: $localeId)
    }
    tradeName
    translations {
      locale {
        id
        locale
        name
      }
      tradeName
    }
    postCountry {
      id
      localizedName(localeId: $localeId)
    }
    postCode
    postAddress
    latitude
    longitude
    legalEntity {
      id
      isActive
      closingDate
      branchNumber
      identityNumber
      legalName
      legalAddress
      legalEntityForm {
        id
        country {
          id
          localizedName(localeId: $localeId)
        }
        originName
        isActive
        shortName
      }
      vat
      activityCode
      canEdit
      companyCategory {
        id
        masterName
        localizedName(localeId: $localeId)
      }
    }
    phone
    website
    email
    logo {
      imageUrl
    }
    acceptability
    shouldOverrideAcceptability
    verified
    canOverrideFacts
    canOverrideAcceptability
    writePolicy {
      postCountry
      postCode
      postAddress
      latitude
      longitude
      phone
      website
      email
      logo
      translations
      legalEntity
    }
  }
}
    `;

/**
 * __useTradeNameForEditQuery__
 *
 * To run a query within a React component, call `useTradeNameForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeNameForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeNameForEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useTradeNameForEditQuery(baseOptions: Apollo.QueryHookOptions<Types.TradeNameForEditQuery, Types.TradeNameForEditQueryVariables> & ({ variables: Types.TradeNameForEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradeNameForEditQuery, Types.TradeNameForEditQueryVariables>(TradeNameForEditDocument, options);
      }
export function useTradeNameForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradeNameForEditQuery, Types.TradeNameForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradeNameForEditQuery, Types.TradeNameForEditQueryVariables>(TradeNameForEditDocument, options);
        }
export function useTradeNameForEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradeNameForEditQuery, Types.TradeNameForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradeNameForEditQuery, Types.TradeNameForEditQueryVariables>(TradeNameForEditDocument, options);
        }
export type TradeNameForEditQueryHookResult = ReturnType<typeof useTradeNameForEditQuery>;
export type TradeNameForEditLazyQueryHookResult = ReturnType<typeof useTradeNameForEditLazyQuery>;
export type TradeNameForEditSuspenseQueryHookResult = ReturnType<typeof useTradeNameForEditSuspenseQuery>;
export type TradeNameForEditQueryResult = Apollo.QueryResult<Types.TradeNameForEditQuery, Types.TradeNameForEditQueryVariables>;
export const UpdaterTradeNameDocument = gql`
    mutation updaterTradeName($input: TradeNameUpdateInput!) {
  tradeNameUpdate(input: $input) {
    recordId
  }
}
    `;
export type UpdaterTradeNameMutationFn = Apollo.MutationFunction<Types.UpdaterTradeNameMutation, Types.UpdaterTradeNameMutationVariables>;

/**
 * __useUpdaterTradeNameMutation__
 *
 * To run a mutation, you first call `useUpdaterTradeNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdaterTradeNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updaterTradeNameMutation, { data, loading, error }] = useUpdaterTradeNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdaterTradeNameMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdaterTradeNameMutation, Types.UpdaterTradeNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdaterTradeNameMutation, Types.UpdaterTradeNameMutationVariables>(UpdaterTradeNameDocument, options);
      }
export type UpdaterTradeNameMutationHookResult = ReturnType<typeof useUpdaterTradeNameMutation>;
export type UpdaterTradeNameMutationResult = Apollo.MutationResult<Types.UpdaterTradeNameMutation>;
export type UpdaterTradeNameMutationOptions = Apollo.BaseMutationOptions<Types.UpdaterTradeNameMutation, Types.UpdaterTradeNameMutationVariables>;
export const TradeNameViewDocument = gql`
    query tradeNameView($id: ID!, $localeId: Int!) {
  tradeName(id: $id) {
    id
    country {
      id
      localizedName(localeId: $localeId)
      codeISO
      identityNumberName
      branchNumberName
      vatName
      legalEntityDiscriminator
    }
    tradeName
    postCountry {
      id
      localizedName(localeId: $localeId)
    }
    postCode
    postAddress
    latitude
    longitude
    legalEntity {
      id
      isActive
      closingDate
      branchNumber
      identityNumber
      vat
      legalName
      legalAddress
      legalEntityForm {
        id
        country {
          id
          localizedName(localeId: $localeId)
        }
        originName
        isActive
        shortName
      }
      activityCode
      canEdit
      companyCategory {
        id
        masterName
        localizedName(localeId: $localeId)
      }
    }
    phone
    website
    email
    logo {
      imageUrl
      thumbnailUrl: imageUrl(transformations: {width: 64, height: 64, resize: fit})
      thumbnailRetinaUrl: imageUrl(
        transformations: {width: 128, height: 128, resize: fit}
      )
    }
    acceptability
    shouldOverrideAcceptability
    verified
    canOverrideFacts
    canOverrideAcceptability
    mainSynonym {
      id
    }
  }
}
    `;

/**
 * __useTradeNameViewQuery__
 *
 * To run a query within a React component, call `useTradeNameViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeNameViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeNameViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useTradeNameViewQuery(baseOptions: Apollo.QueryHookOptions<Types.TradeNameViewQuery, Types.TradeNameViewQueryVariables> & ({ variables: Types.TradeNameViewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradeNameViewQuery, Types.TradeNameViewQueryVariables>(TradeNameViewDocument, options);
      }
export function useTradeNameViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradeNameViewQuery, Types.TradeNameViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradeNameViewQuery, Types.TradeNameViewQueryVariables>(TradeNameViewDocument, options);
        }
export function useTradeNameViewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradeNameViewQuery, Types.TradeNameViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradeNameViewQuery, Types.TradeNameViewQueryVariables>(TradeNameViewDocument, options);
        }
export type TradeNameViewQueryHookResult = ReturnType<typeof useTradeNameViewQuery>;
export type TradeNameViewLazyQueryHookResult = ReturnType<typeof useTradeNameViewLazyQuery>;
export type TradeNameViewSuspenseQueryHookResult = ReturnType<typeof useTradeNameViewSuspenseQuery>;
export type TradeNameViewQueryResult = Apollo.QueryResult<Types.TradeNameViewQuery, Types.TradeNameViewQueryVariables>;
export const WineReviewCreateDocument = gql`
    mutation wineReviewCreate($input: WineReviewCreateInput!) {
  wineReviewCreate(input: $input) {
    recordId
  }
}
    `;
export type WineReviewCreateMutationFn = Apollo.MutationFunction<Types.WineReviewCreateMutation, Types.WineReviewCreateMutationVariables>;

/**
 * __useWineReviewCreateMutation__
 *
 * To run a mutation, you first call `useWineReviewCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineReviewCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineReviewCreateMutation, { data, loading, error }] = useWineReviewCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineReviewCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineReviewCreateMutation, Types.WineReviewCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineReviewCreateMutation, Types.WineReviewCreateMutationVariables>(WineReviewCreateDocument, options);
      }
export type WineReviewCreateMutationHookResult = ReturnType<typeof useWineReviewCreateMutation>;
export type WineReviewCreateMutationResult = Apollo.MutationResult<Types.WineReviewCreateMutation>;
export type WineReviewCreateMutationOptions = Apollo.BaseMutationOptions<Types.WineReviewCreateMutation, Types.WineReviewCreateMutationVariables>;
export const CreateWineReviewDraftDocument = gql`
    mutation createWineReviewDraft($input: WineReviewDraftCreateInput!) {
  wineReviewDraftCreate(input: $input) {
    recordId
    record {
      id
      product {
        id
      }
      blindCode
      date
      place
      isBottleDefected
      score
      scoreSystem {
        maxScore
      }
      review
      price
      currency {
        id
      }
      address
      latitude
      longitude
      drinkingWindowFrom
      drinkingWindowTo
      frontLabelImage {
        imageUrl
      }
      backLabelImage {
        imageUrl
      }
      isPrivate
      authorUser {
        id
      }
      foodPairs {
        id
      }
      images {
        imageUrl
      }
      placeType
      website
      exhibition {
        id
      }
      isDraft
    }
  }
}
    `;
export type CreateWineReviewDraftMutationFn = Apollo.MutationFunction<Types.CreateWineReviewDraftMutation, Types.CreateWineReviewDraftMutationVariables>;

/**
 * __useCreateWineReviewDraftMutation__
 *
 * To run a mutation, you first call `useCreateWineReviewDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWineReviewDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWineReviewDraftMutation, { data, loading, error }] = useCreateWineReviewDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWineReviewDraftMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateWineReviewDraftMutation, Types.CreateWineReviewDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateWineReviewDraftMutation, Types.CreateWineReviewDraftMutationVariables>(CreateWineReviewDraftDocument, options);
      }
export type CreateWineReviewDraftMutationHookResult = ReturnType<typeof useCreateWineReviewDraftMutation>;
export type CreateWineReviewDraftMutationResult = Apollo.MutationResult<Types.CreateWineReviewDraftMutation>;
export type CreateWineReviewDraftMutationOptions = Apollo.BaseMutationOptions<Types.CreateWineReviewDraftMutation, Types.CreateWineReviewDraftMutationVariables>;
export const ProductImagesUpdateDocument = gql`
    mutation productImagesUpdate($input: ProductImagesUpdateInput!) {
  productImagesUpdate(input: $input) {
    recordId
    record {
      id
      bottleImage {
        originUrl
      }
      frontLabelImage {
        originUrl
      }
      backLabelImage {
        originUrl
      }
    }
  }
}
    `;
export type ProductImagesUpdateMutationFn = Apollo.MutationFunction<Types.ProductImagesUpdateMutation, Types.ProductImagesUpdateMutationVariables>;

/**
 * __useProductImagesUpdateMutation__
 *
 * To run a mutation, you first call `useProductImagesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductImagesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productImagesUpdateMutation, { data, loading, error }] = useProductImagesUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductImagesUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProductImagesUpdateMutation, Types.ProductImagesUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductImagesUpdateMutation, Types.ProductImagesUpdateMutationVariables>(ProductImagesUpdateDocument, options);
      }
export type ProductImagesUpdateMutationHookResult = ReturnType<typeof useProductImagesUpdateMutation>;
export type ProductImagesUpdateMutationResult = Apollo.MutationResult<Types.ProductImagesUpdateMutation>;
export type ProductImagesUpdateMutationOptions = Apollo.BaseMutationOptions<Types.ProductImagesUpdateMutation, Types.ProductImagesUpdateMutationVariables>;
export const PublishWineReviewsDocument = gql`
    mutation publishWineReviews($input: WineReviewsPublishInput!) {
  wineReviewsPublish(input: $input) {
    records {
      id
      isDraft
    }
  }
}
    `;
export type PublishWineReviewsMutationFn = Apollo.MutationFunction<Types.PublishWineReviewsMutation, Types.PublishWineReviewsMutationVariables>;

/**
 * __usePublishWineReviewsMutation__
 *
 * To run a mutation, you first call `usePublishWineReviewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishWineReviewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishWineReviewsMutation, { data, loading, error }] = usePublishWineReviewsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishWineReviewsMutation(baseOptions?: Apollo.MutationHookOptions<Types.PublishWineReviewsMutation, Types.PublishWineReviewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PublishWineReviewsMutation, Types.PublishWineReviewsMutationVariables>(PublishWineReviewsDocument, options);
      }
export type PublishWineReviewsMutationHookResult = ReturnType<typeof usePublishWineReviewsMutation>;
export type PublishWineReviewsMutationResult = Apollo.MutationResult<Types.PublishWineReviewsMutation>;
export type PublishWineReviewsMutationOptions = Apollo.BaseMutationOptions<Types.PublishWineReviewsMutation, Types.PublishWineReviewsMutationVariables>;
export const UpdateReviewDocument = gql`
    mutation updateReview($input: WineReviewUpdateInput!) {
  wineReviewUpdate(input: $input) {
    recordId
  }
}
    `;
export type UpdateReviewMutationFn = Apollo.MutationFunction<Types.UpdateReviewMutation, Types.UpdateReviewMutationVariables>;

/**
 * __useUpdateReviewMutation__
 *
 * To run a mutation, you first call `useUpdateReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReviewMutation, { data, loading, error }] = useUpdateReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReviewMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateReviewMutation, Types.UpdateReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateReviewMutation, Types.UpdateReviewMutationVariables>(UpdateReviewDocument, options);
      }
export type UpdateReviewMutationHookResult = ReturnType<typeof useUpdateReviewMutation>;
export type UpdateReviewMutationResult = Apollo.MutationResult<Types.UpdateReviewMutation>;
export type UpdateReviewMutationOptions = Apollo.BaseMutationOptions<Types.UpdateReviewMutation, Types.UpdateReviewMutationVariables>;
export const UpdateWineReviewDraftDocument = gql`
    mutation updateWineReviewDraft($input: WineReviewDraftUpdateInput!) {
  wineReviewDraftUpdate(input: $input) {
    recordId
    record {
      id
      product {
        id
      }
      blindCode
      date
      place
      isBottleDefected
      score
      scoreSystem {
        maxScore
      }
      review
      price
      currency {
        id
      }
      address
      latitude
      longitude
      drinkingWindowFrom
      drinkingWindowTo
      frontLabelImage {
        imageUrl
      }
      backLabelImage {
        imageUrl
      }
      isPrivate
      authorUser {
        id
      }
      foodPairs {
        id
      }
      images {
        imageUrl
      }
      website
      placeType
      exhibition {
        id
      }
    }
  }
}
    `;
export type UpdateWineReviewDraftMutationFn = Apollo.MutationFunction<Types.UpdateWineReviewDraftMutation, Types.UpdateWineReviewDraftMutationVariables>;

/**
 * __useUpdateWineReviewDraftMutation__
 *
 * To run a mutation, you first call `useUpdateWineReviewDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWineReviewDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWineReviewDraftMutation, { data, loading, error }] = useUpdateWineReviewDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWineReviewDraftMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateWineReviewDraftMutation, Types.UpdateWineReviewDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateWineReviewDraftMutation, Types.UpdateWineReviewDraftMutationVariables>(UpdateWineReviewDraftDocument, options);
      }
export type UpdateWineReviewDraftMutationHookResult = ReturnType<typeof useUpdateWineReviewDraftMutation>;
export type UpdateWineReviewDraftMutationResult = Apollo.MutationResult<Types.UpdateWineReviewDraftMutation>;
export type UpdateWineReviewDraftMutationOptions = Apollo.BaseMutationOptions<Types.UpdateWineReviewDraftMutation, Types.UpdateWineReviewDraftMutationVariables>;
export const SearchProductsDocument = gql`
    query searchProducts($localeId: Int!, $query: String!, $limit: Int! = 7, $filter: ProductsPaginationFilter) {
  productsPagination(
    localeId: $localeId
    limit: $limit
    query: $query
    filter: $filter
  ) {
    items {
      id
      bottleSize {
        id
        volumeML
      }
      packageType {
        id
        isDefault
        shortName
        localizedName(localeId: $localeId)
      }
      wine {
        id
        grapeVarieties {
          grapeVarietySynonym {
            id
            name
          }
          value
        }
        nationalGIType {
          id
          localizedName(localeId: $localeId)
          euGIType {
            id
            masterName
          }
        }
        geoIndications {
          id
          localizedName(localeId: $localeId)
          wineRegionsEU {
            id
            localizedName(localeId: $localeId)
          }
        }
        vintage
        tradeName {
          id
          tradeName
          country {
            id
            localizedName(localeId: $localeId)
          }
        }
        wineType {
          id
          localizedName(localeId: $localeId)
        }
        wineColor {
          id
          localizedName(localeId: $localeId)
        }
        brand {
          id
          name
          localizedName(localeId: $localeId)
        }
        sweetnessLevel {
          id
          localizedName(localeId: $localeId)
        }
        keyWords
        specialClassifications {
          id
          name
        }
        country {
          id
          localizedName(localeId: $localeId)
        }
      }
    }
  }
}
    `;

/**
 * __useSearchProductsQuery__
 *
 * To run a query within a React component, call `useSearchProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductsQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchProductsQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchProductsQuery, Types.SearchProductsQueryVariables> & ({ variables: Types.SearchProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchProductsQuery, Types.SearchProductsQueryVariables>(SearchProductsDocument, options);
      }
export function useSearchProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchProductsQuery, Types.SearchProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchProductsQuery, Types.SearchProductsQueryVariables>(SearchProductsDocument, options);
        }
export function useSearchProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SearchProductsQuery, Types.SearchProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SearchProductsQuery, Types.SearchProductsQueryVariables>(SearchProductsDocument, options);
        }
export type SearchProductsQueryHookResult = ReturnType<typeof useSearchProductsQuery>;
export type SearchProductsLazyQueryHookResult = ReturnType<typeof useSearchProductsLazyQuery>;
export type SearchProductsSuspenseQueryHookResult = ReturnType<typeof useSearchProductsSuspenseQuery>;
export type SearchProductsQueryResult = Apollo.QueryResult<Types.SearchProductsQuery, Types.SearchProductsQueryVariables>;
export const SellWineOffersAcceptedFilterByKeyDocument = gql`
    query sellWineOffersAcceptedFilterByKey($companyId: ID!, $localeId: Int!, $page: PositiveInt, $perPage: PositiveInt! = 50, $filterSearch: String, $query: String, $key: String!, $isBuyer: Boolean!, $includeUnusedLabels: Boolean!, $filter: WineOfferFiltersFilter) {
  me {
    company(id: $companyId) {
      wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: $filter
        query: $query
      ) {
        filterByKey(key: $key) {
          ...facetComplexFilter
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetComplexFilterFragmentDoc}
${FacetListFilterFragmentDoc}`;

/**
 * __useSellWineOffersAcceptedFilterByKeyQuery__
 *
 * To run a query within a React component, call `useSellWineOffersAcceptedFilterByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellWineOffersAcceptedFilterByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellWineOffersAcceptedFilterByKeyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      query: // value for 'query'
 *      key: // value for 'key'
 *      isBuyer: // value for 'isBuyer'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSellWineOffersAcceptedFilterByKeyQuery(baseOptions: Apollo.QueryHookOptions<Types.SellWineOffersAcceptedFilterByKeyQuery, Types.SellWineOffersAcceptedFilterByKeyQueryVariables> & ({ variables: Types.SellWineOffersAcceptedFilterByKeyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SellWineOffersAcceptedFilterByKeyQuery, Types.SellWineOffersAcceptedFilterByKeyQueryVariables>(SellWineOffersAcceptedFilterByKeyDocument, options);
      }
export function useSellWineOffersAcceptedFilterByKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SellWineOffersAcceptedFilterByKeyQuery, Types.SellWineOffersAcceptedFilterByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SellWineOffersAcceptedFilterByKeyQuery, Types.SellWineOffersAcceptedFilterByKeyQueryVariables>(SellWineOffersAcceptedFilterByKeyDocument, options);
        }
export function useSellWineOffersAcceptedFilterByKeySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SellWineOffersAcceptedFilterByKeyQuery, Types.SellWineOffersAcceptedFilterByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SellWineOffersAcceptedFilterByKeyQuery, Types.SellWineOffersAcceptedFilterByKeyQueryVariables>(SellWineOffersAcceptedFilterByKeyDocument, options);
        }
export type SellWineOffersAcceptedFilterByKeyQueryHookResult = ReturnType<typeof useSellWineOffersAcceptedFilterByKeyQuery>;
export type SellWineOffersAcceptedFilterByKeyLazyQueryHookResult = ReturnType<typeof useSellWineOffersAcceptedFilterByKeyLazyQuery>;
export type SellWineOffersAcceptedFilterByKeySuspenseQueryHookResult = ReturnType<typeof useSellWineOffersAcceptedFilterByKeySuspenseQuery>;
export type SellWineOffersAcceptedFilterByKeyQueryResult = Apollo.QueryResult<Types.SellWineOffersAcceptedFilterByKeyQuery, Types.SellWineOffersAcceptedFilterByKeyQueryVariables>;
export const SellWineOffersAcceptedFiltersDocument = gql`
    query sellWineOffersAcceptedFilters($companyId: ID!, $localeId: Int!, $page: PositiveInt, $perPage: PositiveInt! = 50, $query: String, $isBuyer: Boolean!, $includeUnusedLabels: Boolean!, $filter: WineOfferFiltersFilter, $filterSearch: String) {
  me {
    company(id: $companyId) {
      wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: $filter
        query: $query
      ) {
        count
        countryFilter: filterByKey(key: "product.wine.country") {
          ...facetListFilter
        }
        distributionFilter: filterByKey(key: "countryOfDelivery") {
          ...facetListFilter
        }
        euGITypeFilter: filterByKey(key: "product.wine.nationalGIType.euGIType") {
          ...facetListFilter
        }
        nationalGITypeFilter: filterByKey(key: "product.wine.nationalGIType") {
          ...facetListFilter
        }
        wineRegionEUFilter: filterByKey(key: "product.wine.wineRegionEU") {
          ...facetListFilter
        }
        geoIndicationFilter: filterByKey(key: "product.wine.geoIndication") {
          ...facetListFilter
        }
        wineTypeFilter: filterByKey(key: "product.wine.wineType") {
          ...facetListFilter
        }
        buyerCompanyCategoryFilter: filterByKey(key: "buyerCompanyCategory") {
          ...facetListFilter
        }
        buyerCompanyFilter: filterByKey(key: "buyerCompanyNameUsernameSecured") {
          ...facetListFilter
        }
        producerFilter: filterByKey(key: "product.wine.tradeName") {
          ...facetListFilter
        }
        wineColourFilter: filterByKey(key: "product.wine.wineColor") {
          ...facetListFilter
        }
        vintageFilter: filterByKey(key: "product.wine.vintage") {
          ...facetListFilter
        }
        buyerCompanyCountryFilter: filterByKey(key: "buyerCompanyCountry") {
          ...facetListFilter
        }
        sweetnessTypeFilter: filterByKey(key: "product.wine.sweetnessLevel") {
          ...facetListFilter
        }
        certificatesFilter: filterByKey(key: "product.wine.certification") {
          ...facetListFilter
        }
        bottleSizeFilter: filterByKey(key: "bottleSize") {
          ...facetListFilter
        }
        packageTypeFilter: filterByKey(key: "packageType") {
          ...facetListFilter
        }
        grapesFilter: filterByKey(key: "grapeAndMonocepage") {
          ...facetComplexFilter
        }
        statusFilter: filterByKey(key: "wineOfferStatus") {
          ...facetListFilter
        }
        wwlRatingFilter: filterByKey(key: "product.wine.rating") {
          ...facetListFilter
        }
        deliveryTermsFilter: filterByKey(key: "incoterms") {
          ...facetListFilter
        }
        alcoholLevelFilter: filterByKey(key: "product.alcoholOnLabel") {
          ...facetRangeFilter
        }
        priceFilter: filterByKey(key: "price") {
          ...facetComplexFilter
        }
        brandFilter: filterByKey(key: "product.wine.brand") {
          ...facetListFilter
        }
        countryOfShipmentFilter: filterByKey(key: "countryOfShipment") {
          ...facetListFilter
        }
        exhibitionFilter: filterByKey(key: "exhibition") {
          ...facetListFilter
        }
        specialClassificationFilter: filterByKey(
          key: "product.wine.specialClassification"
        ) {
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}`;

/**
 * __useSellWineOffersAcceptedFiltersQuery__
 *
 * To run a query within a React component, call `useSellWineOffersAcceptedFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellWineOffersAcceptedFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellWineOffersAcceptedFiltersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      isBuyer: // value for 'isBuyer'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *      filter: // value for 'filter'
 *      filterSearch: // value for 'filterSearch'
 *   },
 * });
 */
export function useSellWineOffersAcceptedFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.SellWineOffersAcceptedFiltersQuery, Types.SellWineOffersAcceptedFiltersQueryVariables> & ({ variables: Types.SellWineOffersAcceptedFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SellWineOffersAcceptedFiltersQuery, Types.SellWineOffersAcceptedFiltersQueryVariables>(SellWineOffersAcceptedFiltersDocument, options);
      }
export function useSellWineOffersAcceptedFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SellWineOffersAcceptedFiltersQuery, Types.SellWineOffersAcceptedFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SellWineOffersAcceptedFiltersQuery, Types.SellWineOffersAcceptedFiltersQueryVariables>(SellWineOffersAcceptedFiltersDocument, options);
        }
export function useSellWineOffersAcceptedFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SellWineOffersAcceptedFiltersQuery, Types.SellWineOffersAcceptedFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SellWineOffersAcceptedFiltersQuery, Types.SellWineOffersAcceptedFiltersQueryVariables>(SellWineOffersAcceptedFiltersDocument, options);
        }
export type SellWineOffersAcceptedFiltersQueryHookResult = ReturnType<typeof useSellWineOffersAcceptedFiltersQuery>;
export type SellWineOffersAcceptedFiltersLazyQueryHookResult = ReturnType<typeof useSellWineOffersAcceptedFiltersLazyQuery>;
export type SellWineOffersAcceptedFiltersSuspenseQueryHookResult = ReturnType<typeof useSellWineOffersAcceptedFiltersSuspenseQuery>;
export type SellWineOffersAcceptedFiltersQueryResult = Apollo.QueryResult<Types.SellWineOffersAcceptedFiltersQuery, Types.SellWineOffersAcceptedFiltersQueryVariables>;
export const SellWineOffersAllFilterByKeyDocument = gql`
    query sellWineOffersAllFilterByKey($companyId: ID!, $localeId: Int!, $page: PositiveInt, $perPage: PositiveInt! = 50, $filterSearch: String, $query: String, $key: String!, $isBuyer: Boolean!, $includeUnusedLabels: Boolean!, $filter: WineOfferFiltersFilter) {
  me {
    company(id: $companyId) {
      wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: $filter
        query: $query
      ) {
        filterByKey(key: $key) {
          ...facetListFilter
          ...facetComplexFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}`;

/**
 * __useSellWineOffersAllFilterByKeyQuery__
 *
 * To run a query within a React component, call `useSellWineOffersAllFilterByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellWineOffersAllFilterByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellWineOffersAllFilterByKeyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      query: // value for 'query'
 *      key: // value for 'key'
 *      isBuyer: // value for 'isBuyer'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSellWineOffersAllFilterByKeyQuery(baseOptions: Apollo.QueryHookOptions<Types.SellWineOffersAllFilterByKeyQuery, Types.SellWineOffersAllFilterByKeyQueryVariables> & ({ variables: Types.SellWineOffersAllFilterByKeyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SellWineOffersAllFilterByKeyQuery, Types.SellWineOffersAllFilterByKeyQueryVariables>(SellWineOffersAllFilterByKeyDocument, options);
      }
export function useSellWineOffersAllFilterByKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SellWineOffersAllFilterByKeyQuery, Types.SellWineOffersAllFilterByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SellWineOffersAllFilterByKeyQuery, Types.SellWineOffersAllFilterByKeyQueryVariables>(SellWineOffersAllFilterByKeyDocument, options);
        }
export function useSellWineOffersAllFilterByKeySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SellWineOffersAllFilterByKeyQuery, Types.SellWineOffersAllFilterByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SellWineOffersAllFilterByKeyQuery, Types.SellWineOffersAllFilterByKeyQueryVariables>(SellWineOffersAllFilterByKeyDocument, options);
        }
export type SellWineOffersAllFilterByKeyQueryHookResult = ReturnType<typeof useSellWineOffersAllFilterByKeyQuery>;
export type SellWineOffersAllFilterByKeyLazyQueryHookResult = ReturnType<typeof useSellWineOffersAllFilterByKeyLazyQuery>;
export type SellWineOffersAllFilterByKeySuspenseQueryHookResult = ReturnType<typeof useSellWineOffersAllFilterByKeySuspenseQuery>;
export type SellWineOffersAllFilterByKeyQueryResult = Apollo.QueryResult<Types.SellWineOffersAllFilterByKeyQuery, Types.SellWineOffersAllFilterByKeyQueryVariables>;
export const SellWineOffersAllFiltersDocument = gql`
    query sellWineOffersAllFilters($companyId: ID!, $localeId: Int!, $page: PositiveInt, $perPage: PositiveInt! = 50, $query: String, $isBuyer: Boolean!, $includeUnusedLabels: Boolean!, $filter: WineOfferFiltersFilter, $filterSearch: String) {
  me {
    company(id: $companyId) {
      wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: $filter
        query: $query
      ) {
        count
        countryFilter: filterByKey(key: "product.wine.country") {
          ...facetListFilter
        }
        distributionFilter: filterByKey(key: "countryOfDelivery") {
          ...facetListFilter
        }
        euGITypeFilter: filterByKey(key: "product.wine.nationalGIType.euGIType") {
          ...facetListFilter
        }
        nationalGITypeFilter: filterByKey(key: "product.wine.nationalGIType") {
          ...facetListFilter
        }
        buyerCompanyCategoryFilter: filterByKey(key: "buyerCompanyCategory") {
          ...facetListFilter
        }
        buyerCompanyFilter: filterByKey(key: "buyerCompanyNameUsernameSecured") {
          ...facetListFilter
        }
        producerFilter: filterByKey(key: "product.wine.tradeName") {
          ...facetListFilter
        }
        wineRegionEUFilter: filterByKey(key: "product.wine.wineRegionEU") {
          ...facetListFilter
        }
        geoIndicationFilter: filterByKey(key: "product.wine.geoIndication") {
          ...facetListFilter
        }
        wineTypeFilter: filterByKey(key: "product.wine.wineType") {
          ...facetListFilter
        }
        wineColourFilter: filterByKey(key: "product.wine.wineColor") {
          ...facetListFilter
        }
        vintageFilter: filterByKey(key: "product.wine.vintage") {
          ...facetListFilter
        }
        sweetnessTypeFilter: filterByKey(key: "product.wine.sweetnessLevel") {
          ...facetListFilter
        }
        certificatesFilter: filterByKey(key: "product.wine.certification") {
          ...facetListFilter
        }
        bottleSizeFilter: filterByKey(key: "bottleSize") {
          ...facetListFilter
        }
        packageTypeFilter: filterByKey(key: "packageType") {
          ...facetListFilter
        }
        grapesFilter: filterByKey(key: "grapeAndMonocepage") {
          ...facetComplexFilter
        }
        statusFilter: filterByKey(key: "wineOfferStatus") {
          ...facetListFilter
        }
        wwlRatingFilter: filterByKey(key: "product.wine.rating") {
          ...facetListFilter
        }
        deliveryTermsFilter: filterByKey(key: "incoterms") {
          ...facetListFilter
        }
        alcoholLevelFilter: filterByKey(key: "product.alcoholOnLabel") {
          ...facetRangeFilter
        }
        priceFilter: filterByKey(key: "price") {
          ...facetComplexFilter
        }
        brandFilter: filterByKey(key: "product.wine.brand") {
          ...facetListFilter
        }
        buyerCompanyCountryFilter: filterByKey(key: "buyerCompanyCountry") {
          ...facetListFilter
        }
        countryOfShipmentFilter: filterByKey(key: "countryOfShipment") {
          ...facetListFilter
        }
        exhibitionFilter: filterByKey(key: "exhibition") {
          ...facetListFilter
        }
        specialClassificationFilter: filterByKey(
          key: "product.wine.specialClassification"
        ) {
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}`;

/**
 * __useSellWineOffersAllFiltersQuery__
 *
 * To run a query within a React component, call `useSellWineOffersAllFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellWineOffersAllFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellWineOffersAllFiltersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      isBuyer: // value for 'isBuyer'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *      filter: // value for 'filter'
 *      filterSearch: // value for 'filterSearch'
 *   },
 * });
 */
export function useSellWineOffersAllFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.SellWineOffersAllFiltersQuery, Types.SellWineOffersAllFiltersQueryVariables> & ({ variables: Types.SellWineOffersAllFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SellWineOffersAllFiltersQuery, Types.SellWineOffersAllFiltersQueryVariables>(SellWineOffersAllFiltersDocument, options);
      }
export function useSellWineOffersAllFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SellWineOffersAllFiltersQuery, Types.SellWineOffersAllFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SellWineOffersAllFiltersQuery, Types.SellWineOffersAllFiltersQueryVariables>(SellWineOffersAllFiltersDocument, options);
        }
export function useSellWineOffersAllFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SellWineOffersAllFiltersQuery, Types.SellWineOffersAllFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SellWineOffersAllFiltersQuery, Types.SellWineOffersAllFiltersQueryVariables>(SellWineOffersAllFiltersDocument, options);
        }
export type SellWineOffersAllFiltersQueryHookResult = ReturnType<typeof useSellWineOffersAllFiltersQuery>;
export type SellWineOffersAllFiltersLazyQueryHookResult = ReturnType<typeof useSellWineOffersAllFiltersLazyQuery>;
export type SellWineOffersAllFiltersSuspenseQueryHookResult = ReturnType<typeof useSellWineOffersAllFiltersSuspenseQuery>;
export type SellWineOffersAllFiltersQueryResult = Apollo.QueryResult<Types.SellWineOffersAllFiltersQuery, Types.SellWineOffersAllFiltersQueryVariables>;
export const SellWineOffersArchivedFilterByKeyDocument = gql`
    query sellWineOffersArchivedFilterByKey($companyId: ID!, $localeId: Int!, $page: PositiveInt, $perPage: PositiveInt! = 50, $filterSearch: String, $query: String, $key: String!, $isBuyer: Boolean!, $includeUnusedLabels: Boolean!, $filter: WineOfferFiltersFilter) {
  me {
    company(id: $companyId) {
      wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: $filter
        query: $query
      ) {
        filterByKey(key: $key) {
          ...facetListFilter
          ...facetComplexFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}`;

/**
 * __useSellWineOffersArchivedFilterByKeyQuery__
 *
 * To run a query within a React component, call `useSellWineOffersArchivedFilterByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellWineOffersArchivedFilterByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellWineOffersArchivedFilterByKeyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      query: // value for 'query'
 *      key: // value for 'key'
 *      isBuyer: // value for 'isBuyer'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSellWineOffersArchivedFilterByKeyQuery(baseOptions: Apollo.QueryHookOptions<Types.SellWineOffersArchivedFilterByKeyQuery, Types.SellWineOffersArchivedFilterByKeyQueryVariables> & ({ variables: Types.SellWineOffersArchivedFilterByKeyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SellWineOffersArchivedFilterByKeyQuery, Types.SellWineOffersArchivedFilterByKeyQueryVariables>(SellWineOffersArchivedFilterByKeyDocument, options);
      }
export function useSellWineOffersArchivedFilterByKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SellWineOffersArchivedFilterByKeyQuery, Types.SellWineOffersArchivedFilterByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SellWineOffersArchivedFilterByKeyQuery, Types.SellWineOffersArchivedFilterByKeyQueryVariables>(SellWineOffersArchivedFilterByKeyDocument, options);
        }
export function useSellWineOffersArchivedFilterByKeySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SellWineOffersArchivedFilterByKeyQuery, Types.SellWineOffersArchivedFilterByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SellWineOffersArchivedFilterByKeyQuery, Types.SellWineOffersArchivedFilterByKeyQueryVariables>(SellWineOffersArchivedFilterByKeyDocument, options);
        }
export type SellWineOffersArchivedFilterByKeyQueryHookResult = ReturnType<typeof useSellWineOffersArchivedFilterByKeyQuery>;
export type SellWineOffersArchivedFilterByKeyLazyQueryHookResult = ReturnType<typeof useSellWineOffersArchivedFilterByKeyLazyQuery>;
export type SellWineOffersArchivedFilterByKeySuspenseQueryHookResult = ReturnType<typeof useSellWineOffersArchivedFilterByKeySuspenseQuery>;
export type SellWineOffersArchivedFilterByKeyQueryResult = Apollo.QueryResult<Types.SellWineOffersArchivedFilterByKeyQuery, Types.SellWineOffersArchivedFilterByKeyQueryVariables>;
export const SellWineOffersArchivedFiltersDocument = gql`
    query sellWineOffersArchivedFilters($companyId: ID!, $localeId: Int!, $page: PositiveInt, $perPage: PositiveInt! = 50, $query: String, $isBuyer: Boolean!, $includeUnusedLabels: Boolean!, $filter: WineOfferFiltersFilter, $filterSearch: String) {
  me {
    company(id: $companyId) {
      wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: $filter
        query: $query
      ) {
        count
        countryFilter: filterByKey(key: "product.wine.country") {
          ...facetListFilter
        }
        distributionFilter: filterByKey(key: "countryOfDelivery") {
          ...facetListFilter
        }
        euGITypeFilter: filterByKey(key: "product.wine.nationalGIType.euGIType") {
          ...facetListFilter
        }
        nationalGITypeFilter: filterByKey(key: "product.wine.nationalGIType") {
          ...facetListFilter
        }
        wineRegionEUFilter: filterByKey(key: "product.wine.wineRegionEU") {
          ...facetListFilter
        }
        geoIndicationFilter: filterByKey(key: "product.wine.geoIndication") {
          ...facetListFilter
        }
        wineTypeFilter: filterByKey(key: "product.wine.wineType") {
          ...facetListFilter
        }
        wineColourFilter: filterByKey(key: "product.wine.wineColor") {
          ...facetListFilter
        }
        buyerCompanyCategoryFilter: filterByKey(key: "buyerCompanyCategory") {
          ...facetListFilter
        }
        buyerCompanyFilter: filterByKey(key: "buyerCompanyNameUsernameSecured") {
          ...facetListFilter
        }
        producerFilter: filterByKey(key: "product.wine.tradeName") {
          ...facetListFilter
        }
        buyerCompanyCountryFilter: filterByKey(key: "buyerCompanyCountry") {
          ...facetListFilter
        }
        vintageFilter: filterByKey(key: "product.wine.vintage") {
          ...facetListFilter
        }
        sweetnessTypeFilter: filterByKey(key: "product.wine.sweetnessLevel") {
          ...facetListFilter
        }
        certificatesFilter: filterByKey(key: "product.wine.certification") {
          ...facetListFilter
        }
        packageTypeFilter: filterByKey(key: "packageType") {
          ...facetListFilter
        }
        grapesFilter: filterByKey(key: "grapeAndMonocepage") {
          ...facetComplexFilter
        }
        statusFilter: filterByKey(key: "wineOfferStatus") {
          ...facetListFilter
        }
        wwlRatingFilter: filterByKey(key: "product.wine.rating") {
          ...facetListFilter
        }
        deliveryTermsFilter: filterByKey(key: "incoterms") {
          ...facetListFilter
        }
        alcoholLevelFilter: filterByKey(key: "product.alcoholOnLabel") {
          ...facetRangeFilter
        }
        priceFilter: filterByKey(key: "price") {
          ...facetComplexFilter
        }
        brandFilter: filterByKey(key: "product.wine.brand") {
          ...facetListFilter
        }
        countryOfShipmentFilter: filterByKey(key: "countryOfShipment") {
          ...facetListFilter
        }
        exhibitionFilter: filterByKey(key: "exhibition") {
          ...facetListFilter
        }
        specialClassificationFilter: filterByKey(
          key: "product.wine.specialClassification"
        ) {
          ...facetListFilter
        }
        bottleSizeFilter: filterByKey(key: "bottleSize") {
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}`;

/**
 * __useSellWineOffersArchivedFiltersQuery__
 *
 * To run a query within a React component, call `useSellWineOffersArchivedFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellWineOffersArchivedFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellWineOffersArchivedFiltersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      isBuyer: // value for 'isBuyer'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *      filter: // value for 'filter'
 *      filterSearch: // value for 'filterSearch'
 *   },
 * });
 */
export function useSellWineOffersArchivedFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.SellWineOffersArchivedFiltersQuery, Types.SellWineOffersArchivedFiltersQueryVariables> & ({ variables: Types.SellWineOffersArchivedFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SellWineOffersArchivedFiltersQuery, Types.SellWineOffersArchivedFiltersQueryVariables>(SellWineOffersArchivedFiltersDocument, options);
      }
export function useSellWineOffersArchivedFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SellWineOffersArchivedFiltersQuery, Types.SellWineOffersArchivedFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SellWineOffersArchivedFiltersQuery, Types.SellWineOffersArchivedFiltersQueryVariables>(SellWineOffersArchivedFiltersDocument, options);
        }
export function useSellWineOffersArchivedFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SellWineOffersArchivedFiltersQuery, Types.SellWineOffersArchivedFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SellWineOffersArchivedFiltersQuery, Types.SellWineOffersArchivedFiltersQueryVariables>(SellWineOffersArchivedFiltersDocument, options);
        }
export type SellWineOffersArchivedFiltersQueryHookResult = ReturnType<typeof useSellWineOffersArchivedFiltersQuery>;
export type SellWineOffersArchivedFiltersLazyQueryHookResult = ReturnType<typeof useSellWineOffersArchivedFiltersLazyQuery>;
export type SellWineOffersArchivedFiltersSuspenseQueryHookResult = ReturnType<typeof useSellWineOffersArchivedFiltersSuspenseQuery>;
export type SellWineOffersArchivedFiltersQueryResult = Apollo.QueryResult<Types.SellWineOffersArchivedFiltersQuery, Types.SellWineOffersArchivedFiltersQueryVariables>;
export const SellWineOffersNegotiationFilterByKeyDocument = gql`
    query sellWineOffersNegotiationFilterByKey($companyId: ID!, $localeId: Int!, $page: PositiveInt, $perPage: PositiveInt! = 50, $filterSearch: String, $query: String, $key: String!, $isBuyer: Boolean!, $includeUnusedLabels: Boolean!, $filter: WineOfferFiltersFilter) {
  me {
    company(id: $companyId) {
      wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: $filter
        query: $query
      ) {
        filterByKey(key: $key) {
          ...facetComplexFilter
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetComplexFilterFragmentDoc}
${FacetListFilterFragmentDoc}`;

/**
 * __useSellWineOffersNegotiationFilterByKeyQuery__
 *
 * To run a query within a React component, call `useSellWineOffersNegotiationFilterByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellWineOffersNegotiationFilterByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellWineOffersNegotiationFilterByKeyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      query: // value for 'query'
 *      key: // value for 'key'
 *      isBuyer: // value for 'isBuyer'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSellWineOffersNegotiationFilterByKeyQuery(baseOptions: Apollo.QueryHookOptions<Types.SellWineOffersNegotiationFilterByKeyQuery, Types.SellWineOffersNegotiationFilterByKeyQueryVariables> & ({ variables: Types.SellWineOffersNegotiationFilterByKeyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SellWineOffersNegotiationFilterByKeyQuery, Types.SellWineOffersNegotiationFilterByKeyQueryVariables>(SellWineOffersNegotiationFilterByKeyDocument, options);
      }
export function useSellWineOffersNegotiationFilterByKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SellWineOffersNegotiationFilterByKeyQuery, Types.SellWineOffersNegotiationFilterByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SellWineOffersNegotiationFilterByKeyQuery, Types.SellWineOffersNegotiationFilterByKeyQueryVariables>(SellWineOffersNegotiationFilterByKeyDocument, options);
        }
export function useSellWineOffersNegotiationFilterByKeySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SellWineOffersNegotiationFilterByKeyQuery, Types.SellWineOffersNegotiationFilterByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SellWineOffersNegotiationFilterByKeyQuery, Types.SellWineOffersNegotiationFilterByKeyQueryVariables>(SellWineOffersNegotiationFilterByKeyDocument, options);
        }
export type SellWineOffersNegotiationFilterByKeyQueryHookResult = ReturnType<typeof useSellWineOffersNegotiationFilterByKeyQuery>;
export type SellWineOffersNegotiationFilterByKeyLazyQueryHookResult = ReturnType<typeof useSellWineOffersNegotiationFilterByKeyLazyQuery>;
export type SellWineOffersNegotiationFilterByKeySuspenseQueryHookResult = ReturnType<typeof useSellWineOffersNegotiationFilterByKeySuspenseQuery>;
export type SellWineOffersNegotiationFilterByKeyQueryResult = Apollo.QueryResult<Types.SellWineOffersNegotiationFilterByKeyQuery, Types.SellWineOffersNegotiationFilterByKeyQueryVariables>;
export const SellWineOffersNegotiationsFiltersDocument = gql`
    query sellWineOffersNegotiationsFilters($companyId: ID!, $localeId: Int!, $page: PositiveInt, $perPage: PositiveInt! = 50, $query: String, $isBuyer: Boolean!, $includeUnusedLabels: Boolean!, $filter: WineOfferFiltersFilter, $filterSearch: String) {
  me {
    company(id: $companyId) {
      wineOffersFilters(
        localeId: $localeId
        isBuyer: $isBuyer
        filter: $filter
        query: $query
      ) {
        count
        countryFilter: filterByKey(key: "product.wine.country") {
          ...facetListFilter
        }
        distributionFilter: filterByKey(key: "countryOfDelivery") {
          ...facetListFilter
        }
        euGITypeFilter: filterByKey(key: "product.wine.nationalGIType.euGIType") {
          ...facetListFilter
        }
        nationalGITypeFilter: filterByKey(key: "product.wine.nationalGIType") {
          ...facetListFilter
        }
        wineRegionEUFilter: filterByKey(key: "product.wine.wineRegionEU") {
          ...facetListFilter
        }
        buyerCompanyCategoryFilter: filterByKey(key: "buyerCompanyCategory") {
          ...facetListFilter
        }
        buyerCompanyFilter: filterByKey(key: "buyerCompanyNameUsernameSecured") {
          ...facetListFilter
        }
        producerFilter: filterByKey(key: "product.wine.tradeName") {
          ...facetListFilter
        }
        geoIndicationFilter: filterByKey(key: "product.wine.geoIndication") {
          ...facetListFilter
        }
        wineTypeFilter: filterByKey(key: "product.wine.wineType") {
          ...facetListFilter
        }
        wineColourFilter: filterByKey(key: "product.wine.wineColor") {
          ...facetListFilter
        }
        vintageFilter: filterByKey(key: "product.wine.vintage") {
          ...facetListFilter
        }
        buyerCompanyCountryFilter: filterByKey(key: "buyerCompanyCountry") {
          ...facetListFilter
        }
        sweetnessTypeFilter: filterByKey(key: "product.wine.sweetnessLevel") {
          ...facetListFilter
        }
        certificatesFilter: filterByKey(key: "product.wine.certification") {
          ...facetListFilter
        }
        bottleSizeFilter: filterByKey(key: "bottleSize") {
          ...facetListFilter
        }
        packageTypeFilter: filterByKey(key: "packageType") {
          ...facetListFilter
        }
        grapesFilter: filterByKey(key: "grapeAndMonocepage") {
          ...facetComplexFilter
        }
        statusFilter: filterByKey(key: "wineOfferStatus") {
          ...facetListFilter
        }
        wwlRatingFilter: filterByKey(key: "product.wine.rating") {
          ...facetListFilter
        }
        deliveryTermsFilter: filterByKey(key: "incoterms") {
          ...facetListFilter
        }
        alcoholLevelFilter: filterByKey(key: "product.alcoholOnLabel") {
          ...facetRangeFilter
        }
        priceFilter: filterByKey(key: "price") {
          ...facetComplexFilter
        }
        brandFilter: filterByKey(key: "product.wine.brand") {
          ...facetListFilter
        }
        countryOfShipmentFilter: filterByKey(key: "countryOfShipment") {
          ...facetListFilter
        }
        exhibitionFilter: filterByKey(key: "exhibition") {
          ...facetListFilter
        }
        specialClassificationFilter: filterByKey(
          key: "product.wine.specialClassification"
        ) {
          ...facetListFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}`;

/**
 * __useSellWineOffersNegotiationsFiltersQuery__
 *
 * To run a query within a React component, call `useSellWineOffersNegotiationsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellWineOffersNegotiationsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellWineOffersNegotiationsFiltersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      isBuyer: // value for 'isBuyer'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *      filter: // value for 'filter'
 *      filterSearch: // value for 'filterSearch'
 *   },
 * });
 */
export function useSellWineOffersNegotiationsFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.SellWineOffersNegotiationsFiltersQuery, Types.SellWineOffersNegotiationsFiltersQueryVariables> & ({ variables: Types.SellWineOffersNegotiationsFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SellWineOffersNegotiationsFiltersQuery, Types.SellWineOffersNegotiationsFiltersQueryVariables>(SellWineOffersNegotiationsFiltersDocument, options);
      }
export function useSellWineOffersNegotiationsFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SellWineOffersNegotiationsFiltersQuery, Types.SellWineOffersNegotiationsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SellWineOffersNegotiationsFiltersQuery, Types.SellWineOffersNegotiationsFiltersQueryVariables>(SellWineOffersNegotiationsFiltersDocument, options);
        }
export function useSellWineOffersNegotiationsFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SellWineOffersNegotiationsFiltersQuery, Types.SellWineOffersNegotiationsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SellWineOffersNegotiationsFiltersQuery, Types.SellWineOffersNegotiationsFiltersQueryVariables>(SellWineOffersNegotiationsFiltersDocument, options);
        }
export type SellWineOffersNegotiationsFiltersQueryHookResult = ReturnType<typeof useSellWineOffersNegotiationsFiltersQuery>;
export type SellWineOffersNegotiationsFiltersLazyQueryHookResult = ReturnType<typeof useSellWineOffersNegotiationsFiltersLazyQuery>;
export type SellWineOffersNegotiationsFiltersSuspenseQueryHookResult = ReturnType<typeof useSellWineOffersNegotiationsFiltersSuspenseQuery>;
export type SellWineOffersNegotiationsFiltersQueryResult = Apollo.QueryResult<Types.SellWineOffersNegotiationsFiltersQuery, Types.SellWineOffersNegotiationsFiltersQueryVariables>;
export const WineOfferRequestDocument = gql`
    query wineOfferRequest($id: ID!, $localeId: Int!, $sellerCompanyId: Int!, $companyId: ID!) {
  wineOfferRequest(id: $id) {
    id
    matches {
      productCount(sellerCompanyId: $sellerCompanyId)
      wineOfferCompanyCount(sellerCompanyId: $sellerCompanyId)
      wineOfferPublicCount(sellerCompanyId: $sellerCompanyId)
    }
    periodOfValidity
    createdAt
    query
    number
    comment
    status
    buyerCompany: buyer(companyId: $companyId) {
      ... on Company {
        id
        country {
          id
          localizedName(localeId: $localeId)
        }
        companyCategory {
          id
          masterName
          localizedName(localeId: $localeId)
        }
        tradeName
        representativeUser {
          id
          username
          name
          lastName
        }
      }
      ... on HiddenCompany {
        id
        country {
          id
          localizedName(localeId: $localeId)
        }
        companyCategory {
          id
          masterName
          localizedName(localeId: $localeId)
        }
        representativeUser {
          id
          username
          name
          lastName
        }
      }
    }
    ...requestFilters
    product {
      id
      bottleSize {
        id
        volumeML
      }
      packageType {
        id
        isDefault
        shortName
        localizedName(localeId: $localeId)
      }
      wine {
        id
        chemicalAnalysis {
          alcoholVolume
        }
        grapeVarieties {
          grapeVarietySynonym {
            id
            name
          }
          value
        }
        nationalGIType {
          id
          localizedName(localeId: $localeId)
          euGIType {
            id
            masterName
          }
        }
        nationalGITypeText
        geoIndications {
          id
          localizedName(localeId: $localeId)
          wineRegionsEU {
            id
            localizedName(localeId: $localeId)
          }
        }
        geoIndicationText
        vintage
        country {
          id
        }
        tradeName {
          id
          tradeName
          country {
            id
            localizedName(localeId: $localeId)
          }
        }
        wineType {
          id
          localizedName(localeId: $localeId)
        }
        wineColor {
          id
          localizedName(localeId: $localeId)
        }
        brand {
          id
          name
        }
        sweetnessLevel {
          id
          localizedName(localeId: $localeId)
        }
        keyWords
        specialClassifications {
          id
          name
        }
      }
    }
  }
}
    ${RequestFiltersFragmentDoc}`;

/**
 * __useWineOfferRequestQuery__
 *
 * To run a query within a React component, call `useWineOfferRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineOfferRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *      sellerCompanyId: // value for 'sellerCompanyId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useWineOfferRequestQuery(baseOptions: Apollo.QueryHookOptions<Types.WineOfferRequestQuery, Types.WineOfferRequestQueryVariables> & ({ variables: Types.WineOfferRequestQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineOfferRequestQuery, Types.WineOfferRequestQueryVariables>(WineOfferRequestDocument, options);
      }
export function useWineOfferRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineOfferRequestQuery, Types.WineOfferRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineOfferRequestQuery, Types.WineOfferRequestQueryVariables>(WineOfferRequestDocument, options);
        }
export function useWineOfferRequestSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineOfferRequestQuery, Types.WineOfferRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineOfferRequestQuery, Types.WineOfferRequestQueryVariables>(WineOfferRequestDocument, options);
        }
export type WineOfferRequestQueryHookResult = ReturnType<typeof useWineOfferRequestQuery>;
export type WineOfferRequestLazyQueryHookResult = ReturnType<typeof useWineOfferRequestLazyQuery>;
export type WineOfferRequestSuspenseQueryHookResult = ReturnType<typeof useWineOfferRequestSuspenseQuery>;
export type WineOfferRequestQueryResult = Apollo.QueryResult<Types.WineOfferRequestQuery, Types.WineOfferRequestQueryVariables>;
export const WineOfferRequestProductListDocument = gql`
    query wineOfferRequestProductList($id: ID!, $sellerCompanyId: Int!, $companyId: ID!, $localeId: Int! = 1, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $facetsInputs: [FacetInput!], $sort: ProductSort! = CREATED_AT_DESC, $query: String) {
  wineOfferRequest(id: $id) {
    id
    number
    createdAt
    comment
    periodOfValidity
    buyerCompany: buyer(companyId: $companyId) {
      ... on Company {
        id
        companyCategory {
          id
          masterName
        }
        tradeName
        representativeUser {
          id
          username
          lastName
          name
        }
        country {
          id
          codeISO
          localizedName(localeId: $localeId)
        }
      }
      ... on HiddenCompany {
        id
        companyCategory {
          id
          masterName
        }
        representativeUser {
          id
          username
          lastName
          name
        }
        country {
          id
          codeISO
          localizedName(localeId: $localeId)
        }
      }
    }
    ...requestFilters
    matches {
      wineOfferCompanyCount(sellerCompanyId: $sellerCompanyId)
      productsPagination(
        localeId: $localeId
        page: $page
        perPage: $perPage
        sort: $sort
        query: $query
        filter: {companyId: $sellerCompanyId, facetsInputs: $facetsInputs}
      ) {
        pageInfo {
          page
          perPage
          hasPreviousPage
          hasNextPage
          totalItems
          totalPages
        }
        items {
          id
          slug
          canOverrideFacts
          acceptability
          verified
          bottleImage {
            ...imageThumbnail48
          }
          bottleImageForPreview: bottleImage {
            ...wineOfferRequestProductListImageForPreview
          }
          bottleImageForUploadDialog: bottleImage {
            ...wineOfferRequestProductListImageForUploadDialog
          }
          frontLabelImage {
            ...imageThumbnail48
          }
          frontLabelImageForPreview: frontLabelImage {
            ...wineOfferRequestProductListImageForPreview
          }
          frontLabelImageForUploadDialog: frontLabelImage {
            ...wineOfferRequestProductListImageForUploadDialog
          }
          backLabelImage {
            ...imageThumbnail48
          }
          backLabelImageForPreview: backLabelImage {
            ...wineOfferRequestProductListImageForPreview
          }
          backLabelImageForUploadDialog: backLabelImage {
            ...wineOfferRequestProductListImageForUploadDialog
          }
          bottleSize {
            id
            volumeML
          }
          packageType {
            id
            isDefault
            shortName
            localizedName(localeId: $localeId)
          }
          recommendedRetailPrice
          currency {
            id
            code
          }
          wine {
            id
            tradeNameBrand
            tradeName {
              id
              tradeName
            }
            keyWords
            specialClassifications {
              id
              name
            }
            vintage
            wineType {
              id
              isDefault
              localizedName(localeId: $localeId)
            }
            wineColor {
              id
              localizedName(localeId: $localeId)
            }
            sweetnessLevel {
              id
              isDefault
              localizedName(localeId: $localeId)
            }
            country {
              id
              codeISO
              localizedName(localeId: $localeId)
            }
            geoIndicationText
            geoIndications {
              id
              localizedName(localeId: $localeId)
            }
            nationalGITypeText
            nationalGIType {
              id
              localizedName(localeId: $localeId)
              localizedShortName(localeId: $localeId)
            }
            brand {
              id
              name
            }
            keyWords
            verified
            rating
            authorUser {
              id
              username
            }
            updatedAt
            sources {
              id
              sourceType {
                id
              }
            }
            sourceAuthorUser {
              id
              username
            }
          }
        }
      }
    }
  }
}
    ${RequestFiltersFragmentDoc}
${ImageThumbnail48FragmentDoc}
${WineOfferRequestProductListImageForPreviewFragmentDoc}
${WineOfferRequestProductListImageForUploadDialogFragmentDoc}`;

/**
 * __useWineOfferRequestProductListQuery__
 *
 * To run a query within a React component, call `useWineOfferRequestProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineOfferRequestProductListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sellerCompanyId: // value for 'sellerCompanyId'
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      facetsInputs: // value for 'facetsInputs'
 *      sort: // value for 'sort'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useWineOfferRequestProductListQuery(baseOptions: Apollo.QueryHookOptions<Types.WineOfferRequestProductListQuery, Types.WineOfferRequestProductListQueryVariables> & ({ variables: Types.WineOfferRequestProductListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineOfferRequestProductListQuery, Types.WineOfferRequestProductListQueryVariables>(WineOfferRequestProductListDocument, options);
      }
export function useWineOfferRequestProductListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineOfferRequestProductListQuery, Types.WineOfferRequestProductListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineOfferRequestProductListQuery, Types.WineOfferRequestProductListQueryVariables>(WineOfferRequestProductListDocument, options);
        }
export function useWineOfferRequestProductListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineOfferRequestProductListQuery, Types.WineOfferRequestProductListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineOfferRequestProductListQuery, Types.WineOfferRequestProductListQueryVariables>(WineOfferRequestProductListDocument, options);
        }
export type WineOfferRequestProductListQueryHookResult = ReturnType<typeof useWineOfferRequestProductListQuery>;
export type WineOfferRequestProductListLazyQueryHookResult = ReturnType<typeof useWineOfferRequestProductListLazyQuery>;
export type WineOfferRequestProductListSuspenseQueryHookResult = ReturnType<typeof useWineOfferRequestProductListSuspenseQuery>;
export type WineOfferRequestProductListQueryResult = Apollo.QueryResult<Types.WineOfferRequestProductListQuery, Types.WineOfferRequestProductListQueryVariables>;
export const WineOfferRequestWineOffersListDocument = gql`
    query wineOfferRequestWineOffersList($id: ID!, $sellerCompanyId: Int!, $companyId: ID!, $localeId: Int! = 1, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $facetsInputs: [FacetInput!], $sort: WineOfferSort! = UPDATED_AT_DESC, $query: String) {
  wineOfferRequest(id: $id) {
    id
    number
    createdAt
    comment
    periodOfValidity
    buyerCompany: buyer(companyId: $companyId) {
      ... on Company {
        id
        companyCategory {
          id
        }
        tradeName
        representativeUser {
          id
          username
        }
        country {
          id
          codeISO
          localizedName(localeId: $localeId)
        }
      }
      ... on HiddenCompany {
        id
        companyCategory {
          id
          masterName
          localizedName(localeId: $localeId)
        }
        country {
          codeISO
          localizedName(localeId: $localeId)
        }
        representativeUser {
          id
          username
        }
      }
    }
    ...requestFilters
    matches {
      wineOfferCompanyCount(sellerCompanyId: $sellerCompanyId)
      wineOffersPagination(
        localeId: $localeId
        page: $page
        perPage: $perPage
        sort: $sort
        query: $query
        filter: {companyId: $sellerCompanyId, facetsInputs: $facetsInputs}
      ) {
        pageInfo {
          page
          perPage
          hasPreviousPage
          hasNextPage
          totalItems
          totalPages
        }
        items {
          ... on WineOfferBase {
            id
            isExclusiveRight
            wineOfferStatus {
              id
              code
            }
            wineOfferType {
              id
              code
            }
            shortlistedCount
            lot {
              bottleQuantity
            }
            price
            currency {
              id
              code
            }
            minimumDeliverySize
            incoterms {
              id
              code
            }
            warehouse {
              id
              country {
                codeISO
              }
              address
            }
            periodOfValidity
            product {
              id
              slug
              mainSynonym {
                ... on Product {
                  id
                }
              }
              bottleImage {
                imageUrl
                thumbnailUrl: imageUrl(transformations: {width: 48})
                thumbnailRetinaUrl: imageUrl(transformations: {width: 96})
              }
              bottleSize {
                id
                volumeML
              }
              packageType {
                id
                isDefault
                shortName
                localizedName(localeId: $localeId)
              }
              recommendedRetailPrice
              currency {
                id
                code
              }
              wine {
                id
                tradeNameBrand
                tradeName {
                  id
                  tradeName
                }
                keyWords
                specialClassifications {
                  id
                  name
                }
                vintage
                wineType {
                  id
                  isDefault
                  localizedName(localeId: $localeId)
                }
                wineColor {
                  id
                  localizedName(localeId: $localeId)
                }
                sweetnessLevel {
                  id
                  isDefault
                  localizedName(localeId: $localeId)
                }
                country {
                  id
                  codeISO
                  localizedName(localeId: $localeId)
                }
                geoIndicationText
                geoIndications {
                  id
                  localizedName(localeId: $localeId)
                }
                nationalGITypeText
                nationalGIType {
                  id
                  localizedName(localeId: $localeId)
                  localizedShortName(localeId: $localeId)
                }
                brand {
                  id
                  name
                }
                keyWords
                verified
                chemicalAnalysis {
                  id
                  alcoholVolume
                }
                rating
                authorUser {
                  id
                  username
                }
                updatedAt
                sources {
                  id
                  sourceType {
                    id
                  }
                }
                sourceAuthorUser {
                  id
                  username
                }
              }
            }
            exhibitions {
              ...exhibitionsDisplayInfo
            }
          }
          ... on WineOfferPublic {
            buyerCompanyCategory {
              id
              localizedName(localeId: $localeId)
            }
            wineOfferDistLimits {
              ...SellWineRequestFullWineOfferDist
            }
          }
          ... on WineOfferPublicDraft {
            draftCompanyCategory: buyerCompanyCategory {
              id
              localizedName(localeId: $localeId)
            }
            wineOfferDistLimits {
              ...SellWineRequestFullWineOfferDist
            }
          }
          ... on WineOfferCompanyDraft {
            buyerDraft: buyer(companyId: $companyId) {
              ... on Company {
                id
                tradeName
              }
            }
            wineOfferDistLimits {
              ...SellWineRequestFullWineOfferDist
            }
          }
          ... on WineOfferCompany {
            id
            company: buyer(companyId: $companyId) {
              ... on Company {
                id
                tradeName
                companyCategory {
                  id
                }
              }
              ... on HiddenCompany {
                id
                companyCategory {
                  id
                }
              }
            }
          }
          ... on WineOfferPersonal {
            isExclusiveRight
            buyerCompany: buyer(companyId: $companyId) {
              ... on Company {
                id
                tradeName
                companyCategory {
                  localizedName(localeId: $localeId)
                }
              }
              ... on HiddenCompany {
                id
                companyCategory {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${RequestFiltersFragmentDoc}
${ExhibitionsDisplayInfoFragmentDoc}
${SellWineRequestFullWineOfferDistFragmentDoc}`;

/**
 * __useWineOfferRequestWineOffersListQuery__
 *
 * To run a query within a React component, call `useWineOfferRequestWineOffersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestWineOffersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineOfferRequestWineOffersListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sellerCompanyId: // value for 'sellerCompanyId'
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      facetsInputs: // value for 'facetsInputs'
 *      sort: // value for 'sort'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useWineOfferRequestWineOffersListQuery(baseOptions: Apollo.QueryHookOptions<Types.WineOfferRequestWineOffersListQuery, Types.WineOfferRequestWineOffersListQueryVariables> & ({ variables: Types.WineOfferRequestWineOffersListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineOfferRequestWineOffersListQuery, Types.WineOfferRequestWineOffersListQueryVariables>(WineOfferRequestWineOffersListDocument, options);
      }
export function useWineOfferRequestWineOffersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineOfferRequestWineOffersListQuery, Types.WineOfferRequestWineOffersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineOfferRequestWineOffersListQuery, Types.WineOfferRequestWineOffersListQueryVariables>(WineOfferRequestWineOffersListDocument, options);
        }
export function useWineOfferRequestWineOffersListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineOfferRequestWineOffersListQuery, Types.WineOfferRequestWineOffersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineOfferRequestWineOffersListQuery, Types.WineOfferRequestWineOffersListQueryVariables>(WineOfferRequestWineOffersListDocument, options);
        }
export type WineOfferRequestWineOffersListQueryHookResult = ReturnType<typeof useWineOfferRequestWineOffersListQuery>;
export type WineOfferRequestWineOffersListLazyQueryHookResult = ReturnType<typeof useWineOfferRequestWineOffersListLazyQuery>;
export type WineOfferRequestWineOffersListSuspenseQueryHookResult = ReturnType<typeof useWineOfferRequestWineOffersListSuspenseQuery>;
export type WineOfferRequestWineOffersListQueryResult = Apollo.QueryResult<Types.WineOfferRequestWineOffersListQuery, Types.WineOfferRequestWineOffersListQueryVariables>;
export const WineOfferRequestsDocument = gql`
    query wineOfferRequests($localeId: Int!, $companyId: ID!, $sellerCompanyId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: WineOfferRequestSort!) {
  me {
    company(id: $companyId) {
      wineOfferRequestsPagination(
        page: $page
        perPage: $perPage
        isBuyer: false
        sort: $sort
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          totalPages
          totalItems
          page
          perPage
        }
        items {
          id
          matches {
            productCount(sellerCompanyId: $sellerCompanyId)
            wineOfferCompanyCount(sellerCompanyId: $sellerCompanyId)
            wineOfferPublicCount(sellerCompanyId: $sellerCompanyId)
          }
          periodOfValidity
          createdAt
          query
          number
          comment
          buyerCompany: buyer(companyId: $companyId) {
            ... on Company {
              id
              tradeName
              companyCategory {
                id
                masterName
                localizedName(localeId: $localeId)
              }
              postAddress
              country {
                id
                localizedName(localeId: $localeId)
                codeISO
              }
              postTerritory {
                masterName
              }
              representativeUser {
                id
                username
                name
                lastName
              }
            }
            ... on HiddenCompany {
              id
              companyCategory {
                id
                masterName
                localizedName(localeId: $localeId)
              }
              country {
                codeISO
                localizedName(localeId: $localeId)
              }
              representativeUser {
                id
                username
              }
            }
          }
          ...requestFilters
          product {
            id
            bottleSize {
              id
              volumeML
            }
            packageType {
              id
              isDefault
              shortName
              localizedName(localeId: $localeId)
            }
            wine {
              id
              chemicalAnalysis {
                alcoholVolume
              }
              grapeVarieties {
                grapeVarietySynonym {
                  id
                  name
                }
                value
              }
              nationalGIType {
                id
                localizedName(localeId: $localeId)
                euGIType {
                  id
                  masterName
                }
              }
              nationalGITypeText
              geoIndications {
                id
                localizedName(localeId: $localeId)
                wineRegionsEU {
                  id
                  localizedName(localeId: $localeId)
                }
              }
              geoIndicationText
              vintage
              country {
                id
              }
              tradeName {
                id
                tradeName
                country {
                  id
                  localizedName(localeId: $localeId)
                  codeISO
                }
              }
              wineType {
                id
                localizedName(localeId: $localeId)
              }
              wineColor {
                id
                localizedName(localeId: $localeId)
              }
              brand {
                id
                name
              }
              sweetnessLevel {
                id
                localizedName(localeId: $localeId)
              }
              keyWords
              specialClassifications {
                id
                name
              }
            }
          }
        }
      }
    }
  }
}
    ${RequestFiltersFragmentDoc}`;

/**
 * __useWineOfferRequestsQuery__
 *
 * To run a query within a React component, call `useWineOfferRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineOfferRequestsQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      companyId: // value for 'companyId'
 *      sellerCompanyId: // value for 'sellerCompanyId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useWineOfferRequestsQuery(baseOptions: Apollo.QueryHookOptions<Types.WineOfferRequestsQuery, Types.WineOfferRequestsQueryVariables> & ({ variables: Types.WineOfferRequestsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineOfferRequestsQuery, Types.WineOfferRequestsQueryVariables>(WineOfferRequestsDocument, options);
      }
export function useWineOfferRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineOfferRequestsQuery, Types.WineOfferRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineOfferRequestsQuery, Types.WineOfferRequestsQueryVariables>(WineOfferRequestsDocument, options);
        }
export function useWineOfferRequestsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineOfferRequestsQuery, Types.WineOfferRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineOfferRequestsQuery, Types.WineOfferRequestsQueryVariables>(WineOfferRequestsDocument, options);
        }
export type WineOfferRequestsQueryHookResult = ReturnType<typeof useWineOfferRequestsQuery>;
export type WineOfferRequestsLazyQueryHookResult = ReturnType<typeof useWineOfferRequestsLazyQuery>;
export type WineOfferRequestsSuspenseQueryHookResult = ReturnType<typeof useWineOfferRequestsSuspenseQuery>;
export type WineOfferRequestsQueryResult = Apollo.QueryResult<Types.WineOfferRequestsQuery, Types.WineOfferRequestsQueryVariables>;
export const ChangeScoreSystemDocument = gql`
    mutation changeScoreSystem($scoreSystemId: Int!, $localeId: Int!) {
  changeScoreSystem(scoreSystemId: $scoreSystemId) {
    id
    scoreSystem {
      id
      minScore
      maxScore
      localizedName(localeId: $localeId)
    }
  }
}
    `;
export type ChangeScoreSystemMutationFn = Apollo.MutationFunction<Types.ChangeScoreSystemMutation, Types.ChangeScoreSystemMutationVariables>;

/**
 * __useChangeScoreSystemMutation__
 *
 * To run a mutation, you first call `useChangeScoreSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeScoreSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeScoreSystemMutation, { data, loading, error }] = useChangeScoreSystemMutation({
 *   variables: {
 *      scoreSystemId: // value for 'scoreSystemId'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useChangeScoreSystemMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangeScoreSystemMutation, Types.ChangeScoreSystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ChangeScoreSystemMutation, Types.ChangeScoreSystemMutationVariables>(ChangeScoreSystemDocument, options);
      }
export type ChangeScoreSystemMutationHookResult = ReturnType<typeof useChangeScoreSystemMutation>;
export type ChangeScoreSystemMutationResult = Apollo.MutationResult<Types.ChangeScoreSystemMutation>;
export type ChangeScoreSystemMutationOptions = Apollo.BaseMutationOptions<Types.ChangeScoreSystemMutation, Types.ChangeScoreSystemMutationVariables>;
export const GetMeDocument = gql`
    query getMe($localeId: Int!) {
  me {
    id
    isVerifiedEmail
    hideBecomeRepresentative
    email
    name
    lastName
    username
    avatar {
      ...imageThumbnail40
    }
    avatar64: avatar {
      ...imageThumbnail64
    }
    companies {
      id
      tradeName
      producerTradeName {
        id
      }
      companyCategory {
        id
        masterName
        shortName: localizedShortName(localeId: $localeId)
      }
      country {
        id
        codeISO
        localizedName(localeId: $localeId)
      }
      isVerifiedUser
      avatar {
        ...imageThumbnail40
      }
      verificationStatus {
        id
        code
      }
      tour {
        id
        skipped
        completed
        tasks {
          id
          task
          completed
          step
          total
        }
      }
    }
    role {
      id
      code
    }
    scoreSystem {
      id
      localizedName(localeId: $localeId)
      minScore
      maxScore
    }
  }
}
    ${ImageThumbnail40FragmentDoc}
${ImageThumbnail64FragmentDoc}`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useGetMeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMeQuery, Types.GetMeQueryVariables> & ({ variables: Types.GetMeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMeQuery, Types.GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMeQuery, Types.GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMeQuery, Types.GetMeQueryVariables>(GetMeDocument, options);
        }
export function useGetMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetMeQuery, Types.GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetMeQuery, Types.GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeSuspenseQueryHookResult = ReturnType<typeof useGetMeSuspenseQuery>;
export type GetMeQueryResult = Apollo.QueryResult<Types.GetMeQuery, Types.GetMeQueryVariables>;
export const ChangeNotificationSettingsDocument = gql`
    mutation changeNotificationSettings($userSettings: UserNotificationSettingsInput, $companySettings: [CompanyNotificationSettingsInput!]) {
  changeNotificationSettings(
    userSettings: $userSettings
    companySettings: $companySettings
  ) {
    id
  }
}
    `;
export type ChangeNotificationSettingsMutationFn = Apollo.MutationFunction<Types.ChangeNotificationSettingsMutation, Types.ChangeNotificationSettingsMutationVariables>;

/**
 * __useChangeNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useChangeNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeNotificationSettingsMutation, { data, loading, error }] = useChangeNotificationSettingsMutation({
 *   variables: {
 *      userSettings: // value for 'userSettings'
 *      companySettings: // value for 'companySettings'
 *   },
 * });
 */
export function useChangeNotificationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangeNotificationSettingsMutation, Types.ChangeNotificationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ChangeNotificationSettingsMutation, Types.ChangeNotificationSettingsMutationVariables>(ChangeNotificationSettingsDocument, options);
      }
export type ChangeNotificationSettingsMutationHookResult = ReturnType<typeof useChangeNotificationSettingsMutation>;
export type ChangeNotificationSettingsMutationResult = Apollo.MutationResult<Types.ChangeNotificationSettingsMutation>;
export type ChangeNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<Types.ChangeNotificationSettingsMutation, Types.ChangeNotificationSettingsMutationVariables>;
export const NotificationSettingsDocument = gql`
    query notificationSettings {
  me {
    id
    notificationSettings {
      news
    }
    companies {
      id
      notificationSettings {
        offerConditionChanged
        offerAccepted
        offerDeclined
        wineOfferRequestCreated
        marketing
        wineReviewPublished
      }
    }
  }
}
    `;

/**
 * __useNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<Types.NotificationSettingsQuery, Types.NotificationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NotificationSettingsQuery, Types.NotificationSettingsQueryVariables>(NotificationSettingsDocument, options);
      }
export function useNotificationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NotificationSettingsQuery, Types.NotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NotificationSettingsQuery, Types.NotificationSettingsQueryVariables>(NotificationSettingsDocument, options);
        }
export function useNotificationSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.NotificationSettingsQuery, Types.NotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.NotificationSettingsQuery, Types.NotificationSettingsQueryVariables>(NotificationSettingsDocument, options);
        }
export type NotificationSettingsQueryHookResult = ReturnType<typeof useNotificationSettingsQuery>;
export type NotificationSettingsLazyQueryHookResult = ReturnType<typeof useNotificationSettingsLazyQuery>;
export type NotificationSettingsSuspenseQueryHookResult = ReturnType<typeof useNotificationSettingsSuspenseQuery>;
export type NotificationSettingsQueryResult = Apollo.QueryResult<Types.NotificationSettingsQuery, Types.NotificationSettingsQueryVariables>;
export const WineAcceptDocument = gql`
    mutation wineAccept($input: WineAcceptInput!) {
  wineAccept(input: $input) {
    recordId
    record {
      id
      acceptability
      verified
      canOverrideAcceptability
      shouldOverrideAcceptability
      updatedAt
      authorUser {
        username
      }
      sourceAuthorUser {
        name
        lastName
      }
    }
  }
}
    `;
export type WineAcceptMutationFn = Apollo.MutationFunction<Types.WineAcceptMutation, Types.WineAcceptMutationVariables>;

/**
 * __useWineAcceptMutation__
 *
 * To run a mutation, you first call `useWineAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineAcceptMutation, { data, loading, error }] = useWineAcceptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineAcceptMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineAcceptMutation, Types.WineAcceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineAcceptMutation, Types.WineAcceptMutationVariables>(WineAcceptDocument, options);
      }
export type WineAcceptMutationHookResult = ReturnType<typeof useWineAcceptMutation>;
export type WineAcceptMutationResult = Apollo.MutationResult<Types.WineAcceptMutation>;
export type WineAcceptMutationOptions = Apollo.BaseMutationOptions<Types.WineAcceptMutation, Types.WineAcceptMutationVariables>;
export const WineRefuseDocument = gql`
    mutation wineRefuse($input: WineRefuseInput!) {
  wineRefuse(input: $input) {
    record {
      id
      acceptability
      verified
      canOverrideAcceptability
      shouldOverrideAcceptability
      updatedAt
      authorUser {
        username
      }
      sourceAuthorUser {
        name
        lastName
      }
    }
  }
}
    `;
export type WineRefuseMutationFn = Apollo.MutationFunction<Types.WineRefuseMutation, Types.WineRefuseMutationVariables>;

/**
 * __useWineRefuseMutation__
 *
 * To run a mutation, you first call `useWineRefuseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineRefuseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineRefuseMutation, { data, loading, error }] = useWineRefuseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineRefuseMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineRefuseMutation, Types.WineRefuseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineRefuseMutation, Types.WineRefuseMutationVariables>(WineRefuseDocument, options);
      }
export type WineRefuseMutationHookResult = ReturnType<typeof useWineRefuseMutation>;
export type WineRefuseMutationResult = Apollo.MutationResult<Types.WineRefuseMutation>;
export type WineRefuseMutationOptions = Apollo.BaseMutationOptions<Types.WineRefuseMutation, Types.WineRefuseMutationVariables>;
export const WineOffersMarkAsReadDocument = gql`
    mutation wineOffersMarkAsRead($companyId: ID!, $wineOfferIds: [Int!]!) {
  wineOffersMarkAsRead(
    input: {companyId: $companyId, wineOfferIds: $wineOfferIds}
  ) {
    records {
      id
      ... on WineOfferBase {
        hasRead(companyId: $companyId)
      }
    }
  }
}
    `;
export type WineOffersMarkAsReadMutationFn = Apollo.MutationFunction<Types.WineOffersMarkAsReadMutation, Types.WineOffersMarkAsReadMutationVariables>;

/**
 * __useWineOffersMarkAsReadMutation__
 *
 * To run a mutation, you first call `useWineOffersMarkAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOffersMarkAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOffersMarkAsReadMutation, { data, loading, error }] = useWineOffersMarkAsReadMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      wineOfferIds: // value for 'wineOfferIds'
 *   },
 * });
 */
export function useWineOffersMarkAsReadMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOffersMarkAsReadMutation, Types.WineOffersMarkAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOffersMarkAsReadMutation, Types.WineOffersMarkAsReadMutationVariables>(WineOffersMarkAsReadDocument, options);
      }
export type WineOffersMarkAsReadMutationHookResult = ReturnType<typeof useWineOffersMarkAsReadMutation>;
export type WineOffersMarkAsReadMutationResult = Apollo.MutationResult<Types.WineOffersMarkAsReadMutation>;
export type WineOffersMarkAsReadMutationOptions = Apollo.BaseMutationOptions<Types.WineOffersMarkAsReadMutation, Types.WineOffersMarkAsReadMutationVariables>;
export const CountriesForMapDocument = gql`
    query countriesForMap {
  countries {
    id
    codeISO
  }
}
    `;

/**
 * __useCountriesForMapQuery__
 *
 * To run a query within a React component, call `useCountriesForMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesForMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesForMapQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesForMapQuery(baseOptions?: Apollo.QueryHookOptions<Types.CountriesForMapQuery, Types.CountriesForMapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountriesForMapQuery, Types.CountriesForMapQueryVariables>(CountriesForMapDocument, options);
      }
export function useCountriesForMapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountriesForMapQuery, Types.CountriesForMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountriesForMapQuery, Types.CountriesForMapQueryVariables>(CountriesForMapDocument, options);
        }
export function useCountriesForMapSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CountriesForMapQuery, Types.CountriesForMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CountriesForMapQuery, Types.CountriesForMapQueryVariables>(CountriesForMapDocument, options);
        }
export type CountriesForMapQueryHookResult = ReturnType<typeof useCountriesForMapQuery>;
export type CountriesForMapLazyQueryHookResult = ReturnType<typeof useCountriesForMapLazyQuery>;
export type CountriesForMapSuspenseQueryHookResult = ReturnType<typeof useCountriesForMapSuspenseQuery>;
export type CountriesForMapQueryResult = Apollo.QueryResult<Types.CountriesForMapQuery, Types.CountriesForMapQueryVariables>;
export const CountriesForPostCodeDocument = gql`
    query countriesForPostCode($localeId: Int!) {
  countries {
    id
    postCodeFormat
    postCodeMask
    postCodeName
    localizedPostCodeTooltip(localeId: $localeId)
    localizedPostCodeFormatError(localeId: $localeId)
  }
}
    `;

/**
 * __useCountriesForPostCodeQuery__
 *
 * To run a query within a React component, call `useCountriesForPostCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesForPostCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesForPostCodeQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useCountriesForPostCodeQuery(baseOptions: Apollo.QueryHookOptions<Types.CountriesForPostCodeQuery, Types.CountriesForPostCodeQueryVariables> & ({ variables: Types.CountriesForPostCodeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountriesForPostCodeQuery, Types.CountriesForPostCodeQueryVariables>(CountriesForPostCodeDocument, options);
      }
export function useCountriesForPostCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountriesForPostCodeQuery, Types.CountriesForPostCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountriesForPostCodeQuery, Types.CountriesForPostCodeQueryVariables>(CountriesForPostCodeDocument, options);
        }
export function useCountriesForPostCodeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CountriesForPostCodeQuery, Types.CountriesForPostCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CountriesForPostCodeQuery, Types.CountriesForPostCodeQueryVariables>(CountriesForPostCodeDocument, options);
        }
export type CountriesForPostCodeQueryHookResult = ReturnType<typeof useCountriesForPostCodeQuery>;
export type CountriesForPostCodeLazyQueryHookResult = ReturnType<typeof useCountriesForPostCodeLazyQuery>;
export type CountriesForPostCodeSuspenseQueryHookResult = ReturnType<typeof useCountriesForPostCodeSuspenseQuery>;
export type CountriesForPostCodeQueryResult = Apollo.QueryResult<Types.CountriesForPostCodeQuery, Types.CountriesForPostCodeQueryVariables>;
export const CreateCompaniesFromExternalDocument = gql`
    mutation createCompaniesFromExternal($input: CompanyCreateFromExternalInput!) {
  companyCreateFromExternal(input: $input) {
    companies {
      id
    }
    query {
      me {
        companies {
          id
        }
      }
    }
  }
}
    `;
export type CreateCompaniesFromExternalMutationFn = Apollo.MutationFunction<Types.CreateCompaniesFromExternalMutation, Types.CreateCompaniesFromExternalMutationVariables>;

/**
 * __useCreateCompaniesFromExternalMutation__
 *
 * To run a mutation, you first call `useCreateCompaniesFromExternalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompaniesFromExternalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompaniesFromExternalMutation, { data, loading, error }] = useCreateCompaniesFromExternalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompaniesFromExternalMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCompaniesFromExternalMutation, Types.CreateCompaniesFromExternalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCompaniesFromExternalMutation, Types.CreateCompaniesFromExternalMutationVariables>(CreateCompaniesFromExternalDocument, options);
      }
export type CreateCompaniesFromExternalMutationHookResult = ReturnType<typeof useCreateCompaniesFromExternalMutation>;
export type CreateCompaniesFromExternalMutationResult = Apollo.MutationResult<Types.CreateCompaniesFromExternalMutation>;
export type CreateCompaniesFromExternalMutationOptions = Apollo.BaseMutationOptions<Types.CreateCompaniesFromExternalMutation, Types.CreateCompaniesFromExternalMutationVariables>;
export const UserExternalCompaniesDocument = gql`
    query userExternalCompanies($localeId: Int!) {
  me {
    externalCompanies {
      id
      country {
        id
        localizedName(localeId: $localeId)
        codeISO
        legalEntityDiscriminator
        vatName
        identityNumberName
      }
      companyCategory {
        id
      }
      tradeName
      legalEntityDiscriminator
    }
  }
}
    `;

/**
 * __useUserExternalCompaniesQuery__
 *
 * To run a query within a React component, call `useUserExternalCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserExternalCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserExternalCompaniesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useUserExternalCompaniesQuery(baseOptions: Apollo.QueryHookOptions<Types.UserExternalCompaniesQuery, Types.UserExternalCompaniesQueryVariables> & ({ variables: Types.UserExternalCompaniesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserExternalCompaniesQuery, Types.UserExternalCompaniesQueryVariables>(UserExternalCompaniesDocument, options);
      }
export function useUserExternalCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserExternalCompaniesQuery, Types.UserExternalCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserExternalCompaniesQuery, Types.UserExternalCompaniesQueryVariables>(UserExternalCompaniesDocument, options);
        }
export function useUserExternalCompaniesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UserExternalCompaniesQuery, Types.UserExternalCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UserExternalCompaniesQuery, Types.UserExternalCompaniesQueryVariables>(UserExternalCompaniesDocument, options);
        }
export type UserExternalCompaniesQueryHookResult = ReturnType<typeof useUserExternalCompaniesQuery>;
export type UserExternalCompaniesLazyQueryHookResult = ReturnType<typeof useUserExternalCompaniesLazyQuery>;
export type UserExternalCompaniesSuspenseQueryHookResult = ReturnType<typeof useUserExternalCompaniesSuspenseQuery>;
export type UserExternalCompaniesQueryResult = Apollo.QueryResult<Types.UserExternalCompaniesQuery, Types.UserExternalCompaniesQueryVariables>;
export const UserHideBecomeRepresentativeDocument = gql`
    mutation userHideBecomeRepresentative {
  userHideBecomeRepresentative {
    id
    hideBecomeRepresentative
  }
}
    `;
export type UserHideBecomeRepresentativeMutationFn = Apollo.MutationFunction<Types.UserHideBecomeRepresentativeMutation, Types.UserHideBecomeRepresentativeMutationVariables>;

/**
 * __useUserHideBecomeRepresentativeMutation__
 *
 * To run a mutation, you first call `useUserHideBecomeRepresentativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserHideBecomeRepresentativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userHideBecomeRepresentativeMutation, { data, loading, error }] = useUserHideBecomeRepresentativeMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserHideBecomeRepresentativeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UserHideBecomeRepresentativeMutation, Types.UserHideBecomeRepresentativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UserHideBecomeRepresentativeMutation, Types.UserHideBecomeRepresentativeMutationVariables>(UserHideBecomeRepresentativeDocument, options);
      }
export type UserHideBecomeRepresentativeMutationHookResult = ReturnType<typeof useUserHideBecomeRepresentativeMutation>;
export type UserHideBecomeRepresentativeMutationResult = Apollo.MutationResult<Types.UserHideBecomeRepresentativeMutation>;
export type UserHideBecomeRepresentativeMutationOptions = Apollo.BaseMutationOptions<Types.UserHideBecomeRepresentativeMutation, Types.UserHideBecomeRepresentativeMutationVariables>;
export const WineOfferRequestParametersToProductFiltersDocument = gql`
    query wineOfferRequestParametersToProductFilters($input: WineOfferRequestParametersToProductFilters!, $localeId: Int!) {
  wineOfferRequestParametersToProductFilters(input: $input) {
    ... on FacetListFilterSelection {
      key
      byName(localeId: $localeId)
    }
    ... on FacetRangeFilterSelection {
      key
      minValue
      maxValue
    }
    ... on FacetBooleanFilterSelection {
      key
      value
    }
    ... on FacetRangeFilterSelection {
      key
      minValue
      maxValue
    }
    ... on FacetDateRangeFilterSelection {
      key
      fromDate
      toDate
    }
    ... on FacetComplexFilterSelection {
      key
      childrenInputs {
        ... on FacetBooleanFilterSelection {
          key
          value
        }
        ... on FacetListFilterSelection {
          key
          byName(localeId: $localeId)
        }
        ... on FacetRangeFilterSelection {
          key
          minValue
          maxValue
        }
      }
    }
  }
}
    `;

/**
 * __useWineOfferRequestParametersToProductFiltersQuery__
 *
 * To run a query within a React component, call `useWineOfferRequestParametersToProductFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestParametersToProductFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineOfferRequestParametersToProductFiltersQuery({
 *   variables: {
 *      input: // value for 'input'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useWineOfferRequestParametersToProductFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.WineOfferRequestParametersToProductFiltersQuery, Types.WineOfferRequestParametersToProductFiltersQueryVariables> & ({ variables: Types.WineOfferRequestParametersToProductFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineOfferRequestParametersToProductFiltersQuery, Types.WineOfferRequestParametersToProductFiltersQueryVariables>(WineOfferRequestParametersToProductFiltersDocument, options);
      }
export function useWineOfferRequestParametersToProductFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineOfferRequestParametersToProductFiltersQuery, Types.WineOfferRequestParametersToProductFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineOfferRequestParametersToProductFiltersQuery, Types.WineOfferRequestParametersToProductFiltersQueryVariables>(WineOfferRequestParametersToProductFiltersDocument, options);
        }
export function useWineOfferRequestParametersToProductFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineOfferRequestParametersToProductFiltersQuery, Types.WineOfferRequestParametersToProductFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineOfferRequestParametersToProductFiltersQuery, Types.WineOfferRequestParametersToProductFiltersQueryVariables>(WineOfferRequestParametersToProductFiltersDocument, options);
        }
export type WineOfferRequestParametersToProductFiltersQueryHookResult = ReturnType<typeof useWineOfferRequestParametersToProductFiltersQuery>;
export type WineOfferRequestParametersToProductFiltersLazyQueryHookResult = ReturnType<typeof useWineOfferRequestParametersToProductFiltersLazyQuery>;
export type WineOfferRequestParametersToProductFiltersSuspenseQueryHookResult = ReturnType<typeof useWineOfferRequestParametersToProductFiltersSuspenseQuery>;
export type WineOfferRequestParametersToProductFiltersQueryResult = Apollo.QueryResult<Types.WineOfferRequestParametersToProductFiltersQuery, Types.WineOfferRequestParametersToProductFiltersQueryVariables>;
export const WineOfferRequestParametersToWineOfferFiltersDocument = gql`
    query wineOfferRequestParametersToWineOfferFilters($input: WineOfferRequestParametersToWineOfferFilters!, $localeId: Int!) {
  wineOfferRequestParametersToWineOfferFilters(input: $input) {
    ... on FacetListFilterSelection {
      key
      byName(localeId: $localeId)
    }
    ... on FacetRangeFilterSelection {
      key
      minValue
      maxValue
    }
    ... on FacetBooleanFilterSelection {
      key
      value
    }
    ... on FacetRangeFilterSelection {
      key
      minValue
      maxValue
    }
    ... on FacetDateRangeFilterSelection {
      key
      fromDate
      toDate
    }
    ... on FacetComplexFilterSelection {
      key
      childrenInputs {
        ... on FacetBooleanFilterSelection {
          key
          value
        }
        ... on FacetListFilterSelection {
          key
          byName(localeId: $localeId)
        }
        ... on FacetRangeFilterSelection {
          key
          minValue
          maxValue
        }
      }
    }
  }
}
    `;

/**
 * __useWineOfferRequestParametersToWineOfferFiltersQuery__
 *
 * To run a query within a React component, call `useWineOfferRequestParametersToWineOfferFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestParametersToWineOfferFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineOfferRequestParametersToWineOfferFiltersQuery({
 *   variables: {
 *      input: // value for 'input'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useWineOfferRequestParametersToWineOfferFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.WineOfferRequestParametersToWineOfferFiltersQuery, Types.WineOfferRequestParametersToWineOfferFiltersQueryVariables> & ({ variables: Types.WineOfferRequestParametersToWineOfferFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineOfferRequestParametersToWineOfferFiltersQuery, Types.WineOfferRequestParametersToWineOfferFiltersQueryVariables>(WineOfferRequestParametersToWineOfferFiltersDocument, options);
      }
export function useWineOfferRequestParametersToWineOfferFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineOfferRequestParametersToWineOfferFiltersQuery, Types.WineOfferRequestParametersToWineOfferFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineOfferRequestParametersToWineOfferFiltersQuery, Types.WineOfferRequestParametersToWineOfferFiltersQueryVariables>(WineOfferRequestParametersToWineOfferFiltersDocument, options);
        }
export function useWineOfferRequestParametersToWineOfferFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineOfferRequestParametersToWineOfferFiltersQuery, Types.WineOfferRequestParametersToWineOfferFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineOfferRequestParametersToWineOfferFiltersQuery, Types.WineOfferRequestParametersToWineOfferFiltersQueryVariables>(WineOfferRequestParametersToWineOfferFiltersDocument, options);
        }
export type WineOfferRequestParametersToWineOfferFiltersQueryHookResult = ReturnType<typeof useWineOfferRequestParametersToWineOfferFiltersQuery>;
export type WineOfferRequestParametersToWineOfferFiltersLazyQueryHookResult = ReturnType<typeof useWineOfferRequestParametersToWineOfferFiltersLazyQuery>;
export type WineOfferRequestParametersToWineOfferFiltersSuspenseQueryHookResult = ReturnType<typeof useWineOfferRequestParametersToWineOfferFiltersSuspenseQuery>;
export type WineOfferRequestParametersToWineOfferFiltersQueryResult = Apollo.QueryResult<Types.WineOfferRequestParametersToWineOfferFiltersQuery, Types.WineOfferRequestParametersToWineOfferFiltersQueryVariables>;
export const WineOfferRequestStatsDocument = gql`
    query wineOfferRequestStats($input: WineOfferRequestStatsInput!, $localeId: Int!) {
  wineOfferRequestStats(input: $input) {
    sellerCount
    sellerWithEmailCount
    companyActiveUserCount
    companyWithOfferCount
    bestOfferPrice {
      price
      currency {
        id
        code
      }
    }
    isBroad
    minimalRecommendedRetailPrice {
      price {
        price
        currency {
          id
          code
        }
      }
      country {
        codeISO
        localizedName(localeId: $localeId)
      }
      bottleSize {
        volumeML
      }
    }
    minimalRetailPrice {
      price {
        price
        currency {
          id
          code
        }
      }
      country {
        codeISO
        localizedName(localeId: $localeId)
      }
      bottleSize {
        volumeML
      }
    }
    bestWwlScore
    potentialNumberOfOffers {
      value
      isForecast
    }
    potentialBestOfferPrice {
      price
      currency {
        id
        code
      }
      isForecast
    }
  }
}
    `;

/**
 * __useWineOfferRequestStatsQuery__
 *
 * To run a query within a React component, call `useWineOfferRequestStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineOfferRequestStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useWineOfferRequestStatsQuery(baseOptions: Apollo.QueryHookOptions<Types.WineOfferRequestStatsQuery, Types.WineOfferRequestStatsQueryVariables> & ({ variables: Types.WineOfferRequestStatsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineOfferRequestStatsQuery, Types.WineOfferRequestStatsQueryVariables>(WineOfferRequestStatsDocument, options);
      }
export function useWineOfferRequestStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineOfferRequestStatsQuery, Types.WineOfferRequestStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineOfferRequestStatsQuery, Types.WineOfferRequestStatsQueryVariables>(WineOfferRequestStatsDocument, options);
        }
export function useWineOfferRequestStatsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineOfferRequestStatsQuery, Types.WineOfferRequestStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineOfferRequestStatsQuery, Types.WineOfferRequestStatsQueryVariables>(WineOfferRequestStatsDocument, options);
        }
export type WineOfferRequestStatsQueryHookResult = ReturnType<typeof useWineOfferRequestStatsQuery>;
export type WineOfferRequestStatsLazyQueryHookResult = ReturnType<typeof useWineOfferRequestStatsLazyQuery>;
export type WineOfferRequestStatsSuspenseQueryHookResult = ReturnType<typeof useWineOfferRequestStatsSuspenseQuery>;
export type WineOfferRequestStatsQueryResult = Apollo.QueryResult<Types.WineOfferRequestStatsQuery, Types.WineOfferRequestStatsQueryVariables>;
export const CompanyContactsDocument = gql`
    query companyContacts($id: ID!, $offerId: Int!, $withWineOffer: Boolean!, $withCrmOrganization: Boolean!, $localeId: Int!) {
  company(id: $id) {
    ...companyContactsData
    crmOrganization @include(if: $withCrmOrganization) {
      id
      link
    }
  }
  wineOffer(id: $offerId) @include(if: $withWineOffer) {
    ...wineOfferContacts
  }
}
    ${CompanyContactsDataFragmentDoc}
${WineOfferContactsFragmentDoc}`;

/**
 * __useCompanyContactsQuery__
 *
 * To run a query within a React component, call `useCompanyContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyContactsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      offerId: // value for 'offerId'
 *      withWineOffer: // value for 'withWineOffer'
 *      withCrmOrganization: // value for 'withCrmOrganization'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useCompanyContactsQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyContactsQuery, Types.CompanyContactsQueryVariables> & ({ variables: Types.CompanyContactsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyContactsQuery, Types.CompanyContactsQueryVariables>(CompanyContactsDocument, options);
      }
export function useCompanyContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyContactsQuery, Types.CompanyContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyContactsQuery, Types.CompanyContactsQueryVariables>(CompanyContactsDocument, options);
        }
export function useCompanyContactsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CompanyContactsQuery, Types.CompanyContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompanyContactsQuery, Types.CompanyContactsQueryVariables>(CompanyContactsDocument, options);
        }
export type CompanyContactsQueryHookResult = ReturnType<typeof useCompanyContactsQuery>;
export type CompanyContactsLazyQueryHookResult = ReturnType<typeof useCompanyContactsLazyQuery>;
export type CompanyContactsSuspenseQueryHookResult = ReturnType<typeof useCompanyContactsSuspenseQuery>;
export type CompanyContactsQueryResult = Apollo.QueryResult<Types.CompanyContactsQuery, Types.CompanyContactsQueryVariables>;
export const CountriesForSelectBoxDocument = gql`
    query countriesForSelectBox($localeId: Int!) {
  countries {
    id
    localizedName(localeId: $localeId)
    codeISO
    masterName
    postCodeName
    isEUMember
  }
}
    `;

/**
 * __useCountriesForSelectBoxQuery__
 *
 * To run a query within a React component, call `useCountriesForSelectBoxQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesForSelectBoxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesForSelectBoxQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useCountriesForSelectBoxQuery(baseOptions: Apollo.QueryHookOptions<Types.CountriesForSelectBoxQuery, Types.CountriesForSelectBoxQueryVariables> & ({ variables: Types.CountriesForSelectBoxQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountriesForSelectBoxQuery, Types.CountriesForSelectBoxQueryVariables>(CountriesForSelectBoxDocument, options);
      }
export function useCountriesForSelectBoxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountriesForSelectBoxQuery, Types.CountriesForSelectBoxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountriesForSelectBoxQuery, Types.CountriesForSelectBoxQueryVariables>(CountriesForSelectBoxDocument, options);
        }
export function useCountriesForSelectBoxSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CountriesForSelectBoxQuery, Types.CountriesForSelectBoxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CountriesForSelectBoxQuery, Types.CountriesForSelectBoxQueryVariables>(CountriesForSelectBoxDocument, options);
        }
export type CountriesForSelectBoxQueryHookResult = ReturnType<typeof useCountriesForSelectBoxQuery>;
export type CountriesForSelectBoxLazyQueryHookResult = ReturnType<typeof useCountriesForSelectBoxLazyQuery>;
export type CountriesForSelectBoxSuspenseQueryHookResult = ReturnType<typeof useCountriesForSelectBoxSuspenseQuery>;
export type CountriesForSelectBoxQueryResult = Apollo.QueryResult<Types.CountriesForSelectBoxQuery, Types.CountriesForSelectBoxQueryVariables>;
export const IncotermsListDocument = gql`
    query incotermsList($localeId: Int!) {
  incoterms {
    id
    localizedShortDescription(localeId: $localeId)
    code
  }
}
    `;

/**
 * __useIncotermsListQuery__
 *
 * To run a query within a React component, call `useIncotermsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncotermsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncotermsListQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useIncotermsListQuery(baseOptions: Apollo.QueryHookOptions<Types.IncotermsListQuery, Types.IncotermsListQueryVariables> & ({ variables: Types.IncotermsListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IncotermsListQuery, Types.IncotermsListQueryVariables>(IncotermsListDocument, options);
      }
export function useIncotermsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IncotermsListQuery, Types.IncotermsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IncotermsListQuery, Types.IncotermsListQueryVariables>(IncotermsListDocument, options);
        }
export function useIncotermsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.IncotermsListQuery, Types.IncotermsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IncotermsListQuery, Types.IncotermsListQueryVariables>(IncotermsListDocument, options);
        }
export type IncotermsListQueryHookResult = ReturnType<typeof useIncotermsListQuery>;
export type IncotermsListLazyQueryHookResult = ReturnType<typeof useIncotermsListLazyQuery>;
export type IncotermsListSuspenseQueryHookResult = ReturnType<typeof useIncotermsListSuspenseQuery>;
export type IncotermsListQueryResult = Apollo.QueryResult<Types.IncotermsListQuery, Types.IncotermsListQueryVariables>;
export const CreateProductELabelDocument = gql`
    mutation createProductELabel($input: ProductELabelCreateInput!) {
  productELabelCreate(input: $input) {
    recordId
    record {
      id
      shortUrl
    }
  }
}
    `;
export type CreateProductELabelMutationFn = Apollo.MutationFunction<Types.CreateProductELabelMutation, Types.CreateProductELabelMutationVariables>;

/**
 * __useCreateProductELabelMutation__
 *
 * To run a mutation, you first call `useCreateProductELabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductELabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductELabelMutation, { data, loading, error }] = useCreateProductELabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductELabelMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateProductELabelMutation, Types.CreateProductELabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateProductELabelMutation, Types.CreateProductELabelMutationVariables>(CreateProductELabelDocument, options);
      }
export type CreateProductELabelMutationHookResult = ReturnType<typeof useCreateProductELabelMutation>;
export type CreateProductELabelMutationResult = Apollo.MutationResult<Types.CreateProductELabelMutation>;
export type CreateProductELabelMutationOptions = Apollo.BaseMutationOptions<Types.CreateProductELabelMutation, Types.CreateProductELabelMutationVariables>;
export const GetProductsToAddELabelDocument = gql`
    query getProductsToAddELabel($localeId: Int!, $companyId: ID!, $query: String!, $limit: PositiveInt! = 20) {
  me {
    id
    company(id: $companyId) {
      id
      producerTradeName {
        productsPagination(localeId: $localeId, perPage: $limit, query: $query) {
          items {
            ...AddELabelProduct
          }
        }
      }
    }
  }
}
    ${AddELabelProductFragmentDoc}`;

/**
 * __useGetProductsToAddELabelQuery__
 *
 * To run a query within a React component, call `useGetProductsToAddELabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsToAddELabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsToAddELabelQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      companyId: // value for 'companyId'
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetProductsToAddELabelQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProductsToAddELabelQuery, Types.GetProductsToAddELabelQueryVariables> & ({ variables: Types.GetProductsToAddELabelQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProductsToAddELabelQuery, Types.GetProductsToAddELabelQueryVariables>(GetProductsToAddELabelDocument, options);
      }
export function useGetProductsToAddELabelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProductsToAddELabelQuery, Types.GetProductsToAddELabelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProductsToAddELabelQuery, Types.GetProductsToAddELabelQueryVariables>(GetProductsToAddELabelDocument, options);
        }
export function useGetProductsToAddELabelSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetProductsToAddELabelQuery, Types.GetProductsToAddELabelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetProductsToAddELabelQuery, Types.GetProductsToAddELabelQueryVariables>(GetProductsToAddELabelDocument, options);
        }
export type GetProductsToAddELabelQueryHookResult = ReturnType<typeof useGetProductsToAddELabelQuery>;
export type GetProductsToAddELabelLazyQueryHookResult = ReturnType<typeof useGetProductsToAddELabelLazyQuery>;
export type GetProductsToAddELabelSuspenseQueryHookResult = ReturnType<typeof useGetProductsToAddELabelSuspenseQuery>;
export type GetProductsToAddELabelQueryResult = Apollo.QueryResult<Types.GetProductsToAddELabelQuery, Types.GetProductsToAddELabelQueryVariables>;
export const PreloadProductForAddELabelModalDocument = gql`
    query preloadProductForAddELabelModal($productId: ID!, $localeId: Int!) {
  product(id: $productId) {
    ...AddELabelProduct
  }
}
    ${AddELabelProductFragmentDoc}`;

/**
 * __usePreloadProductForAddELabelModalQuery__
 *
 * To run a query within a React component, call `usePreloadProductForAddELabelModalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreloadProductForAddELabelModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreloadProductForAddELabelModalQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function usePreloadProductForAddELabelModalQuery(baseOptions: Apollo.QueryHookOptions<Types.PreloadProductForAddELabelModalQuery, Types.PreloadProductForAddELabelModalQueryVariables> & ({ variables: Types.PreloadProductForAddELabelModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PreloadProductForAddELabelModalQuery, Types.PreloadProductForAddELabelModalQueryVariables>(PreloadProductForAddELabelModalDocument, options);
      }
export function usePreloadProductForAddELabelModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PreloadProductForAddELabelModalQuery, Types.PreloadProductForAddELabelModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PreloadProductForAddELabelModalQuery, Types.PreloadProductForAddELabelModalQueryVariables>(PreloadProductForAddELabelModalDocument, options);
        }
export function usePreloadProductForAddELabelModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PreloadProductForAddELabelModalQuery, Types.PreloadProductForAddELabelModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PreloadProductForAddELabelModalQuery, Types.PreloadProductForAddELabelModalQueryVariables>(PreloadProductForAddELabelModalDocument, options);
        }
export type PreloadProductForAddELabelModalQueryHookResult = ReturnType<typeof usePreloadProductForAddELabelModalQuery>;
export type PreloadProductForAddELabelModalLazyQueryHookResult = ReturnType<typeof usePreloadProductForAddELabelModalLazyQuery>;
export type PreloadProductForAddELabelModalSuspenseQueryHookResult = ReturnType<typeof usePreloadProductForAddELabelModalSuspenseQuery>;
export type PreloadProductForAddELabelModalQueryResult = Apollo.QueryResult<Types.PreloadProductForAddELabelModalQuery, Types.PreloadProductForAddELabelModalQueryVariables>;
export const LanguagesListForELabelDocument = gql`
    query languagesListForELabel {
  locales(onlyEUOfficial: true) {
    id
    name
    locale
  }
}
    `;

/**
 * __useLanguagesListForELabelQuery__
 *
 * To run a query within a React component, call `useLanguagesListForELabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguagesListForELabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguagesListForELabelQuery({
 *   variables: {
 *   },
 * });
 */
export function useLanguagesListForELabelQuery(baseOptions?: Apollo.QueryHookOptions<Types.LanguagesListForELabelQuery, Types.LanguagesListForELabelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LanguagesListForELabelQuery, Types.LanguagesListForELabelQueryVariables>(LanguagesListForELabelDocument, options);
      }
export function useLanguagesListForELabelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LanguagesListForELabelQuery, Types.LanguagesListForELabelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LanguagesListForELabelQuery, Types.LanguagesListForELabelQueryVariables>(LanguagesListForELabelDocument, options);
        }
export function useLanguagesListForELabelSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LanguagesListForELabelQuery, Types.LanguagesListForELabelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LanguagesListForELabelQuery, Types.LanguagesListForELabelQueryVariables>(LanguagesListForELabelDocument, options);
        }
export type LanguagesListForELabelQueryHookResult = ReturnType<typeof useLanguagesListForELabelQuery>;
export type LanguagesListForELabelLazyQueryHookResult = ReturnType<typeof useLanguagesListForELabelLazyQuery>;
export type LanguagesListForELabelSuspenseQueryHookResult = ReturnType<typeof useLanguagesListForELabelSuspenseQuery>;
export type LanguagesListForELabelQueryResult = Apollo.QueryResult<Types.LanguagesListForELabelQuery, Types.LanguagesListForELabelQueryVariables>;
export const SuspiciousWordSearchDocument = gql`
    query suspiciousWordSearch($query: String) {
  suspiciousWordSearch(query: $query) {
    query
    word
    rightField
    highlight
  }
}
    `;

/**
 * __useSuspiciousWordSearchQuery__
 *
 * To run a query within a React component, call `useSuspiciousWordSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuspiciousWordSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuspiciousWordSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSuspiciousWordSearchQuery(baseOptions?: Apollo.QueryHookOptions<Types.SuspiciousWordSearchQuery, Types.SuspiciousWordSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SuspiciousWordSearchQuery, Types.SuspiciousWordSearchQueryVariables>(SuspiciousWordSearchDocument, options);
      }
export function useSuspiciousWordSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SuspiciousWordSearchQuery, Types.SuspiciousWordSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SuspiciousWordSearchQuery, Types.SuspiciousWordSearchQueryVariables>(SuspiciousWordSearchDocument, options);
        }
export function useSuspiciousWordSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SuspiciousWordSearchQuery, Types.SuspiciousWordSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SuspiciousWordSearchQuery, Types.SuspiciousWordSearchQueryVariables>(SuspiciousWordSearchDocument, options);
        }
export type SuspiciousWordSearchQueryHookResult = ReturnType<typeof useSuspiciousWordSearchQuery>;
export type SuspiciousWordSearchLazyQueryHookResult = ReturnType<typeof useSuspiciousWordSearchLazyQuery>;
export type SuspiciousWordSearchSuspenseQueryHookResult = ReturnType<typeof useSuspiciousWordSearchSuspenseQuery>;
export type SuspiciousWordSearchQueryResult = Apollo.QueryResult<Types.SuspiciousWordSearchQuery, Types.SuspiciousWordSearchQueryVariables>;
export const CompanySendVerificationEmailDocument = gql`
    mutation companySendVerificationEmail($companyId: ID!, $localeId: Int!, $email: EmailAddress!) {
  companySendVerificationEmail(
    input: {companyId: $companyId, email: $email, localeId: $localeId}
  ) {
    success: _
  }
}
    `;
export type CompanySendVerificationEmailMutationFn = Apollo.MutationFunction<Types.CompanySendVerificationEmailMutation, Types.CompanySendVerificationEmailMutationVariables>;

/**
 * __useCompanySendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useCompanySendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanySendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companySendVerificationEmailMutation, { data, loading, error }] = useCompanySendVerificationEmailMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCompanySendVerificationEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompanySendVerificationEmailMutation, Types.CompanySendVerificationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompanySendVerificationEmailMutation, Types.CompanySendVerificationEmailMutationVariables>(CompanySendVerificationEmailDocument, options);
      }
export type CompanySendVerificationEmailMutationHookResult = ReturnType<typeof useCompanySendVerificationEmailMutation>;
export type CompanySendVerificationEmailMutationResult = Apollo.MutationResult<Types.CompanySendVerificationEmailMutation>;
export type CompanySendVerificationEmailMutationOptions = Apollo.BaseMutationOptions<Types.CompanySendVerificationEmailMutation, Types.CompanySendVerificationEmailMutationVariables>;
export const CompanySendVerificationEmailToRepresenativeDocument = gql`
    mutation companySendVerificationEmailToRepresenative($companyId: ID!, $localeId: Int!, $representativeUserId: Int!) {
  companySendVerificationEmailToRepresenative(
    input: {companyId: $companyId, localeId: $localeId, representativeUserId: $representativeUserId}
  ) {
    success: _
  }
}
    `;
export type CompanySendVerificationEmailToRepresenativeMutationFn = Apollo.MutationFunction<Types.CompanySendVerificationEmailToRepresenativeMutation, Types.CompanySendVerificationEmailToRepresenativeMutationVariables>;

/**
 * __useCompanySendVerificationEmailToRepresenativeMutation__
 *
 * To run a mutation, you first call `useCompanySendVerificationEmailToRepresenativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanySendVerificationEmailToRepresenativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companySendVerificationEmailToRepresenativeMutation, { data, loading, error }] = useCompanySendVerificationEmailToRepresenativeMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      representativeUserId: // value for 'representativeUserId'
 *   },
 * });
 */
export function useCompanySendVerificationEmailToRepresenativeMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompanySendVerificationEmailToRepresenativeMutation, Types.CompanySendVerificationEmailToRepresenativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompanySendVerificationEmailToRepresenativeMutation, Types.CompanySendVerificationEmailToRepresenativeMutationVariables>(CompanySendVerificationEmailToRepresenativeDocument, options);
      }
export type CompanySendVerificationEmailToRepresenativeMutationHookResult = ReturnType<typeof useCompanySendVerificationEmailToRepresenativeMutation>;
export type CompanySendVerificationEmailToRepresenativeMutationResult = Apollo.MutationResult<Types.CompanySendVerificationEmailToRepresenativeMutation>;
export type CompanySendVerificationEmailToRepresenativeMutationOptions = Apollo.BaseMutationOptions<Types.CompanySendVerificationEmailToRepresenativeMutation, Types.CompanySendVerificationEmailToRepresenativeMutationVariables>;
export const CompanySendVerificationRequestDocument = gql`
    mutation companySendVerificationRequest($companyId: ID!) {
  companyVerificationRequest(input: {companyId: $companyId}) {
    record {
      id
      verificationStatus {
        id
        code
      }
    }
  }
}
    `;
export type CompanySendVerificationRequestMutationFn = Apollo.MutationFunction<Types.CompanySendVerificationRequestMutation, Types.CompanySendVerificationRequestMutationVariables>;

/**
 * __useCompanySendVerificationRequestMutation__
 *
 * To run a mutation, you first call `useCompanySendVerificationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanySendVerificationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companySendVerificationRequestMutation, { data, loading, error }] = useCompanySendVerificationRequestMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanySendVerificationRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompanySendVerificationRequestMutation, Types.CompanySendVerificationRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompanySendVerificationRequestMutation, Types.CompanySendVerificationRequestMutationVariables>(CompanySendVerificationRequestDocument, options);
      }
export type CompanySendVerificationRequestMutationHookResult = ReturnType<typeof useCompanySendVerificationRequestMutation>;
export type CompanySendVerificationRequestMutationResult = Apollo.MutationResult<Types.CompanySendVerificationRequestMutation>;
export type CompanySendVerificationRequestMutationOptions = Apollo.BaseMutationOptions<Types.CompanySendVerificationRequestMutation, Types.CompanySendVerificationRequestMutationVariables>;
export const GetCompanyRepresentativesDocument = gql`
    query getCompanyRepresentatives($companyId: ID!) {
  me {
    id
    company(id: $companyId) {
      id
      website
      verificationStatus {
        id
        code
      }
      representativeGroup {
        companies(verifiedOnly: true) {
          id
          representativeUser {
            id
            username
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCompanyRepresentativesQuery__
 *
 * To run a query within a React component, call `useGetCompanyRepresentativesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyRepresentativesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyRepresentativesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyRepresentativesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCompanyRepresentativesQuery, Types.GetCompanyRepresentativesQueryVariables> & ({ variables: Types.GetCompanyRepresentativesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCompanyRepresentativesQuery, Types.GetCompanyRepresentativesQueryVariables>(GetCompanyRepresentativesDocument, options);
      }
export function useGetCompanyRepresentativesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCompanyRepresentativesQuery, Types.GetCompanyRepresentativesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCompanyRepresentativesQuery, Types.GetCompanyRepresentativesQueryVariables>(GetCompanyRepresentativesDocument, options);
        }
export function useGetCompanyRepresentativesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetCompanyRepresentativesQuery, Types.GetCompanyRepresentativesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetCompanyRepresentativesQuery, Types.GetCompanyRepresentativesQueryVariables>(GetCompanyRepresentativesDocument, options);
        }
export type GetCompanyRepresentativesQueryHookResult = ReturnType<typeof useGetCompanyRepresentativesQuery>;
export type GetCompanyRepresentativesLazyQueryHookResult = ReturnType<typeof useGetCompanyRepresentativesLazyQuery>;
export type GetCompanyRepresentativesSuspenseQueryHookResult = ReturnType<typeof useGetCompanyRepresentativesSuspenseQuery>;
export type GetCompanyRepresentativesQueryResult = Apollo.QueryResult<Types.GetCompanyRepresentativesQuery, Types.GetCompanyRepresentativesQueryVariables>;
export const WebsiteAndEmailHaveSameDomainDocument = gql`
    query websiteAndEmailHaveSameDomain($website: String, $email: String) {
  websiteAndEmailHaveSameDomain(website: $website, email: $email)
}
    `;

/**
 * __useWebsiteAndEmailHaveSameDomainQuery__
 *
 * To run a query within a React component, call `useWebsiteAndEmailHaveSameDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteAndEmailHaveSameDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteAndEmailHaveSameDomainQuery({
 *   variables: {
 *      website: // value for 'website'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useWebsiteAndEmailHaveSameDomainQuery(baseOptions?: Apollo.QueryHookOptions<Types.WebsiteAndEmailHaveSameDomainQuery, Types.WebsiteAndEmailHaveSameDomainQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WebsiteAndEmailHaveSameDomainQuery, Types.WebsiteAndEmailHaveSameDomainQueryVariables>(WebsiteAndEmailHaveSameDomainDocument, options);
      }
export function useWebsiteAndEmailHaveSameDomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WebsiteAndEmailHaveSameDomainQuery, Types.WebsiteAndEmailHaveSameDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WebsiteAndEmailHaveSameDomainQuery, Types.WebsiteAndEmailHaveSameDomainQueryVariables>(WebsiteAndEmailHaveSameDomainDocument, options);
        }
export function useWebsiteAndEmailHaveSameDomainSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WebsiteAndEmailHaveSameDomainQuery, Types.WebsiteAndEmailHaveSameDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WebsiteAndEmailHaveSameDomainQuery, Types.WebsiteAndEmailHaveSameDomainQueryVariables>(WebsiteAndEmailHaveSameDomainDocument, options);
        }
export type WebsiteAndEmailHaveSameDomainQueryHookResult = ReturnType<typeof useWebsiteAndEmailHaveSameDomainQuery>;
export type WebsiteAndEmailHaveSameDomainLazyQueryHookResult = ReturnType<typeof useWebsiteAndEmailHaveSameDomainLazyQuery>;
export type WebsiteAndEmailHaveSameDomainSuspenseQueryHookResult = ReturnType<typeof useWebsiteAndEmailHaveSameDomainSuspenseQuery>;
export type WebsiteAndEmailHaveSameDomainQueryResult = Apollo.QueryResult<Types.WebsiteAndEmailHaveSameDomainQuery, Types.WebsiteAndEmailHaveSameDomainQueryVariables>;
export const VerifyDataDocument = gql`
    query verifyData($id: ID!) {
  me {
    id
    company(id: $id) {
      id
      isVerifiedUser
      verificationStatus {
        id
        code
      }
    }
  }
}
    `;

/**
 * __useVerifyDataQuery__
 *
 * To run a query within a React component, call `useVerifyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVerifyDataQuery(baseOptions: Apollo.QueryHookOptions<Types.VerifyDataQuery, Types.VerifyDataQueryVariables> & ({ variables: Types.VerifyDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.VerifyDataQuery, Types.VerifyDataQueryVariables>(VerifyDataDocument, options);
      }
export function useVerifyDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.VerifyDataQuery, Types.VerifyDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.VerifyDataQuery, Types.VerifyDataQueryVariables>(VerifyDataDocument, options);
        }
export function useVerifyDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.VerifyDataQuery, Types.VerifyDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.VerifyDataQuery, Types.VerifyDataQueryVariables>(VerifyDataDocument, options);
        }
export type VerifyDataQueryHookResult = ReturnType<typeof useVerifyDataQuery>;
export type VerifyDataLazyQueryHookResult = ReturnType<typeof useVerifyDataLazyQuery>;
export type VerifyDataSuspenseQueryHookResult = ReturnType<typeof useVerifyDataSuspenseQuery>;
export type VerifyDataQueryResult = Apollo.QueryResult<Types.VerifyDataQuery, Types.VerifyDataQueryVariables>;
export const SearchModalProductsDocument = gql`
    query searchModalProducts($localeId: Int!, $query: String!, $limit: Int! = 20) {
  productsPagination(localeId: $localeId, limit: $limit, query: $query) {
    items {
      ...searchModalProduct
    }
  }
}
    ${SearchModalProductFragmentDoc}`;

/**
 * __useSearchModalProductsQuery__
 *
 * To run a query within a React component, call `useSearchModalProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchModalProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchModalProductsQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchModalProductsQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchModalProductsQuery, Types.SearchModalProductsQueryVariables> & ({ variables: Types.SearchModalProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchModalProductsQuery, Types.SearchModalProductsQueryVariables>(SearchModalProductsDocument, options);
      }
export function useSearchModalProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchModalProductsQuery, Types.SearchModalProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchModalProductsQuery, Types.SearchModalProductsQueryVariables>(SearchModalProductsDocument, options);
        }
export function useSearchModalProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SearchModalProductsQuery, Types.SearchModalProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SearchModalProductsQuery, Types.SearchModalProductsQueryVariables>(SearchModalProductsDocument, options);
        }
export type SearchModalProductsQueryHookResult = ReturnType<typeof useSearchModalProductsQuery>;
export type SearchModalProductsLazyQueryHookResult = ReturnType<typeof useSearchModalProductsLazyQuery>;
export type SearchModalProductsSuspenseQueryHookResult = ReturnType<typeof useSearchModalProductsSuspenseQuery>;
export type SearchModalProductsQueryResult = Apollo.QueryResult<Types.SearchModalProductsQuery, Types.SearchModalProductsQueryVariables>;
export const ProductElabelSubscriptionPreviewDocument = gql`
    query productElabelSubscriptionPreview($input: ProductELabelSubscriptionPreviewInput!) {
  productELabelSubscriptionPreview(input: $input) {
    priceWithoutTax
    currency {
      id
      symbol
    }
  }
}
    `;

/**
 * __useProductElabelSubscriptionPreviewQuery__
 *
 * To run a query within a React component, call `useProductElabelSubscriptionPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductElabelSubscriptionPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductElabelSubscriptionPreviewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductElabelSubscriptionPreviewQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductElabelSubscriptionPreviewQuery, Types.ProductElabelSubscriptionPreviewQueryVariables> & ({ variables: Types.ProductElabelSubscriptionPreviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductElabelSubscriptionPreviewQuery, Types.ProductElabelSubscriptionPreviewQueryVariables>(ProductElabelSubscriptionPreviewDocument, options);
      }
export function useProductElabelSubscriptionPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductElabelSubscriptionPreviewQuery, Types.ProductElabelSubscriptionPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductElabelSubscriptionPreviewQuery, Types.ProductElabelSubscriptionPreviewQueryVariables>(ProductElabelSubscriptionPreviewDocument, options);
        }
export function useProductElabelSubscriptionPreviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductElabelSubscriptionPreviewQuery, Types.ProductElabelSubscriptionPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductElabelSubscriptionPreviewQuery, Types.ProductElabelSubscriptionPreviewQueryVariables>(ProductElabelSubscriptionPreviewDocument, options);
        }
export type ProductElabelSubscriptionPreviewQueryHookResult = ReturnType<typeof useProductElabelSubscriptionPreviewQuery>;
export type ProductElabelSubscriptionPreviewLazyQueryHookResult = ReturnType<typeof useProductElabelSubscriptionPreviewLazyQuery>;
export type ProductElabelSubscriptionPreviewSuspenseQueryHookResult = ReturnType<typeof useProductElabelSubscriptionPreviewSuspenseQuery>;
export type ProductElabelSubscriptionPreviewQueryResult = Apollo.QueryResult<Types.ProductElabelSubscriptionPreviewQuery, Types.ProductElabelSubscriptionPreviewQueryVariables>;
export const ProductELabelCouponByCodeDocument = gql`
    query productELabelCouponByCode($promoCode: String!) {
  productELabelCouponByCode(promoCode: $promoCode) {
    id
    name
  }
}
    `;

/**
 * __useProductELabelCouponByCodeQuery__
 *
 * To run a query within a React component, call `useProductELabelCouponByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductELabelCouponByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductELabelCouponByCodeQuery({
 *   variables: {
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function useProductELabelCouponByCodeQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductELabelCouponByCodeQuery, Types.ProductELabelCouponByCodeQueryVariables> & ({ variables: Types.ProductELabelCouponByCodeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductELabelCouponByCodeQuery, Types.ProductELabelCouponByCodeQueryVariables>(ProductELabelCouponByCodeDocument, options);
      }
export function useProductELabelCouponByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductELabelCouponByCodeQuery, Types.ProductELabelCouponByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductELabelCouponByCodeQuery, Types.ProductELabelCouponByCodeQueryVariables>(ProductELabelCouponByCodeDocument, options);
        }
export function useProductELabelCouponByCodeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductELabelCouponByCodeQuery, Types.ProductELabelCouponByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductELabelCouponByCodeQuery, Types.ProductELabelCouponByCodeQueryVariables>(ProductELabelCouponByCodeDocument, options);
        }
export type ProductELabelCouponByCodeQueryHookResult = ReturnType<typeof useProductELabelCouponByCodeQuery>;
export type ProductELabelCouponByCodeLazyQueryHookResult = ReturnType<typeof useProductELabelCouponByCodeLazyQuery>;
export type ProductELabelCouponByCodeSuspenseQueryHookResult = ReturnType<typeof useProductELabelCouponByCodeSuspenseQuery>;
export type ProductELabelCouponByCodeQueryResult = Apollo.QueryResult<Types.ProductELabelCouponByCodeQuery, Types.ProductELabelCouponByCodeQueryVariables>;
export const CompanyTourDocument = gql`
    subscription companyTour($companyId: ID!) {
  companyTour(companyId: $companyId) {
    id
    skipped
    completed
    tasks {
      id
      task
      completed
      step
      total
    }
  }
}
    `;

/**
 * __useCompanyTourSubscription__
 *
 * To run a query within a React component, call `useCompanyTourSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCompanyTourSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyTourSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyTourSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.CompanyTourSubscription, Types.CompanyTourSubscriptionVariables> & ({ variables: Types.CompanyTourSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.CompanyTourSubscription, Types.CompanyTourSubscriptionVariables>(CompanyTourDocument, options);
      }
export type CompanyTourSubscriptionHookResult = ReturnType<typeof useCompanyTourSubscription>;
export type CompanyTourSubscriptionResult = Apollo.SubscriptionResult<Types.CompanyTourSubscription>;
export const UnreadsDocument = gql`
    subscription unreads($companyId: ID!) {
  wineOffersUnreads(companyId: $companyId) {
    buyer {
      items {
        wineOfferStatus {
          code
        }
        count
      }
      total
    }
    seller {
      items {
        wineOfferStatus {
          code
        }
        count
      }
      total
    }
  }
}
    `;

/**
 * __useUnreadsSubscription__
 *
 * To run a query within a React component, call `useUnreadsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUnreadsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadsSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUnreadsSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.UnreadsSubscription, Types.UnreadsSubscriptionVariables> & ({ variables: Types.UnreadsSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.UnreadsSubscription, Types.UnreadsSubscriptionVariables>(UnreadsDocument, options);
      }
export type UnreadsSubscriptionHookResult = ReturnType<typeof useUnreadsSubscription>;
export type UnreadsSubscriptionResult = Apollo.SubscriptionResult<Types.UnreadsSubscription>;
export const WineOfferRequestsUnreadsDocument = gql`
    subscription wineOfferRequestsUnreads($companyId: ID!) {
  wineOfferRequestsUnreads(companyId: $companyId) {
    seller {
      notCompletedCount
    }
  }
}
    `;

/**
 * __useWineOfferRequestsUnreadsSubscription__
 *
 * To run a query within a React component, call `useWineOfferRequestsUnreadsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestsUnreadsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineOfferRequestsUnreadsSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useWineOfferRequestsUnreadsSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.WineOfferRequestsUnreadsSubscription, Types.WineOfferRequestsUnreadsSubscriptionVariables> & ({ variables: Types.WineOfferRequestsUnreadsSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.WineOfferRequestsUnreadsSubscription, Types.WineOfferRequestsUnreadsSubscriptionVariables>(WineOfferRequestsUnreadsDocument, options);
      }
export type WineOfferRequestsUnreadsSubscriptionHookResult = ReturnType<typeof useWineOfferRequestsUnreadsSubscription>;
export type WineOfferRequestsUnreadsSubscriptionResult = Apollo.SubscriptionResult<Types.WineOfferRequestsUnreadsSubscription>;
export const WineReviewsPendingDocument = gql`
    subscription wineReviewsPending {
  wineReviewsPending {
    count
  }
}
    `;

/**
 * __useWineReviewsPendingSubscription__
 *
 * To run a query within a React component, call `useWineReviewsPendingSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWineReviewsPendingSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineReviewsPendingSubscription({
 *   variables: {
 *   },
 * });
 */
export function useWineReviewsPendingSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Types.WineReviewsPendingSubscription, Types.WineReviewsPendingSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.WineReviewsPendingSubscription, Types.WineReviewsPendingSubscriptionVariables>(WineReviewsPendingDocument, options);
      }
export type WineReviewsPendingSubscriptionHookResult = ReturnType<typeof useWineReviewsPendingSubscription>;
export type WineReviewsPendingSubscriptionResult = Apollo.SubscriptionResult<Types.WineReviewsPendingSubscription>;
export const FakeDocument = gql`
    query fake {
  _
}
    `;

/**
 * __useFakeQuery__
 *
 * To run a query within a React component, call `useFakeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFakeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFakeQuery({
 *   variables: {
 *   },
 * });
 */
export function useFakeQuery(baseOptions?: Apollo.QueryHookOptions<Types.FakeQuery, Types.FakeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FakeQuery, Types.FakeQueryVariables>(FakeDocument, options);
      }
export function useFakeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FakeQuery, Types.FakeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FakeQuery, Types.FakeQueryVariables>(FakeDocument, options);
        }
export function useFakeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.FakeQuery, Types.FakeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.FakeQuery, Types.FakeQueryVariables>(FakeDocument, options);
        }
export type FakeQueryHookResult = ReturnType<typeof useFakeQuery>;
export type FakeLazyQueryHookResult = ReturnType<typeof useFakeLazyQuery>;
export type FakeSuspenseQueryHookResult = ReturnType<typeof useFakeSuspenseQuery>;
export type FakeQueryResult = Apollo.QueryResult<Types.FakeQuery, Types.FakeQueryVariables>;
export const UserTutorialCompleteDocument = gql`
    mutation userTutorialComplete($page: String!) {
  userTutorialComplete(page: $page) {
    user {
      tutorialCompleted(page: $page)
    }
  }
}
    `;
export type UserTutorialCompleteMutationFn = Apollo.MutationFunction<Types.UserTutorialCompleteMutation, Types.UserTutorialCompleteMutationVariables>;

/**
 * __useUserTutorialCompleteMutation__
 *
 * To run a mutation, you first call `useUserTutorialCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserTutorialCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userTutorialCompleteMutation, { data, loading, error }] = useUserTutorialCompleteMutation({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useUserTutorialCompleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.UserTutorialCompleteMutation, Types.UserTutorialCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UserTutorialCompleteMutation, Types.UserTutorialCompleteMutationVariables>(UserTutorialCompleteDocument, options);
      }
export type UserTutorialCompleteMutationHookResult = ReturnType<typeof useUserTutorialCompleteMutation>;
export type UserTutorialCompleteMutationResult = Apollo.MutationResult<Types.UserTutorialCompleteMutation>;
export type UserTutorialCompleteMutationOptions = Apollo.BaseMutationOptions<Types.UserTutorialCompleteMutation, Types.UserTutorialCompleteMutationVariables>;
export const UserTutorialCompletedDocument = gql`
    query userTutorialCompleted($page: String!) {
  me {
    id
    tutorialCompleted(page: $page)
  }
}
    `;

/**
 * __useUserTutorialCompletedQuery__
 *
 * To run a query within a React component, call `useUserTutorialCompletedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTutorialCompletedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTutorialCompletedQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useUserTutorialCompletedQuery(baseOptions: Apollo.QueryHookOptions<Types.UserTutorialCompletedQuery, Types.UserTutorialCompletedQueryVariables> & ({ variables: Types.UserTutorialCompletedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserTutorialCompletedQuery, Types.UserTutorialCompletedQueryVariables>(UserTutorialCompletedDocument, options);
      }
export function useUserTutorialCompletedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserTutorialCompletedQuery, Types.UserTutorialCompletedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserTutorialCompletedQuery, Types.UserTutorialCompletedQueryVariables>(UserTutorialCompletedDocument, options);
        }
export function useUserTutorialCompletedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UserTutorialCompletedQuery, Types.UserTutorialCompletedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UserTutorialCompletedQuery, Types.UserTutorialCompletedQueryVariables>(UserTutorialCompletedDocument, options);
        }
export type UserTutorialCompletedQueryHookResult = ReturnType<typeof useUserTutorialCompletedQuery>;
export type UserTutorialCompletedLazyQueryHookResult = ReturnType<typeof useUserTutorialCompletedLazyQuery>;
export type UserTutorialCompletedSuspenseQueryHookResult = ReturnType<typeof useUserTutorialCompletedSuspenseQuery>;
export type UserTutorialCompletedQueryResult = Apollo.QueryResult<Types.UserTutorialCompletedQuery, Types.UserTutorialCompletedQueryVariables>;
export const AdminDeleteMerchantDocument = gql`
    mutation adminDeleteMerchant($id: [ID!]!) {
  admin {
    merchants {
      delete(id: $id) {
        recordIds
      }
    }
  }
}
    `;
export type AdminDeleteMerchantMutationFn = Apollo.MutationFunction<Types.AdminDeleteMerchantMutation, Types.AdminDeleteMerchantMutationVariables>;

/**
 * __useAdminDeleteMerchantMutation__
 *
 * To run a mutation, you first call `useAdminDeleteMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteMerchantMutation, { data, loading, error }] = useAdminDeleteMerchantMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteMerchantMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminDeleteMerchantMutation, Types.AdminDeleteMerchantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminDeleteMerchantMutation, Types.AdminDeleteMerchantMutationVariables>(AdminDeleteMerchantDocument, options);
      }
export type AdminDeleteMerchantMutationHookResult = ReturnType<typeof useAdminDeleteMerchantMutation>;
export type AdminDeleteMerchantMutationResult = Apollo.MutationResult<Types.AdminDeleteMerchantMutation>;
export type AdminDeleteMerchantMutationOptions = Apollo.BaseMutationOptions<Types.AdminDeleteMerchantMutation, Types.AdminDeleteMerchantMutationVariables>;
export const AdminActivateMerchantDocument = gql`
    mutation adminActivateMerchant($id: [ID!]!) {
  admin {
    merchants {
      activate(id: $id) {
        recordIds
        records {
          id
          status
        }
      }
    }
  }
}
    `;
export type AdminActivateMerchantMutationFn = Apollo.MutationFunction<Types.AdminActivateMerchantMutation, Types.AdminActivateMerchantMutationVariables>;

/**
 * __useAdminActivateMerchantMutation__
 *
 * To run a mutation, you first call `useAdminActivateMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminActivateMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminActivateMerchantMutation, { data, loading, error }] = useAdminActivateMerchantMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminActivateMerchantMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminActivateMerchantMutation, Types.AdminActivateMerchantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminActivateMerchantMutation, Types.AdminActivateMerchantMutationVariables>(AdminActivateMerchantDocument, options);
      }
export type AdminActivateMerchantMutationHookResult = ReturnType<typeof useAdminActivateMerchantMutation>;
export type AdminActivateMerchantMutationResult = Apollo.MutationResult<Types.AdminActivateMerchantMutation>;
export type AdminActivateMerchantMutationOptions = Apollo.BaseMutationOptions<Types.AdminActivateMerchantMutation, Types.AdminActivateMerchantMutationVariables>;
export const MarkAsNotReferenceDocument = gql`
    mutation markAsNotReference($ids: [ID!]) {
  admin {
    productImages {
      markAsNotReference(id: $ids) {
        records {
          id
          isReference
        }
      }
    }
  }
}
    `;
export type MarkAsNotReferenceMutationFn = Apollo.MutationFunction<Types.MarkAsNotReferenceMutation, Types.MarkAsNotReferenceMutationVariables>;

/**
 * __useMarkAsNotReferenceMutation__
 *
 * To run a mutation, you first call `useMarkAsNotReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsNotReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsNotReferenceMutation, { data, loading, error }] = useMarkAsNotReferenceMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMarkAsNotReferenceMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkAsNotReferenceMutation, Types.MarkAsNotReferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MarkAsNotReferenceMutation, Types.MarkAsNotReferenceMutationVariables>(MarkAsNotReferenceDocument, options);
      }
export type MarkAsNotReferenceMutationHookResult = ReturnType<typeof useMarkAsNotReferenceMutation>;
export type MarkAsNotReferenceMutationResult = Apollo.MutationResult<Types.MarkAsNotReferenceMutation>;
export type MarkAsNotReferenceMutationOptions = Apollo.BaseMutationOptions<Types.MarkAsNotReferenceMutation, Types.MarkAsNotReferenceMutationVariables>;
export const MarkAsReferenceDocument = gql`
    mutation markAsReference($ids: [ID!]) {
  admin {
    productImages {
      markAsReference(id: $ids) {
        records {
          id
          isReference
        }
      }
    }
  }
}
    `;
export type MarkAsReferenceMutationFn = Apollo.MutationFunction<Types.MarkAsReferenceMutation, Types.MarkAsReferenceMutationVariables>;

/**
 * __useMarkAsReferenceMutation__
 *
 * To run a mutation, you first call `useMarkAsReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsReferenceMutation, { data, loading, error }] = useMarkAsReferenceMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMarkAsReferenceMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkAsReferenceMutation, Types.MarkAsReferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MarkAsReferenceMutation, Types.MarkAsReferenceMutationVariables>(MarkAsReferenceDocument, options);
      }
export type MarkAsReferenceMutationHookResult = ReturnType<typeof useMarkAsReferenceMutation>;
export type MarkAsReferenceMutationResult = Apollo.MutationResult<Types.MarkAsReferenceMutation>;
export type MarkAsReferenceMutationOptions = Apollo.BaseMutationOptions<Types.MarkAsReferenceMutation, Types.MarkAsReferenceMutationVariables>;
export const ResetReferenceDocument = gql`
    mutation resetReference($ids: [ID!]) {
  admin {
    productImages {
      resetReference(id: $ids) {
        records {
          id
          isReference
        }
      }
    }
  }
}
    `;
export type ResetReferenceMutationFn = Apollo.MutationFunction<Types.ResetReferenceMutation, Types.ResetReferenceMutationVariables>;

/**
 * __useResetReferenceMutation__
 *
 * To run a mutation, you first call `useResetReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetReferenceMutation, { data, loading, error }] = useResetReferenceMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useResetReferenceMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResetReferenceMutation, Types.ResetReferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResetReferenceMutation, Types.ResetReferenceMutationVariables>(ResetReferenceDocument, options);
      }
export type ResetReferenceMutationHookResult = ReturnType<typeof useResetReferenceMutation>;
export type ResetReferenceMutationResult = Apollo.MutationResult<Types.ResetReferenceMutation>;
export type ResetReferenceMutationOptions = Apollo.BaseMutationOptions<Types.ResetReferenceMutation, Types.ResetReferenceMutationVariables>;
export const ProductForRecognitionCheckDocument = gql`
    query productForRecognitionCheck($slug: String!, $localeId: Int!) {
  productBySlug(slug: $slug) {
    id
    slug
    wine {
      id
      country {
        id
        localizedName(localeId: $localeId)
      }
      tradeNameBrand
      brand {
        name
        localizedName(localeId: $localeId)
      }
      keyWords
      tradeName {
        tradeName
      }
      wineType {
        isDefault
        localizedName(localeId: $localeId)
      }
      wineColor {
        localizedName(localeId: $localeId)
      }
      sweetnessLevel {
        isDefault
        localizedName(localeId: $localeId)
      }
      specialClassifications {
        name
      }
      vintage
      nationalGIType {
        localizedName(localeId: $localeId)
        localizedShortName(localeId: $localeId)
      }
      nationalGITypeText
      geoIndicationText
      geoIndications {
        localizedName(localeId: $localeId)
        wineRegionsEU {
          localizedName(localeId: $localeId)
        }
      }
    }
    bottleSize {
      id
      volumeML
    }
    packageType {
      id
      isDefault
      shortName
      localizedName(localeId: $localeId)
    }
  }
}
    `;

/**
 * __useProductForRecognitionCheckQuery__
 *
 * To run a query within a React component, call `useProductForRecognitionCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductForRecognitionCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductForRecognitionCheckQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useProductForRecognitionCheckQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductForRecognitionCheckQuery, Types.ProductForRecognitionCheckQueryVariables> & ({ variables: Types.ProductForRecognitionCheckQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductForRecognitionCheckQuery, Types.ProductForRecognitionCheckQueryVariables>(ProductForRecognitionCheckDocument, options);
      }
export function useProductForRecognitionCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductForRecognitionCheckQuery, Types.ProductForRecognitionCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductForRecognitionCheckQuery, Types.ProductForRecognitionCheckQueryVariables>(ProductForRecognitionCheckDocument, options);
        }
export function useProductForRecognitionCheckSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductForRecognitionCheckQuery, Types.ProductForRecognitionCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductForRecognitionCheckQuery, Types.ProductForRecognitionCheckQueryVariables>(ProductForRecognitionCheckDocument, options);
        }
export type ProductForRecognitionCheckQueryHookResult = ReturnType<typeof useProductForRecognitionCheckQuery>;
export type ProductForRecognitionCheckLazyQueryHookResult = ReturnType<typeof useProductForRecognitionCheckLazyQuery>;
export type ProductForRecognitionCheckSuspenseQueryHookResult = ReturnType<typeof useProductForRecognitionCheckSuspenseQuery>;
export type ProductForRecognitionCheckQueryResult = Apollo.QueryResult<Types.ProductForRecognitionCheckQuery, Types.ProductForRecognitionCheckQueryVariables>;
export const AdminUpdateImageDocument = gql`
    mutation adminUpdateImage($input: AdminProductImageUpdateInput!) {
  admin {
    productImages {
      update(input: $input) {
        record {
          id
        }
      }
    }
  }
}
    `;
export type AdminUpdateImageMutationFn = Apollo.MutationFunction<Types.AdminUpdateImageMutation, Types.AdminUpdateImageMutationVariables>;

/**
 * __useAdminUpdateImageMutation__
 *
 * To run a mutation, you first call `useAdminUpdateImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateImageMutation, { data, loading, error }] = useAdminUpdateImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateImageMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminUpdateImageMutation, Types.AdminUpdateImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminUpdateImageMutation, Types.AdminUpdateImageMutationVariables>(AdminUpdateImageDocument, options);
      }
export type AdminUpdateImageMutationHookResult = ReturnType<typeof useAdminUpdateImageMutation>;
export type AdminUpdateImageMutationResult = Apollo.MutationResult<Types.AdminUpdateImageMutation>;
export type AdminUpdateImageMutationOptions = Apollo.BaseMutationOptions<Types.AdminUpdateImageMutation, Types.AdminUpdateImageMutationVariables>;
export const AdminDeleteImageDocument = gql`
    mutation adminDeleteImage($ids: [ID!]) {
  admin {
    productImages {
      delete(id: $ids) {
        __typename
      }
    }
  }
}
    `;
export type AdminDeleteImageMutationFn = Apollo.MutationFunction<Types.AdminDeleteImageMutation, Types.AdminDeleteImageMutationVariables>;

/**
 * __useAdminDeleteImageMutation__
 *
 * To run a mutation, you first call `useAdminDeleteImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteImageMutation, { data, loading, error }] = useAdminDeleteImageMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAdminDeleteImageMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminDeleteImageMutation, Types.AdminDeleteImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminDeleteImageMutation, Types.AdminDeleteImageMutationVariables>(AdminDeleteImageDocument, options);
      }
export type AdminDeleteImageMutationHookResult = ReturnType<typeof useAdminDeleteImageMutation>;
export type AdminDeleteImageMutationResult = Apollo.MutationResult<Types.AdminDeleteImageMutation>;
export type AdminDeleteImageMutationOptions = Apollo.BaseMutationOptions<Types.AdminDeleteImageMutation, Types.AdminDeleteImageMutationVariables>;
export const AdminRepeatSearchDocument = gql`
    mutation adminRepeatSearch($id: ID!) {
  admin {
    productImages {
      repeatSearch(id: $id) {
        record {
          id
          search {
            googleResponse {
              product {
                id
              }
            }
            tineyeResponse {
              product {
                id
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type AdminRepeatSearchMutationFn = Apollo.MutationFunction<Types.AdminRepeatSearchMutation, Types.AdminRepeatSearchMutationVariables>;

/**
 * __useAdminRepeatSearchMutation__
 *
 * To run a mutation, you first call `useAdminRepeatSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRepeatSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRepeatSearchMutation, { data, loading, error }] = useAdminRepeatSearchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminRepeatSearchMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminRepeatSearchMutation, Types.AdminRepeatSearchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminRepeatSearchMutation, Types.AdminRepeatSearchMutationVariables>(AdminRepeatSearchDocument, options);
      }
export type AdminRepeatSearchMutationHookResult = ReturnType<typeof useAdminRepeatSearchMutation>;
export type AdminRepeatSearchMutationResult = Apollo.MutationResult<Types.AdminRepeatSearchMutation>;
export type AdminRepeatSearchMutationOptions = Apollo.BaseMutationOptions<Types.AdminRepeatSearchMutation, Types.AdminRepeatSearchMutationVariables>;
export const AdminProductImagesDocument = gql`
    query adminProductImages($page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filter: AdminProductImageFilter, $localeId: Int!) {
  admin {
    productImages {
      productImagesPagination(page: $page, perPage: $perPage, filter: $filter) {
        pageInfo {
          page
          perPage
          totalItems
          totalPages
          hasPreviousPage
          hasNextPage
        }
        items {
          id
          url
          type
          isReference
          linkStatus
          isVintageUnknown
          image {
            ...imageThumbnail48
            thumbnail256Url: imageUrl(
              transformations: {width: 400, height: 256, resize: fit}
            )
            zoomedUrl: imageUrl(
              transformations: {width: 1000, height: 1000, enlarge: 1, resize: fit}
            )
          }
          product {
            ...adminProductImagesProductInfo
          }
          sourceType
          search {
            ... on ProductImageSearch {
              id
              googleResponse {
                product {
                  ...adminProductImagesProductInfo
                }
                referenceImage
              }
              tineyeResponse {
                product {
                  ...adminProductImagesProductInfo
                }
                response
                referenceImage
              }
            }
          }
          authorUser {
            id
            username
          }
          createdAt
        }
      }
    }
  }
}
    ${ImageThumbnail48FragmentDoc}
${AdminProductImagesProductInfoFragmentDoc}`;

/**
 * __useAdminProductImagesQuery__
 *
 * To run a query within a React component, call `useAdminProductImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProductImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProductImagesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useAdminProductImagesQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminProductImagesQuery, Types.AdminProductImagesQueryVariables> & ({ variables: Types.AdminProductImagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminProductImagesQuery, Types.AdminProductImagesQueryVariables>(AdminProductImagesDocument, options);
      }
export function useAdminProductImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminProductImagesQuery, Types.AdminProductImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminProductImagesQuery, Types.AdminProductImagesQueryVariables>(AdminProductImagesDocument, options);
        }
export function useAdminProductImagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminProductImagesQuery, Types.AdminProductImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminProductImagesQuery, Types.AdminProductImagesQueryVariables>(AdminProductImagesDocument, options);
        }
export type AdminProductImagesQueryHookResult = ReturnType<typeof useAdminProductImagesQuery>;
export type AdminProductImagesLazyQueryHookResult = ReturnType<typeof useAdminProductImagesLazyQuery>;
export type AdminProductImagesSuspenseQueryHookResult = ReturnType<typeof useAdminProductImagesSuspenseQuery>;
export type AdminProductImagesQueryResult = Apollo.QueryResult<Types.AdminProductImagesQuery, Types.AdminProductImagesQueryVariables>;
export const AdminImportPriceSourceFileDocument = gql`
    mutation adminImportPriceSourceFile($sourceURL: URL!, $merchantId: ID!, $modificationDate: Date, $hideOther: Boolean! = false) {
  admin {
    priceSources {
      importFile(
        input: {sourceURL: $sourceURL, merchantId: $merchantId, modificationDate: $modificationDate, hideOther: $hideOther}
      ) {
        recordId
      }
    }
  }
}
    `;
export type AdminImportPriceSourceFileMutationFn = Apollo.MutationFunction<Types.AdminImportPriceSourceFileMutation, Types.AdminImportPriceSourceFileMutationVariables>;

/**
 * __useAdminImportPriceSourceFileMutation__
 *
 * To run a mutation, you first call `useAdminImportPriceSourceFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminImportPriceSourceFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminImportPriceSourceFileMutation, { data, loading, error }] = useAdminImportPriceSourceFileMutation({
 *   variables: {
 *      sourceURL: // value for 'sourceURL'
 *      merchantId: // value for 'merchantId'
 *      modificationDate: // value for 'modificationDate'
 *      hideOther: // value for 'hideOther'
 *   },
 * });
 */
export function useAdminImportPriceSourceFileMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminImportPriceSourceFileMutation, Types.AdminImportPriceSourceFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminImportPriceSourceFileMutation, Types.AdminImportPriceSourceFileMutationVariables>(AdminImportPriceSourceFileDocument, options);
      }
export type AdminImportPriceSourceFileMutationHookResult = ReturnType<typeof useAdminImportPriceSourceFileMutation>;
export type AdminImportPriceSourceFileMutationResult = Apollo.MutationResult<Types.AdminImportPriceSourceFileMutation>;
export type AdminImportPriceSourceFileMutationOptions = Apollo.BaseMutationOptions<Types.AdminImportPriceSourceFileMutation, Types.AdminImportPriceSourceFileMutationVariables>;
export const AdminMerchantSearchDocument = gql`
    query adminMerchantSearch($query: String!, $limit: PositiveInt! = 20) {
  admin {
    merchants {
      merchantSearch(query: $query, limit: $limit) {
        id
        name
        website
      }
    }
  }
}
    `;

/**
 * __useAdminMerchantSearchQuery__
 *
 * To run a query within a React component, call `useAdminMerchantSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMerchantSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMerchantSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAdminMerchantSearchQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminMerchantSearchQuery, Types.AdminMerchantSearchQueryVariables> & ({ variables: Types.AdminMerchantSearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminMerchantSearchQuery, Types.AdminMerchantSearchQueryVariables>(AdminMerchantSearchDocument, options);
      }
export function useAdminMerchantSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminMerchantSearchQuery, Types.AdminMerchantSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminMerchantSearchQuery, Types.AdminMerchantSearchQueryVariables>(AdminMerchantSearchDocument, options);
        }
export function useAdminMerchantSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminMerchantSearchQuery, Types.AdminMerchantSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminMerchantSearchQuery, Types.AdminMerchantSearchQueryVariables>(AdminMerchantSearchDocument, options);
        }
export type AdminMerchantSearchQueryHookResult = ReturnType<typeof useAdminMerchantSearchQuery>;
export type AdminMerchantSearchLazyQueryHookResult = ReturnType<typeof useAdminMerchantSearchLazyQuery>;
export type AdminMerchantSearchSuspenseQueryHookResult = ReturnType<typeof useAdminMerchantSearchSuspenseQuery>;
export type AdminMerchantSearchQueryResult = Apollo.QueryResult<Types.AdminMerchantSearchQuery, Types.AdminMerchantSearchQueryVariables>;
export const AdminValidPriceSourceFileDocument = gql`
    mutation adminValidPriceSourceFile($input: AdminPriceSourceValidateFileInput!) {
  admin {
    priceSources {
      validateFile(input: $input) {
        ... on AdminPriceSourceValidateFileFormatPayload {
          error
        }
        ... on AdminPriceSourceValidateFileValidationPayload {
          errors {
            field
            line
            message
            meta
          }
          invalidRowCount
          totalRowCount
          validRowCount
        }
      }
    }
  }
}
    `;
export type AdminValidPriceSourceFileMutationFn = Apollo.MutationFunction<Types.AdminValidPriceSourceFileMutation, Types.AdminValidPriceSourceFileMutationVariables>;

/**
 * __useAdminValidPriceSourceFileMutation__
 *
 * To run a mutation, you first call `useAdminValidPriceSourceFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminValidPriceSourceFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminValidPriceSourceFileMutation, { data, loading, error }] = useAdminValidPriceSourceFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminValidPriceSourceFileMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminValidPriceSourceFileMutation, Types.AdminValidPriceSourceFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminValidPriceSourceFileMutation, Types.AdminValidPriceSourceFileMutationVariables>(AdminValidPriceSourceFileDocument, options);
      }
export type AdminValidPriceSourceFileMutationHookResult = ReturnType<typeof useAdminValidPriceSourceFileMutation>;
export type AdminValidPriceSourceFileMutationResult = Apollo.MutationResult<Types.AdminValidPriceSourceFileMutation>;
export type AdminValidPriceSourceFileMutationOptions = Apollo.BaseMutationOptions<Types.AdminValidPriceSourceFileMutation, Types.AdminValidPriceSourceFileMutationVariables>;
export const GetUploadUrlDocument = gql`
    mutation getUploadUrl($filename: String!) {
  uploadUrl(filename: $filename)
}
    `;
export type GetUploadUrlMutationFn = Apollo.MutationFunction<Types.GetUploadUrlMutation, Types.GetUploadUrlMutationVariables>;

/**
 * __useGetUploadUrlMutation__
 *
 * To run a mutation, you first call `useGetUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUploadUrlMutation, { data, loading, error }] = useGetUploadUrlMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useGetUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<Types.GetUploadUrlMutation, Types.GetUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GetUploadUrlMutation, Types.GetUploadUrlMutationVariables>(GetUploadUrlDocument, options);
      }
export type GetUploadUrlMutationHookResult = ReturnType<typeof useGetUploadUrlMutation>;
export type GetUploadUrlMutationResult = Apollo.MutationResult<Types.GetUploadUrlMutation>;
export type GetUploadUrlMutationOptions = Apollo.BaseMutationOptions<Types.GetUploadUrlMutation, Types.GetUploadUrlMutationVariables>;
export const AdminUpdateRequestPeriodOfValidityDocument = gql`
    mutation adminUpdateRequestPeriodOfValidity($input: AdminWineOfferRequestUpdatePeriodOfValidityInput!) {
  admin {
    wineOfferRequests {
      updatePeriodOfValidity(input: $input) {
        records {
          id
          periodOfValidity
        }
      }
    }
  }
}
    `;
export type AdminUpdateRequestPeriodOfValidityMutationFn = Apollo.MutationFunction<Types.AdminUpdateRequestPeriodOfValidityMutation, Types.AdminUpdateRequestPeriodOfValidityMutationVariables>;

/**
 * __useAdminUpdateRequestPeriodOfValidityMutation__
 *
 * To run a mutation, you first call `useAdminUpdateRequestPeriodOfValidityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateRequestPeriodOfValidityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateRequestPeriodOfValidityMutation, { data, loading, error }] = useAdminUpdateRequestPeriodOfValidityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateRequestPeriodOfValidityMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminUpdateRequestPeriodOfValidityMutation, Types.AdminUpdateRequestPeriodOfValidityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminUpdateRequestPeriodOfValidityMutation, Types.AdminUpdateRequestPeriodOfValidityMutationVariables>(AdminUpdateRequestPeriodOfValidityDocument, options);
      }
export type AdminUpdateRequestPeriodOfValidityMutationHookResult = ReturnType<typeof useAdminUpdateRequestPeriodOfValidityMutation>;
export type AdminUpdateRequestPeriodOfValidityMutationResult = Apollo.MutationResult<Types.AdminUpdateRequestPeriodOfValidityMutation>;
export type AdminUpdateRequestPeriodOfValidityMutationOptions = Apollo.BaseMutationOptions<Types.AdminUpdateRequestPeriodOfValidityMutation, Types.AdminUpdateRequestPeriodOfValidityMutationVariables>;
export const AdminUpdateWineOfferRequestStageDocument = gql`
    mutation adminUpdateWineOfferRequestStage($id: ID!, $stage: WineOfferRequestStage!) {
  admin {
    wineOfferRequests {
      changeStage(input: {id: $id, stage: $stage}) {
        record {
          id
          stage
        }
      }
    }
  }
}
    `;
export type AdminUpdateWineOfferRequestStageMutationFn = Apollo.MutationFunction<Types.AdminUpdateWineOfferRequestStageMutation, Types.AdminUpdateWineOfferRequestStageMutationVariables>;

/**
 * __useAdminUpdateWineOfferRequestStageMutation__
 *
 * To run a mutation, you first call `useAdminUpdateWineOfferRequestStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateWineOfferRequestStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateWineOfferRequestStageMutation, { data, loading, error }] = useAdminUpdateWineOfferRequestStageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useAdminUpdateWineOfferRequestStageMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminUpdateWineOfferRequestStageMutation, Types.AdminUpdateWineOfferRequestStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminUpdateWineOfferRequestStageMutation, Types.AdminUpdateWineOfferRequestStageMutationVariables>(AdminUpdateWineOfferRequestStageDocument, options);
      }
export type AdminUpdateWineOfferRequestStageMutationHookResult = ReturnType<typeof useAdminUpdateWineOfferRequestStageMutation>;
export type AdminUpdateWineOfferRequestStageMutationResult = Apollo.MutationResult<Types.AdminUpdateWineOfferRequestStageMutation>;
export type AdminUpdateWineOfferRequestStageMutationOptions = Apollo.BaseMutationOptions<Types.AdminUpdateWineOfferRequestStageMutation, Types.AdminUpdateWineOfferRequestStageMutationVariables>;
export const AdminWineOfferRequestsDeleteDocument = gql`
    mutation adminWineOfferRequestsDelete($id: [ID!]!) {
  admin {
    wineOfferRequests {
      delete(id: $id) {
        recordIds
      }
    }
  }
}
    `;
export type AdminWineOfferRequestsDeleteMutationFn = Apollo.MutationFunction<Types.AdminWineOfferRequestsDeleteMutation, Types.AdminWineOfferRequestsDeleteMutationVariables>;

/**
 * __useAdminWineOfferRequestsDeleteMutation__
 *
 * To run a mutation, you first call `useAdminWineOfferRequestsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminWineOfferRequestsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminWineOfferRequestsDeleteMutation, { data, loading, error }] = useAdminWineOfferRequestsDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminWineOfferRequestsDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminWineOfferRequestsDeleteMutation, Types.AdminWineOfferRequestsDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminWineOfferRequestsDeleteMutation, Types.AdminWineOfferRequestsDeleteMutationVariables>(AdminWineOfferRequestsDeleteDocument, options);
      }
export type AdminWineOfferRequestsDeleteMutationHookResult = ReturnType<typeof useAdminWineOfferRequestsDeleteMutation>;
export type AdminWineOfferRequestsDeleteMutationResult = Apollo.MutationResult<Types.AdminWineOfferRequestsDeleteMutation>;
export type AdminWineOfferRequestsDeleteMutationOptions = Apollo.BaseMutationOptions<Types.AdminWineOfferRequestsDeleteMutation, Types.AdminWineOfferRequestsDeleteMutationVariables>;
export const AdminUpdateWineOfferRequestNoteDocument = gql`
    mutation adminUpdateWineOfferRequestNote($id: ID!, $note: String!) {
  admin {
    wineOfferRequests {
      editNote(input: {id: $id, note: $note}) {
        record {
          id
          note
        }
      }
    }
  }
}
    `;
export type AdminUpdateWineOfferRequestNoteMutationFn = Apollo.MutationFunction<Types.AdminUpdateWineOfferRequestNoteMutation, Types.AdminUpdateWineOfferRequestNoteMutationVariables>;

/**
 * __useAdminUpdateWineOfferRequestNoteMutation__
 *
 * To run a mutation, you first call `useAdminUpdateWineOfferRequestNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateWineOfferRequestNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateWineOfferRequestNoteMutation, { data, loading, error }] = useAdminUpdateWineOfferRequestNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useAdminUpdateWineOfferRequestNoteMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminUpdateWineOfferRequestNoteMutation, Types.AdminUpdateWineOfferRequestNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminUpdateWineOfferRequestNoteMutation, Types.AdminUpdateWineOfferRequestNoteMutationVariables>(AdminUpdateWineOfferRequestNoteDocument, options);
      }
export type AdminUpdateWineOfferRequestNoteMutationHookResult = ReturnType<typeof useAdminUpdateWineOfferRequestNoteMutation>;
export type AdminUpdateWineOfferRequestNoteMutationResult = Apollo.MutationResult<Types.AdminUpdateWineOfferRequestNoteMutation>;
export type AdminUpdateWineOfferRequestNoteMutationOptions = Apollo.BaseMutationOptions<Types.AdminUpdateWineOfferRequestNoteMutation, Types.AdminUpdateWineOfferRequestNoteMutationVariables>;
export const WineOfferRequestExportPotentialProducersDocument = gql`
    mutation wineOfferRequestExportPotentialProducers($input: AdminWineOfferRequestExportPotentialProducersInput!) {
  admin {
    wineOfferRequests {
      wineOfferRequestExportPotentialProducers(input: $input) {
        link
        sizeInBytes
      }
    }
  }
}
    `;
export type WineOfferRequestExportPotentialProducersMutationFn = Apollo.MutationFunction<Types.WineOfferRequestExportPotentialProducersMutation, Types.WineOfferRequestExportPotentialProducersMutationVariables>;

/**
 * __useWineOfferRequestExportPotentialProducersMutation__
 *
 * To run a mutation, you first call `useWineOfferRequestExportPotentialProducersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestExportPotentialProducersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOfferRequestExportPotentialProducersMutation, { data, loading, error }] = useWineOfferRequestExportPotentialProducersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOfferRequestExportPotentialProducersMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOfferRequestExportPotentialProducersMutation, Types.WineOfferRequestExportPotentialProducersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOfferRequestExportPotentialProducersMutation, Types.WineOfferRequestExportPotentialProducersMutationVariables>(WineOfferRequestExportPotentialProducersDocument, options);
      }
export type WineOfferRequestExportPotentialProducersMutationHookResult = ReturnType<typeof useWineOfferRequestExportPotentialProducersMutation>;
export type WineOfferRequestExportPotentialProducersMutationResult = Apollo.MutationResult<Types.WineOfferRequestExportPotentialProducersMutation>;
export type WineOfferRequestExportPotentialProducersMutationOptions = Apollo.BaseMutationOptions<Types.WineOfferRequestExportPotentialProducersMutation, Types.WineOfferRequestExportPotentialProducersMutationVariables>;
export const AdminDeleteTradeOffersLotsDocument = gql`
    mutation adminDeleteTradeOffersLots($id: [ID!]!) {
  admin {
    tradeOffers {
      deleteLots(id: $id) {
        recordIds
      }
    }
  }
}
    `;
export type AdminDeleteTradeOffersLotsMutationFn = Apollo.MutationFunction<Types.AdminDeleteTradeOffersLotsMutation, Types.AdminDeleteTradeOffersLotsMutationVariables>;

/**
 * __useAdminDeleteTradeOffersLotsMutation__
 *
 * To run a mutation, you first call `useAdminDeleteTradeOffersLotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteTradeOffersLotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteTradeOffersLotsMutation, { data, loading, error }] = useAdminDeleteTradeOffersLotsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteTradeOffersLotsMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminDeleteTradeOffersLotsMutation, Types.AdminDeleteTradeOffersLotsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminDeleteTradeOffersLotsMutation, Types.AdminDeleteTradeOffersLotsMutationVariables>(AdminDeleteTradeOffersLotsDocument, options);
      }
export type AdminDeleteTradeOffersLotsMutationHookResult = ReturnType<typeof useAdminDeleteTradeOffersLotsMutation>;
export type AdminDeleteTradeOffersLotsMutationResult = Apollo.MutationResult<Types.AdminDeleteTradeOffersLotsMutation>;
export type AdminDeleteTradeOffersLotsMutationOptions = Apollo.BaseMutationOptions<Types.AdminDeleteTradeOffersLotsMutation, Types.AdminDeleteTradeOffersLotsMutationVariables>;
export const AdminTradeOfferForPricesTableDocument = gql`
    query adminTradeOfferForPricesTable($id: ID!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: TradeOfferLotSort) {
  admin {
    tradeOffers {
      tradeOffer(id: $id) {
        id
        additionalId
        productURL
        isProductURLUnique
        merchant {
          id
          name
          website
          country {
            id
            codeISO
          }
        }
        status
        priceSource {
          id
          type
          status
        }
        updatedAt
        updateUser {
          username
        }
        lotsPagination(page: $page, perPage: $perPage, sort: $sort) {
          items {
            ...adminTradeOfferLotForPricesTable
          }
          pageInfo {
            totalItems
            totalPages
            page
            perPage
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
}
    ${AdminTradeOfferLotForPricesTableFragmentDoc}`;

/**
 * __useAdminTradeOfferForPricesTableQuery__
 *
 * To run a query within a React component, call `useAdminTradeOfferForPricesTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTradeOfferForPricesTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTradeOfferForPricesTableQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAdminTradeOfferForPricesTableQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminTradeOfferForPricesTableQuery, Types.AdminTradeOfferForPricesTableQueryVariables> & ({ variables: Types.AdminTradeOfferForPricesTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminTradeOfferForPricesTableQuery, Types.AdminTradeOfferForPricesTableQueryVariables>(AdminTradeOfferForPricesTableDocument, options);
      }
export function useAdminTradeOfferForPricesTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminTradeOfferForPricesTableQuery, Types.AdminTradeOfferForPricesTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminTradeOfferForPricesTableQuery, Types.AdminTradeOfferForPricesTableQueryVariables>(AdminTradeOfferForPricesTableDocument, options);
        }
export function useAdminTradeOfferForPricesTableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminTradeOfferForPricesTableQuery, Types.AdminTradeOfferForPricesTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminTradeOfferForPricesTableQuery, Types.AdminTradeOfferForPricesTableQueryVariables>(AdminTradeOfferForPricesTableDocument, options);
        }
export type AdminTradeOfferForPricesTableQueryHookResult = ReturnType<typeof useAdminTradeOfferForPricesTableQuery>;
export type AdminTradeOfferForPricesTableLazyQueryHookResult = ReturnType<typeof useAdminTradeOfferForPricesTableLazyQuery>;
export type AdminTradeOfferForPricesTableSuspenseQueryHookResult = ReturnType<typeof useAdminTradeOfferForPricesTableSuspenseQuery>;
export type AdminTradeOfferForPricesTableQueryResult = Apollo.QueryResult<Types.AdminTradeOfferForPricesTableQuery, Types.AdminTradeOfferForPricesTableQueryVariables>;
export const AdminActivateTradeOffersDocument = gql`
    mutation adminActivateTradeOffers($id: [ID!]!) {
  admin {
    tradeOffers {
      activate(id: $id) {
        records {
          id
          status
        }
      }
    }
  }
}
    `;
export type AdminActivateTradeOffersMutationFn = Apollo.MutationFunction<Types.AdminActivateTradeOffersMutation, Types.AdminActivateTradeOffersMutationVariables>;

/**
 * __useAdminActivateTradeOffersMutation__
 *
 * To run a mutation, you first call `useAdminActivateTradeOffersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminActivateTradeOffersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminActivateTradeOffersMutation, { data, loading, error }] = useAdminActivateTradeOffersMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminActivateTradeOffersMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminActivateTradeOffersMutation, Types.AdminActivateTradeOffersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminActivateTradeOffersMutation, Types.AdminActivateTradeOffersMutationVariables>(AdminActivateTradeOffersDocument, options);
      }
export type AdminActivateTradeOffersMutationHookResult = ReturnType<typeof useAdminActivateTradeOffersMutation>;
export type AdminActivateTradeOffersMutationResult = Apollo.MutationResult<Types.AdminActivateTradeOffersMutation>;
export type AdminActivateTradeOffersMutationOptions = Apollo.BaseMutationOptions<Types.AdminActivateTradeOffersMutation, Types.AdminActivateTradeOffersMutationVariables>;
export const AdminDeleteTradeOffersDocument = gql`
    mutation adminDeleteTradeOffers($id: [ID!]!) {
  admin {
    tradeOffers {
      delete(id: $id) {
        records {
          id
          status
        }
      }
    }
  }
}
    `;
export type AdminDeleteTradeOffersMutationFn = Apollo.MutationFunction<Types.AdminDeleteTradeOffersMutation, Types.AdminDeleteTradeOffersMutationVariables>;

/**
 * __useAdminDeleteTradeOffersMutation__
 *
 * To run a mutation, you first call `useAdminDeleteTradeOffersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteTradeOffersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteTradeOffersMutation, { data, loading, error }] = useAdminDeleteTradeOffersMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteTradeOffersMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminDeleteTradeOffersMutation, Types.AdminDeleteTradeOffersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminDeleteTradeOffersMutation, Types.AdminDeleteTradeOffersMutationVariables>(AdminDeleteTradeOffersDocument, options);
      }
export type AdminDeleteTradeOffersMutationHookResult = ReturnType<typeof useAdminDeleteTradeOffersMutation>;
export type AdminDeleteTradeOffersMutationResult = Apollo.MutationResult<Types.AdminDeleteTradeOffersMutation>;
export type AdminDeleteTradeOffersMutationOptions = Apollo.BaseMutationOptions<Types.AdminDeleteTradeOffersMutation, Types.AdminDeleteTradeOffersMutationVariables>;
export const AdminHideTradeOffersDocument = gql`
    mutation adminHideTradeOffers($id: [ID!]!) {
  admin {
    tradeOffers {
      hide(id: $id) {
        records {
          id
          status
        }
      }
    }
  }
}
    `;
export type AdminHideTradeOffersMutationFn = Apollo.MutationFunction<Types.AdminHideTradeOffersMutation, Types.AdminHideTradeOffersMutationVariables>;

/**
 * __useAdminHideTradeOffersMutation__
 *
 * To run a mutation, you first call `useAdminHideTradeOffersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminHideTradeOffersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminHideTradeOffersMutation, { data, loading, error }] = useAdminHideTradeOffersMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminHideTradeOffersMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminHideTradeOffersMutation, Types.AdminHideTradeOffersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminHideTradeOffersMutation, Types.AdminHideTradeOffersMutationVariables>(AdminHideTradeOffersDocument, options);
      }
export type AdminHideTradeOffersMutationHookResult = ReturnType<typeof useAdminHideTradeOffersMutation>;
export type AdminHideTradeOffersMutationResult = Apollo.MutationResult<Types.AdminHideTradeOffersMutation>;
export type AdminHideTradeOffersMutationOptions = Apollo.BaseMutationOptions<Types.AdminHideTradeOffersMutation, Types.AdminHideTradeOffersMutationVariables>;
export const AdminExportTradeOfferLotsDocument = gql`
    mutation adminExportTradeOfferLots($input: AdminTradeOfferLotExportInput!) {
  admin {
    tradeOffers {
      exportLots(input: $input) {
        link
        sizeInBytes
      }
    }
  }
}
    `;
export type AdminExportTradeOfferLotsMutationFn = Apollo.MutationFunction<Types.AdminExportTradeOfferLotsMutation, Types.AdminExportTradeOfferLotsMutationVariables>;

/**
 * __useAdminExportTradeOfferLotsMutation__
 *
 * To run a mutation, you first call `useAdminExportTradeOfferLotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminExportTradeOfferLotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminExportTradeOfferLotsMutation, { data, loading, error }] = useAdminExportTradeOfferLotsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminExportTradeOfferLotsMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminExportTradeOfferLotsMutation, Types.AdminExportTradeOfferLotsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminExportTradeOfferLotsMutation, Types.AdminExportTradeOfferLotsMutationVariables>(AdminExportTradeOfferLotsDocument, options);
      }
export type AdminExportTradeOfferLotsMutationHookResult = ReturnType<typeof useAdminExportTradeOfferLotsMutation>;
export type AdminExportTradeOfferLotsMutationResult = Apollo.MutationResult<Types.AdminExportTradeOfferLotsMutation>;
export type AdminExportTradeOfferLotsMutationOptions = Apollo.BaseMutationOptions<Types.AdminExportTradeOfferLotsMutation, Types.AdminExportTradeOfferLotsMutationVariables>;
export const AdminTradeOffersFiltersDocument = gql`
    query adminTradeOffersFilters($localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt!, $filterSearch: String, $facetsInputs: [FacetInput!], $status: [TradeOfferStatus!], $query: String, $includeUnusedLabels: Boolean! = true) {
  admin {
    tradeOffers {
      tradeOfferOrLotsFilters(
        localeId: $localeId
        filter: {facetsInputs: $facetsInputs, status: $status}
        query: $query
      ) {
        count
        updateUserUsernameFilter: filterByKey(key: "updateUserUsername") {
          ...facetFreeListFilter
        }
        merchantFilter: filterByKey(key: "merchant.name") {
          ...facetListFilter
        }
        creationDateFilter: filterByKey(key: "createdAt") {
          ...facetDateRangeFilter
        }
        updatedAtFilter: filterByKey(key: "updatedAt") {
          ...facetDateRangeFilter
        }
        wineCountryFilter: filterByKey(key: "product.wine.country") {
          ...facetListFilter
        }
        producerFilter: filterByKey(key: "product.wine.tradeName") {
          ...facetListFilter
        }
        productBrandFilter: filterByKey(key: "product.wine.brand") {
          ...facetListFilter
        }
        nationalGITypeFilter: filterByKey(key: "product.wine.nationalGIType") {
          ...facetListFilter
        }
        wineRegionEUFilter: filterByKey(key: "product.wine.wineRegionEU") {
          ...facetListFilter
        }
        geoIndicationFilter: filterByKey(key: "product.wine.geoIndication") {
          ...facetListFilter
        }
        specialClassificationFilter: filterByKey(
          key: "product.wine.specialClassification"
        ) {
          ...facetListFilter
        }
        wineTypeFilter: filterByKey(key: "product.wine.wineType") {
          ...facetListFilter
        }
        wineColourFilter: filterByKey(key: "product.wine.wineColor") {
          ...facetListFilter
        }
        sweetnessTypeFilter: filterByKey(key: "product.wine.sweetnessLevel") {
          ...facetListFilter
        }
        vintageFilter: filterByKey(key: "product.wine.vintage") {
          ...facetListFilter
        }
        bottleSizeFilter: filterByKey(key: "product.bottleSize") {
          ...facetListFilter
        }
        packageTypeFilter: filterByKey(key: "product.packageType") {
          ...facetListFilter
        }
        uniqueProductURLFilter: filterByKey(key: "isProductURLUnique") {
          ...facetListFilter
        }
        eshopCountryFilter: filterByKey(key: "merchant.country") {
          ...facetListFilter
        }
        grapesFilter: filterByKey(key: "grapeAndMonocepage") {
          ...facetComplexFilter
        }
        alcoholLevelFilter: filterByKey(key: "chemicalAnalysisAlcohol") {
          ...facetRangeFilter
        }
        certificatesFilter: filterByKey(key: "product.wine.certification") {
          ...facetListFilter
        }
        wwlRatingFilter: filterByKey(key: "product.wine.rating") {
          ...facetListFilter
        }
        rrpFilter: filterByKey(key: "product.priceAtWinery") {
          ...facetComplexFilter
        }
        pricePerLotFilter: filterByKey(key: "price") {
          ...facetComplexFilter
        }
        pricePerBottleFilter: filterByKey(key: "pricePerBottle") {
          ...facetComplexFilter
        }
        wineLotFilter: filterByKey(key: "wineLot") {
          ...facetListFilter
        }
        lotCreatedAtFilter: filterByKey(key: "createdAt") {
          ...facetDateRangeFilter
        }
        priceDateFilter: filterByKey(key: "lotCreatedAt") {
          ...facetDateRangeFilter
        }
        isMerchantSetFilter: filterByKey(key: "isMerchantSet") {
          ...facetListFilter
        }
        isProductSetFilter: filterByKey(key: "isProductSet") {
          ...facetListFilter
        }
        priceCoefFilter: filterByKey(key: "priceCoefficient") {
          ...facetRangeFilter
        }
      }
    }
  }
}
    ${FacetFreeListFilterFragmentDoc}
${FacetListFilterFragmentDoc}
${FacetDateRangeFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}`;

/**
 * __useAdminTradeOffersFiltersQuery__
 *
 * To run a query within a React component, call `useAdminTradeOffersFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTradeOffersFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTradeOffersFiltersQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      status: // value for 'status'
 *      query: // value for 'query'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useAdminTradeOffersFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminTradeOffersFiltersQuery, Types.AdminTradeOffersFiltersQueryVariables> & ({ variables: Types.AdminTradeOffersFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminTradeOffersFiltersQuery, Types.AdminTradeOffersFiltersQueryVariables>(AdminTradeOffersFiltersDocument, options);
      }
export function useAdminTradeOffersFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminTradeOffersFiltersQuery, Types.AdminTradeOffersFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminTradeOffersFiltersQuery, Types.AdminTradeOffersFiltersQueryVariables>(AdminTradeOffersFiltersDocument, options);
        }
export function useAdminTradeOffersFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminTradeOffersFiltersQuery, Types.AdminTradeOffersFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminTradeOffersFiltersQuery, Types.AdminTradeOffersFiltersQueryVariables>(AdminTradeOffersFiltersDocument, options);
        }
export type AdminTradeOffersFiltersQueryHookResult = ReturnType<typeof useAdminTradeOffersFiltersQuery>;
export type AdminTradeOffersFiltersLazyQueryHookResult = ReturnType<typeof useAdminTradeOffersFiltersLazyQuery>;
export type AdminTradeOffersFiltersSuspenseQueryHookResult = ReturnType<typeof useAdminTradeOffersFiltersSuspenseQuery>;
export type AdminTradeOffersFiltersQueryResult = Apollo.QueryResult<Types.AdminTradeOffersFiltersQuery, Types.AdminTradeOffersFiltersQueryVariables>;
export const AdminTradeOffersFiltersItemDocument = gql`
    query adminTradeOffersFiltersItem($filterKey: String!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $filterSearch: String, $facetsInputs: [FacetInput!], $query: String, $includeUnusedLabels: Boolean! = true) {
  admin {
    tradeOffers {
      tradeOfferOrLotsFilters(
        localeId: $localeId
        filter: {facetsInputs: $facetsInputs}
        query: $query
      ) {
        filterByKey(key: $filterKey) {
          ...facetFreeListFilter
          ...facetListFilter
          ...facetDateRangeFilter
          ...facetComplexFilter
          ...facetRangeFilter
        }
      }
    }
  }
}
    ${FacetFreeListFilterFragmentDoc}
${FacetListFilterFragmentDoc}
${FacetDateRangeFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}`;

/**
 * __useAdminTradeOffersFiltersItemQuery__
 *
 * To run a query within a React component, call `useAdminTradeOffersFiltersItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTradeOffersFiltersItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTradeOffersFiltersItemQuery({
 *   variables: {
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      query: // value for 'query'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useAdminTradeOffersFiltersItemQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminTradeOffersFiltersItemQuery, Types.AdminTradeOffersFiltersItemQueryVariables> & ({ variables: Types.AdminTradeOffersFiltersItemQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminTradeOffersFiltersItemQuery, Types.AdminTradeOffersFiltersItemQueryVariables>(AdminTradeOffersFiltersItemDocument, options);
      }
export function useAdminTradeOffersFiltersItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminTradeOffersFiltersItemQuery, Types.AdminTradeOffersFiltersItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminTradeOffersFiltersItemQuery, Types.AdminTradeOffersFiltersItemQueryVariables>(AdminTradeOffersFiltersItemDocument, options);
        }
export function useAdminTradeOffersFiltersItemSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminTradeOffersFiltersItemQuery, Types.AdminTradeOffersFiltersItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminTradeOffersFiltersItemQuery, Types.AdminTradeOffersFiltersItemQueryVariables>(AdminTradeOffersFiltersItemDocument, options);
        }
export type AdminTradeOffersFiltersItemQueryHookResult = ReturnType<typeof useAdminTradeOffersFiltersItemQuery>;
export type AdminTradeOffersFiltersItemLazyQueryHookResult = ReturnType<typeof useAdminTradeOffersFiltersItemLazyQuery>;
export type AdminTradeOffersFiltersItemSuspenseQueryHookResult = ReturnType<typeof useAdminTradeOffersFiltersItemSuspenseQuery>;
export type AdminTradeOffersFiltersItemQueryResult = Apollo.QueryResult<Types.AdminTradeOffersFiltersItemQuery, Types.AdminTradeOffersFiltersItemQueryVariables>;
export const AdminTradeOffersListDocument = gql`
    query adminTradeOffersList($localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: TradeOfferSort!, $query: String, $filter: AdminTradeOfferFilter, $currencyCodeToConvert: String, $wineLotIdToConvert: Int, $convertPrice: Boolean!) {
  admin {
    tradeOffers {
      tradeOfferOrLotsPagination(
        localeId: $localeId
        page: $page
        perPage: $perPage
        sort: $sort
        query: $query
        filter: $filter
      ) {
        pageInfo {
          totalItems
          totalPages
          page
          perPage
          hasNextPage
          hasPreviousPage
        }
        items {
          ...adminTradeOfferLotListItem
          ...adminTradeOfferListItem
        }
      }
    }
  }
}
    ${AdminTradeOfferLotListItemFragmentDoc}
${AdminTradeOfferListItemFragmentDoc}`;

/**
 * __useAdminTradeOffersListQuery__
 *
 * To run a query within a React component, call `useAdminTradeOffersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTradeOffersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTradeOffersListQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *      currencyCodeToConvert: // value for 'currencyCodeToConvert'
 *      wineLotIdToConvert: // value for 'wineLotIdToConvert'
 *      convertPrice: // value for 'convertPrice'
 *   },
 * });
 */
export function useAdminTradeOffersListQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminTradeOffersListQuery, Types.AdminTradeOffersListQueryVariables> & ({ variables: Types.AdminTradeOffersListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminTradeOffersListQuery, Types.AdminTradeOffersListQueryVariables>(AdminTradeOffersListDocument, options);
      }
export function useAdminTradeOffersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminTradeOffersListQuery, Types.AdminTradeOffersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminTradeOffersListQuery, Types.AdminTradeOffersListQueryVariables>(AdminTradeOffersListDocument, options);
        }
export function useAdminTradeOffersListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminTradeOffersListQuery, Types.AdminTradeOffersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminTradeOffersListQuery, Types.AdminTradeOffersListQueryVariables>(AdminTradeOffersListDocument, options);
        }
export type AdminTradeOffersListQueryHookResult = ReturnType<typeof useAdminTradeOffersListQuery>;
export type AdminTradeOffersListLazyQueryHookResult = ReturnType<typeof useAdminTradeOffersListLazyQuery>;
export type AdminTradeOffersListSuspenseQueryHookResult = ReturnType<typeof useAdminTradeOffersListSuspenseQuery>;
export type AdminTradeOffersListQueryResult = Apollo.QueryResult<Types.AdminTradeOffersListQuery, Types.AdminTradeOffersListQueryVariables>;
export const TakeOwnershipDocument = gql`
    mutation takeOwnership($wineReviewId: ID!) {
  admin {
    wineReviews {
      takeOwnership(wineReviewId: [$wineReviewId]) {
        __typename
      }
    }
  }
}
    `;
export type TakeOwnershipMutationFn = Apollo.MutationFunction<Types.TakeOwnershipMutation, Types.TakeOwnershipMutationVariables>;

/**
 * __useTakeOwnershipMutation__
 *
 * To run a mutation, you first call `useTakeOwnershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTakeOwnershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [takeOwnershipMutation, { data, loading, error }] = useTakeOwnershipMutation({
 *   variables: {
 *      wineReviewId: // value for 'wineReviewId'
 *   },
 * });
 */
export function useTakeOwnershipMutation(baseOptions?: Apollo.MutationHookOptions<Types.TakeOwnershipMutation, Types.TakeOwnershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.TakeOwnershipMutation, Types.TakeOwnershipMutationVariables>(TakeOwnershipDocument, options);
      }
export type TakeOwnershipMutationHookResult = ReturnType<typeof useTakeOwnershipMutation>;
export type TakeOwnershipMutationResult = Apollo.MutationResult<Types.TakeOwnershipMutation>;
export type TakeOwnershipMutationOptions = Apollo.BaseMutationOptions<Types.TakeOwnershipMutation, Types.TakeOwnershipMutationVariables>;
export const CompaniesListForTakeReviewOwnershipDocument = gql`
    query companiesListForTakeReviewOwnership($localeId: Int!, $query: String) {
  companiesPagination(localeId: $localeId, perPage: 10, query: $query) {
    items {
      id
      tradeName
      representativeUser {
        id
        username
      }
    }
  }
}
    `;

/**
 * __useCompaniesListForTakeReviewOwnershipQuery__
 *
 * To run a query within a React component, call `useCompaniesListForTakeReviewOwnershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesListForTakeReviewOwnershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesListForTakeReviewOwnershipQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCompaniesListForTakeReviewOwnershipQuery(baseOptions: Apollo.QueryHookOptions<Types.CompaniesListForTakeReviewOwnershipQuery, Types.CompaniesListForTakeReviewOwnershipQueryVariables> & ({ variables: Types.CompaniesListForTakeReviewOwnershipQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompaniesListForTakeReviewOwnershipQuery, Types.CompaniesListForTakeReviewOwnershipQueryVariables>(CompaniesListForTakeReviewOwnershipDocument, options);
      }
export function useCompaniesListForTakeReviewOwnershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompaniesListForTakeReviewOwnershipQuery, Types.CompaniesListForTakeReviewOwnershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompaniesListForTakeReviewOwnershipQuery, Types.CompaniesListForTakeReviewOwnershipQueryVariables>(CompaniesListForTakeReviewOwnershipDocument, options);
        }
export function useCompaniesListForTakeReviewOwnershipSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CompaniesListForTakeReviewOwnershipQuery, Types.CompaniesListForTakeReviewOwnershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompaniesListForTakeReviewOwnershipQuery, Types.CompaniesListForTakeReviewOwnershipQueryVariables>(CompaniesListForTakeReviewOwnershipDocument, options);
        }
export type CompaniesListForTakeReviewOwnershipQueryHookResult = ReturnType<typeof useCompaniesListForTakeReviewOwnershipQuery>;
export type CompaniesListForTakeReviewOwnershipLazyQueryHookResult = ReturnType<typeof useCompaniesListForTakeReviewOwnershipLazyQuery>;
export type CompaniesListForTakeReviewOwnershipSuspenseQueryHookResult = ReturnType<typeof useCompaniesListForTakeReviewOwnershipSuspenseQuery>;
export type CompaniesListForTakeReviewOwnershipQueryResult = Apollo.QueryResult<Types.CompaniesListForTakeReviewOwnershipQuery, Types.CompaniesListForTakeReviewOwnershipQueryVariables>;
export const ReviewTransferOwnershipDocument = gql`
    mutation reviewTransferOwnership($wineReviewId: ID!, $companyId: ID!, $type: WineReviewType!) {
  admin {
    wineReviews {
      transferOwnership(
        wineReviewId: $wineReviewId
        companyId: $companyId
        type: $type
      ) {
        record {
          id
          owner {
            type: __typename
            ... on User {
              id
              username
              name
              lastName
            }
            ... on Company {
              tradeName
            }
          }
        }
      }
    }
  }
}
    `;
export type ReviewTransferOwnershipMutationFn = Apollo.MutationFunction<Types.ReviewTransferOwnershipMutation, Types.ReviewTransferOwnershipMutationVariables>;

/**
 * __useReviewTransferOwnershipMutation__
 *
 * To run a mutation, you first call `useReviewTransferOwnershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewTransferOwnershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewTransferOwnershipMutation, { data, loading, error }] = useReviewTransferOwnershipMutation({
 *   variables: {
 *      wineReviewId: // value for 'wineReviewId'
 *      companyId: // value for 'companyId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useReviewTransferOwnershipMutation(baseOptions?: Apollo.MutationHookOptions<Types.ReviewTransferOwnershipMutation, Types.ReviewTransferOwnershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ReviewTransferOwnershipMutation, Types.ReviewTransferOwnershipMutationVariables>(ReviewTransferOwnershipDocument, options);
      }
export type ReviewTransferOwnershipMutationHookResult = ReturnType<typeof useReviewTransferOwnershipMutation>;
export type ReviewTransferOwnershipMutationResult = Apollo.MutationResult<Types.ReviewTransferOwnershipMutation>;
export type ReviewTransferOwnershipMutationOptions = Apollo.BaseMutationOptions<Types.ReviewTransferOwnershipMutation, Types.ReviewTransferOwnershipMutationVariables>;
export const VerificationStatusesDocument = gql`
    query verificationStatuses($localeId: Int!) {
  expertVerificationStatuses {
    id
    code
    localizedName(localeId: $localeId)
  }
}
    `;

/**
 * __useVerificationStatusesQuery__
 *
 * To run a query within a React component, call `useVerificationStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerificationStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerificationStatusesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useVerificationStatusesQuery(baseOptions: Apollo.QueryHookOptions<Types.VerificationStatusesQuery, Types.VerificationStatusesQueryVariables> & ({ variables: Types.VerificationStatusesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.VerificationStatusesQuery, Types.VerificationStatusesQueryVariables>(VerificationStatusesDocument, options);
      }
export function useVerificationStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.VerificationStatusesQuery, Types.VerificationStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.VerificationStatusesQuery, Types.VerificationStatusesQueryVariables>(VerificationStatusesDocument, options);
        }
export function useVerificationStatusesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.VerificationStatusesQuery, Types.VerificationStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.VerificationStatusesQuery, Types.VerificationStatusesQueryVariables>(VerificationStatusesDocument, options);
        }
export type VerificationStatusesQueryHookResult = ReturnType<typeof useVerificationStatusesQuery>;
export type VerificationStatusesLazyQueryHookResult = ReturnType<typeof useVerificationStatusesLazyQuery>;
export type VerificationStatusesSuspenseQueryHookResult = ReturnType<typeof useVerificationStatusesSuspenseQuery>;
export type VerificationStatusesQueryResult = Apollo.QueryResult<Types.VerificationStatusesQuery, Types.VerificationStatusesQueryVariables>;
export const AdminAssignStripeCustomerToCompanyDocument = gql`
    mutation adminAssignStripeCustomerToCompany($stripeCustomerId: String!, $companyId: ID!) {
  admin {
    stripeCustomers {
      assign(stripeCustomerId: $stripeCustomerId, companyId: $companyId) {
        company {
          id
        }
      }
    }
  }
}
    `;
export type AdminAssignStripeCustomerToCompanyMutationFn = Apollo.MutationFunction<Types.AdminAssignStripeCustomerToCompanyMutation, Types.AdminAssignStripeCustomerToCompanyMutationVariables>;

/**
 * __useAdminAssignStripeCustomerToCompanyMutation__
 *
 * To run a mutation, you first call `useAdminAssignStripeCustomerToCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAssignStripeCustomerToCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAssignStripeCustomerToCompanyMutation, { data, loading, error }] = useAdminAssignStripeCustomerToCompanyMutation({
 *   variables: {
 *      stripeCustomerId: // value for 'stripeCustomerId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAdminAssignStripeCustomerToCompanyMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminAssignStripeCustomerToCompanyMutation, Types.AdminAssignStripeCustomerToCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminAssignStripeCustomerToCompanyMutation, Types.AdminAssignStripeCustomerToCompanyMutationVariables>(AdminAssignStripeCustomerToCompanyDocument, options);
      }
export type AdminAssignStripeCustomerToCompanyMutationHookResult = ReturnType<typeof useAdminAssignStripeCustomerToCompanyMutation>;
export type AdminAssignStripeCustomerToCompanyMutationResult = Apollo.MutationResult<Types.AdminAssignStripeCustomerToCompanyMutation>;
export type AdminAssignStripeCustomerToCompanyMutationOptions = Apollo.BaseMutationOptions<Types.AdminAssignStripeCustomerToCompanyMutation, Types.AdminAssignStripeCustomerToCompanyMutationVariables>;
export const AdminStripeCustomerDocument = gql`
    query adminStripeCustomer($id: String!) {
  admin {
    stripeCustomers {
      customer(id: $id) {
        email
        name
        subscription {
          status
          interval
          periodStart
          periodEnd
          quota
          usedQuota
        }
      }
    }
  }
}
    `;

/**
 * __useAdminStripeCustomerQuery__
 *
 * To run a query within a React component, call `useAdminStripeCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminStripeCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminStripeCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminStripeCustomerQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminStripeCustomerQuery, Types.AdminStripeCustomerQueryVariables> & ({ variables: Types.AdminStripeCustomerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminStripeCustomerQuery, Types.AdminStripeCustomerQueryVariables>(AdminStripeCustomerDocument, options);
      }
export function useAdminStripeCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminStripeCustomerQuery, Types.AdminStripeCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminStripeCustomerQuery, Types.AdminStripeCustomerQueryVariables>(AdminStripeCustomerDocument, options);
        }
export function useAdminStripeCustomerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminStripeCustomerQuery, Types.AdminStripeCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminStripeCustomerQuery, Types.AdminStripeCustomerQueryVariables>(AdminStripeCustomerDocument, options);
        }
export type AdminStripeCustomerQueryHookResult = ReturnType<typeof useAdminStripeCustomerQuery>;
export type AdminStripeCustomerLazyQueryHookResult = ReturnType<typeof useAdminStripeCustomerLazyQuery>;
export type AdminStripeCustomerSuspenseQueryHookResult = ReturnType<typeof useAdminStripeCustomerSuspenseQuery>;
export type AdminStripeCustomerQueryResult = Apollo.QueryResult<Types.AdminStripeCustomerQuery, Types.AdminStripeCustomerQueryVariables>;
export const CompanyByStripeCustomerIdDocument = gql`
    query companyByStripeCustomerId($id: String!) {
  admin {
    companies {
      companyByStripeCustomerId(stripeCustomerId: $id) {
        id
      }
    }
  }
}
    `;

/**
 * __useCompanyByStripeCustomerIdQuery__
 *
 * To run a query within a React component, call `useCompanyByStripeCustomerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyByStripeCustomerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyByStripeCustomerIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyByStripeCustomerIdQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyByStripeCustomerIdQuery, Types.CompanyByStripeCustomerIdQueryVariables> & ({ variables: Types.CompanyByStripeCustomerIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyByStripeCustomerIdQuery, Types.CompanyByStripeCustomerIdQueryVariables>(CompanyByStripeCustomerIdDocument, options);
      }
export function useCompanyByStripeCustomerIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyByStripeCustomerIdQuery, Types.CompanyByStripeCustomerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyByStripeCustomerIdQuery, Types.CompanyByStripeCustomerIdQueryVariables>(CompanyByStripeCustomerIdDocument, options);
        }
export function useCompanyByStripeCustomerIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CompanyByStripeCustomerIdQuery, Types.CompanyByStripeCustomerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompanyByStripeCustomerIdQuery, Types.CompanyByStripeCustomerIdQueryVariables>(CompanyByStripeCustomerIdDocument, options);
        }
export type CompanyByStripeCustomerIdQueryHookResult = ReturnType<typeof useCompanyByStripeCustomerIdQuery>;
export type CompanyByStripeCustomerIdLazyQueryHookResult = ReturnType<typeof useCompanyByStripeCustomerIdLazyQuery>;
export type CompanyByStripeCustomerIdSuspenseQueryHookResult = ReturnType<typeof useCompanyByStripeCustomerIdSuspenseQuery>;
export type CompanyByStripeCustomerIdQueryResult = Apollo.QueryResult<Types.CompanyByStripeCustomerIdQuery, Types.CompanyByStripeCustomerIdQueryVariables>;
export const AwardByNameDocument = gql`
    query awardByName($name: String!) {
  awardByName(name: $name) {
    id
  }
}
    `;

/**
 * __useAwardByNameQuery__
 *
 * To run a query within a React component, call `useAwardByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useAwardByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAwardByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAwardByNameQuery(baseOptions: Apollo.QueryHookOptions<Types.AwardByNameQuery, Types.AwardByNameQueryVariables> & ({ variables: Types.AwardByNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AwardByNameQuery, Types.AwardByNameQueryVariables>(AwardByNameDocument, options);
      }
export function useAwardByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AwardByNameQuery, Types.AwardByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AwardByNameQuery, Types.AwardByNameQueryVariables>(AwardByNameDocument, options);
        }
export function useAwardByNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AwardByNameQuery, Types.AwardByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AwardByNameQuery, Types.AwardByNameQueryVariables>(AwardByNameDocument, options);
        }
export type AwardByNameQueryHookResult = ReturnType<typeof useAwardByNameQuery>;
export type AwardByNameLazyQueryHookResult = ReturnType<typeof useAwardByNameLazyQuery>;
export type AwardByNameSuspenseQueryHookResult = ReturnType<typeof useAwardByNameSuspenseQuery>;
export type AwardByNameQueryResult = Apollo.QueryResult<Types.AwardByNameQuery, Types.AwardByNameQueryVariables>;
export const AwardCreateDocument = gql`
    mutation awardCreate($input: AwardCreateInput!) {
  awardCreate(input: $input) {
    recordId
  }
}
    `;
export type AwardCreateMutationFn = Apollo.MutationFunction<Types.AwardCreateMutation, Types.AwardCreateMutationVariables>;

/**
 * __useAwardCreateMutation__
 *
 * To run a mutation, you first call `useAwardCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAwardCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [awardCreateMutation, { data, loading, error }] = useAwardCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAwardCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.AwardCreateMutation, Types.AwardCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AwardCreateMutation, Types.AwardCreateMutationVariables>(AwardCreateDocument, options);
      }
export type AwardCreateMutationHookResult = ReturnType<typeof useAwardCreateMutation>;
export type AwardCreateMutationResult = Apollo.MutationResult<Types.AwardCreateMutation>;
export type AwardCreateMutationOptions = Apollo.BaseMutationOptions<Types.AwardCreateMutation, Types.AwardCreateMutationVariables>;
export const AwardForEditDocument = gql`
    query awardForEdit($id: Int!) {
  award(id: $id) {
    id
    masterName
    masterNameShort
    minScore
    maxScore
    roundScore
    stepScore
    level
    lowerLimit
  }
}
    `;

/**
 * __useAwardForEditQuery__
 *
 * To run a query within a React component, call `useAwardForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useAwardForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAwardForEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAwardForEditQuery(baseOptions: Apollo.QueryHookOptions<Types.AwardForEditQuery, Types.AwardForEditQueryVariables> & ({ variables: Types.AwardForEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AwardForEditQuery, Types.AwardForEditQueryVariables>(AwardForEditDocument, options);
      }
export function useAwardForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AwardForEditQuery, Types.AwardForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AwardForEditQuery, Types.AwardForEditQueryVariables>(AwardForEditDocument, options);
        }
export function useAwardForEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AwardForEditQuery, Types.AwardForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AwardForEditQuery, Types.AwardForEditQueryVariables>(AwardForEditDocument, options);
        }
export type AwardForEditQueryHookResult = ReturnType<typeof useAwardForEditQuery>;
export type AwardForEditLazyQueryHookResult = ReturnType<typeof useAwardForEditLazyQuery>;
export type AwardForEditSuspenseQueryHookResult = ReturnType<typeof useAwardForEditSuspenseQuery>;
export type AwardForEditQueryResult = Apollo.QueryResult<Types.AwardForEditQuery, Types.AwardForEditQueryVariables>;
export const AwardUpdateDocument = gql`
    mutation awardUpdate($input: AwardUpdateInput!) {
  awardUpdate(input: $input) {
    recordId
  }
}
    `;
export type AwardUpdateMutationFn = Apollo.MutationFunction<Types.AwardUpdateMutation, Types.AwardUpdateMutationVariables>;

/**
 * __useAwardUpdateMutation__
 *
 * To run a mutation, you first call `useAwardUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAwardUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [awardUpdateMutation, { data, loading, error }] = useAwardUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAwardUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.AwardUpdateMutation, Types.AwardUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AwardUpdateMutation, Types.AwardUpdateMutationVariables>(AwardUpdateDocument, options);
      }
export type AwardUpdateMutationHookResult = ReturnType<typeof useAwardUpdateMutation>;
export type AwardUpdateMutationResult = Apollo.MutationResult<Types.AwardUpdateMutation>;
export type AwardUpdateMutationOptions = Apollo.BaseMutationOptions<Types.AwardUpdateMutation, Types.AwardUpdateMutationVariables>;
export const RewardByNameDocument = gql`
    query rewardByName($awardId: Int!, $name: String!) {
  award(id: $awardId) {
    id
    rewardByName(name: $name) {
      id
    }
  }
}
    `;

/**
 * __useRewardByNameQuery__
 *
 * To run a query within a React component, call `useRewardByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardByNameQuery({
 *   variables: {
 *      awardId: // value for 'awardId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRewardByNameQuery(baseOptions: Apollo.QueryHookOptions<Types.RewardByNameQuery, Types.RewardByNameQueryVariables> & ({ variables: Types.RewardByNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RewardByNameQuery, Types.RewardByNameQueryVariables>(RewardByNameDocument, options);
      }
export function useRewardByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RewardByNameQuery, Types.RewardByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RewardByNameQuery, Types.RewardByNameQueryVariables>(RewardByNameDocument, options);
        }
export function useRewardByNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RewardByNameQuery, Types.RewardByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RewardByNameQuery, Types.RewardByNameQueryVariables>(RewardByNameDocument, options);
        }
export type RewardByNameQueryHookResult = ReturnType<typeof useRewardByNameQuery>;
export type RewardByNameLazyQueryHookResult = ReturnType<typeof useRewardByNameLazyQuery>;
export type RewardByNameSuspenseQueryHookResult = ReturnType<typeof useRewardByNameSuspenseQuery>;
export type RewardByNameQueryResult = Apollo.QueryResult<Types.RewardByNameQuery, Types.RewardByNameQueryVariables>;
export const AwardNameDocument = gql`
    query awardName($id: Int!) {
  award(id: $id) {
    id
    masterName
  }
}
    `;

/**
 * __useAwardNameQuery__
 *
 * To run a query within a React component, call `useAwardNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useAwardNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAwardNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAwardNameQuery(baseOptions: Apollo.QueryHookOptions<Types.AwardNameQuery, Types.AwardNameQueryVariables> & ({ variables: Types.AwardNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AwardNameQuery, Types.AwardNameQueryVariables>(AwardNameDocument, options);
      }
export function useAwardNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AwardNameQuery, Types.AwardNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AwardNameQuery, Types.AwardNameQueryVariables>(AwardNameDocument, options);
        }
export function useAwardNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AwardNameQuery, Types.AwardNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AwardNameQuery, Types.AwardNameQueryVariables>(AwardNameDocument, options);
        }
export type AwardNameQueryHookResult = ReturnType<typeof useAwardNameQuery>;
export type AwardNameLazyQueryHookResult = ReturnType<typeof useAwardNameLazyQuery>;
export type AwardNameSuspenseQueryHookResult = ReturnType<typeof useAwardNameSuspenseQuery>;
export type AwardNameQueryResult = Apollo.QueryResult<Types.AwardNameQuery, Types.AwardNameQueryVariables>;
export const AwardRewardCreateDocument = gql`
    mutation awardRewardCreate($input: AwardRewardCreateInput!) {
  awardRewardCreate(input: $input) {
    recordId
  }
}
    `;
export type AwardRewardCreateMutationFn = Apollo.MutationFunction<Types.AwardRewardCreateMutation, Types.AwardRewardCreateMutationVariables>;

/**
 * __useAwardRewardCreateMutation__
 *
 * To run a mutation, you first call `useAwardRewardCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAwardRewardCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [awardRewardCreateMutation, { data, loading, error }] = useAwardRewardCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAwardRewardCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.AwardRewardCreateMutation, Types.AwardRewardCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AwardRewardCreateMutation, Types.AwardRewardCreateMutationVariables>(AwardRewardCreateDocument, options);
      }
export type AwardRewardCreateMutationHookResult = ReturnType<typeof useAwardRewardCreateMutation>;
export type AwardRewardCreateMutationResult = Apollo.MutationResult<Types.AwardRewardCreateMutation>;
export type AwardRewardCreateMutationOptions = Apollo.BaseMutationOptions<Types.AwardRewardCreateMutation, Types.AwardRewardCreateMutationVariables>;
export const AwardRewardUpdateDocument = gql`
    mutation awardRewardUpdate($input: AwardRewardUpdateInput!) {
  awardRewardUpdate(input: $input) {
    recordId
    record {
      id
      scoreLevel {
        id
        score
        name
      }
    }
  }
}
    `;
export type AwardRewardUpdateMutationFn = Apollo.MutationFunction<Types.AwardRewardUpdateMutation, Types.AwardRewardUpdateMutationVariables>;

/**
 * __useAwardRewardUpdateMutation__
 *
 * To run a mutation, you first call `useAwardRewardUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAwardRewardUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [awardRewardUpdateMutation, { data, loading, error }] = useAwardRewardUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAwardRewardUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.AwardRewardUpdateMutation, Types.AwardRewardUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AwardRewardUpdateMutation, Types.AwardRewardUpdateMutationVariables>(AwardRewardUpdateDocument, options);
      }
export type AwardRewardUpdateMutationHookResult = ReturnType<typeof useAwardRewardUpdateMutation>;
export type AwardRewardUpdateMutationResult = Apollo.MutationResult<Types.AwardRewardUpdateMutation>;
export type AwardRewardUpdateMutationOptions = Apollo.BaseMutationOptions<Types.AwardRewardUpdateMutation, Types.AwardRewardUpdateMutationVariables>;
export const RewardForEditDocument = gql`
    query rewardForEdit($id: Int!) {
  awardReward(id: $id) {
    id
    name
    shortName
    minScore
    maxScore
    minYear
    maxYear
    scoreLevel {
      id
      name
    }
    rewardType {
      id
      masterName
    }
    award {
      id
      masterName
    }
  }
}
    `;

/**
 * __useRewardForEditQuery__
 *
 * To run a query within a React component, call `useRewardForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardForEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRewardForEditQuery(baseOptions: Apollo.QueryHookOptions<Types.RewardForEditQuery, Types.RewardForEditQueryVariables> & ({ variables: Types.RewardForEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RewardForEditQuery, Types.RewardForEditQueryVariables>(RewardForEditDocument, options);
      }
export function useRewardForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RewardForEditQuery, Types.RewardForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RewardForEditQuery, Types.RewardForEditQueryVariables>(RewardForEditDocument, options);
        }
export function useRewardForEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RewardForEditQuery, Types.RewardForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RewardForEditQuery, Types.RewardForEditQueryVariables>(RewardForEditDocument, options);
        }
export type RewardForEditQueryHookResult = ReturnType<typeof useRewardForEditQuery>;
export type RewardForEditLazyQueryHookResult = ReturnType<typeof useRewardForEditLazyQuery>;
export type RewardForEditSuspenseQueryHookResult = ReturnType<typeof useRewardForEditSuspenseQuery>;
export type RewardForEditQueryResult = Apollo.QueryResult<Types.RewardForEditQuery, Types.RewardForEditQueryVariables>;
export const TradeMarkBrandsSearchDocument = gql`
    query tradeMarkBrandsSearch($query: String!) {
  tradeMarkBrandsSearch(query: $query) {
    items
  }
}
    `;

/**
 * __useTradeMarkBrandsSearchQuery__
 *
 * To run a query within a React component, call `useTradeMarkBrandsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeMarkBrandsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeMarkBrandsSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useTradeMarkBrandsSearchQuery(baseOptions: Apollo.QueryHookOptions<Types.TradeMarkBrandsSearchQuery, Types.TradeMarkBrandsSearchQueryVariables> & ({ variables: Types.TradeMarkBrandsSearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradeMarkBrandsSearchQuery, Types.TradeMarkBrandsSearchQueryVariables>(TradeMarkBrandsSearchDocument, options);
      }
export function useTradeMarkBrandsSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradeMarkBrandsSearchQuery, Types.TradeMarkBrandsSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradeMarkBrandsSearchQuery, Types.TradeMarkBrandsSearchQueryVariables>(TradeMarkBrandsSearchDocument, options);
        }
export function useTradeMarkBrandsSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradeMarkBrandsSearchQuery, Types.TradeMarkBrandsSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradeMarkBrandsSearchQuery, Types.TradeMarkBrandsSearchQueryVariables>(TradeMarkBrandsSearchDocument, options);
        }
export type TradeMarkBrandsSearchQueryHookResult = ReturnType<typeof useTradeMarkBrandsSearchQuery>;
export type TradeMarkBrandsSearchLazyQueryHookResult = ReturnType<typeof useTradeMarkBrandsSearchLazyQuery>;
export type TradeMarkBrandsSearchSuspenseQueryHookResult = ReturnType<typeof useTradeMarkBrandsSearchSuspenseQuery>;
export type TradeMarkBrandsSearchQueryResult = Apollo.QueryResult<Types.TradeMarkBrandsSearchQuery, Types.TradeMarkBrandsSearchQueryVariables>;
export const TradeMarksByNameDocument = gql`
    query tradeMarksByName($name: String!, $localeId: Int!) {
  tradeMarksByName(name: $name) {
    id
    holderName
    country {
      id
      localizedName(localeId: $localeId)
      codeISO
    }
    status {
      id
      localizedName(localeId: $localeId)
    }
  }
}
    `;

/**
 * __useTradeMarksByNameQuery__
 *
 * To run a query within a React component, call `useTradeMarksByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeMarksByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeMarksByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useTradeMarksByNameQuery(baseOptions: Apollo.QueryHookOptions<Types.TradeMarksByNameQuery, Types.TradeMarksByNameQueryVariables> & ({ variables: Types.TradeMarksByNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradeMarksByNameQuery, Types.TradeMarksByNameQueryVariables>(TradeMarksByNameDocument, options);
      }
export function useTradeMarksByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradeMarksByNameQuery, Types.TradeMarksByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradeMarksByNameQuery, Types.TradeMarksByNameQueryVariables>(TradeMarksByNameDocument, options);
        }
export function useTradeMarksByNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradeMarksByNameQuery, Types.TradeMarksByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradeMarksByNameQuery, Types.TradeMarksByNameQueryVariables>(TradeMarksByNameDocument, options);
        }
export type TradeMarksByNameQueryHookResult = ReturnType<typeof useTradeMarksByNameQuery>;
export type TradeMarksByNameLazyQueryHookResult = ReturnType<typeof useTradeMarksByNameLazyQuery>;
export type TradeMarksByNameSuspenseQueryHookResult = ReturnType<typeof useTradeMarksByNameSuspenseQuery>;
export type TradeMarksByNameQueryResult = Apollo.QueryResult<Types.TradeMarksByNameQuery, Types.TradeMarksByNameQueryVariables>;
export const TradeNameSearchForBrandEditDocument = gql`
    query tradeNameSearchForBrandEdit($query: String, $localeId: Int!) {
  tradeNameInstantSearch(query: $query, localeId: $localeId) {
    id
    tradeName
    country {
      id
      codeISO
      isEUMember
      localizedName(localeId: $localeId)
    }
    logo {
      imageUrl
      thumbnailUrl: imageUrl(transformations: {width: 32, height: 32, resize: fit})
      thumbnailRetinaUrl: imageUrl(
        transformations: {width: 64, height: 64, resize: fit}
      )
    }
  }
}
    `;

/**
 * __useTradeNameSearchForBrandEditQuery__
 *
 * To run a query within a React component, call `useTradeNameSearchForBrandEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeNameSearchForBrandEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeNameSearchForBrandEditQuery({
 *   variables: {
 *      query: // value for 'query'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useTradeNameSearchForBrandEditQuery(baseOptions: Apollo.QueryHookOptions<Types.TradeNameSearchForBrandEditQuery, Types.TradeNameSearchForBrandEditQueryVariables> & ({ variables: Types.TradeNameSearchForBrandEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradeNameSearchForBrandEditQuery, Types.TradeNameSearchForBrandEditQueryVariables>(TradeNameSearchForBrandEditDocument, options);
      }
export function useTradeNameSearchForBrandEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradeNameSearchForBrandEditQuery, Types.TradeNameSearchForBrandEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradeNameSearchForBrandEditQuery, Types.TradeNameSearchForBrandEditQueryVariables>(TradeNameSearchForBrandEditDocument, options);
        }
export function useTradeNameSearchForBrandEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradeNameSearchForBrandEditQuery, Types.TradeNameSearchForBrandEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradeNameSearchForBrandEditQuery, Types.TradeNameSearchForBrandEditQueryVariables>(TradeNameSearchForBrandEditDocument, options);
        }
export type TradeNameSearchForBrandEditQueryHookResult = ReturnType<typeof useTradeNameSearchForBrandEditQuery>;
export type TradeNameSearchForBrandEditLazyQueryHookResult = ReturnType<typeof useTradeNameSearchForBrandEditLazyQuery>;
export type TradeNameSearchForBrandEditSuspenseQueryHookResult = ReturnType<typeof useTradeNameSearchForBrandEditSuspenseQuery>;
export type TradeNameSearchForBrandEditQueryResult = Apollo.QueryResult<Types.TradeNameSearchForBrandEditQuery, Types.TradeNameSearchForBrandEditQueryVariables>;
export const SearchBrandByIdDocument = gql`
    query searchBrandById($tradeNameId: ID!, $brandName: String!) {
  brandByCompositeId(name: $brandName, tradeNameId: $tradeNameId) {
    id
    acceptability
    canOverrideAcceptability
  }
}
    `;

/**
 * __useSearchBrandByIdQuery__
 *
 * To run a query within a React component, call `useSearchBrandByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBrandByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBrandByIdQuery({
 *   variables: {
 *      tradeNameId: // value for 'tradeNameId'
 *      brandName: // value for 'brandName'
 *   },
 * });
 */
export function useSearchBrandByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchBrandByIdQuery, Types.SearchBrandByIdQueryVariables> & ({ variables: Types.SearchBrandByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchBrandByIdQuery, Types.SearchBrandByIdQueryVariables>(SearchBrandByIdDocument, options);
      }
export function useSearchBrandByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchBrandByIdQuery, Types.SearchBrandByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchBrandByIdQuery, Types.SearchBrandByIdQueryVariables>(SearchBrandByIdDocument, options);
        }
export function useSearchBrandByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SearchBrandByIdQuery, Types.SearchBrandByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SearchBrandByIdQuery, Types.SearchBrandByIdQueryVariables>(SearchBrandByIdDocument, options);
        }
export type SearchBrandByIdQueryHookResult = ReturnType<typeof useSearchBrandByIdQuery>;
export type SearchBrandByIdLazyQueryHookResult = ReturnType<typeof useSearchBrandByIdLazyQuery>;
export type SearchBrandByIdSuspenseQueryHookResult = ReturnType<typeof useSearchBrandByIdSuspenseQuery>;
export type SearchBrandByIdQueryResult = Apollo.QueryResult<Types.SearchBrandByIdQuery, Types.SearchBrandByIdQueryVariables>;
export const PreloadCreateBrandDocument = gql`
    query preloadCreateBrand($companyId: ID!, $localeId: Int!) {
  me {
    id
    company(id: $companyId) {
      id
      producerTradeName {
        id
        tradeName
        country {
          id
          codeISO
          isEUMember
          localizedName(localeId: $localeId)
        }
      }
    }
  }
}
    `;

/**
 * __usePreloadCreateBrandQuery__
 *
 * To run a query within a React component, call `usePreloadCreateBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreloadCreateBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreloadCreateBrandQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function usePreloadCreateBrandQuery(baseOptions: Apollo.QueryHookOptions<Types.PreloadCreateBrandQuery, Types.PreloadCreateBrandQueryVariables> & ({ variables: Types.PreloadCreateBrandQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PreloadCreateBrandQuery, Types.PreloadCreateBrandQueryVariables>(PreloadCreateBrandDocument, options);
      }
export function usePreloadCreateBrandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PreloadCreateBrandQuery, Types.PreloadCreateBrandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PreloadCreateBrandQuery, Types.PreloadCreateBrandQueryVariables>(PreloadCreateBrandDocument, options);
        }
export function usePreloadCreateBrandSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PreloadCreateBrandQuery, Types.PreloadCreateBrandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PreloadCreateBrandQuery, Types.PreloadCreateBrandQueryVariables>(PreloadCreateBrandDocument, options);
        }
export type PreloadCreateBrandQueryHookResult = ReturnType<typeof usePreloadCreateBrandQuery>;
export type PreloadCreateBrandLazyQueryHookResult = ReturnType<typeof usePreloadCreateBrandLazyQuery>;
export type PreloadCreateBrandSuspenseQueryHookResult = ReturnType<typeof usePreloadCreateBrandSuspenseQuery>;
export type PreloadCreateBrandQueryResult = Apollo.QueryResult<Types.PreloadCreateBrandQuery, Types.PreloadCreateBrandQueryVariables>;
export const BrandUpdateDocument = gql`
    mutation brandUpdate($input: BrandUpdateInput!) {
  brandUpdate(input: $input) {
    recordId
  }
}
    `;
export type BrandUpdateMutationFn = Apollo.MutationFunction<Types.BrandUpdateMutation, Types.BrandUpdateMutationVariables>;

/**
 * __useBrandUpdateMutation__
 *
 * To run a mutation, you first call `useBrandUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrandUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brandUpdateMutation, { data, loading, error }] = useBrandUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBrandUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.BrandUpdateMutation, Types.BrandUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BrandUpdateMutation, Types.BrandUpdateMutationVariables>(BrandUpdateDocument, options);
      }
export type BrandUpdateMutationHookResult = ReturnType<typeof useBrandUpdateMutation>;
export type BrandUpdateMutationResult = Apollo.MutationResult<Types.BrandUpdateMutation>;
export type BrandUpdateMutationOptions = Apollo.BaseMutationOptions<Types.BrandUpdateMutation, Types.BrandUpdateMutationVariables>;
export const BrandForEditDocument = gql`
    query brandForEdit($id: ID!) {
  brand(id: $id) {
    id
    acceptability
    canOverrideAcceptability
    canOverrideFacts
    tradeName {
      id
      tradeName
      acceptability
    }
    name
    translations {
      locale {
        id
        locale
        name
      }
      name
    }
    canOverrideFacts
  }
  locales {
    id
    locale
    name
  }
}
    `;

/**
 * __useBrandForEditQuery__
 *
 * To run a query within a React component, call `useBrandForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandForEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBrandForEditQuery(baseOptions: Apollo.QueryHookOptions<Types.BrandForEditQuery, Types.BrandForEditQueryVariables> & ({ variables: Types.BrandForEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BrandForEditQuery, Types.BrandForEditQueryVariables>(BrandForEditDocument, options);
      }
export function useBrandForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BrandForEditQuery, Types.BrandForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BrandForEditQuery, Types.BrandForEditQueryVariables>(BrandForEditDocument, options);
        }
export function useBrandForEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BrandForEditQuery, Types.BrandForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BrandForEditQuery, Types.BrandForEditQueryVariables>(BrandForEditDocument, options);
        }
export type BrandForEditQueryHookResult = ReturnType<typeof useBrandForEditQuery>;
export type BrandForEditLazyQueryHookResult = ReturnType<typeof useBrandForEditLazyQuery>;
export type BrandForEditSuspenseQueryHookResult = ReturnType<typeof useBrandForEditSuspenseQuery>;
export type BrandForEditQueryResult = Apollo.QueryResult<Types.BrandForEditQuery, Types.BrandForEditQueryVariables>;
export const ViewBrandDocument = gql`
    query viewBrand($id: ID!, $localeId: Int!) {
  brand(id: $id) {
    id
    name
    localizedName(localeId: $localeId)
    tradeName {
      id
      tradeName
      acceptability
      canOverrideAcceptability
      country {
        localizedName(localeId: $localeId)
        codeISO
        id
      }
    }
    translations {
      ...viewBrandTranslationItem
    }
    acceptability
    canOverrideFacts
    canOverrideAcceptability
    shouldOverrideAcceptability
    verified
    tradeMarks {
      id
      name
      country {
        localizedName(localeId: $localeId)
        codeISO
        id
      }
      holderName
      TIN
      status {
        id
        localizedName(localeId: $localeId)
      }
    }
    mainSynonym {
      id
    }
  }
}
    ${ViewBrandTranslationItemFragmentDoc}`;

/**
 * __useViewBrandQuery__
 *
 * To run a query within a React component, call `useViewBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewBrandQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useViewBrandQuery(baseOptions: Apollo.QueryHookOptions<Types.ViewBrandQuery, Types.ViewBrandQueryVariables> & ({ variables: Types.ViewBrandQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ViewBrandQuery, Types.ViewBrandQueryVariables>(ViewBrandDocument, options);
      }
export function useViewBrandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ViewBrandQuery, Types.ViewBrandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ViewBrandQuery, Types.ViewBrandQueryVariables>(ViewBrandDocument, options);
        }
export function useViewBrandSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ViewBrandQuery, Types.ViewBrandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ViewBrandQuery, Types.ViewBrandQueryVariables>(ViewBrandDocument, options);
        }
export type ViewBrandQueryHookResult = ReturnType<typeof useViewBrandQuery>;
export type ViewBrandLazyQueryHookResult = ReturnType<typeof useViewBrandLazyQuery>;
export type ViewBrandSuspenseQueryHookResult = ReturnType<typeof useViewBrandSuspenseQuery>;
export type ViewBrandQueryResult = Apollo.QueryResult<Types.ViewBrandQuery, Types.ViewBrandQueryVariables>;
export const WineReviewRequestDocument = gql`
    mutation wineReviewRequest($input: WineReviewRequestInput!) {
  wineReviewRequest(input: $input) {
    query {
      _
    }
  }
}
    `;
export type WineReviewRequestMutationFn = Apollo.MutationFunction<Types.WineReviewRequestMutation, Types.WineReviewRequestMutationVariables>;

/**
 * __useWineReviewRequestMutation__
 *
 * To run a mutation, you first call `useWineReviewRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineReviewRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineReviewRequestMutation, { data, loading, error }] = useWineReviewRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineReviewRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineReviewRequestMutation, Types.WineReviewRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineReviewRequestMutation, Types.WineReviewRequestMutationVariables>(WineReviewRequestDocument, options);
      }
export type WineReviewRequestMutationHookResult = ReturnType<typeof useWineReviewRequestMutation>;
export type WineReviewRequestMutationResult = Apollo.MutationResult<Types.WineReviewRequestMutation>;
export type WineReviewRequestMutationOptions = Apollo.BaseMutationOptions<Types.WineReviewRequestMutation, Types.WineReviewRequestMutationVariables>;
export const CountriesForAddDealDocument = gql`
    query countriesForAddDeal($localeId: Int!) {
  countries {
    id
    masterName
    codeISO
    identityNumberName
    identityNumberFormat
    localizedPostCodeTooltip(localeId: $localeId)
    postCodeName
    postCodeMask
    postCodeFormat
    localizedIdentityNumberTooltip(localeId: $localeId)
    localizedName(localeId: $localeId)
    tinMask
    localizedIdentityNumberFormatError(localeId: $localeId)
    vatFormat
    vatMask
    localizedVATFormatError(localeId: $localeId)
    localizedVATTooltip(localeId: $localeId)
  }
}
    `;

/**
 * __useCountriesForAddDealQuery__
 *
 * To run a query within a React component, call `useCountriesForAddDealQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesForAddDealQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesForAddDealQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useCountriesForAddDealQuery(baseOptions: Apollo.QueryHookOptions<Types.CountriesForAddDealQuery, Types.CountriesForAddDealQueryVariables> & ({ variables: Types.CountriesForAddDealQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountriesForAddDealQuery, Types.CountriesForAddDealQueryVariables>(CountriesForAddDealDocument, options);
      }
export function useCountriesForAddDealLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountriesForAddDealQuery, Types.CountriesForAddDealQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountriesForAddDealQuery, Types.CountriesForAddDealQueryVariables>(CountriesForAddDealDocument, options);
        }
export function useCountriesForAddDealSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CountriesForAddDealQuery, Types.CountriesForAddDealQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CountriesForAddDealQuery, Types.CountriesForAddDealQueryVariables>(CountriesForAddDealDocument, options);
        }
export type CountriesForAddDealQueryHookResult = ReturnType<typeof useCountriesForAddDealQuery>;
export type CountriesForAddDealLazyQueryHookResult = ReturnType<typeof useCountriesForAddDealLazyQuery>;
export type CountriesForAddDealSuspenseQueryHookResult = ReturnType<typeof useCountriesForAddDealSuspenseQuery>;
export type CountriesForAddDealQueryResult = Apollo.QueryResult<Types.CountriesForAddDealQuery, Types.CountriesForAddDealQueryVariables>;
export const WineOfferDealCreateDocument = gql`
    mutation wineOfferDealCreate($input: WineOfferDealCreateInput!) {
  wineOfferDealCreate(input: $input) {
    record {
      id
    }
  }
}
    `;
export type WineOfferDealCreateMutationFn = Apollo.MutationFunction<Types.WineOfferDealCreateMutation, Types.WineOfferDealCreateMutationVariables>;

/**
 * __useWineOfferDealCreateMutation__
 *
 * To run a mutation, you first call `useWineOfferDealCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOfferDealCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOfferDealCreateMutation, { data, loading, error }] = useWineOfferDealCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOfferDealCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOfferDealCreateMutation, Types.WineOfferDealCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOfferDealCreateMutation, Types.WineOfferDealCreateMutationVariables>(WineOfferDealCreateDocument, options);
      }
export type WineOfferDealCreateMutationHookResult = ReturnType<typeof useWineOfferDealCreateMutation>;
export type WineOfferDealCreateMutationResult = Apollo.MutationResult<Types.WineOfferDealCreateMutation>;
export type WineOfferDealCreateMutationOptions = Apollo.BaseMutationOptions<Types.WineOfferDealCreateMutation, Types.WineOfferDealCreateMutationVariables>;
export const BuyWineAppellationRatingProductOffersDocument = gql`
    query buyWineAppellationRatingProductOffers($productId: ID!, $companyId: ID!, $localeId: Int!, $facetsInputs: [FacetInput!], $wineOfferStatusIds: [Int!], $wineOfferTypeIds: [Int!]) {
  product(id: $productId) {
    wineOffers(
      isBuyer: true
      companyId: $companyId
      filter: {facetsInputs: $facetsInputs, wineOfferStatusIds: $wineOfferStatusIds, wineOfferTypeIds: $wineOfferTypeIds}
    ) {
      count
      pagination(localeId: $localeId) {
        items {
          ... on WineOfferBase {
            id
            product {
              id
            }
            wineOfferType {
              code
            }
            wineOfferStatus {
              code
            }
            hasRead(companyId: $companyId)
            shortlisted(companyId: $companyId)
            pricePerBottle
            currency {
              code
              rate
            }
            priceCoefficient
            minimumDeliverySize
            incoterms {
              id
              code
              localizedShortDescription(localeId: $localeId)
              __typename
            }
            warehouse {
              id
              country {
                codeISO
                localizedName(localeId: $localeId)
                __typename
              }
              address
              territory {
                masterName
                __typename
              }
            }
            sellerCompany {
              companyCategory {
                id
              }
              tradeName
            }
            periodOfValidity
            exhibitions {
              ...exhibitionsDisplayInfo
            }
          }
        }
      }
    }
  }
}
    ${ExhibitionsDisplayInfoFragmentDoc}`;

/**
 * __useBuyWineAppellationRatingProductOffersQuery__
 *
 * To run a query within a React component, call `useBuyWineAppellationRatingProductOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyWineAppellationRatingProductOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyWineAppellationRatingProductOffersQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      facetsInputs: // value for 'facetsInputs'
 *      wineOfferStatusIds: // value for 'wineOfferStatusIds'
 *      wineOfferTypeIds: // value for 'wineOfferTypeIds'
 *   },
 * });
 */
export function useBuyWineAppellationRatingProductOffersQuery(baseOptions: Apollo.QueryHookOptions<Types.BuyWineAppellationRatingProductOffersQuery, Types.BuyWineAppellationRatingProductOffersQueryVariables> & ({ variables: Types.BuyWineAppellationRatingProductOffersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyWineAppellationRatingProductOffersQuery, Types.BuyWineAppellationRatingProductOffersQueryVariables>(BuyWineAppellationRatingProductOffersDocument, options);
      }
export function useBuyWineAppellationRatingProductOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyWineAppellationRatingProductOffersQuery, Types.BuyWineAppellationRatingProductOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyWineAppellationRatingProductOffersQuery, Types.BuyWineAppellationRatingProductOffersQueryVariables>(BuyWineAppellationRatingProductOffersDocument, options);
        }
export function useBuyWineAppellationRatingProductOffersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyWineAppellationRatingProductOffersQuery, Types.BuyWineAppellationRatingProductOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyWineAppellationRatingProductOffersQuery, Types.BuyWineAppellationRatingProductOffersQueryVariables>(BuyWineAppellationRatingProductOffersDocument, options);
        }
export type BuyWineAppellationRatingProductOffersQueryHookResult = ReturnType<typeof useBuyWineAppellationRatingProductOffersQuery>;
export type BuyWineAppellationRatingProductOffersLazyQueryHookResult = ReturnType<typeof useBuyWineAppellationRatingProductOffersLazyQuery>;
export type BuyWineAppellationRatingProductOffersSuspenseQueryHookResult = ReturnType<typeof useBuyWineAppellationRatingProductOffersSuspenseQuery>;
export type BuyWineAppellationRatingProductOffersQueryResult = Apollo.QueryResult<Types.BuyWineAppellationRatingProductOffersQuery, Types.BuyWineAppellationRatingProductOffersQueryVariables>;
export const ProductDataForAppellationRatingDocument = gql`
    query productDataForAppellationRating($slug: String!, $perPage: PositiveInt!, $geoIndicationId: Int!, $sort: ProductSort!, $localeId: Int!, $offerId: Int!) {
  productBySlug(slug: $slug) {
    id
    slug
    appellationRating(geoIndicationId: $geoIndicationId) {
      count
      rank
      geoIndication {
        id
        localizedName(localeId: $localeId)
        nationalGITypes {
          id
          localizedShortName(localeId: $localeId)
        }
      }
      page(perPage: $perPage, localeId: $localeId, sort: $sort) {
        pageInfo {
          page
        }
      }
    }
    bottleSize {
      id
      volumeML
    }
    packageType {
      id
      isDefault
      shortName
      localizedName(localeId: $localeId)
    }
    recommendedRetailPrice
    currency {
      id
      code
      rate
    }
    wine {
      id
      chemicalAnalysis {
        alcoholVolume
      }
      tradeNameBrand
      tradeName {
        id
        tradeName
      }
      keyWords
      specialClassifications {
        id
        name
      }
      vintage
      wineType {
        id
        isDefault
        localizedName(localeId: $localeId)
      }
      wineColor {
        id
        localizedName(localeId: $localeId)
      }
      sweetnessLevel {
        id
        localizedName(localeId: $localeId)
      }
      country {
        id
        localizedName(localeId: $localeId)
      }
      geoIndications {
        id
        localizedName(localeId: $localeId)
      }
      nationalGIType {
        id
        localizedShortName(localeId: $localeId)
      }
      rating
    }
  }
  wineOffer(id: $offerId) {
    ... on WineOfferBase {
      id
      pricePerBottle
      currency {
        code
        id
        rate
      }
      priceCoefficient
      minimumDeliverySize
      incoterms {
        id
        code
        localizedShortDescription(localeId: $localeId)
        __typename
      }
      warehouse {
        id
        country {
          codeISO
          localizedName(localeId: $localeId)
          __typename
        }
        address
        territory {
          masterName
          __typename
        }
      }
    }
  }
}
    `;

/**
 * __useProductDataForAppellationRatingQuery__
 *
 * To run a query within a React component, call `useProductDataForAppellationRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDataForAppellationRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDataForAppellationRatingQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      perPage: // value for 'perPage'
 *      geoIndicationId: // value for 'geoIndicationId'
 *      sort: // value for 'sort'
 *      localeId: // value for 'localeId'
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useProductDataForAppellationRatingQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductDataForAppellationRatingQuery, Types.ProductDataForAppellationRatingQueryVariables> & ({ variables: Types.ProductDataForAppellationRatingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductDataForAppellationRatingQuery, Types.ProductDataForAppellationRatingQueryVariables>(ProductDataForAppellationRatingDocument, options);
      }
export function useProductDataForAppellationRatingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductDataForAppellationRatingQuery, Types.ProductDataForAppellationRatingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductDataForAppellationRatingQuery, Types.ProductDataForAppellationRatingQueryVariables>(ProductDataForAppellationRatingDocument, options);
        }
export function useProductDataForAppellationRatingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductDataForAppellationRatingQuery, Types.ProductDataForAppellationRatingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductDataForAppellationRatingQuery, Types.ProductDataForAppellationRatingQueryVariables>(ProductDataForAppellationRatingDocument, options);
        }
export type ProductDataForAppellationRatingQueryHookResult = ReturnType<typeof useProductDataForAppellationRatingQuery>;
export type ProductDataForAppellationRatingLazyQueryHookResult = ReturnType<typeof useProductDataForAppellationRatingLazyQuery>;
export type ProductDataForAppellationRatingSuspenseQueryHookResult = ReturnType<typeof useProductDataForAppellationRatingSuspenseQuery>;
export type ProductDataForAppellationRatingQueryResult = Apollo.QueryResult<Types.ProductDataForAppellationRatingQuery, Types.ProductDataForAppellationRatingQueryVariables>;
export const ProductsForAppellationRatingTableDocument = gql`
    query productsForAppellationRatingTable($slug: String!, $geoIndicationId: Int!, $localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt!, $sort: ProductSort!, $companyId: ID!, $query: String, $facetsInputs: [FacetInput!], $wineOfferStatusIds: [Int!], $wineOfferTypeIds: [Int!], $offerCurrencyCode: String!) {
  productBySlug(slug: $slug) {
    id
    appellationRating(geoIndicationId: $geoIndicationId) {
      pagination(
        localeId: $localeId
        page: $page
        perPage: $perPage
        sort: $sort
        query: $query
        filter: {facetsInputs: $facetsInputs, wineOfferStatusIds: $wineOfferStatusIds, wineOfferTypeIds: $wineOfferTypeIds}
        companyId: $companyId
      ) {
        ...BuyWinePageOfProductAppellationRating
      }
    }
  }
}
    ${BuyWinePageOfProductAppellationRatingFragmentDoc}`;

/**
 * __useProductsForAppellationRatingTableQuery__
 *
 * To run a query within a React component, call `useProductsForAppellationRatingTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsForAppellationRatingTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsForAppellationRatingTableQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      geoIndicationId: // value for 'geoIndicationId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      companyId: // value for 'companyId'
 *      query: // value for 'query'
 *      facetsInputs: // value for 'facetsInputs'
 *      wineOfferStatusIds: // value for 'wineOfferStatusIds'
 *      wineOfferTypeIds: // value for 'wineOfferTypeIds'
 *      offerCurrencyCode: // value for 'offerCurrencyCode'
 *   },
 * });
 */
export function useProductsForAppellationRatingTableQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductsForAppellationRatingTableQuery, Types.ProductsForAppellationRatingTableQueryVariables> & ({ variables: Types.ProductsForAppellationRatingTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductsForAppellationRatingTableQuery, Types.ProductsForAppellationRatingTableQueryVariables>(ProductsForAppellationRatingTableDocument, options);
      }
export function useProductsForAppellationRatingTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductsForAppellationRatingTableQuery, Types.ProductsForAppellationRatingTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductsForAppellationRatingTableQuery, Types.ProductsForAppellationRatingTableQueryVariables>(ProductsForAppellationRatingTableDocument, options);
        }
export function useProductsForAppellationRatingTableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductsForAppellationRatingTableQuery, Types.ProductsForAppellationRatingTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductsForAppellationRatingTableQuery, Types.ProductsForAppellationRatingTableQueryVariables>(ProductsForAppellationRatingTableDocument, options);
        }
export type ProductsForAppellationRatingTableQueryHookResult = ReturnType<typeof useProductsForAppellationRatingTableQuery>;
export type ProductsForAppellationRatingTableLazyQueryHookResult = ReturnType<typeof useProductsForAppellationRatingTableLazyQuery>;
export type ProductsForAppellationRatingTableSuspenseQueryHookResult = ReturnType<typeof useProductsForAppellationRatingTableSuspenseQuery>;
export type ProductsForAppellationRatingTableQueryResult = Apollo.QueryResult<Types.ProductsForAppellationRatingTableQuery, Types.ProductsForAppellationRatingTableQueryVariables>;
export const WineOfferRequestsCompleteDocument = gql`
    mutation wineOfferRequestsComplete($input: WineOfferRequestsCompleteInput!) {
  wineOfferRequestsComplete(input: $input) {
    records {
      id
      periodOfValidity
      status
    }
  }
}
    `;
export type WineOfferRequestsCompleteMutationFn = Apollo.MutationFunction<Types.WineOfferRequestsCompleteMutation, Types.WineOfferRequestsCompleteMutationVariables>;

/**
 * __useWineOfferRequestsCompleteMutation__
 *
 * To run a mutation, you first call `useWineOfferRequestsCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestsCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOfferRequestsCompleteMutation, { data, loading, error }] = useWineOfferRequestsCompleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOfferRequestsCompleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOfferRequestsCompleteMutation, Types.WineOfferRequestsCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOfferRequestsCompleteMutation, Types.WineOfferRequestsCompleteMutationVariables>(WineOfferRequestsCompleteDocument, options);
      }
export type WineOfferRequestsCompleteMutationHookResult = ReturnType<typeof useWineOfferRequestsCompleteMutation>;
export type WineOfferRequestsCompleteMutationResult = Apollo.MutationResult<Types.WineOfferRequestsCompleteMutation>;
export type WineOfferRequestsCompleteMutationOptions = Apollo.BaseMutationOptions<Types.WineOfferRequestsCompleteMutation, Types.WineOfferRequestsCompleteMutationVariables>;
export const CompanyGrantInfoDocument = gql`
    mutation companyGrantInfo($companyId: ID!, $recipientCompanyId: ID!, $offerId: Int!) {
  companyGrantInfo(
    input: {companyId: $companyId, recipientCompanyId: $recipientCompanyId}
  ) {
    query {
      wineOffer(id: $offerId) {
        ... on WineOfferBase {
          id
        }
        ... on WineOfferCompany {
          canBuyerGrantCompanyInfo
          canBuyerRevokeCompanyInfo
        }
        ... on WineOfferPersonal {
          canBuyerGrantCompanyInfo
          canBuyerRevokeCompanyInfo
        }
      }
    }
  }
}
    `;
export type CompanyGrantInfoMutationFn = Apollo.MutationFunction<Types.CompanyGrantInfoMutation, Types.CompanyGrantInfoMutationVariables>;

/**
 * __useCompanyGrantInfoMutation__
 *
 * To run a mutation, you first call `useCompanyGrantInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyGrantInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyGrantInfoMutation, { data, loading, error }] = useCompanyGrantInfoMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      recipientCompanyId: // value for 'recipientCompanyId'
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useCompanyGrantInfoMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompanyGrantInfoMutation, Types.CompanyGrantInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompanyGrantInfoMutation, Types.CompanyGrantInfoMutationVariables>(CompanyGrantInfoDocument, options);
      }
export type CompanyGrantInfoMutationHookResult = ReturnType<typeof useCompanyGrantInfoMutation>;
export type CompanyGrantInfoMutationResult = Apollo.MutationResult<Types.CompanyGrantInfoMutation>;
export type CompanyGrantInfoMutationOptions = Apollo.BaseMutationOptions<Types.CompanyGrantInfoMutation, Types.CompanyGrantInfoMutationVariables>;
export const GrantInfoCompanyDocument = gql`
    query grantInfoCompany($id: ID!, $localeId: Int!) {
  me {
    company(id: $id) {
      id
      tradeName
      companyCategory {
        id
        masterName
        shortName: localizedShortName(localeId: $localeId)
      }
      country {
        id
        codeISO
        localizedName(localeId: $localeId)
        vatName
        identityNumberName
        branchNumberName
      }
      isVerifiedUser
      avatar {
        imageUrl
        thumbnailUrl: imageUrl(transformations: {width: 40, height: 40, resize: fit})
        thumbnailRetinaUrl: imageUrl(
          transformations: {width: 80, height: 80, resize: fit}
        )
      }
      website
      postCode
      postAddress
      email
      phone
      representativeUser {
        id
        name
        username
        lastName
      }
      legalEntity {
        identityNumber
        branchNumber
        vat
        legalEntityForm {
          originName
        }
        legalName
        legalAddress
        activityCode
      }
    }
  }
}
    `;

/**
 * __useGrantInfoCompanyQuery__
 *
 * To run a query within a React component, call `useGrantInfoCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrantInfoCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrantInfoCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useGrantInfoCompanyQuery(baseOptions: Apollo.QueryHookOptions<Types.GrantInfoCompanyQuery, Types.GrantInfoCompanyQueryVariables> & ({ variables: Types.GrantInfoCompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GrantInfoCompanyQuery, Types.GrantInfoCompanyQueryVariables>(GrantInfoCompanyDocument, options);
      }
export function useGrantInfoCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GrantInfoCompanyQuery, Types.GrantInfoCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GrantInfoCompanyQuery, Types.GrantInfoCompanyQueryVariables>(GrantInfoCompanyDocument, options);
        }
export function useGrantInfoCompanySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GrantInfoCompanyQuery, Types.GrantInfoCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GrantInfoCompanyQuery, Types.GrantInfoCompanyQueryVariables>(GrantInfoCompanyDocument, options);
        }
export type GrantInfoCompanyQueryHookResult = ReturnType<typeof useGrantInfoCompanyQuery>;
export type GrantInfoCompanyLazyQueryHookResult = ReturnType<typeof useGrantInfoCompanyLazyQuery>;
export type GrantInfoCompanySuspenseQueryHookResult = ReturnType<typeof useGrantInfoCompanySuspenseQuery>;
export type GrantInfoCompanyQueryResult = Apollo.QueryResult<Types.GrantInfoCompanyQuery, Types.GrantInfoCompanyQueryVariables>;
export const WineOfferBuyerOfferConditionsDocument = gql`
    mutation wineOfferBuyerOfferConditions($input: WineOfferBuyerOfferConditionsInput!) {
  wineOfferBuyerOfferConditions(input: $input) {
    record {
      id
      wineOfferStatus {
        code
      }
      buyerPeriodOfValidity
      buyerPrice
      buyerPricePerBottle
      buyerIncoterms {
        id
      }
      buyerDeliverySize
      comment
    }
  }
}
    `;
export type WineOfferBuyerOfferConditionsMutationFn = Apollo.MutationFunction<Types.WineOfferBuyerOfferConditionsMutation, Types.WineOfferBuyerOfferConditionsMutationVariables>;

/**
 * __useWineOfferBuyerOfferConditionsMutation__
 *
 * To run a mutation, you first call `useWineOfferBuyerOfferConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOfferBuyerOfferConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOfferBuyerOfferConditionsMutation, { data, loading, error }] = useWineOfferBuyerOfferConditionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOfferBuyerOfferConditionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOfferBuyerOfferConditionsMutation, Types.WineOfferBuyerOfferConditionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOfferBuyerOfferConditionsMutation, Types.WineOfferBuyerOfferConditionsMutationVariables>(WineOfferBuyerOfferConditionsDocument, options);
      }
export type WineOfferBuyerOfferConditionsMutationHookResult = ReturnType<typeof useWineOfferBuyerOfferConditionsMutation>;
export type WineOfferBuyerOfferConditionsMutationResult = Apollo.MutationResult<Types.WineOfferBuyerOfferConditionsMutation>;
export type WineOfferBuyerOfferConditionsMutationOptions = Apollo.BaseMutationOptions<Types.WineOfferBuyerOfferConditionsMutation, Types.WineOfferBuyerOfferConditionsMutationVariables>;
export const BuyWineAnalysisDocument = gql`
    query buyWineAnalysis($companyId: ID!, $localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt!, $sort: WineOfferSort!, $query: String, $filter: WineOfferFilter) {
  me {
    id
    company(id: $companyId) {
      id
      wineOffersPagination(
        localeId: $localeId
        page: $page
        perPage: $perPage
        sort: $sort
        filter: $filter
        query: $query
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          totalPages
          totalItems
          page
          perPage
        }
        items {
          __typename
          ... on WineOfferBase {
            id
            hasRead(companyId: $companyId)
            shortlisted(companyId: $companyId)
            minimumDeliverySize
            wineOfferType {
              code
            }
            wineOfferStatus {
              code
            }
            priceCoefficient
            price
            product {
              id
              slug
              mainSynonym {
                ... on Product {
                  id
                }
              }
              priceAtWinery
              recommendedRetailPrice
              currency {
                code
                id
              }
              wine {
                id
                chemicalAnalysis {
                  alcoholVolume
                }
                country {
                  id
                  localizedName(localeId: $localeId)
                  codeISO
                }
                tradeNameBrand
                brand {
                  name
                  acceptability
                  popularity
                }
                acceptability
                keyWords
                tradeName {
                  acceptability
                  tradeName
                  country {
                    id
                    localizedName(localeId: $localeId)
                    codeISO
                  }
                }
                wineType {
                  isDefault
                  masterName
                  localizedName(localeId: $localeId)
                }
                wineColor {
                  localizedName(localeId: $localeId)
                }
                sweetnessLevel {
                  masterName
                  isDefault
                  localizedName(localeId: $localeId)
                }
                specialClassifications {
                  name
                }
                vintage
                grapeVarieties {
                  grapeVarietySynonym {
                    name
                  }
                  value
                }
                nationalGIType {
                  shortName
                  localizedName(localeId: $localeId)
                }
                geoIndications {
                  localizedName(localeId: $localeId)
                  wineRegionsEU {
                    localizedName(localeId: $localeId)
                  }
                }
                rating
              }
              bottleSize {
                id
                volumeML
              }
              packageType {
                id
                isDefault
                shortName
                localizedName(localeId: $localeId)
              }
              bottleImage {
                imageUrl
                thumbnailUrl: imageUrl(transformations: {width: 48, height: 96, resize: fit})
                thumbnailRetinaUrl: imageUrl(
                  transformations: {width: 96, height: 192, resize: fit}
                )
              }
              appellationRatings {
                rank
                count
                geoIndication {
                  id
                  localizedName(localeId: $localeId)
                }
              }
            }
            pricePerBottle
            currency {
              code
              id
            }
            lot {
              id
              localizedName(localeId: $localeId)
              bottleQuantity
            }
            wineOfferType {
              code
            }
            periodOfValidity
            sellerCompany {
              tradeName
              companyCategory {
                id
                masterName
                localizedName(localeId: $localeId)
              }
            }
            incoterms {
              id
              code
              localizedShortDescription(localeId: $localeId)
            }
            warehouse {
              id
              country {
                codeISO
                localizedName(localeId: $localeId)
                codeISO
              }
              address
              territory {
                masterName
              }
            }
            exhibitions {
              ...exhibitionsDisplayInfo
            }
          }
          ... on WineOfferPersonal {
            buyerPrice
            buyerPricePerBottle
            buyerIncoterms {
              id
              code
              localizedShortDescription(localeId: $localeId)
            }
            buyerDeliverySize
            buyerPeriodOfValidity
            buyerCompany: buyer(companyId: $companyId) {
              ... on Company {
                tradeName
                id
                companyCategory {
                  id
                  masterName
                  localizedName(localeId: $localeId)
                }
              }
            }
          }
          ... on WineOfferCompany {
            buyer(companyId: $companyId) {
              ... on Company {
                id
                tradeName
                companyCategory {
                  id
                  masterName
                  localizedName(localeId: $localeId)
                }
              }
            }
            wineOfferDistLimits {
              country {
                id
                localizedName(localeId: $localeId)
                codeISO
              }
              address
              isIncluded
              territory {
                radius
                radiusUnit {
                  id
                  masterName
                }
              }
            }
          }
          ... on WineOfferPublic {
            buyerCompanyCategory {
              id
              masterName
              localizedName(localeId: $localeId)
            }
            wineOfferDistLimits {
              country {
                id
                localizedName(localeId: $localeId)
              }
              address
              isIncluded
              territory {
                radius
                radiusUnit {
                  id
                  masterName
                }
              }
            }
          }
          ... on WineOfferDeal {
            buyerCompany {
              tradeName
              id
              companyCategory {
                id
                masterName
                localizedName(localeId: $localeId)
              }
            }
            wineOfferType {
              code
            }
            wineOfferStatus {
              code
            }
            sellerCompanyCategory {
              id
              localizedName(localeId: $localeId)
            }
            sellerTradeName
            createdAt
          }
        }
      }
    }
  }
}
    ${ExhibitionsDisplayInfoFragmentDoc}`;

/**
 * __useBuyWineAnalysisQuery__
 *
 * To run a query within a React component, call `useBuyWineAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyWineAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyWineAnalysisQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBuyWineAnalysisQuery(baseOptions: Apollo.QueryHookOptions<Types.BuyWineAnalysisQuery, Types.BuyWineAnalysisQueryVariables> & ({ variables: Types.BuyWineAnalysisQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyWineAnalysisQuery, Types.BuyWineAnalysisQueryVariables>(BuyWineAnalysisDocument, options);
      }
export function useBuyWineAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyWineAnalysisQuery, Types.BuyWineAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyWineAnalysisQuery, Types.BuyWineAnalysisQueryVariables>(BuyWineAnalysisDocument, options);
        }
export function useBuyWineAnalysisSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyWineAnalysisQuery, Types.BuyWineAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyWineAnalysisQuery, Types.BuyWineAnalysisQueryVariables>(BuyWineAnalysisDocument, options);
        }
export type BuyWineAnalysisQueryHookResult = ReturnType<typeof useBuyWineAnalysisQuery>;
export type BuyWineAnalysisLazyQueryHookResult = ReturnType<typeof useBuyWineAnalysisLazyQuery>;
export type BuyWineAnalysisSuspenseQueryHookResult = ReturnType<typeof useBuyWineAnalysisSuspenseQuery>;
export type BuyWineAnalysisQueryResult = Apollo.QueryResult<Types.BuyWineAnalysisQuery, Types.BuyWineAnalysisQueryVariables>;
export const BuyWineWineOffersDocument = gql`
    query buyWineWineOffers($companyId: ID!, $localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt!, $sort: WineOfferSort!, $query: String, $filter: WineOfferFilter) {
  me {
    id
    company(id: $companyId) {
      id
      wineOffersPagination(
        localeId: $localeId
        page: $page
        perPage: $perPage
        sort: $sort
        filter: $filter
        query: $query
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          totalPages
          totalItems
          page
          perPage
        }
        items {
          __typename
          ... on WineOfferBase {
            id
            hasRead(companyId: $companyId)
            shortlisted(companyId: $companyId)
            minimumDeliverySize
            wineOfferType {
              code
            }
            wineOfferStatus {
              code
            }
            priceCoefficient
            price
            product {
              id
              slug
              mainSynonym {
                ... on Product {
                  id
                }
              }
              acceptability
              priceAtWinery
              currency {
                code
                id
              }
              wine {
                id
                chemicalAnalysis {
                  alcoholVolume
                }
                country {
                  id
                  localizedName(localeId: $localeId)
                  codeISO
                }
                tradeNameBrand
                brand {
                  name
                  acceptability
                  popularity
                }
                acceptability
                keyWords
                tradeName {
                  acceptability
                  tradeName
                  country {
                    id
                    localizedName(localeId: $localeId)
                    codeISO
                  }
                }
                wineType {
                  isDefault
                  masterName
                  localizedName(localeId: $localeId)
                }
                wineColor {
                  localizedName(localeId: $localeId)
                }
                sweetnessLevel {
                  masterName
                  isDefault
                  localizedName(localeId: $localeId)
                }
                specialClassifications {
                  name
                }
                vintage
                grapeVarieties {
                  grapeVarietySynonym {
                    name
                  }
                  value
                }
                nationalGIType {
                  shortName
                  localizedName(localeId: $localeId)
                }
                geoIndications {
                  localizedName(localeId: $localeId)
                  wineRegionsEU {
                    localizedName(localeId: $localeId)
                  }
                }
                rating
              }
              bottleSize {
                id
                volumeML
              }
              packageType {
                id
                isDefault
                shortName
                localizedName(localeId: $localeId)
              }
              bottleImage {
                imageUrl
                thumbnailUrl: imageUrl(transformations: {width: 48, height: 96, resize: fit})
                thumbnailRetinaUrl: imageUrl(
                  transformations: {width: 96, height: 192, resize: fit}
                )
              }
            }
            pricePerBottle
            currency {
              code
              id
            }
            lot {
              id
              localizedName(localeId: $localeId)
              bottleQuantity
            }
            wineOfferType {
              code
            }
            periodOfValidity
            sellerCompany {
              id
              tradeName
              companyCategory {
                id
                masterName
                localizedName(localeId: $localeId)
              }
            }
            isExclusiveRight
            incoterms {
              id
              code
              localizedShortDescription(localeId: $localeId)
            }
            warehouse {
              id
              country {
                id
                codeISO
                localizedName(localeId: $localeId)
              }
              address
              territory {
                masterName
              }
            }
            exhibitions {
              ...exhibitionsDisplayInfo
            }
          }
          ... on WineOfferPersonal {
            isExclusiveRight
            buyerPrice
            buyerPricePerBottle
            buyerIncoterms {
              id
              code
              localizedShortDescription(localeId: $localeId)
            }
            buyerDeliverySize
            buyerPeriodOfValidity
            buyerContactsRequested
            canBuyerGrantCompanyInfo
            canBuyerRevokeCompanyInfo
            buyerCompany: buyer(companyId: $companyId) {
              ... on Company {
                id
                tradeName
                companyCategory {
                  id
                  masterName
                  localizedName(localeId: $localeId)
                }
              }
            }
            wineOfferDistLimits {
              ...BuyWineFullWineOfferDist
            }
          }
          ... on WineOfferCompany {
            buyer(companyId: $companyId) {
              ... on Company {
                id
                tradeName
                companyCategory {
                  id
                  masterName
                  localizedName(localeId: $localeId)
                }
              }
            }
            buyerContactsRequested
            canBuyerGrantCompanyInfo
            canBuyerRevokeCompanyInfo
            wineOfferDistLimits {
              ...BuyWineFullWineOfferDist
            }
          }
          ... on WineOfferPublic {
            buyerCompanyCategory {
              id
              masterName
              localizedName(localeId: $localeId)
            }
            wineOfferDistLimits {
              ...BuyWineFullWineOfferDist
            }
          }
          ... on WineOfferDeal {
            buyerCompany {
              tradeName
              id
              companyCategory {
                id
                masterName
                localizedName(localeId: $localeId)
              }
            }
            wineOfferType {
              code
            }
            wineOfferStatus {
              code
            }
            sellerCompanyCategory {
              id
              localizedName(localeId: $localeId)
            }
            sellerTradeName
            createdAt
          }
        }
      }
    }
  }
}
    ${ExhibitionsDisplayInfoFragmentDoc}
${BuyWineFullWineOfferDistFragmentDoc}`;

/**
 * __useBuyWineWineOffersQuery__
 *
 * To run a query within a React component, call `useBuyWineWineOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyWineWineOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyWineWineOffersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBuyWineWineOffersQuery(baseOptions: Apollo.QueryHookOptions<Types.BuyWineWineOffersQuery, Types.BuyWineWineOffersQueryVariables> & ({ variables: Types.BuyWineWineOffersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyWineWineOffersQuery, Types.BuyWineWineOffersQueryVariables>(BuyWineWineOffersDocument, options);
      }
export function useBuyWineWineOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyWineWineOffersQuery, Types.BuyWineWineOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyWineWineOffersQuery, Types.BuyWineWineOffersQueryVariables>(BuyWineWineOffersDocument, options);
        }
export function useBuyWineWineOffersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyWineWineOffersQuery, Types.BuyWineWineOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyWineWineOffersQuery, Types.BuyWineWineOffersQueryVariables>(BuyWineWineOffersDocument, options);
        }
export type BuyWineWineOffersQueryHookResult = ReturnType<typeof useBuyWineWineOffersQuery>;
export type BuyWineWineOffersLazyQueryHookResult = ReturnType<typeof useBuyWineWineOffersLazyQuery>;
export type BuyWineWineOffersSuspenseQueryHookResult = ReturnType<typeof useBuyWineWineOffersSuspenseQuery>;
export type BuyWineWineOffersQueryResult = Apollo.QueryResult<Types.BuyWineWineOffersQuery, Types.BuyWineWineOffersQueryVariables>;
export const CompanyRevokeInfoDocument = gql`
    mutation companyRevokeInfo($companyId: ID!, $recipientCompanyId: ID!, $offerId: Int!) {
  companyRevokeInfo(
    input: {companyId: $companyId, recipientCompanyId: $recipientCompanyId}
  ) {
    query {
      wineOffer(id: $offerId) {
        ... on WineOfferBase {
          id
        }
        ... on WineOfferCompany {
          canBuyerGrantCompanyInfo
          canBuyerRevokeCompanyInfo
        }
        ... on WineOfferPersonal {
          canBuyerGrantCompanyInfo
          canBuyerRevokeCompanyInfo
        }
      }
    }
  }
}
    `;
export type CompanyRevokeInfoMutationFn = Apollo.MutationFunction<Types.CompanyRevokeInfoMutation, Types.CompanyRevokeInfoMutationVariables>;

/**
 * __useCompanyRevokeInfoMutation__
 *
 * To run a mutation, you first call `useCompanyRevokeInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyRevokeInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyRevokeInfoMutation, { data, loading, error }] = useCompanyRevokeInfoMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      recipientCompanyId: // value for 'recipientCompanyId'
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useCompanyRevokeInfoMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompanyRevokeInfoMutation, Types.CompanyRevokeInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompanyRevokeInfoMutation, Types.CompanyRevokeInfoMutationVariables>(CompanyRevokeInfoDocument, options);
      }
export type CompanyRevokeInfoMutationHookResult = ReturnType<typeof useCompanyRevokeInfoMutation>;
export type CompanyRevokeInfoMutationResult = Apollo.MutationResult<Types.CompanyRevokeInfoMutation>;
export type CompanyRevokeInfoMutationOptions = Apollo.BaseMutationOptions<Types.CompanyRevokeInfoMutation, Types.CompanyRevokeInfoMutationVariables>;
export const WineNegotiationsOfferDocument = gql`
    query wineNegotiationsOffer($id: Int!, $localeId: Int!) {
  wineOffer(id: $id) {
    __typename
    ... on WineOfferBase {
      note
      price
      pricePerBottle
      warehouse {
        id
        name
      }
      incoterms {
        id
        localizedShortDescription(localeId: $localeId)
        code
      }
      lot {
        id
        masterName
        localizedName(localeId: $localeId)
        bottleQuantity
      }
      minimumDeliverySize
      periodOfValidity
      currency {
        code
        id
      }
      wineOfferStatus {
        id
        code
      }
      wineOfferType {
        id
        code
      }
    }
    ... on WineOfferPersonal {
      buyerPrice
      buyerPricePerBottle
      buyerIncoterms {
        id
        code
        localizedShortDescription(localeId: $localeId)
      }
      buyerDeliverySize
      buyerPeriodOfValidity
      comment
    }
  }
}
    `;

/**
 * __useWineNegotiationsOfferQuery__
 *
 * To run a query within a React component, call `useWineNegotiationsOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineNegotiationsOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineNegotiationsOfferQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useWineNegotiationsOfferQuery(baseOptions: Apollo.QueryHookOptions<Types.WineNegotiationsOfferQuery, Types.WineNegotiationsOfferQueryVariables> & ({ variables: Types.WineNegotiationsOfferQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineNegotiationsOfferQuery, Types.WineNegotiationsOfferQueryVariables>(WineNegotiationsOfferDocument, options);
      }
export function useWineNegotiationsOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineNegotiationsOfferQuery, Types.WineNegotiationsOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineNegotiationsOfferQuery, Types.WineNegotiationsOfferQueryVariables>(WineNegotiationsOfferDocument, options);
        }
export function useWineNegotiationsOfferSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineNegotiationsOfferQuery, Types.WineNegotiationsOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineNegotiationsOfferQuery, Types.WineNegotiationsOfferQueryVariables>(WineNegotiationsOfferDocument, options);
        }
export type WineNegotiationsOfferQueryHookResult = ReturnType<typeof useWineNegotiationsOfferQuery>;
export type WineNegotiationsOfferLazyQueryHookResult = ReturnType<typeof useWineNegotiationsOfferLazyQuery>;
export type WineNegotiationsOfferSuspenseQueryHookResult = ReturnType<typeof useWineNegotiationsOfferSuspenseQuery>;
export type WineNegotiationsOfferQueryResult = Apollo.QueryResult<Types.WineNegotiationsOfferQuery, Types.WineNegotiationsOfferQueryVariables>;
export const WineOffersAddToShortlistDocument = gql`
    mutation wineOffersAddToShortlist($companyId: ID!, $wineOfferIds: [Int!]!) {
  wineOffersAddToShortlist(
    input: {companyId: $companyId, wineOfferIds: $wineOfferIds}
  ) {
    records {
      id
      shortlisted(companyId: $companyId)
    }
  }
}
    `;
export type WineOffersAddToShortlistMutationFn = Apollo.MutationFunction<Types.WineOffersAddToShortlistMutation, Types.WineOffersAddToShortlistMutationVariables>;

/**
 * __useWineOffersAddToShortlistMutation__
 *
 * To run a mutation, you first call `useWineOffersAddToShortlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOffersAddToShortlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOffersAddToShortlistMutation, { data, loading, error }] = useWineOffersAddToShortlistMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      wineOfferIds: // value for 'wineOfferIds'
 *   },
 * });
 */
export function useWineOffersAddToShortlistMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOffersAddToShortlistMutation, Types.WineOffersAddToShortlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOffersAddToShortlistMutation, Types.WineOffersAddToShortlistMutationVariables>(WineOffersAddToShortlistDocument, options);
      }
export type WineOffersAddToShortlistMutationHookResult = ReturnType<typeof useWineOffersAddToShortlistMutation>;
export type WineOffersAddToShortlistMutationResult = Apollo.MutationResult<Types.WineOffersAddToShortlistMutation>;
export type WineOffersAddToShortlistMutationOptions = Apollo.BaseMutationOptions<Types.WineOffersAddToShortlistMutation, Types.WineOffersAddToShortlistMutationVariables>;
export const WineOffersArchiveDocument = gql`
    mutation wineOffersArchive($companyId: ID!, $wineOfferIds: [Int!]!) {
  wineOffersArchive(input: {companyId: $companyId, wineOfferIds: $wineOfferIds}) {
    records {
      id
    }
  }
}
    `;
export type WineOffersArchiveMutationFn = Apollo.MutationFunction<Types.WineOffersArchiveMutation, Types.WineOffersArchiveMutationVariables>;

/**
 * __useWineOffersArchiveMutation__
 *
 * To run a mutation, you first call `useWineOffersArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOffersArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOffersArchiveMutation, { data, loading, error }] = useWineOffersArchiveMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      wineOfferIds: // value for 'wineOfferIds'
 *   },
 * });
 */
export function useWineOffersArchiveMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOffersArchiveMutation, Types.WineOffersArchiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOffersArchiveMutation, Types.WineOffersArchiveMutationVariables>(WineOffersArchiveDocument, options);
      }
export type WineOffersArchiveMutationHookResult = ReturnType<typeof useWineOffersArchiveMutation>;
export type WineOffersArchiveMutationResult = Apollo.MutationResult<Types.WineOffersArchiveMutation>;
export type WineOffersArchiveMutationOptions = Apollo.BaseMutationOptions<Types.WineOffersArchiveMutation, Types.WineOffersArchiveMutationVariables>;
export const WineOffersRejectDocument = gql`
    mutation wineOffersReject($companyId: ID!, $wineOfferIds: [Int!]!) {
  wineOffersReject(input: {companyId: $companyId, wineOfferIds: $wineOfferIds}) {
    records {
      id
    }
  }
}
    `;
export type WineOffersRejectMutationFn = Apollo.MutationFunction<Types.WineOffersRejectMutation, Types.WineOffersRejectMutationVariables>;

/**
 * __useWineOffersRejectMutation__
 *
 * To run a mutation, you first call `useWineOffersRejectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOffersRejectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOffersRejectMutation, { data, loading, error }] = useWineOffersRejectMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      wineOfferIds: // value for 'wineOfferIds'
 *   },
 * });
 */
export function useWineOffersRejectMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOffersRejectMutation, Types.WineOffersRejectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOffersRejectMutation, Types.WineOffersRejectMutationVariables>(WineOffersRejectDocument, options);
      }
export type WineOffersRejectMutationHookResult = ReturnType<typeof useWineOffersRejectMutation>;
export type WineOffersRejectMutationResult = Apollo.MutationResult<Types.WineOffersRejectMutation>;
export type WineOffersRejectMutationOptions = Apollo.BaseMutationOptions<Types.WineOffersRejectMutation, Types.WineOffersRejectMutationVariables>;
export const WineOffersRemoveFromShortlistDocument = gql`
    mutation wineOffersRemoveFromShortlist($companyId: ID!, $wineOfferIds: [Int!]!) {
  wineOffersRemoveFromShortlist(
    input: {companyId: $companyId, wineOfferIds: $wineOfferIds}
  ) {
    records {
      id
      shortlisted(companyId: $companyId)
    }
  }
}
    `;
export type WineOffersRemoveFromShortlistMutationFn = Apollo.MutationFunction<Types.WineOffersRemoveFromShortlistMutation, Types.WineOffersRemoveFromShortlistMutationVariables>;

/**
 * __useWineOffersRemoveFromShortlistMutation__
 *
 * To run a mutation, you first call `useWineOffersRemoveFromShortlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOffersRemoveFromShortlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOffersRemoveFromShortlistMutation, { data, loading, error }] = useWineOffersRemoveFromShortlistMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      wineOfferIds: // value for 'wineOfferIds'
 *   },
 * });
 */
export function useWineOffersRemoveFromShortlistMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOffersRemoveFromShortlistMutation, Types.WineOffersRemoveFromShortlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOffersRemoveFromShortlistMutation, Types.WineOffersRemoveFromShortlistMutationVariables>(WineOffersRemoveFromShortlistDocument, options);
      }
export type WineOffersRemoveFromShortlistMutationHookResult = ReturnType<typeof useWineOffersRemoveFromShortlistMutation>;
export type WineOffersRemoveFromShortlistMutationResult = Apollo.MutationResult<Types.WineOffersRemoveFromShortlistMutation>;
export type WineOffersRemoveFromShortlistMutationOptions = Apollo.BaseMutationOptions<Types.WineOffersRemoveFromShortlistMutation, Types.WineOffersRemoveFromShortlistMutationVariables>;
export const WineOfferRequestCreateDocument = gql`
    mutation wineOfferRequestCreate($input: WineOfferRequestCreateInput!) {
  wineOfferRequestCreate(input: $input) {
    request {
      id
    }
  }
}
    `;
export type WineOfferRequestCreateMutationFn = Apollo.MutationFunction<Types.WineOfferRequestCreateMutation, Types.WineOfferRequestCreateMutationVariables>;

/**
 * __useWineOfferRequestCreateMutation__
 *
 * To run a mutation, you first call `useWineOfferRequestCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOfferRequestCreateMutation, { data, loading, error }] = useWineOfferRequestCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOfferRequestCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOfferRequestCreateMutation, Types.WineOfferRequestCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOfferRequestCreateMutation, Types.WineOfferRequestCreateMutationVariables>(WineOfferRequestCreateDocument, options);
      }
export type WineOfferRequestCreateMutationHookResult = ReturnType<typeof useWineOfferRequestCreateMutation>;
export type WineOfferRequestCreateMutationResult = Apollo.MutationResult<Types.WineOfferRequestCreateMutation>;
export type WineOfferRequestCreateMutationOptions = Apollo.BaseMutationOptions<Types.WineOfferRequestCreateMutation, Types.WineOfferRequestCreateMutationVariables>;
export const WineOfferRequestSellersCountDocument = gql`
    query wineOfferRequestSellersCount($input: WineOfferRequestSellersInput!) {
  wineOfferRequestSellers(input: $input) {
    count
  }
}
    `;

/**
 * __useWineOfferRequestSellersCountQuery__
 *
 * To run a query within a React component, call `useWineOfferRequestSellersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestSellersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineOfferRequestSellersCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOfferRequestSellersCountQuery(baseOptions: Apollo.QueryHookOptions<Types.WineOfferRequestSellersCountQuery, Types.WineOfferRequestSellersCountQueryVariables> & ({ variables: Types.WineOfferRequestSellersCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineOfferRequestSellersCountQuery, Types.WineOfferRequestSellersCountQueryVariables>(WineOfferRequestSellersCountDocument, options);
      }
export function useWineOfferRequestSellersCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineOfferRequestSellersCountQuery, Types.WineOfferRequestSellersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineOfferRequestSellersCountQuery, Types.WineOfferRequestSellersCountQueryVariables>(WineOfferRequestSellersCountDocument, options);
        }
export function useWineOfferRequestSellersCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineOfferRequestSellersCountQuery, Types.WineOfferRequestSellersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineOfferRequestSellersCountQuery, Types.WineOfferRequestSellersCountQueryVariables>(WineOfferRequestSellersCountDocument, options);
        }
export type WineOfferRequestSellersCountQueryHookResult = ReturnType<typeof useWineOfferRequestSellersCountQuery>;
export type WineOfferRequestSellersCountLazyQueryHookResult = ReturnType<typeof useWineOfferRequestSellersCountLazyQuery>;
export type WineOfferRequestSellersCountSuspenseQueryHookResult = ReturnType<typeof useWineOfferRequestSellersCountSuspenseQuery>;
export type WineOfferRequestSellersCountQueryResult = Apollo.QueryResult<Types.WineOfferRequestSellersCountQuery, Types.WineOfferRequestSellersCountQueryVariables>;
export const BuyerSearchDocument = gql`
    query buyerSearch($page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $query: String, $companyId: ID) {
  buyersPagination(
    companyId: $companyId
    page: $page
    perPage: $perPage
    query: $query
  ) {
    pageInfo {
      page
      perPage
      hasNextPage
      hasPreviousPage
    }
    items {
      ... on Company {
        id
        tradeName
      }
      ... on HiddenCompany {
        id
        companyCategory {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useBuyerSearchQuery__
 *
 * To run a query within a React component, call `useBuyerSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyerSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyerSearchQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useBuyerSearchQuery(baseOptions?: Apollo.QueryHookOptions<Types.BuyerSearchQuery, Types.BuyerSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyerSearchQuery, Types.BuyerSearchQueryVariables>(BuyerSearchDocument, options);
      }
export function useBuyerSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyerSearchQuery, Types.BuyerSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyerSearchQuery, Types.BuyerSearchQueryVariables>(BuyerSearchDocument, options);
        }
export function useBuyerSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyerSearchQuery, Types.BuyerSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyerSearchQuery, Types.BuyerSearchQueryVariables>(BuyerSearchDocument, options);
        }
export type BuyerSearchQueryHookResult = ReturnType<typeof useBuyerSearchQuery>;
export type BuyerSearchLazyQueryHookResult = ReturnType<typeof useBuyerSearchLazyQuery>;
export type BuyerSearchSuspenseQueryHookResult = ReturnType<typeof useBuyerSearchSuspenseQuery>;
export type BuyerSearchQueryResult = Apollo.QueryResult<Types.BuyerSearchQuery, Types.BuyerSearchQueryVariables>;
export const NationalGiTypeGeoIndicationSearchDocument = gql`
    query nationalGITypeGeoIndicationSearch($nationalGITypeId: Int!, $localeId: Int!, $query: String) {
  nationalGIType(id: $nationalGITypeId) {
    id
    geoIndications(localeId: $localeId, query: $query) {
      id
      localizedName(localeId: $localeId)
      wineTerritoryType {
        localizedName(localeId: $localeId)
      }
      wineRegionsEU {
        id
        localizedName(localeId: $localeId)
      }
    }
  }
}
    `;

/**
 * __useNationalGiTypeGeoIndicationSearchQuery__
 *
 * To run a query within a React component, call `useNationalGiTypeGeoIndicationSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useNationalGiTypeGeoIndicationSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNationalGiTypeGeoIndicationSearchQuery({
 *   variables: {
 *      nationalGITypeId: // value for 'nationalGITypeId'
 *      localeId: // value for 'localeId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useNationalGiTypeGeoIndicationSearchQuery(baseOptions: Apollo.QueryHookOptions<Types.NationalGiTypeGeoIndicationSearchQuery, Types.NationalGiTypeGeoIndicationSearchQueryVariables> & ({ variables: Types.NationalGiTypeGeoIndicationSearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NationalGiTypeGeoIndicationSearchQuery, Types.NationalGiTypeGeoIndicationSearchQueryVariables>(NationalGiTypeGeoIndicationSearchDocument, options);
      }
export function useNationalGiTypeGeoIndicationSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NationalGiTypeGeoIndicationSearchQuery, Types.NationalGiTypeGeoIndicationSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NationalGiTypeGeoIndicationSearchQuery, Types.NationalGiTypeGeoIndicationSearchQueryVariables>(NationalGiTypeGeoIndicationSearchDocument, options);
        }
export function useNationalGiTypeGeoIndicationSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.NationalGiTypeGeoIndicationSearchQuery, Types.NationalGiTypeGeoIndicationSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.NationalGiTypeGeoIndicationSearchQuery, Types.NationalGiTypeGeoIndicationSearchQueryVariables>(NationalGiTypeGeoIndicationSearchDocument, options);
        }
export type NationalGiTypeGeoIndicationSearchQueryHookResult = ReturnType<typeof useNationalGiTypeGeoIndicationSearchQuery>;
export type NationalGiTypeGeoIndicationSearchLazyQueryHookResult = ReturnType<typeof useNationalGiTypeGeoIndicationSearchLazyQuery>;
export type NationalGiTypeGeoIndicationSearchSuspenseQueryHookResult = ReturnType<typeof useNationalGiTypeGeoIndicationSearchSuspenseQuery>;
export type NationalGiTypeGeoIndicationSearchQueryResult = Apollo.QueryResult<Types.NationalGiTypeGeoIndicationSearchQuery, Types.NationalGiTypeGeoIndicationSearchQueryVariables>;
export const SpecialClassificationsForRequestCreateDocument = gql`
    query specialClassificationsForRequestCreate($wineTypeId: Int, $wineColorId: Int, $sweetnessLevelId: Int, $nationalGITypeId: Int, $geoIndicationId: [Int], $tradeNameId: ID) {
  specialClassifications(
    filter: {wineTypeId: $wineTypeId, wineColorId: $wineColorId, sweetnessLevelId: $sweetnessLevelId, nationalGITypeId: $nationalGITypeId, geoIndicationId: $geoIndicationId, tradeNameId: $tradeNameId}
  ) {
    id
    name
  }
}
    `;

/**
 * __useSpecialClassificationsForRequestCreateQuery__
 *
 * To run a query within a React component, call `useSpecialClassificationsForRequestCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecialClassificationsForRequestCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecialClassificationsForRequestCreateQuery({
 *   variables: {
 *      wineTypeId: // value for 'wineTypeId'
 *      wineColorId: // value for 'wineColorId'
 *      sweetnessLevelId: // value for 'sweetnessLevelId'
 *      nationalGITypeId: // value for 'nationalGITypeId'
 *      geoIndicationId: // value for 'geoIndicationId'
 *      tradeNameId: // value for 'tradeNameId'
 *   },
 * });
 */
export function useSpecialClassificationsForRequestCreateQuery(baseOptions?: Apollo.QueryHookOptions<Types.SpecialClassificationsForRequestCreateQuery, Types.SpecialClassificationsForRequestCreateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SpecialClassificationsForRequestCreateQuery, Types.SpecialClassificationsForRequestCreateQueryVariables>(SpecialClassificationsForRequestCreateDocument, options);
      }
export function useSpecialClassificationsForRequestCreateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SpecialClassificationsForRequestCreateQuery, Types.SpecialClassificationsForRequestCreateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SpecialClassificationsForRequestCreateQuery, Types.SpecialClassificationsForRequestCreateQueryVariables>(SpecialClassificationsForRequestCreateDocument, options);
        }
export function useSpecialClassificationsForRequestCreateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SpecialClassificationsForRequestCreateQuery, Types.SpecialClassificationsForRequestCreateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SpecialClassificationsForRequestCreateQuery, Types.SpecialClassificationsForRequestCreateQueryVariables>(SpecialClassificationsForRequestCreateDocument, options);
        }
export type SpecialClassificationsForRequestCreateQueryHookResult = ReturnType<typeof useSpecialClassificationsForRequestCreateQuery>;
export type SpecialClassificationsForRequestCreateLazyQueryHookResult = ReturnType<typeof useSpecialClassificationsForRequestCreateLazyQuery>;
export type SpecialClassificationsForRequestCreateSuspenseQueryHookResult = ReturnType<typeof useSpecialClassificationsForRequestCreateSuspenseQuery>;
export type SpecialClassificationsForRequestCreateQueryResult = Apollo.QueryResult<Types.SpecialClassificationsForRequestCreateQuery, Types.SpecialClassificationsForRequestCreateQueryVariables>;
export const WineOfferRequestDraftCreateDocument = gql`
    mutation wineOfferRequestDraftCreate($input: WineOfferRequestDraftCreateInput!) {
  wineOfferRequestDraftCreate(input: $input) {
    request {
      id
    }
  }
}
    `;
export type WineOfferRequestDraftCreateMutationFn = Apollo.MutationFunction<Types.WineOfferRequestDraftCreateMutation, Types.WineOfferRequestDraftCreateMutationVariables>;

/**
 * __useWineOfferRequestDraftCreateMutation__
 *
 * To run a mutation, you first call `useWineOfferRequestDraftCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestDraftCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOfferRequestDraftCreateMutation, { data, loading, error }] = useWineOfferRequestDraftCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOfferRequestDraftCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOfferRequestDraftCreateMutation, Types.WineOfferRequestDraftCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOfferRequestDraftCreateMutation, Types.WineOfferRequestDraftCreateMutationVariables>(WineOfferRequestDraftCreateDocument, options);
      }
export type WineOfferRequestDraftCreateMutationHookResult = ReturnType<typeof useWineOfferRequestDraftCreateMutation>;
export type WineOfferRequestDraftCreateMutationResult = Apollo.MutationResult<Types.WineOfferRequestDraftCreateMutation>;
export type WineOfferRequestDraftCreateMutationOptions = Apollo.BaseMutationOptions<Types.WineOfferRequestDraftCreateMutation, Types.WineOfferRequestDraftCreateMutationVariables>;
export const WineOfferRequestDraftPublishDocument = gql`
    mutation wineOfferRequestDraftPublish($input: WineOfferRequestDraftPublishInput!) {
  wineOfferRequestDraftPublish(input: $input) {
    request {
      id
    }
  }
}
    `;
export type WineOfferRequestDraftPublishMutationFn = Apollo.MutationFunction<Types.WineOfferRequestDraftPublishMutation, Types.WineOfferRequestDraftPublishMutationVariables>;

/**
 * __useWineOfferRequestDraftPublishMutation__
 *
 * To run a mutation, you first call `useWineOfferRequestDraftPublishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestDraftPublishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOfferRequestDraftPublishMutation, { data, loading, error }] = useWineOfferRequestDraftPublishMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOfferRequestDraftPublishMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOfferRequestDraftPublishMutation, Types.WineOfferRequestDraftPublishMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOfferRequestDraftPublishMutation, Types.WineOfferRequestDraftPublishMutationVariables>(WineOfferRequestDraftPublishDocument, options);
      }
export type WineOfferRequestDraftPublishMutationHookResult = ReturnType<typeof useWineOfferRequestDraftPublishMutation>;
export type WineOfferRequestDraftPublishMutationResult = Apollo.MutationResult<Types.WineOfferRequestDraftPublishMutation>;
export type WineOfferRequestDraftPublishMutationOptions = Apollo.BaseMutationOptions<Types.WineOfferRequestDraftPublishMutation, Types.WineOfferRequestDraftPublishMutationVariables>;
export const WineOfferRequestDraftUpdateDocument = gql`
    mutation wineOfferRequestDraftUpdate($input: WineOfferRequestDraftUpdateInput!) {
  wineOfferRequestDraftUpdate(input: $input) {
    request {
      id
    }
  }
}
    `;
export type WineOfferRequestDraftUpdateMutationFn = Apollo.MutationFunction<Types.WineOfferRequestDraftUpdateMutation, Types.WineOfferRequestDraftUpdateMutationVariables>;

/**
 * __useWineOfferRequestDraftUpdateMutation__
 *
 * To run a mutation, you first call `useWineOfferRequestDraftUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestDraftUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOfferRequestDraftUpdateMutation, { data, loading, error }] = useWineOfferRequestDraftUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOfferRequestDraftUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOfferRequestDraftUpdateMutation, Types.WineOfferRequestDraftUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOfferRequestDraftUpdateMutation, Types.WineOfferRequestDraftUpdateMutationVariables>(WineOfferRequestDraftUpdateDocument, options);
      }
export type WineOfferRequestDraftUpdateMutationHookResult = ReturnType<typeof useWineOfferRequestDraftUpdateMutation>;
export type WineOfferRequestDraftUpdateMutationResult = Apollo.MutationResult<Types.WineOfferRequestDraftUpdateMutation>;
export type WineOfferRequestDraftUpdateMutationOptions = Apollo.BaseMutationOptions<Types.WineOfferRequestDraftUpdateMutation, Types.WineOfferRequestDraftUpdateMutationVariables>;
export const WineOfferRequestFiltersParseDocument = gql`
    query wineOfferRequestFiltersParse($input: WineOfferRequestFiltersParseInput!, $localeId: Int!) {
  wineOfferRequestFiltersParse(input: $input) {
    tradeName {
      id
      tradeName
    }
    wineType {
      id
      localizedName(localeId: $localeId)
    }
    brand {
      id
      name
    }
    tradeNameCountry {
      id
      localizedName(localeId: $localeId)
      codeISO
    }
    countryOfShipment {
      id
      localizedName(localeId: $localeId)
      codeISO
    }
    wineCountry {
      id
      localizedName(localeId: $localeId)
      codeISO
    }
    wwlScore
    wineColor {
      id
      localizedName(localeId: $localeId)
    }
    vintage
    sweetnessLevel {
      id
      localizedName(localeId: $localeId)
    }
    specialClassification {
      id
      name
    }
    priceCurrency {
      code
      id
    }
    geoIndication {
      id
      localizedName(localeId: $localeId)
    }
    packageType {
      id
      localizedName(localeId: $localeId)
    }
    onlyFromProducer
    onlyForMe
    nationalGIType {
      localizedShortName(localeId: $localeId)
      id
    }
    monocepage
    minimumDeliverySize
    minPrice
    maxPrice
    minAlcohol
    maxAlcohol
    incoterms {
      id
      code
      localizedShortDescription(localeId: $localeId)
    }
    grapeVarietySynonym {
      id
      name
    }
    exhibition {
      id
      name
    }
    exclusive
    certification {
      id
      localizedName(localeId: $localeId)
    }
    bottleSize {
      id
      volumeML
    }
  }
}
    `;

/**
 * __useWineOfferRequestFiltersParseQuery__
 *
 * To run a query within a React component, call `useWineOfferRequestFiltersParseQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestFiltersParseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineOfferRequestFiltersParseQuery({
 *   variables: {
 *      input: // value for 'input'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useWineOfferRequestFiltersParseQuery(baseOptions: Apollo.QueryHookOptions<Types.WineOfferRequestFiltersParseQuery, Types.WineOfferRequestFiltersParseQueryVariables> & ({ variables: Types.WineOfferRequestFiltersParseQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineOfferRequestFiltersParseQuery, Types.WineOfferRequestFiltersParseQueryVariables>(WineOfferRequestFiltersParseDocument, options);
      }
export function useWineOfferRequestFiltersParseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineOfferRequestFiltersParseQuery, Types.WineOfferRequestFiltersParseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineOfferRequestFiltersParseQuery, Types.WineOfferRequestFiltersParseQueryVariables>(WineOfferRequestFiltersParseDocument, options);
        }
export function useWineOfferRequestFiltersParseSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineOfferRequestFiltersParseQuery, Types.WineOfferRequestFiltersParseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineOfferRequestFiltersParseQuery, Types.WineOfferRequestFiltersParseQueryVariables>(WineOfferRequestFiltersParseDocument, options);
        }
export type WineOfferRequestFiltersParseQueryHookResult = ReturnType<typeof useWineOfferRequestFiltersParseQuery>;
export type WineOfferRequestFiltersParseLazyQueryHookResult = ReturnType<typeof useWineOfferRequestFiltersParseLazyQuery>;
export type WineOfferRequestFiltersParseSuspenseQueryHookResult = ReturnType<typeof useWineOfferRequestFiltersParseSuspenseQuery>;
export type WineOfferRequestFiltersParseQueryResult = Apollo.QueryResult<Types.WineOfferRequestFiltersParseQuery, Types.WineOfferRequestFiltersParseQueryVariables>;
export const CompanyForRequestViewDocument = gql`
    query companyForRequestView($id: ID!, $localeId: Int!) {
  me {
    id
    company(id: $id) {
      id
      isVerifiedUser
      country {
        id
        localizedName(localeId: $localeId)
      }
      companyCategory {
        id
        localizedName(localeId: $localeId)
      }
      tradeName
      website
      postCode
      postAddress
      email
      phone
      avatar {
        imageUrl
      }
    }
  }
}
    `;

/**
 * __useCompanyForRequestViewQuery__
 *
 * To run a query within a React component, call `useCompanyForRequestViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyForRequestViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyForRequestViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useCompanyForRequestViewQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyForRequestViewQuery, Types.CompanyForRequestViewQueryVariables> & ({ variables: Types.CompanyForRequestViewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyForRequestViewQuery, Types.CompanyForRequestViewQueryVariables>(CompanyForRequestViewDocument, options);
      }
export function useCompanyForRequestViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyForRequestViewQuery, Types.CompanyForRequestViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyForRequestViewQuery, Types.CompanyForRequestViewQueryVariables>(CompanyForRequestViewDocument, options);
        }
export function useCompanyForRequestViewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CompanyForRequestViewQuery, Types.CompanyForRequestViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompanyForRequestViewQuery, Types.CompanyForRequestViewQueryVariables>(CompanyForRequestViewDocument, options);
        }
export type CompanyForRequestViewQueryHookResult = ReturnType<typeof useCompanyForRequestViewQuery>;
export type CompanyForRequestViewLazyQueryHookResult = ReturnType<typeof useCompanyForRequestViewLazyQuery>;
export type CompanyForRequestViewSuspenseQueryHookResult = ReturnType<typeof useCompanyForRequestViewSuspenseQuery>;
export type CompanyForRequestViewQueryResult = Apollo.QueryResult<Types.CompanyForRequestViewQuery, Types.CompanyForRequestViewQueryVariables>;
export const CompanyChangeBuyCurrencyDocument = gql`
    mutation companyChangeBuyCurrency($input: CompanyChangeBuyCurrencyInput!) {
  companyChangeBuyCurrency(input: $input) {
    company {
      id
      buyCurrency {
        id
        code
      }
    }
  }
}
    `;
export type CompanyChangeBuyCurrencyMutationFn = Apollo.MutationFunction<Types.CompanyChangeBuyCurrencyMutation, Types.CompanyChangeBuyCurrencyMutationVariables>;

/**
 * __useCompanyChangeBuyCurrencyMutation__
 *
 * To run a mutation, you first call `useCompanyChangeBuyCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyChangeBuyCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyChangeBuyCurrencyMutation, { data, loading, error }] = useCompanyChangeBuyCurrencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyChangeBuyCurrencyMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompanyChangeBuyCurrencyMutation, Types.CompanyChangeBuyCurrencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompanyChangeBuyCurrencyMutation, Types.CompanyChangeBuyCurrencyMutationVariables>(CompanyChangeBuyCurrencyDocument, options);
      }
export type CompanyChangeBuyCurrencyMutationHookResult = ReturnType<typeof useCompanyChangeBuyCurrencyMutation>;
export type CompanyChangeBuyCurrencyMutationResult = Apollo.MutationResult<Types.CompanyChangeBuyCurrencyMutation>;
export type CompanyChangeBuyCurrencyMutationOptions = Apollo.BaseMutationOptions<Types.CompanyChangeBuyCurrencyMutation, Types.CompanyChangeBuyCurrencyMutationVariables>;
export const MyCompanyDocument = gql`
    query myCompany($companyId: ID!) {
  me {
    company(id: $companyId) {
      buyCurrency {
        id
        code
      }
    }
  }
}
    `;

/**
 * __useMyCompanyQuery__
 *
 * To run a query within a React component, call `useMyCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMyCompanyQuery(baseOptions: Apollo.QueryHookOptions<Types.MyCompanyQuery, Types.MyCompanyQueryVariables> & ({ variables: Types.MyCompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MyCompanyQuery, Types.MyCompanyQueryVariables>(MyCompanyDocument, options);
      }
export function useMyCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MyCompanyQuery, Types.MyCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MyCompanyQuery, Types.MyCompanyQueryVariables>(MyCompanyDocument, options);
        }
export function useMyCompanySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MyCompanyQuery, Types.MyCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MyCompanyQuery, Types.MyCompanyQueryVariables>(MyCompanyDocument, options);
        }
export type MyCompanyQueryHookResult = ReturnType<typeof useMyCompanyQuery>;
export type MyCompanyLazyQueryHookResult = ReturnType<typeof useMyCompanyLazyQuery>;
export type MyCompanySuspenseQueryHookResult = ReturnType<typeof useMyCompanySuspenseQuery>;
export type MyCompanyQueryResult = Apollo.QueryResult<Types.MyCompanyQuery, Types.MyCompanyQueryVariables>;
export const WineOfferRequestProductFiltersParseDocument = gql`
    query wineOfferRequestProductFiltersParse($input: WineOfferRequestProductFiltersParseInput!, $localeId: Int!) {
  wineOfferRequestProductFiltersParse(input: $input) {
    tradeName {
      id
      tradeName
    }
    wineType {
      id
      localizedName(localeId: $localeId)
    }
    brand {
      id
      name
    }
    tradeNameCountry {
      id
      localizedName(localeId: $localeId)
      codeISO
    }
    countryOfShipment {
      id
      localizedName(localeId: $localeId)
      codeISO
    }
    wineCountry {
      id
      localizedName(localeId: $localeId)
      codeISO
    }
    wwlScore
    wineColor {
      id
      localizedName(localeId: $localeId)
    }
    vintage
    sweetnessLevel {
      id
      localizedName(localeId: $localeId)
    }
    specialClassification {
      id
      name
    }
    priceCurrency {
      code
      id
    }
    geoIndication {
      id
      localizedName(localeId: $localeId)
    }
    packageType {
      id
      localizedName(localeId: $localeId)
    }
    onlyFromProducer
    onlyForMe
    nationalGIType {
      localizedShortName(localeId: $localeId)
      id
    }
    monocepage
    minimumDeliverySize
    minPrice
    maxPrice
    minAlcohol
    maxAlcohol
    incoterms {
      id
      code
      localizedShortDescription(localeId: $localeId)
    }
    grapeVarietySynonym {
      id
      name
    }
    exhibition {
      id
      name
    }
    exclusive
    certification {
      id
      localizedName(localeId: $localeId)
    }
    bottleSize {
      id
      volumeML
    }
  }
}
    `;

/**
 * __useWineOfferRequestProductFiltersParseQuery__
 *
 * To run a query within a React component, call `useWineOfferRequestProductFiltersParseQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineOfferRequestProductFiltersParseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineOfferRequestProductFiltersParseQuery({
 *   variables: {
 *      input: // value for 'input'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useWineOfferRequestProductFiltersParseQuery(baseOptions: Apollo.QueryHookOptions<Types.WineOfferRequestProductFiltersParseQuery, Types.WineOfferRequestProductFiltersParseQueryVariables> & ({ variables: Types.WineOfferRequestProductFiltersParseQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineOfferRequestProductFiltersParseQuery, Types.WineOfferRequestProductFiltersParseQueryVariables>(WineOfferRequestProductFiltersParseDocument, options);
      }
export function useWineOfferRequestProductFiltersParseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineOfferRequestProductFiltersParseQuery, Types.WineOfferRequestProductFiltersParseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineOfferRequestProductFiltersParseQuery, Types.WineOfferRequestProductFiltersParseQueryVariables>(WineOfferRequestProductFiltersParseDocument, options);
        }
export function useWineOfferRequestProductFiltersParseSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineOfferRequestProductFiltersParseQuery, Types.WineOfferRequestProductFiltersParseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineOfferRequestProductFiltersParseQuery, Types.WineOfferRequestProductFiltersParseQueryVariables>(WineOfferRequestProductFiltersParseDocument, options);
        }
export type WineOfferRequestProductFiltersParseQueryHookResult = ReturnType<typeof useWineOfferRequestProductFiltersParseQuery>;
export type WineOfferRequestProductFiltersParseLazyQueryHookResult = ReturnType<typeof useWineOfferRequestProductFiltersParseLazyQuery>;
export type WineOfferRequestProductFiltersParseSuspenseQueryHookResult = ReturnType<typeof useWineOfferRequestProductFiltersParseSuspenseQuery>;
export type WineOfferRequestProductFiltersParseQueryResult = Apollo.QueryResult<Types.WineOfferRequestProductFiltersParseQuery, Types.WineOfferRequestProductFiltersParseQueryVariables>;
export const CountriesForCompanyCreateDocument = gql`
    query countriesForCompanyCreate($localeId: Int!) {
  countries {
    id
    masterName
    codeISO
    localizedName(localeId: $localeId)
    legalEntityDiscriminator
    vatName
    vatFormat
    vatMask
    localizedVATTooltip(localeId: $localeId)
    localizedVATFormatError(localeId: $localeId)
    identityNumberName
    identityNumberFormat
    tinMask
    localizedIdentityNumberTooltip(localeId: $localeId)
    localizedIdentityNumberFormatError(localeId: $localeId)
    localizedPostCodeTooltip(localeId: $localeId)
    localizedPostCodeFormatError(localeId: $localeId)
    postCodeName
    postCodeMask
    postCodeFormat
    branchNumberName
    branchNumberFormat
    localizedBranchNumberFormatError(localeId: $localeId)
    localizedBranchNumberTooltip(localeId: $localeId)
  }
}
    `;

/**
 * __useCountriesForCompanyCreateQuery__
 *
 * To run a query within a React component, call `useCountriesForCompanyCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesForCompanyCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesForCompanyCreateQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useCountriesForCompanyCreateQuery(baseOptions: Apollo.QueryHookOptions<Types.CountriesForCompanyCreateQuery, Types.CountriesForCompanyCreateQueryVariables> & ({ variables: Types.CountriesForCompanyCreateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountriesForCompanyCreateQuery, Types.CountriesForCompanyCreateQueryVariables>(CountriesForCompanyCreateDocument, options);
      }
export function useCountriesForCompanyCreateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountriesForCompanyCreateQuery, Types.CountriesForCompanyCreateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountriesForCompanyCreateQuery, Types.CountriesForCompanyCreateQueryVariables>(CountriesForCompanyCreateDocument, options);
        }
export function useCountriesForCompanyCreateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CountriesForCompanyCreateQuery, Types.CountriesForCompanyCreateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CountriesForCompanyCreateQuery, Types.CountriesForCompanyCreateQueryVariables>(CountriesForCompanyCreateDocument, options);
        }
export type CountriesForCompanyCreateQueryHookResult = ReturnType<typeof useCountriesForCompanyCreateQuery>;
export type CountriesForCompanyCreateLazyQueryHookResult = ReturnType<typeof useCountriesForCompanyCreateLazyQuery>;
export type CountriesForCompanyCreateSuspenseQueryHookResult = ReturnType<typeof useCountriesForCompanyCreateSuspenseQuery>;
export type CountriesForCompanyCreateQueryResult = Apollo.QueryResult<Types.CountriesForCompanyCreateQuery, Types.CountriesForCompanyCreateQueryVariables>;
export const LegalEntitiesAndBranchesDocument = gql`
    query legalEntitiesAndBranches($countryId: Int!, $identityNumber: String, $vat: String, $localeId: Int!, $fetchOfficialTradeNames: Boolean!) {
  legalEntitiesAndBranches(
    countryId: $countryId
    identityNumber: $identityNumber
    vat: $vat
  ) {
    records {
      id
      country {
        id
        identityNumberName
        branchNumberName
      }
      identityNumber
      branchNumber
      legalEntityForm {
        id
        shortName
        originName
      }
      companyCategory {
        id
        masterName
        localizedName(localeId: $localeId)
      }
      legalName
      legalAddress
      activityCode
      isActive
      isOfficialRegistry
      isHeadOffice
      closingDate
      vat
      canEdit
      officialTradeNames @include(if: $fetchOfficialTradeNames) {
        id
        tradeName
        country {
          id
          codeISO
          localizedName(localeId: $localeId)
        }
        email
        phone
        website
        postCountry {
          id
        }
        postAddress
        postCode
        latitude
        longitude
        logo {
          imageUrl
          thumbnailUrl: imageUrl(transformations: {width: 80, height: 80, resize: fit})
          thumbnailRetinaUrl: imageUrl(
            transformations: {width: 160, height: 160, resize: fit}
          )
        }
        writePolicy {
          postCountry
          postCode
          postAddress
          latitude
          longitude
          phone
          website
          email
          logo
        }
      }
    }
  }
}
    `;

/**
 * __useLegalEntitiesAndBranchesQuery__
 *
 * To run a query within a React component, call `useLegalEntitiesAndBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegalEntitiesAndBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegalEntitiesAndBranchesQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *      identityNumber: // value for 'identityNumber'
 *      vat: // value for 'vat'
 *      localeId: // value for 'localeId'
 *      fetchOfficialTradeNames: // value for 'fetchOfficialTradeNames'
 *   },
 * });
 */
export function useLegalEntitiesAndBranchesQuery(baseOptions: Apollo.QueryHookOptions<Types.LegalEntitiesAndBranchesQuery, Types.LegalEntitiesAndBranchesQueryVariables> & ({ variables: Types.LegalEntitiesAndBranchesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LegalEntitiesAndBranchesQuery, Types.LegalEntitiesAndBranchesQueryVariables>(LegalEntitiesAndBranchesDocument, options);
      }
export function useLegalEntitiesAndBranchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LegalEntitiesAndBranchesQuery, Types.LegalEntitiesAndBranchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LegalEntitiesAndBranchesQuery, Types.LegalEntitiesAndBranchesQueryVariables>(LegalEntitiesAndBranchesDocument, options);
        }
export function useLegalEntitiesAndBranchesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LegalEntitiesAndBranchesQuery, Types.LegalEntitiesAndBranchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LegalEntitiesAndBranchesQuery, Types.LegalEntitiesAndBranchesQueryVariables>(LegalEntitiesAndBranchesDocument, options);
        }
export type LegalEntitiesAndBranchesQueryHookResult = ReturnType<typeof useLegalEntitiesAndBranchesQuery>;
export type LegalEntitiesAndBranchesLazyQueryHookResult = ReturnType<typeof useLegalEntitiesAndBranchesLazyQuery>;
export type LegalEntitiesAndBranchesSuspenseQueryHookResult = ReturnType<typeof useLegalEntitiesAndBranchesSuspenseQuery>;
export type LegalEntitiesAndBranchesQueryResult = Apollo.QueryResult<Types.LegalEntitiesAndBranchesQuery, Types.LegalEntitiesAndBranchesQueryVariables>;
export const TradeNameByCompositeIdForCreateDocument = gql`
    query tradeNameByCompositeIdForCreate($compositeId: TradeNameCompositeIDInput!) {
  tradeNameByCompositeId(compositeId: $compositeId) {
    id
    email
    phone
    website
    postAddress
    postCode
    logo {
      imageUrl
      thumbnailUrl: imageUrl(transformations: {width: 80, height: 80, resize: fit})
      thumbnailRetinaUrl: imageUrl(
        transformations: {width: 160, height: 160, resize: fit}
      )
    }
    postCountry {
      id
    }
    latitude
    longitude
    country {
      id
      codeISO
    }
    writePolicy {
      postCountry
      postCode
      postAddress
      latitude
      longitude
      phone
      website
      email
      logo
    }
  }
}
    `;

/**
 * __useTradeNameByCompositeIdForCreateQuery__
 *
 * To run a query within a React component, call `useTradeNameByCompositeIdForCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeNameByCompositeIdForCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeNameByCompositeIdForCreateQuery({
 *   variables: {
 *      compositeId: // value for 'compositeId'
 *   },
 * });
 */
export function useTradeNameByCompositeIdForCreateQuery(baseOptions: Apollo.QueryHookOptions<Types.TradeNameByCompositeIdForCreateQuery, Types.TradeNameByCompositeIdForCreateQueryVariables> & ({ variables: Types.TradeNameByCompositeIdForCreateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradeNameByCompositeIdForCreateQuery, Types.TradeNameByCompositeIdForCreateQueryVariables>(TradeNameByCompositeIdForCreateDocument, options);
      }
export function useTradeNameByCompositeIdForCreateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradeNameByCompositeIdForCreateQuery, Types.TradeNameByCompositeIdForCreateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradeNameByCompositeIdForCreateQuery, Types.TradeNameByCompositeIdForCreateQueryVariables>(TradeNameByCompositeIdForCreateDocument, options);
        }
export function useTradeNameByCompositeIdForCreateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradeNameByCompositeIdForCreateQuery, Types.TradeNameByCompositeIdForCreateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradeNameByCompositeIdForCreateQuery, Types.TradeNameByCompositeIdForCreateQueryVariables>(TradeNameByCompositeIdForCreateDocument, options);
        }
export type TradeNameByCompositeIdForCreateQueryHookResult = ReturnType<typeof useTradeNameByCompositeIdForCreateQuery>;
export type TradeNameByCompositeIdForCreateLazyQueryHookResult = ReturnType<typeof useTradeNameByCompositeIdForCreateLazyQuery>;
export type TradeNameByCompositeIdForCreateSuspenseQueryHookResult = ReturnType<typeof useTradeNameByCompositeIdForCreateSuspenseQuery>;
export type TradeNameByCompositeIdForCreateQueryResult = Apollo.QueryResult<Types.TradeNameByCompositeIdForCreateQuery, Types.TradeNameByCompositeIdForCreateQueryVariables>;
export const CompanyCreateDocument = gql`
    mutation companyCreate($input: CompanyCreateInput!) {
  companyCreate(input: $input) {
    company {
      id
      tradeName
      companyCategory {
        id
        masterName
      }
      isVerifiedUser
    }
  }
}
    `;
export type CompanyCreateMutationFn = Apollo.MutationFunction<Types.CompanyCreateMutation, Types.CompanyCreateMutationVariables>;

/**
 * __useCompanyCreateMutation__
 *
 * To run a mutation, you first call `useCompanyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyCreateMutation, { data, loading, error }] = useCompanyCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompanyCreateMutation, Types.CompanyCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompanyCreateMutation, Types.CompanyCreateMutationVariables>(CompanyCreateDocument, options);
      }
export type CompanyCreateMutationHookResult = ReturnType<typeof useCompanyCreateMutation>;
export type CompanyCreateMutationResult = Apollo.MutationResult<Types.CompanyCreateMutation>;
export type CompanyCreateMutationOptions = Apollo.BaseMutationOptions<Types.CompanyCreateMutation, Types.CompanyCreateMutationVariables>;
export const ChangeEmailDocument = gql`
    mutation changeEmail($token: String!) {
  success: changeEmail(changeEmailToken: $token)
}
    `;
export type ChangeEmailMutationFn = Apollo.MutationFunction<Types.ChangeEmailMutation, Types.ChangeEmailMutationVariables>;

/**
 * __useChangeEmailMutation__
 *
 * To run a mutation, you first call `useChangeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmailMutation, { data, loading, error }] = useChangeEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useChangeEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangeEmailMutation, Types.ChangeEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ChangeEmailMutation, Types.ChangeEmailMutationVariables>(ChangeEmailDocument, options);
      }
export type ChangeEmailMutationHookResult = ReturnType<typeof useChangeEmailMutation>;
export type ChangeEmailMutationResult = Apollo.MutationResult<Types.ChangeEmailMutation>;
export type ChangeEmailMutationOptions = Apollo.BaseMutationOptions<Types.ChangeEmailMutation, Types.ChangeEmailMutationVariables>;
export const ConfirmMailDocument = gql`
    mutation confirmMail($token: String!) {
  confirmMail(confirmToken: $token) {
    token
    refreshToken
  }
}
    `;
export type ConfirmMailMutationFn = Apollo.MutationFunction<Types.ConfirmMailMutation, Types.ConfirmMailMutationVariables>;

/**
 * __useConfirmMailMutation__
 *
 * To run a mutation, you first call `useConfirmMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmMailMutation, { data, loading, error }] = useConfirmMailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmMailMutation(baseOptions?: Apollo.MutationHookOptions<Types.ConfirmMailMutation, Types.ConfirmMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ConfirmMailMutation, Types.ConfirmMailMutationVariables>(ConfirmMailDocument, options);
      }
export type ConfirmMailMutationHookResult = ReturnType<typeof useConfirmMailMutation>;
export type ConfirmMailMutationResult = Apollo.MutationResult<Types.ConfirmMailMutation>;
export type ConfirmMailMutationOptions = Apollo.BaseMutationOptions<Types.ConfirmMailMutation, Types.ConfirmMailMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($resetToken: String!, $newPassword: String!) {
  resetPassword(resetToken: $resetToken, newPassword: $newPassword)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      resetToken: // value for 'resetToken'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<Types.ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>;
export const AwardRewardsDeleteDocument = gql`
    mutation awardRewardsDelete($input: AwardRewardsDeleteInput!) {
  awardRewardsDelete(input: $input) {
    recordIds
  }
}
    `;
export type AwardRewardsDeleteMutationFn = Apollo.MutationFunction<Types.AwardRewardsDeleteMutation, Types.AwardRewardsDeleteMutationVariables>;

/**
 * __useAwardRewardsDeleteMutation__
 *
 * To run a mutation, you first call `useAwardRewardsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAwardRewardsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [awardRewardsDeleteMutation, { data, loading, error }] = useAwardRewardsDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAwardRewardsDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.AwardRewardsDeleteMutation, Types.AwardRewardsDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AwardRewardsDeleteMutation, Types.AwardRewardsDeleteMutationVariables>(AwardRewardsDeleteDocument, options);
      }
export type AwardRewardsDeleteMutationHookResult = ReturnType<typeof useAwardRewardsDeleteMutation>;
export type AwardRewardsDeleteMutationResult = Apollo.MutationResult<Types.AwardRewardsDeleteMutation>;
export type AwardRewardsDeleteMutationOptions = Apollo.BaseMutationOptions<Types.AwardRewardsDeleteMutation, Types.AwardRewardsDeleteMutationVariables>;
export const AwardRewardsPaginationDocument = gql`
    query awardRewardsPagination($awardName: String!, $localeId: Int! = 1, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: AwardRewardSort) {
  award: awardByName(name: $awardName) {
    id
    masterName
    rewardsPagination(
      localeId: $localeId
      page: $page
      perPage: $perPage
      sort: $sort
    ) {
      pageInfo {
        totalItems
        totalPages
        hasNextPage
        hasPreviousPage
        page
        perPage
      }
      items {
        id
        rewardType {
          id
          masterName
        }
        shortName
        name
        minYear
        maxYear
        minScore
        maxScore
        scoreLevel {
          id
          score
        }
        updatedAt
        authorUser {
          id
          username
        }
        isReferenced
      }
    }
  }
}
    `;

/**
 * __useAwardRewardsPaginationQuery__
 *
 * To run a query within a React component, call `useAwardRewardsPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAwardRewardsPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAwardRewardsPaginationQuery({
 *   variables: {
 *      awardName: // value for 'awardName'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAwardRewardsPaginationQuery(baseOptions: Apollo.QueryHookOptions<Types.AwardRewardsPaginationQuery, Types.AwardRewardsPaginationQueryVariables> & ({ variables: Types.AwardRewardsPaginationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AwardRewardsPaginationQuery, Types.AwardRewardsPaginationQueryVariables>(AwardRewardsPaginationDocument, options);
      }
export function useAwardRewardsPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AwardRewardsPaginationQuery, Types.AwardRewardsPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AwardRewardsPaginationQuery, Types.AwardRewardsPaginationQueryVariables>(AwardRewardsPaginationDocument, options);
        }
export function useAwardRewardsPaginationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AwardRewardsPaginationQuery, Types.AwardRewardsPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AwardRewardsPaginationQuery, Types.AwardRewardsPaginationQueryVariables>(AwardRewardsPaginationDocument, options);
        }
export type AwardRewardsPaginationQueryHookResult = ReturnType<typeof useAwardRewardsPaginationQuery>;
export type AwardRewardsPaginationLazyQueryHookResult = ReturnType<typeof useAwardRewardsPaginationLazyQuery>;
export type AwardRewardsPaginationSuspenseQueryHookResult = ReturnType<typeof useAwardRewardsPaginationSuspenseQuery>;
export type AwardRewardsPaginationQueryResult = Apollo.QueryResult<Types.AwardRewardsPaginationQuery, Types.AwardRewardsPaginationQueryVariables>;
export const AwardsDeleteDocument = gql`
    mutation awardsDelete($input: AwardsDeleteInput!) {
  awardsDelete(input: $input) {
    recordIds
  }
}
    `;
export type AwardsDeleteMutationFn = Apollo.MutationFunction<Types.AwardsDeleteMutation, Types.AwardsDeleteMutationVariables>;

/**
 * __useAwardsDeleteMutation__
 *
 * To run a mutation, you first call `useAwardsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAwardsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [awardsDeleteMutation, { data, loading, error }] = useAwardsDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAwardsDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.AwardsDeleteMutation, Types.AwardsDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AwardsDeleteMutation, Types.AwardsDeleteMutationVariables>(AwardsDeleteDocument, options);
      }
export type AwardsDeleteMutationHookResult = ReturnType<typeof useAwardsDeleteMutation>;
export type AwardsDeleteMutationResult = Apollo.MutationResult<Types.AwardsDeleteMutation>;
export type AwardsDeleteMutationOptions = Apollo.BaseMutationOptions<Types.AwardsDeleteMutation, Types.AwardsDeleteMutationVariables>;
export const AwardsListDocument = gql`
    query awardsList($localeId: Int! = 1, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: AwardSort, $query: String) {
  awardsDatabasePagination(
    localeId: $localeId
    page: $page
    perPage: $perPage
    sort: $sort
    query: $query
  ) {
    pageInfo {
      totalItems
      totalPages
      hasNextPage
      hasPreviousPage
      page
      perPage
    }
    items {
      id
      masterName
      masterNameShort
      minScore
      maxScore
      roundScore
      stepScore
      level
      lowerLimit
      updatedAt
      authorUser {
        id
        username
      }
      isReferenced
    }
  }
}
    `;

/**
 * __useAwardsListQuery__
 *
 * To run a query within a React component, call `useAwardsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAwardsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAwardsListQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAwardsListQuery(baseOptions?: Apollo.QueryHookOptions<Types.AwardsListQuery, Types.AwardsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AwardsListQuery, Types.AwardsListQueryVariables>(AwardsListDocument, options);
      }
export function useAwardsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AwardsListQuery, Types.AwardsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AwardsListQuery, Types.AwardsListQueryVariables>(AwardsListDocument, options);
        }
export function useAwardsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AwardsListQuery, Types.AwardsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AwardsListQuery, Types.AwardsListQueryVariables>(AwardsListDocument, options);
        }
export type AwardsListQueryHookResult = ReturnType<typeof useAwardsListQuery>;
export type AwardsListLazyQueryHookResult = ReturnType<typeof useAwardsListLazyQuery>;
export type AwardsListSuspenseQueryHookResult = ReturnType<typeof useAwardsListSuspenseQuery>;
export type AwardsListQueryResult = Apollo.QueryResult<Types.AwardsListQuery, Types.AwardsListQueryVariables>;
export const BrandFactMoveDocument = gql`
    mutation brandFactMove($input: BrandFactMoveInput!) {
  brandFactMove(input: $input) {
    query {
      _
    }
    record {
      id
      name
    }
  }
}
    `;
export type BrandFactMoveMutationFn = Apollo.MutationFunction<Types.BrandFactMoveMutation, Types.BrandFactMoveMutationVariables>;

/**
 * __useBrandFactMoveMutation__
 *
 * To run a mutation, you first call `useBrandFactMoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrandFactMoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brandFactMoveMutation, { data, loading, error }] = useBrandFactMoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBrandFactMoveMutation(baseOptions?: Apollo.MutationHookOptions<Types.BrandFactMoveMutation, Types.BrandFactMoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BrandFactMoveMutation, Types.BrandFactMoveMutationVariables>(BrandFactMoveDocument, options);
      }
export type BrandFactMoveMutationHookResult = ReturnType<typeof useBrandFactMoveMutation>;
export type BrandFactMoveMutationResult = Apollo.MutationResult<Types.BrandFactMoveMutation>;
export type BrandFactMoveMutationOptions = Apollo.BaseMutationOptions<Types.BrandFactMoveMutation, Types.BrandFactMoveMutationVariables>;
export const MoveFactBrandsDocument = gql`
    query moveFactBrands($query: String, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: BrandSort!, $filter: BrandFilter) {
  brandsDatabasePagination(
    filter: $filter
    query: $query
    page: $page
    perPage: $perPage
    sort: $sort
  ) {
    items {
      id
      name
      tradeName {
        tradeName
      }
    }
  }
}
    `;

/**
 * __useMoveFactBrandsQuery__
 *
 * To run a query within a React component, call `useMoveFactBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoveFactBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoveFactBrandsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMoveFactBrandsQuery(baseOptions: Apollo.QueryHookOptions<Types.MoveFactBrandsQuery, Types.MoveFactBrandsQueryVariables> & ({ variables: Types.MoveFactBrandsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MoveFactBrandsQuery, Types.MoveFactBrandsQueryVariables>(MoveFactBrandsDocument, options);
      }
export function useMoveFactBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MoveFactBrandsQuery, Types.MoveFactBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MoveFactBrandsQuery, Types.MoveFactBrandsQueryVariables>(MoveFactBrandsDocument, options);
        }
export function useMoveFactBrandsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MoveFactBrandsQuery, Types.MoveFactBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MoveFactBrandsQuery, Types.MoveFactBrandsQueryVariables>(MoveFactBrandsDocument, options);
        }
export type MoveFactBrandsQueryHookResult = ReturnType<typeof useMoveFactBrandsQuery>;
export type MoveFactBrandsLazyQueryHookResult = ReturnType<typeof useMoveFactBrandsLazyQuery>;
export type MoveFactBrandsSuspenseQueryHookResult = ReturnType<typeof useMoveFactBrandsSuspenseQuery>;
export type MoveFactBrandsQueryResult = Apollo.QueryResult<Types.MoveFactBrandsQuery, Types.MoveFactBrandsQueryVariables>;
export const ExhibitionsDeleteDocument = gql`
    mutation exhibitionsDelete($input: ExhibitionsDeleteInput!) {
  exhibitionsDelete(input: $input) {
    recordIds
  }
}
    `;
export type ExhibitionsDeleteMutationFn = Apollo.MutationFunction<Types.ExhibitionsDeleteMutation, Types.ExhibitionsDeleteMutationVariables>;

/**
 * __useExhibitionsDeleteMutation__
 *
 * To run a mutation, you first call `useExhibitionsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExhibitionsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exhibitionsDeleteMutation, { data, loading, error }] = useExhibitionsDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExhibitionsDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.ExhibitionsDeleteMutation, Types.ExhibitionsDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ExhibitionsDeleteMutation, Types.ExhibitionsDeleteMutationVariables>(ExhibitionsDeleteDocument, options);
      }
export type ExhibitionsDeleteMutationHookResult = ReturnType<typeof useExhibitionsDeleteMutation>;
export type ExhibitionsDeleteMutationResult = Apollo.MutationResult<Types.ExhibitionsDeleteMutation>;
export type ExhibitionsDeleteMutationOptions = Apollo.BaseMutationOptions<Types.ExhibitionsDeleteMutation, Types.ExhibitionsDeleteMutationVariables>;
export const ExhibitionsDatabasePaginationDocument = gql`
    query exhibitionsDatabasePagination($localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: ExhibitionSort, $filters: ExhibitionFilter, $query: String) {
  exhibitionsDatabasePagination(
    localeId: $localeId
    page: $page
    perPage: $perPage
    sort: $sort
    filter: $filters
    query: $query
  ) {
    pageInfo {
      page
      perPage
      hasNextPage
      hasPreviousPage
      totalItems
      totalPages
    }
    items {
      id
      isReferenced
      name
      country {
        id
        codeISO
        localizedName(localeId: $localeId)
      }
      dateStart
      dateEnd
      exhibitionType {
        id
        localizedName(localeId: $localeId)
      }
      participantsMin
      participantsMax
      place
      website
      authorUser {
        id
        username
      }
      updatedAt
    }
  }
}
    `;

/**
 * __useExhibitionsDatabasePaginationQuery__
 *
 * To run a query within a React component, call `useExhibitionsDatabasePaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useExhibitionsDatabasePaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExhibitionsDatabasePaginationQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useExhibitionsDatabasePaginationQuery(baseOptions: Apollo.QueryHookOptions<Types.ExhibitionsDatabasePaginationQuery, Types.ExhibitionsDatabasePaginationQueryVariables> & ({ variables: Types.ExhibitionsDatabasePaginationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExhibitionsDatabasePaginationQuery, Types.ExhibitionsDatabasePaginationQueryVariables>(ExhibitionsDatabasePaginationDocument, options);
      }
export function useExhibitionsDatabasePaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExhibitionsDatabasePaginationQuery, Types.ExhibitionsDatabasePaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExhibitionsDatabasePaginationQuery, Types.ExhibitionsDatabasePaginationQueryVariables>(ExhibitionsDatabasePaginationDocument, options);
        }
export function useExhibitionsDatabasePaginationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ExhibitionsDatabasePaginationQuery, Types.ExhibitionsDatabasePaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ExhibitionsDatabasePaginationQuery, Types.ExhibitionsDatabasePaginationQueryVariables>(ExhibitionsDatabasePaginationDocument, options);
        }
export type ExhibitionsDatabasePaginationQueryHookResult = ReturnType<typeof useExhibitionsDatabasePaginationQuery>;
export type ExhibitionsDatabasePaginationLazyQueryHookResult = ReturnType<typeof useExhibitionsDatabasePaginationLazyQuery>;
export type ExhibitionsDatabasePaginationSuspenseQueryHookResult = ReturnType<typeof useExhibitionsDatabasePaginationSuspenseQuery>;
export type ExhibitionsDatabasePaginationQueryResult = Apollo.QueryResult<Types.ExhibitionsDatabasePaginationQuery, Types.ExhibitionsDatabasePaginationQueryVariables>;
export const TradeNameFactMoveDocument = gql`
    mutation tradeNameFactMove($input: TradeNameFactMoveInput!) {
  tradeNameFactMove(input: $input) {
    record {
      id
      tradeName
    }
  }
}
    `;
export type TradeNameFactMoveMutationFn = Apollo.MutationFunction<Types.TradeNameFactMoveMutation, Types.TradeNameFactMoveMutationVariables>;

/**
 * __useTradeNameFactMoveMutation__
 *
 * To run a mutation, you first call `useTradeNameFactMoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTradeNameFactMoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tradeNameFactMoveMutation, { data, loading, error }] = useTradeNameFactMoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTradeNameFactMoveMutation(baseOptions?: Apollo.MutationHookOptions<Types.TradeNameFactMoveMutation, Types.TradeNameFactMoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.TradeNameFactMoveMutation, Types.TradeNameFactMoveMutationVariables>(TradeNameFactMoveDocument, options);
      }
export type TradeNameFactMoveMutationHookResult = ReturnType<typeof useTradeNameFactMoveMutation>;
export type TradeNameFactMoveMutationResult = Apollo.MutationResult<Types.TradeNameFactMoveMutation>;
export type TradeNameFactMoveMutationOptions = Apollo.BaseMutationOptions<Types.TradeNameFactMoveMutation, Types.TradeNameFactMoveMutationVariables>;
export const MoveFactTradeNamesDocument = gql`
    query moveFactTradeNames($localeId: Int!, $query: String, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: TradeNameSort!, $filter: TradeNameFilter!) {
  tradeNameDatabasePagination(
    localeId: $localeId
    filter: $filter
    query: $query
    page: $page
    perPage: $perPage
    sort: $sort
  ) {
    items {
      id
      tradeName
      country {
        id
        codeISO
      }
      legalEntity {
        id
        legalName
        identityNumber
        legalAddress
      }
      website
      phone
      email
      postAddress
      postCode
      phone
      email
    }
  }
}
    `;

/**
 * __useMoveFactTradeNamesQuery__
 *
 * To run a query within a React component, call `useMoveFactTradeNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoveFactTradeNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoveFactTradeNamesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMoveFactTradeNamesQuery(baseOptions: Apollo.QueryHookOptions<Types.MoveFactTradeNamesQuery, Types.MoveFactTradeNamesQueryVariables> & ({ variables: Types.MoveFactTradeNamesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MoveFactTradeNamesQuery, Types.MoveFactTradeNamesQueryVariables>(MoveFactTradeNamesDocument, options);
      }
export function useMoveFactTradeNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MoveFactTradeNamesQuery, Types.MoveFactTradeNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MoveFactTradeNamesQuery, Types.MoveFactTradeNamesQueryVariables>(MoveFactTradeNamesDocument, options);
        }
export function useMoveFactTradeNamesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MoveFactTradeNamesQuery, Types.MoveFactTradeNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MoveFactTradeNamesQuery, Types.MoveFactTradeNamesQueryVariables>(MoveFactTradeNamesDocument, options);
        }
export type MoveFactTradeNamesQueryHookResult = ReturnType<typeof useMoveFactTradeNamesQuery>;
export type MoveFactTradeNamesLazyQueryHookResult = ReturnType<typeof useMoveFactTradeNamesLazyQuery>;
export type MoveFactTradeNamesSuspenseQueryHookResult = ReturnType<typeof useMoveFactTradeNamesSuspenseQuery>;
export type MoveFactTradeNamesQueryResult = Apollo.QueryResult<Types.MoveFactTradeNamesQuery, Types.MoveFactTradeNamesQueryVariables>;
export const TradeNameAcceptabilityDeleteDocument = gql`
    mutation tradeNameAcceptabilityDelete($input: TradeNameAcceptabilityDeleteInput!) {
  tradeNameAcceptabilityDelete(input: $input) {
    query {
      _
    }
    record {
      id
      acceptability
    }
  }
}
    `;
export type TradeNameAcceptabilityDeleteMutationFn = Apollo.MutationFunction<Types.TradeNameAcceptabilityDeleteMutation, Types.TradeNameAcceptabilityDeleteMutationVariables>;

/**
 * __useTradeNameAcceptabilityDeleteMutation__
 *
 * To run a mutation, you first call `useTradeNameAcceptabilityDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTradeNameAcceptabilityDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tradeNameAcceptabilityDeleteMutation, { data, loading, error }] = useTradeNameAcceptabilityDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTradeNameAcceptabilityDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.TradeNameAcceptabilityDeleteMutation, Types.TradeNameAcceptabilityDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.TradeNameAcceptabilityDeleteMutation, Types.TradeNameAcceptabilityDeleteMutationVariables>(TradeNameAcceptabilityDeleteDocument, options);
      }
export type TradeNameAcceptabilityDeleteMutationHookResult = ReturnType<typeof useTradeNameAcceptabilityDeleteMutation>;
export type TradeNameAcceptabilityDeleteMutationResult = Apollo.MutationResult<Types.TradeNameAcceptabilityDeleteMutation>;
export type TradeNameAcceptabilityDeleteMutationOptions = Apollo.BaseMutationOptions<Types.TradeNameAcceptabilityDeleteMutation, Types.TradeNameAcceptabilityDeleteMutationVariables>;
export const AdminExportTradeNamesDocument = gql`
    mutation adminExportTradeNames($input: AdminTradeNamesExportInput!) {
  admin {
    tradeNames {
      export(input: $input) {
        link
        sizeInBytes
      }
    }
  }
}
    `;
export type AdminExportTradeNamesMutationFn = Apollo.MutationFunction<Types.AdminExportTradeNamesMutation, Types.AdminExportTradeNamesMutationVariables>;

/**
 * __useAdminExportTradeNamesMutation__
 *
 * To run a mutation, you first call `useAdminExportTradeNamesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminExportTradeNamesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminExportTradeNamesMutation, { data, loading, error }] = useAdminExportTradeNamesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminExportTradeNamesMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminExportTradeNamesMutation, Types.AdminExportTradeNamesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminExportTradeNamesMutation, Types.AdminExportTradeNamesMutationVariables>(AdminExportTradeNamesDocument, options);
      }
export type AdminExportTradeNamesMutationHookResult = ReturnType<typeof useAdminExportTradeNamesMutation>;
export type AdminExportTradeNamesMutationResult = Apollo.MutationResult<Types.AdminExportTradeNamesMutation>;
export type AdminExportTradeNamesMutationOptions = Apollo.BaseMutationOptions<Types.AdminExportTradeNamesMutation, Types.AdminExportTradeNamesMutationVariables>;
export const ProductFactMoveDocument = gql`
    mutation productFactMove($input: ProductFactMoveInput!) {
  productFactMove(input: $input) {
    query {
      _
    }
    record {
      id
    }
  }
}
    `;
export type ProductFactMoveMutationFn = Apollo.MutationFunction<Types.ProductFactMoveMutation, Types.ProductFactMoveMutationVariables>;

/**
 * __useProductFactMoveMutation__
 *
 * To run a mutation, you first call `useProductFactMoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductFactMoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productFactMoveMutation, { data, loading, error }] = useProductFactMoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductFactMoveMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProductFactMoveMutation, Types.ProductFactMoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductFactMoveMutation, Types.ProductFactMoveMutationVariables>(ProductFactMoveDocument, options);
      }
export type ProductFactMoveMutationHookResult = ReturnType<typeof useProductFactMoveMutation>;
export type ProductFactMoveMutationResult = Apollo.MutationResult<Types.ProductFactMoveMutation>;
export type ProductFactMoveMutationOptions = Apollo.BaseMutationOptions<Types.ProductFactMoveMutation, Types.ProductFactMoveMutationVariables>;
export const TechSheetFactMoveDocument = gql`
    mutation techSheetFactMove($input: TechSheetFactMoveInput!) {
  techSheetFactMove(input: $input) {
    query {
      _
    }
    record {
      id
    }
  }
}
    `;
export type TechSheetFactMoveMutationFn = Apollo.MutationFunction<Types.TechSheetFactMoveMutation, Types.TechSheetFactMoveMutationVariables>;

/**
 * __useTechSheetFactMoveMutation__
 *
 * To run a mutation, you first call `useTechSheetFactMoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTechSheetFactMoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [techSheetFactMoveMutation, { data, loading, error }] = useTechSheetFactMoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTechSheetFactMoveMutation(baseOptions?: Apollo.MutationHookOptions<Types.TechSheetFactMoveMutation, Types.TechSheetFactMoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.TechSheetFactMoveMutation, Types.TechSheetFactMoveMutationVariables>(TechSheetFactMoveDocument, options);
      }
export type TechSheetFactMoveMutationHookResult = ReturnType<typeof useTechSheetFactMoveMutation>;
export type TechSheetFactMoveMutationResult = Apollo.MutationResult<Types.TechSheetFactMoveMutation>;
export type TechSheetFactMoveMutationOptions = Apollo.BaseMutationOptions<Types.TechSheetFactMoveMutation, Types.TechSheetFactMoveMutationVariables>;
export const WineAwardFactMoveDocument = gql`
    mutation wineAwardFactMove($input: WineAwardFactMoveInput!) {
  wineAwardFactMove(input: $input) {
    query {
      _
    }
    record {
      id
    }
  }
}
    `;
export type WineAwardFactMoveMutationFn = Apollo.MutationFunction<Types.WineAwardFactMoveMutation, Types.WineAwardFactMoveMutationVariables>;

/**
 * __useWineAwardFactMoveMutation__
 *
 * To run a mutation, you first call `useWineAwardFactMoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineAwardFactMoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineAwardFactMoveMutation, { data, loading, error }] = useWineAwardFactMoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineAwardFactMoveMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineAwardFactMoveMutation, Types.WineAwardFactMoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineAwardFactMoveMutation, Types.WineAwardFactMoveMutationVariables>(WineAwardFactMoveDocument, options);
      }
export type WineAwardFactMoveMutationHookResult = ReturnType<typeof useWineAwardFactMoveMutation>;
export type WineAwardFactMoveMutationResult = Apollo.MutationResult<Types.WineAwardFactMoveMutation>;
export type WineAwardFactMoveMutationOptions = Apollo.BaseMutationOptions<Types.WineAwardFactMoveMutation, Types.WineAwardFactMoveMutationVariables>;
export const WineFactMoveDocument = gql`
    mutation wineFactMove($input: WineFactMoveInput!) {
  wineFactMove(input: $input) {
    query {
      _
    }
    record {
      id
    }
  }
}
    `;
export type WineFactMoveMutationFn = Apollo.MutationFunction<Types.WineFactMoveMutation, Types.WineFactMoveMutationVariables>;

/**
 * __useWineFactMoveMutation__
 *
 * To run a mutation, you first call `useWineFactMoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineFactMoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineFactMoveMutation, { data, loading, error }] = useWineFactMoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineFactMoveMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineFactMoveMutation, Types.WineFactMoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineFactMoveMutation, Types.WineFactMoveMutationVariables>(WineFactMoveDocument, options);
      }
export type WineFactMoveMutationHookResult = ReturnType<typeof useWineFactMoveMutation>;
export type WineFactMoveMutationResult = Apollo.MutationResult<Types.WineFactMoveMutation>;
export type WineFactMoveMutationOptions = Apollo.BaseMutationOptions<Types.WineFactMoveMutation, Types.WineFactMoveMutationVariables>;
export const MoveFactWinesDocument = gql`
    query moveFactWines($localeId: Int!, $query: String, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: WineSort!, $filter: WineDatabaseFilter) {
  winesDatabasePagination(
    localeId: $localeId
    filter: $filter
    query: $query
    page: $page
    perPage: $perPage
    sort: $sort
  ) {
    items {
      id
      tradeNameBrand
      tradeName {
        tradeName
        country {
          codeISO
          localizedName(localeId: $localeId)
        }
      }
      nationalGITypeText
      nationalGIType {
        localizedShortName(localeId: $localeId)
      }
      geoIndicationText
      geoIndications {
        localizedName(localeId: $localeId)
      }
      keyWords
      specialClassifications {
        name
      }
      sweetnessLevel {
        isDefault
        localizedName(localeId: $localeId)
      }
      wineType {
        isDefault
        localizedName(localeId: $localeId)
      }
      wineColor {
        localizedName(localeId: $localeId)
      }
      country {
        codeISO
        localizedName(localeId: $localeId)
      }
      vintage
    }
  }
}
    `;

/**
 * __useMoveFactWinesQuery__
 *
 * To run a query within a React component, call `useMoveFactWinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoveFactWinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoveFactWinesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMoveFactWinesQuery(baseOptions: Apollo.QueryHookOptions<Types.MoveFactWinesQuery, Types.MoveFactWinesQueryVariables> & ({ variables: Types.MoveFactWinesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MoveFactWinesQuery, Types.MoveFactWinesQueryVariables>(MoveFactWinesDocument, options);
      }
export function useMoveFactWinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MoveFactWinesQuery, Types.MoveFactWinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MoveFactWinesQuery, Types.MoveFactWinesQueryVariables>(MoveFactWinesDocument, options);
        }
export function useMoveFactWinesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MoveFactWinesQuery, Types.MoveFactWinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MoveFactWinesQuery, Types.MoveFactWinesQueryVariables>(MoveFactWinesDocument, options);
        }
export type MoveFactWinesQueryHookResult = ReturnType<typeof useMoveFactWinesQuery>;
export type MoveFactWinesLazyQueryHookResult = ReturnType<typeof useMoveFactWinesLazyQuery>;
export type MoveFactWinesSuspenseQueryHookResult = ReturnType<typeof useMoveFactWinesSuspenseQuery>;
export type MoveFactWinesQueryResult = Apollo.QueryResult<Types.MoveFactWinesQuery, Types.MoveFactWinesQueryVariables>;
export const ProductForCreateReviewDocument = gql`
    query productForCreateReview($id: ID!, $localeId: Int!) {
  product(id: $id) {
    ...reviewSearchProduct
  }
}
    ${ReviewSearchProductFragmentDoc}`;

/**
 * __useProductForCreateReviewQuery__
 *
 * To run a query within a React component, call `useProductForCreateReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductForCreateReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductForCreateReviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useProductForCreateReviewQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductForCreateReviewQuery, Types.ProductForCreateReviewQueryVariables> & ({ variables: Types.ProductForCreateReviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductForCreateReviewQuery, Types.ProductForCreateReviewQueryVariables>(ProductForCreateReviewDocument, options);
      }
export function useProductForCreateReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductForCreateReviewQuery, Types.ProductForCreateReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductForCreateReviewQuery, Types.ProductForCreateReviewQueryVariables>(ProductForCreateReviewDocument, options);
        }
export function useProductForCreateReviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductForCreateReviewQuery, Types.ProductForCreateReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductForCreateReviewQuery, Types.ProductForCreateReviewQueryVariables>(ProductForCreateReviewDocument, options);
        }
export type ProductForCreateReviewQueryHookResult = ReturnType<typeof useProductForCreateReviewQuery>;
export type ProductForCreateReviewLazyQueryHookResult = ReturnType<typeof useProductForCreateReviewLazyQuery>;
export type ProductForCreateReviewSuspenseQueryHookResult = ReturnType<typeof useProductForCreateReviewSuspenseQuery>;
export type ProductForCreateReviewQueryResult = Apollo.QueryResult<Types.ProductForCreateReviewQuery, Types.ProductForCreateReviewQueryVariables>;
export const WineOverwriteDocument = gql`
    mutation wineOverwrite($input: WineOverwriteInput!) {
  wineOverwrite(input: $input) {
    __typename
  }
}
    `;
export type WineOverwriteMutationFn = Apollo.MutationFunction<Types.WineOverwriteMutation, Types.WineOverwriteMutationVariables>;

/**
 * __useWineOverwriteMutation__
 *
 * To run a mutation, you first call `useWineOverwriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOverwriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOverwriteMutation, { data, loading, error }] = useWineOverwriteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOverwriteMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOverwriteMutation, Types.WineOverwriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOverwriteMutation, Types.WineOverwriteMutationVariables>(WineOverwriteDocument, options);
      }
export type WineOverwriteMutationHookResult = ReturnType<typeof useWineOverwriteMutation>;
export type WineOverwriteMutationResult = Apollo.MutationResult<Types.WineOverwriteMutation>;
export type WineOverwriteMutationOptions = Apollo.BaseMutationOptions<Types.WineOverwriteMutation, Types.WineOverwriteMutationVariables>;
export const ProductELabelUpdateDocument = gql`
    mutation productELabelUpdate($input: ProductELabelUpdateInput!) {
  productELabelUpdate(input: $input) {
    recordId
  }
}
    `;
export type ProductELabelUpdateMutationFn = Apollo.MutationFunction<Types.ProductELabelUpdateMutation, Types.ProductELabelUpdateMutationVariables>;

/**
 * __useProductELabelUpdateMutation__
 *
 * To run a mutation, you first call `useProductELabelUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductELabelUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productELabelUpdateMutation, { data, loading, error }] = useProductELabelUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductELabelUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProductELabelUpdateMutation, Types.ProductELabelUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductELabelUpdateMutation, Types.ProductELabelUpdateMutationVariables>(ProductELabelUpdateDocument, options);
      }
export type ProductELabelUpdateMutationHookResult = ReturnType<typeof useProductELabelUpdateMutation>;
export type ProductELabelUpdateMutationResult = Apollo.MutationResult<Types.ProductELabelUpdateMutation>;
export type ProductELabelUpdateMutationOptions = Apollo.BaseMutationOptions<Types.ProductELabelUpdateMutation, Types.ProductELabelUpdateMutationVariables>;
export const ELabelForEditDocument = gql`
    query eLabelForEdit($id: ID!, $localeId: Int!) {
  productELabel(id: $id) {
    id
    bottleImage {
      imageUrl
      thumbnailUrl: imageUrl(transformations: {width: 96, height: 96, resize: fit})
      thumbnailRetinaUrl: imageUrl(
        transformations: {width: 192, height: 192, resize: fit}
      )
    }
    bottleImagePreview: bottleImage {
      imageUrl
      thumbnailUrl: imageUrl(transformations: {width: 205, height: 205, resize: fit})
      thumbnailRetinaUrl: imageUrl(
        transformations: {width: 410, height: 410, resize: fit}
      )
    }
    bottleSize {
      id
      volumeML
    }
    packageType {
      id
      isDefault
      shortName
      localizedName(localeId: $localeId)
    }
    originProduct {
      slug
    }
    packageElements {
      element {
        id
        localizedName(localeId: $localeId)
      }
      euMaterials {
        id
        logo
        code
        localizedName(localeId: $localeId)
        localizedDisposal(localeId: $localeId)
      }
    }
    wine {
      tradeName {
        website
        logo {
          thumbnailUrl: imageUrl(transformations: {width: 50, height: 50, resize: fit})
          thumbnailRetinaUrl: imageUrl(
            transformations: {width: 100, height: 100, resize: fit}
          )
        }
        tradeName
      }
      wineType {
        localizedName(localeId: $localeId)
      }
      country {
        id
        localizedName(localeId: $localeId)
        codeISO
      }
      wineColor {
        localizedName(localeId: $localeId)
      }
      brand {
        brand
      }
      keyWords
      sweetnessLevel {
        localizedName(localeId: $localeId)
      }
      vintage
      chemicalAnalysis {
        alcoholVolume
      }
      nationalGIType {
        localizedShortName(localeId: $localeId)
      }
      geoIndications {
        id
        localizedName(localeId: $localeId)
      }
      specialClassifications {
        id
        name
      }
      wineTypeEU {
        id
        localizedName(localeId: $localeId)
      }
      grapeVarieties {
        grapeVarietySynonym {
          id
          name
        }
        value
      }
      ingredients {
        id
        localizedName(localeId: $localeId)
        isAllergen
        code
        category {
          id
          localizedName(localeId: $localeId)
          isPrintable
        }
      }
      nutritionDeclaration {
        energyKCal
        energyKCalApproximate
        energyKJ
        fat
        fatApproximate
        saturates
        saturatesApproximate
        carbohydrate
        carbohydrateApproximate
        sugars
        sugarsApproximate
        protein
        proteinApproximate
        salt
        saltApproximate
      }
      certs {
        id
        logo
        localizedName(localeId: $localeId)
      }
      responsibleConsumptions {
        id
        logo
        localizedName(localeId: $localeId)
      }
    }
  }
}
    `;

/**
 * __useELabelForEditQuery__
 *
 * To run a query within a React component, call `useELabelForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useELabelForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useELabelForEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useELabelForEditQuery(baseOptions: Apollo.QueryHookOptions<Types.ELabelForEditQuery, Types.ELabelForEditQueryVariables> & ({ variables: Types.ELabelForEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ELabelForEditQuery, Types.ELabelForEditQueryVariables>(ELabelForEditDocument, options);
      }
export function useELabelForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ELabelForEditQuery, Types.ELabelForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ELabelForEditQuery, Types.ELabelForEditQueryVariables>(ELabelForEditDocument, options);
        }
export function useELabelForEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ELabelForEditQuery, Types.ELabelForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ELabelForEditQuery, Types.ELabelForEditQueryVariables>(ELabelForEditDocument, options);
        }
export type ELabelForEditQueryHookResult = ReturnType<typeof useELabelForEditQuery>;
export type ELabelForEditLazyQueryHookResult = ReturnType<typeof useELabelForEditLazyQuery>;
export type ELabelForEditSuspenseQueryHookResult = ReturnType<typeof useELabelForEditSuspenseQuery>;
export type ELabelForEditQueryResult = Apollo.QueryResult<Types.ELabelForEditQuery, Types.ELabelForEditQueryVariables>;
export const ExhibitionByNameDocument = gql`
    query exhibitionByName($name: String!) {
  exhibitionByName(name: $name) {
    id
  }
}
    `;

/**
 * __useExhibitionByNameQuery__
 *
 * To run a query within a React component, call `useExhibitionByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useExhibitionByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExhibitionByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useExhibitionByNameQuery(baseOptions: Apollo.QueryHookOptions<Types.ExhibitionByNameQuery, Types.ExhibitionByNameQueryVariables> & ({ variables: Types.ExhibitionByNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExhibitionByNameQuery, Types.ExhibitionByNameQueryVariables>(ExhibitionByNameDocument, options);
      }
export function useExhibitionByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExhibitionByNameQuery, Types.ExhibitionByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExhibitionByNameQuery, Types.ExhibitionByNameQueryVariables>(ExhibitionByNameDocument, options);
        }
export function useExhibitionByNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ExhibitionByNameQuery, Types.ExhibitionByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ExhibitionByNameQuery, Types.ExhibitionByNameQueryVariables>(ExhibitionByNameDocument, options);
        }
export type ExhibitionByNameQueryHookResult = ReturnType<typeof useExhibitionByNameQuery>;
export type ExhibitionByNameLazyQueryHookResult = ReturnType<typeof useExhibitionByNameLazyQuery>;
export type ExhibitionByNameSuspenseQueryHookResult = ReturnType<typeof useExhibitionByNameSuspenseQuery>;
export type ExhibitionByNameQueryResult = Apollo.QueryResult<Types.ExhibitionByNameQuery, Types.ExhibitionByNameQueryVariables>;
export const ExhibitionCreateDocument = gql`
    mutation exhibitionCreate($input: ExhibitionCreateInput!) {
  exhibitionCreate(input: $input) {
    recordId
  }
}
    `;
export type ExhibitionCreateMutationFn = Apollo.MutationFunction<Types.ExhibitionCreateMutation, Types.ExhibitionCreateMutationVariables>;

/**
 * __useExhibitionCreateMutation__
 *
 * To run a mutation, you first call `useExhibitionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExhibitionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exhibitionCreateMutation, { data, loading, error }] = useExhibitionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExhibitionCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.ExhibitionCreateMutation, Types.ExhibitionCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ExhibitionCreateMutation, Types.ExhibitionCreateMutationVariables>(ExhibitionCreateDocument, options);
      }
export type ExhibitionCreateMutationHookResult = ReturnType<typeof useExhibitionCreateMutation>;
export type ExhibitionCreateMutationResult = Apollo.MutationResult<Types.ExhibitionCreateMutation>;
export type ExhibitionCreateMutationOptions = Apollo.BaseMutationOptions<Types.ExhibitionCreateMutation, Types.ExhibitionCreateMutationVariables>;
export const ExhibitionUpdateDocument = gql`
    mutation exhibitionUpdate($input: ExhibitionUpdateInput!) {
  exhibitionUpdate(input: $input) {
    recordId
  }
}
    `;
export type ExhibitionUpdateMutationFn = Apollo.MutationFunction<Types.ExhibitionUpdateMutation, Types.ExhibitionUpdateMutationVariables>;

/**
 * __useExhibitionUpdateMutation__
 *
 * To run a mutation, you first call `useExhibitionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExhibitionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exhibitionUpdateMutation, { data, loading, error }] = useExhibitionUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExhibitionUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.ExhibitionUpdateMutation, Types.ExhibitionUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ExhibitionUpdateMutation, Types.ExhibitionUpdateMutationVariables>(ExhibitionUpdateDocument, options);
      }
export type ExhibitionUpdateMutationHookResult = ReturnType<typeof useExhibitionUpdateMutation>;
export type ExhibitionUpdateMutationResult = Apollo.MutationResult<Types.ExhibitionUpdateMutation>;
export type ExhibitionUpdateMutationOptions = Apollo.BaseMutationOptions<Types.ExhibitionUpdateMutation, Types.ExhibitionUpdateMutationVariables>;
export const ExhibitionForEditDocument = gql`
    query exhibitionForEdit($id: Int!, $localeId: Int!) {
  exhibition(id: $id) {
    id
    name
    dateStart
    dateEnd
    country {
      id
      localizedName(localeId: $localeId)
      codeISO
    }
    place
    website
    participantsMin
    participantsMax
    exhibitionType {
      id
      localizedName(localeId: $localeId)
    }
  }
}
    `;

/**
 * __useExhibitionForEditQuery__
 *
 * To run a query within a React component, call `useExhibitionForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useExhibitionForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExhibitionForEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useExhibitionForEditQuery(baseOptions: Apollo.QueryHookOptions<Types.ExhibitionForEditQuery, Types.ExhibitionForEditQueryVariables> & ({ variables: Types.ExhibitionForEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExhibitionForEditQuery, Types.ExhibitionForEditQueryVariables>(ExhibitionForEditDocument, options);
      }
export function useExhibitionForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExhibitionForEditQuery, Types.ExhibitionForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExhibitionForEditQuery, Types.ExhibitionForEditQueryVariables>(ExhibitionForEditDocument, options);
        }
export function useExhibitionForEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ExhibitionForEditQuery, Types.ExhibitionForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ExhibitionForEditQuery, Types.ExhibitionForEditQueryVariables>(ExhibitionForEditDocument, options);
        }
export type ExhibitionForEditQueryHookResult = ReturnType<typeof useExhibitionForEditQuery>;
export type ExhibitionForEditLazyQueryHookResult = ReturnType<typeof useExhibitionForEditLazyQuery>;
export type ExhibitionForEditSuspenseQueryHookResult = ReturnType<typeof useExhibitionForEditSuspenseQuery>;
export type ExhibitionForEditQueryResult = Apollo.QueryResult<Types.ExhibitionForEditQuery, Types.ExhibitionForEditQueryVariables>;
export const SendPasswordResetEmailDocument = gql`
    mutation sendPasswordResetEmail($email: EmailAddress!, $localeId: Int!) {
  sendPasswordResetEmail(email: $email, localeId: $localeId)
}
    `;
export type SendPasswordResetEmailMutationFn = Apollo.MutationFunction<Types.SendPasswordResetEmailMutation, Types.SendPasswordResetEmailMutationVariables>;

/**
 * __useSendPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetEmailMutation, { data, loading, error }] = useSendPasswordResetEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useSendPasswordResetEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendPasswordResetEmailMutation, Types.SendPasswordResetEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendPasswordResetEmailMutation, Types.SendPasswordResetEmailMutationVariables>(SendPasswordResetEmailDocument, options);
      }
export type SendPasswordResetEmailMutationHookResult = ReturnType<typeof useSendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationResult = Apollo.MutationResult<Types.SendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationOptions = Apollo.BaseMutationOptions<Types.SendPasswordResetEmailMutation, Types.SendPasswordResetEmailMutationVariables>;
export const CompanyTourCompleteDocument = gql`
    mutation companyTourComplete($companyId: ID!) {
  companyTourComplete(input: {companyId: $companyId}) {
    company {
      id
      tour {
        id
        completed
      }
    }
  }
}
    `;
export type CompanyTourCompleteMutationFn = Apollo.MutationFunction<Types.CompanyTourCompleteMutation, Types.CompanyTourCompleteMutationVariables>;

/**
 * __useCompanyTourCompleteMutation__
 *
 * To run a mutation, you first call `useCompanyTourCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyTourCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyTourCompleteMutation, { data, loading, error }] = useCompanyTourCompleteMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyTourCompleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompanyTourCompleteMutation, Types.CompanyTourCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompanyTourCompleteMutation, Types.CompanyTourCompleteMutationVariables>(CompanyTourCompleteDocument, options);
      }
export type CompanyTourCompleteMutationHookResult = ReturnType<typeof useCompanyTourCompleteMutation>;
export type CompanyTourCompleteMutationResult = Apollo.MutationResult<Types.CompanyTourCompleteMutation>;
export type CompanyTourCompleteMutationOptions = Apollo.BaseMutationOptions<Types.CompanyTourCompleteMutation, Types.CompanyTourCompleteMutationVariables>;
export const CompanyTourSkipDocument = gql`
    mutation companyTourSkip($companyId: ID!) {
  companyTourSkip(input: {companyId: $companyId}) {
    __typename
  }
}
    `;
export type CompanyTourSkipMutationFn = Apollo.MutationFunction<Types.CompanyTourSkipMutation, Types.CompanyTourSkipMutationVariables>;

/**
 * __useCompanyTourSkipMutation__
 *
 * To run a mutation, you first call `useCompanyTourSkipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyTourSkipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyTourSkipMutation, { data, loading, error }] = useCompanyTourSkipMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyTourSkipMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompanyTourSkipMutation, Types.CompanyTourSkipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompanyTourSkipMutation, Types.CompanyTourSkipMutationVariables>(CompanyTourSkipDocument, options);
      }
export type CompanyTourSkipMutationHookResult = ReturnType<typeof useCompanyTourSkipMutation>;
export type CompanyTourSkipMutationResult = Apollo.MutationResult<Types.CompanyTourSkipMutation>;
export type CompanyTourSkipMutationOptions = Apollo.BaseMutationOptions<Types.CompanyTourSkipMutation, Types.CompanyTourSkipMutationVariables>;
export const LastBuyerOfferDocument = gql`
    query lastBuyerOffer($companyId: ID!, $localeId: Int!) {
  me {
    id
    company(id: $companyId) {
      id
      lastOffer: wineOffersPagination(
        localeId: $localeId
        isBuyer: true
        page: 1
        perPage: 1
        filter: {wineOfferTypeIds: [2], wineOfferStatusIds: [4]}
        sort: UPDATED_AT_DESC
      ) {
        items {
          ...lastPersonalOffer
        }
      }
    }
  }
}
    ${LastPersonalOfferFragmentDoc}`;

/**
 * __useLastBuyerOfferQuery__
 *
 * To run a query within a React component, call `useLastBuyerOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastBuyerOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastBuyerOfferQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useLastBuyerOfferQuery(baseOptions: Apollo.QueryHookOptions<Types.LastBuyerOfferQuery, Types.LastBuyerOfferQueryVariables> & ({ variables: Types.LastBuyerOfferQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LastBuyerOfferQuery, Types.LastBuyerOfferQueryVariables>(LastBuyerOfferDocument, options);
      }
export function useLastBuyerOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LastBuyerOfferQuery, Types.LastBuyerOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LastBuyerOfferQuery, Types.LastBuyerOfferQueryVariables>(LastBuyerOfferDocument, options);
        }
export function useLastBuyerOfferSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LastBuyerOfferQuery, Types.LastBuyerOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LastBuyerOfferQuery, Types.LastBuyerOfferQueryVariables>(LastBuyerOfferDocument, options);
        }
export type LastBuyerOfferQueryHookResult = ReturnType<typeof useLastBuyerOfferQuery>;
export type LastBuyerOfferLazyQueryHookResult = ReturnType<typeof useLastBuyerOfferLazyQuery>;
export type LastBuyerOfferSuspenseQueryHookResult = ReturnType<typeof useLastBuyerOfferSuspenseQuery>;
export type LastBuyerOfferQueryResult = Apollo.QueryResult<Types.LastBuyerOfferQuery, Types.LastBuyerOfferQueryVariables>;
export const RequestsPerDayAsBuyerDocument = gql`
    query requestsPerDayAsBuyer($companyId: ID!, $days: Int!) {
  me {
    id
    company(id: $companyId) {
      id
      wineOfferRequestsPeriodOfValiditySeries(isBuyer: true, limit: $days) {
        value: count
        date: periodOfValidity
      }
      wineOfferRequestsPagination(isBuyer: true) {
        pageInfo {
          totalItems
        }
      }
    }
  }
}
    `;

/**
 * __useRequestsPerDayAsBuyerQuery__
 *
 * To run a query within a React component, call `useRequestsPerDayAsBuyerQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestsPerDayAsBuyerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestsPerDayAsBuyerQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      days: // value for 'days'
 *   },
 * });
 */
export function useRequestsPerDayAsBuyerQuery(baseOptions: Apollo.QueryHookOptions<Types.RequestsPerDayAsBuyerQuery, Types.RequestsPerDayAsBuyerQueryVariables> & ({ variables: Types.RequestsPerDayAsBuyerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RequestsPerDayAsBuyerQuery, Types.RequestsPerDayAsBuyerQueryVariables>(RequestsPerDayAsBuyerDocument, options);
      }
export function useRequestsPerDayAsBuyerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RequestsPerDayAsBuyerQuery, Types.RequestsPerDayAsBuyerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RequestsPerDayAsBuyerQuery, Types.RequestsPerDayAsBuyerQueryVariables>(RequestsPerDayAsBuyerDocument, options);
        }
export function useRequestsPerDayAsBuyerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RequestsPerDayAsBuyerQuery, Types.RequestsPerDayAsBuyerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RequestsPerDayAsBuyerQuery, Types.RequestsPerDayAsBuyerQueryVariables>(RequestsPerDayAsBuyerDocument, options);
        }
export type RequestsPerDayAsBuyerQueryHookResult = ReturnType<typeof useRequestsPerDayAsBuyerQuery>;
export type RequestsPerDayAsBuyerLazyQueryHookResult = ReturnType<typeof useRequestsPerDayAsBuyerLazyQuery>;
export type RequestsPerDayAsBuyerSuspenseQueryHookResult = ReturnType<typeof useRequestsPerDayAsBuyerSuspenseQuery>;
export type RequestsPerDayAsBuyerQueryResult = Apollo.QueryResult<Types.RequestsPerDayAsBuyerQuery, Types.RequestsPerDayAsBuyerQueryVariables>;
export const BuyerNegotiationsCountDocument = gql`
    query buyerNegotiationsCount($companyId: ID!, $localeId: Int!) {
  me {
    id
    company(id: $companyId) {
      id
      waitingWineOffers: wineOffersPagination(
        localeId: $localeId
        isBuyer: true
        filter: {wineOfferTypeIds: [2], wineOfferStatusIds: [4]}
      ) {
        pageInfo {
          totalItems
        }
      }
      activeNegotiations: wineOffersPagination(
        localeId: $localeId
        isBuyer: true
        filter: {wineOfferTypeIds: [2], wineOfferStatusIds: [3, 4]}
      ) {
        pageInfo {
          totalItems
        }
      }
    }
  }
}
    `;

/**
 * __useBuyerNegotiationsCountQuery__
 *
 * To run a query within a React component, call `useBuyerNegotiationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyerNegotiationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyerNegotiationsCountQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useBuyerNegotiationsCountQuery(baseOptions: Apollo.QueryHookOptions<Types.BuyerNegotiationsCountQuery, Types.BuyerNegotiationsCountQueryVariables> & ({ variables: Types.BuyerNegotiationsCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyerNegotiationsCountQuery, Types.BuyerNegotiationsCountQueryVariables>(BuyerNegotiationsCountDocument, options);
      }
export function useBuyerNegotiationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyerNegotiationsCountQuery, Types.BuyerNegotiationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyerNegotiationsCountQuery, Types.BuyerNegotiationsCountQueryVariables>(BuyerNegotiationsCountDocument, options);
        }
export function useBuyerNegotiationsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyerNegotiationsCountQuery, Types.BuyerNegotiationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyerNegotiationsCountQuery, Types.BuyerNegotiationsCountQueryVariables>(BuyerNegotiationsCountDocument, options);
        }
export type BuyerNegotiationsCountQueryHookResult = ReturnType<typeof useBuyerNegotiationsCountQuery>;
export type BuyerNegotiationsCountLazyQueryHookResult = ReturnType<typeof useBuyerNegotiationsCountLazyQuery>;
export type BuyerNegotiationsCountSuspenseQueryHookResult = ReturnType<typeof useBuyerNegotiationsCountSuspenseQuery>;
export type BuyerNegotiationsCountQueryResult = Apollo.QueryResult<Types.BuyerNegotiationsCountQuery, Types.BuyerNegotiationsCountQueryVariables>;
export const LastProductVisitsDocument = gql`
    query lastProductVisits($limit: Int!, $localeId: Int!) {
  me {
    id
    productVisits(limit: $limit) {
      id
      slug
      bottleImage {
        imageUrl
        thumbnailUrl: imageUrl(transformations: {width: 48, height: 192, resize: fit})
        thumbnailRetinaUrl: imageUrl(
          transformations: {width: 96, height: 384, resize: fit}
        )
      }
      bottleSize {
        id
        volumeML
      }
      packageType {
        id
        isDefault
        shortName
        localizedName(localeId: $localeId)
      }
      wine {
        id
        country {
          id
          codeISO
          localizedName(localeId: $localeId)
        }
        rating
        wineColor {
          id
        }
        wineType {
          id
        }
        tradeName {
          id
          tradeName
          localizedTradeName(localeId: $localeId)
        }
        brand {
          id
          name
        }
        vintage
        geoIndicationText
        geoIndications {
          id
          localizedName(localeId: $localeId)
        }
        nationalGITypeText
        nationalGIType {
          id
          localizedShortName(localeId: $localeId)
        }
        specialClassifications {
          id
          name
        }
        keyWords
      }
      mainSynonym {
        ... on Product {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useLastProductVisitsQuery__
 *
 * To run a query within a React component, call `useLastProductVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastProductVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastProductVisitsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useLastProductVisitsQuery(baseOptions: Apollo.QueryHookOptions<Types.LastProductVisitsQuery, Types.LastProductVisitsQueryVariables> & ({ variables: Types.LastProductVisitsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LastProductVisitsQuery, Types.LastProductVisitsQueryVariables>(LastProductVisitsDocument, options);
      }
export function useLastProductVisitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LastProductVisitsQuery, Types.LastProductVisitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LastProductVisitsQuery, Types.LastProductVisitsQueryVariables>(LastProductVisitsDocument, options);
        }
export function useLastProductVisitsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LastProductVisitsQuery, Types.LastProductVisitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LastProductVisitsQuery, Types.LastProductVisitsQueryVariables>(LastProductVisitsDocument, options);
        }
export type LastProductVisitsQueryHookResult = ReturnType<typeof useLastProductVisitsQuery>;
export type LastProductVisitsLazyQueryHookResult = ReturnType<typeof useLastProductVisitsLazyQuery>;
export type LastProductVisitsSuspenseQueryHookResult = ReturnType<typeof useLastProductVisitsSuspenseQuery>;
export type LastProductVisitsQueryResult = Apollo.QueryResult<Types.LastProductVisitsQuery, Types.LastProductVisitsQueryVariables>;
export const MyContributionsDocument = gql`
    query MyContributions($localeId: Int!, $filterByUserName: FacetInput!, $limit: Int!) {
  winesFilters(localeId: $localeId, filter: {facetsInputs: [$filterByUserName]}) {
    count
  }
  brandsFilters(localeId: $localeId, filter: {facetsInputs: [$filterByUserName]}) {
    count
  }
  tradeNamesFilters(
    localeId: $localeId
    filter: {facetsInputs: [$filterByUserName]}
  ) {
    count
  }
  me {
    id
    winesContributedSeries(limit: $limit) {
      count
      date
    }
    brandsContributedSeries(limit: $limit) {
      count
      date
    }
    tradeNamesContributedSeries(limit: $limit) {
      count
      date
    }
  }
}
    `;

/**
 * __useMyContributionsQuery__
 *
 * To run a query within a React component, call `useMyContributionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyContributionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyContributionsQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      filterByUserName: // value for 'filterByUserName'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMyContributionsQuery(baseOptions: Apollo.QueryHookOptions<Types.MyContributionsQuery, Types.MyContributionsQueryVariables> & ({ variables: Types.MyContributionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MyContributionsQuery, Types.MyContributionsQueryVariables>(MyContributionsDocument, options);
      }
export function useMyContributionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MyContributionsQuery, Types.MyContributionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MyContributionsQuery, Types.MyContributionsQueryVariables>(MyContributionsDocument, options);
        }
export function useMyContributionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MyContributionsQuery, Types.MyContributionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MyContributionsQuery, Types.MyContributionsQueryVariables>(MyContributionsDocument, options);
        }
export type MyContributionsQueryHookResult = ReturnType<typeof useMyContributionsQuery>;
export type MyContributionsLazyQueryHookResult = ReturnType<typeof useMyContributionsLazyQuery>;
export type MyContributionsSuspenseQueryHookResult = ReturnType<typeof useMyContributionsSuspenseQuery>;
export type MyContributionsQueryResult = Apollo.QueryResult<Types.MyContributionsQuery, Types.MyContributionsQueryVariables>;
export const CompaniesListDocument = gql`
    query companiesList($localeId: Int!) {
  me {
    id
    companies {
      id
      avatar {
        thumbnailUrl: imageUrl(transformations: {width: 40, height: 40, resize: fit})
        thumbnailRetinaUrl: imageUrl(
          transformations: {width: 80, height: 80, resize: fit}
        )
      }
      verificationStatus {
        id
        code
      }
      tradeName
      legalEntity {
        id
        identityNumber
        branchNumber
        vat
        legalEntityForm {
          id
        }
        legalName
        activityCode
      }
      country {
        id
        identityNumberName
        branchNumberName
        vatName
        legalEntityDiscriminator
      }
      postCountry {
        id
        localizedName(localeId: $localeId)
      }
      postAddress
      postCode
      email
      website
      phone
      companyCategory {
        id
        masterName
        localizedName(localeId: $localeId)
      }
    }
  }
}
    `;

/**
 * __useCompaniesListQuery__
 *
 * To run a query within a React component, call `useCompaniesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesListQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useCompaniesListQuery(baseOptions: Apollo.QueryHookOptions<Types.CompaniesListQuery, Types.CompaniesListQueryVariables> & ({ variables: Types.CompaniesListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompaniesListQuery, Types.CompaniesListQueryVariables>(CompaniesListDocument, options);
      }
export function useCompaniesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompaniesListQuery, Types.CompaniesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompaniesListQuery, Types.CompaniesListQueryVariables>(CompaniesListDocument, options);
        }
export function useCompaniesListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CompaniesListQuery, Types.CompaniesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompaniesListQuery, Types.CompaniesListQueryVariables>(CompaniesListDocument, options);
        }
export type CompaniesListQueryHookResult = ReturnType<typeof useCompaniesListQuery>;
export type CompaniesListLazyQueryHookResult = ReturnType<typeof useCompaniesListLazyQuery>;
export type CompaniesListSuspenseQueryHookResult = ReturnType<typeof useCompaniesListSuspenseQuery>;
export type CompaniesListQueryResult = Apollo.QueryResult<Types.CompaniesListQuery, Types.CompaniesListQueryVariables>;
export const LastSellerOfferDocument = gql`
    query lastSellerOffer($companyId: ID!, $localeId: Int!) {
  me {
    id
    company(id: $companyId) {
      id
      lastOffer: wineOffersPagination(
        localeId: $localeId
        isBuyer: false
        page: 1
        perPage: 1
        filter: {wineOfferTypeIds: [2], wineOfferStatusIds: [3]}
        sort: UPDATED_AT_DESC
      ) {
        items {
          ...lastPersonalOffer
        }
      }
    }
  }
}
    ${LastPersonalOfferFragmentDoc}`;

/**
 * __useLastSellerOfferQuery__
 *
 * To run a query within a React component, call `useLastSellerOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastSellerOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastSellerOfferQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useLastSellerOfferQuery(baseOptions: Apollo.QueryHookOptions<Types.LastSellerOfferQuery, Types.LastSellerOfferQueryVariables> & ({ variables: Types.LastSellerOfferQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LastSellerOfferQuery, Types.LastSellerOfferQueryVariables>(LastSellerOfferDocument, options);
      }
export function useLastSellerOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LastSellerOfferQuery, Types.LastSellerOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LastSellerOfferQuery, Types.LastSellerOfferQueryVariables>(LastSellerOfferDocument, options);
        }
export function useLastSellerOfferSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LastSellerOfferQuery, Types.LastSellerOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LastSellerOfferQuery, Types.LastSellerOfferQueryVariables>(LastSellerOfferDocument, options);
        }
export type LastSellerOfferQueryHookResult = ReturnType<typeof useLastSellerOfferQuery>;
export type LastSellerOfferLazyQueryHookResult = ReturnType<typeof useLastSellerOfferLazyQuery>;
export type LastSellerOfferSuspenseQueryHookResult = ReturnType<typeof useLastSellerOfferSuspenseQuery>;
export type LastSellerOfferQueryResult = Apollo.QueryResult<Types.LastSellerOfferQuery, Types.LastSellerOfferQueryVariables>;
export const RequestsPerDayAsSellerDocument = gql`
    query requestsPerDayAsSeller($companyId: ID!, $days: Int!) {
  me {
    id
    company(id: $companyId) {
      id
      wineOfferRequestsPeriodOfValiditySeries(isBuyer: false, limit: $days) {
        value: count
        date: periodOfValidity
      }
      wineOfferRequestsPagination(isBuyer: false) {
        pageInfo {
          totalItems
        }
      }
    }
  }
}
    `;

/**
 * __useRequestsPerDayAsSellerQuery__
 *
 * To run a query within a React component, call `useRequestsPerDayAsSellerQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestsPerDayAsSellerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestsPerDayAsSellerQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      days: // value for 'days'
 *   },
 * });
 */
export function useRequestsPerDayAsSellerQuery(baseOptions: Apollo.QueryHookOptions<Types.RequestsPerDayAsSellerQuery, Types.RequestsPerDayAsSellerQueryVariables> & ({ variables: Types.RequestsPerDayAsSellerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RequestsPerDayAsSellerQuery, Types.RequestsPerDayAsSellerQueryVariables>(RequestsPerDayAsSellerDocument, options);
      }
export function useRequestsPerDayAsSellerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RequestsPerDayAsSellerQuery, Types.RequestsPerDayAsSellerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RequestsPerDayAsSellerQuery, Types.RequestsPerDayAsSellerQueryVariables>(RequestsPerDayAsSellerDocument, options);
        }
export function useRequestsPerDayAsSellerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RequestsPerDayAsSellerQuery, Types.RequestsPerDayAsSellerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RequestsPerDayAsSellerQuery, Types.RequestsPerDayAsSellerQueryVariables>(RequestsPerDayAsSellerDocument, options);
        }
export type RequestsPerDayAsSellerQueryHookResult = ReturnType<typeof useRequestsPerDayAsSellerQuery>;
export type RequestsPerDayAsSellerLazyQueryHookResult = ReturnType<typeof useRequestsPerDayAsSellerLazyQuery>;
export type RequestsPerDayAsSellerSuspenseQueryHookResult = ReturnType<typeof useRequestsPerDayAsSellerSuspenseQuery>;
export type RequestsPerDayAsSellerQueryResult = Apollo.QueryResult<Types.RequestsPerDayAsSellerQuery, Types.RequestsPerDayAsSellerQueryVariables>;
export const SellerNegotiationsCountDocument = gql`
    query sellerNegotiationsCount($companyId: ID!, $localeId: Int!) {
  me {
    id
    company(id: $companyId) {
      id
      waitingWineOffers: wineOffersPagination(
        localeId: $localeId
        isBuyer: false
        filter: {wineOfferTypeIds: [2], wineOfferStatusIds: [4]}
      ) {
        pageInfo {
          totalItems
        }
      }
      activeNegotiations: wineOffersPagination(
        localeId: $localeId
        isBuyer: false
        filter: {wineOfferTypeIds: [2], wineOfferStatusIds: [3, 4]}
      ) {
        pageInfo {
          totalItems
        }
      }
    }
  }
}
    `;

/**
 * __useSellerNegotiationsCountQuery__
 *
 * To run a query within a React component, call `useSellerNegotiationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerNegotiationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerNegotiationsCountQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useSellerNegotiationsCountQuery(baseOptions: Apollo.QueryHookOptions<Types.SellerNegotiationsCountQuery, Types.SellerNegotiationsCountQueryVariables> & ({ variables: Types.SellerNegotiationsCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SellerNegotiationsCountQuery, Types.SellerNegotiationsCountQueryVariables>(SellerNegotiationsCountDocument, options);
      }
export function useSellerNegotiationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SellerNegotiationsCountQuery, Types.SellerNegotiationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SellerNegotiationsCountQuery, Types.SellerNegotiationsCountQueryVariables>(SellerNegotiationsCountDocument, options);
        }
export function useSellerNegotiationsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SellerNegotiationsCountQuery, Types.SellerNegotiationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SellerNegotiationsCountQuery, Types.SellerNegotiationsCountQueryVariables>(SellerNegotiationsCountDocument, options);
        }
export type SellerNegotiationsCountQueryHookResult = ReturnType<typeof useSellerNegotiationsCountQuery>;
export type SellerNegotiationsCountLazyQueryHookResult = ReturnType<typeof useSellerNegotiationsCountLazyQuery>;
export type SellerNegotiationsCountSuspenseQueryHookResult = ReturnType<typeof useSellerNegotiationsCountSuspenseQuery>;
export type SellerNegotiationsCountQueryResult = Apollo.QueryResult<Types.SellerNegotiationsCountQuery, Types.SellerNegotiationsCountQueryVariables>;
export const SignInDocument = gql`
    mutation signIn($email: EmailAddress!, $password: String!, $localeId: Int) {
  signIn(email: $email, password: $password, localeId: $localeId) {
    token
    refreshToken
    locale {
      id
      locale
    }
  }
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<Types.SignInMutation, Types.SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<Types.SignInMutation, Types.SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SignInMutation, Types.SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<Types.SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<Types.SignInMutation, Types.SignInMutationVariables>;
export const AdminCreateMerchantDocument = gql`
    mutation adminCreateMerchant($input: AdminMerchantCreateInput!) {
  admin {
    merchants {
      create(input: $input) {
        recordId
      }
    }
  }
}
    `;
export type AdminCreateMerchantMutationFn = Apollo.MutationFunction<Types.AdminCreateMerchantMutation, Types.AdminCreateMerchantMutationVariables>;

/**
 * __useAdminCreateMerchantMutation__
 *
 * To run a mutation, you first call `useAdminCreateMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateMerchantMutation, { data, loading, error }] = useAdminCreateMerchantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateMerchantMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminCreateMerchantMutation, Types.AdminCreateMerchantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminCreateMerchantMutation, Types.AdminCreateMerchantMutationVariables>(AdminCreateMerchantDocument, options);
      }
export type AdminCreateMerchantMutationHookResult = ReturnType<typeof useAdminCreateMerchantMutation>;
export type AdminCreateMerchantMutationResult = Apollo.MutationResult<Types.AdminCreateMerchantMutation>;
export type AdminCreateMerchantMutationOptions = Apollo.BaseMutationOptions<Types.AdminCreateMerchantMutation, Types.AdminCreateMerchantMutationVariables>;
export const AdminMerchantByIdDocument = gql`
    query adminMerchantById($id: ID!, $localeId: Int!) {
  admin {
    merchants {
      merchant(id: $id) {
        id
        name
        website
        country {
          id
          localizedName(localeId: $localeId)
          codeISO
          masterName
          postCodeName
          isEUMember
        }
        deliveryTime {
          id
          localizedName(localeId: $localeId)
        }
        deliveryTerms
      }
    }
  }
}
    `;

/**
 * __useAdminMerchantByIdQuery__
 *
 * To run a query within a React component, call `useAdminMerchantByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMerchantByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMerchantByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useAdminMerchantByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminMerchantByIdQuery, Types.AdminMerchantByIdQueryVariables> & ({ variables: Types.AdminMerchantByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminMerchantByIdQuery, Types.AdminMerchantByIdQueryVariables>(AdminMerchantByIdDocument, options);
      }
export function useAdminMerchantByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminMerchantByIdQuery, Types.AdminMerchantByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminMerchantByIdQuery, Types.AdminMerchantByIdQueryVariables>(AdminMerchantByIdDocument, options);
        }
export function useAdminMerchantByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminMerchantByIdQuery, Types.AdminMerchantByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminMerchantByIdQuery, Types.AdminMerchantByIdQueryVariables>(AdminMerchantByIdDocument, options);
        }
export type AdminMerchantByIdQueryHookResult = ReturnType<typeof useAdminMerchantByIdQuery>;
export type AdminMerchantByIdLazyQueryHookResult = ReturnType<typeof useAdminMerchantByIdLazyQuery>;
export type AdminMerchantByIdSuspenseQueryHookResult = ReturnType<typeof useAdminMerchantByIdSuspenseQuery>;
export type AdminMerchantByIdQueryResult = Apollo.QueryResult<Types.AdminMerchantByIdQuery, Types.AdminMerchantByIdQueryVariables>;
export const AdminUpdateMerchantDocument = gql`
    mutation adminUpdateMerchant($input: AdminMerchantUpdateInput!) {
  admin {
    merchants {
      update(input: $input) {
        recordId
      }
    }
  }
}
    `;
export type AdminUpdateMerchantMutationFn = Apollo.MutationFunction<Types.AdminUpdateMerchantMutation, Types.AdminUpdateMerchantMutationVariables>;

/**
 * __useAdminUpdateMerchantMutation__
 *
 * To run a mutation, you first call `useAdminUpdateMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateMerchantMutation, { data, loading, error }] = useAdminUpdateMerchantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateMerchantMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminUpdateMerchantMutation, Types.AdminUpdateMerchantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminUpdateMerchantMutation, Types.AdminUpdateMerchantMutationVariables>(AdminUpdateMerchantDocument, options);
      }
export type AdminUpdateMerchantMutationHookResult = ReturnType<typeof useAdminUpdateMerchantMutation>;
export type AdminUpdateMerchantMutationResult = Apollo.MutationResult<Types.AdminUpdateMerchantMutation>;
export type AdminUpdateMerchantMutationOptions = Apollo.BaseMutationOptions<Types.AdminUpdateMerchantMutation, Types.AdminUpdateMerchantMutationVariables>;
export const CompanyViewDocument = gql`
    query companyView($id: ID!, $localeId: Int!) {
  me {
    id
    company(id: $id) {
      id
      companyCategory {
        id
        masterName
        localizedName(localeId: $localeId)
      }
      country {
        id
        localizedName(localeId: $localeId)
        codeISO
        identityNumberName
        branchNumberName
        vatName
        legalEntityDiscriminator
      }
      avatar {
        imageUrl
        thumbnailUrl: imageUrl(transformations: {width: 64, height: 64, resize: fit})
        thumbnailRetinaUrl: imageUrl(
          transformations: {width: 128, height: 128, resize: fit}
        )
      }
      tradeName
      legalEntity {
        id
        identityNumber
        branchNumber
        vat
        isActive
        closingDate
        legalEntityForm {
          id
          originName
          shortName
        }
        activityCode
        legalName
        legalAddress
      }
      website
      postCode
      postAddress
      email
      phone
      isVerifiedUser
      verificationStatus {
        id
        code
      }
      productELabelSubscription {
        quota
        usedQuota
        status
        periodStart
        periodEnd
        price
        interval
        currency {
          symbol
        }
      }
    }
  }
}
    `;

/**
 * __useCompanyViewQuery__
 *
 * To run a query within a React component, call `useCompanyViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useCompanyViewQuery(baseOptions: Apollo.QueryHookOptions<Types.CompanyViewQuery, Types.CompanyViewQueryVariables> & ({ variables: Types.CompanyViewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompanyViewQuery, Types.CompanyViewQueryVariables>(CompanyViewDocument, options);
      }
export function useCompanyViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompanyViewQuery, Types.CompanyViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompanyViewQuery, Types.CompanyViewQueryVariables>(CompanyViewDocument, options);
        }
export function useCompanyViewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CompanyViewQuery, Types.CompanyViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompanyViewQuery, Types.CompanyViewQueryVariables>(CompanyViewDocument, options);
        }
export type CompanyViewQueryHookResult = ReturnType<typeof useCompanyViewQuery>;
export type CompanyViewLazyQueryHookResult = ReturnType<typeof useCompanyViewLazyQuery>;
export type CompanyViewSuspenseQueryHookResult = ReturnType<typeof useCompanyViewSuspenseQuery>;
export type CompanyViewQueryResult = Apollo.QueryResult<Types.CompanyViewQuery, Types.CompanyViewQueryVariables>;
export const ProductELabelsPublishDocument = gql`
    mutation productELabelsPublish($input: ProductELabelsPublishInput!) {
  productELabelsPublish(input: $input) {
    records {
      id
      isPublished
      companyPublications {
        company {
          id
          representativeUser {
            id
            username
          }
        }
        isPublished
      }
    }
  }
}
    `;
export type ProductELabelsPublishMutationFn = Apollo.MutationFunction<Types.ProductELabelsPublishMutation, Types.ProductELabelsPublishMutationVariables>;

/**
 * __useProductELabelsPublishMutation__
 *
 * To run a mutation, you first call `useProductELabelsPublishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductELabelsPublishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productELabelsPublishMutation, { data, loading, error }] = useProductELabelsPublishMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductELabelsPublishMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProductELabelsPublishMutation, Types.ProductELabelsPublishMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductELabelsPublishMutation, Types.ProductELabelsPublishMutationVariables>(ProductELabelsPublishDocument, options);
      }
export type ProductELabelsPublishMutationHookResult = ReturnType<typeof useProductELabelsPublishMutation>;
export type ProductELabelsPublishMutationResult = Apollo.MutationResult<Types.ProductELabelsPublishMutation>;
export type ProductELabelsPublishMutationOptions = Apollo.BaseMutationOptions<Types.ProductELabelsPublishMutation, Types.ProductELabelsPublishMutationVariables>;
export const ProductELabelsUnpublishDocument = gql`
    mutation productELabelsUnpublish($input: ProductELabelsUnpublishInput!) {
  productELabelsUnpublish(input: $input) {
    records {
      id
      isPublished
      companyPublications {
        company {
          id
          representativeUser {
            id
            username
          }
        }
        isPublished
      }
    }
  }
}
    `;
export type ProductELabelsUnpublishMutationFn = Apollo.MutationFunction<Types.ProductELabelsUnpublishMutation, Types.ProductELabelsUnpublishMutationVariables>;

/**
 * __useProductELabelsUnpublishMutation__
 *
 * To run a mutation, you first call `useProductELabelsUnpublishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductELabelsUnpublishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productELabelsUnpublishMutation, { data, loading, error }] = useProductELabelsUnpublishMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductELabelsUnpublishMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProductELabelsUnpublishMutation, Types.ProductELabelsUnpublishMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductELabelsUnpublishMutation, Types.ProductELabelsUnpublishMutationVariables>(ProductELabelsUnpublishDocument, options);
      }
export type ProductELabelsUnpublishMutationHookResult = ReturnType<typeof useProductELabelsUnpublishMutation>;
export type ProductELabelsUnpublishMutationResult = Apollo.MutationResult<Types.ProductELabelsUnpublishMutation>;
export type ProductELabelsUnpublishMutationOptions = Apollo.BaseMutationOptions<Types.ProductELabelsUnpublishMutation, Types.ProductELabelsUnpublishMutationVariables>;
export const MyELabelsTabsCountersDocument = gql`
    query myELabelsTabsCounters($localeId: Int!, $companyId: ID!) {
  me {
    company(id: $companyId) {
      id
      representativeGroup {
        companies(verifiedOnly: false) {
          id
        }
      }
      producerTradeName {
        id
        publishedCount: productELabelsPagination(
          localeId: $localeId
          filter: {isPublished: true}
        ) {
          pageInfo {
            totalItems
          }
        }
        publishedByMeCount: productELabelsPagination(
          localeId: $localeId
          filter: {publishedByCompany: [$companyId]}
        ) {
          pageInfo {
            totalItems
          }
        }
      }
      productELabelSubscription {
        status
        quota
        periodEnd
      }
    }
  }
}
    `;

/**
 * __useMyELabelsTabsCountersQuery__
 *
 * To run a query within a React component, call `useMyELabelsTabsCountersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyELabelsTabsCountersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyELabelsTabsCountersQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMyELabelsTabsCountersQuery(baseOptions: Apollo.QueryHookOptions<Types.MyELabelsTabsCountersQuery, Types.MyELabelsTabsCountersQueryVariables> & ({ variables: Types.MyELabelsTabsCountersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MyELabelsTabsCountersQuery, Types.MyELabelsTabsCountersQueryVariables>(MyELabelsTabsCountersDocument, options);
      }
export function useMyELabelsTabsCountersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MyELabelsTabsCountersQuery, Types.MyELabelsTabsCountersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MyELabelsTabsCountersQuery, Types.MyELabelsTabsCountersQueryVariables>(MyELabelsTabsCountersDocument, options);
        }
export function useMyELabelsTabsCountersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MyELabelsTabsCountersQuery, Types.MyELabelsTabsCountersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MyELabelsTabsCountersQuery, Types.MyELabelsTabsCountersQueryVariables>(MyELabelsTabsCountersDocument, options);
        }
export type MyELabelsTabsCountersQueryHookResult = ReturnType<typeof useMyELabelsTabsCountersQuery>;
export type MyELabelsTabsCountersLazyQueryHookResult = ReturnType<typeof useMyELabelsTabsCountersLazyQuery>;
export type MyELabelsTabsCountersSuspenseQueryHookResult = ReturnType<typeof useMyELabelsTabsCountersSuspenseQuery>;
export type MyELabelsTabsCountersQueryResult = Apollo.QueryResult<Types.MyELabelsTabsCountersQuery, Types.MyELabelsTabsCountersQueryVariables>;
export const ProductsAddToShortlistDocument = gql`
    mutation productsAddToShortlist($companyId: ID!, $productIds: [ID!]!) {
  productsAddToShortlist(input: {companyId: $companyId, productIds: $productIds}) {
    records {
      id
      shortlisted(companyId: $companyId)
    }
  }
}
    `;
export type ProductsAddToShortlistMutationFn = Apollo.MutationFunction<Types.ProductsAddToShortlistMutation, Types.ProductsAddToShortlistMutationVariables>;

/**
 * __useProductsAddToShortlistMutation__
 *
 * To run a mutation, you first call `useProductsAddToShortlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductsAddToShortlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productsAddToShortlistMutation, { data, loading, error }] = useProductsAddToShortlistMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useProductsAddToShortlistMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProductsAddToShortlistMutation, Types.ProductsAddToShortlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductsAddToShortlistMutation, Types.ProductsAddToShortlistMutationVariables>(ProductsAddToShortlistDocument, options);
      }
export type ProductsAddToShortlistMutationHookResult = ReturnType<typeof useProductsAddToShortlistMutation>;
export type ProductsAddToShortlistMutationResult = Apollo.MutationResult<Types.ProductsAddToShortlistMutation>;
export type ProductsAddToShortlistMutationOptions = Apollo.BaseMutationOptions<Types.ProductsAddToShortlistMutation, Types.ProductsAddToShortlistMutationVariables>;
export const ProductsRemoveFromShortlistDocument = gql`
    mutation productsRemoveFromShortlist($companyId: ID!, $productIds: [ID!]!) {
  productsRemoveFromShortlist(
    input: {companyId: $companyId, productIds: $productIds}
  ) {
    records {
      id
      shortlisted(companyId: $companyId)
    }
  }
}
    `;
export type ProductsRemoveFromShortlistMutationFn = Apollo.MutationFunction<Types.ProductsRemoveFromShortlistMutation, Types.ProductsRemoveFromShortlistMutationVariables>;

/**
 * __useProductsRemoveFromShortlistMutation__
 *
 * To run a mutation, you first call `useProductsRemoveFromShortlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductsRemoveFromShortlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productsRemoveFromShortlistMutation, { data, loading, error }] = useProductsRemoveFromShortlistMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useProductsRemoveFromShortlistMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProductsRemoveFromShortlistMutation, Types.ProductsRemoveFromShortlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductsRemoveFromShortlistMutation, Types.ProductsRemoveFromShortlistMutationVariables>(ProductsRemoveFromShortlistDocument, options);
      }
export type ProductsRemoveFromShortlistMutationHookResult = ReturnType<typeof useProductsRemoveFromShortlistMutation>;
export type ProductsRemoveFromShortlistMutationResult = Apollo.MutationResult<Types.ProductsRemoveFromShortlistMutation>;
export type ProductsRemoveFromShortlistMutationOptions = Apollo.BaseMutationOptions<Types.ProductsRemoveFromShortlistMutation, Types.ProductsRemoveFromShortlistMutationVariables>;
export const MyWinesProductImagesUpdateDocument = gql`
    mutation myWinesProductImagesUpdate($input: ProductImagesUpdateInput!) {
  productImagesUpdate(input: $input) {
    record {
      id
      bottleImage {
        ...imageThumbnail48
      }
      frontLabelImage {
        ...imageThumbnail48
      }
      backLabelImage {
        ...imageThumbnail48
      }
    }
  }
}
    ${ImageThumbnail48FragmentDoc}`;
export type MyWinesProductImagesUpdateMutationFn = Apollo.MutationFunction<Types.MyWinesProductImagesUpdateMutation, Types.MyWinesProductImagesUpdateMutationVariables>;

/**
 * __useMyWinesProductImagesUpdateMutation__
 *
 * To run a mutation, you first call `useMyWinesProductImagesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMyWinesProductImagesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [myWinesProductImagesUpdateMutation, { data, loading, error }] = useMyWinesProductImagesUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMyWinesProductImagesUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.MyWinesProductImagesUpdateMutation, Types.MyWinesProductImagesUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MyWinesProductImagesUpdateMutation, Types.MyWinesProductImagesUpdateMutationVariables>(MyWinesProductImagesUpdateDocument, options);
      }
export type MyWinesProductImagesUpdateMutationHookResult = ReturnType<typeof useMyWinesProductImagesUpdateMutation>;
export type MyWinesProductImagesUpdateMutationResult = Apollo.MutationResult<Types.MyWinesProductImagesUpdateMutation>;
export type MyWinesProductImagesUpdateMutationOptions = Apollo.BaseMutationOptions<Types.MyWinesProductImagesUpdateMutation, Types.MyWinesProductImagesUpdateMutationVariables>;
export const ProductsExportDocument = gql`
    mutation productsExport($input: ProductsExportInput!) {
  productsExport(input: $input) {
    link
    sizeInBytes
  }
}
    `;
export type ProductsExportMutationFn = Apollo.MutationFunction<Types.ProductsExportMutation, Types.ProductsExportMutationVariables>;

/**
 * __useProductsExportMutation__
 *
 * To run a mutation, you first call `useProductsExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductsExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productsExportMutation, { data, loading, error }] = useProductsExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductsExportMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProductsExportMutation, Types.ProductsExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductsExportMutation, Types.ProductsExportMutationVariables>(ProductsExportDocument, options);
      }
export type ProductsExportMutationHookResult = ReturnType<typeof useProductsExportMutation>;
export type ProductsExportMutationResult = Apollo.MutationResult<Types.ProductsExportMutation>;
export type ProductsExportMutationOptions = Apollo.BaseMutationOptions<Types.ProductsExportMutation, Types.ProductsExportMutationVariables>;
export const ShortlistCountDocument = gql`
    query shortlistCount($localeId: Int!, $companyId: ID!, $onlyAccepted: Boolean! = false) {
  me {
    id
    company(id: $companyId) {
      id
      productsShortlistPagination(
        localeId: $localeId
        filter: {onlyAccepted: $onlyAccepted}
      ) {
        pageInfo {
          totalItems
        }
      }
    }
  }
}
    `;

/**
 * __useShortlistCountQuery__
 *
 * To run a query within a React component, call `useShortlistCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useShortlistCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShortlistCountQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      companyId: // value for 'companyId'
 *      onlyAccepted: // value for 'onlyAccepted'
 *   },
 * });
 */
export function useShortlistCountQuery(baseOptions: Apollo.QueryHookOptions<Types.ShortlistCountQuery, Types.ShortlistCountQueryVariables> & ({ variables: Types.ShortlistCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ShortlistCountQuery, Types.ShortlistCountQueryVariables>(ShortlistCountDocument, options);
      }
export function useShortlistCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ShortlistCountQuery, Types.ShortlistCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ShortlistCountQuery, Types.ShortlistCountQueryVariables>(ShortlistCountDocument, options);
        }
export function useShortlistCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ShortlistCountQuery, Types.ShortlistCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ShortlistCountQuery, Types.ShortlistCountQueryVariables>(ShortlistCountDocument, options);
        }
export type ShortlistCountQueryHookResult = ReturnType<typeof useShortlistCountQuery>;
export type ShortlistCountLazyQueryHookResult = ReturnType<typeof useShortlistCountLazyQuery>;
export type ShortlistCountSuspenseQueryHookResult = ReturnType<typeof useShortlistCountSuspenseQuery>;
export type ShortlistCountQueryResult = Apollo.QueryResult<Types.ShortlistCountQuery, Types.ShortlistCountQueryVariables>;
export const WineOffersArchiveHideDocument = gql`
    mutation wineOffersArchiveHide($companyId: ID!, $wineOfferIds: [Int!]!) {
  wineOffersArchiveHide(
    input: {companyId: $companyId, wineOfferIds: $wineOfferIds}
  ) {
    records {
      id
    }
  }
}
    `;
export type WineOffersArchiveHideMutationFn = Apollo.MutationFunction<Types.WineOffersArchiveHideMutation, Types.WineOffersArchiveHideMutationVariables>;

/**
 * __useWineOffersArchiveHideMutation__
 *
 * To run a mutation, you first call `useWineOffersArchiveHideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOffersArchiveHideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOffersArchiveHideMutation, { data, loading, error }] = useWineOffersArchiveHideMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      wineOfferIds: // value for 'wineOfferIds'
 *   },
 * });
 */
export function useWineOffersArchiveHideMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOffersArchiveHideMutation, Types.WineOffersArchiveHideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOffersArchiveHideMutation, Types.WineOffersArchiveHideMutationVariables>(WineOffersArchiveHideDocument, options);
      }
export type WineOffersArchiveHideMutationHookResult = ReturnType<typeof useWineOffersArchiveHideMutation>;
export type WineOffersArchiveHideMutationResult = Apollo.MutationResult<Types.WineOffersArchiveHideMutation>;
export type WineOffersArchiveHideMutationOptions = Apollo.BaseMutationOptions<Types.WineOffersArchiveHideMutation, Types.WineOffersArchiveHideMutationVariables>;
export const WineOfferBuyerAddCommentDocument = gql`
    mutation wineOfferBuyerAddComment($input: WineOfferBuyerOfferConditionsInput!) {
  wineOfferBuyerOfferConditions(input: $input) {
    record {
      id
    }
  }
}
    `;
export type WineOfferBuyerAddCommentMutationFn = Apollo.MutationFunction<Types.WineOfferBuyerAddCommentMutation, Types.WineOfferBuyerAddCommentMutationVariables>;

/**
 * __useWineOfferBuyerAddCommentMutation__
 *
 * To run a mutation, you first call `useWineOfferBuyerAddCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOfferBuyerAddCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOfferBuyerAddCommentMutation, { data, loading, error }] = useWineOfferBuyerAddCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOfferBuyerAddCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOfferBuyerAddCommentMutation, Types.WineOfferBuyerAddCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOfferBuyerAddCommentMutation, Types.WineOfferBuyerAddCommentMutationVariables>(WineOfferBuyerAddCommentDocument, options);
      }
export type WineOfferBuyerAddCommentMutationHookResult = ReturnType<typeof useWineOfferBuyerAddCommentMutation>;
export type WineOfferBuyerAddCommentMutationResult = Apollo.MutationResult<Types.WineOfferBuyerAddCommentMutation>;
export type WineOfferBuyerAddCommentMutationOptions = Apollo.BaseMutationOptions<Types.WineOfferBuyerAddCommentMutation, Types.WineOfferBuyerAddCommentMutationVariables>;
export const WineOfferSellerAddCommentDocument = gql`
    mutation wineOfferSellerAddComment($input: WineOfferSellerOfferConditionsInput!) {
  wineOfferSellerOfferConditions(input: $input) {
    record {
      id
    }
  }
}
    `;
export type WineOfferSellerAddCommentMutationFn = Apollo.MutationFunction<Types.WineOfferSellerAddCommentMutation, Types.WineOfferSellerAddCommentMutationVariables>;

/**
 * __useWineOfferSellerAddCommentMutation__
 *
 * To run a mutation, you first call `useWineOfferSellerAddCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOfferSellerAddCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOfferSellerAddCommentMutation, { data, loading, error }] = useWineOfferSellerAddCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOfferSellerAddCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOfferSellerAddCommentMutation, Types.WineOfferSellerAddCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOfferSellerAddCommentMutation, Types.WineOfferSellerAddCommentMutationVariables>(WineOfferSellerAddCommentDocument, options);
      }
export type WineOfferSellerAddCommentMutationHookResult = ReturnType<typeof useWineOfferSellerAddCommentMutation>;
export type WineOfferSellerAddCommentMutationResult = Apollo.MutationResult<Types.WineOfferSellerAddCommentMutation>;
export type WineOfferSellerAddCommentMutationOptions = Apollo.BaseMutationOptions<Types.WineOfferSellerAddCommentMutation, Types.WineOfferSellerAddCommentMutationVariables>;
export const WineOfferDocument = gql`
    query WineOffer($localeId: Int!, $companyId: ID!, $offerId: Int!) {
  wineOffer(id: $offerId) {
    ... on WineOfferBase {
      ...wineOfferBase
      shortlisted(companyId: $companyId)
    }
    ... on WineOfferPublic {
      ...wineOfferPublic
    }
    ... on WineOfferCompany {
      ...wineOfferCompany
    }
    ... on WineOfferPersonal {
      ...WineOfferPersonalFragment
      history {
        ...wineOfferHistory
        hasRead(companyId: $companyId)
      }
    }
    ... on WineOfferPublicDraft {
      id
      contactPhone
      contactEmail
      PublicDraftBuyer: buyerCompanyCategory {
        id
        localizedName(localeId: $localeId)
      }
    }
    ... on WineOfferCompanyDraft {
      id
      CompanyDraftBuyer: buyer(companyId: $companyId) {
        ...wineOfferBuyerInfo
      }
      buyerContactsRequested
      canBuyerGrantCompanyInfo
      canBuyerRevokeCompanyInfo
      contactPhone
      contactEmail
    }
    ... on WineOfferDeal {
      id
      shipmentCountry {
        id
        localizedName(localeId: $localeId)
      }
    }
  }
}
    ${WineOfferBaseFragmentDoc}
${WineOfferPublicFragmentDoc}
${WineOfferCompanyFragmentDoc}
${WineOfferPersonalFragmentFragmentDoc}
${WineOfferHistoryFragmentDoc}
${WineOfferBuyerInfoFragmentDoc}`;

/**
 * __useWineOfferQuery__
 *
 * To run a query within a React component, call `useWineOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineOfferQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      companyId: // value for 'companyId'
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useWineOfferQuery(baseOptions: Apollo.QueryHookOptions<Types.WineOfferQuery, Types.WineOfferQueryVariables> & ({ variables: Types.WineOfferQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineOfferQuery, Types.WineOfferQueryVariables>(WineOfferDocument, options);
      }
export function useWineOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineOfferQuery, Types.WineOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineOfferQuery, Types.WineOfferQueryVariables>(WineOfferDocument, options);
        }
export function useWineOfferSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineOfferQuery, Types.WineOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineOfferQuery, Types.WineOfferQueryVariables>(WineOfferDocument, options);
        }
export type WineOfferQueryHookResult = ReturnType<typeof useWineOfferQuery>;
export type WineOfferLazyQueryHookResult = ReturnType<typeof useWineOfferLazyQuery>;
export type WineOfferSuspenseQueryHookResult = ReturnType<typeof useWineOfferSuspenseQuery>;
export type WineOfferQueryResult = Apollo.QueryResult<Types.WineOfferQuery, Types.WineOfferQueryVariables>;
export const WineOfferAdminDocument = gql`
    query WineOfferAdmin($localeId: Int!, $companyId: ID, $offerId: Int!) {
  wineOffer(id: $offerId) {
    ... on WineOfferBase {
      ...wineOfferBase
    }
    ... on WineOfferPublic {
      ...wineOfferPublic
    }
    ... on WineOfferCompany {
      ...wineOfferCompany
    }
    ... on WineOfferPersonal {
      ...WineOfferPersonalFragment
      history {
        ...wineOfferHistory
      }
    }
    ... on WineOfferDeal {
      id
      shipmentCountry {
        id
        localizedName(localeId: $localeId)
      }
    }
  }
}
    ${WineOfferBaseFragmentDoc}
${WineOfferPublicFragmentDoc}
${WineOfferCompanyFragmentDoc}
${WineOfferPersonalFragmentFragmentDoc}
${WineOfferHistoryFragmentDoc}`;

/**
 * __useWineOfferAdminQuery__
 *
 * To run a query within a React component, call `useWineOfferAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineOfferAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineOfferAdminQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      companyId: // value for 'companyId'
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useWineOfferAdminQuery(baseOptions: Apollo.QueryHookOptions<Types.WineOfferAdminQuery, Types.WineOfferAdminQueryVariables> & ({ variables: Types.WineOfferAdminQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineOfferAdminQuery, Types.WineOfferAdminQueryVariables>(WineOfferAdminDocument, options);
      }
export function useWineOfferAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineOfferAdminQuery, Types.WineOfferAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineOfferAdminQuery, Types.WineOfferAdminQueryVariables>(WineOfferAdminDocument, options);
        }
export function useWineOfferAdminSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineOfferAdminQuery, Types.WineOfferAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineOfferAdminQuery, Types.WineOfferAdminQueryVariables>(WineOfferAdminDocument, options);
        }
export type WineOfferAdminQueryHookResult = ReturnType<typeof useWineOfferAdminQuery>;
export type WineOfferAdminLazyQueryHookResult = ReturnType<typeof useWineOfferAdminLazyQuery>;
export type WineOfferAdminSuspenseQueryHookResult = ReturnType<typeof useWineOfferAdminSuspenseQuery>;
export type WineOfferAdminQueryResult = Apollo.QueryResult<Types.WineOfferAdminQuery, Types.WineOfferAdminQueryVariables>;
export const LegalEntitiesAndBranchesForEditDocument = gql`
    query legalEntitiesAndBranchesForEdit($countryId: Int!, $identityNumber: String, $vat: String, $localeId: Int!) {
  legalEntitiesAndBranches(
    countryId: $countryId
    identityNumber: $identityNumber
    vat: $vat
  ) {
    records {
      id
      country {
        id
        identityNumberName
        branchNumberName
      }
      identityNumber
      branchNumber
      legalEntityForm {
        id
        shortName
        originName
      }
      companyCategory {
        id
        masterName
        localizedName(localeId: $localeId)
      }
      legalName
      legalAddress
      activityCode
      isActive
      isOfficialRegistry
      isHeadOffice
      closingDate
      vat
      canEdit
    }
  }
}
    `;

/**
 * __useLegalEntitiesAndBranchesForEditQuery__
 *
 * To run a query within a React component, call `useLegalEntitiesAndBranchesForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegalEntitiesAndBranchesForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegalEntitiesAndBranchesForEditQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *      identityNumber: // value for 'identityNumber'
 *      vat: // value for 'vat'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useLegalEntitiesAndBranchesForEditQuery(baseOptions: Apollo.QueryHookOptions<Types.LegalEntitiesAndBranchesForEditQuery, Types.LegalEntitiesAndBranchesForEditQueryVariables> & ({ variables: Types.LegalEntitiesAndBranchesForEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LegalEntitiesAndBranchesForEditQuery, Types.LegalEntitiesAndBranchesForEditQueryVariables>(LegalEntitiesAndBranchesForEditDocument, options);
      }
export function useLegalEntitiesAndBranchesForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LegalEntitiesAndBranchesForEditQuery, Types.LegalEntitiesAndBranchesForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LegalEntitiesAndBranchesForEditQuery, Types.LegalEntitiesAndBranchesForEditQueryVariables>(LegalEntitiesAndBranchesForEditDocument, options);
        }
export function useLegalEntitiesAndBranchesForEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LegalEntitiesAndBranchesForEditQuery, Types.LegalEntitiesAndBranchesForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LegalEntitiesAndBranchesForEditQuery, Types.LegalEntitiesAndBranchesForEditQueryVariables>(LegalEntitiesAndBranchesForEditDocument, options);
        }
export type LegalEntitiesAndBranchesForEditQueryHookResult = ReturnType<typeof useLegalEntitiesAndBranchesForEditQuery>;
export type LegalEntitiesAndBranchesForEditLazyQueryHookResult = ReturnType<typeof useLegalEntitiesAndBranchesForEditLazyQuery>;
export type LegalEntitiesAndBranchesForEditSuspenseQueryHookResult = ReturnType<typeof useLegalEntitiesAndBranchesForEditSuspenseQuery>;
export type LegalEntitiesAndBranchesForEditQueryResult = Apollo.QueryResult<Types.LegalEntitiesAndBranchesForEditQuery, Types.LegalEntitiesAndBranchesForEditQueryVariables>;
export const AdminTradeOfferByCompositeIdDocument = gql`
    query adminTradeOfferByCompositeId($productURL: URL!, $additionalId: ID) {
  admin {
    tradeOffers {
      tradeOfferByCompositeId(
        compositeId: {productURL: $productURL, additionalId: $additionalId}
      ) {
        id
      }
    }
  }
}
    `;

/**
 * __useAdminTradeOfferByCompositeIdQuery__
 *
 * To run a query within a React component, call `useAdminTradeOfferByCompositeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTradeOfferByCompositeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTradeOfferByCompositeIdQuery({
 *   variables: {
 *      productURL: // value for 'productURL'
 *      additionalId: // value for 'additionalId'
 *   },
 * });
 */
export function useAdminTradeOfferByCompositeIdQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminTradeOfferByCompositeIdQuery, Types.AdminTradeOfferByCompositeIdQueryVariables> & ({ variables: Types.AdminTradeOfferByCompositeIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminTradeOfferByCompositeIdQuery, Types.AdminTradeOfferByCompositeIdQueryVariables>(AdminTradeOfferByCompositeIdDocument, options);
      }
export function useAdminTradeOfferByCompositeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminTradeOfferByCompositeIdQuery, Types.AdminTradeOfferByCompositeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminTradeOfferByCompositeIdQuery, Types.AdminTradeOfferByCompositeIdQueryVariables>(AdminTradeOfferByCompositeIdDocument, options);
        }
export function useAdminTradeOfferByCompositeIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminTradeOfferByCompositeIdQuery, Types.AdminTradeOfferByCompositeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminTradeOfferByCompositeIdQuery, Types.AdminTradeOfferByCompositeIdQueryVariables>(AdminTradeOfferByCompositeIdDocument, options);
        }
export type AdminTradeOfferByCompositeIdQueryHookResult = ReturnType<typeof useAdminTradeOfferByCompositeIdQuery>;
export type AdminTradeOfferByCompositeIdLazyQueryHookResult = ReturnType<typeof useAdminTradeOfferByCompositeIdLazyQuery>;
export type AdminTradeOfferByCompositeIdSuspenseQueryHookResult = ReturnType<typeof useAdminTradeOfferByCompositeIdSuspenseQuery>;
export type AdminTradeOfferByCompositeIdQueryResult = Apollo.QueryResult<Types.AdminTradeOfferByCompositeIdQuery, Types.AdminTradeOfferByCompositeIdQueryVariables>;
export const WineLotsForTradeOfferLotDocument = gql`
    query wineLotsForTradeOfferLot($localeId: Int!) {
  wineLots: wineLotsForTradeOfferLot {
    id
    masterName
    bottleQuantity
    localizedName(localeId: $localeId)
    localizedShortName(localeId: $localeId)
  }
}
    `;

/**
 * __useWineLotsForTradeOfferLotQuery__
 *
 * To run a query within a React component, call `useWineLotsForTradeOfferLotQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineLotsForTradeOfferLotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineLotsForTradeOfferLotQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useWineLotsForTradeOfferLotQuery(baseOptions: Apollo.QueryHookOptions<Types.WineLotsForTradeOfferLotQuery, Types.WineLotsForTradeOfferLotQueryVariables> & ({ variables: Types.WineLotsForTradeOfferLotQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineLotsForTradeOfferLotQuery, Types.WineLotsForTradeOfferLotQueryVariables>(WineLotsForTradeOfferLotDocument, options);
      }
export function useWineLotsForTradeOfferLotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineLotsForTradeOfferLotQuery, Types.WineLotsForTradeOfferLotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineLotsForTradeOfferLotQuery, Types.WineLotsForTradeOfferLotQueryVariables>(WineLotsForTradeOfferLotDocument, options);
        }
export function useWineLotsForTradeOfferLotSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineLotsForTradeOfferLotQuery, Types.WineLotsForTradeOfferLotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineLotsForTradeOfferLotQuery, Types.WineLotsForTradeOfferLotQueryVariables>(WineLotsForTradeOfferLotDocument, options);
        }
export type WineLotsForTradeOfferLotQueryHookResult = ReturnType<typeof useWineLotsForTradeOfferLotQuery>;
export type WineLotsForTradeOfferLotLazyQueryHookResult = ReturnType<typeof useWineLotsForTradeOfferLotLazyQuery>;
export type WineLotsForTradeOfferLotSuspenseQueryHookResult = ReturnType<typeof useWineLotsForTradeOfferLotSuspenseQuery>;
export type WineLotsForTradeOfferLotQueryResult = Apollo.QueryResult<Types.WineLotsForTradeOfferLotQuery, Types.WineLotsForTradeOfferLotQueryVariables>;
export const AdminTradeOfferProductUrlUsedDocument = gql`
    query adminTradeOfferProductURLUsed($productURL: URL!) {
  admin {
    tradeOffers {
      tradeOfferProductURLUsed(productURL: $productURL) {
        isUsed
        isUnique
        tradeOffer {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useAdminTradeOfferProductUrlUsedQuery__
 *
 * To run a query within a React component, call `useAdminTradeOfferProductUrlUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTradeOfferProductUrlUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTradeOfferProductUrlUsedQuery({
 *   variables: {
 *      productURL: // value for 'productURL'
 *   },
 * });
 */
export function useAdminTradeOfferProductUrlUsedQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminTradeOfferProductUrlUsedQuery, Types.AdminTradeOfferProductUrlUsedQueryVariables> & ({ variables: Types.AdminTradeOfferProductUrlUsedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminTradeOfferProductUrlUsedQuery, Types.AdminTradeOfferProductUrlUsedQueryVariables>(AdminTradeOfferProductUrlUsedDocument, options);
      }
export function useAdminTradeOfferProductUrlUsedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminTradeOfferProductUrlUsedQuery, Types.AdminTradeOfferProductUrlUsedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminTradeOfferProductUrlUsedQuery, Types.AdminTradeOfferProductUrlUsedQueryVariables>(AdminTradeOfferProductUrlUsedDocument, options);
        }
export function useAdminTradeOfferProductUrlUsedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminTradeOfferProductUrlUsedQuery, Types.AdminTradeOfferProductUrlUsedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminTradeOfferProductUrlUsedQuery, Types.AdminTradeOfferProductUrlUsedQueryVariables>(AdminTradeOfferProductUrlUsedDocument, options);
        }
export type AdminTradeOfferProductUrlUsedQueryHookResult = ReturnType<typeof useAdminTradeOfferProductUrlUsedQuery>;
export type AdminTradeOfferProductUrlUsedLazyQueryHookResult = ReturnType<typeof useAdminTradeOfferProductUrlUsedLazyQuery>;
export type AdminTradeOfferProductUrlUsedSuspenseQueryHookResult = ReturnType<typeof useAdminTradeOfferProductUrlUsedSuspenseQuery>;
export type AdminTradeOfferProductUrlUsedQueryResult = Apollo.QueryResult<Types.AdminTradeOfferProductUrlUsedQuery, Types.AdminTradeOfferProductUrlUsedQueryVariables>;
export const AdminCreateTradeOfferDocument = gql`
    mutation adminCreateTradeOffer($input: AdminTradeOfferCreateInput!) {
  admin {
    tradeOffers {
      create(input: $input) {
        recordId
      }
    }
  }
}
    `;
export type AdminCreateTradeOfferMutationFn = Apollo.MutationFunction<Types.AdminCreateTradeOfferMutation, Types.AdminCreateTradeOfferMutationVariables>;

/**
 * __useAdminCreateTradeOfferMutation__
 *
 * To run a mutation, you first call `useAdminCreateTradeOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateTradeOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateTradeOfferMutation, { data, loading, error }] = useAdminCreateTradeOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateTradeOfferMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminCreateTradeOfferMutation, Types.AdminCreateTradeOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminCreateTradeOfferMutation, Types.AdminCreateTradeOfferMutationVariables>(AdminCreateTradeOfferDocument, options);
      }
export type AdminCreateTradeOfferMutationHookResult = ReturnType<typeof useAdminCreateTradeOfferMutation>;
export type AdminCreateTradeOfferMutationResult = Apollo.MutationResult<Types.AdminCreateTradeOfferMutation>;
export type AdminCreateTradeOfferMutationOptions = Apollo.BaseMutationOptions<Types.AdminCreateTradeOfferMutation, Types.AdminCreateTradeOfferMutationVariables>;
export const AdminTradeOfferByIdDocument = gql`
    query adminTradeOfferById($id: ID!, $localeId: Int!, $uniqueWineLot: Boolean! = false) {
  admin {
    tradeOffers {
      tradeOffer(id: $id) {
        product {
          ...searchModalProduct
        }
        productURL
        isProductURLUnique
        additionalId
        merchant {
          id
          name
          website
        }
        lotsPagination(page: 1, perPage: 50, uniqueWineLot: $uniqueWineLot) {
          items {
            createdAt
            id
            price
            tradeOffer {
              id
            }
            currency {
              id
              code
              codeNumber
              masterName
              isGlobal
              rate
              symbol
            }
            lot {
              id
              bottleQuantity
              localizedName(localeId: $localeId)
            }
            deliveryTime {
              id
              localizedName(localeId: $localeId)
            }
          }
        }
        reference {
          wineName
          wineDescription
          bottleSize {
            id
            volumeML
          }
          packageType {
            id
            isDefault
            localizedName(localeId: $localeId)
          }
        }
      }
    }
  }
}
    ${SearchModalProductFragmentDoc}`;

/**
 * __useAdminTradeOfferByIdQuery__
 *
 * To run a query within a React component, call `useAdminTradeOfferByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTradeOfferByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTradeOfferByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *      uniqueWineLot: // value for 'uniqueWineLot'
 *   },
 * });
 */
export function useAdminTradeOfferByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.AdminTradeOfferByIdQuery, Types.AdminTradeOfferByIdQueryVariables> & ({ variables: Types.AdminTradeOfferByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminTradeOfferByIdQuery, Types.AdminTradeOfferByIdQueryVariables>(AdminTradeOfferByIdDocument, options);
      }
export function useAdminTradeOfferByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminTradeOfferByIdQuery, Types.AdminTradeOfferByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminTradeOfferByIdQuery, Types.AdminTradeOfferByIdQueryVariables>(AdminTradeOfferByIdDocument, options);
        }
export function useAdminTradeOfferByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AdminTradeOfferByIdQuery, Types.AdminTradeOfferByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AdminTradeOfferByIdQuery, Types.AdminTradeOfferByIdQueryVariables>(AdminTradeOfferByIdDocument, options);
        }
export type AdminTradeOfferByIdQueryHookResult = ReturnType<typeof useAdminTradeOfferByIdQuery>;
export type AdminTradeOfferByIdLazyQueryHookResult = ReturnType<typeof useAdminTradeOfferByIdLazyQuery>;
export type AdminTradeOfferByIdSuspenseQueryHookResult = ReturnType<typeof useAdminTradeOfferByIdSuspenseQuery>;
export type AdminTradeOfferByIdQueryResult = Apollo.QueryResult<Types.AdminTradeOfferByIdQuery, Types.AdminTradeOfferByIdQueryVariables>;
export const AdminUpdateTradeOfferDocument = gql`
    mutation adminUpdateTradeOffer($input: AdminTradeOfferUpdateInput!) {
  admin {
    tradeOffers {
      update(input: $input) {
        recordId
      }
    }
  }
}
    `;
export type AdminUpdateTradeOfferMutationFn = Apollo.MutationFunction<Types.AdminUpdateTradeOfferMutation, Types.AdminUpdateTradeOfferMutationVariables>;

/**
 * __useAdminUpdateTradeOfferMutation__
 *
 * To run a mutation, you first call `useAdminUpdateTradeOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateTradeOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateTradeOfferMutation, { data, loading, error }] = useAdminUpdateTradeOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateTradeOfferMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminUpdateTradeOfferMutation, Types.AdminUpdateTradeOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminUpdateTradeOfferMutation, Types.AdminUpdateTradeOfferMutationVariables>(AdminUpdateTradeOfferDocument, options);
      }
export type AdminUpdateTradeOfferMutationHookResult = ReturnType<typeof useAdminUpdateTradeOfferMutation>;
export type AdminUpdateTradeOfferMutationResult = Apollo.MutationResult<Types.AdminUpdateTradeOfferMutation>;
export type AdminUpdateTradeOfferMutationOptions = Apollo.BaseMutationOptions<Types.AdminUpdateTradeOfferMutation, Types.AdminUpdateTradeOfferMutationVariables>;
export const AdminCreateTradeOfferLotsDocument = gql`
    mutation adminCreateTradeOfferLots($input: AdminTradeOfferCreateLotsInput!) {
  admin {
    tradeOffers {
      createLots(input: $input) {
        records {
          price
          currency {
            id
          }
          lot {
            id
          }
          deliveryTime {
            id
          }
        }
      }
    }
  }
}
    `;
export type AdminCreateTradeOfferLotsMutationFn = Apollo.MutationFunction<Types.AdminCreateTradeOfferLotsMutation, Types.AdminCreateTradeOfferLotsMutationVariables>;

/**
 * __useAdminCreateTradeOfferLotsMutation__
 *
 * To run a mutation, you first call `useAdminCreateTradeOfferLotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateTradeOfferLotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateTradeOfferLotsMutation, { data, loading, error }] = useAdminCreateTradeOfferLotsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateTradeOfferLotsMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminCreateTradeOfferLotsMutation, Types.AdminCreateTradeOfferLotsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminCreateTradeOfferLotsMutation, Types.AdminCreateTradeOfferLotsMutationVariables>(AdminCreateTradeOfferLotsDocument, options);
      }
export type AdminCreateTradeOfferLotsMutationHookResult = ReturnType<typeof useAdminCreateTradeOfferLotsMutation>;
export type AdminCreateTradeOfferLotsMutationResult = Apollo.MutationResult<Types.AdminCreateTradeOfferLotsMutation>;
export type AdminCreateTradeOfferLotsMutationOptions = Apollo.BaseMutationOptions<Types.AdminCreateTradeOfferLotsMutation, Types.AdminCreateTradeOfferLotsMutationVariables>;
export const RecognitionCreateProductExpandedDocument = gql`
    mutation recognitionCreateProductExpanded($input: ProductCreateExpandedInput!, $localeId: Int!) {
  productCreateExpanded(input: $input) {
    record {
      ...reviewSearchProduct
      acceptability
    }
  }
}
    ${ReviewSearchProductFragmentDoc}`;
export type RecognitionCreateProductExpandedMutationFn = Apollo.MutationFunction<Types.RecognitionCreateProductExpandedMutation, Types.RecognitionCreateProductExpandedMutationVariables>;

/**
 * __useRecognitionCreateProductExpandedMutation__
 *
 * To run a mutation, you first call `useRecognitionCreateProductExpandedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecognitionCreateProductExpandedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recognitionCreateProductExpandedMutation, { data, loading, error }] = useRecognitionCreateProductExpandedMutation({
 *   variables: {
 *      input: // value for 'input'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useRecognitionCreateProductExpandedMutation(baseOptions?: Apollo.MutationHookOptions<Types.RecognitionCreateProductExpandedMutation, Types.RecognitionCreateProductExpandedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RecognitionCreateProductExpandedMutation, Types.RecognitionCreateProductExpandedMutationVariables>(RecognitionCreateProductExpandedDocument, options);
      }
export type RecognitionCreateProductExpandedMutationHookResult = ReturnType<typeof useRecognitionCreateProductExpandedMutation>;
export type RecognitionCreateProductExpandedMutationResult = Apollo.MutationResult<Types.RecognitionCreateProductExpandedMutation>;
export type RecognitionCreateProductExpandedMutationOptions = Apollo.BaseMutationOptions<Types.RecognitionCreateProductExpandedMutation, Types.RecognitionCreateProductExpandedMutationVariables>;
export const RecognitionProductByExpandedCompositeIdDocument = gql`
    query recognitionProductByExpandedCompositeId($id: ProductExpandedCompositeIDInput!, $localeId: Int!) {
  productByExpandedCompositeId(compositeId: $id) {
    ...reviewSearchProduct
    acceptability
  }
}
    ${ReviewSearchProductFragmentDoc}`;

/**
 * __useRecognitionProductByExpandedCompositeIdQuery__
 *
 * To run a query within a React component, call `useRecognitionProductByExpandedCompositeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecognitionProductByExpandedCompositeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecognitionProductByExpandedCompositeIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useRecognitionProductByExpandedCompositeIdQuery(baseOptions: Apollo.QueryHookOptions<Types.RecognitionProductByExpandedCompositeIdQuery, Types.RecognitionProductByExpandedCompositeIdQueryVariables> & ({ variables: Types.RecognitionProductByExpandedCompositeIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RecognitionProductByExpandedCompositeIdQuery, Types.RecognitionProductByExpandedCompositeIdQueryVariables>(RecognitionProductByExpandedCompositeIdDocument, options);
      }
export function useRecognitionProductByExpandedCompositeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RecognitionProductByExpandedCompositeIdQuery, Types.RecognitionProductByExpandedCompositeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RecognitionProductByExpandedCompositeIdQuery, Types.RecognitionProductByExpandedCompositeIdQueryVariables>(RecognitionProductByExpandedCompositeIdDocument, options);
        }
export function useRecognitionProductByExpandedCompositeIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RecognitionProductByExpandedCompositeIdQuery, Types.RecognitionProductByExpandedCompositeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RecognitionProductByExpandedCompositeIdQuery, Types.RecognitionProductByExpandedCompositeIdQueryVariables>(RecognitionProductByExpandedCompositeIdDocument, options);
        }
export type RecognitionProductByExpandedCompositeIdQueryHookResult = ReturnType<typeof useRecognitionProductByExpandedCompositeIdQuery>;
export type RecognitionProductByExpandedCompositeIdLazyQueryHookResult = ReturnType<typeof useRecognitionProductByExpandedCompositeIdLazyQuery>;
export type RecognitionProductByExpandedCompositeIdSuspenseQueryHookResult = ReturnType<typeof useRecognitionProductByExpandedCompositeIdSuspenseQuery>;
export type RecognitionProductByExpandedCompositeIdQueryResult = Apollo.QueryResult<Types.RecognitionProductByExpandedCompositeIdQuery, Types.RecognitionProductByExpandedCompositeIdQueryVariables>;
export const TradeNameSearchForReviewDocument = gql`
    query tradeNameSearchForReview($query: String, $countryId: Int, $localeId: Int!) {
  tradeNameInstantSearch(
    query: $query
    filter: {countryId: $countryId}
    localeId: $localeId
  ) {
    id
    tradeName
  }
}
    `;

/**
 * __useTradeNameSearchForReviewQuery__
 *
 * To run a query within a React component, call `useTradeNameSearchForReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeNameSearchForReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeNameSearchForReviewQuery({
 *   variables: {
 *      query: // value for 'query'
 *      countryId: // value for 'countryId'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useTradeNameSearchForReviewQuery(baseOptions: Apollo.QueryHookOptions<Types.TradeNameSearchForReviewQuery, Types.TradeNameSearchForReviewQueryVariables> & ({ variables: Types.TradeNameSearchForReviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradeNameSearchForReviewQuery, Types.TradeNameSearchForReviewQueryVariables>(TradeNameSearchForReviewDocument, options);
      }
export function useTradeNameSearchForReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradeNameSearchForReviewQuery, Types.TradeNameSearchForReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradeNameSearchForReviewQuery, Types.TradeNameSearchForReviewQueryVariables>(TradeNameSearchForReviewDocument, options);
        }
export function useTradeNameSearchForReviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradeNameSearchForReviewQuery, Types.TradeNameSearchForReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradeNameSearchForReviewQuery, Types.TradeNameSearchForReviewQueryVariables>(TradeNameSearchForReviewDocument, options);
        }
export type TradeNameSearchForReviewQueryHookResult = ReturnType<typeof useTradeNameSearchForReviewQuery>;
export type TradeNameSearchForReviewLazyQueryHookResult = ReturnType<typeof useTradeNameSearchForReviewLazyQuery>;
export type TradeNameSearchForReviewSuspenseQueryHookResult = ReturnType<typeof useTradeNameSearchForReviewSuspenseQuery>;
export type TradeNameSearchForReviewQueryResult = Apollo.QueryResult<Types.TradeNameSearchForReviewQuery, Types.TradeNameSearchForReviewQueryVariables>;
export const SpecialClassificationsForReviewDocument = gql`
    query specialClassificationsForReview($wineTypeId: Int, $wineColorId: Int, $sweetnessLevelId: Int, $nationalGITypeId: Int, $geoIndicationId: [Int], $companyId: ID!) {
  specialClassifications(
    filter: {wineTypeId: $wineTypeId, wineColorId: $wineColorId, sweetnessLevelId: $sweetnessLevelId, nationalGITypeId: $nationalGITypeId, geoIndicationId: $geoIndicationId, tradeNameId: $companyId}
  ) {
    id
    name
  }
}
    `;

/**
 * __useSpecialClassificationsForReviewQuery__
 *
 * To run a query within a React component, call `useSpecialClassificationsForReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecialClassificationsForReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecialClassificationsForReviewQuery({
 *   variables: {
 *      wineTypeId: // value for 'wineTypeId'
 *      wineColorId: // value for 'wineColorId'
 *      sweetnessLevelId: // value for 'sweetnessLevelId'
 *      nationalGITypeId: // value for 'nationalGITypeId'
 *      geoIndicationId: // value for 'geoIndicationId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSpecialClassificationsForReviewQuery(baseOptions: Apollo.QueryHookOptions<Types.SpecialClassificationsForReviewQuery, Types.SpecialClassificationsForReviewQueryVariables> & ({ variables: Types.SpecialClassificationsForReviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SpecialClassificationsForReviewQuery, Types.SpecialClassificationsForReviewQueryVariables>(SpecialClassificationsForReviewDocument, options);
      }
export function useSpecialClassificationsForReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SpecialClassificationsForReviewQuery, Types.SpecialClassificationsForReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SpecialClassificationsForReviewQuery, Types.SpecialClassificationsForReviewQueryVariables>(SpecialClassificationsForReviewDocument, options);
        }
export function useSpecialClassificationsForReviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SpecialClassificationsForReviewQuery, Types.SpecialClassificationsForReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SpecialClassificationsForReviewQuery, Types.SpecialClassificationsForReviewQueryVariables>(SpecialClassificationsForReviewDocument, options);
        }
export type SpecialClassificationsForReviewQueryHookResult = ReturnType<typeof useSpecialClassificationsForReviewQuery>;
export type SpecialClassificationsForReviewLazyQueryHookResult = ReturnType<typeof useSpecialClassificationsForReviewLazyQuery>;
export type SpecialClassificationsForReviewSuspenseQueryHookResult = ReturnType<typeof useSpecialClassificationsForReviewSuspenseQuery>;
export type SpecialClassificationsForReviewQueryResult = Apollo.QueryResult<Types.SpecialClassificationsForReviewQuery, Types.SpecialClassificationsForReviewQueryVariables>;
export const SweetnessForReviewDocument = gql`
    query sweetnessForReview($localeId: Int!, $countryId: Int!, $wineTypeId: Int!) {
  country(id: $countryId) {
    id
    sweetnessLevels(wineTypeId: $wineTypeId) {
      id
      isDefault
      localizedName(localeId: $localeId)
    }
  }
}
    `;

/**
 * __useSweetnessForReviewQuery__
 *
 * To run a query within a React component, call `useSweetnessForReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSweetnessForReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSweetnessForReviewQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      countryId: // value for 'countryId'
 *      wineTypeId: // value for 'wineTypeId'
 *   },
 * });
 */
export function useSweetnessForReviewQuery(baseOptions: Apollo.QueryHookOptions<Types.SweetnessForReviewQuery, Types.SweetnessForReviewQueryVariables> & ({ variables: Types.SweetnessForReviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SweetnessForReviewQuery, Types.SweetnessForReviewQueryVariables>(SweetnessForReviewDocument, options);
      }
export function useSweetnessForReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SweetnessForReviewQuery, Types.SweetnessForReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SweetnessForReviewQuery, Types.SweetnessForReviewQueryVariables>(SweetnessForReviewDocument, options);
        }
export function useSweetnessForReviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SweetnessForReviewQuery, Types.SweetnessForReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SweetnessForReviewQuery, Types.SweetnessForReviewQueryVariables>(SweetnessForReviewDocument, options);
        }
export type SweetnessForReviewQueryHookResult = ReturnType<typeof useSweetnessForReviewQuery>;
export type SweetnessForReviewLazyQueryHookResult = ReturnType<typeof useSweetnessForReviewLazyQuery>;
export type SweetnessForReviewSuspenseQueryHookResult = ReturnType<typeof useSweetnessForReviewSuspenseQuery>;
export type SweetnessForReviewQueryResult = Apollo.QueryResult<Types.SweetnessForReviewQuery, Types.SweetnessForReviewQueryVariables>;
export const ReviewSearchProductsDocument = gql`
    query reviewSearchProducts($localeId: Int!, $query: String!, $limit: Int! = 7) {
  productsOrWinesSearch(localeId: $localeId, limit: $limit, query: $query) {
    items {
      ...reviewSearchProduct
      ...reviewSearchWine
    }
  }
}
    ${ReviewSearchProductFragmentDoc}
${ReviewSearchWineFragmentDoc}`;

/**
 * __useReviewSearchProductsQuery__
 *
 * To run a query within a React component, call `useReviewSearchProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewSearchProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewSearchProductsQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useReviewSearchProductsQuery(baseOptions: Apollo.QueryHookOptions<Types.ReviewSearchProductsQuery, Types.ReviewSearchProductsQueryVariables> & ({ variables: Types.ReviewSearchProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReviewSearchProductsQuery, Types.ReviewSearchProductsQueryVariables>(ReviewSearchProductsDocument, options);
      }
export function useReviewSearchProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReviewSearchProductsQuery, Types.ReviewSearchProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReviewSearchProductsQuery, Types.ReviewSearchProductsQueryVariables>(ReviewSearchProductsDocument, options);
        }
export function useReviewSearchProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ReviewSearchProductsQuery, Types.ReviewSearchProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReviewSearchProductsQuery, Types.ReviewSearchProductsQueryVariables>(ReviewSearchProductsDocument, options);
        }
export type ReviewSearchProductsQueryHookResult = ReturnType<typeof useReviewSearchProductsQuery>;
export type ReviewSearchProductsLazyQueryHookResult = ReturnType<typeof useReviewSearchProductsLazyQuery>;
export type ReviewSearchProductsSuspenseQueryHookResult = ReturnType<typeof useReviewSearchProductsSuspenseQuery>;
export type ReviewSearchProductsQueryResult = Apollo.QueryResult<Types.ReviewSearchProductsQuery, Types.ReviewSearchProductsQueryVariables>;
export const ProductForSearchByImageResultDocument = gql`
    query productForSearchByImageResult($slug: String!, $localeId: Int!) {
  productBySlug(slug: $slug) {
    id
    canOverrideFacts
    acceptability
    wine {
      id
      products {
        id
        acceptability
        bottleSize {
          id
          volumeML
        }
        packageType {
          id
          isDefault
          shortName
          localizedName(localeId: $localeId)
        }
        bottleImage {
          ...imageThumbnail128
        }
      }
      country {
        id
        localizedName(localeId: $localeId)
        codeISO
      }
      tradeNameBrand
      brand {
        name
        id
        localizedName(localeId: $localeId)
      }
      keyWords
      tradeName {
        id
        tradeName
        country {
          id
          localizedName(localeId: $localeId)
          codeISO
        }
      }
      wineType {
        id
        localizedName(localeId: $localeId)
      }
      wineColor {
        id
        localizedName(localeId: $localeId)
      }
      sweetnessLevel {
        id
        localizedName(localeId: $localeId)
      }
      specialClassifications {
        id
        name
      }
      vintage
      vintageList(onlyAcceptable: false) {
        id
        vintage
        acceptability
      }
      nationalGIType {
        id
        localizedName(localeId: $localeId)
        localizedShortName(localeId: $localeId)
      }
      nationalGITypeText
      geoIndicationText
      geoIndications {
        id
        localizedName(localeId: $localeId)
        wineRegionsEU {
          localizedName(localeId: $localeId)
        }
      }
    }
    bottleSize {
      id
      volumeML
    }
    packageType {
      id
      isDefault
      shortName
      localizedName(localeId: $localeId)
    }
    bottleImage {
      imageUrl
      ...imageThumbnail128
    }
    backLabelImage {
      imageUrl
      ...imageThumbnail128
    }
    frontLabelImage {
      imageUrl
      ...imageThumbnail128
    }
  }
}
    ${ImageThumbnail128FragmentDoc}`;

/**
 * __useProductForSearchByImageResultQuery__
 *
 * To run a query within a React component, call `useProductForSearchByImageResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductForSearchByImageResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductForSearchByImageResultQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useProductForSearchByImageResultQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductForSearchByImageResultQuery, Types.ProductForSearchByImageResultQueryVariables> & ({ variables: Types.ProductForSearchByImageResultQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductForSearchByImageResultQuery, Types.ProductForSearchByImageResultQueryVariables>(ProductForSearchByImageResultDocument, options);
      }
export function useProductForSearchByImageResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductForSearchByImageResultQuery, Types.ProductForSearchByImageResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductForSearchByImageResultQuery, Types.ProductForSearchByImageResultQueryVariables>(ProductForSearchByImageResultDocument, options);
        }
export function useProductForSearchByImageResultSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductForSearchByImageResultQuery, Types.ProductForSearchByImageResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductForSearchByImageResultQuery, Types.ProductForSearchByImageResultQueryVariables>(ProductForSearchByImageResultDocument, options);
        }
export type ProductForSearchByImageResultQueryHookResult = ReturnType<typeof useProductForSearchByImageResultQuery>;
export type ProductForSearchByImageResultLazyQueryHookResult = ReturnType<typeof useProductForSearchByImageResultLazyQuery>;
export type ProductForSearchByImageResultSuspenseQueryHookResult = ReturnType<typeof useProductForSearchByImageResultSuspenseQuery>;
export type ProductForSearchByImageResultQueryResult = Apollo.QueryResult<Types.ProductForSearchByImageResultQuery, Types.ProductForSearchByImageResultQueryVariables>;
export const ProductCreateExpandedDocument = gql`
    mutation productCreateExpanded($input: ProductCreateExpandedInput!, $localeId: Int!) {
  productCreateExpanded(input: $input) {
    recordId
    record {
      id
      canOverrideFacts
      wine {
        country {
          id
          localizedName(localeId: $localeId)
          codeISO
        }
        tradeNameBrand
        brand {
          name
          id
          localizedName(localeId: $localeId)
        }
        keyWords
        tradeName {
          id
          tradeName
        }
        wineType {
          id
          localizedName(localeId: $localeId)
        }
        wineColor {
          id
          localizedName(localeId: $localeId)
        }
        sweetnessLevel {
          id
          localizedName(localeId: $localeId)
        }
        specialClassifications {
          id
          name
        }
        vintage
        vintageList {
          id
          vintage
          acceptability
        }
        nationalGIType {
          id
          localizedName(localeId: $localeId)
          localizedShortName(localeId: $localeId)
        }
        nationalGITypeText
        geoIndicationText
        geoIndications {
          id
          localizedName(localeId: $localeId)
          wineRegionsEU {
            localizedName(localeId: $localeId)
          }
        }
      }
      bottleSize {
        id
        volumeML
      }
      packageType {
        id
        isDefault
        shortName
        localizedName(localeId: $localeId)
      }
      bottleImage {
        imageUrl
      }
      backLabelImage {
        imageUrl
      }
      frontLabelImage {
        imageUrl
      }
    }
  }
}
    `;
export type ProductCreateExpandedMutationFn = Apollo.MutationFunction<Types.ProductCreateExpandedMutation, Types.ProductCreateExpandedMutationVariables>;

/**
 * __useProductCreateExpandedMutation__
 *
 * To run a mutation, you first call `useProductCreateExpandedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductCreateExpandedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productCreateExpandedMutation, { data, loading, error }] = useProductCreateExpandedMutation({
 *   variables: {
 *      input: // value for 'input'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useProductCreateExpandedMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProductCreateExpandedMutation, Types.ProductCreateExpandedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductCreateExpandedMutation, Types.ProductCreateExpandedMutationVariables>(ProductCreateExpandedDocument, options);
      }
export type ProductCreateExpandedMutationHookResult = ReturnType<typeof useProductCreateExpandedMutation>;
export type ProductCreateExpandedMutationResult = Apollo.MutationResult<Types.ProductCreateExpandedMutation>;
export type ProductCreateExpandedMutationOptions = Apollo.BaseMutationOptions<Types.ProductCreateExpandedMutation, Types.ProductCreateExpandedMutationVariables>;
export const SearchProductForVintageDialogDocument = gql`
    query searchProductForVintageDialog($id: ProductCompositeIDInput!, $localeId: Int!) {
  productByCompositeId(compositeId: $id) {
    id
    acceptability
    canOverrideFacts
    wine {
      id
      country {
        id
        localizedName(localeId: $localeId)
        codeISO
      }
      tradeNameBrand
      brand {
        name
        id
        localizedName(localeId: $localeId)
      }
      keyWords
      tradeName {
        id
        tradeName
      }
      wineType {
        id
        localizedName(localeId: $localeId)
      }
      wineColor {
        id
        localizedName(localeId: $localeId)
      }
      sweetnessLevel {
        id
        localizedName(localeId: $localeId)
      }
      specialClassifications {
        id
        name
      }
      vintage
      vintageList {
        id
        vintage
        acceptability
      }
      nationalGIType {
        id
        localizedName(localeId: $localeId)
        localizedShortName(localeId: $localeId)
      }
      nationalGITypeText
      geoIndicationText
      geoIndications {
        id
        localizedName(localeId: $localeId)
        wineRegionsEU {
          localizedName(localeId: $localeId)
        }
      }
    }
    bottleSize {
      id
      volumeML
    }
    packageType {
      id
      isDefault
      shortName
      localizedName(localeId: $localeId)
    }
    bottleImage {
      imageUrl
    }
    backLabelImage {
      imageUrl
    }
    frontLabelImage {
      imageUrl
    }
  }
}
    `;

/**
 * __useSearchProductForVintageDialogQuery__
 *
 * To run a query within a React component, call `useSearchProductForVintageDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductForVintageDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductForVintageDialogQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useSearchProductForVintageDialogQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchProductForVintageDialogQuery, Types.SearchProductForVintageDialogQueryVariables> & ({ variables: Types.SearchProductForVintageDialogQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchProductForVintageDialogQuery, Types.SearchProductForVintageDialogQueryVariables>(SearchProductForVintageDialogDocument, options);
      }
export function useSearchProductForVintageDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchProductForVintageDialogQuery, Types.SearchProductForVintageDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchProductForVintageDialogQuery, Types.SearchProductForVintageDialogQueryVariables>(SearchProductForVintageDialogDocument, options);
        }
export function useSearchProductForVintageDialogSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SearchProductForVintageDialogQuery, Types.SearchProductForVintageDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SearchProductForVintageDialogQuery, Types.SearchProductForVintageDialogQueryVariables>(SearchProductForVintageDialogDocument, options);
        }
export type SearchProductForVintageDialogQueryHookResult = ReturnType<typeof useSearchProductForVintageDialogQuery>;
export type SearchProductForVintageDialogLazyQueryHookResult = ReturnType<typeof useSearchProductForVintageDialogLazyQuery>;
export type SearchProductForVintageDialogSuspenseQueryHookResult = ReturnType<typeof useSearchProductForVintageDialogSuspenseQuery>;
export type SearchProductForVintageDialogQueryResult = Apollo.QueryResult<Types.SearchProductForVintageDialogQuery, Types.SearchProductForVintageDialogQueryVariables>;
export const SearchWineForVintageDialogDocument = gql`
    query searchWineForVintageDialog($id: WineCompositeIDInput!) {
  wineByCompositeId(compositeId: $id) {
    id
    acceptability
    vintage
  }
}
    `;

/**
 * __useSearchWineForVintageDialogQuery__
 *
 * To run a query within a React component, call `useSearchWineForVintageDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchWineForVintageDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchWineForVintageDialogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSearchWineForVintageDialogQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchWineForVintageDialogQuery, Types.SearchWineForVintageDialogQueryVariables> & ({ variables: Types.SearchWineForVintageDialogQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchWineForVintageDialogQuery, Types.SearchWineForVintageDialogQueryVariables>(SearchWineForVintageDialogDocument, options);
      }
export function useSearchWineForVintageDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchWineForVintageDialogQuery, Types.SearchWineForVintageDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchWineForVintageDialogQuery, Types.SearchWineForVintageDialogQueryVariables>(SearchWineForVintageDialogDocument, options);
        }
export function useSearchWineForVintageDialogSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SearchWineForVintageDialogQuery, Types.SearchWineForVintageDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SearchWineForVintageDialogQuery, Types.SearchWineForVintageDialogQueryVariables>(SearchWineForVintageDialogDocument, options);
        }
export type SearchWineForVintageDialogQueryHookResult = ReturnType<typeof useSearchWineForVintageDialogQuery>;
export type SearchWineForVintageDialogLazyQueryHookResult = ReturnType<typeof useSearchWineForVintageDialogLazyQuery>;
export type SearchWineForVintageDialogSuspenseQueryHookResult = ReturnType<typeof useSearchWineForVintageDialogSuspenseQuery>;
export type SearchWineForVintageDialogQueryResult = Apollo.QueryResult<Types.SearchWineForVintageDialogQuery, Types.SearchWineForVintageDialogQueryVariables>;
export const ReviewForEditDocument = gql`
    query reviewForEdit($id: ID!, $localeId: Int!) {
  wineReview(id: $id) {
    id
    product {
      ...reviewSearchProduct
      bottleImageThumb: bottleImage {
        ...imageThumbnail64
      }
    }
    createdAt
    blindCode
    date
    place
    isBottleDefected
    score
    review
    price
    owner {
      ... on User {
        id
        role {
          id
          code
        }
      }
    }
    authorUser {
      id
      name
      lastName
      permissions {
        reviewsConsidered
      }
      avatar {
        ...imageThumbnail64
      }
      bio
      username
      status {
        code
        localizedName(localeId: $localeId)
      }
      facebook
      linkedin
      twitter
      website
      expertQualifications {
        qualification {
          localizedName(localeId: $localeId)
        }
        isVerified
        verification {
          status {
            code
            localizedName(localeId: $localeId)
          }
        }
      }
    }
    currency {
      id
      code
    }
    recognitionResult {
      ... on ProductRecognitionError {
        reason
      }
      ... on ProductRecognitionNeedMoreInfo {
        unknownAttributes
      }
      ... on ProductRecognised {
        bottleImage {
          id
        }
        frontLabelImage {
          id
        }
        backLabelImage {
          id
        }
      }
    }
    blockedAt
    address
    latitude
    longitude
    drinkingWindowFrom
    drinkingWindowTo
    bottleImage {
      ...imageThumbnail160
    }
    frontLabelImage {
      ...imageThumbnail160
    }
    backLabelImage {
      ...imageThumbnail160
    }
    images {
      ...imageThumbnail128
    }
    type
    isDraft
    foodPairs {
      id
      localizedName(localeId: $localeId)
    }
    scoreSystem {
      id
      minScore
      maxScore
    }
  }
}
    ${ReviewSearchProductFragmentDoc}
${ImageThumbnail64FragmentDoc}
${ImageThumbnail160FragmentDoc}
${ImageThumbnail128FragmentDoc}`;

/**
 * __useReviewForEditQuery__
 *
 * To run a query within a React component, call `useReviewForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewForEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useReviewForEditQuery(baseOptions: Apollo.QueryHookOptions<Types.ReviewForEditQuery, Types.ReviewForEditQueryVariables> & ({ variables: Types.ReviewForEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReviewForEditQuery, Types.ReviewForEditQueryVariables>(ReviewForEditDocument, options);
      }
export function useReviewForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReviewForEditQuery, Types.ReviewForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReviewForEditQuery, Types.ReviewForEditQueryVariables>(ReviewForEditDocument, options);
        }
export function useReviewForEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ReviewForEditQuery, Types.ReviewForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReviewForEditQuery, Types.ReviewForEditQueryVariables>(ReviewForEditDocument, options);
        }
export type ReviewForEditQueryHookResult = ReturnType<typeof useReviewForEditQuery>;
export type ReviewForEditLazyQueryHookResult = ReturnType<typeof useReviewForEditLazyQuery>;
export type ReviewForEditSuspenseQueryHookResult = ReturnType<typeof useReviewForEditSuspenseQuery>;
export type ReviewForEditQueryResult = Apollo.QueryResult<Types.ReviewForEditQuery, Types.ReviewForEditQueryVariables>;
export const ReviewViewDocument = gql`
    query reviewView($id: ID!, $localeId: Int!) {
  wineReview(id: $id) {
    id
    product {
      ...reviewSearchProduct
      bottleImageThumb: bottleImage {
        ...imageThumbnail64
      }
    }
    createdAt
    blindCode
    date
    place
    isBottleDefected
    score
    review
    price
    owner {
      ... on User {
        id
        role {
          id
          code
        }
      }
    }
    authorUser {
      id
      name
      lastName
      permissions {
        reviewsConsidered
      }
      avatar {
        ...imageThumbnail64
      }
      bio
      username
      status {
        code
        localizedName(localeId: $localeId)
      }
      facebook
      linkedin
      twitter
      website
      expertQualifications {
        qualification {
          localizedName(localeId: $localeId)
        }
        isVerified
        verification {
          status {
            code
            localizedName(localeId: $localeId)
          }
        }
      }
    }
    currency {
      id
      code
    }
    recognitionResult {
      ... on ProductRecognitionError {
        reason
      }
      ... on ProductRecognitionNeedMoreInfo {
        unknownAttributes
      }
      ... on ProductRecognised {
        bottleImage {
          id
        }
        frontLabelImage {
          id
        }
        backLabelImage {
          id
        }
      }
    }
    blockedAt
    address
    latitude
    longitude
    drinkingWindowFrom
    drinkingWindowTo
    bottleImage {
      ...imageThumbnail64
    }
    images {
      ...imageThumbnail128
    }
    type
    isDraft
    foodPairs {
      id
      localizedName(localeId: $localeId)
    }
    scoreSystem {
      id
      minScore
      maxScore
    }
  }
}
    ${ReviewSearchProductFragmentDoc}
${ImageThumbnail64FragmentDoc}
${ImageThumbnail128FragmentDoc}`;

/**
 * __useReviewViewQuery__
 *
 * To run a query within a React component, call `useReviewViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useReviewViewQuery(baseOptions: Apollo.QueryHookOptions<Types.ReviewViewQuery, Types.ReviewViewQueryVariables> & ({ variables: Types.ReviewViewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReviewViewQuery, Types.ReviewViewQueryVariables>(ReviewViewDocument, options);
      }
export function useReviewViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReviewViewQuery, Types.ReviewViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReviewViewQuery, Types.ReviewViewQueryVariables>(ReviewViewDocument, options);
        }
export function useReviewViewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ReviewViewQuery, Types.ReviewViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReviewViewQuery, Types.ReviewViewQueryVariables>(ReviewViewDocument, options);
        }
export type ReviewViewQueryHookResult = ReturnType<typeof useReviewViewQuery>;
export type ReviewViewLazyQueryHookResult = ReturnType<typeof useReviewViewLazyQuery>;
export type ReviewViewSuspenseQueryHookResult = ReturnType<typeof useReviewViewSuspenseQuery>;
export type ReviewViewQueryResult = Apollo.QueryResult<Types.ReviewViewQuery, Types.ReviewViewQueryVariables>;
export const AverageScoreByVintageDocument = gql`
    query averageScoreByVintage($localeId: Int!) {
  me {
    id
    wineReviewsAggregation {
      byVintageScore(localeId: $localeId) {
        ...averageScoreByVintageItem
      }
    }
  }
}
    ${AverageScoreByVintageItemFragmentDoc}`;

/**
 * __useAverageScoreByVintageQuery__
 *
 * To run a query within a React component, call `useAverageScoreByVintageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAverageScoreByVintageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAverageScoreByVintageQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useAverageScoreByVintageQuery(baseOptions: Apollo.QueryHookOptions<Types.AverageScoreByVintageQuery, Types.AverageScoreByVintageQueryVariables> & ({ variables: Types.AverageScoreByVintageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AverageScoreByVintageQuery, Types.AverageScoreByVintageQueryVariables>(AverageScoreByVintageDocument, options);
      }
export function useAverageScoreByVintageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AverageScoreByVintageQuery, Types.AverageScoreByVintageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AverageScoreByVintageQuery, Types.AverageScoreByVintageQueryVariables>(AverageScoreByVintageDocument, options);
        }
export function useAverageScoreByVintageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AverageScoreByVintageQuery, Types.AverageScoreByVintageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AverageScoreByVintageQuery, Types.AverageScoreByVintageQueryVariables>(AverageScoreByVintageDocument, options);
        }
export type AverageScoreByVintageQueryHookResult = ReturnType<typeof useAverageScoreByVintageQuery>;
export type AverageScoreByVintageLazyQueryHookResult = ReturnType<typeof useAverageScoreByVintageLazyQuery>;
export type AverageScoreByVintageSuspenseQueryHookResult = ReturnType<typeof useAverageScoreByVintageSuspenseQuery>;
export type AverageScoreByVintageQueryResult = Apollo.QueryResult<Types.AverageScoreByVintageQuery, Types.AverageScoreByVintageQueryVariables>;
export const GeoIndicationsByCountriesDocument = gql`
    query geoIndicationsByCountries($localeId: Int!) {
  me {
    wineReviewsAggregation {
      byCountryGeoIndication(localeId: $localeId, limit: 10) {
        ...geoIndicationsByCountriesItem
      }
    }
  }
}
    ${GeoIndicationsByCountriesItemFragmentDoc}`;

/**
 * __useGeoIndicationsByCountriesQuery__
 *
 * To run a query within a React component, call `useGeoIndicationsByCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeoIndicationsByCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeoIndicationsByCountriesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useGeoIndicationsByCountriesQuery(baseOptions: Apollo.QueryHookOptions<Types.GeoIndicationsByCountriesQuery, Types.GeoIndicationsByCountriesQueryVariables> & ({ variables: Types.GeoIndicationsByCountriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GeoIndicationsByCountriesQuery, Types.GeoIndicationsByCountriesQueryVariables>(GeoIndicationsByCountriesDocument, options);
      }
export function useGeoIndicationsByCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GeoIndicationsByCountriesQuery, Types.GeoIndicationsByCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GeoIndicationsByCountriesQuery, Types.GeoIndicationsByCountriesQueryVariables>(GeoIndicationsByCountriesDocument, options);
        }
export function useGeoIndicationsByCountriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GeoIndicationsByCountriesQuery, Types.GeoIndicationsByCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GeoIndicationsByCountriesQuery, Types.GeoIndicationsByCountriesQueryVariables>(GeoIndicationsByCountriesDocument, options);
        }
export type GeoIndicationsByCountriesQueryHookResult = ReturnType<typeof useGeoIndicationsByCountriesQuery>;
export type GeoIndicationsByCountriesLazyQueryHookResult = ReturnType<typeof useGeoIndicationsByCountriesLazyQuery>;
export type GeoIndicationsByCountriesSuspenseQueryHookResult = ReturnType<typeof useGeoIndicationsByCountriesSuspenseQuery>;
export type GeoIndicationsByCountriesQueryResult = Apollo.QueryResult<Types.GeoIndicationsByCountriesQuery, Types.GeoIndicationsByCountriesQueryVariables>;
export const GrapesCoverageDocument = gql`
    query grapesCoverage {
  me {
    id
    wineReviewsAggregation {
      grapeVarietyMonocepageCoverage {
        ...grapesCoverageItem
      }
    }
  }
}
    ${GrapesCoverageItemFragmentDoc}`;

/**
 * __useGrapesCoverageQuery__
 *
 * To run a query within a React component, call `useGrapesCoverageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrapesCoverageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrapesCoverageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGrapesCoverageQuery(baseOptions?: Apollo.QueryHookOptions<Types.GrapesCoverageQuery, Types.GrapesCoverageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GrapesCoverageQuery, Types.GrapesCoverageQueryVariables>(GrapesCoverageDocument, options);
      }
export function useGrapesCoverageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GrapesCoverageQuery, Types.GrapesCoverageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GrapesCoverageQuery, Types.GrapesCoverageQueryVariables>(GrapesCoverageDocument, options);
        }
export function useGrapesCoverageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GrapesCoverageQuery, Types.GrapesCoverageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GrapesCoverageQuery, Types.GrapesCoverageQueryVariables>(GrapesCoverageDocument, options);
        }
export type GrapesCoverageQueryHookResult = ReturnType<typeof useGrapesCoverageQuery>;
export type GrapesCoverageLazyQueryHookResult = ReturnType<typeof useGrapesCoverageLazyQuery>;
export type GrapesCoverageSuspenseQueryHookResult = ReturnType<typeof useGrapesCoverageSuspenseQuery>;
export type GrapesCoverageQueryResult = Apollo.QueryResult<Types.GrapesCoverageQuery, Types.GrapesCoverageQueryVariables>;
export const MostReviewedGrapesDocument = gql`
    query mostReviewedGrapes($localeId: Int!) {
  me {
    wineReviewsAggregation {
      byGrapeVariety(localeId: $localeId, limit: 10) {
        ...mostReviewedGrapesItem
      }
    }
  }
}
    ${MostReviewedGrapesItemFragmentDoc}`;

/**
 * __useMostReviewedGrapesQuery__
 *
 * To run a query within a React component, call `useMostReviewedGrapesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMostReviewedGrapesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMostReviewedGrapesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useMostReviewedGrapesQuery(baseOptions: Apollo.QueryHookOptions<Types.MostReviewedGrapesQuery, Types.MostReviewedGrapesQueryVariables> & ({ variables: Types.MostReviewedGrapesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MostReviewedGrapesQuery, Types.MostReviewedGrapesQueryVariables>(MostReviewedGrapesDocument, options);
      }
export function useMostReviewedGrapesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MostReviewedGrapesQuery, Types.MostReviewedGrapesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MostReviewedGrapesQuery, Types.MostReviewedGrapesQueryVariables>(MostReviewedGrapesDocument, options);
        }
export function useMostReviewedGrapesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MostReviewedGrapesQuery, Types.MostReviewedGrapesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MostReviewedGrapesQuery, Types.MostReviewedGrapesQueryVariables>(MostReviewedGrapesDocument, options);
        }
export type MostReviewedGrapesQueryHookResult = ReturnType<typeof useMostReviewedGrapesQuery>;
export type MostReviewedGrapesLazyQueryHookResult = ReturnType<typeof useMostReviewedGrapesLazyQuery>;
export type MostReviewedGrapesSuspenseQueryHookResult = ReturnType<typeof useMostReviewedGrapesSuspenseQuery>;
export type MostReviewedGrapesQueryResult = Apollo.QueryResult<Types.MostReviewedGrapesQuery, Types.MostReviewedGrapesQueryVariables>;
export const ProducersCoverageByGeoIndicationDocument = gql`
    query producersCoverageByGeoIndication($localeId: Int!) {
  me {
    wineReviewsAggregation {
      byGeoIndicationTradeName(localeId: $localeId, limit: 10) {
        ...producersCoverageByGeoIndicationItem
      }
    }
  }
}
    ${ProducersCoverageByGeoIndicationItemFragmentDoc}`;

/**
 * __useProducersCoverageByGeoIndicationQuery__
 *
 * To run a query within a React component, call `useProducersCoverageByGeoIndicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useProducersCoverageByGeoIndicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProducersCoverageByGeoIndicationQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useProducersCoverageByGeoIndicationQuery(baseOptions: Apollo.QueryHookOptions<Types.ProducersCoverageByGeoIndicationQuery, Types.ProducersCoverageByGeoIndicationQueryVariables> & ({ variables: Types.ProducersCoverageByGeoIndicationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProducersCoverageByGeoIndicationQuery, Types.ProducersCoverageByGeoIndicationQueryVariables>(ProducersCoverageByGeoIndicationDocument, options);
      }
export function useProducersCoverageByGeoIndicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProducersCoverageByGeoIndicationQuery, Types.ProducersCoverageByGeoIndicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProducersCoverageByGeoIndicationQuery, Types.ProducersCoverageByGeoIndicationQueryVariables>(ProducersCoverageByGeoIndicationDocument, options);
        }
export function useProducersCoverageByGeoIndicationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProducersCoverageByGeoIndicationQuery, Types.ProducersCoverageByGeoIndicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProducersCoverageByGeoIndicationQuery, Types.ProducersCoverageByGeoIndicationQueryVariables>(ProducersCoverageByGeoIndicationDocument, options);
        }
export type ProducersCoverageByGeoIndicationQueryHookResult = ReturnType<typeof useProducersCoverageByGeoIndicationQuery>;
export type ProducersCoverageByGeoIndicationLazyQueryHookResult = ReturnType<typeof useProducersCoverageByGeoIndicationLazyQuery>;
export type ProducersCoverageByGeoIndicationSuspenseQueryHookResult = ReturnType<typeof useProducersCoverageByGeoIndicationSuspenseQuery>;
export type ProducersCoverageByGeoIndicationQueryResult = Apollo.QueryResult<Types.ProducersCoverageByGeoIndicationQuery, Types.ProducersCoverageByGeoIndicationQueryVariables>;
export const ReviewsByColorDocument = gql`
    query reviewsByColor($localeId: Int!) {
  me {
    id
    wineReviewsAggregation {
      byWineColor(localeId: $localeId) {
        ...reviewsByColorItem
      }
    }
  }
}
    ${ReviewsByColorItemFragmentDoc}`;

/**
 * __useReviewsByColorQuery__
 *
 * To run a query within a React component, call `useReviewsByColorQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsByColorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsByColorQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useReviewsByColorQuery(baseOptions: Apollo.QueryHookOptions<Types.ReviewsByColorQuery, Types.ReviewsByColorQueryVariables> & ({ variables: Types.ReviewsByColorQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReviewsByColorQuery, Types.ReviewsByColorQueryVariables>(ReviewsByColorDocument, options);
      }
export function useReviewsByColorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReviewsByColorQuery, Types.ReviewsByColorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReviewsByColorQuery, Types.ReviewsByColorQueryVariables>(ReviewsByColorDocument, options);
        }
export function useReviewsByColorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ReviewsByColorQuery, Types.ReviewsByColorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReviewsByColorQuery, Types.ReviewsByColorQueryVariables>(ReviewsByColorDocument, options);
        }
export type ReviewsByColorQueryHookResult = ReturnType<typeof useReviewsByColorQuery>;
export type ReviewsByColorLazyQueryHookResult = ReturnType<typeof useReviewsByColorLazyQuery>;
export type ReviewsByColorSuspenseQueryHookResult = ReturnType<typeof useReviewsByColorSuspenseQuery>;
export type ReviewsByColorQueryResult = Apollo.QueryResult<Types.ReviewsByColorQuery, Types.ReviewsByColorQueryVariables>;
export const ReviewsByCountryDocument = gql`
    query reviewsByCountry($localeId: Int!) {
  me {
    id
    wineReviewsAggregation {
      byCountry(localeId: $localeId) {
        ...reviewsByCountryItem
      }
    }
  }
}
    ${ReviewsByCountryItemFragmentDoc}`;

/**
 * __useReviewsByCountryQuery__
 *
 * To run a query within a React component, call `useReviewsByCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsByCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsByCountryQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useReviewsByCountryQuery(baseOptions: Apollo.QueryHookOptions<Types.ReviewsByCountryQuery, Types.ReviewsByCountryQueryVariables> & ({ variables: Types.ReviewsByCountryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReviewsByCountryQuery, Types.ReviewsByCountryQueryVariables>(ReviewsByCountryDocument, options);
      }
export function useReviewsByCountryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReviewsByCountryQuery, Types.ReviewsByCountryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReviewsByCountryQuery, Types.ReviewsByCountryQueryVariables>(ReviewsByCountryDocument, options);
        }
export function useReviewsByCountrySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ReviewsByCountryQuery, Types.ReviewsByCountryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReviewsByCountryQuery, Types.ReviewsByCountryQueryVariables>(ReviewsByCountryDocument, options);
        }
export type ReviewsByCountryQueryHookResult = ReturnType<typeof useReviewsByCountryQuery>;
export type ReviewsByCountryLazyQueryHookResult = ReturnType<typeof useReviewsByCountryLazyQuery>;
export type ReviewsByCountrySuspenseQueryHookResult = ReturnType<typeof useReviewsByCountrySuspenseQuery>;
export type ReviewsByCountryQueryResult = Apollo.QueryResult<Types.ReviewsByCountryQuery, Types.ReviewsByCountryQueryVariables>;
export const ReviewsByPriceDocument = gql`
    query reviewsByPrice($localeId: Int!) {
  me {
    id
    wineReviewsAggregation {
      byPriceRange(localeId: $localeId) {
        ...reviewsByPriceItem
      }
    }
  }
}
    ${ReviewsByPriceItemFragmentDoc}`;

/**
 * __useReviewsByPriceQuery__
 *
 * To run a query within a React component, call `useReviewsByPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsByPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsByPriceQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useReviewsByPriceQuery(baseOptions: Apollo.QueryHookOptions<Types.ReviewsByPriceQuery, Types.ReviewsByPriceQueryVariables> & ({ variables: Types.ReviewsByPriceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReviewsByPriceQuery, Types.ReviewsByPriceQueryVariables>(ReviewsByPriceDocument, options);
      }
export function useReviewsByPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReviewsByPriceQuery, Types.ReviewsByPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReviewsByPriceQuery, Types.ReviewsByPriceQueryVariables>(ReviewsByPriceDocument, options);
        }
export function useReviewsByPriceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ReviewsByPriceQuery, Types.ReviewsByPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReviewsByPriceQuery, Types.ReviewsByPriceQueryVariables>(ReviewsByPriceDocument, options);
        }
export type ReviewsByPriceQueryHookResult = ReturnType<typeof useReviewsByPriceQuery>;
export type ReviewsByPriceLazyQueryHookResult = ReturnType<typeof useReviewsByPriceLazyQuery>;
export type ReviewsByPriceSuspenseQueryHookResult = ReturnType<typeof useReviewsByPriceSuspenseQuery>;
export type ReviewsByPriceQueryResult = Apollo.QueryResult<Types.ReviewsByPriceQuery, Types.ReviewsByPriceQueryVariables>;
export const ReviewsBySweetnessDocument = gql`
    query reviewsBySweetness($localeId: Int!) {
  me {
    id
    wineReviewsAggregation {
      bySweetnessLevel(localeId: $localeId) {
        ...reviewsBySweetnessItem
      }
    }
  }
}
    ${ReviewsBySweetnessItemFragmentDoc}`;

/**
 * __useReviewsBySweetnessQuery__
 *
 * To run a query within a React component, call `useReviewsBySweetnessQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsBySweetnessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsBySweetnessQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useReviewsBySweetnessQuery(baseOptions: Apollo.QueryHookOptions<Types.ReviewsBySweetnessQuery, Types.ReviewsBySweetnessQueryVariables> & ({ variables: Types.ReviewsBySweetnessQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReviewsBySweetnessQuery, Types.ReviewsBySweetnessQueryVariables>(ReviewsBySweetnessDocument, options);
      }
export function useReviewsBySweetnessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReviewsBySweetnessQuery, Types.ReviewsBySweetnessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReviewsBySweetnessQuery, Types.ReviewsBySweetnessQueryVariables>(ReviewsBySweetnessDocument, options);
        }
export function useReviewsBySweetnessSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ReviewsBySweetnessQuery, Types.ReviewsBySweetnessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReviewsBySweetnessQuery, Types.ReviewsBySweetnessQueryVariables>(ReviewsBySweetnessDocument, options);
        }
export type ReviewsBySweetnessQueryHookResult = ReturnType<typeof useReviewsBySweetnessQuery>;
export type ReviewsBySweetnessLazyQueryHookResult = ReturnType<typeof useReviewsBySweetnessLazyQuery>;
export type ReviewsBySweetnessSuspenseQueryHookResult = ReturnType<typeof useReviewsBySweetnessSuspenseQuery>;
export type ReviewsBySweetnessQueryResult = Apollo.QueryResult<Types.ReviewsBySweetnessQuery, Types.ReviewsBySweetnessQueryVariables>;
export const TopRatedGeoIndicationsDocument = gql`
    query topRatedGeoIndications($localeId: Int!) {
  me {
    wineReviewsAggregation {
      byGeoIndication(localeId: $localeId, limit: 10, sort: AVG_SCORE_DESC) {
        ...topRatedGeoIndicationsItem
      }
    }
  }
}
    ${TopRatedGeoIndicationsItemFragmentDoc}`;

/**
 * __useTopRatedGeoIndicationsQuery__
 *
 * To run a query within a React component, call `useTopRatedGeoIndicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopRatedGeoIndicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopRatedGeoIndicationsQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useTopRatedGeoIndicationsQuery(baseOptions: Apollo.QueryHookOptions<Types.TopRatedGeoIndicationsQuery, Types.TopRatedGeoIndicationsQueryVariables> & ({ variables: Types.TopRatedGeoIndicationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TopRatedGeoIndicationsQuery, Types.TopRatedGeoIndicationsQueryVariables>(TopRatedGeoIndicationsDocument, options);
      }
export function useTopRatedGeoIndicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TopRatedGeoIndicationsQuery, Types.TopRatedGeoIndicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TopRatedGeoIndicationsQuery, Types.TopRatedGeoIndicationsQueryVariables>(TopRatedGeoIndicationsDocument, options);
        }
export function useTopRatedGeoIndicationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TopRatedGeoIndicationsQuery, Types.TopRatedGeoIndicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TopRatedGeoIndicationsQuery, Types.TopRatedGeoIndicationsQueryVariables>(TopRatedGeoIndicationsDocument, options);
        }
export type TopRatedGeoIndicationsQueryHookResult = ReturnType<typeof useTopRatedGeoIndicationsQuery>;
export type TopRatedGeoIndicationsLazyQueryHookResult = ReturnType<typeof useTopRatedGeoIndicationsLazyQuery>;
export type TopRatedGeoIndicationsSuspenseQueryHookResult = ReturnType<typeof useTopRatedGeoIndicationsSuspenseQuery>;
export type TopRatedGeoIndicationsQueryResult = Apollo.QueryResult<Types.TopRatedGeoIndicationsQuery, Types.TopRatedGeoIndicationsQueryVariables>;
export const WineReviewsMostScoredDocument = gql`
    query wineReviewsMostScored($localeId: Int!, $lastMonthStart: DateTime, $lastMonthEnd: DateTime, $lastYearStart: DateTime, $lastYearEnd: DateTime) {
  me {
    id
    wineReviewsAggregation {
      lastMonth: mostScored(from: $lastMonthStart, to: $lastMonthEnd) {
        ...MostScoredReview
      }
      lastYear: mostScored(from: $lastYearStart, to: $lastYearEnd) {
        ...MostScoredReview
      }
      allTime: mostScored {
        ...MostScoredReview
      }
    }
  }
}
    ${MostScoredReviewFragmentDoc}`;

/**
 * __useWineReviewsMostScoredQuery__
 *
 * To run a query within a React component, call `useWineReviewsMostScoredQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineReviewsMostScoredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineReviewsMostScoredQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      lastMonthStart: // value for 'lastMonthStart'
 *      lastMonthEnd: // value for 'lastMonthEnd'
 *      lastYearStart: // value for 'lastYearStart'
 *      lastYearEnd: // value for 'lastYearEnd'
 *   },
 * });
 */
export function useWineReviewsMostScoredQuery(baseOptions: Apollo.QueryHookOptions<Types.WineReviewsMostScoredQuery, Types.WineReviewsMostScoredQueryVariables> & ({ variables: Types.WineReviewsMostScoredQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineReviewsMostScoredQuery, Types.WineReviewsMostScoredQueryVariables>(WineReviewsMostScoredDocument, options);
      }
export function useWineReviewsMostScoredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineReviewsMostScoredQuery, Types.WineReviewsMostScoredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineReviewsMostScoredQuery, Types.WineReviewsMostScoredQueryVariables>(WineReviewsMostScoredDocument, options);
        }
export function useWineReviewsMostScoredSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineReviewsMostScoredQuery, Types.WineReviewsMostScoredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineReviewsMostScoredQuery, Types.WineReviewsMostScoredQueryVariables>(WineReviewsMostScoredDocument, options);
        }
export type WineReviewsMostScoredQueryHookResult = ReturnType<typeof useWineReviewsMostScoredQuery>;
export type WineReviewsMostScoredLazyQueryHookResult = ReturnType<typeof useWineReviewsMostScoredLazyQuery>;
export type WineReviewsMostScoredSuspenseQueryHookResult = ReturnType<typeof useWineReviewsMostScoredSuspenseQuery>;
export type WineReviewsMostScoredQueryResult = Apollo.QueryResult<Types.WineReviewsMostScoredQuery, Types.WineReviewsMostScoredQueryVariables>;
export const TopReviewedGeoIndicationsDocument = gql`
    query topReviewedGeoIndications($localeId: Int!) {
  me {
    wineReviewsAggregation {
      byGeoIndication(localeId: $localeId, limit: 10, sort: COUNT_DESC) {
        ...topReviewedGeoIndicationsItem
      }
    }
  }
}
    ${TopReviewedGeoIndicationsItemFragmentDoc}`;

/**
 * __useTopReviewedGeoIndicationsQuery__
 *
 * To run a query within a React component, call `useTopReviewedGeoIndicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopReviewedGeoIndicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopReviewedGeoIndicationsQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useTopReviewedGeoIndicationsQuery(baseOptions: Apollo.QueryHookOptions<Types.TopReviewedGeoIndicationsQuery, Types.TopReviewedGeoIndicationsQueryVariables> & ({ variables: Types.TopReviewedGeoIndicationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TopReviewedGeoIndicationsQuery, Types.TopReviewedGeoIndicationsQueryVariables>(TopReviewedGeoIndicationsDocument, options);
      }
export function useTopReviewedGeoIndicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TopReviewedGeoIndicationsQuery, Types.TopReviewedGeoIndicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TopReviewedGeoIndicationsQuery, Types.TopReviewedGeoIndicationsQueryVariables>(TopReviewedGeoIndicationsDocument, options);
        }
export function useTopReviewedGeoIndicationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TopReviewedGeoIndicationsQuery, Types.TopReviewedGeoIndicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TopReviewedGeoIndicationsQuery, Types.TopReviewedGeoIndicationsQueryVariables>(TopReviewedGeoIndicationsDocument, options);
        }
export type TopReviewedGeoIndicationsQueryHookResult = ReturnType<typeof useTopReviewedGeoIndicationsQuery>;
export type TopReviewedGeoIndicationsLazyQueryHookResult = ReturnType<typeof useTopReviewedGeoIndicationsLazyQuery>;
export type TopReviewedGeoIndicationsSuspenseQueryHookResult = ReturnType<typeof useTopReviewedGeoIndicationsSuspenseQuery>;
export type TopReviewedGeoIndicationsQueryResult = Apollo.QueryResult<Types.TopReviewedGeoIndicationsQuery, Types.TopReviewedGeoIndicationsQueryVariables>;
export const AvgReviewScoreByUserDocument = gql`
    query avgReviewScoreByUser($lastMonthStart: DateTime!, $lastMonthEnd: DateTime!, $beforeLastMonthStart: DateTime!, $beforeLastMonthEnd: DateTime!, $lastYearStart: DateTime!, $lastYearEnd: DateTime!, $beforeLastYearStart: DateTime!, $beforeLastYearEnd: DateTime!) {
  me {
    wineReviewsAggregation {
      allTime: avgScore
      lastMonth: avgScore(from: $lastMonthStart, to: $lastMonthEnd)
      beforeLastMonth: avgScore(from: $beforeLastMonthStart, to: $beforeLastMonthEnd)
      lastYear: avgScore(from: $lastYearStart, to: $lastYearEnd)
      beforeLastYear: avgScore(from: $beforeLastYearStart, to: $beforeLastYearEnd)
    }
  }
}
    `;

/**
 * __useAvgReviewScoreByUserQuery__
 *
 * To run a query within a React component, call `useAvgReviewScoreByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvgReviewScoreByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvgReviewScoreByUserQuery({
 *   variables: {
 *      lastMonthStart: // value for 'lastMonthStart'
 *      lastMonthEnd: // value for 'lastMonthEnd'
 *      beforeLastMonthStart: // value for 'beforeLastMonthStart'
 *      beforeLastMonthEnd: // value for 'beforeLastMonthEnd'
 *      lastYearStart: // value for 'lastYearStart'
 *      lastYearEnd: // value for 'lastYearEnd'
 *      beforeLastYearStart: // value for 'beforeLastYearStart'
 *      beforeLastYearEnd: // value for 'beforeLastYearEnd'
 *   },
 * });
 */
export function useAvgReviewScoreByUserQuery(baseOptions: Apollo.QueryHookOptions<Types.AvgReviewScoreByUserQuery, Types.AvgReviewScoreByUserQueryVariables> & ({ variables: Types.AvgReviewScoreByUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AvgReviewScoreByUserQuery, Types.AvgReviewScoreByUserQueryVariables>(AvgReviewScoreByUserDocument, options);
      }
export function useAvgReviewScoreByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AvgReviewScoreByUserQuery, Types.AvgReviewScoreByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AvgReviewScoreByUserQuery, Types.AvgReviewScoreByUserQueryVariables>(AvgReviewScoreByUserDocument, options);
        }
export function useAvgReviewScoreByUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AvgReviewScoreByUserQuery, Types.AvgReviewScoreByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AvgReviewScoreByUserQuery, Types.AvgReviewScoreByUserQueryVariables>(AvgReviewScoreByUserDocument, options);
        }
export type AvgReviewScoreByUserQueryHookResult = ReturnType<typeof useAvgReviewScoreByUserQuery>;
export type AvgReviewScoreByUserLazyQueryHookResult = ReturnType<typeof useAvgReviewScoreByUserLazyQuery>;
export type AvgReviewScoreByUserSuspenseQueryHookResult = ReturnType<typeof useAvgReviewScoreByUserSuspenseQuery>;
export type AvgReviewScoreByUserQueryResult = Apollo.QueryResult<Types.AvgReviewScoreByUserQuery, Types.AvgReviewScoreByUserQueryVariables>;
export const TotalReviewsByUserDocument = gql`
    query totalReviewsByUser($lastMonthStart: DateTime!, $lastMonthEnd: DateTime!, $beforeLastMonthStart: DateTime!, $beforeLastMonthEnd: DateTime!, $lastYearStart: DateTime!, $lastYearEnd: DateTime!, $beforeLastYearStart: DateTime!, $beforeLastYearEnd: DateTime!) {
  me {
    wineReviewsAggregation {
      allTime: count
      lastMonth: count(from: $lastMonthStart, to: $lastMonthEnd)
      beforeLastMonth: count(from: $beforeLastMonthStart, to: $beforeLastMonthEnd)
      lastYear: count(from: $lastYearStart, to: $lastYearEnd)
      beforeLastYear: count(from: $beforeLastYearStart, to: $beforeLastYearEnd)
    }
  }
}
    `;

/**
 * __useTotalReviewsByUserQuery__
 *
 * To run a query within a React component, call `useTotalReviewsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalReviewsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalReviewsByUserQuery({
 *   variables: {
 *      lastMonthStart: // value for 'lastMonthStart'
 *      lastMonthEnd: // value for 'lastMonthEnd'
 *      beforeLastMonthStart: // value for 'beforeLastMonthStart'
 *      beforeLastMonthEnd: // value for 'beforeLastMonthEnd'
 *      lastYearStart: // value for 'lastYearStart'
 *      lastYearEnd: // value for 'lastYearEnd'
 *      beforeLastYearStart: // value for 'beforeLastYearStart'
 *      beforeLastYearEnd: // value for 'beforeLastYearEnd'
 *   },
 * });
 */
export function useTotalReviewsByUserQuery(baseOptions: Apollo.QueryHookOptions<Types.TotalReviewsByUserQuery, Types.TotalReviewsByUserQueryVariables> & ({ variables: Types.TotalReviewsByUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TotalReviewsByUserQuery, Types.TotalReviewsByUserQueryVariables>(TotalReviewsByUserDocument, options);
      }
export function useTotalReviewsByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TotalReviewsByUserQuery, Types.TotalReviewsByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TotalReviewsByUserQuery, Types.TotalReviewsByUserQueryVariables>(TotalReviewsByUserDocument, options);
        }
export function useTotalReviewsByUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TotalReviewsByUserQuery, Types.TotalReviewsByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TotalReviewsByUserQuery, Types.TotalReviewsByUserQueryVariables>(TotalReviewsByUserDocument, options);
        }
export type TotalReviewsByUserQueryHookResult = ReturnType<typeof useTotalReviewsByUserQuery>;
export type TotalReviewsByUserLazyQueryHookResult = ReturnType<typeof useTotalReviewsByUserLazyQuery>;
export type TotalReviewsByUserSuspenseQueryHookResult = ReturnType<typeof useTotalReviewsByUserSuspenseQuery>;
export type TotalReviewsByUserQueryResult = Apollo.QueryResult<Types.TotalReviewsByUserQuery, Types.TotalReviewsByUserQueryVariables>;
export const DeleteWineReviewDocument = gql`
    mutation deleteWineReview($id: ID!) {
  wineReviewDelete(id: $id) {
    recordId
  }
}
    `;
export type DeleteWineReviewMutationFn = Apollo.MutationFunction<Types.DeleteWineReviewMutation, Types.DeleteWineReviewMutationVariables>;

/**
 * __useDeleteWineReviewMutation__
 *
 * To run a mutation, you first call `useDeleteWineReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWineReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWineReviewMutation, { data, loading, error }] = useDeleteWineReviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWineReviewMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteWineReviewMutation, Types.DeleteWineReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteWineReviewMutation, Types.DeleteWineReviewMutationVariables>(DeleteWineReviewDocument, options);
      }
export type DeleteWineReviewMutationHookResult = ReturnType<typeof useDeleteWineReviewMutation>;
export type DeleteWineReviewMutationResult = Apollo.MutationResult<Types.DeleteWineReviewMutation>;
export type DeleteWineReviewMutationOptions = Apollo.BaseMutationOptions<Types.DeleteWineReviewMutation, Types.DeleteWineReviewMutationVariables>;
export const GetMyReviewsDocument = gql`
    query getMyReviews($localeId: Int!, $query: String, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: WineReviewSort! = REVIEW_DATE_ASC, $filter: WineReviewFilter) {
  wineReviewsContributedPagination(
    localeId: $localeId
    filter: $filter
    query: $query
    page: $page
    perPage: $perPage
    sort: $sort
  ) {
    pageInfo {
      totalItems
      totalPages
      page
      perPage
      hasNextPage
      hasPreviousPage
    }
    items {
      id
      review
      recognitionResult {
        ... on ProductRecognitionError {
          reason
        }
        ... on ProductRecognitionNeedMoreInfo {
          unknownAttributes
        }
      }
      product {
        id
        slug
        acceptability
        canOverrideFacts
        wine {
          tradeNameBrand
          id
          country {
            id
            localizedName(localeId: $localeId)
            codeISO
          }
          brand {
            name
            acceptability
            popularity
          }
          acceptability
          keyWords
          tradeName {
            acceptability
            tradeName
            country {
              id
              localizedName(localeId: $localeId)
              codeISO
            }
          }
          wineType {
            isDefault
            masterName
            localizedName(localeId: $localeId)
          }
          wineColor {
            localizedName(localeId: $localeId)
          }
          sweetnessLevel {
            masterName
            isDefault
            localizedName(localeId: $localeId)
          }
          specialClassifications {
            name
          }
          vintage
          nationalGIType {
            shortName
            localizedName(localeId: $localeId)
          }
          geoIndications {
            localizedName(localeId: $localeId)
            wineRegionsEU {
              localizedName(localeId: $localeId)
            }
          }
          rating
        }
        bottleSize {
          id
          volumeML
        }
        packageType {
          id
          isDefault
          shortName
          localizedName(localeId: $localeId)
        }
      }
      blindCode
      date
      isBottleDefected
      score
      scoreSystem {
        localizedName(localeId: $localeId)
        minScore
        maxScore
      }
      review
      price
      currency {
        id
        code
        masterName
      }
      latitude
      longitude
      drinkingWindowFrom
      drinkingWindowTo
      ...myReviewsImages
      isDraft
      type
      status
      authorUser {
        id
        name
      }
      owner {
        type: __typename
        ... on User {
          id
          role {
            id
            code
          }
        }
      }
      createdAt
      updatedAt
      blockedAt
      foodPairs {
        id
        localizedName(localeId: $localeId)
      }
    }
  }
}
    ${MyReviewsImagesFragmentDoc}`;

/**
 * __useGetMyReviewsQuery__
 *
 * To run a query within a React component, call `useGetMyReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyReviewsQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetMyReviewsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMyReviewsQuery, Types.GetMyReviewsQueryVariables> & ({ variables: Types.GetMyReviewsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMyReviewsQuery, Types.GetMyReviewsQueryVariables>(GetMyReviewsDocument, options);
      }
export function useGetMyReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMyReviewsQuery, Types.GetMyReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMyReviewsQuery, Types.GetMyReviewsQueryVariables>(GetMyReviewsDocument, options);
        }
export function useGetMyReviewsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetMyReviewsQuery, Types.GetMyReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetMyReviewsQuery, Types.GetMyReviewsQueryVariables>(GetMyReviewsDocument, options);
        }
export type GetMyReviewsQueryHookResult = ReturnType<typeof useGetMyReviewsQuery>;
export type GetMyReviewsLazyQueryHookResult = ReturnType<typeof useGetMyReviewsLazyQuery>;
export type GetMyReviewsSuspenseQueryHookResult = ReturnType<typeof useGetMyReviewsSuspenseQuery>;
export type GetMyReviewsQueryResult = Apollo.QueryResult<Types.GetMyReviewsQuery, Types.GetMyReviewsQueryVariables>;
export const SearchProductForBottleSizeDocument = gql`
    query searchProductForBottleSize($id: ProductCompositeIDInput!, $localeId: Int!) {
  productByCompositeId(compositeId: $id) {
    id
    acceptability
    canOverrideAcceptability
    canOverrideFacts
    verified
    shouldOverrideAcceptability
    bottleSize {
      id
      volumeML
    }
    packageType {
      id
      isDefault
      shortName
      localizedName(localeId: $localeId)
    }
  }
}
    `;

/**
 * __useSearchProductForBottleSizeQuery__
 *
 * To run a query within a React component, call `useSearchProductForBottleSizeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductForBottleSizeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductForBottleSizeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useSearchProductForBottleSizeQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchProductForBottleSizeQuery, Types.SearchProductForBottleSizeQueryVariables> & ({ variables: Types.SearchProductForBottleSizeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchProductForBottleSizeQuery, Types.SearchProductForBottleSizeQueryVariables>(SearchProductForBottleSizeDocument, options);
      }
export function useSearchProductForBottleSizeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchProductForBottleSizeQuery, Types.SearchProductForBottleSizeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchProductForBottleSizeQuery, Types.SearchProductForBottleSizeQueryVariables>(SearchProductForBottleSizeDocument, options);
        }
export function useSearchProductForBottleSizeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SearchProductForBottleSizeQuery, Types.SearchProductForBottleSizeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SearchProductForBottleSizeQuery, Types.SearchProductForBottleSizeQueryVariables>(SearchProductForBottleSizeDocument, options);
        }
export type SearchProductForBottleSizeQueryHookResult = ReturnType<typeof useSearchProductForBottleSizeQuery>;
export type SearchProductForBottleSizeLazyQueryHookResult = ReturnType<typeof useSearchProductForBottleSizeLazyQuery>;
export type SearchProductForBottleSizeSuspenseQueryHookResult = ReturnType<typeof useSearchProductForBottleSizeSuspenseQuery>;
export type SearchProductForBottleSizeQueryResult = Apollo.QueryResult<Types.SearchProductForBottleSizeQuery, Types.SearchProductForBottleSizeQueryVariables>;
export const WineProductsUpdateForOfferDocument = gql`
    mutation wineProductsUpdateForOffer($input: WineProductsUpdateInput!, $wineId: ID!, $localeId: Int!) {
  wineProductsUpdate(input: $input) {
    query {
      wine(id: $wineId) {
        id
        products {
          id
          acceptability
          shouldOverrideAcceptability
          canOverrideAcceptability
          verified
          bottleSize {
            id
            volumeML
          }
          packageType {
            id
            isDefault
            shortName
            localizedName(localeId: $localeId)
          }
        }
      }
    }
  }
}
    `;
export type WineProductsUpdateForOfferMutationFn = Apollo.MutationFunction<Types.WineProductsUpdateForOfferMutation, Types.WineProductsUpdateForOfferMutationVariables>;

/**
 * __useWineProductsUpdateForOfferMutation__
 *
 * To run a mutation, you first call `useWineProductsUpdateForOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineProductsUpdateForOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineProductsUpdateForOfferMutation, { data, loading, error }] = useWineProductsUpdateForOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *      wineId: // value for 'wineId'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useWineProductsUpdateForOfferMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineProductsUpdateForOfferMutation, Types.WineProductsUpdateForOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineProductsUpdateForOfferMutation, Types.WineProductsUpdateForOfferMutationVariables>(WineProductsUpdateForOfferDocument, options);
      }
export type WineProductsUpdateForOfferMutationHookResult = ReturnType<typeof useWineProductsUpdateForOfferMutation>;
export type WineProductsUpdateForOfferMutationResult = Apollo.MutationResult<Types.WineProductsUpdateForOfferMutation>;
export type WineProductsUpdateForOfferMutationOptions = Apollo.BaseMutationOptions<Types.WineProductsUpdateForOfferMutation, Types.WineProductsUpdateForOfferMutationVariables>;
export const CountryBoundsDocument = gql`
    query countryBounds($countryId: Int!) {
  country(id: $countryId) {
    id
    border {
      __typename
      ... on GeoJSONMultiPolygon {
        type
        coordinates
      }
    }
  }
}
    `;

/**
 * __useCountryBoundsQuery__
 *
 * To run a query within a React component, call `useCountryBoundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryBoundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryBoundsQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useCountryBoundsQuery(baseOptions: Apollo.QueryHookOptions<Types.CountryBoundsQuery, Types.CountryBoundsQueryVariables> & ({ variables: Types.CountryBoundsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountryBoundsQuery, Types.CountryBoundsQueryVariables>(CountryBoundsDocument, options);
      }
export function useCountryBoundsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountryBoundsQuery, Types.CountryBoundsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountryBoundsQuery, Types.CountryBoundsQueryVariables>(CountryBoundsDocument, options);
        }
export function useCountryBoundsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CountryBoundsQuery, Types.CountryBoundsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CountryBoundsQuery, Types.CountryBoundsQueryVariables>(CountryBoundsDocument, options);
        }
export type CountryBoundsQueryHookResult = ReturnType<typeof useCountryBoundsQuery>;
export type CountryBoundsLazyQueryHookResult = ReturnType<typeof useCountryBoundsLazyQuery>;
export type CountryBoundsSuspenseQueryHookResult = ReturnType<typeof useCountryBoundsSuspenseQuery>;
export type CountryBoundsQueryResult = Apollo.QueryResult<Types.CountryBoundsQuery, Types.CountryBoundsQueryVariables>;
export const UnitsForRadiusDocument = gql`
    query unitsForRadius($localeId: Int!) {
  unitGroupByName(masterName: "WineOffer.Radius") {
    units {
      id
      localizedName(localeId: $localeId)
    }
  }
}
    `;

/**
 * __useUnitsForRadiusQuery__
 *
 * To run a query within a React component, call `useUnitsForRadiusQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitsForRadiusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitsForRadiusQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useUnitsForRadiusQuery(baseOptions: Apollo.QueryHookOptions<Types.UnitsForRadiusQuery, Types.UnitsForRadiusQueryVariables> & ({ variables: Types.UnitsForRadiusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UnitsForRadiusQuery, Types.UnitsForRadiusQueryVariables>(UnitsForRadiusDocument, options);
      }
export function useUnitsForRadiusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UnitsForRadiusQuery, Types.UnitsForRadiusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UnitsForRadiusQuery, Types.UnitsForRadiusQueryVariables>(UnitsForRadiusDocument, options);
        }
export function useUnitsForRadiusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UnitsForRadiusQuery, Types.UnitsForRadiusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UnitsForRadiusQuery, Types.UnitsForRadiusQueryVariables>(UnitsForRadiusDocument, options);
        }
export type UnitsForRadiusQueryHookResult = ReturnType<typeof useUnitsForRadiusQuery>;
export type UnitsForRadiusLazyQueryHookResult = ReturnType<typeof useUnitsForRadiusLazyQuery>;
export type UnitsForRadiusSuspenseQueryHookResult = ReturnType<typeof useUnitsForRadiusSuspenseQuery>;
export type UnitsForRadiusQueryResult = Apollo.QueryResult<Types.UnitsForRadiusQuery, Types.UnitsForRadiusQueryVariables>;
export const CountriesForOfferDocument = gql`
    query countriesForOffer($localeId: Int!) {
  countries {
    id
    masterName
    codeISO
    identityNumberName
    identityNumberFormat
    localizedPostCodeTooltip(localeId: $localeId)
    postCodeName
    postCodeMask
    postCodeFormat
    localizedIdentityNumberTooltip(localeId: $localeId)
    localizedName(localeId: $localeId)
    tinMask
    localizedIdentityNumberFormatError(localeId: $localeId)
    vatFormat
    vatMask
    localizedVATFormatError(localeId: $localeId)
    localizedVATTooltip(localeId: $localeId)
  }
}
    `;

/**
 * __useCountriesForOfferQuery__
 *
 * To run a query within a React component, call `useCountriesForOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesForOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesForOfferQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useCountriesForOfferQuery(baseOptions: Apollo.QueryHookOptions<Types.CountriesForOfferQuery, Types.CountriesForOfferQueryVariables> & ({ variables: Types.CountriesForOfferQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountriesForOfferQuery, Types.CountriesForOfferQueryVariables>(CountriesForOfferDocument, options);
      }
export function useCountriesForOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountriesForOfferQuery, Types.CountriesForOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountriesForOfferQuery, Types.CountriesForOfferQueryVariables>(CountriesForOfferDocument, options);
        }
export function useCountriesForOfferSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CountriesForOfferQuery, Types.CountriesForOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CountriesForOfferQuery, Types.CountriesForOfferQueryVariables>(CountriesForOfferDocument, options);
        }
export type CountriesForOfferQueryHookResult = ReturnType<typeof useCountriesForOfferQuery>;
export type CountriesForOfferLazyQueryHookResult = ReturnType<typeof useCountriesForOfferLazyQuery>;
export type CountriesForOfferSuspenseQueryHookResult = ReturnType<typeof useCountriesForOfferSuspenseQuery>;
export type CountriesForOfferQueryResult = Apollo.QueryResult<Types.CountriesForOfferQuery, Types.CountriesForOfferQueryVariables>;
export const ExhibitionsForOfferDocument = gql`
    query exhibitionsForOffer {
  exhibitions {
    ...exhibitionInfo
  }
}
    ${ExhibitionInfoFragmentDoc}`;

/**
 * __useExhibitionsForOfferQuery__
 *
 * To run a query within a React component, call `useExhibitionsForOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useExhibitionsForOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExhibitionsForOfferQuery({
 *   variables: {
 *   },
 * });
 */
export function useExhibitionsForOfferQuery(baseOptions?: Apollo.QueryHookOptions<Types.ExhibitionsForOfferQuery, Types.ExhibitionsForOfferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExhibitionsForOfferQuery, Types.ExhibitionsForOfferQueryVariables>(ExhibitionsForOfferDocument, options);
      }
export function useExhibitionsForOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExhibitionsForOfferQuery, Types.ExhibitionsForOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExhibitionsForOfferQuery, Types.ExhibitionsForOfferQueryVariables>(ExhibitionsForOfferDocument, options);
        }
export function useExhibitionsForOfferSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ExhibitionsForOfferQuery, Types.ExhibitionsForOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ExhibitionsForOfferQuery, Types.ExhibitionsForOfferQueryVariables>(ExhibitionsForOfferDocument, options);
        }
export type ExhibitionsForOfferQueryHookResult = ReturnType<typeof useExhibitionsForOfferQuery>;
export type ExhibitionsForOfferLazyQueryHookResult = ReturnType<typeof useExhibitionsForOfferLazyQuery>;
export type ExhibitionsForOfferSuspenseQueryHookResult = ReturnType<typeof useExhibitionsForOfferSuspenseQuery>;
export type ExhibitionsForOfferQueryResult = Apollo.QueryResult<Types.ExhibitionsForOfferQuery, Types.ExhibitionsForOfferQueryVariables>;
export const CompaniesSellerPaginationDocument = gql`
    query companiesSellerPagination($query: String, $page: PositiveInt!, $perPage: PositiveInt!) {
  sellersPagination(query: $query, page: $page, perPage: $perPage) {
    items {
      id
      tradeName
      companyCategory {
        id
        masterName
      }
      email
      phone
    }
  }
}
    `;

/**
 * __useCompaniesSellerPaginationQuery__
 *
 * To run a query within a React component, call `useCompaniesSellerPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesSellerPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesSellerPaginationQuery({
 *   variables: {
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useCompaniesSellerPaginationQuery(baseOptions: Apollo.QueryHookOptions<Types.CompaniesSellerPaginationQuery, Types.CompaniesSellerPaginationQueryVariables> & ({ variables: Types.CompaniesSellerPaginationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompaniesSellerPaginationQuery, Types.CompaniesSellerPaginationQueryVariables>(CompaniesSellerPaginationDocument, options);
      }
export function useCompaniesSellerPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompaniesSellerPaginationQuery, Types.CompaniesSellerPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompaniesSellerPaginationQuery, Types.CompaniesSellerPaginationQueryVariables>(CompaniesSellerPaginationDocument, options);
        }
export function useCompaniesSellerPaginationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CompaniesSellerPaginationQuery, Types.CompaniesSellerPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompaniesSellerPaginationQuery, Types.CompaniesSellerPaginationQueryVariables>(CompaniesSellerPaginationDocument, options);
        }
export type CompaniesSellerPaginationQueryHookResult = ReturnType<typeof useCompaniesSellerPaginationQuery>;
export type CompaniesSellerPaginationLazyQueryHookResult = ReturnType<typeof useCompaniesSellerPaginationLazyQuery>;
export type CompaniesSellerPaginationSuspenseQueryHookResult = ReturnType<typeof useCompaniesSellerPaginationSuspenseQuery>;
export type CompaniesSellerPaginationQueryResult = Apollo.QueryResult<Types.CompaniesSellerPaginationQuery, Types.CompaniesSellerPaginationQueryVariables>;
export const OfferAdminGetCompanyWarehousesDocument = gql`
    query offerAdminGetCompanyWarehouses($query: String, $page: PositiveInt!, $perPage: PositiveInt!, $sort: WarehouseSort! = CREATED_AT_DESC, $companyId: ID!, $localeId: Int!) {
  admin {
    companies {
      company(id: $companyId) {
        warehousesPagination(
          localeId: $localeId
          page: $page
          perPage: $perPage
          sort: $sort
          query: $query
        ) {
          ...OfferWarehousesPaginationFragment
        }
      }
    }
  }
}
    ${OfferWarehousesPaginationFragmentFragmentDoc}`;

/**
 * __useOfferAdminGetCompanyWarehousesQuery__
 *
 * To run a query within a React component, call `useOfferAdminGetCompanyWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferAdminGetCompanyWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferAdminGetCompanyWarehousesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useOfferAdminGetCompanyWarehousesQuery(baseOptions: Apollo.QueryHookOptions<Types.OfferAdminGetCompanyWarehousesQuery, Types.OfferAdminGetCompanyWarehousesQueryVariables> & ({ variables: Types.OfferAdminGetCompanyWarehousesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OfferAdminGetCompanyWarehousesQuery, Types.OfferAdminGetCompanyWarehousesQueryVariables>(OfferAdminGetCompanyWarehousesDocument, options);
      }
export function useOfferAdminGetCompanyWarehousesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OfferAdminGetCompanyWarehousesQuery, Types.OfferAdminGetCompanyWarehousesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OfferAdminGetCompanyWarehousesQuery, Types.OfferAdminGetCompanyWarehousesQueryVariables>(OfferAdminGetCompanyWarehousesDocument, options);
        }
export function useOfferAdminGetCompanyWarehousesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.OfferAdminGetCompanyWarehousesQuery, Types.OfferAdminGetCompanyWarehousesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.OfferAdminGetCompanyWarehousesQuery, Types.OfferAdminGetCompanyWarehousesQueryVariables>(OfferAdminGetCompanyWarehousesDocument, options);
        }
export type OfferAdminGetCompanyWarehousesQueryHookResult = ReturnType<typeof useOfferAdminGetCompanyWarehousesQuery>;
export type OfferAdminGetCompanyWarehousesLazyQueryHookResult = ReturnType<typeof useOfferAdminGetCompanyWarehousesLazyQuery>;
export type OfferAdminGetCompanyWarehousesSuspenseQueryHookResult = ReturnType<typeof useOfferAdminGetCompanyWarehousesSuspenseQuery>;
export type OfferAdminGetCompanyWarehousesQueryResult = Apollo.QueryResult<Types.OfferAdminGetCompanyWarehousesQuery, Types.OfferAdminGetCompanyWarehousesQueryVariables>;
export const OfferGetCompanyWarehousesDocument = gql`
    query offerGetCompanyWarehouses($companyId: ID!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 30, $sort: WarehouseSort! = CREATED_AT_DESC, $query: String) {
  me {
    id
    company(id: $companyId) {
      id
      warehousesPagination(
        localeId: $localeId
        page: $page
        perPage: $perPage
        sort: $sort
        query: $query
      ) {
        ...OfferWarehousesPaginationFragment
      }
    }
  }
}
    ${OfferWarehousesPaginationFragmentFragmentDoc}`;

/**
 * __useOfferGetCompanyWarehousesQuery__
 *
 * To run a query within a React component, call `useOfferGetCompanyWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferGetCompanyWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferGetCompanyWarehousesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useOfferGetCompanyWarehousesQuery(baseOptions: Apollo.QueryHookOptions<Types.OfferGetCompanyWarehousesQuery, Types.OfferGetCompanyWarehousesQueryVariables> & ({ variables: Types.OfferGetCompanyWarehousesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OfferGetCompanyWarehousesQuery, Types.OfferGetCompanyWarehousesQueryVariables>(OfferGetCompanyWarehousesDocument, options);
      }
export function useOfferGetCompanyWarehousesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OfferGetCompanyWarehousesQuery, Types.OfferGetCompanyWarehousesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OfferGetCompanyWarehousesQuery, Types.OfferGetCompanyWarehousesQueryVariables>(OfferGetCompanyWarehousesDocument, options);
        }
export function useOfferGetCompanyWarehousesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.OfferGetCompanyWarehousesQuery, Types.OfferGetCompanyWarehousesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.OfferGetCompanyWarehousesQuery, Types.OfferGetCompanyWarehousesQueryVariables>(OfferGetCompanyWarehousesDocument, options);
        }
export type OfferGetCompanyWarehousesQueryHookResult = ReturnType<typeof useOfferGetCompanyWarehousesQuery>;
export type OfferGetCompanyWarehousesLazyQueryHookResult = ReturnType<typeof useOfferGetCompanyWarehousesLazyQuery>;
export type OfferGetCompanyWarehousesSuspenseQueryHookResult = ReturnType<typeof useOfferGetCompanyWarehousesSuspenseQuery>;
export type OfferGetCompanyWarehousesQueryResult = Apollo.QueryResult<Types.OfferGetCompanyWarehousesQuery, Types.OfferGetCompanyWarehousesQueryVariables>;
export const WineOffersRequestBuyerContactsDocument = gql`
    mutation wineOffersRequestBuyerContacts($companyId: ID!, $wineOfferIds: [Int!]!) {
  wineOffersRequestBuyerContacts(
    input: {companyId: $companyId, wineOfferIds: $wineOfferIds}
  ) {
    records {
      id
      ... on WineOfferPersonal {
        buyerContactsRequested
      }
      ... on WineOfferCompany {
        buyerContactsRequested
      }
    }
  }
}
    `;
export type WineOffersRequestBuyerContactsMutationFn = Apollo.MutationFunction<Types.WineOffersRequestBuyerContactsMutation, Types.WineOffersRequestBuyerContactsMutationVariables>;

/**
 * __useWineOffersRequestBuyerContactsMutation__
 *
 * To run a mutation, you first call `useWineOffersRequestBuyerContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOffersRequestBuyerContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOffersRequestBuyerContactsMutation, { data, loading, error }] = useWineOffersRequestBuyerContactsMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      wineOfferIds: // value for 'wineOfferIds'
 *   },
 * });
 */
export function useWineOffersRequestBuyerContactsMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOffersRequestBuyerContactsMutation, Types.WineOffersRequestBuyerContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOffersRequestBuyerContactsMutation, Types.WineOffersRequestBuyerContactsMutationVariables>(WineOffersRequestBuyerContactsDocument, options);
      }
export type WineOffersRequestBuyerContactsMutationHookResult = ReturnType<typeof useWineOffersRequestBuyerContactsMutation>;
export type WineOffersRequestBuyerContactsMutationResult = Apollo.MutationResult<Types.WineOffersRequestBuyerContactsMutation>;
export type WineOffersRequestBuyerContactsMutationOptions = Apollo.BaseMutationOptions<Types.WineOffersRequestBuyerContactsMutation, Types.WineOffersRequestBuyerContactsMutationVariables>;
export const SelectedCompanyContactsDocument = gql`
    query selectedCompanyContacts($id: ID!) {
  me {
    company(id: $id) {
      id
      phone
      email
    }
  }
}
    `;

/**
 * __useSelectedCompanyContactsQuery__
 *
 * To run a query within a React component, call `useSelectedCompanyContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedCompanyContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedCompanyContactsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSelectedCompanyContactsQuery(baseOptions: Apollo.QueryHookOptions<Types.SelectedCompanyContactsQuery, Types.SelectedCompanyContactsQueryVariables> & ({ variables: Types.SelectedCompanyContactsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SelectedCompanyContactsQuery, Types.SelectedCompanyContactsQueryVariables>(SelectedCompanyContactsDocument, options);
      }
export function useSelectedCompanyContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SelectedCompanyContactsQuery, Types.SelectedCompanyContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SelectedCompanyContactsQuery, Types.SelectedCompanyContactsQueryVariables>(SelectedCompanyContactsDocument, options);
        }
export function useSelectedCompanyContactsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SelectedCompanyContactsQuery, Types.SelectedCompanyContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SelectedCompanyContactsQuery, Types.SelectedCompanyContactsQueryVariables>(SelectedCompanyContactsDocument, options);
        }
export type SelectedCompanyContactsQueryHookResult = ReturnType<typeof useSelectedCompanyContactsQuery>;
export type SelectedCompanyContactsLazyQueryHookResult = ReturnType<typeof useSelectedCompanyContactsLazyQuery>;
export type SelectedCompanyContactsSuspenseQueryHookResult = ReturnType<typeof useSelectedCompanyContactsSuspenseQuery>;
export type SelectedCompanyContactsQueryResult = Apollo.QueryResult<Types.SelectedCompanyContactsQuery, Types.SelectedCompanyContactsQueryVariables>;
export const PackagesListForOfferDocument = gql`
    query packagesListForOffer($id: ID!, $localeId: Int!) {
  wine(id: $id) {
    id
    products {
      id
      productId: id
      acceptability
      shouldOverrideAcceptability
      canOverrideAcceptability
      verified
      bottleSize {
        id
        volumeML
      }
      packageType {
        id
        isDefault
        shortName
        localizedName(localeId: $localeId)
      }
      bottleImage {
        imageUrl
        ...imageThumbnail48
      }
    }
  }
}
    ${ImageThumbnail48FragmentDoc}`;

/**
 * __usePackagesListForOfferQuery__
 *
 * To run a query within a React component, call `usePackagesListForOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagesListForOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagesListForOfferQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function usePackagesListForOfferQuery(baseOptions: Apollo.QueryHookOptions<Types.PackagesListForOfferQuery, Types.PackagesListForOfferQueryVariables> & ({ variables: Types.PackagesListForOfferQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PackagesListForOfferQuery, Types.PackagesListForOfferQueryVariables>(PackagesListForOfferDocument, options);
      }
export function usePackagesListForOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PackagesListForOfferQuery, Types.PackagesListForOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PackagesListForOfferQuery, Types.PackagesListForOfferQueryVariables>(PackagesListForOfferDocument, options);
        }
export function usePackagesListForOfferSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PackagesListForOfferQuery, Types.PackagesListForOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PackagesListForOfferQuery, Types.PackagesListForOfferQueryVariables>(PackagesListForOfferDocument, options);
        }
export type PackagesListForOfferQueryHookResult = ReturnType<typeof usePackagesListForOfferQuery>;
export type PackagesListForOfferLazyQueryHookResult = ReturnType<typeof usePackagesListForOfferLazyQuery>;
export type PackagesListForOfferSuspenseQueryHookResult = ReturnType<typeof usePackagesListForOfferSuspenseQuery>;
export type PackagesListForOfferQueryResult = Apollo.QueryResult<Types.PackagesListForOfferQuery, Types.PackagesListForOfferQueryVariables>;
export const AdminWineOffersCompanyCreateDocument = gql`
    mutation adminWineOffersCompanyCreate($input: AdminWineOffersCompanyCreateInput!) {
  admin {
    wineOffers {
      wineOffersCompanyCreate(input: $input) {
        records {
          id
        }
      }
    }
  }
}
    `;
export type AdminWineOffersCompanyCreateMutationFn = Apollo.MutationFunction<Types.AdminWineOffersCompanyCreateMutation, Types.AdminWineOffersCompanyCreateMutationVariables>;

/**
 * __useAdminWineOffersCompanyCreateMutation__
 *
 * To run a mutation, you first call `useAdminWineOffersCompanyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminWineOffersCompanyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminWineOffersCompanyCreateMutation, { data, loading, error }] = useAdminWineOffersCompanyCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminWineOffersCompanyCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminWineOffersCompanyCreateMutation, Types.AdminWineOffersCompanyCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminWineOffersCompanyCreateMutation, Types.AdminWineOffersCompanyCreateMutationVariables>(AdminWineOffersCompanyCreateDocument, options);
      }
export type AdminWineOffersCompanyCreateMutationHookResult = ReturnType<typeof useAdminWineOffersCompanyCreateMutation>;
export type AdminWineOffersCompanyCreateMutationResult = Apollo.MutationResult<Types.AdminWineOffersCompanyCreateMutation>;
export type AdminWineOffersCompanyCreateMutationOptions = Apollo.BaseMutationOptions<Types.AdminWineOffersCompanyCreateMutation, Types.AdminWineOffersCompanyCreateMutationVariables>;
export const AdminWineOffersPublicCreateDocument = gql`
    mutation adminWineOffersPublicCreate($input: AdminWineOffersPublicCreateInput!) {
  admin {
    wineOffers {
      wineOffersPublicCreate(input: $input) {
        records {
          id
        }
      }
    }
  }
}
    `;
export type AdminWineOffersPublicCreateMutationFn = Apollo.MutationFunction<Types.AdminWineOffersPublicCreateMutation, Types.AdminWineOffersPublicCreateMutationVariables>;

/**
 * __useAdminWineOffersPublicCreateMutation__
 *
 * To run a mutation, you first call `useAdminWineOffersPublicCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminWineOffersPublicCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminWineOffersPublicCreateMutation, { data, loading, error }] = useAdminWineOffersPublicCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminWineOffersPublicCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminWineOffersPublicCreateMutation, Types.AdminWineOffersPublicCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminWineOffersPublicCreateMutation, Types.AdminWineOffersPublicCreateMutationVariables>(AdminWineOffersPublicCreateDocument, options);
      }
export type AdminWineOffersPublicCreateMutationHookResult = ReturnType<typeof useAdminWineOffersPublicCreateMutation>;
export type AdminWineOffersPublicCreateMutationResult = Apollo.MutationResult<Types.AdminWineOffersPublicCreateMutation>;
export type AdminWineOffersPublicCreateMutationOptions = Apollo.BaseMutationOptions<Types.AdminWineOffersPublicCreateMutation, Types.AdminWineOffersPublicCreateMutationVariables>;
export const GetWineOfferForCopyDocument = gql`
    query getWineOfferForCopy($id: Int!, $localeId: Int!, $companyId: ID!) {
  wineOffer(id: $id) {
    ... on WineOfferPersonal {
      ...offerForCopyWineOfferPersonal
    }
    ... on WineOfferBase {
      id
      wineOfferDistLimits {
        id
        isIncluded
        address
        country {
          id
          localizedName(localeId: $localeId)
          codeISO
        }
        territory {
          latitude
          longitude
          radius
          radiusUnit {
            id
            localizedName(localeId: $localeId)
          }
        }
      }
      wineOfferType {
        id
        code
      }
      wineOfferStatus {
        id
        code
        localizedName(localeId: $localeId)
      }
      product {
        id
        bottleSize {
          id
          volumeML
        }
        packageType {
          id
          isDefault
          shortName
          localizedName(localeId: $localeId)
        }
        wine {
          id
          tradeNameBrand
          nationalGIType {
            localizedName(localeId: $localeId)
          }
          geoIndications {
            localizedName(localeId: $localeId)
            wineRegionsEU {
              localizedName(localeId: $localeId)
            }
          }
          vintage
          tradeName {
            tradeName
            country {
              localizedName(localeId: $localeId)
            }
          }
          wineType {
            localizedName(localeId: $localeId)
          }
          wineColor {
            localizedName(localeId: $localeId)
          }
          brand {
            name
          }
          sweetnessLevel {
            localizedName(localeId: $localeId)
          }
          keyWords
          specialClassifications {
            id
            name
          }
          products {
            id
            acceptability
            shouldOverrideAcceptability
            canOverrideAcceptability
            verified
            bottleSize {
              id
              volumeML
            }
            packageType {
              id
              isDefault
              shortName
              localizedName(localeId: $localeId)
            }
            bottleImage {
              imageUrl
              thumbnailUrl: imageUrl(transformations: {width: 48, height: 96, resize: fit})
              thumbnailRetinaUrl: imageUrl(
                transformations: {width: 96, height: 192, resize: fit}
              )
            }
          }
        }
        bottleImage {
          imageUrl
        }
      }
      price
      currency {
        id
        code
      }
      lot {
        id
        localizedName(localeId: $localeId)
        bottleQuantity
      }
      minimumDeliverySize
      periodOfValidity
      incoterms {
        id
        code
        localizedShortDescription(localeId: $localeId)
      }
      pricePerBottle
      warehouse {
        id
        name
        country {
          id
          codeISO
        }
      }
      isExclusiveRight
      exhibitions {
        ...exhibitionsDisplayInfo
      }
      lastSellerConditions {
        price
        currency {
          id
          code
        }
        deliverySize
        incoterms {
          id
          code
          localizedShortDescription(localeId: $localeId)
        }
        warehouse {
          id
          name
          country {
            id
            codeISO
          }
        }
      }
    }
    ... on WineOfferCompany {
      buyer(companyId: $companyId) {
        ... on Company {
          id
          tradeName
        }
        ... on HiddenCompany {
          id
        }
      }
      wineOfferDistLimits {
        ...SellWineFullWineOfferDist
      }
      seller: sellerCompany {
        ...offerForCopySeller
      }
      contactEmail
      contactPhone
    }
    ... on WineOfferPublic {
      buyerCompanyCategory {
        id
        localizedName(localeId: $localeId)
      }
      seller: sellerCompany {
        ...offerForCopySeller
      }
      wineOfferDistLimits {
        ...SellWineFullWineOfferDist
      }
      contactEmail
      contactPhone
    }
    ... on WineOfferPublicDraft {
      draftCompanyCategory: buyerCompanyCategory {
        id
        localizedName(localeId: $localeId)
      }
      wineOfferDistLimits {
        ...SellWineFullWineOfferDist
      }
      seller: sellerCompany {
        ...offerForCopySeller
      }
      contactEmail
      contactPhone
    }
    ... on WineOfferCompanyDraft {
      buyerDraft: buyer(companyId: $companyId) {
        ... on Company {
          id
          tradeName
        }
        ... on HiddenCompany {
          id
        }
      }
      wineOfferDistLimits {
        ...SellWineFullWineOfferDist
      }
      contactEmail
      contactPhone
      seller: sellerCompany {
        ...offerForCopySeller
      }
    }
    ... on WineOfferPersonal {
      isExclusiveRight
      buyerCompany: buyer(companyId: $companyId) {
        ... on Company {
          id
          tradeName
          companyCategory {
            localizedName(localeId: $localeId)
          }
        }
        ... on HiddenCompany {
          id
          companyCategory {
            localizedName(localeId: $localeId)
          }
        }
      }
      seller: sellerCompany {
        ...offerForCopySeller
      }
      contactEmail
      contactPhone
    }
  }
}
    ${OfferForCopyWineOfferPersonalFragmentDoc}
${ExhibitionsDisplayInfoFragmentDoc}
${SellWineFullWineOfferDistFragmentDoc}
${OfferForCopySellerFragmentDoc}`;

/**
 * __useGetWineOfferForCopyQuery__
 *
 * To run a query within a React component, call `useGetWineOfferForCopyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWineOfferForCopyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWineOfferForCopyQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetWineOfferForCopyQuery(baseOptions: Apollo.QueryHookOptions<Types.GetWineOfferForCopyQuery, Types.GetWineOfferForCopyQueryVariables> & ({ variables: Types.GetWineOfferForCopyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetWineOfferForCopyQuery, Types.GetWineOfferForCopyQueryVariables>(GetWineOfferForCopyDocument, options);
      }
export function useGetWineOfferForCopyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetWineOfferForCopyQuery, Types.GetWineOfferForCopyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetWineOfferForCopyQuery, Types.GetWineOfferForCopyQueryVariables>(GetWineOfferForCopyDocument, options);
        }
export function useGetWineOfferForCopySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetWineOfferForCopyQuery, Types.GetWineOfferForCopyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetWineOfferForCopyQuery, Types.GetWineOfferForCopyQueryVariables>(GetWineOfferForCopyDocument, options);
        }
export type GetWineOfferForCopyQueryHookResult = ReturnType<typeof useGetWineOfferForCopyQuery>;
export type GetWineOfferForCopyLazyQueryHookResult = ReturnType<typeof useGetWineOfferForCopyLazyQuery>;
export type GetWineOfferForCopySuspenseQueryHookResult = ReturnType<typeof useGetWineOfferForCopySuspenseQuery>;
export type GetWineOfferForCopyQueryResult = Apollo.QueryResult<Types.GetWineOfferForCopyQuery, Types.GetWineOfferForCopyQueryVariables>;
export const WineOffersCompanyCreateDocument = gql`
    mutation wineOffersCompanyCreate($input: WineOffersCompanyCreateInput!) {
  wineOffersCompanyCreate(input: $input) {
    records {
      id
    }
  }
}
    `;
export type WineOffersCompanyCreateMutationFn = Apollo.MutationFunction<Types.WineOffersCompanyCreateMutation, Types.WineOffersCompanyCreateMutationVariables>;

/**
 * __useWineOffersCompanyCreateMutation__
 *
 * To run a mutation, you first call `useWineOffersCompanyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOffersCompanyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOffersCompanyCreateMutation, { data, loading, error }] = useWineOffersCompanyCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOffersCompanyCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOffersCompanyCreateMutation, Types.WineOffersCompanyCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOffersCompanyCreateMutation, Types.WineOffersCompanyCreateMutationVariables>(WineOffersCompanyCreateDocument, options);
      }
export type WineOffersCompanyCreateMutationHookResult = ReturnType<typeof useWineOffersCompanyCreateMutation>;
export type WineOffersCompanyCreateMutationResult = Apollo.MutationResult<Types.WineOffersCompanyCreateMutation>;
export type WineOffersCompanyCreateMutationOptions = Apollo.BaseMutationOptions<Types.WineOffersCompanyCreateMutation, Types.WineOffersCompanyCreateMutationVariables>;
export const WineOffersCompanyDraftCreateDocument = gql`
    mutation wineOffersCompanyDraftCreate($input: WineOffersCompanyDraftCreateInput!) {
  wineOffersCompanyDraftCreate(input: $input) {
    records {
      id
    }
  }
}
    `;
export type WineOffersCompanyDraftCreateMutationFn = Apollo.MutationFunction<Types.WineOffersCompanyDraftCreateMutation, Types.WineOffersCompanyDraftCreateMutationVariables>;

/**
 * __useWineOffersCompanyDraftCreateMutation__
 *
 * To run a mutation, you first call `useWineOffersCompanyDraftCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOffersCompanyDraftCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOffersCompanyDraftCreateMutation, { data, loading, error }] = useWineOffersCompanyDraftCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOffersCompanyDraftCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOffersCompanyDraftCreateMutation, Types.WineOffersCompanyDraftCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOffersCompanyDraftCreateMutation, Types.WineOffersCompanyDraftCreateMutationVariables>(WineOffersCompanyDraftCreateDocument, options);
      }
export type WineOffersCompanyDraftCreateMutationHookResult = ReturnType<typeof useWineOffersCompanyDraftCreateMutation>;
export type WineOffersCompanyDraftCreateMutationResult = Apollo.MutationResult<Types.WineOffersCompanyDraftCreateMutation>;
export type WineOffersCompanyDraftCreateMutationOptions = Apollo.BaseMutationOptions<Types.WineOffersCompanyDraftCreateMutation, Types.WineOffersCompanyDraftCreateMutationVariables>;
export const WineOffersPublicCreateDocument = gql`
    mutation wineOffersPublicCreate($input: WineOffersPublicCreateInput!) {
  wineOffersPublicCreate(input: $input) {
    records {
      id
    }
  }
}
    `;
export type WineOffersPublicCreateMutationFn = Apollo.MutationFunction<Types.WineOffersPublicCreateMutation, Types.WineOffersPublicCreateMutationVariables>;

/**
 * __useWineOffersPublicCreateMutation__
 *
 * To run a mutation, you first call `useWineOffersPublicCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOffersPublicCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOffersPublicCreateMutation, { data, loading, error }] = useWineOffersPublicCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOffersPublicCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOffersPublicCreateMutation, Types.WineOffersPublicCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOffersPublicCreateMutation, Types.WineOffersPublicCreateMutationVariables>(WineOffersPublicCreateDocument, options);
      }
export type WineOffersPublicCreateMutationHookResult = ReturnType<typeof useWineOffersPublicCreateMutation>;
export type WineOffersPublicCreateMutationResult = Apollo.MutationResult<Types.WineOffersPublicCreateMutation>;
export type WineOffersPublicCreateMutationOptions = Apollo.BaseMutationOptions<Types.WineOffersPublicCreateMutation, Types.WineOffersPublicCreateMutationVariables>;
export const WineOffersPublicDraftCreateDocument = gql`
    mutation wineOffersPublicDraftCreate($input: WineOffersPublicDraftCreateInput!) {
  wineOffersPublicDraftCreate(input: $input) {
    records {
      id
    }
  }
}
    `;
export type WineOffersPublicDraftCreateMutationFn = Apollo.MutationFunction<Types.WineOffersPublicDraftCreateMutation, Types.WineOffersPublicDraftCreateMutationVariables>;

/**
 * __useWineOffersPublicDraftCreateMutation__
 *
 * To run a mutation, you first call `useWineOffersPublicDraftCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOffersPublicDraftCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOffersPublicDraftCreateMutation, { data, loading, error }] = useWineOffersPublicDraftCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOffersPublicDraftCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOffersPublicDraftCreateMutation, Types.WineOffersPublicDraftCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOffersPublicDraftCreateMutation, Types.WineOffersPublicDraftCreateMutationVariables>(WineOffersPublicDraftCreateDocument, options);
      }
export type WineOffersPublicDraftCreateMutationHookResult = ReturnType<typeof useWineOffersPublicDraftCreateMutation>;
export type WineOffersPublicDraftCreateMutationResult = Apollo.MutationResult<Types.WineOffersPublicDraftCreateMutation>;
export type WineOffersPublicDraftCreateMutationOptions = Apollo.BaseMutationOptions<Types.WineOffersPublicDraftCreateMutation, Types.WineOffersPublicDraftCreateMutationVariables>;
export const WineOfferCompanyDraftUpdateDocument = gql`
    mutation wineOfferCompanyDraftUpdate($input: WineOfferCompanyDraftUpdateInput!) {
  wineOfferCompanyDraftUpdate(input: $input) {
    record {
      id
    }
  }
}
    `;
export type WineOfferCompanyDraftUpdateMutationFn = Apollo.MutationFunction<Types.WineOfferCompanyDraftUpdateMutation, Types.WineOfferCompanyDraftUpdateMutationVariables>;

/**
 * __useWineOfferCompanyDraftUpdateMutation__
 *
 * To run a mutation, you first call `useWineOfferCompanyDraftUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOfferCompanyDraftUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOfferCompanyDraftUpdateMutation, { data, loading, error }] = useWineOfferCompanyDraftUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOfferCompanyDraftUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOfferCompanyDraftUpdateMutation, Types.WineOfferCompanyDraftUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOfferCompanyDraftUpdateMutation, Types.WineOfferCompanyDraftUpdateMutationVariables>(WineOfferCompanyDraftUpdateDocument, options);
      }
export type WineOfferCompanyDraftUpdateMutationHookResult = ReturnType<typeof useWineOfferCompanyDraftUpdateMutation>;
export type WineOfferCompanyDraftUpdateMutationResult = Apollo.MutationResult<Types.WineOfferCompanyDraftUpdateMutation>;
export type WineOfferCompanyDraftUpdateMutationOptions = Apollo.BaseMutationOptions<Types.WineOfferCompanyDraftUpdateMutation, Types.WineOfferCompanyDraftUpdateMutationVariables>;
export const WineOfferPublicDraftUpdateDocument = gql`
    mutation wineOfferPublicDraftUpdate($input: WineOfferPublicDraftUpdateInput!) {
  wineOfferPublicDraftUpdate(input: $input) {
    record {
      id
    }
  }
}
    `;
export type WineOfferPublicDraftUpdateMutationFn = Apollo.MutationFunction<Types.WineOfferPublicDraftUpdateMutation, Types.WineOfferPublicDraftUpdateMutationVariables>;

/**
 * __useWineOfferPublicDraftUpdateMutation__
 *
 * To run a mutation, you first call `useWineOfferPublicDraftUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOfferPublicDraftUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOfferPublicDraftUpdateMutation, { data, loading, error }] = useWineOfferPublicDraftUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOfferPublicDraftUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOfferPublicDraftUpdateMutation, Types.WineOfferPublicDraftUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOfferPublicDraftUpdateMutation, Types.WineOfferPublicDraftUpdateMutationVariables>(WineOfferPublicDraftUpdateDocument, options);
      }
export type WineOfferPublicDraftUpdateMutationHookResult = ReturnType<typeof useWineOfferPublicDraftUpdateMutation>;
export type WineOfferPublicDraftUpdateMutationResult = Apollo.MutationResult<Types.WineOfferPublicDraftUpdateMutation>;
export type WineOfferPublicDraftUpdateMutationOptions = Apollo.BaseMutationOptions<Types.WineOfferPublicDraftUpdateMutation, Types.WineOfferPublicDraftUpdateMutationVariables>;
export const AdminWineOfferCompanyUpdateDocument = gql`
    mutation adminWineOfferCompanyUpdate($input: AdminWineOfferCompanyUpdateInput!) {
  admin {
    wineOffers {
      wineOfferCompanyUpdate(input: $input) {
        record {
          id
        }
      }
    }
  }
}
    `;
export type AdminWineOfferCompanyUpdateMutationFn = Apollo.MutationFunction<Types.AdminWineOfferCompanyUpdateMutation, Types.AdminWineOfferCompanyUpdateMutationVariables>;

/**
 * __useAdminWineOfferCompanyUpdateMutation__
 *
 * To run a mutation, you first call `useAdminWineOfferCompanyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminWineOfferCompanyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminWineOfferCompanyUpdateMutation, { data, loading, error }] = useAdminWineOfferCompanyUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminWineOfferCompanyUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminWineOfferCompanyUpdateMutation, Types.AdminWineOfferCompanyUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminWineOfferCompanyUpdateMutation, Types.AdminWineOfferCompanyUpdateMutationVariables>(AdminWineOfferCompanyUpdateDocument, options);
      }
export type AdminWineOfferCompanyUpdateMutationHookResult = ReturnType<typeof useAdminWineOfferCompanyUpdateMutation>;
export type AdminWineOfferCompanyUpdateMutationResult = Apollo.MutationResult<Types.AdminWineOfferCompanyUpdateMutation>;
export type AdminWineOfferCompanyUpdateMutationOptions = Apollo.BaseMutationOptions<Types.AdminWineOfferCompanyUpdateMutation, Types.AdminWineOfferCompanyUpdateMutationVariables>;
export const AdminWineOfferPublicUpdateDocument = gql`
    mutation adminWineOfferPublicUpdate($input: AdminWineOfferPublicUpdateInput!) {
  admin {
    wineOffers {
      wineOfferPublicUpdate(input: $input) {
        record {
          id
        }
      }
    }
  }
}
    `;
export type AdminWineOfferPublicUpdateMutationFn = Apollo.MutationFunction<Types.AdminWineOfferPublicUpdateMutation, Types.AdminWineOfferPublicUpdateMutationVariables>;

/**
 * __useAdminWineOfferPublicUpdateMutation__
 *
 * To run a mutation, you first call `useAdminWineOfferPublicUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminWineOfferPublicUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminWineOfferPublicUpdateMutation, { data, loading, error }] = useAdminWineOfferPublicUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminWineOfferPublicUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.AdminWineOfferPublicUpdateMutation, Types.AdminWineOfferPublicUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AdminWineOfferPublicUpdateMutation, Types.AdminWineOfferPublicUpdateMutationVariables>(AdminWineOfferPublicUpdateDocument, options);
      }
export type AdminWineOfferPublicUpdateMutationHookResult = ReturnType<typeof useAdminWineOfferPublicUpdateMutation>;
export type AdminWineOfferPublicUpdateMutationResult = Apollo.MutationResult<Types.AdminWineOfferPublicUpdateMutation>;
export type AdminWineOfferPublicUpdateMutationOptions = Apollo.BaseMutationOptions<Types.AdminWineOfferPublicUpdateMutation, Types.AdminWineOfferPublicUpdateMutationVariables>;
export const WineOfferCompanyUpdateDocument = gql`
    mutation wineOfferCompanyUpdate($input: WineOfferCompanyUpdateInput!) {
  wineOfferCompanyUpdate(input: $input) {
    record {
      id
    }
  }
}
    `;
export type WineOfferCompanyUpdateMutationFn = Apollo.MutationFunction<Types.WineOfferCompanyUpdateMutation, Types.WineOfferCompanyUpdateMutationVariables>;

/**
 * __useWineOfferCompanyUpdateMutation__
 *
 * To run a mutation, you first call `useWineOfferCompanyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOfferCompanyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOfferCompanyUpdateMutation, { data, loading, error }] = useWineOfferCompanyUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOfferCompanyUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOfferCompanyUpdateMutation, Types.WineOfferCompanyUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOfferCompanyUpdateMutation, Types.WineOfferCompanyUpdateMutationVariables>(WineOfferCompanyUpdateDocument, options);
      }
export type WineOfferCompanyUpdateMutationHookResult = ReturnType<typeof useWineOfferCompanyUpdateMutation>;
export type WineOfferCompanyUpdateMutationResult = Apollo.MutationResult<Types.WineOfferCompanyUpdateMutation>;
export type WineOfferCompanyUpdateMutationOptions = Apollo.BaseMutationOptions<Types.WineOfferCompanyUpdateMutation, Types.WineOfferCompanyUpdateMutationVariables>;
export const WineOfferPublicUpdateDocument = gql`
    mutation wineOfferPublicUpdate($input: WineOfferPublicUpdateInput!) {
  wineOfferPublicUpdate(input: $input) {
    record {
      id
    }
  }
}
    `;
export type WineOfferPublicUpdateMutationFn = Apollo.MutationFunction<Types.WineOfferPublicUpdateMutation, Types.WineOfferPublicUpdateMutationVariables>;

/**
 * __useWineOfferPublicUpdateMutation__
 *
 * To run a mutation, you first call `useWineOfferPublicUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOfferPublicUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOfferPublicUpdateMutation, { data, loading, error }] = useWineOfferPublicUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOfferPublicUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOfferPublicUpdateMutation, Types.WineOfferPublicUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOfferPublicUpdateMutation, Types.WineOfferPublicUpdateMutationVariables>(WineOfferPublicUpdateDocument, options);
      }
export type WineOfferPublicUpdateMutationHookResult = ReturnType<typeof useWineOfferPublicUpdateMutation>;
export type WineOfferPublicUpdateMutationResult = Apollo.MutationResult<Types.WineOfferPublicUpdateMutation>;
export type WineOfferPublicUpdateMutationOptions = Apollo.BaseMutationOptions<Types.WineOfferPublicUpdateMutation, Types.WineOfferPublicUpdateMutationVariables>;
export const CompaniesForNewOfferDocument = gql`
    query companiesForNewOffer($query: String, $page: PositiveInt!, $perPage: PositiveInt!, $companyId: ID) {
  buyersPagination(
    query: $query
    page: $page
    perPage: $perPage
    companyId: $companyId
  ) {
    items {
      ... on Company {
        id
        tradeName
        companyCategory {
          id
          masterName
        }
        representativeUser {
          id
          name
          lastName
        }
      }
      ... on HiddenCompany {
        id
        companyCategory {
          id
          masterName
        }
      }
    }
  }
}
    `;

/**
 * __useCompaniesForNewOfferQuery__
 *
 * To run a query within a React component, call `useCompaniesForNewOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesForNewOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesForNewOfferQuery({
 *   variables: {
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompaniesForNewOfferQuery(baseOptions: Apollo.QueryHookOptions<Types.CompaniesForNewOfferQuery, Types.CompaniesForNewOfferQueryVariables> & ({ variables: Types.CompaniesForNewOfferQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CompaniesForNewOfferQuery, Types.CompaniesForNewOfferQueryVariables>(CompaniesForNewOfferDocument, options);
      }
export function useCompaniesForNewOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CompaniesForNewOfferQuery, Types.CompaniesForNewOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CompaniesForNewOfferQuery, Types.CompaniesForNewOfferQueryVariables>(CompaniesForNewOfferDocument, options);
        }
export function useCompaniesForNewOfferSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CompaniesForNewOfferQuery, Types.CompaniesForNewOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CompaniesForNewOfferQuery, Types.CompaniesForNewOfferQueryVariables>(CompaniesForNewOfferDocument, options);
        }
export type CompaniesForNewOfferQueryHookResult = ReturnType<typeof useCompaniesForNewOfferQuery>;
export type CompaniesForNewOfferLazyQueryHookResult = ReturnType<typeof useCompaniesForNewOfferLazyQuery>;
export type CompaniesForNewOfferSuspenseQueryHookResult = ReturnType<typeof useCompaniesForNewOfferSuspenseQuery>;
export type CompaniesForNewOfferQueryResult = Apollo.QueryResult<Types.CompaniesForNewOfferQuery, Types.CompaniesForNewOfferQueryVariables>;
export const GetProductForNewOfferDocument = gql`
    query getProductForNewOffer($localeId: Int!, $productId: ID!) {
  product(id: $productId) {
    ...productForNewOffer
  }
}
    ${ProductForNewOfferFragmentDoc}`;

/**
 * __useGetProductForNewOfferQuery__
 *
 * To run a query within a React component, call `useGetProductForNewOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductForNewOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductForNewOfferQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetProductForNewOfferQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProductForNewOfferQuery, Types.GetProductForNewOfferQueryVariables> & ({ variables: Types.GetProductForNewOfferQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProductForNewOfferQuery, Types.GetProductForNewOfferQueryVariables>(GetProductForNewOfferDocument, options);
      }
export function useGetProductForNewOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProductForNewOfferQuery, Types.GetProductForNewOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProductForNewOfferQuery, Types.GetProductForNewOfferQueryVariables>(GetProductForNewOfferDocument, options);
        }
export function useGetProductForNewOfferSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetProductForNewOfferQuery, Types.GetProductForNewOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetProductForNewOfferQuery, Types.GetProductForNewOfferQueryVariables>(GetProductForNewOfferDocument, options);
        }
export type GetProductForNewOfferQueryHookResult = ReturnType<typeof useGetProductForNewOfferQuery>;
export type GetProductForNewOfferLazyQueryHookResult = ReturnType<typeof useGetProductForNewOfferLazyQuery>;
export type GetProductForNewOfferSuspenseQueryHookResult = ReturnType<typeof useGetProductForNewOfferSuspenseQuery>;
export type GetProductForNewOfferQueryResult = Apollo.QueryResult<Types.GetProductForNewOfferQuery, Types.GetProductForNewOfferQueryVariables>;
export const WineOfferForEditDocument = gql`
    query wineOfferForEdit($id: Int!, $localeId: Int!, $companyId: ID) {
  wineOffer(id: $id) {
    ... on WineOfferBase {
      id
      wineOfferType {
        id
        code
      }
      wineOfferStatus {
        id
        code
      }
      product {
        id
        bottleSize {
          id
          volumeML
        }
        packageType {
          id
          isDefault
          shortName
          localizedName(localeId: $localeId)
        }
        mainSynonym {
          ... on Product {
            id
          }
        }
        wine {
          id
          chemicalAnalysis {
            alcoholVolume
          }
          tradeNameBrand
          grapeVarieties {
            grapeVarietySynonym {
              name
            }
            value
          }
          nationalGIType {
            localizedName(localeId: $localeId)
          }
          geoIndications {
            localizedName(localeId: $localeId)
            wineRegionsEU {
              localizedName(localeId: $localeId)
            }
          }
          vintage
          tradeName {
            tradeName
            country {
              localizedName(localeId: $localeId)
            }
          }
          wineType {
            localizedName(localeId: $localeId)
            isDefault
          }
          wineColor {
            localizedName(localeId: $localeId)
          }
          brand {
            id
            name
          }
          sweetnessLevel {
            localizedName(localeId: $localeId)
            isDefault
          }
          keyWords
          specialClassifications {
            id
            name
          }
          products {
            id
            acceptability
            shouldOverrideAcceptability
            canOverrideAcceptability
            verified
            bottleSize {
              id
              volumeML
            }
            packageType {
              id
              isDefault
              shortName
              localizedName(localeId: $localeId)
            }
          }
        }
        bottleImage {
          originUrl
          imageUrl
          thumbnailUrl: imageUrl(transformations: {width: 48, height: 48, resize: fit})
          thumbnailRetinaUrl: imageUrl(
            transformations: {width: 96, height: 96, resize: fit}
          )
        }
      }
      price
      currency {
        id
        code
      }
      lot {
        id
        localizedName(localeId: $localeId)
        bottleQuantity
      }
      minimumDeliverySize
      periodOfValidity
      incoterms {
        id
        code
        localizedShortDescription(localeId: $localeId)
      }
      pricePerBottle
      warehouse {
        id
        name
        country {
          id
          codeISO
          localizedName(localeId: $localeId)
        }
      }
      isExclusiveRight
      exhibitions {
        ...exhibitionsDisplayInfo
      }
      sellerCompany {
        ...SellerFragment
      }
      note
    }
    ... on WineOfferCompany {
      buyer(companyId: $companyId) {
        ... on HiddenCompany {
          id
        }
        ... on Company {
          id
          tradeName
        }
      }
      wineOfferDistLimits {
        ...AddNewOfferFullWineOfferDist
      }
      contactPhone
      contactEmail
    }
    ... on WineOfferPublic {
      buyerCompanyCategory {
        id
        localizedName(localeId: $localeId)
      }
      wineOfferDistLimits {
        ...AddNewOfferFullWineOfferDist
      }
      contactPhone
      contactEmail
    }
    ... on WineOfferPublicDraft {
      draftCompanyCategory: buyerCompanyCategory {
        id
        localizedName(localeId: $localeId)
      }
      wineOfferDistLimits {
        ...AddNewOfferFullWineOfferDist
      }
      contactPhone
      contactEmail
    }
    ... on WineOfferCompanyDraft {
      buyerDraft: buyer(companyId: $companyId) {
        ... on HiddenCompany {
          id
        }
        ... on Company {
          id
          tradeName
        }
      }
      wineOfferDistLimits {
        ...AddNewOfferFullWineOfferDist
      }
      contactPhone
      contactEmail
    }
    ... on WineOfferPersonal {
      isExclusiveRight
      buyer(companyId: $companyId) {
        ... on HiddenCompany {
          id
        }
        ... on Company {
          id
          tradeName
          companyCategory {
            localizedName(localeId: $localeId)
          }
        }
      }
    }
  }
}
    ${ExhibitionsDisplayInfoFragmentDoc}
${SellerFragmentFragmentDoc}
${AddNewOfferFullWineOfferDistFragmentDoc}`;

/**
 * __useWineOfferForEditQuery__
 *
 * To run a query within a React component, call `useWineOfferForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineOfferForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineOfferForEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useWineOfferForEditQuery(baseOptions: Apollo.QueryHookOptions<Types.WineOfferForEditQuery, Types.WineOfferForEditQueryVariables> & ({ variables: Types.WineOfferForEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineOfferForEditQuery, Types.WineOfferForEditQueryVariables>(WineOfferForEditDocument, options);
      }
export function useWineOfferForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineOfferForEditQuery, Types.WineOfferForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineOfferForEditQuery, Types.WineOfferForEditQueryVariables>(WineOfferForEditDocument, options);
        }
export function useWineOfferForEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineOfferForEditQuery, Types.WineOfferForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineOfferForEditQuery, Types.WineOfferForEditQueryVariables>(WineOfferForEditDocument, options);
        }
export type WineOfferForEditQueryHookResult = ReturnType<typeof useWineOfferForEditQuery>;
export type WineOfferForEditLazyQueryHookResult = ReturnType<typeof useWineOfferForEditLazyQuery>;
export type WineOfferForEditSuspenseQueryHookResult = ReturnType<typeof useWineOfferForEditSuspenseQuery>;
export type WineOfferForEditQueryResult = Apollo.QueryResult<Types.WineOfferForEditQuery, Types.WineOfferForEditQueryVariables>;
export const WineOfferSellerOfferConditionsDocument = gql`
    mutation wineOfferSellerOfferConditions($input: WineOfferSellerOfferConditionsInput!) {
  wineOfferSellerOfferConditions(input: $input) {
    record {
      id
      wineOfferStatus {
        code
      }
      comment
      price
      currency {
        id
      }
      lot {
        id
      }
      incoterms {
        id
      }
      warehouse {
        id
      }
      minimumDeliverySize
      periodOfValidity
      comment
    }
  }
}
    `;
export type WineOfferSellerOfferConditionsMutationFn = Apollo.MutationFunction<Types.WineOfferSellerOfferConditionsMutation, Types.WineOfferSellerOfferConditionsMutationVariables>;

/**
 * __useWineOfferSellerOfferConditionsMutation__
 *
 * To run a mutation, you first call `useWineOfferSellerOfferConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOfferSellerOfferConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOfferSellerOfferConditionsMutation, { data, loading, error }] = useWineOfferSellerOfferConditionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOfferSellerOfferConditionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOfferSellerOfferConditionsMutation, Types.WineOfferSellerOfferConditionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOfferSellerOfferConditionsMutation, Types.WineOfferSellerOfferConditionsMutationVariables>(WineOfferSellerOfferConditionsDocument, options);
      }
export type WineOfferSellerOfferConditionsMutationHookResult = ReturnType<typeof useWineOfferSellerOfferConditionsMutation>;
export type WineOfferSellerOfferConditionsMutationResult = Apollo.MutationResult<Types.WineOfferSellerOfferConditionsMutation>;
export type WineOfferSellerOfferConditionsMutationOptions = Apollo.BaseMutationOptions<Types.WineOfferSellerOfferConditionsMutation, Types.WineOfferSellerOfferConditionsMutationVariables>;
export const WineOffersUpdatePeriodOfValidityDocument = gql`
    mutation wineOffersUpdatePeriodOfValidity($input: WineOffersUpdatePeriodOfValidityInput!) {
  wineOffersUpdatePeriodOfValidity(input: $input) {
    __typename
  }
}
    `;
export type WineOffersUpdatePeriodOfValidityMutationFn = Apollo.MutationFunction<Types.WineOffersUpdatePeriodOfValidityMutation, Types.WineOffersUpdatePeriodOfValidityMutationVariables>;

/**
 * __useWineOffersUpdatePeriodOfValidityMutation__
 *
 * To run a mutation, you first call `useWineOffersUpdatePeriodOfValidityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOffersUpdatePeriodOfValidityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOffersUpdatePeriodOfValidityMutation, { data, loading, error }] = useWineOffersUpdatePeriodOfValidityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineOffersUpdatePeriodOfValidityMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOffersUpdatePeriodOfValidityMutation, Types.WineOffersUpdatePeriodOfValidityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOffersUpdatePeriodOfValidityMutation, Types.WineOffersUpdatePeriodOfValidityMutationVariables>(WineOffersUpdatePeriodOfValidityDocument, options);
      }
export type WineOffersUpdatePeriodOfValidityMutationHookResult = ReturnType<typeof useWineOffersUpdatePeriodOfValidityMutation>;
export type WineOffersUpdatePeriodOfValidityMutationResult = Apollo.MutationResult<Types.WineOffersUpdatePeriodOfValidityMutation>;
export type WineOffersUpdatePeriodOfValidityMutationOptions = Apollo.BaseMutationOptions<Types.WineOffersUpdatePeriodOfValidityMutation, Types.WineOffersUpdatePeriodOfValidityMutationVariables>;
export const GetNegotiationsHistoryDocument = gql`
    query getNegotiationsHistory($companyId: ID!, $id: Int!, $localeId: Int!) {
  me {
    company(id: $companyId) {
      wineOfferPersonalHistory(id: $id) {
        ... on WineOfferPersonal {
          id
          baseWineOffer {
            ... on WineOfferCompany {
              id
              incoterms {
                code
              }
              pricePerBottle
              periodOfValidity
              price
              minimumDeliverySize
              warehouse {
                id
                country {
                  codeISO
                }
                address
              }
              lot {
                bottleQuantity
              }
              currency {
                code
              }
            }
            ... on WineOfferPublic {
              id
              incoterms {
                code
              }
              pricePerBottle
              periodOfValidity
              price
              minimumDeliverySize
              warehouse {
                id
                country {
                  codeISO
                }
                address
              }
              lot {
                bottleQuantity
              }
              currency {
                code
              }
            }
            ... on WineOfferPersonal {
              id
              incoterms {
                code
              }
              pricePerBottle
              periodOfValidity
              price
              minimumDeliverySize
              warehouse {
                id
                country {
                  codeISO
                }
                address
              }
              lot {
                bottleQuantity
              }
              currency {
                code
              }
            }
          }
          hasRead(companyId: $companyId)
          wineOfferStatus {
            code
          }
          wineOfferType {
            code
          }
          createdAt
          updatedAt
          sellerCompany {
            companyCategory {
              id
            }
            tradeName
            avatar {
              imageUrl
            }
            representativeUser {
              name
              lastName
              username
            }
          }
          buyerCompany: buyer(companyId: $companyId) {
            ... on Company {
              id
              companyCategory {
                id
              }
              tradeName
              avatar {
                imageUrl
              }
              representativeUser {
                name
                lastName
                username
              }
            }
            ... on HiddenCompany {
              id
              companyCategory {
                id
              }
              representativeUser {
                name
                lastName
                username
              }
            }
          }
          buyerPrice
          buyerPricePerBottle
          pricePerBottle
          price
          incoterms {
            code
          }
          currency {
            code
          }
          lot {
            localizedName(localeId: $localeId)
            bottleQuantity
          }
          buyerIncoterms {
            code
          }
          warehouse {
            id
            country {
              codeISO
            }
            address
          }
          minimumDeliverySize
          buyerDeliverySize
          periodOfValidity
          buyerPeriodOfValidity
          comment
        }
      }
    }
  }
}
    `;

/**
 * __useGetNegotiationsHistoryQuery__
 *
 * To run a query within a React component, call `useGetNegotiationsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNegotiationsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNegotiationsHistoryQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useGetNegotiationsHistoryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNegotiationsHistoryQuery, Types.GetNegotiationsHistoryQueryVariables> & ({ variables: Types.GetNegotiationsHistoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNegotiationsHistoryQuery, Types.GetNegotiationsHistoryQueryVariables>(GetNegotiationsHistoryDocument, options);
      }
export function useGetNegotiationsHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNegotiationsHistoryQuery, Types.GetNegotiationsHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNegotiationsHistoryQuery, Types.GetNegotiationsHistoryQueryVariables>(GetNegotiationsHistoryDocument, options);
        }
export function useGetNegotiationsHistorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetNegotiationsHistoryQuery, Types.GetNegotiationsHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetNegotiationsHistoryQuery, Types.GetNegotiationsHistoryQueryVariables>(GetNegotiationsHistoryDocument, options);
        }
export type GetNegotiationsHistoryQueryHookResult = ReturnType<typeof useGetNegotiationsHistoryQuery>;
export type GetNegotiationsHistoryLazyQueryHookResult = ReturnType<typeof useGetNegotiationsHistoryLazyQuery>;
export type GetNegotiationsHistorySuspenseQueryHookResult = ReturnType<typeof useGetNegotiationsHistorySuspenseQuery>;
export type GetNegotiationsHistoryQueryResult = Apollo.QueryResult<Types.GetNegotiationsHistoryQuery, Types.GetNegotiationsHistoryQueryVariables>;
export const SellWineWineOffersDocument = gql`
    query SellWineWineOffers($companyId: ID!, $localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt!, $sort: WineOfferSort!, $query: String, $filter: WineOfferFilter) {
  me {
    id
    company(id: $companyId) {
      id
      wineOffersPagination(
        localeId: $localeId
        isBuyer: false
        page: $page
        perPage: $perPage
        sort: $sort
        filter: $filter
        query: $query
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          totalPages
          totalItems
          page
          perPage
        }
        items {
          __typename
          ... on WineOfferBase {
            id
            shortlistedCount
            hasRead(companyId: $companyId)
            minimumDeliverySize
            wineOfferType {
              code
            }
            wineOfferStatus {
              code
            }
            priceCoefficient
            price
            product {
              id
              slug
              mainSynonym {
                ... on Product {
                  id
                }
              }
              acceptability
              priceAtWinery
              currency {
                code
                id
              }
              wine {
                id
                chemicalAnalysis {
                  alcoholVolume
                }
                country {
                  id
                  localizedName(localeId: $localeId)
                  codeISO
                }
                tradeNameBrand
                brand {
                  name
                  acceptability
                  popularity
                }
                acceptability
                keyWords
                tradeName {
                  acceptability
                  tradeName
                  country {
                    id
                    localizedName(localeId: $localeId)
                  }
                }
                wineType {
                  isDefault
                  masterName
                  localizedName(localeId: $localeId)
                }
                wineColor {
                  localizedName(localeId: $localeId)
                }
                sweetnessLevel {
                  masterName
                  isDefault
                  localizedName(localeId: $localeId)
                }
                specialClassifications {
                  name
                }
                vintage
                grapeVarieties {
                  grapeVarietySynonym {
                    name
                  }
                  value
                }
                nationalGIType {
                  shortName
                  localizedName(localeId: $localeId)
                }
                geoIndications {
                  localizedName(localeId: $localeId)
                  wineRegionsEU {
                    localizedName(localeId: $localeId)
                  }
                }
                rating
              }
              bottleSize {
                id
                volumeML
              }
              packageType {
                id
                isDefault
                shortName
                localizedName(localeId: $localeId)
              }
              bottleImage {
                imageUrl
                thumbnailUrl: imageUrl(transformations: {width: 48, height: 96, resize: fit})
                thumbnailRetinaUrl: imageUrl(
                  transformations: {width: 96, height: 192, resize: fit}
                )
              }
            }
            pricePerBottle
            currency {
              code
              id
            }
            lot {
              id
              localizedName(localeId: $localeId)
              bottleQuantity
            }
            wineOfferType {
              code
            }
            periodOfValidity
            sellerCompany {
              tradeName
              companyCategory {
                id
                masterName
                localizedName(localeId: $localeId)
              }
            }
            isExclusiveRight
            incoterms {
              id
              code
              localizedShortDescription(localeId: $localeId)
            }
            warehouse {
              id
              country {
                codeISO
                localizedName(localeId: $localeId)
              }
              address
              territory {
                masterName
              }
            }
            exhibitions {
              ...exhibitionsDisplayInfo
            }
          }
          ... on WineOfferPersonal {
            wineOfferDistLimits {
              ...SellWineFullWineOfferDist
            }
            isExclusiveRight
            buyerPrice
            buyerPricePerBottle
            buyerIncoterms {
              id
              code
              localizedShortDescription(localeId: $localeId)
            }
            buyerDeliverySize
            buyerPeriodOfValidity
            buyerCompany: buyer(companyId: $companyId) {
              ... on Company {
                tradeName
                id
                companyCategory {
                  id
                  masterName
                  localizedName(localeId: $localeId)
                }
                country {
                  codeISO
                  localizedName(localeId: $localeId)
                }
              }
              ... on HiddenCompany {
                id
                companyCategory {
                  id
                  masterName
                  localizedName(localeId: $localeId)
                }
                country {
                  codeISO
                  localizedName(localeId: $localeId)
                }
              }
            }
          }
          ... on WineOfferCompany {
            buyerCompany: buyer(companyId: $companyId) {
              ... on Company {
                id
                tradeName
                companyCategory {
                  id
                  masterName
                  localizedName(localeId: $localeId)
                }
                country {
                  codeISO
                  localizedName(localeId: $localeId)
                }
              }
              ... on HiddenCompany {
                id
                companyCategory {
                  id
                  masterName
                  localizedName(localeId: $localeId)
                }
                country {
                  codeISO
                  localizedName(localeId: $localeId)
                }
              }
            }
            wineOfferDistLimits {
              ...SellWineFullWineOfferDist
            }
          }
          ... on WineOfferPublic {
            buyerCompanyCategory {
              id
              masterName
              localizedName(localeId: $localeId)
            }
            wineOfferDistLimits {
              ...SellWineFullWineOfferDist
            }
          }
          ... on WineOfferPublicDraft {
            draftCompanyCategory: buyerCompanyCategory {
              id
              masterName
              localizedName(localeId: $localeId)
            }
            wineOfferDistLimits {
              ...SellWineFullWineOfferDist
            }
          }
          ... on WineOfferCompanyDraft {
            buyerDraft: buyer(companyId: $companyId) {
              ... on Company {
                tradeName
                id
                companyCategory {
                  id
                  masterName
                  localizedName(localeId: $localeId)
                }
                country {
                  codeISO
                  localizedName(localeId: $localeId)
                }
              }
              ... on HiddenCompany {
                id
                companyCategory {
                  id
                  masterName
                  localizedName(localeId: $localeId)
                }
                country {
                  codeISO
                  localizedName(localeId: $localeId)
                }
              }
            }
            wineOfferDistLimits {
              ...SellWineFullWineOfferDist
            }
          }
        }
      }
    }
  }
}
    ${ExhibitionsDisplayInfoFragmentDoc}
${SellWineFullWineOfferDistFragmentDoc}`;

/**
 * __useSellWineWineOffersQuery__
 *
 * To run a query within a React component, call `useSellWineWineOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellWineWineOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellWineWineOffersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSellWineWineOffersQuery(baseOptions: Apollo.QueryHookOptions<Types.SellWineWineOffersQuery, Types.SellWineWineOffersQueryVariables> & ({ variables: Types.SellWineWineOffersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SellWineWineOffersQuery, Types.SellWineWineOffersQueryVariables>(SellWineWineOffersDocument, options);
      }
export function useSellWineWineOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SellWineWineOffersQuery, Types.SellWineWineOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SellWineWineOffersQuery, Types.SellWineWineOffersQueryVariables>(SellWineWineOffersDocument, options);
        }
export function useSellWineWineOffersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SellWineWineOffersQuery, Types.SellWineWineOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SellWineWineOffersQuery, Types.SellWineWineOffersQueryVariables>(SellWineWineOffersDocument, options);
        }
export type SellWineWineOffersQueryHookResult = ReturnType<typeof useSellWineWineOffersQuery>;
export type SellWineWineOffersLazyQueryHookResult = ReturnType<typeof useSellWineWineOffersLazyQuery>;
export type SellWineWineOffersSuspenseQueryHookResult = ReturnType<typeof useSellWineWineOffersSuspenseQuery>;
export type SellWineWineOffersQueryResult = Apollo.QueryResult<Types.SellWineWineOffersQuery, Types.SellWineWineOffersQueryVariables>;
export const WineOffersDeleteDocument = gql`
    mutation wineOffersDelete($companyId: ID!, $wineOfferIds: [Int!]!) {
  wineOffersDelete(input: {companyId: $companyId, wineOfferIds: $wineOfferIds}) {
    recordIds
  }
}
    `;
export type WineOffersDeleteMutationFn = Apollo.MutationFunction<Types.WineOffersDeleteMutation, Types.WineOffersDeleteMutationVariables>;

/**
 * __useWineOffersDeleteMutation__
 *
 * To run a mutation, you first call `useWineOffersDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOffersDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOffersDeleteMutation, { data, loading, error }] = useWineOffersDeleteMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      wineOfferIds: // value for 'wineOfferIds'
 *   },
 * });
 */
export function useWineOffersDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOffersDeleteMutation, Types.WineOffersDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOffersDeleteMutation, Types.WineOffersDeleteMutationVariables>(WineOffersDeleteDocument, options);
      }
export type WineOffersDeleteMutationHookResult = ReturnType<typeof useWineOffersDeleteMutation>;
export type WineOffersDeleteMutationResult = Apollo.MutationResult<Types.WineOffersDeleteMutation>;
export type WineOffersDeleteMutationOptions = Apollo.BaseMutationOptions<Types.WineOffersDeleteMutation, Types.WineOffersDeleteMutationVariables>;
export const WineOffersPublishDocument = gql`
    mutation wineOffersPublish($companyId: ID!, $wineOfferIds: [Int!]!) {
  wineOffersPublish(input: {companyId: $companyId, wineOfferIds: $wineOfferIds}) {
    records {
      id
      wineOfferStatus {
        id
        code
      }
      wineOfferType {
        id
        code
      }
    }
  }
}
    `;
export type WineOffersPublishMutationFn = Apollo.MutationFunction<Types.WineOffersPublishMutation, Types.WineOffersPublishMutationVariables>;

/**
 * __useWineOffersPublishMutation__
 *
 * To run a mutation, you first call `useWineOffersPublishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOffersPublishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOffersPublishMutation, { data, loading, error }] = useWineOffersPublishMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      wineOfferIds: // value for 'wineOfferIds'
 *   },
 * });
 */
export function useWineOffersPublishMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOffersPublishMutation, Types.WineOffersPublishMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOffersPublishMutation, Types.WineOffersPublishMutationVariables>(WineOffersPublishDocument, options);
      }
export type WineOffersPublishMutationHookResult = ReturnType<typeof useWineOffersPublishMutation>;
export type WineOffersPublishMutationResult = Apollo.MutationResult<Types.WineOffersPublishMutation>;
export type WineOffersPublishMutationOptions = Apollo.BaseMutationOptions<Types.WineOffersPublishMutation, Types.WineOffersPublishMutationVariables>;
export const WineOffersUnpublishDocument = gql`
    mutation wineOffersUnpublish($companyId: ID!, $wineOfferIds: [Int!]!) {
  wineOffersUnpublish(input: {companyId: $companyId, wineOfferIds: $wineOfferIds}) {
    records {
      id
      wineOfferStatus {
        id
        code
      }
      wineOfferType {
        id
        code
      }
    }
  }
}
    `;
export type WineOffersUnpublishMutationFn = Apollo.MutationFunction<Types.WineOffersUnpublishMutation, Types.WineOffersUnpublishMutationVariables>;

/**
 * __useWineOffersUnpublishMutation__
 *
 * To run a mutation, you first call `useWineOffersUnpublishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineOffersUnpublishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineOffersUnpublishMutation, { data, loading, error }] = useWineOffersUnpublishMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      wineOfferIds: // value for 'wineOfferIds'
 *   },
 * });
 */
export function useWineOffersUnpublishMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineOffersUnpublishMutation, Types.WineOffersUnpublishMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineOffersUnpublishMutation, Types.WineOffersUnpublishMutationVariables>(WineOffersUnpublishDocument, options);
      }
export type WineOffersUnpublishMutationHookResult = ReturnType<typeof useWineOffersUnpublishMutation>;
export type WineOffersUnpublishMutationResult = Apollo.MutationResult<Types.WineOffersUnpublishMutation>;
export type WineOffersUnpublishMutationOptions = Apollo.BaseMutationOptions<Types.WineOffersUnpublishMutation, Types.WineOffersUnpublishMutationVariables>;
export const SellWineNegotiationDocument = gql`
    query sellWineNegotiation($id: Int!, $localeId: Int!) {
  wineOffer(id: $id) {
    __typename
    ... on WineOfferBase {
      price
      pricePerBottle
      warehouse {
        id
        name
      }
      incoterms {
        id
        localizedShortDescription(localeId: $localeId)
        code
      }
      lot {
        id
        masterName
        localizedName(localeId: $localeId)
        bottleQuantity
      }
      minimumDeliverySize
      periodOfValidity
      currency {
        code
        id
      }
      wineOfferStatus {
        id
        code
      }
    }
    ... on WineOfferPersonal {
      buyerPrice
      buyerPricePerBottle
      buyerIncoterms {
        id
        code
        localizedShortDescription(localeId: $localeId)
      }
      buyerDeliverySize
      buyerPeriodOfValidity
      comment
    }
  }
}
    `;

/**
 * __useSellWineNegotiationQuery__
 *
 * To run a query within a React component, call `useSellWineNegotiationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellWineNegotiationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellWineNegotiationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useSellWineNegotiationQuery(baseOptions: Apollo.QueryHookOptions<Types.SellWineNegotiationQuery, Types.SellWineNegotiationQueryVariables> & ({ variables: Types.SellWineNegotiationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SellWineNegotiationQuery, Types.SellWineNegotiationQueryVariables>(SellWineNegotiationDocument, options);
      }
export function useSellWineNegotiationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SellWineNegotiationQuery, Types.SellWineNegotiationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SellWineNegotiationQuery, Types.SellWineNegotiationQueryVariables>(SellWineNegotiationDocument, options);
        }
export function useSellWineNegotiationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SellWineNegotiationQuery, Types.SellWineNegotiationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SellWineNegotiationQuery, Types.SellWineNegotiationQueryVariables>(SellWineNegotiationDocument, options);
        }
export type SellWineNegotiationQueryHookResult = ReturnType<typeof useSellWineNegotiationQuery>;
export type SellWineNegotiationLazyQueryHookResult = ReturnType<typeof useSellWineNegotiationLazyQuery>;
export type SellWineNegotiationSuspenseQueryHookResult = ReturnType<typeof useSellWineNegotiationSuspenseQuery>;
export type SellWineNegotiationQueryResult = Apollo.QueryResult<Types.SellWineNegotiationQuery, Types.SellWineNegotiationQueryVariables>;
export const WarehousesDeleteDocument = gql`
    mutation warehousesDelete($input: WarehousesDeleteInput!) {
  warehousesDelete(input: $input) {
    recordIds
  }
}
    `;
export type WarehousesDeleteMutationFn = Apollo.MutationFunction<Types.WarehousesDeleteMutation, Types.WarehousesDeleteMutationVariables>;

/**
 * __useWarehousesDeleteMutation__
 *
 * To run a mutation, you first call `useWarehousesDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWarehousesDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [warehousesDeleteMutation, { data, loading, error }] = useWarehousesDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWarehousesDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.WarehousesDeleteMutation, Types.WarehousesDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WarehousesDeleteMutation, Types.WarehousesDeleteMutationVariables>(WarehousesDeleteDocument, options);
      }
export type WarehousesDeleteMutationHookResult = ReturnType<typeof useWarehousesDeleteMutation>;
export type WarehousesDeleteMutationResult = Apollo.MutationResult<Types.WarehousesDeleteMutation>;
export type WarehousesDeleteMutationOptions = Apollo.BaseMutationOptions<Types.WarehousesDeleteMutation, Types.WarehousesDeleteMutationVariables>;
export const WarehouseCreateDocument = gql`
    mutation warehouseCreate($input: WarehouseCreateInput!) {
  warehouseCreate(input: $input) {
    warehouse {
      id
      address
      country {
        id
        codeISO
      }
    }
  }
}
    `;
export type WarehouseCreateMutationFn = Apollo.MutationFunction<Types.WarehouseCreateMutation, Types.WarehouseCreateMutationVariables>;

/**
 * __useWarehouseCreateMutation__
 *
 * To run a mutation, you first call `useWarehouseCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWarehouseCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [warehouseCreateMutation, { data, loading, error }] = useWarehouseCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWarehouseCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.WarehouseCreateMutation, Types.WarehouseCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WarehouseCreateMutation, Types.WarehouseCreateMutationVariables>(WarehouseCreateDocument, options);
      }
export type WarehouseCreateMutationHookResult = ReturnType<typeof useWarehouseCreateMutation>;
export type WarehouseCreateMutationResult = Apollo.MutationResult<Types.WarehouseCreateMutation>;
export type WarehouseCreateMutationOptions = Apollo.BaseMutationOptions<Types.WarehouseCreateMutation, Types.WarehouseCreateMutationVariables>;
export const WarehouseUpdateDocument = gql`
    mutation warehouseUpdate($input: WarehouseUpdateInput!) {
  warehouseUpdate(input: $input) {
    warehouse {
      id
      address
      country {
        id
      }
    }
  }
}
    `;
export type WarehouseUpdateMutationFn = Apollo.MutationFunction<Types.WarehouseUpdateMutation, Types.WarehouseUpdateMutationVariables>;

/**
 * __useWarehouseUpdateMutation__
 *
 * To run a mutation, you first call `useWarehouseUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWarehouseUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [warehouseUpdateMutation, { data, loading, error }] = useWarehouseUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWarehouseUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.WarehouseUpdateMutation, Types.WarehouseUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WarehouseUpdateMutation, Types.WarehouseUpdateMutationVariables>(WarehouseUpdateDocument, options);
      }
export type WarehouseUpdateMutationHookResult = ReturnType<typeof useWarehouseUpdateMutation>;
export type WarehouseUpdateMutationResult = Apollo.MutationResult<Types.WarehouseUpdateMutation>;
export type WarehouseUpdateMutationOptions = Apollo.BaseMutationOptions<Types.WarehouseUpdateMutation, Types.WarehouseUpdateMutationVariables>;
export const GetCompanyWarehousesDocument = gql`
    query getCompanyWarehouses($companyId: ID!, $localeId: Int!, $page: PositiveInt! = 1, $perPage: PositiveInt! = 20, $sort: WarehouseSort! = CREATED_AT_DESC) {
  me {
    id
    company(id: $companyId) {
      id
      warehousesPagination(
        localeId: $localeId
        page: $page
        perPage: $perPage
        sort: $sort
      ) {
        pageInfo {
          totalItems
          totalPages
          page
          perPage
        }
        items {
          id
          name
          address
          longitude
          latitude
          country {
            id
            localizedName(localeId: $localeId)
            codeISO
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCompanyWarehousesQuery__
 *
 * To run a query within a React component, call `useGetCompanyWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyWarehousesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetCompanyWarehousesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCompanyWarehousesQuery, Types.GetCompanyWarehousesQueryVariables> & ({ variables: Types.GetCompanyWarehousesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCompanyWarehousesQuery, Types.GetCompanyWarehousesQueryVariables>(GetCompanyWarehousesDocument, options);
      }
export function useGetCompanyWarehousesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCompanyWarehousesQuery, Types.GetCompanyWarehousesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCompanyWarehousesQuery, Types.GetCompanyWarehousesQueryVariables>(GetCompanyWarehousesDocument, options);
        }
export function useGetCompanyWarehousesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetCompanyWarehousesQuery, Types.GetCompanyWarehousesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetCompanyWarehousesQuery, Types.GetCompanyWarehousesQueryVariables>(GetCompanyWarehousesDocument, options);
        }
export type GetCompanyWarehousesQueryHookResult = ReturnType<typeof useGetCompanyWarehousesQuery>;
export type GetCompanyWarehousesLazyQueryHookResult = ReturnType<typeof useGetCompanyWarehousesLazyQuery>;
export type GetCompanyWarehousesSuspenseQueryHookResult = ReturnType<typeof useGetCompanyWarehousesSuspenseQuery>;
export type GetCompanyWarehousesQueryResult = Apollo.QueryResult<Types.GetCompanyWarehousesQuery, Types.GetCompanyWarehousesQueryVariables>;
export const RequestOffersFilterDocument = gql`
    query requestOffersFilter($id: ID!, $sellerCompanyId: Int!, $filterKey: String!, $localeId: Int!, $productSearch: String, $facetsInputs: [FacetInput!], $page: PositiveInt! = 1, $perPage: PositiveInt! = 10, $filterSearch: String, $includeUnusedLabels: Boolean = true) {
  wineOfferRequest(id: $id) {
    matches {
      wineOffersFilters(
        localeId: $localeId
        filter: {companyId: $sellerCompanyId, facetsInputs: $facetsInputs}
        query: $productSearch
      ) {
        filterByKey(key: $filterKey) {
          ...facetListFilter
          ...facetComplexFilter
          ...facetRangeFilter
          ...facetBooleanFilter
          ...facetDateRangeFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}
${FacetBooleanFilterFragmentDoc}
${FacetDateRangeFilterFragmentDoc}`;

/**
 * __useRequestOffersFilterQuery__
 *
 * To run a query within a React component, call `useRequestOffersFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestOffersFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestOffersFilterQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sellerCompanyId: // value for 'sellerCompanyId'
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      productSearch: // value for 'productSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useRequestOffersFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.RequestOffersFilterQuery, Types.RequestOffersFilterQueryVariables> & ({ variables: Types.RequestOffersFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RequestOffersFilterQuery, Types.RequestOffersFilterQueryVariables>(RequestOffersFilterDocument, options);
      }
export function useRequestOffersFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RequestOffersFilterQuery, Types.RequestOffersFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RequestOffersFilterQuery, Types.RequestOffersFilterQueryVariables>(RequestOffersFilterDocument, options);
        }
export function useRequestOffersFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RequestOffersFilterQuery, Types.RequestOffersFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RequestOffersFilterQuery, Types.RequestOffersFilterQueryVariables>(RequestOffersFilterDocument, options);
        }
export type RequestOffersFilterQueryHookResult = ReturnType<typeof useRequestOffersFilterQuery>;
export type RequestOffersFilterLazyQueryHookResult = ReturnType<typeof useRequestOffersFilterLazyQuery>;
export type RequestOffersFilterSuspenseQueryHookResult = ReturnType<typeof useRequestOffersFilterSuspenseQuery>;
export type RequestOffersFilterQueryResult = Apollo.QueryResult<Types.RequestOffersFilterQuery, Types.RequestOffersFilterQueryVariables>;
export const RequestOffersFiltersDocument = gql`
    query requestOffersFilters($id: ID!, $sellerCompanyId: Int!, $localeId: Int!, $productSearch: String, $facetsInputs: [FacetInput!], $page: PositiveInt! = 1, $perPage: PositiveInt! = 10, $filterSearch: String = "", $includeUnusedLabels: Boolean = true) {
  wineOfferRequest(id: $id) {
    matches {
      wineOffersFilters(
        localeId: $localeId
        filter: {companyId: $sellerCompanyId, facetsInputs: $facetsInputs}
        query: $productSearch
      ) {
        producer: filterByKey(key: "product.wine.tradeName") {
          ...facetListFilter
        }
        brand: filterByKey(key: "product.wine.brand") {
          ...facetListFilter
        }
        country: filterByKey(key: "product.wine.country") {
          ...facetListFilter
        }
        nationalGIType: filterByKey(key: "product.wine.nationalGIType") {
          ...facetListFilter
        }
        wineRegionEU: filterByKey(key: "product.wine.wineRegionEU") {
          ...facetListFilter
        }
        geoIndication: filterByKey(key: "product.wine.geoIndication") {
          ...facetListFilter
        }
        specialClassification: filterByKey(key: "product.wine.specialClassification") {
          ...facetListFilter
        }
        wineType: filterByKey(key: "product.wine.wineType") {
          ...facetListFilter
        }
        wineColour: filterByKey(key: "product.wine.wineColor") {
          ...facetListFilter
        }
        sweetnessType: filterByKey(key: "product.wine.sweetnessLevel") {
          ...facetListFilter
        }
        vintage: filterByKey(key: "product.wine.vintage") {
          ...facetListFilter
        }
        bottleSize: filterByKey(key: "bottleSize") {
          ...facetListFilter
        }
        grapes: filterByKey(key: "grapeAndMonocepage") {
          ...facetComplexFilter
        }
        monocepage: filterByKey(key: "product.wine.monocepage") {
          ...facetBooleanFilter
        }
        certification: filterByKey(key: "product.wine.certification") {
          ...facetListFilter
        }
        alcoholLevel: filterByKey(key: "product.alcoholOnLabel") {
          ...facetRangeFilter
        }
        wwlRating: filterByKey(key: "product.wine.rating") {
          ...facetListFilter
        }
        pricePerBottle: filterByKey(key: "pricePerBottle") {
          ...facetComplexFilter
        }
        minimalBatch: filterByKey(key: "minimumDeliverySize") {
          ...facetRangeFilter
        }
        deliveryTerms: filterByKey(key: "incoterms") {
          ...facetListFilter
        }
        countryOfShipment: filterByKey(key: "countryOfShipment") {
          ...facetListFilter
        }
        priceCoefficient: filterByKey(key: "priceCoefficient") {
          ...facetRangeFilter
        }
        wineOfferType: filterByKey(key: "wineOfferType") {
          ...facetListFilter
        }
        updatedAt: filterByKey(key: "updatedAt") {
          ...facetDateRangeFilter
        }
        periodOfValidity: filterByKey(key: "periodOfValidity") {
          ...facetDateRangeFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetBooleanFilterFragmentDoc}
${FacetRangeFilterFragmentDoc}
${FacetDateRangeFilterFragmentDoc}`;

/**
 * __useRequestOffersFiltersQuery__
 *
 * To run a query within a React component, call `useRequestOffersFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestOffersFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestOffersFiltersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sellerCompanyId: // value for 'sellerCompanyId'
 *      localeId: // value for 'localeId'
 *      productSearch: // value for 'productSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useRequestOffersFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.RequestOffersFiltersQuery, Types.RequestOffersFiltersQueryVariables> & ({ variables: Types.RequestOffersFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RequestOffersFiltersQuery, Types.RequestOffersFiltersQueryVariables>(RequestOffersFiltersDocument, options);
      }
export function useRequestOffersFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RequestOffersFiltersQuery, Types.RequestOffersFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RequestOffersFiltersQuery, Types.RequestOffersFiltersQueryVariables>(RequestOffersFiltersDocument, options);
        }
export function useRequestOffersFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RequestOffersFiltersQuery, Types.RequestOffersFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RequestOffersFiltersQuery, Types.RequestOffersFiltersQueryVariables>(RequestOffersFiltersDocument, options);
        }
export type RequestOffersFiltersQueryHookResult = ReturnType<typeof useRequestOffersFiltersQuery>;
export type RequestOffersFiltersLazyQueryHookResult = ReturnType<typeof useRequestOffersFiltersLazyQuery>;
export type RequestOffersFiltersSuspenseQueryHookResult = ReturnType<typeof useRequestOffersFiltersSuspenseQuery>;
export type RequestOffersFiltersQueryResult = Apollo.QueryResult<Types.RequestOffersFiltersQuery, Types.RequestOffersFiltersQueryVariables>;
export const RequestOffersFiltersResultsCountDocument = gql`
    query requestOffersFiltersResultsCount($id: ID!, $sellerCompanyId: Int!, $localeId: Int!, $productSearch: String, $facetsInputs: [FacetInput!]) {
  wineOfferRequest(id: $id) {
    matches {
      wineOffersFilters(
        localeId: $localeId
        filter: {companyId: $sellerCompanyId, facetsInputs: $facetsInputs}
        query: $productSearch
      ) {
        count
      }
    }
  }
}
    `;

/**
 * __useRequestOffersFiltersResultsCountQuery__
 *
 * To run a query within a React component, call `useRequestOffersFiltersResultsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestOffersFiltersResultsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestOffersFiltersResultsCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sellerCompanyId: // value for 'sellerCompanyId'
 *      localeId: // value for 'localeId'
 *      productSearch: // value for 'productSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *   },
 * });
 */
export function useRequestOffersFiltersResultsCountQuery(baseOptions: Apollo.QueryHookOptions<Types.RequestOffersFiltersResultsCountQuery, Types.RequestOffersFiltersResultsCountQueryVariables> & ({ variables: Types.RequestOffersFiltersResultsCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RequestOffersFiltersResultsCountQuery, Types.RequestOffersFiltersResultsCountQueryVariables>(RequestOffersFiltersResultsCountDocument, options);
      }
export function useRequestOffersFiltersResultsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RequestOffersFiltersResultsCountQuery, Types.RequestOffersFiltersResultsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RequestOffersFiltersResultsCountQuery, Types.RequestOffersFiltersResultsCountQueryVariables>(RequestOffersFiltersResultsCountDocument, options);
        }
export function useRequestOffersFiltersResultsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RequestOffersFiltersResultsCountQuery, Types.RequestOffersFiltersResultsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RequestOffersFiltersResultsCountQuery, Types.RequestOffersFiltersResultsCountQueryVariables>(RequestOffersFiltersResultsCountDocument, options);
        }
export type RequestOffersFiltersResultsCountQueryHookResult = ReturnType<typeof useRequestOffersFiltersResultsCountQuery>;
export type RequestOffersFiltersResultsCountLazyQueryHookResult = ReturnType<typeof useRequestOffersFiltersResultsCountLazyQuery>;
export type RequestOffersFiltersResultsCountSuspenseQueryHookResult = ReturnType<typeof useRequestOffersFiltersResultsCountSuspenseQuery>;
export type RequestOffersFiltersResultsCountQueryResult = Apollo.QueryResult<Types.RequestOffersFiltersResultsCountQuery, Types.RequestOffersFiltersResultsCountQueryVariables>;
export const RequestWinesFilterDocument = gql`
    query requestWinesFilter($id: ID!, $sellerCompanyId: Int!, $filterKey: String!, $localeId: Int!, $productSearch: String, $facetsInputs: [FacetInput!], $page: PositiveInt! = 1, $perPage: PositiveInt! = 10, $filterSearch: String, $includeUnusedLabels: Boolean = true) {
  wineOfferRequest(id: $id) {
    matches {
      productsFilters(
        localeId: $localeId
        filter: {companyId: $sellerCompanyId, facetsInputs: $facetsInputs}
        query: $productSearch
      ) {
        filterByKey(key: $filterKey) {
          ...facetListFilter
          ...facetComplexFilter
          ...facetBooleanFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetBooleanFilterFragmentDoc}`;

/**
 * __useRequestWinesFilterQuery__
 *
 * To run a query within a React component, call `useRequestWinesFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestWinesFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestWinesFilterQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sellerCompanyId: // value for 'sellerCompanyId'
 *      filterKey: // value for 'filterKey'
 *      localeId: // value for 'localeId'
 *      productSearch: // value for 'productSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useRequestWinesFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.RequestWinesFilterQuery, Types.RequestWinesFilterQueryVariables> & ({ variables: Types.RequestWinesFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RequestWinesFilterQuery, Types.RequestWinesFilterQueryVariables>(RequestWinesFilterDocument, options);
      }
export function useRequestWinesFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RequestWinesFilterQuery, Types.RequestWinesFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RequestWinesFilterQuery, Types.RequestWinesFilterQueryVariables>(RequestWinesFilterDocument, options);
        }
export function useRequestWinesFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RequestWinesFilterQuery, Types.RequestWinesFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RequestWinesFilterQuery, Types.RequestWinesFilterQueryVariables>(RequestWinesFilterDocument, options);
        }
export type RequestWinesFilterQueryHookResult = ReturnType<typeof useRequestWinesFilterQuery>;
export type RequestWinesFilterLazyQueryHookResult = ReturnType<typeof useRequestWinesFilterLazyQuery>;
export type RequestWinesFilterSuspenseQueryHookResult = ReturnType<typeof useRequestWinesFilterSuspenseQuery>;
export type RequestWinesFilterQueryResult = Apollo.QueryResult<Types.RequestWinesFilterQuery, Types.RequestWinesFilterQueryVariables>;
export const RequestWinesFiltersDocument = gql`
    query requestWinesFilters($id: ID!, $sellerCompanyId: Int!, $localeId: Int!, $productSearch: String, $facetsInputs: [FacetInput!], $page: PositiveInt! = 1, $perPage: PositiveInt! = 10, $filterSearch: String = "", $includeUnusedLabels: Boolean = true) {
  wineOfferRequest(id: $id) {
    matches {
      productsFilters(
        localeId: $localeId
        filter: {companyId: $sellerCompanyId, facetsInputs: $facetsInputs}
        query: $productSearch
      ) {
        count
        country: filterByKey(key: "wine.country") {
          ...facetListFilter
        }
        brand: filterByKey(key: "wine.brand") {
          ...facetListFilter
        }
        nationalGIType: filterByKey(key: "wine.nationalGIType") {
          ...facetListFilter
        }
        wineRegionEU: filterByKey(key: "wine.wineRegionEU") {
          ...facetListFilter
        }
        geoIndication: filterByKey(key: "wine.geoIndication") {
          ...facetListFilter
        }
        specialClassification: filterByKey(key: "wine.specialClassification") {
          ...facetListFilter
        }
        wineType: filterByKey(key: "wine.wineType") {
          ...facetListFilter
        }
        wineColour: filterByKey(key: "wine.wineColor") {
          ...facetListFilter
        }
        sweetnessType: filterByKey(key: "wine.sweetnessLevel") {
          ...facetListFilter
        }
        vintage: filterByKey(key: "wine.vintage") {
          ...facetListFilter
        }
        bottleSize: filterByKey(key: "bottleSize") {
          ...facetListFilter
        }
        grapes: filterByKey(key: "grapeAndMonocepage") {
          ...facetComplexFilter
        }
        certification: filterByKey(key: "wine.certification") {
          ...facetListFilter
        }
        wwlRating: filterByKey(key: "wine.rating") {
          ...facetListFilter
        }
        rrp: filterByKey(key: "priceAtWinery") {
          ...facetComplexFilter
        }
        reviews: filterByKey(key: "havePublicReviewsBool") {
          ...facetBooleanFilter
        }
      }
    }
  }
}
    ${FacetListFilterFragmentDoc}
${FacetComplexFilterFragmentDoc}
${FacetBooleanFilterFragmentDoc}`;

/**
 * __useRequestWinesFiltersQuery__
 *
 * To run a query within a React component, call `useRequestWinesFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestWinesFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestWinesFiltersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sellerCompanyId: // value for 'sellerCompanyId'
 *      localeId: // value for 'localeId'
 *      productSearch: // value for 'productSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterSearch: // value for 'filterSearch'
 *      includeUnusedLabels: // value for 'includeUnusedLabels'
 *   },
 * });
 */
export function useRequestWinesFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.RequestWinesFiltersQuery, Types.RequestWinesFiltersQueryVariables> & ({ variables: Types.RequestWinesFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RequestWinesFiltersQuery, Types.RequestWinesFiltersQueryVariables>(RequestWinesFiltersDocument, options);
      }
export function useRequestWinesFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RequestWinesFiltersQuery, Types.RequestWinesFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RequestWinesFiltersQuery, Types.RequestWinesFiltersQueryVariables>(RequestWinesFiltersDocument, options);
        }
export function useRequestWinesFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RequestWinesFiltersQuery, Types.RequestWinesFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RequestWinesFiltersQuery, Types.RequestWinesFiltersQueryVariables>(RequestWinesFiltersDocument, options);
        }
export type RequestWinesFiltersQueryHookResult = ReturnType<typeof useRequestWinesFiltersQuery>;
export type RequestWinesFiltersLazyQueryHookResult = ReturnType<typeof useRequestWinesFiltersLazyQuery>;
export type RequestWinesFiltersSuspenseQueryHookResult = ReturnType<typeof useRequestWinesFiltersSuspenseQuery>;
export type RequestWinesFiltersQueryResult = Apollo.QueryResult<Types.RequestWinesFiltersQuery, Types.RequestWinesFiltersQueryVariables>;
export const RequestWinesFiltersResultsCountDocument = gql`
    query requestWinesFiltersResultsCount($id: ID!, $sellerCompanyId: Int!, $localeId: Int!, $productSearch: String, $facetsInputs: [FacetInput!]) {
  wineOfferRequest(id: $id) {
    matches {
      productsFilters(
        localeId: $localeId
        filter: {companyId: $sellerCompanyId, facetsInputs: $facetsInputs}
        query: $productSearch
      ) {
        count
      }
    }
  }
}
    `;

/**
 * __useRequestWinesFiltersResultsCountQuery__
 *
 * To run a query within a React component, call `useRequestWinesFiltersResultsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestWinesFiltersResultsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestWinesFiltersResultsCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sellerCompanyId: // value for 'sellerCompanyId'
 *      localeId: // value for 'localeId'
 *      productSearch: // value for 'productSearch'
 *      facetsInputs: // value for 'facetsInputs'
 *   },
 * });
 */
export function useRequestWinesFiltersResultsCountQuery(baseOptions: Apollo.QueryHookOptions<Types.RequestWinesFiltersResultsCountQuery, Types.RequestWinesFiltersResultsCountQueryVariables> & ({ variables: Types.RequestWinesFiltersResultsCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RequestWinesFiltersResultsCountQuery, Types.RequestWinesFiltersResultsCountQueryVariables>(RequestWinesFiltersResultsCountDocument, options);
      }
export function useRequestWinesFiltersResultsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RequestWinesFiltersResultsCountQuery, Types.RequestWinesFiltersResultsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RequestWinesFiltersResultsCountQuery, Types.RequestWinesFiltersResultsCountQueryVariables>(RequestWinesFiltersResultsCountDocument, options);
        }
export function useRequestWinesFiltersResultsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RequestWinesFiltersResultsCountQuery, Types.RequestWinesFiltersResultsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RequestWinesFiltersResultsCountQuery, Types.RequestWinesFiltersResultsCountQueryVariables>(RequestWinesFiltersResultsCountDocument, options);
        }
export type RequestWinesFiltersResultsCountQueryHookResult = ReturnType<typeof useRequestWinesFiltersResultsCountQuery>;
export type RequestWinesFiltersResultsCountLazyQueryHookResult = ReturnType<typeof useRequestWinesFiltersResultsCountLazyQuery>;
export type RequestWinesFiltersResultsCountSuspenseQueryHookResult = ReturnType<typeof useRequestWinesFiltersResultsCountSuspenseQuery>;
export type RequestWinesFiltersResultsCountQueryResult = Apollo.QueryResult<Types.RequestWinesFiltersResultsCountQuery, Types.RequestWinesFiltersResultsCountQueryVariables>;
export const SellWineProductImagesUpdateDocument = gql`
    mutation sellWineProductImagesUpdate($input: ProductImagesUpdateInput!) {
  productImagesUpdate(input: $input) {
    record {
      id
      bottleImage {
        imageUrl
        thumbnail48Url: imageUrl(transformations: {width: 48, height: 48, resize: fit})
        thumbnailUrl: imageUrl(transformations: {width: 232, height: 232, resize: fit})
        thumbnailRetinaUrl: imageUrl(
          transformations: {width: 464, height: 464, resize: fit}
        )
      }
      frontLabelImage {
        imageUrl
        thumbnail48Url: imageUrl(transformations: {width: 48, height: 48, resize: fit})
        thumbnailUrl: imageUrl(transformations: {width: 232, height: 232, resize: fit})
        thumbnailRetinaUrl: imageUrl(
          transformations: {width: 464, height: 464, resize: fit}
        )
      }
      backLabelImage {
        imageUrl
        thumbnail48Url: imageUrl(transformations: {width: 48, height: 48, resize: fit})
        thumbnailUrl: imageUrl(transformations: {width: 232, height: 232, resize: fit})
        thumbnailRetinaUrl: imageUrl(
          transformations: {width: 464, height: 464, resize: fit}
        )
      }
    }
  }
}
    `;
export type SellWineProductImagesUpdateMutationFn = Apollo.MutationFunction<Types.SellWineProductImagesUpdateMutation, Types.SellWineProductImagesUpdateMutationVariables>;

/**
 * __useSellWineProductImagesUpdateMutation__
 *
 * To run a mutation, you first call `useSellWineProductImagesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSellWineProductImagesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sellWineProductImagesUpdateMutation, { data, loading, error }] = useSellWineProductImagesUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSellWineProductImagesUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.SellWineProductImagesUpdateMutation, Types.SellWineProductImagesUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SellWineProductImagesUpdateMutation, Types.SellWineProductImagesUpdateMutationVariables>(SellWineProductImagesUpdateDocument, options);
      }
export type SellWineProductImagesUpdateMutationHookResult = ReturnType<typeof useSellWineProductImagesUpdateMutation>;
export type SellWineProductImagesUpdateMutationResult = Apollo.MutationResult<Types.SellWineProductImagesUpdateMutation>;
export type SellWineProductImagesUpdateMutationOptions = Apollo.BaseMutationOptions<Types.SellWineProductImagesUpdateMutation, Types.SellWineProductImagesUpdateMutationVariables>;
export const UsernameTakenDocument = gql`
    query usernameTaken($username: String!) {
  usernameTaken(username: $username)
}
    `;

/**
 * __useUsernameTakenQuery__
 *
 * To run a query within a React component, call `useUsernameTakenQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsernameTakenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsernameTakenQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUsernameTakenQuery(baseOptions: Apollo.QueryHookOptions<Types.UsernameTakenQuery, Types.UsernameTakenQueryVariables> & ({ variables: Types.UsernameTakenQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UsernameTakenQuery, Types.UsernameTakenQueryVariables>(UsernameTakenDocument, options);
      }
export function useUsernameTakenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UsernameTakenQuery, Types.UsernameTakenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UsernameTakenQuery, Types.UsernameTakenQueryVariables>(UsernameTakenDocument, options);
        }
export function useUsernameTakenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UsernameTakenQuery, Types.UsernameTakenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UsernameTakenQuery, Types.UsernameTakenQueryVariables>(UsernameTakenDocument, options);
        }
export type UsernameTakenQueryHookResult = ReturnType<typeof useUsernameTakenQuery>;
export type UsernameTakenLazyQueryHookResult = ReturnType<typeof useUsernameTakenLazyQuery>;
export type UsernameTakenSuspenseQueryHookResult = ReturnType<typeof useUsernameTakenSuspenseQuery>;
export type UsernameTakenQueryResult = Apollo.QueryResult<Types.UsernameTakenQuery, Types.UsernameTakenQueryVariables>;
export const SignUpDocument = gql`
    mutation SignUp($email: EmailAddress!, $password: String!, $username: String!, $localeId: Int, $recaptchaResponse: String) {
  signUp(
    email: $email
    password: $password
    username: $username
    localeId: $localeId
    recaptchaResponse: $recaptchaResponse
  ) {
    token
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<Types.SignUpMutation, Types.SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      username: // value for 'username'
 *      localeId: // value for 'localeId'
 *      recaptchaResponse: // value for 'recaptchaResponse'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<Types.SignUpMutation, Types.SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SignUpMutation, Types.SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<Types.SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<Types.SignUpMutation, Types.SignUpMutationVariables>;
export const CompanyRemoveDocument = gql`
    mutation companyRemove($input: CompanyRemoveInput!) {
  companyRemove(input: $input) {
    query {
      _
    }
  }
}
    `;
export type CompanyRemoveMutationFn = Apollo.MutationFunction<Types.CompanyRemoveMutation, Types.CompanyRemoveMutationVariables>;

/**
 * __useCompanyRemoveMutation__
 *
 * To run a mutation, you first call `useCompanyRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyRemoveMutation, { data, loading, error }] = useCompanyRemoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyRemoveMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompanyRemoveMutation, Types.CompanyRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompanyRemoveMutation, Types.CompanyRemoveMutationVariables>(CompanyRemoveDocument, options);
      }
export type CompanyRemoveMutationHookResult = ReturnType<typeof useCompanyRemoveMutation>;
export type CompanyRemoveMutationResult = Apollo.MutationResult<Types.CompanyRemoveMutation>;
export type CompanyRemoveMutationOptions = Apollo.BaseMutationOptions<Types.CompanyRemoveMutation, Types.CompanyRemoveMutationVariables>;
export const UserCompaniesDocument = gql`
    query userCompanies($localeId: Int!) {
  me {
    id
    companies {
      id
      tradeName
      companyCategory {
        id
        name: localizedName(localeId: $localeId)
        masterName
      }
      postCountry {
        id
        name: localizedName(localeId: $localeId)
      }
      postAddress
      postCode
      isVerifiedUser
      avatar {
        imageUrl
      }
    }
  }
}
    `;

/**
 * __useUserCompaniesQuery__
 *
 * To run a query within a React component, call `useUserCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCompaniesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useUserCompaniesQuery(baseOptions: Apollo.QueryHookOptions<Types.UserCompaniesQuery, Types.UserCompaniesQueryVariables> & ({ variables: Types.UserCompaniesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserCompaniesQuery, Types.UserCompaniesQueryVariables>(UserCompaniesDocument, options);
      }
export function useUserCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserCompaniesQuery, Types.UserCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserCompaniesQuery, Types.UserCompaniesQueryVariables>(UserCompaniesDocument, options);
        }
export function useUserCompaniesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UserCompaniesQuery, Types.UserCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UserCompaniesQuery, Types.UserCompaniesQueryVariables>(UserCompaniesDocument, options);
        }
export type UserCompaniesQueryHookResult = ReturnType<typeof useUserCompaniesQuery>;
export type UserCompaniesLazyQueryHookResult = ReturnType<typeof useUserCompaniesLazyQuery>;
export type UserCompaniesSuspenseQueryHookResult = ReturnType<typeof useUserCompaniesSuspenseQuery>;
export type UserCompaniesQueryResult = Apollo.QueryResult<Types.UserCompaniesQuery, Types.UserCompaniesQueryVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser {
  userDelete {
    query {
      _
    }
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<Types.DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($oldPassword: String!, $newPassword: String!) {
  changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
    token
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<Types.ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>;
export const SendChangeEmailEmailDocument = gql`
    mutation sendChangeEmailEmail($email: EmailAddress!, $localeId: Int!) {
  sendChangeEmailEmail(email: $email, localeId: $localeId)
}
    `;
export type SendChangeEmailEmailMutationFn = Apollo.MutationFunction<Types.SendChangeEmailEmailMutation, Types.SendChangeEmailEmailMutationVariables>;

/**
 * __useSendChangeEmailEmailMutation__
 *
 * To run a mutation, you first call `useSendChangeEmailEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChangeEmailEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChangeEmailEmailMutation, { data, loading, error }] = useSendChangeEmailEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useSendChangeEmailEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendChangeEmailEmailMutation, Types.SendChangeEmailEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendChangeEmailEmailMutation, Types.SendChangeEmailEmailMutationVariables>(SendChangeEmailEmailDocument, options);
      }
export type SendChangeEmailEmailMutationHookResult = ReturnType<typeof useSendChangeEmailEmailMutation>;
export type SendChangeEmailEmailMutationResult = Apollo.MutationResult<Types.SendChangeEmailEmailMutation>;
export type SendChangeEmailEmailMutationOptions = Apollo.BaseMutationOptions<Types.SendChangeEmailEmailMutation, Types.SendChangeEmailEmailMutationVariables>;
export const UserProfileDocument = gql`
    query userProfile($id: ID!, $localeId: Int!) {
  user(id: $id) {
    id
    avatar {
      imageUrl
      thumbnailUrl: imageUrl(transformations: {width: 80, height: 80, resize: fit})
      thumbnailRetinaUrl: imageUrl(
        transformations: {width: 160, height: 160, resize: fit}
      )
    }
    name
    lastName
    username
    email
    bio
    facebook
    twitter
    linkedin
    website
    permissions {
      reviewsDisplayed
      reviewsConsidered
    }
    expertVerification {
      status {
        id
        localizedName(localeId: $localeId)
        code
      }
    }
    expertQualification {
      id
      localizedName(localeId: $localeId)
    }
    expertQualificationDocs
    country {
      id
      localizedName(localeId: $localeId)
      codeISO
    }
    createdAt
  }
}
    `;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useUserProfileQuery(baseOptions: Apollo.QueryHookOptions<Types.UserProfileQuery, Types.UserProfileQueryVariables> & ({ variables: Types.UserProfileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserProfileQuery, Types.UserProfileQueryVariables>(UserProfileDocument, options);
      }
export function useUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserProfileQuery, Types.UserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserProfileQuery, Types.UserProfileQueryVariables>(UserProfileDocument, options);
        }
export function useUserProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UserProfileQuery, Types.UserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UserProfileQuery, Types.UserProfileQueryVariables>(UserProfileDocument, options);
        }
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;
export type UserProfileSuspenseQueryHookResult = ReturnType<typeof useUserProfileSuspenseQuery>;
export type UserProfileQueryResult = Apollo.QueryResult<Types.UserProfileQuery, Types.UserProfileQueryVariables>;
export const UserUpdateDocument = gql`
    mutation userUpdate($input: UserInput!) {
  userUpdate(input: $input) {
    id
  }
}
    `;
export type UserUpdateMutationFn = Apollo.MutationFunction<Types.UserUpdateMutation, Types.UserUpdateMutationVariables>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.UserUpdateMutation, Types.UserUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UserUpdateMutation, Types.UserUpdateMutationVariables>(UserUpdateDocument, options);
      }
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = Apollo.MutationResult<Types.UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<Types.UserUpdateMutation, Types.UserUpdateMutationVariables>;
export const ChangeLocaleDocument = gql`
    mutation ChangeLocale($localeId: Int!) {
  changeLocale(localeId: $localeId) {
    id
  }
}
    `;
export type ChangeLocaleMutationFn = Apollo.MutationFunction<Types.ChangeLocaleMutation, Types.ChangeLocaleMutationVariables>;

/**
 * __useChangeLocaleMutation__
 *
 * To run a mutation, you first call `useChangeLocaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeLocaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeLocaleMutation, { data, loading, error }] = useChangeLocaleMutation({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useChangeLocaleMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangeLocaleMutation, Types.ChangeLocaleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ChangeLocaleMutation, Types.ChangeLocaleMutationVariables>(ChangeLocaleDocument, options);
      }
export type ChangeLocaleMutationHookResult = ReturnType<typeof useChangeLocaleMutation>;
export type ChangeLocaleMutationResult = Apollo.MutationResult<Types.ChangeLocaleMutation>;
export type ChangeLocaleMutationOptions = Apollo.BaseMutationOptions<Types.ChangeLocaleMutation, Types.ChangeLocaleMutationVariables>;
export const CompanyVerifyByEmailDocument = gql`
    mutation companyVerifyByEmail($verifyToken: String!, $localeId: Int!) {
  companyVerifyByEmail(verifyToken: $verifyToken, localeId: $localeId) {
    success: _
  }
}
    `;
export type CompanyVerifyByEmailMutationFn = Apollo.MutationFunction<Types.CompanyVerifyByEmailMutation, Types.CompanyVerifyByEmailMutationVariables>;

/**
 * __useCompanyVerifyByEmailMutation__
 *
 * To run a mutation, you first call `useCompanyVerifyByEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyVerifyByEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyVerifyByEmailMutation, { data, loading, error }] = useCompanyVerifyByEmailMutation({
 *   variables: {
 *      verifyToken: // value for 'verifyToken'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useCompanyVerifyByEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompanyVerifyByEmailMutation, Types.CompanyVerifyByEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompanyVerifyByEmailMutation, Types.CompanyVerifyByEmailMutationVariables>(CompanyVerifyByEmailDocument, options);
      }
export type CompanyVerifyByEmailMutationHookResult = ReturnType<typeof useCompanyVerifyByEmailMutation>;
export type CompanyVerifyByEmailMutationResult = Apollo.MutationResult<Types.CompanyVerifyByEmailMutation>;
export type CompanyVerifyByEmailMutationOptions = Apollo.BaseMutationOptions<Types.CompanyVerifyByEmailMutation, Types.CompanyVerifyByEmailMutationVariables>;
export const CreateWineDocument = gql`
    mutation createWine($input: WineCreateInput!) {
  wineCreate(input: $input) {
    record {
      id
    }
  }
}
    `;
export type CreateWineMutationFn = Apollo.MutationFunction<Types.CreateWineMutation, Types.CreateWineMutationVariables>;

/**
 * __useCreateWineMutation__
 *
 * To run a mutation, you first call `useCreateWineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWineMutation, { data, loading, error }] = useCreateWineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWineMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateWineMutation, Types.CreateWineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateWineMutation, Types.CreateWineMutationVariables>(CreateWineDocument, options);
      }
export type CreateWineMutationHookResult = ReturnType<typeof useCreateWineMutation>;
export type CreateWineMutationResult = Apollo.MutationResult<Types.CreateWineMutation>;
export type CreateWineMutationOptions = Apollo.BaseMutationOptions<Types.CreateWineMutation, Types.CreateWineMutationVariables>;
export const WineByCompositeIdDocument = gql`
    query wineByCompositeId($compositeId: WineCompositeIDInput!) {
  wineByCompositeId(compositeId: $compositeId) {
    id
    acceptability
    canOverrideAcceptability
    canOverrideFacts
  }
}
    `;

/**
 * __useWineByCompositeIdQuery__
 *
 * To run a query within a React component, call `useWineByCompositeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineByCompositeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineByCompositeIdQuery({
 *   variables: {
 *      compositeId: // value for 'compositeId'
 *   },
 * });
 */
export function useWineByCompositeIdQuery(baseOptions: Apollo.QueryHookOptions<Types.WineByCompositeIdQuery, Types.WineByCompositeIdQueryVariables> & ({ variables: Types.WineByCompositeIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineByCompositeIdQuery, Types.WineByCompositeIdQueryVariables>(WineByCompositeIdDocument, options);
      }
export function useWineByCompositeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineByCompositeIdQuery, Types.WineByCompositeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineByCompositeIdQuery, Types.WineByCompositeIdQueryVariables>(WineByCompositeIdDocument, options);
        }
export function useWineByCompositeIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineByCompositeIdQuery, Types.WineByCompositeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineByCompositeIdQuery, Types.WineByCompositeIdQueryVariables>(WineByCompositeIdDocument, options);
        }
export type WineByCompositeIdQueryHookResult = ReturnType<typeof useWineByCompositeIdQuery>;
export type WineByCompositeIdLazyQueryHookResult = ReturnType<typeof useWineByCompositeIdLazyQuery>;
export type WineByCompositeIdSuspenseQueryHookResult = ReturnType<typeof useWineByCompositeIdSuspenseQuery>;
export type WineByCompositeIdQueryResult = Apollo.QueryResult<Types.WineByCompositeIdQuery, Types.WineByCompositeIdQueryVariables>;
export const WineProductsUpdateDocument = gql`
    mutation wineProductsUpdate($input: WineProductsUpdateInput!) {
  wineProductsUpdate(input: $input) {
    query {
      _
    }
  }
}
    `;
export type WineProductsUpdateMutationFn = Apollo.MutationFunction<Types.WineProductsUpdateMutation, Types.WineProductsUpdateMutationVariables>;

/**
 * __useWineProductsUpdateMutation__
 *
 * To run a mutation, you first call `useWineProductsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineProductsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineProductsUpdateMutation, { data, loading, error }] = useWineProductsUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineProductsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineProductsUpdateMutation, Types.WineProductsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineProductsUpdateMutation, Types.WineProductsUpdateMutationVariables>(WineProductsUpdateDocument, options);
      }
export type WineProductsUpdateMutationHookResult = ReturnType<typeof useWineProductsUpdateMutation>;
export type WineProductsUpdateMutationResult = Apollo.MutationResult<Types.WineProductsUpdateMutation>;
export type WineProductsUpdateMutationOptions = Apollo.BaseMutationOptions<Types.WineProductsUpdateMutation, Types.WineProductsUpdateMutationVariables>;
export const NationalGiTypesDocument = gql`
    query nationalGITypes($localeId: Int!, $countryId: Int!) {
  country(id: $countryId) {
    id
    nationalGITypes {
      id
      localizedFullName(localeId: $localeId)
      localizedShortName(localeId: $localeId)
      euGIType {
        id
        masterName
      }
    }
  }
}
    `;

/**
 * __useNationalGiTypesQuery__
 *
 * To run a query within a React component, call `useNationalGiTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNationalGiTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNationalGiTypesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useNationalGiTypesQuery(baseOptions: Apollo.QueryHookOptions<Types.NationalGiTypesQuery, Types.NationalGiTypesQueryVariables> & ({ variables: Types.NationalGiTypesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NationalGiTypesQuery, Types.NationalGiTypesQueryVariables>(NationalGiTypesDocument, options);
      }
export function useNationalGiTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NationalGiTypesQuery, Types.NationalGiTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NationalGiTypesQuery, Types.NationalGiTypesQueryVariables>(NationalGiTypesDocument, options);
        }
export function useNationalGiTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.NationalGiTypesQuery, Types.NationalGiTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.NationalGiTypesQuery, Types.NationalGiTypesQueryVariables>(NationalGiTypesDocument, options);
        }
export type NationalGiTypesQueryHookResult = ReturnType<typeof useNationalGiTypesQuery>;
export type NationalGiTypesLazyQueryHookResult = ReturnType<typeof useNationalGiTypesLazyQuery>;
export type NationalGiTypesSuspenseQueryHookResult = ReturnType<typeof useNationalGiTypesSuspenseQuery>;
export type NationalGiTypesQueryResult = Apollo.QueryResult<Types.NationalGiTypesQuery, Types.NationalGiTypesQueryVariables>;
export const SpecialClassificationsForWineCreateDocument = gql`
    query specialClassificationsForWineCreate($wineTypeId: Int, $wineColorId: Int, $sweetnessLevelId: Int, $nationalGITypeId: Int, $geoIndicationId: [Int], $companyId: ID!) {
  specialClassifications(
    filter: {wineTypeId: $wineTypeId, wineColorId: $wineColorId, sweetnessLevelId: $sweetnessLevelId, nationalGITypeId: $nationalGITypeId, geoIndicationId: $geoIndicationId, tradeNameId: $companyId}
  ) {
    id
    name
  }
}
    `;

/**
 * __useSpecialClassificationsForWineCreateQuery__
 *
 * To run a query within a React component, call `useSpecialClassificationsForWineCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecialClassificationsForWineCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecialClassificationsForWineCreateQuery({
 *   variables: {
 *      wineTypeId: // value for 'wineTypeId'
 *      wineColorId: // value for 'wineColorId'
 *      sweetnessLevelId: // value for 'sweetnessLevelId'
 *      nationalGITypeId: // value for 'nationalGITypeId'
 *      geoIndicationId: // value for 'geoIndicationId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSpecialClassificationsForWineCreateQuery(baseOptions: Apollo.QueryHookOptions<Types.SpecialClassificationsForWineCreateQuery, Types.SpecialClassificationsForWineCreateQueryVariables> & ({ variables: Types.SpecialClassificationsForWineCreateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SpecialClassificationsForWineCreateQuery, Types.SpecialClassificationsForWineCreateQueryVariables>(SpecialClassificationsForWineCreateDocument, options);
      }
export function useSpecialClassificationsForWineCreateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SpecialClassificationsForWineCreateQuery, Types.SpecialClassificationsForWineCreateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SpecialClassificationsForWineCreateQuery, Types.SpecialClassificationsForWineCreateQueryVariables>(SpecialClassificationsForWineCreateDocument, options);
        }
export function useSpecialClassificationsForWineCreateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SpecialClassificationsForWineCreateQuery, Types.SpecialClassificationsForWineCreateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SpecialClassificationsForWineCreateQuery, Types.SpecialClassificationsForWineCreateQueryVariables>(SpecialClassificationsForWineCreateDocument, options);
        }
export type SpecialClassificationsForWineCreateQueryHookResult = ReturnType<typeof useSpecialClassificationsForWineCreateQuery>;
export type SpecialClassificationsForWineCreateLazyQueryHookResult = ReturnType<typeof useSpecialClassificationsForWineCreateLazyQuery>;
export type SpecialClassificationsForWineCreateSuspenseQueryHookResult = ReturnType<typeof useSpecialClassificationsForWineCreateSuspenseQuery>;
export type SpecialClassificationsForWineCreateQueryResult = Apollo.QueryResult<Types.SpecialClassificationsForWineCreateQuery, Types.SpecialClassificationsForWineCreateQueryVariables>;
export const TradeNameBrandForWineCreateDocument = gql`
    query tradeNameBrandForWineCreate($tradeNameId: ID!, $brandId: ID!) {
  tradeNameBrand(tradeNameId: $tradeNameId, brandId: $brandId)
}
    `;

/**
 * __useTradeNameBrandForWineCreateQuery__
 *
 * To run a query within a React component, call `useTradeNameBrandForWineCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeNameBrandForWineCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeNameBrandForWineCreateQuery({
 *   variables: {
 *      tradeNameId: // value for 'tradeNameId'
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function useTradeNameBrandForWineCreateQuery(baseOptions: Apollo.QueryHookOptions<Types.TradeNameBrandForWineCreateQuery, Types.TradeNameBrandForWineCreateQueryVariables> & ({ variables: Types.TradeNameBrandForWineCreateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradeNameBrandForWineCreateQuery, Types.TradeNameBrandForWineCreateQueryVariables>(TradeNameBrandForWineCreateDocument, options);
      }
export function useTradeNameBrandForWineCreateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradeNameBrandForWineCreateQuery, Types.TradeNameBrandForWineCreateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradeNameBrandForWineCreateQuery, Types.TradeNameBrandForWineCreateQueryVariables>(TradeNameBrandForWineCreateDocument, options);
        }
export function useTradeNameBrandForWineCreateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradeNameBrandForWineCreateQuery, Types.TradeNameBrandForWineCreateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradeNameBrandForWineCreateQuery, Types.TradeNameBrandForWineCreateQueryVariables>(TradeNameBrandForWineCreateDocument, options);
        }
export type TradeNameBrandForWineCreateQueryHookResult = ReturnType<typeof useTradeNameBrandForWineCreateQuery>;
export type TradeNameBrandForWineCreateLazyQueryHookResult = ReturnType<typeof useTradeNameBrandForWineCreateLazyQuery>;
export type TradeNameBrandForWineCreateSuspenseQueryHookResult = ReturnType<typeof useTradeNameBrandForWineCreateSuspenseQuery>;
export type TradeNameBrandForWineCreateQueryResult = Apollo.QueryResult<Types.TradeNameBrandForWineCreateQuery, Types.TradeNameBrandForWineCreateQueryVariables>;
export const TradeNameSearchForWineCreateDocument = gql`
    query tradeNameSearchForWineCreate($query: String, $countryId: Int, $localeId: Int!) {
  tradeNameInstantSearch(
    query: $query
    filter: {countryId: $countryId}
    localeId: $localeId
  ) {
    id
    tradeName
  }
}
    `;

/**
 * __useTradeNameSearchForWineCreateQuery__
 *
 * To run a query within a React component, call `useTradeNameSearchForWineCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeNameSearchForWineCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeNameSearchForWineCreateQuery({
 *   variables: {
 *      query: // value for 'query'
 *      countryId: // value for 'countryId'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useTradeNameSearchForWineCreateQuery(baseOptions: Apollo.QueryHookOptions<Types.TradeNameSearchForWineCreateQuery, Types.TradeNameSearchForWineCreateQueryVariables> & ({ variables: Types.TradeNameSearchForWineCreateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradeNameSearchForWineCreateQuery, Types.TradeNameSearchForWineCreateQueryVariables>(TradeNameSearchForWineCreateDocument, options);
      }
export function useTradeNameSearchForWineCreateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradeNameSearchForWineCreateQuery, Types.TradeNameSearchForWineCreateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradeNameSearchForWineCreateQuery, Types.TradeNameSearchForWineCreateQueryVariables>(TradeNameSearchForWineCreateDocument, options);
        }
export function useTradeNameSearchForWineCreateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradeNameSearchForWineCreateQuery, Types.TradeNameSearchForWineCreateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradeNameSearchForWineCreateQuery, Types.TradeNameSearchForWineCreateQueryVariables>(TradeNameSearchForWineCreateDocument, options);
        }
export type TradeNameSearchForWineCreateQueryHookResult = ReturnType<typeof useTradeNameSearchForWineCreateQuery>;
export type TradeNameSearchForWineCreateLazyQueryHookResult = ReturnType<typeof useTradeNameSearchForWineCreateLazyQuery>;
export type TradeNameSearchForWineCreateSuspenseQueryHookResult = ReturnType<typeof useTradeNameSearchForWineCreateSuspenseQuery>;
export type TradeNameSearchForWineCreateQueryResult = Apollo.QueryResult<Types.TradeNameSearchForWineCreateQuery, Types.TradeNameSearchForWineCreateQueryVariables>;
export const SweetnessByCountryDocument = gql`
    query sweetnessByCountry($localeId: Int!, $countryId: Int!, $wineTypeId: Int!) {
  country(id: $countryId) {
    id
    sweetnessLevels(wineTypeId: $wineTypeId) {
      id
      isDefault
      localizedName(localeId: $localeId)
    }
  }
}
    `;

/**
 * __useSweetnessByCountryQuery__
 *
 * To run a query within a React component, call `useSweetnessByCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSweetnessByCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSweetnessByCountryQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *      countryId: // value for 'countryId'
 *      wineTypeId: // value for 'wineTypeId'
 *   },
 * });
 */
export function useSweetnessByCountryQuery(baseOptions: Apollo.QueryHookOptions<Types.SweetnessByCountryQuery, Types.SweetnessByCountryQueryVariables> & ({ variables: Types.SweetnessByCountryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SweetnessByCountryQuery, Types.SweetnessByCountryQueryVariables>(SweetnessByCountryDocument, options);
      }
export function useSweetnessByCountryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SweetnessByCountryQuery, Types.SweetnessByCountryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SweetnessByCountryQuery, Types.SweetnessByCountryQueryVariables>(SweetnessByCountryDocument, options);
        }
export function useSweetnessByCountrySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SweetnessByCountryQuery, Types.SweetnessByCountryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SweetnessByCountryQuery, Types.SweetnessByCountryQueryVariables>(SweetnessByCountryDocument, options);
        }
export type SweetnessByCountryQueryHookResult = ReturnType<typeof useSweetnessByCountryQuery>;
export type SweetnessByCountryLazyQueryHookResult = ReturnType<typeof useSweetnessByCountryLazyQuery>;
export type SweetnessByCountrySuspenseQueryHookResult = ReturnType<typeof useSweetnessByCountrySuspenseQuery>;
export type SweetnessByCountryQueryResult = Apollo.QueryResult<Types.SweetnessByCountryQuery, Types.SweetnessByCountryQueryVariables>;
export const PreloadWineDataForCreateDocument = gql`
    query preloadWineDataForCreate($companyId: ID!, $localeId: Int!) {
  me {
    id
    name
    company(id: $companyId) {
      id
      producerTradeName {
        id
        tradeName
        country {
          id
          localizedName(localeId: $localeId)
          codeISO
          isEUMember
        }
      }
      companyCategory {
        id
        masterName
      }
    }
  }
}
    `;

/**
 * __usePreloadWineDataForCreateQuery__
 *
 * To run a query within a React component, call `usePreloadWineDataForCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreloadWineDataForCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreloadWineDataForCreateQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function usePreloadWineDataForCreateQuery(baseOptions: Apollo.QueryHookOptions<Types.PreloadWineDataForCreateQuery, Types.PreloadWineDataForCreateQueryVariables> & ({ variables: Types.PreloadWineDataForCreateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PreloadWineDataForCreateQuery, Types.PreloadWineDataForCreateQueryVariables>(PreloadWineDataForCreateDocument, options);
      }
export function usePreloadWineDataForCreateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PreloadWineDataForCreateQuery, Types.PreloadWineDataForCreateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PreloadWineDataForCreateQuery, Types.PreloadWineDataForCreateQueryVariables>(PreloadWineDataForCreateDocument, options);
        }
export function usePreloadWineDataForCreateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PreloadWineDataForCreateQuery, Types.PreloadWineDataForCreateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PreloadWineDataForCreateQuery, Types.PreloadWineDataForCreateQueryVariables>(PreloadWineDataForCreateDocument, options);
        }
export type PreloadWineDataForCreateQueryHookResult = ReturnType<typeof usePreloadWineDataForCreateQuery>;
export type PreloadWineDataForCreateLazyQueryHookResult = ReturnType<typeof usePreloadWineDataForCreateLazyQuery>;
export type PreloadWineDataForCreateSuspenseQueryHookResult = ReturnType<typeof usePreloadWineDataForCreateSuspenseQuery>;
export type PreloadWineDataForCreateQueryResult = Apollo.QueryResult<Types.PreloadWineDataForCreateQuery, Types.PreloadWineDataForCreateQueryVariables>;
export const ResponsibleConsumptionsDocument = gql`
    query responsibleConsumptions($localeId: Int!) {
  responsibleConsumptions {
    id
    logo
    localizedName(localeId: $localeId)
  }
}
    `;

/**
 * __useResponsibleConsumptionsQuery__
 *
 * To run a query within a React component, call `useResponsibleConsumptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResponsibleConsumptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResponsibleConsumptionsQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useResponsibleConsumptionsQuery(baseOptions: Apollo.QueryHookOptions<Types.ResponsibleConsumptionsQuery, Types.ResponsibleConsumptionsQueryVariables> & ({ variables: Types.ResponsibleConsumptionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ResponsibleConsumptionsQuery, Types.ResponsibleConsumptionsQueryVariables>(ResponsibleConsumptionsDocument, options);
      }
export function useResponsibleConsumptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ResponsibleConsumptionsQuery, Types.ResponsibleConsumptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ResponsibleConsumptionsQuery, Types.ResponsibleConsumptionsQueryVariables>(ResponsibleConsumptionsDocument, options);
        }
export function useResponsibleConsumptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ResponsibleConsumptionsQuery, Types.ResponsibleConsumptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ResponsibleConsumptionsQuery, Types.ResponsibleConsumptionsQueryVariables>(ResponsibleConsumptionsDocument, options);
        }
export type ResponsibleConsumptionsQueryHookResult = ReturnType<typeof useResponsibleConsumptionsQuery>;
export type ResponsibleConsumptionsLazyQueryHookResult = ReturnType<typeof useResponsibleConsumptionsLazyQuery>;
export type ResponsibleConsumptionsSuspenseQueryHookResult = ReturnType<typeof useResponsibleConsumptionsSuspenseQuery>;
export type ResponsibleConsumptionsQueryResult = Apollo.QueryResult<Types.ResponsibleConsumptionsQuery, Types.ResponsibleConsumptionsQueryVariables>;
export const AwardDetailsForWineEditDocument = gql`
    query awardDetailsForWineEdit($id: Int!, $localeId: Int!) {
  award(id: $id) {
    id
    masterName
    minScore
    maxScore
    roundScore
    stepScore
    rewards {
      id
      name
      shortName
      minYear
      maxYear
      rewardType {
        id
        masterName
        localizedName(localeId: $localeId)
      }
    }
    rewardTypes {
      id
      masterName
      localizedName(localeId: $localeId)
    }
  }
}
    `;

/**
 * __useAwardDetailsForWineEditQuery__
 *
 * To run a query within a React component, call `useAwardDetailsForWineEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useAwardDetailsForWineEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAwardDetailsForWineEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useAwardDetailsForWineEditQuery(baseOptions: Apollo.QueryHookOptions<Types.AwardDetailsForWineEditQuery, Types.AwardDetailsForWineEditQueryVariables> & ({ variables: Types.AwardDetailsForWineEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AwardDetailsForWineEditQuery, Types.AwardDetailsForWineEditQueryVariables>(AwardDetailsForWineEditDocument, options);
      }
export function useAwardDetailsForWineEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AwardDetailsForWineEditQuery, Types.AwardDetailsForWineEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AwardDetailsForWineEditQuery, Types.AwardDetailsForWineEditQueryVariables>(AwardDetailsForWineEditDocument, options);
        }
export function useAwardDetailsForWineEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AwardDetailsForWineEditQuery, Types.AwardDetailsForWineEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AwardDetailsForWineEditQuery, Types.AwardDetailsForWineEditQueryVariables>(AwardDetailsForWineEditDocument, options);
        }
export type AwardDetailsForWineEditQueryHookResult = ReturnType<typeof useAwardDetailsForWineEditQuery>;
export type AwardDetailsForWineEditLazyQueryHookResult = ReturnType<typeof useAwardDetailsForWineEditLazyQuery>;
export type AwardDetailsForWineEditSuspenseQueryHookResult = ReturnType<typeof useAwardDetailsForWineEditSuspenseQuery>;
export type AwardDetailsForWineEditQueryResult = Apollo.QueryResult<Types.AwardDetailsForWineEditQuery, Types.AwardDetailsForWineEditQueryVariables>;
export const WineAwardByCompositeIdDocument = gql`
    query wineAwardByCompositeId($id: WineAwardCompositeIDInput!, $localeId: Int!) {
  wineAwardByCompositeId(compositeId: $id) {
    id
    acceptability
    canOverrideAcceptability
    canOverrideFacts
    maxScore
    minScore
    year
    rewards {
      id
      maxYear
      minYear
      name
      shortName
      rewardType {
        id
        masterName
        localizedName(localeId: $localeId)
      }
    }
    award {
      id
      masterName
      masterNameShort
      maxScore
      minScore
      roundScore
      stepScore
    }
  }
}
    `;

/**
 * __useWineAwardByCompositeIdQuery__
 *
 * To run a query within a React component, call `useWineAwardByCompositeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineAwardByCompositeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineAwardByCompositeIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useWineAwardByCompositeIdQuery(baseOptions: Apollo.QueryHookOptions<Types.WineAwardByCompositeIdQuery, Types.WineAwardByCompositeIdQueryVariables> & ({ variables: Types.WineAwardByCompositeIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineAwardByCompositeIdQuery, Types.WineAwardByCompositeIdQueryVariables>(WineAwardByCompositeIdDocument, options);
      }
export function useWineAwardByCompositeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineAwardByCompositeIdQuery, Types.WineAwardByCompositeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineAwardByCompositeIdQuery, Types.WineAwardByCompositeIdQueryVariables>(WineAwardByCompositeIdDocument, options);
        }
export function useWineAwardByCompositeIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineAwardByCompositeIdQuery, Types.WineAwardByCompositeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineAwardByCompositeIdQuery, Types.WineAwardByCompositeIdQueryVariables>(WineAwardByCompositeIdDocument, options);
        }
export type WineAwardByCompositeIdQueryHookResult = ReturnType<typeof useWineAwardByCompositeIdQuery>;
export type WineAwardByCompositeIdLazyQueryHookResult = ReturnType<typeof useWineAwardByCompositeIdLazyQuery>;
export type WineAwardByCompositeIdSuspenseQueryHookResult = ReturnType<typeof useWineAwardByCompositeIdSuspenseQuery>;
export type WineAwardByCompositeIdQueryResult = Apollo.QueryResult<Types.WineAwardByCompositeIdQuery, Types.WineAwardByCompositeIdQueryVariables>;
export const AwardsForWineEditDocument = gql`
    query awardsForWineEdit($id: ID!, $localeId: Int!) {
  wine(id: $id) {
    id
    ...techSheetWineFields
    techSheet(localeId: $localeId) {
      ...techSheetFields
    }
    products {
      ...techSheetProductFields
    }
    awards {
      id
      acceptability
      canOverrideAcceptability
      canOverrideFacts
      maxScore
      minScore
      year
      rewards {
        id
        maxYear
        minYear
        name
        shortName
        rewardType {
          id
          masterName
          localizedName(localeId: $localeId)
        }
      }
      award {
        id
        masterName
        masterNameShort
        maxScore
        minScore
        roundScore
        stepScore
      }
    }
  }
}
    ${TechSheetWineFieldsFragmentDoc}
${TechSheetFieldsFragmentDoc}
${TechSheetProductFieldsFragmentDoc}`;

/**
 * __useAwardsForWineEditQuery__
 *
 * To run a query within a React component, call `useAwardsForWineEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useAwardsForWineEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAwardsForWineEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useAwardsForWineEditQuery(baseOptions: Apollo.QueryHookOptions<Types.AwardsForWineEditQuery, Types.AwardsForWineEditQueryVariables> & ({ variables: Types.AwardsForWineEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AwardsForWineEditQuery, Types.AwardsForWineEditQueryVariables>(AwardsForWineEditDocument, options);
      }
export function useAwardsForWineEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AwardsForWineEditQuery, Types.AwardsForWineEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AwardsForWineEditQuery, Types.AwardsForWineEditQueryVariables>(AwardsForWineEditDocument, options);
        }
export function useAwardsForWineEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AwardsForWineEditQuery, Types.AwardsForWineEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AwardsForWineEditQuery, Types.AwardsForWineEditQueryVariables>(AwardsForWineEditDocument, options);
        }
export type AwardsForWineEditQueryHookResult = ReturnType<typeof useAwardsForWineEditQuery>;
export type AwardsForWineEditLazyQueryHookResult = ReturnType<typeof useAwardsForWineEditLazyQuery>;
export type AwardsForWineEditSuspenseQueryHookResult = ReturnType<typeof useAwardsForWineEditSuspenseQuery>;
export type AwardsForWineEditQueryResult = Apollo.QueryResult<Types.AwardsForWineEditQuery, Types.AwardsForWineEditQueryVariables>;
export const WineAwardsUpdateDocument = gql`
    mutation wineAwardsUpdate($input: WineAwardsUpdateInput!) {
  wineAwardsUpdate(input: $input) {
    recordId
  }
}
    `;
export type WineAwardsUpdateMutationFn = Apollo.MutationFunction<Types.WineAwardsUpdateMutation, Types.WineAwardsUpdateMutationVariables>;

/**
 * __useWineAwardsUpdateMutation__
 *
 * To run a mutation, you first call `useWineAwardsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineAwardsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineAwardsUpdateMutation, { data, loading, error }] = useWineAwardsUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineAwardsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineAwardsUpdateMutation, Types.WineAwardsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineAwardsUpdateMutation, Types.WineAwardsUpdateMutationVariables>(WineAwardsUpdateDocument, options);
      }
export type WineAwardsUpdateMutationHookResult = ReturnType<typeof useWineAwardsUpdateMutation>;
export type WineAwardsUpdateMutationResult = Apollo.MutationResult<Types.WineAwardsUpdateMutation>;
export type WineAwardsUpdateMutationOptions = Apollo.BaseMutationOptions<Types.WineAwardsUpdateMutation, Types.WineAwardsUpdateMutationVariables>;
export const EditWineGeneralVocabulariesDocument = gql`
    query editWineGeneralVocabularies($localeId: Int!) {
  annualProductionUnits: unitGroupByName(
    masterName: "Wine.AdditionalInfo.annualProduction"
  ) {
    units {
      id
      masterName
    }
  }
  acidityTotalUnits: unitGroupByName(
    masterName: "Wine.ChemicalAnalysis.acidityTotal"
  ) {
    units {
      id
      masterName
    }
  }
  wineTypesEU {
    id
    localizedName(localeId: $localeId)
  }
  ingredients {
    id
    localizedName(localeId: $localeId)
    code
    category {
      id
      localizedName(localeId: $localeId)
      isPrintable
    }
  }
  responsibleConsumptions {
    id
    logo
    localizedName(localeId: $localeId)
  }
}
    `;

/**
 * __useEditWineGeneralVocabulariesQuery__
 *
 * To run a query within a React component, call `useEditWineGeneralVocabulariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditWineGeneralVocabulariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditWineGeneralVocabulariesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useEditWineGeneralVocabulariesQuery(baseOptions: Apollo.QueryHookOptions<Types.EditWineGeneralVocabulariesQuery, Types.EditWineGeneralVocabulariesQueryVariables> & ({ variables: Types.EditWineGeneralVocabulariesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EditWineGeneralVocabulariesQuery, Types.EditWineGeneralVocabulariesQueryVariables>(EditWineGeneralVocabulariesDocument, options);
      }
export function useEditWineGeneralVocabulariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EditWineGeneralVocabulariesQuery, Types.EditWineGeneralVocabulariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EditWineGeneralVocabulariesQuery, Types.EditWineGeneralVocabulariesQueryVariables>(EditWineGeneralVocabulariesDocument, options);
        }
export function useEditWineGeneralVocabulariesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EditWineGeneralVocabulariesQuery, Types.EditWineGeneralVocabulariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EditWineGeneralVocabulariesQuery, Types.EditWineGeneralVocabulariesQueryVariables>(EditWineGeneralVocabulariesDocument, options);
        }
export type EditWineGeneralVocabulariesQueryHookResult = ReturnType<typeof useEditWineGeneralVocabulariesQuery>;
export type EditWineGeneralVocabulariesLazyQueryHookResult = ReturnType<typeof useEditWineGeneralVocabulariesLazyQuery>;
export type EditWineGeneralVocabulariesSuspenseQueryHookResult = ReturnType<typeof useEditWineGeneralVocabulariesSuspenseQuery>;
export type EditWineGeneralVocabulariesQueryResult = Apollo.QueryResult<Types.EditWineGeneralVocabulariesQuery, Types.EditWineGeneralVocabulariesQueryVariables>;
export const GeneralDataForWineEditDocument = gql`
    query generalDataForWineEdit($id: ID!, $localeId: Int!) {
  wine(id: $id) {
    id
    canOverrideAcceptability
    canOverrideFacts
    ...techSheetWineFields
    techSheet(localeId: $localeId) {
      ...techSheetFields
    }
    wineTypeEU {
      id
      localizedName(localeId: $localeId)
    }
    ingredients {
      id
      localizedName(localeId: $localeId)
      code
      category {
        id
        localizedName(localeId: $localeId)
        isPrintable
      }
    }
    chemicalAnalysis {
      id
      acidityTotal {
        value
        unit {
          id
          masterName
        }
      }
      pH
      residualSugar
      alcoholVolume
    }
    certs {
      id
      localizedName(localeId: $localeId)
      logo
    }
    responsibleConsumptions {
      id
      localizedName(localeId: $localeId)
      logo
    }
    grapeVarieties {
      value
      grapeVarietySynonym {
        id
        name
      }
    }
    servingTemperatureMin
    servingTemperatureMax
    foodPairs {
      id
      masterName
      localizedName(localeId: $localeId)
    }
    nutritionDeclaration {
      carbohydrate
      carbohydrateApproximate
      energyKCal
      energyKCalApproximate
      energyKJ
      fat
      fatApproximate
      protein
      proteinApproximate
      salt
      saltApproximate
      saturates
      saturatesApproximate
      sugars
      sugarsApproximate
    }
    products {
      ...techSheetProductFields
    }
  }
}
    ${TechSheetWineFieldsFragmentDoc}
${TechSheetFieldsFragmentDoc}
${TechSheetProductFieldsFragmentDoc}`;

/**
 * __useGeneralDataForWineEditQuery__
 *
 * To run a query within a React component, call `useGeneralDataForWineEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneralDataForWineEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneralDataForWineEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useGeneralDataForWineEditQuery(baseOptions: Apollo.QueryHookOptions<Types.GeneralDataForWineEditQuery, Types.GeneralDataForWineEditQueryVariables> & ({ variables: Types.GeneralDataForWineEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GeneralDataForWineEditQuery, Types.GeneralDataForWineEditQueryVariables>(GeneralDataForWineEditDocument, options);
      }
export function useGeneralDataForWineEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GeneralDataForWineEditQuery, Types.GeneralDataForWineEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GeneralDataForWineEditQuery, Types.GeneralDataForWineEditQueryVariables>(GeneralDataForWineEditDocument, options);
        }
export function useGeneralDataForWineEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GeneralDataForWineEditQuery, Types.GeneralDataForWineEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GeneralDataForWineEditQuery, Types.GeneralDataForWineEditQueryVariables>(GeneralDataForWineEditDocument, options);
        }
export type GeneralDataForWineEditQueryHookResult = ReturnType<typeof useGeneralDataForWineEditQuery>;
export type GeneralDataForWineEditLazyQueryHookResult = ReturnType<typeof useGeneralDataForWineEditLazyQuery>;
export type GeneralDataForWineEditSuspenseQueryHookResult = ReturnType<typeof useGeneralDataForWineEditSuspenseQuery>;
export type GeneralDataForWineEditQueryResult = Apollo.QueryResult<Types.GeneralDataForWineEditQuery, Types.GeneralDataForWineEditQueryVariables>;
export const UpdateWineDocument = gql`
    mutation updateWine($input: WineUpdateInput!) {
  wineUpdate(input: $input) {
    recordId
  }
}
    `;
export type UpdateWineMutationFn = Apollo.MutationFunction<Types.UpdateWineMutation, Types.UpdateWineMutationVariables>;

/**
 * __useUpdateWineMutation__
 *
 * To run a mutation, you first call `useUpdateWineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWineMutation, { data, loading, error }] = useUpdateWineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWineMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateWineMutation, Types.UpdateWineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateWineMutation, Types.UpdateWineMutationVariables>(UpdateWineDocument, options);
      }
export type UpdateWineMutationHookResult = ReturnType<typeof useUpdateWineMutation>;
export type UpdateWineMutationResult = Apollo.MutationResult<Types.UpdateWineMutation>;
export type UpdateWineMutationOptions = Apollo.BaseMutationOptions<Types.UpdateWineMutation, Types.UpdateWineMutationVariables>;
export const ProductByCompositeIdForWineEditDocument = gql`
    query productByCompositeIdForWineEdit($id: ProductCompositeIDInput!, $localeId: Int!) {
  productByCompositeId(compositeId: $id) {
    id
    acceptability
    canOverrideAcceptability
    canOverrideFacts
    verified
    shouldOverrideAcceptability
    bottleSize {
      id
      volumeML
    }
    packageType {
      id
      isDefault
      shortName
      localizedName(localeId: $localeId)
    }
  }
}
    `;

/**
 * __useProductByCompositeIdForWineEditQuery__
 *
 * To run a query within a React component, call `useProductByCompositeIdForWineEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductByCompositeIdForWineEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductByCompositeIdForWineEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useProductByCompositeIdForWineEditQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductByCompositeIdForWineEditQuery, Types.ProductByCompositeIdForWineEditQueryVariables> & ({ variables: Types.ProductByCompositeIdForWineEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductByCompositeIdForWineEditQuery, Types.ProductByCompositeIdForWineEditQueryVariables>(ProductByCompositeIdForWineEditDocument, options);
      }
export function useProductByCompositeIdForWineEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductByCompositeIdForWineEditQuery, Types.ProductByCompositeIdForWineEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductByCompositeIdForWineEditQuery, Types.ProductByCompositeIdForWineEditQueryVariables>(ProductByCompositeIdForWineEditDocument, options);
        }
export function useProductByCompositeIdForWineEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductByCompositeIdForWineEditQuery, Types.ProductByCompositeIdForWineEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductByCompositeIdForWineEditQuery, Types.ProductByCompositeIdForWineEditQueryVariables>(ProductByCompositeIdForWineEditDocument, options);
        }
export type ProductByCompositeIdForWineEditQueryHookResult = ReturnType<typeof useProductByCompositeIdForWineEditQuery>;
export type ProductByCompositeIdForWineEditLazyQueryHookResult = ReturnType<typeof useProductByCompositeIdForWineEditLazyQuery>;
export type ProductByCompositeIdForWineEditSuspenseQueryHookResult = ReturnType<typeof useProductByCompositeIdForWineEditSuspenseQuery>;
export type ProductByCompositeIdForWineEditQueryResult = Apollo.QueryResult<Types.ProductByCompositeIdForWineEditQuery, Types.ProductByCompositeIdForWineEditQueryVariables>;
export const WineKeyWordsUpdateDocument = gql`
    mutation wineKeyWordsUpdate($input: WineKeyWordsUpdateInput!) {
  wineKeyWordsUpdate(input: $input) {
    recordId
  }
}
    `;
export type WineKeyWordsUpdateMutationFn = Apollo.MutationFunction<Types.WineKeyWordsUpdateMutation, Types.WineKeyWordsUpdateMutationVariables>;

/**
 * __useWineKeyWordsUpdateMutation__
 *
 * To run a mutation, you first call `useWineKeyWordsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWineKeyWordsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wineKeyWordsUpdateMutation, { data, loading, error }] = useWineKeyWordsUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWineKeyWordsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.WineKeyWordsUpdateMutation, Types.WineKeyWordsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WineKeyWordsUpdateMutation, Types.WineKeyWordsUpdateMutationVariables>(WineKeyWordsUpdateDocument, options);
      }
export type WineKeyWordsUpdateMutationHookResult = ReturnType<typeof useWineKeyWordsUpdateMutation>;
export type WineKeyWordsUpdateMutationResult = Apollo.MutationResult<Types.WineKeyWordsUpdateMutation>;
export type WineKeyWordsUpdateMutationOptions = Apollo.BaseMutationOptions<Types.WineKeyWordsUpdateMutation, Types.WineKeyWordsUpdateMutationVariables>;
export const IdentificationForWineEditDocument = gql`
    query IdentificationForWineEdit($id: ID!, $localeId: Int!) {
  wine(id: $id) {
    id
    canOverrideAcceptability
    canOverrideFacts
    ...techSheetWineFields
    tradeName {
      id
      tradeName
      country {
        id
        codeISO
        localizedName(localeId: $localeId)
      }
    }
    keyWords
    country {
      id
      codeISO
      localizedName(localeId: $localeId)
    }
    brand {
      id
      localizedName(localeId: $localeId)
    }
    vintage
    tradeNameBrand
    keyWords
    vintage
    specialClassifications {
      id
      name
    }
    wineType {
      id
      isDefault
      localizedName(localeId: $localeId)
    }
    wineColor {
      id
      localizedName(localeId: $localeId)
    }
    sweetnessLevel {
      id
      isDefault
      localizedName(localeId: $localeId)
    }
    geoIndications {
      id
      wineRegionsEU {
        id
        localizedName(localeId: $localeId)
      }
    }
    geoIndicationText
    geoIndications {
      id
      localizedName(localeId: $localeId)
    }
    nationalGITypeText
    nationalGIType {
      localizedShortName(localeId: $localeId)
    }
    products {
      id
      acceptability
      canOverrideAcceptability
      canOverrideFacts
      shouldOverrideAcceptability
      verified
      bottleSize {
        id
        volumeML
      }
      packageType {
        id
        isDefault
        shortName
        localizedName(localeId: $localeId)
      }
    }
  }
}
    ${TechSheetWineFieldsFragmentDoc}`;

/**
 * __useIdentificationForWineEditQuery__
 *
 * To run a query within a React component, call `useIdentificationForWineEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdentificationForWineEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdentificationForWineEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useIdentificationForWineEditQuery(baseOptions: Apollo.QueryHookOptions<Types.IdentificationForWineEditQuery, Types.IdentificationForWineEditQueryVariables> & ({ variables: Types.IdentificationForWineEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IdentificationForWineEditQuery, Types.IdentificationForWineEditQueryVariables>(IdentificationForWineEditDocument, options);
      }
export function useIdentificationForWineEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IdentificationForWineEditQuery, Types.IdentificationForWineEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IdentificationForWineEditQuery, Types.IdentificationForWineEditQueryVariables>(IdentificationForWineEditDocument, options);
        }
export function useIdentificationForWineEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.IdentificationForWineEditQuery, Types.IdentificationForWineEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IdentificationForWineEditQuery, Types.IdentificationForWineEditQueryVariables>(IdentificationForWineEditDocument, options);
        }
export type IdentificationForWineEditQueryHookResult = ReturnType<typeof useIdentificationForWineEditQuery>;
export type IdentificationForWineEditLazyQueryHookResult = ReturnType<typeof useIdentificationForWineEditLazyQuery>;
export type IdentificationForWineEditSuspenseQueryHookResult = ReturnType<typeof useIdentificationForWineEditSuspenseQuery>;
export type IdentificationForWineEditQueryResult = Apollo.QueryResult<Types.IdentificationForWineEditQuery, Types.IdentificationForWineEditQueryVariables>;
export const EditWineProductsVocabulariesDocument = gql`
    query editWineProductsVocabularies($localeId: Int!) {
  annualProductionUnits: unitGroupByName(
    masterName: "Wine.AdditionalInfo.annualProduction"
  ) {
    units {
      id
      masterName
      localizedName(localeId: $localeId)
    }
  }
  currencies {
    id
    code
  }
}
    `;

/**
 * __useEditWineProductsVocabulariesQuery__
 *
 * To run a query within a React component, call `useEditWineProductsVocabulariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditWineProductsVocabulariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditWineProductsVocabulariesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useEditWineProductsVocabulariesQuery(baseOptions: Apollo.QueryHookOptions<Types.EditWineProductsVocabulariesQuery, Types.EditWineProductsVocabulariesQueryVariables> & ({ variables: Types.EditWineProductsVocabulariesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EditWineProductsVocabulariesQuery, Types.EditWineProductsVocabulariesQueryVariables>(EditWineProductsVocabulariesDocument, options);
      }
export function useEditWineProductsVocabulariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EditWineProductsVocabulariesQuery, Types.EditWineProductsVocabulariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EditWineProductsVocabulariesQuery, Types.EditWineProductsVocabulariesQueryVariables>(EditWineProductsVocabulariesDocument, options);
        }
export function useEditWineProductsVocabulariesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EditWineProductsVocabulariesQuery, Types.EditWineProductsVocabulariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EditWineProductsVocabulariesQuery, Types.EditWineProductsVocabulariesQueryVariables>(EditWineProductsVocabulariesDocument, options);
        }
export type EditWineProductsVocabulariesQueryHookResult = ReturnType<typeof useEditWineProductsVocabulariesQuery>;
export type EditWineProductsVocabulariesLazyQueryHookResult = ReturnType<typeof useEditWineProductsVocabulariesLazyQuery>;
export type EditWineProductsVocabulariesSuspenseQueryHookResult = ReturnType<typeof useEditWineProductsVocabulariesSuspenseQuery>;
export type EditWineProductsVocabulariesQueryResult = Apollo.QueryResult<Types.EditWineProductsVocabulariesQuery, Types.EditWineProductsVocabulariesQueryVariables>;
export const ProductsForWineEditDocument = gql`
    query productsForWineEdit($id: ID!, $localeId: Int!) {
  wine(id: $id) {
    id
    ...techSheetWineFields
    techSheet(localeId: $localeId) {
      ...techSheetFields
    }
    products {
      id
      acceptability
      canOverrideAcceptability
      canOverrideFacts
      verified
      shouldOverrideAcceptability
      shortUrl
      bottleSize {
        id
        volumeML
      }
      packageType {
        id
        isDefault
        shortName
        localizedName(localeId: $localeId)
      }
      backLabelImage {
        imageUrl
        thumbnailUrl: imageUrl(transformations: {width: 302, height: 302, resize: fit})
        thumbnailRetinaUrl: imageUrl(
          transformations: {width: 604, height: 604, resize: fit}
        )
      }
      bottleImage {
        imageUrl
        thumbnailUrl: imageUrl(transformations: {width: 302, height: 302, resize: fit})
        thumbnailRetinaUrl: imageUrl(
          transformations: {width: 604, height: 604, resize: fit}
        )
      }
      frontLabelImage {
        imageUrl
        thumbnailUrl: imageUrl(transformations: {width: 302, height: 302, resize: fit})
        thumbnailRetinaUrl: imageUrl(
          transformations: {width: 604, height: 604, resize: fit}
        )
      }
      cellarDoorPrice
      cellarDoorPriceLink
      bottlingDate
      currency {
        id
        code
      }
      closureType {
        id
        localizedName(localeId: $localeId)
        masterName
      }
      annualProduction {
        value
        unit {
          id
          masterName
        }
      }
      gtin
      writePolicy {
        bottleImage
        frontLabelImage
        backLabelImage
        closureType
        bottlingDate
        annualProduction
        cellarDoorPrice
        cellarDoorPriceLink
        gtin
      }
    }
  }
}
    ${TechSheetWineFieldsFragmentDoc}
${TechSheetFieldsFragmentDoc}`;

/**
 * __useProductsForWineEditQuery__
 *
 * To run a query within a React component, call `useProductsForWineEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsForWineEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsForWineEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useProductsForWineEditQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductsForWineEditQuery, Types.ProductsForWineEditQueryVariables> & ({ variables: Types.ProductsForWineEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductsForWineEditQuery, Types.ProductsForWineEditQueryVariables>(ProductsForWineEditDocument, options);
      }
export function useProductsForWineEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductsForWineEditQuery, Types.ProductsForWineEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductsForWineEditQuery, Types.ProductsForWineEditQueryVariables>(ProductsForWineEditDocument, options);
        }
export function useProductsForWineEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductsForWineEditQuery, Types.ProductsForWineEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductsForWineEditQuery, Types.ProductsForWineEditQueryVariables>(ProductsForWineEditDocument, options);
        }
export type ProductsForWineEditQueryHookResult = ReturnType<typeof useProductsForWineEditQuery>;
export type ProductsForWineEditLazyQueryHookResult = ReturnType<typeof useProductsForWineEditLazyQuery>;
export type ProductsForWineEditSuspenseQueryHookResult = ReturnType<typeof useProductsForWineEditSuspenseQuery>;
export type ProductsForWineEditQueryResult = Apollo.QueryResult<Types.ProductsForWineEditQuery, Types.ProductsForWineEditQueryVariables>;
export const UpdateProductDocument = gql`
    mutation updateProduct($input: ProductUpdateInput!) {
  productUpdate(input: $input) {
    recordId
  }
}
    `;
export type UpdateProductMutationFn = Apollo.MutationFunction<Types.UpdateProductMutation, Types.UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateProductMutation, Types.UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateProductMutation, Types.UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<Types.UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<Types.UpdateProductMutation, Types.UpdateProductMutationVariables>;
export const TradeNameSearchForWineEditDocument = gql`
    query tradeNameSearchForWineEdit($query: String, $localeId: Int!) {
  tradeNameInstantSearch(query: $query, localeId: $localeId) {
    id
    tradeName
  }
}
    `;

/**
 * __useTradeNameSearchForWineEditQuery__
 *
 * To run a query within a React component, call `useTradeNameSearchForWineEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeNameSearchForWineEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeNameSearchForWineEditQuery({
 *   variables: {
 *      query: // value for 'query'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useTradeNameSearchForWineEditQuery(baseOptions: Apollo.QueryHookOptions<Types.TradeNameSearchForWineEditQuery, Types.TradeNameSearchForWineEditQueryVariables> & ({ variables: Types.TradeNameSearchForWineEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradeNameSearchForWineEditQuery, Types.TradeNameSearchForWineEditQueryVariables>(TradeNameSearchForWineEditDocument, options);
      }
export function useTradeNameSearchForWineEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradeNameSearchForWineEditQuery, Types.TradeNameSearchForWineEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradeNameSearchForWineEditQuery, Types.TradeNameSearchForWineEditQueryVariables>(TradeNameSearchForWineEditDocument, options);
        }
export function useTradeNameSearchForWineEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradeNameSearchForWineEditQuery, Types.TradeNameSearchForWineEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradeNameSearchForWineEditQuery, Types.TradeNameSearchForWineEditQueryVariables>(TradeNameSearchForWineEditDocument, options);
        }
export type TradeNameSearchForWineEditQueryHookResult = ReturnType<typeof useTradeNameSearchForWineEditQuery>;
export type TradeNameSearchForWineEditLazyQueryHookResult = ReturnType<typeof useTradeNameSearchForWineEditLazyQuery>;
export type TradeNameSearchForWineEditSuspenseQueryHookResult = ReturnType<typeof useTradeNameSearchForWineEditSuspenseQuery>;
export type TradeNameSearchForWineEditQueryResult = Apollo.QueryResult<Types.TradeNameSearchForWineEditQuery, Types.TradeNameSearchForWineEditQueryVariables>;
export const EditWineTechSheetsVocabulariesDocument = gql`
    query editWineTechSheetsVocabularies($localeId: Int!) {
  locales {
    id
    name
    locale
    localizedName(localeId: $localeId)
  }
}
    `;

/**
 * __useEditWineTechSheetsVocabulariesQuery__
 *
 * To run a query within a React component, call `useEditWineTechSheetsVocabulariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditWineTechSheetsVocabulariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditWineTechSheetsVocabulariesQuery({
 *   variables: {
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useEditWineTechSheetsVocabulariesQuery(baseOptions: Apollo.QueryHookOptions<Types.EditWineTechSheetsVocabulariesQuery, Types.EditWineTechSheetsVocabulariesQueryVariables> & ({ variables: Types.EditWineTechSheetsVocabulariesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EditWineTechSheetsVocabulariesQuery, Types.EditWineTechSheetsVocabulariesQueryVariables>(EditWineTechSheetsVocabulariesDocument, options);
      }
export function useEditWineTechSheetsVocabulariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EditWineTechSheetsVocabulariesQuery, Types.EditWineTechSheetsVocabulariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EditWineTechSheetsVocabulariesQuery, Types.EditWineTechSheetsVocabulariesQueryVariables>(EditWineTechSheetsVocabulariesDocument, options);
        }
export function useEditWineTechSheetsVocabulariesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EditWineTechSheetsVocabulariesQuery, Types.EditWineTechSheetsVocabulariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EditWineTechSheetsVocabulariesQuery, Types.EditWineTechSheetsVocabulariesQueryVariables>(EditWineTechSheetsVocabulariesDocument, options);
        }
export type EditWineTechSheetsVocabulariesQueryHookResult = ReturnType<typeof useEditWineTechSheetsVocabulariesQuery>;
export type EditWineTechSheetsVocabulariesLazyQueryHookResult = ReturnType<typeof useEditWineTechSheetsVocabulariesLazyQuery>;
export type EditWineTechSheetsVocabulariesSuspenseQueryHookResult = ReturnType<typeof useEditWineTechSheetsVocabulariesSuspenseQuery>;
export type EditWineTechSheetsVocabulariesQueryResult = Apollo.QueryResult<Types.EditWineTechSheetsVocabulariesQuery, Types.EditWineTechSheetsVocabulariesQueryVariables>;
export const LocalizedTechSheetForPreviewDocument = gql`
    query localizedTechSheetForPreview($wineId: ID!, $localeId: Int!) {
  wine(id: $wineId) {
    id
    ...techSheetWineFields
    products {
      ...techSheetProductFields
    }
  }
}
    ${TechSheetWineFieldsFragmentDoc}
${TechSheetProductFieldsFragmentDoc}`;

/**
 * __useLocalizedTechSheetForPreviewQuery__
 *
 * To run a query within a React component, call `useLocalizedTechSheetForPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocalizedTechSheetForPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocalizedTechSheetForPreviewQuery({
 *   variables: {
 *      wineId: // value for 'wineId'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useLocalizedTechSheetForPreviewQuery(baseOptions: Apollo.QueryHookOptions<Types.LocalizedTechSheetForPreviewQuery, Types.LocalizedTechSheetForPreviewQueryVariables> & ({ variables: Types.LocalizedTechSheetForPreviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LocalizedTechSheetForPreviewQuery, Types.LocalizedTechSheetForPreviewQueryVariables>(LocalizedTechSheetForPreviewDocument, options);
      }
export function useLocalizedTechSheetForPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LocalizedTechSheetForPreviewQuery, Types.LocalizedTechSheetForPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LocalizedTechSheetForPreviewQuery, Types.LocalizedTechSheetForPreviewQueryVariables>(LocalizedTechSheetForPreviewDocument, options);
        }
export function useLocalizedTechSheetForPreviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LocalizedTechSheetForPreviewQuery, Types.LocalizedTechSheetForPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LocalizedTechSheetForPreviewQuery, Types.LocalizedTechSheetForPreviewQueryVariables>(LocalizedTechSheetForPreviewDocument, options);
        }
export type LocalizedTechSheetForPreviewQueryHookResult = ReturnType<typeof useLocalizedTechSheetForPreviewQuery>;
export type LocalizedTechSheetForPreviewLazyQueryHookResult = ReturnType<typeof useLocalizedTechSheetForPreviewLazyQuery>;
export type LocalizedTechSheetForPreviewSuspenseQueryHookResult = ReturnType<typeof useLocalizedTechSheetForPreviewSuspenseQuery>;
export type LocalizedTechSheetForPreviewQueryResult = Apollo.QueryResult<Types.LocalizedTechSheetForPreviewQuery, Types.LocalizedTechSheetForPreviewQueryVariables>;
export const SaveTechSheetDocument = gql`
    mutation saveTechSheet($input: TechSheetSaveInput!) {
  techSheetSave(input: $input) {
    recordId
    record {
      ...techSheetFields
    }
  }
}
    ${TechSheetFieldsFragmentDoc}`;
export type SaveTechSheetMutationFn = Apollo.MutationFunction<Types.SaveTechSheetMutation, Types.SaveTechSheetMutationVariables>;

/**
 * __useSaveTechSheetMutation__
 *
 * To run a mutation, you first call `useSaveTechSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTechSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTechSheetMutation, { data, loading, error }] = useSaveTechSheetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveTechSheetMutation(baseOptions?: Apollo.MutationHookOptions<Types.SaveTechSheetMutation, Types.SaveTechSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SaveTechSheetMutation, Types.SaveTechSheetMutationVariables>(SaveTechSheetDocument, options);
      }
export type SaveTechSheetMutationHookResult = ReturnType<typeof useSaveTechSheetMutation>;
export type SaveTechSheetMutationResult = Apollo.MutationResult<Types.SaveTechSheetMutation>;
export type SaveTechSheetMutationOptions = Apollo.BaseMutationOptions<Types.SaveTechSheetMutation, Types.SaveTechSheetMutationVariables>;
export const TechSheetsForWineEditDocument = gql`
    query techSheetsForWineEdit($id: ID!, $localeId: Int!) {
  wine(id: $id) {
    id
    ...techSheetWineFields
    techSheet(localeId: $localeId) {
      ...techSheetFields
    }
    country {
      id
      codeISO
    }
    tradeName {
      id
      tradeName
      website
      phone
      email
    }
    awards {
      id
      award {
        id
        masterName
        masterNameShort
      }
      year
      maxScore
      minScore
      rewards {
        id
        name
        shortName
        rewardType {
          id
          masterName
        }
      }
    }
    products {
      id
      bottleSize {
        id
        volumeML
      }
      packageType {
        id
        isDefault
        shortName
        localizedName(localeId: $localeId)
      }
    }
    techSheets {
      canOverrideFacts
      ...techSheetFields
    }
  }
}
    ${TechSheetWineFieldsFragmentDoc}
${TechSheetFieldsFragmentDoc}`;

/**
 * __useTechSheetsForWineEditQuery__
 *
 * To run a query within a React component, call `useTechSheetsForWineEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechSheetsForWineEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechSheetsForWineEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useTechSheetsForWineEditQuery(baseOptions: Apollo.QueryHookOptions<Types.TechSheetsForWineEditQuery, Types.TechSheetsForWineEditQueryVariables> & ({ variables: Types.TechSheetsForWineEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TechSheetsForWineEditQuery, Types.TechSheetsForWineEditQueryVariables>(TechSheetsForWineEditDocument, options);
      }
export function useTechSheetsForWineEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TechSheetsForWineEditQuery, Types.TechSheetsForWineEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TechSheetsForWineEditQuery, Types.TechSheetsForWineEditQueryVariables>(TechSheetsForWineEditDocument, options);
        }
export function useTechSheetsForWineEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TechSheetsForWineEditQuery, Types.TechSheetsForWineEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TechSheetsForWineEditQuery, Types.TechSheetsForWineEditQueryVariables>(TechSheetsForWineEditDocument, options);
        }
export type TechSheetsForWineEditQueryHookResult = ReturnType<typeof useTechSheetsForWineEditQuery>;
export type TechSheetsForWineEditLazyQueryHookResult = ReturnType<typeof useTechSheetsForWineEditLazyQuery>;
export type TechSheetsForWineEditSuspenseQueryHookResult = ReturnType<typeof useTechSheetsForWineEditSuspenseQuery>;
export type TechSheetsForWineEditQueryResult = Apollo.QueryResult<Types.TechSheetsForWineEditQuery, Types.TechSheetsForWineEditQueryVariables>;
export const WineCheckRightsDocument = gql`
    query wineCheckRights($id: ID!) {
  wine(id: $id) {
    id
    acceptability
    tradeName {
      id
      acceptability
    }
    brand {
      id
      acceptability
    }
  }
}
    `;

/**
 * __useWineCheckRightsQuery__
 *
 * To run a query within a React component, call `useWineCheckRightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineCheckRightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineCheckRightsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWineCheckRightsQuery(baseOptions: Apollo.QueryHookOptions<Types.WineCheckRightsQuery, Types.WineCheckRightsQueryVariables> & ({ variables: Types.WineCheckRightsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineCheckRightsQuery, Types.WineCheckRightsQueryVariables>(WineCheckRightsDocument, options);
      }
export function useWineCheckRightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineCheckRightsQuery, Types.WineCheckRightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineCheckRightsQuery, Types.WineCheckRightsQueryVariables>(WineCheckRightsDocument, options);
        }
export function useWineCheckRightsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineCheckRightsQuery, Types.WineCheckRightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineCheckRightsQuery, Types.WineCheckRightsQueryVariables>(WineCheckRightsDocument, options);
        }
export type WineCheckRightsQueryHookResult = ReturnType<typeof useWineCheckRightsQuery>;
export type WineCheckRightsLazyQueryHookResult = ReturnType<typeof useWineCheckRightsLazyQuery>;
export type WineCheckRightsSuspenseQueryHookResult = ReturnType<typeof useWineCheckRightsSuspenseQuery>;
export type WineCheckRightsQueryResult = Apollo.QueryResult<Types.WineCheckRightsQuery, Types.WineCheckRightsQueryVariables>;
export const GetProductAppellationRatingPageDocument = gql`
    query getProductAppellationRatingPage($slug: String!, $localeId: Int!, $perPage: PositiveInt!, $geoIndicationId: Int!, $sort: ProductSort!) {
  productBySlug(slug: $slug) {
    id
    appellationRating(geoIndicationId: $geoIndicationId) {
      page(perPage: $perPage, localeId: $localeId, sort: $sort) {
        ...winePageOfProductAppellationRating
      }
    }
  }
}
    ${WinePageOfProductAppellationRatingFragmentDoc}`;

/**
 * __useGetProductAppellationRatingPageQuery__
 *
 * To run a query within a React component, call `useGetProductAppellationRatingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductAppellationRatingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductAppellationRatingPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      localeId: // value for 'localeId'
 *      perPage: // value for 'perPage'
 *      geoIndicationId: // value for 'geoIndicationId'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetProductAppellationRatingPageQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProductAppellationRatingPageQuery, Types.GetProductAppellationRatingPageQueryVariables> & ({ variables: Types.GetProductAppellationRatingPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProductAppellationRatingPageQuery, Types.GetProductAppellationRatingPageQueryVariables>(GetProductAppellationRatingPageDocument, options);
      }
export function useGetProductAppellationRatingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProductAppellationRatingPageQuery, Types.GetProductAppellationRatingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProductAppellationRatingPageQuery, Types.GetProductAppellationRatingPageQueryVariables>(GetProductAppellationRatingPageDocument, options);
        }
export function useGetProductAppellationRatingPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetProductAppellationRatingPageQuery, Types.GetProductAppellationRatingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetProductAppellationRatingPageQuery, Types.GetProductAppellationRatingPageQueryVariables>(GetProductAppellationRatingPageDocument, options);
        }
export type GetProductAppellationRatingPageQueryHookResult = ReturnType<typeof useGetProductAppellationRatingPageQuery>;
export type GetProductAppellationRatingPageLazyQueryHookResult = ReturnType<typeof useGetProductAppellationRatingPageLazyQuery>;
export type GetProductAppellationRatingPageSuspenseQueryHookResult = ReturnType<typeof useGetProductAppellationRatingPageSuspenseQuery>;
export type GetProductAppellationRatingPageQueryResult = Apollo.QueryResult<Types.GetProductAppellationRatingPageQuery, Types.GetProductAppellationRatingPageQueryVariables>;
export const ProductDataForRatingDocument = gql`
    query productDataForRating($slug: String!, $perPage: PositiveInt!, $geoIndicationId: Int!, $sort: ProductSort!, $localeId: Int!) {
  productBySlug(slug: $slug) {
    id
    slug
    appellationRating(geoIndicationId: $geoIndicationId) {
      count
      rank
      geoIndication {
        id
        localizedName(localeId: $localeId)
        nationalGITypes {
          id
          localizedShortName(localeId: $localeId)
        }
      }
      page(perPage: $perPage, localeId: $localeId, sort: $sort) {
        pageInfo {
          page
        }
      }
    }
    bottleSize {
      id
      volumeML
    }
    packageType {
      id
      isDefault
      shortName
      localizedName(localeId: $localeId)
    }
    recommendedRetailPrice
    currency {
      id
      code
    }
    wine {
      id
      tradeNameBrand
      tradeName {
        id
        tradeName
      }
      keyWords
      specialClassifications {
        id
        name
      }
      vintage
      wineType {
        id
        isDefault
        localizedName(localeId: $localeId)
      }
      wineColor {
        id
        localizedName(localeId: $localeId)
      }
      sweetnessLevel {
        id
        localizedName(localeId: $localeId)
      }
      country {
        id
        localizedName(localeId: $localeId)
      }
      geoIndications {
        id
        localizedName(localeId: $localeId)
      }
      nationalGIType {
        id
        localizedShortName(localeId: $localeId)
      }
      rating
      chemicalAnalysis {
        alcoholVolume
      }
    }
  }
}
    `;

/**
 * __useProductDataForRatingQuery__
 *
 * To run a query within a React component, call `useProductDataForRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDataForRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDataForRatingQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      perPage: // value for 'perPage'
 *      geoIndicationId: // value for 'geoIndicationId'
 *      sort: // value for 'sort'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useProductDataForRatingQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductDataForRatingQuery, Types.ProductDataForRatingQueryVariables> & ({ variables: Types.ProductDataForRatingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductDataForRatingQuery, Types.ProductDataForRatingQueryVariables>(ProductDataForRatingDocument, options);
      }
export function useProductDataForRatingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductDataForRatingQuery, Types.ProductDataForRatingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductDataForRatingQuery, Types.ProductDataForRatingQueryVariables>(ProductDataForRatingDocument, options);
        }
export function useProductDataForRatingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductDataForRatingQuery, Types.ProductDataForRatingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductDataForRatingQuery, Types.ProductDataForRatingQueryVariables>(ProductDataForRatingDocument, options);
        }
export type ProductDataForRatingQueryHookResult = ReturnType<typeof useProductDataForRatingQuery>;
export type ProductDataForRatingLazyQueryHookResult = ReturnType<typeof useProductDataForRatingLazyQuery>;
export type ProductDataForRatingSuspenseQueryHookResult = ReturnType<typeof useProductDataForRatingSuspenseQuery>;
export type ProductDataForRatingQueryResult = Apollo.QueryResult<Types.ProductDataForRatingQuery, Types.ProductDataForRatingQueryVariables>;
export const ProductsForRatingTableDocument = gql`
    query productsForRatingTable($slug: String!, $geoIndicationId: Int!, $localeId: Int!, $page: PositiveInt!, $perPage: PositiveInt!, $sort: ProductSort!) {
  productBySlug(slug: $slug) {
    id
    appellationRating(geoIndicationId: $geoIndicationId) {
      pagination(localeId: $localeId, page: $page, perPage: $perPage, sort: $sort) {
        ...winePageOfProductAppellationRating
      }
    }
  }
}
    ${WinePageOfProductAppellationRatingFragmentDoc}`;

/**
 * __useProductsForRatingTableQuery__
 *
 * To run a query within a React component, call `useProductsForRatingTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsForRatingTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsForRatingTableQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      geoIndicationId: // value for 'geoIndicationId'
 *      localeId: // value for 'localeId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useProductsForRatingTableQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductsForRatingTableQuery, Types.ProductsForRatingTableQueryVariables> & ({ variables: Types.ProductsForRatingTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductsForRatingTableQuery, Types.ProductsForRatingTableQueryVariables>(ProductsForRatingTableDocument, options);
      }
export function useProductsForRatingTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductsForRatingTableQuery, Types.ProductsForRatingTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductsForRatingTableQuery, Types.ProductsForRatingTableQueryVariables>(ProductsForRatingTableDocument, options);
        }
export function useProductsForRatingTableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductsForRatingTableQuery, Types.ProductsForRatingTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductsForRatingTableQuery, Types.ProductsForRatingTableQueryVariables>(ProductsForRatingTableDocument, options);
        }
export type ProductsForRatingTableQueryHookResult = ReturnType<typeof useProductsForRatingTableQuery>;
export type ProductsForRatingTableLazyQueryHookResult = ReturnType<typeof useProductsForRatingTableLazyQuery>;
export type ProductsForRatingTableSuspenseQueryHookResult = ReturnType<typeof useProductsForRatingTableSuspenseQuery>;
export type ProductsForRatingTableQueryResult = Apollo.QueryResult<Types.ProductsForRatingTableQuery, Types.ProductsForRatingTableQueryVariables>;
export const ProductPageReviewsDocument = gql`
    query productPageReviews($id: ID!, $localeId: Int!, $limit: Int!, $offset: Int) {
  product(id: $id) {
    id
    reviewsPagination(limit: $limit, offset: $offset) {
      pageInfo {
        totalItems
        page
      }
      items {
        ...productPageReview
      }
    }
  }
}
    ${ProductPageReviewFragmentDoc}`;

/**
 * __useProductPageReviewsQuery__
 *
 * To run a query within a React component, call `useProductPageReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductPageReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductPageReviewsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useProductPageReviewsQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductPageReviewsQuery, Types.ProductPageReviewsQueryVariables> & ({ variables: Types.ProductPageReviewsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductPageReviewsQuery, Types.ProductPageReviewsQueryVariables>(ProductPageReviewsDocument, options);
      }
export function useProductPageReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductPageReviewsQuery, Types.ProductPageReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductPageReviewsQuery, Types.ProductPageReviewsQueryVariables>(ProductPageReviewsDocument, options);
        }
export function useProductPageReviewsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductPageReviewsQuery, Types.ProductPageReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductPageReviewsQuery, Types.ProductPageReviewsQueryVariables>(ProductPageReviewsDocument, options);
        }
export type ProductPageReviewsQueryHookResult = ReturnType<typeof useProductPageReviewsQuery>;
export type ProductPageReviewsLazyQueryHookResult = ReturnType<typeof useProductPageReviewsLazyQuery>;
export type ProductPageReviewsSuspenseQueryHookResult = ReturnType<typeof useProductPageReviewsSuspenseQuery>;
export type ProductPageReviewsQueryResult = Apollo.QueryResult<Types.ProductPageReviewsQuery, Types.ProductPageReviewsQueryVariables>;
export const ProductTechSheetsListForEditDocument = gql`
    query productTechSheetsListForEdit($id: ID!, $localeId: Int!) {
  product(id: $id) {
    id
    wine {
      id
      techSheets {
        id
        locale {
          id
          locale
          name
          localizedName(localeId: $localeId)
        }
      }
    }
  }
  locales {
    id
    locale
  }
}
    `;

/**
 * __useProductTechSheetsListForEditQuery__
 *
 * To run a query within a React component, call `useProductTechSheetsListForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTechSheetsListForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTechSheetsListForEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useProductTechSheetsListForEditQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductTechSheetsListForEditQuery, Types.ProductTechSheetsListForEditQueryVariables> & ({ variables: Types.ProductTechSheetsListForEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductTechSheetsListForEditQuery, Types.ProductTechSheetsListForEditQueryVariables>(ProductTechSheetsListForEditDocument, options);
      }
export function useProductTechSheetsListForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductTechSheetsListForEditQuery, Types.ProductTechSheetsListForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductTechSheetsListForEditQuery, Types.ProductTechSheetsListForEditQueryVariables>(ProductTechSheetsListForEditDocument, options);
        }
export function useProductTechSheetsListForEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductTechSheetsListForEditQuery, Types.ProductTechSheetsListForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductTechSheetsListForEditQuery, Types.ProductTechSheetsListForEditQueryVariables>(ProductTechSheetsListForEditDocument, options);
        }
export type ProductTechSheetsListForEditQueryHookResult = ReturnType<typeof useProductTechSheetsListForEditQuery>;
export type ProductTechSheetsListForEditLazyQueryHookResult = ReturnType<typeof useProductTechSheetsListForEditLazyQuery>;
export type ProductTechSheetsListForEditSuspenseQueryHookResult = ReturnType<typeof useProductTechSheetsListForEditSuspenseQuery>;
export type ProductTechSheetsListForEditQueryResult = Apollo.QueryResult<Types.ProductTechSheetsListForEditQuery, Types.ProductTechSheetsListForEditQueryVariables>;
export const TechSheetForPdfDocument = gql`
    query techSheetForPdf($productId: ID!, $localeId: Int!) {
  product(id: $productId) {
    id
    slug
    ...techSheetProductFields
    wine {
      id
      ...techSheetWineFields
      techSheet(localeId: $localeId) {
        id
        locale {
          id
          locale
        }
        ...techSheetFields
      }
    }
  }
}
    ${TechSheetProductFieldsFragmentDoc}
${TechSheetWineFieldsFragmentDoc}
${TechSheetFieldsFragmentDoc}`;

/**
 * __useTechSheetForPdfQuery__
 *
 * To run a query within a React component, call `useTechSheetForPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechSheetForPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechSheetForPdfQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useTechSheetForPdfQuery(baseOptions: Apollo.QueryHookOptions<Types.TechSheetForPdfQuery, Types.TechSheetForPdfQueryVariables> & ({ variables: Types.TechSheetForPdfQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TechSheetForPdfQuery, Types.TechSheetForPdfQueryVariables>(TechSheetForPdfDocument, options);
      }
export function useTechSheetForPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TechSheetForPdfQuery, Types.TechSheetForPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TechSheetForPdfQuery, Types.TechSheetForPdfQueryVariables>(TechSheetForPdfDocument, options);
        }
export function useTechSheetForPdfSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TechSheetForPdfQuery, Types.TechSheetForPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TechSheetForPdfQuery, Types.TechSheetForPdfQueryVariables>(TechSheetForPdfDocument, options);
        }
export type TechSheetForPdfQueryHookResult = ReturnType<typeof useTechSheetForPdfQuery>;
export type TechSheetForPdfLazyQueryHookResult = ReturnType<typeof useTechSheetForPdfLazyQuery>;
export type TechSheetForPdfSuspenseQueryHookResult = ReturnType<typeof useTechSheetForPdfSuspenseQuery>;
export type TechSheetForPdfQueryResult = Apollo.QueryResult<Types.TechSheetForPdfQuery, Types.TechSheetForPdfQueryVariables>;
export const ProductVisitDocument = gql`
    mutation productVisit($id: ID) {
  productVisit(id: $id) {
    _
  }
}
    `;
export type ProductVisitMutationFn = Apollo.MutationFunction<Types.ProductVisitMutation, Types.ProductVisitMutationVariables>;

/**
 * __useProductVisitMutation__
 *
 * To run a mutation, you first call `useProductVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productVisitMutation, { data, loading, error }] = useProductVisitMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductVisitMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProductVisitMutation, Types.ProductVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProductVisitMutation, Types.ProductVisitMutationVariables>(ProductVisitDocument, options);
      }
export type ProductVisitMutationHookResult = ReturnType<typeof useProductVisitMutation>;
export type ProductVisitMutationResult = Apollo.MutationResult<Types.ProductVisitMutation>;
export type ProductVisitMutationOptions = Apollo.BaseMutationOptions<Types.ProductVisitMutation, Types.ProductVisitMutationVariables>;
export const ProductViewBySlugDocument = gql`
    query productViewBySlug($slug: String!, $localeId: Int!, $enLocaleId: Int!, $withEnLocaleId: Boolean!) {
  productBySlug(slug: $slug) {
    ...techSheetProductFields
    id
    acceptability
    canOverrideAcceptability
    canOverrideFacts
    verified
    wine {
      ...techSheetWineFields
      techSheet(localeId: $localeId) {
        ...techSheetFields
      }
      techSheetEn: techSheet(localeId: $enLocaleId) @include(if: $withEnLocaleId) {
        ...techSheetFields
      }
      ...wineViewFields
    }
    slug
    bottleSize {
      id
      volumeML
    }
    packageType {
      id
      isDefault
      shortName
      localizedName(localeId: $localeId)
    }
    closureType {
      id
      localizedName(localeId: $localeId)
    }
    frontLabelImage {
      ...productViewImage
    }
    frontLabelImageThumb: frontLabelImage {
      ...imageThumbnail64
    }
    backLabelImage {
      ...productViewImage
    }
    backLabelImageThumb: backLabelImage {
      ...imageThumbnail64
    }
    bottleImage {
      ...productViewImage
    }
    bottleImageThumb: bottleImage {
      ...imageThumbnail64
    }
    shortUrl
    annualProduction {
      value
      unit {
        id
        masterName
        localizedName(localeId: $localeId)
      }
    }
    bottlingDate
    appellationRatings {
      rank
      count
      geoIndication {
        id
        localizedName(localeId: $localeId)
      }
    }
    priceAtWinery
    currency {
      id
      code
    }
    mainSynonym {
      id
      slug
    }
  }
}
    ${TechSheetProductFieldsFragmentDoc}
${TechSheetWineFieldsFragmentDoc}
${TechSheetFieldsFragmentDoc}
${WineViewFieldsFragmentDoc}
${ProductViewImageFragmentDoc}
${ImageThumbnail64FragmentDoc}`;

/**
 * __useProductViewBySlugQuery__
 *
 * To run a query within a React component, call `useProductViewBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductViewBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductViewBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      localeId: // value for 'localeId'
 *      enLocaleId: // value for 'enLocaleId'
 *      withEnLocaleId: // value for 'withEnLocaleId'
 *   },
 * });
 */
export function useProductViewBySlugQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductViewBySlugQuery, Types.ProductViewBySlugQueryVariables> & ({ variables: Types.ProductViewBySlugQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductViewBySlugQuery, Types.ProductViewBySlugQueryVariables>(ProductViewBySlugDocument, options);
      }
export function useProductViewBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductViewBySlugQuery, Types.ProductViewBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductViewBySlugQuery, Types.ProductViewBySlugQueryVariables>(ProductViewBySlugDocument, options);
        }
export function useProductViewBySlugSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductViewBySlugQuery, Types.ProductViewBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductViewBySlugQuery, Types.ProductViewBySlugQueryVariables>(ProductViewBySlugDocument, options);
        }
export type ProductViewBySlugQueryHookResult = ReturnType<typeof useProductViewBySlugQuery>;
export type ProductViewBySlugLazyQueryHookResult = ReturnType<typeof useProductViewBySlugLazyQuery>;
export type ProductViewBySlugSuspenseQueryHookResult = ReturnType<typeof useProductViewBySlugSuspenseQuery>;
export type ProductViewBySlugQueryResult = Apollo.QueryResult<Types.ProductViewBySlugQuery, Types.ProductViewBySlugQueryVariables>;
export const WineTitleDocument = gql`
    query wineTitle($id: ID!) {
  wine(id: $id) {
    id
    tradeNameBrand
    keyWords
    specialClassifications {
      id
      name
    }
  }
}
    `;

/**
 * __useWineTitleQuery__
 *
 * To run a query within a React component, call `useWineTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineTitleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWineTitleQuery(baseOptions: Apollo.QueryHookOptions<Types.WineTitleQuery, Types.WineTitleQueryVariables> & ({ variables: Types.WineTitleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineTitleQuery, Types.WineTitleQueryVariables>(WineTitleDocument, options);
      }
export function useWineTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineTitleQuery, Types.WineTitleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineTitleQuery, Types.WineTitleQueryVariables>(WineTitleDocument, options);
        }
export function useWineTitleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineTitleQuery, Types.WineTitleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineTitleQuery, Types.WineTitleQueryVariables>(WineTitleDocument, options);
        }
export type WineTitleQueryHookResult = ReturnType<typeof useWineTitleQuery>;
export type WineTitleLazyQueryHookResult = ReturnType<typeof useWineTitleLazyQuery>;
export type WineTitleSuspenseQueryHookResult = ReturnType<typeof useWineTitleSuspenseQuery>;
export type WineTitleQueryResult = Apollo.QueryResult<Types.WineTitleQuery, Types.WineTitleQueryVariables>;
export const WineViewByIdDocument = gql`
    query wineViewById($id: ID!, $localeId: Int!) {
  wine(id: $id) {
    ...wineViewFields
  }
}
    ${WineViewFieldsFragmentDoc}`;

/**
 * __useWineViewByIdQuery__
 *
 * To run a query within a React component, call `useWineViewByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useWineViewByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWineViewByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      localeId: // value for 'localeId'
 *   },
 * });
 */
export function useWineViewByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.WineViewByIdQuery, Types.WineViewByIdQueryVariables> & ({ variables: Types.WineViewByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WineViewByIdQuery, Types.WineViewByIdQueryVariables>(WineViewByIdDocument, options);
      }
export function useWineViewByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WineViewByIdQuery, Types.WineViewByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WineViewByIdQuery, Types.WineViewByIdQueryVariables>(WineViewByIdDocument, options);
        }
export function useWineViewByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WineViewByIdQuery, Types.WineViewByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WineViewByIdQuery, Types.WineViewByIdQueryVariables>(WineViewByIdDocument, options);
        }
export type WineViewByIdQueryHookResult = ReturnType<typeof useWineViewByIdQuery>;
export type WineViewByIdLazyQueryHookResult = ReturnType<typeof useWineViewByIdLazyQuery>;
export type WineViewByIdSuspenseQueryHookResult = ReturnType<typeof useWineViewByIdSuspenseQuery>;
export type WineViewByIdQueryResult = Apollo.QueryResult<Types.WineViewByIdQuery, Types.WineViewByIdQueryVariables>;